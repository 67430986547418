<template>
  <div id="app">
    <v-app id="inspire">
      <v-card>
        <v-toolbar color="#333333">
          <v-icon color="white" class="mr-2">view_quilt</v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light">
            Apresentação
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
          <v-container fluid grid-list-md>
            <v-layout wrap>
              <v-flex xs4>
                <v-autocomplete
                  v-model="origem"
                  autofocus
                  :items="origens"
                  :filter="filtrarObjetos"
                  label="Origem"
                  item-text="nome"
                  item-value="id"
                  autocomplete="disabled"
                  :name="Math.random()"
                  no-data-text
                  :rules="[
                    validacao.origem == false || validacao.origem,
                    value => !!value || 'Obrigatório.'
                  ]"
                  @change="carregarCompetencias"
                ></v-autocomplete>
              </v-flex>
              <v-flex md8></v-flex>

              <v-flex xs12 md4>
                <h4 class="overline font-weight-black font-italic">
                  Competência
                </h4>
                <v-divider></v-divider>
              </v-flex>
              <v-flex md8></v-flex>
              <v-flex xs4>
                <v-progress-linear
                  :active="loadingComp"
                  class="ma-0"
                  color="cyan accent-3"
                  height="3"
                  indeterminate
                ></v-progress-linear>
              </v-flex>
              <v-flex md8></v-flex>

              <v-flex xs6 md2>
                <v-autocomplete
                  v-model="competenciaInicial"
                  :items="competencias"
                  :filter="filtrarItens"
                  hint="Obrigatório"
                  :rules="[
                    validacao.competenciaInicial == false ||
                      validacao.competenciaInicial,
                    value => !!value || 'Obrigatório.'
                  ]"
                  label="Inicial"
                  :name="Math.random()"
                  no-data-text
                  :disabled="competencias.length == 0"
                ></v-autocomplete>
              </v-flex>

              <v-flex xs6 md2>
                <v-autocomplete
                  v-model="competenciaFinal"
                  :items="competencias"
                  :filter="filtrarItens"
                  hint="Obrigatório"
                  :rules="[
                    validacao.competenciaFinal == false ||
                      validacao.competenciaFinal,
                    value => !!value || 'Obrigatório.'
                  ]"
                  label="Final"
                  :name="Math.random()"
                  no-data-text
                  :disabled="competencias.length == 0"
                ></v-autocomplete>
              </v-flex>
              <v-flex xs1>
                <v-btn
                  color="blue"
                  :loading="loading"
                  :disabled="competencias.length == 0"
                  fab
                  small
                  @click="pesquisaDados()"
                >
                  <v-icon color="white" title="Imprimir">print</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
        <div class="px-4 mt-3">
          <div v-html="html"></div>

          <v-snackbar
            v-model="snackErro"
            color="error"
            :timeout="5000"
            relative
            bottom
            left
            >{{ mensagem }}</v-snackbar
          >
        </div>
      </v-card>
    </v-app>
  </div>
</template>

<script>
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
import util from "../../../util.js";
export default {
  data() {
    return {
      origem: null,
      competenciaInicial: null,
      competenciaFinal: null,
      validacao: {
        origem: false,
        competenciaInicial: false,
        competenciaFinal: false
      },
      origens: [],
      competencias: [],
      html: "",
      dtInicioUS: ["", ""],
      mnuDtInicio: false,
      elementoValid: true,
      mensagem: "",
      snackErro: false,
      carregando: true,
      options: {},
      loader: null,
      loading: false,
      loadingComp: false
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    async pesquisaDados() {
      this.html = "";
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem =
          "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }
      if (
        !util.valida_data_BR("01/" + this.competenciaInicial) ||
        !util.valida_data_BR("01/" + this.competenciaFinal)
      ) {
        this.mensagem = "Verifique a competência";
        this.snackErro = true;
        return;
      }

      var dtInicial = util.parseDate("01/" + this.competenciaInicial);
      var dtFinal = util.parseDate("01/" + this.competenciaFinal);
      if (dtInicial > dtFinal) {
        this.mensagem = "Competência inicial deve ser inferior a final";
        this.snackErro = true;
        return;
      }

      var nroCompet = 1;
      do {
        dtInicial.setMonth(dtInicial.getMonth() + 1);
        nroCompet++;
      } while (dtInicial < dtFinal);
      if (nroCompet > 12) {
        this.mensagem = "Período máximo: 12 meses";
        this.snackErro = true;
        return;
      }

      this.loader = "loading";
      const l = this.loader;
      this[l] = !this[l];

      //sinaliza que está carregando
      this.carregando = true;

      try {
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "fin/parcela/apresentacao?idSite=" +
            this.$store.state.usuario.idSitePrincipal +
            "&origem=" +
            this.origem +
            "&competenciaInicial=" +
            this.competenciaInicial +
            "&competenciaFinal=" +
            this.competenciaFinal
        );

        util.imprimir(response.data);

        //fecha o loading
        this.carregando = false;
        //se não achar registro seta a mensagem senão deixa em branco
        // this.mensagem =
        //   this.registros.length > 1 ? "" : "Nenhum registro encontrado";
      } catch (e) {
        this.carregando = false;
        // this.mensagem = "Erro ao buscar dados: " + e;
      } finally {
        this[l] = false;
        this.loader = null;
      }
    },
    async carregarCompetencias() {
      try {
        this.loadingComp = true;
        this.competencias = [];
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "fin/parcela/primeiroVecto?origem=" +
            this.origem
        );
        var dtInicial = new Date(response.data.dtVecto);
        dtInicial.setDate(1);

        var dtFinal = new Date();
        dtFinal.setDate(1);
        do {
          this.competencias.push(util.formatDate(dtFinal).substring(3));
          dtFinal.setMonth(dtFinal.getMonth() - 1);
        } while (dtFinal > dtInicial);
      } catch {
        this.mensagem = "Ocorreu um erro ao carregas as competências";
        this.snackErro = true;
      } finally {
        this.loadingComp = false;
      }
    },

    valida_data(date) {
      if (date) {
        return util.valida_data_BR(date);
      } else {
        return true;
      }
    },
    filtrarItens(item, queryText, itemText) {
      return util.filtrarItens(item, queryText, itemText);
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    formatDateUS(date) {
      return util.formatDateUS(date);
    }
  },
  beforeMount() {
    this.POSSUI_DIREITO([
      "WEBSUITA_ADMIN_SUITA",
      "FINANCEIRO_RELATÓRIOS_APRESENTAÇÃO"
    ]);

    if (this.$store.state.usuario.site_comercial) {
      this.origens.push({ id: "ANU", nome: "Anúncio" });
    }
    if (this.$store.state.usuario.site_assinaturas) {
      this.origens.push({ id: "ASS", nome: "Assinatura" });
    }
  }
};
</script>
