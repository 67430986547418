<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">question_answer</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Módulo Comercial</v-toolbar-title>
    </v-toolbar>
    <v-container>
      <v-layout wrap>
        <v-flex xs12>
          <v-alert :value="true" color="blue" icon="apps" outlined>Bem vindo ao módulo Comercial.</v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.7 - 30/03/2023:
            <br />- Textos padrão para anúncios;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.1 - 02/01/2023:
            <br />- Definição de colunas/altura padrão para o anúncio, a partir do cadastro do formato;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.79 - 07/07/2022:
            <br />- Filtros de anunciantes com/sem anúncio e com/sem e-mail;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.71:
            <br />- Seleção dos campos desejados na exportação de dados de anúncios;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.70:
            <br />- Separação dos bloqueios de parcelas (Anúncio em Negociação) e de publicações (Publicação Bloqueada);
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.68:
            <br />- Recibo prévio de anúncio;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.67:
            <br />- Cópia de anúncio;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.66:
            <br />- Definição de comissão padrão e desconto de comissão no cadastro do agente/agência;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.65:
            <br />- Inclusão de texto para fatura diretamente na tela de captação;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.64:
            <br />- Desconto máximo do agente; <br />- Bloqueio de publicação por forma de pagamento e liberação na quitação da parcela;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.63:
            <br />- Alteração de valores, vencimentos e formas de pagamento das parcelas antes de salvar o anúncio;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.62:
            <br />- Pacotes de Anúncios;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.57:
            <br />- Permutas: <br />- Captação de anúncio utilizando PERMUTA A PAGAR cadastrada previamente no Financeiro; <br />- Captação de anúncio na forma de pagamento PERMUTA
            A RECEBER a ser abatida posteriormente no Financeiro;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.56:
            <br />- Cadastro automático do logradouro ao incluir um novo CEP; <br />- Filtro avançado e impressão da listagem de anúncios e exportação por CSV; <br />- Geração do
            Pedido de Inserção (numeração automática ou não);
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.55:
            <br />- Versão de Homologação do Comercial Web;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.51:
            <br />- Cobrança de anúncios através de réguas/etapas;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.13:
            <br />- Visualização de dados de anúncios importados do Comercial;
          </v-alert>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  methods: {
    ...mapMutations(["MOSTRAR_MENU"])
  },
  beforeMount() {
    this.MOSTRAR_MENU();

    var direito = false;
    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      if (element.modulo == "COMERCIAL" || element.modulo + "_" + element.grupo + "_" + element.direito == "WEBSUITA_ADMIN_SUITA") {
        direito = true;
      }
    });
    if (!direito) {
      this.$router.push("/acesso-negado");
    }
  }
};
</script>

<style></style>
