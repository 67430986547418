<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">local_offer</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light"
        >Produto</v-toolbar-title
      >
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear
              :active="isUpdating"
              class="ma-0"
              color="cyan accent-3"
              height="3"
              indeterminate
            ></v-progress-linear>
          </v-flex>

          <v-flex xs12 md12 v-if="siteGrupo.length > 0">
            <v-autocomplete
              v-model="produto.idSite"
              :disabled="isUpdating"
              :items="siteGrupo"
              :filter="filtrarObjetos"
              label="Restrito ao Site"
              item-text="nome"
              item-value="id"
              :rules="[validacao.idSite == false || validacao.idSite]"
              autocomplete="disabled"
              :name="Math.random()"
              no-data-text
              clearable
              @change="validacao.idSite = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md6>
            <v-text-field
              autofocus
              v-model="produto.nome"
              label="Nome do Produto"
              maxlength="50"
              hint="Obrigatório"
              :rules="[
                validacao.nome == false || validacao.nome,
                value => !!value || 'Obrigatório.',
                value => valida_nome(value) || 'Nome inválido',
                value => value.length > 1 || 'Preencha o nome do produto'
              ]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="
                produto.nome = pLetra(produto.nome);
                validacao.nome = false;
              "
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md6>
            <v-autocomplete
              v-model="produto.tipo"
              :disabled="isUpdating || (id != '' && !direitoAvancado)"
              :items="tipos"
              :filter="filtrarItens"
              hint="Obrigatório"
              :rules="[
                validacao.tipo == false || validacao.tipo,
                value => !!value || 'Obrigatório'
              ]"
              label="Selecione o Tipo de Produto"
              autocomplete="disabled"
              :name="Math.random()"
              no-data-text
              @change="validacao.tipo = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs6 md3>
            <v-switch
              v-model="produto.situacao"
              :label="`${produto.situacao == 1 ? 'Ativo' : 'Inativo'}`"
              :disabled="
                isUpdating ||
                  (id != '' && produto.situacao == 1 && !direitoAvancado)
              "
            ></v-switch>
          </v-flex>

          <v-expansion-panels v-model="painel" focusable v-if="nfse">
            <v-expansion-panel dark class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Configuração NFSe</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider></v-divider>
                <v-container fluid grid-list-md>
                  <v-layout wrap>
                    <v-flex xs6 md3>
                      <v-text-field
                        v-model="produto.natOp"
                        autofocus
                        label="Natureza da Operação"
                        v-mask="'#'"
                        :rules="[validacao.natOp == false || validacao.natOp]"
                        :disabled="isUpdating || (id != '' && !direitoAdmin)"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        @change="validacao.natOp = false"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs6 md3>
                      <v-text-field
                        v-model="produto.codTribMun"
                        label="Código Tributário Municipal"
                        maxlength="20"
                        :rules="[
                          validacao.codTribMun == false || validacao.codTribMun
                        ]"
                        :disabled="isUpdating || (id != '' && !direitoAdmin)"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        @change="validacao.codTribMun = false"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs6 md3>
                      <v-text-field
                        v-model="produto.cnae"
                        label="CNAE"
                        maxlength="7"
                        :rules="[validacao.cnae == false || validacao.cnae]"
                        :disabled="isUpdating || (id != '' && !direitoAdmin)"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        @change="validacao.cnae = false"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs6 md3>
                      <v-text-field
                        v-model="produto.codListaServ"
                        label="Código Lista de Serviço"
                        maxlength="4"
                        :rules="[
                          validacao.codListaServ == false ||
                            validacao.codListaServ
                        ]"
                        :disabled="isUpdating || (id != '' && !direitoAdmin)"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        @change="validacao.codListaServ = false"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs6 md3>
                      <v-currency-field
                        :locale="`pt-${$store.state.usuario.site_pais}`"
                        v-model="produto.iss"
                        label="Alíquota ISS"
                        :allowNegative="false"
                        :max="99"
                        :rules="[validacao.iss == false || validacao.iss]"
                        color="blue-grey lighten-2"
                        @change="validacao.iss = false"
                        :disabled="isUpdating || (id != '' && !direitoAdmin)"
                      ></v-currency-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">{{
              validacao.mensagemErro
            }}</v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn
        to="/comercial/produtos"
        id="btn-rsn"
        color="orange lighten-2 white"
        style="color:white"
      >
        <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar
      </v-btn>
      <v-btn
        :loading="isUpdating"
        color="blue white"
        style="color:white"
        id="btn-rsn"
        depressed
        @click="salvar('false')"
      >
        <v-icon id="icon-rsn" left>update</v-icon>Salvar
      </v-btn>
      <v-btn
        :loading="isUpdating"
        color="orange white"
        style="color:white"
        id="btn-rsn"
        outlined
        @click="salvar('retornar')"
      >
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>
      <v-btn
        :loading="isUpdating"
        color="blue white"
        style="color:white"
        id="btn-rsn"
        outlined
        @click="salvar('true')"
        v-if="direitoCriar"
      >
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Novo
      </v-btn>
    </v-card-actions>
    <v-snackbar
      v-model="snackbar"
      color="info"
      :timeout="5000"
      relative
      bottom
      left
      >{{ mensagem }}</v-snackbar
    >
    <v-snackbar
      v-model="snackErro"
      color="error"
      :timeout="5000"
      relative
      bottom
      left
      >{{ mensagem }}</v-snackbar
    >
  </v-card>
</template>

<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      produto: {
        id: "",
        idSite: null,
        nome: "",
        tipo: null,
        natOp: null,
        codTribMun: "",
        cnae: "",
        codListaServ: "",
        iss: 0,
        situacao: 1
      },

      validacao: {
        id: false,
        idSite: false,
        nome: false,
        tipo: false,
        natOp: false,
        codTribMun: false,
        cnae: false,
        codListaServ: false,
        iss: false,
        situacao: false,
        mensagemErro: false
      },

      siteGrupo: [],

      tipos: ["JORNAL", "CLASSIFICADOS", "ONLINE"],

      idSitePrincipal: null,
      nfse: null,

      painel: [0],
      direitoCriar: false,
      direitoAdmin: false,
      direitoAvancado: false,

      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,
      isUpdating: false
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem =
          "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }
      if (!this.produto.natOp) {
        this.produto.natOp = null;
      }

      this.isUpdating = true;
      try {
        var retorno = "";
        if (this.id) {
          retorno = await api.put(
            process.env.VUE_APP_URL_WEB_API + "com/produto/" + this.id,
            this.produto
          );
        } else {
          retorno = await api.post(
            process.env.VUE_APP_URL_WEB_API +
              "com/produto?idSite=" +
              this.idSitePrincipal,
            this.produto
          );
          this.id = retorno.data.id;
          this.produto.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "true") {
          this.$router.push("/comercial/produto");
          this.$router.go("/comercial/produto");
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push("/comercial/produtos");
          }, 2000);
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem =
            "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem =
            "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },

    async carregarConfigNFSe() {
      try {
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "fin/config-nfse/site/" +
            this.idSitePrincipal
        );
        if (response && response.data && response.data.id) {
          this.nfse = true;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a configuração da NFSe";
        this.snackErro = true;
      }
    },

    pLetra(text) {
      return util.pLetra(text);
    },
    valida_nome(text) {
      return util.valida_nome(text);
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    filtrarItens(item, queryText, itemText) {
      return util.filtrarItens(item, queryText, itemText);
    }
  },

  async created() {
    this.id = this.$route.params.id ? this.$route.params.id : "";

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito =
        element.modulo + "_" + element.grupo + "_" + element.direito;
      if (
        direito == "WEBSUITA_ADMIN_SUITA" ||
        direito == "COMERCIAL_PRODUTOS_CRIAR"
      ) {
        this.direitoCriar = true;
      }
      if (
        direito == "WEBSUITA_ADMIN_SUITA" ||
        direito == "COMERCIAL_PRODUTOS_EDITAR_AVANÇADO"
      ) {
        this.direitoAvancado = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA") {
        this.direitoAdmin = true;
      }
    });

    //* Verifica se o site do usuário está em GRUPO - OBS: ou ele compartilha a informação ou deixa exclusiva para o seu site
    var idGrupoSite = this.$store.state.usuario.site_idGrupo;
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
    if (idGrupoSite != null) {
      this.siteGrupo = [
        {
          id: this.idSitePrincipal,
          nome: this.$store.state.usuario.site_nome
        }
      ];
    }

    await this.carregarConfigNFSe();

    try {
      //* Se é edição, carrega o item
      if (this.id == "") {
        this.POSSUI_DIREITO([
          "WEBSUITA_ADMIN_SUITA",
          "COMERCIAL_PRODUTOS_CRIAR"
        ]);
      } else {
        this.POSSUI_DIREITO([
          "WEBSUITA_ADMIN_SUITA",
          "COMERCIAL_PRODUTOS_EDITAR"
        ]);
        //* Carrega o item - somente se for público (idSite=null) ou for do site do usuário logado
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "com/produto/" +
            this.id +
            "?idSite=" +
            this.idSitePrincipal
        );
        if (!response.data) {
          this.$router.push("/comercial/produtos");
        } else {
          this.produto = response.data;
        }
      }
    } catch {
      this.mensagem = "Ocorreu um erro ao carregar os dados";
      this.snackErro = true;
    }
  }
};
</script>
