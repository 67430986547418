<template>
  <div id="app">
    <v-app id="inspire">
      <v-card>
        <v-toolbar color="#333333">
          <v-icon color="white" class="mr-2">bookmarks</v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light">
            Contas -
            {{ grupo == 1 ? ($store.state.usuario.site_planoContas || direitoAdmin ? "Ativo" : "Caixa & Bancos") : grupo == 2 ? "Passivo" : grupo == 3 ? "Receitas" : "Despesas" }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <router-link v-if="direitoCriar" :to="'/financeiro/conta/' + grupo + '/null'" style="text-decoration: none">
            <v-btn v-if="$store.state.usuario.site_planoContas || direitoAdmin" color="success" fab small>
              <v-icon color="white" title="Incluir">add</v-icon>
            </v-btn>
          </router-link>
        </v-toolbar>
        <div class="px-4 mt-3">
          <v-layout wrap>
            <v-spacer></v-spacer>
            <v-flex xs12 md5>
              <v-text-field
                background-color="white"
                class="mt-2 mb-2 elevation-0"
                v-model="filtroPesquisa"
                append-icon="search"
                label="Digite para pesquisar..."
                placeholder="Pesquisa pelo nome"
                hide-details
                @keyup.enter="limpaFiltro"
                @click:append="limpaFiltro"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-data-table
            mobile-breakpoint="0"
            :headers="grupo < 3 ? headersAP : headers"
            :items="registros"
            :options.sync="options"
            :no-data-text="mensagemPesquisa"
            :footer-props="rodape"
            :disable-sort="carregando"
            :disable-pagination="carregando"
          >
            <template v-slot:item.actions="{ item }">
              <router-link
                v-if="direitoEditar && (item.nome != 'Disponível' || (item.nome == 'Disponível' && ($store.state.usuario.site_planoContas || direitoAdmin)))"
                :to="'/financeiro/conta/' + grupo + '/' + item.idSintetica + '/' + item.id"
                style="text-decoration: none"
              >
                <v-icon color="blue" title="Editar">edit</v-icon>
              </router-link>
              <v-icon color="blue" title="Visualizar Log" @click="visualizarLog(item)">info</v-icon>
            </template>

            <template v-slot:item.numero="{ item }">
              <span
                v-html="
                  '&emsp;'.repeat(item.numero.split('.').length - 2) +
                    item.numero +
                    ' - ' +
                    (item.nome != 'Disponível' || (item.nome == 'Disponível' && ($store.state.usuario.site_planoContas || direitoAdmin)) ? item.nome : 'Caixa & Bancos')
                "
              ></span>
            </template>

            <template v-slot:item.actions2="{ item }">
              <router-link v-if="item.analitica == 0 && direitoCriar" :to="'/financeiro/conta/' + grupo + '/' + item.id" style="text-decoration: none">
                <v-icon color="success" title="Incluir">add_circle</v-icon>
              </router-link>
            </template>

            <template v-slot:item.analitica="{ item }">
              <span v-html="item.analitica == 0 ? 'TÍTULO' : '<strong>Lançável</strong>'"></span>
            </template>

            <template v-slot:item.situacao="{ item }">
              <span v-html="item.situacao == 1 ? 'Ativo' : item.situacao == 0 ? '<strong>Inativo</strong>' : ''"></span>
            </template>
          </v-data-table>
          <div class="text-xs-center pt-2 marg-bt">
            <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 7" circle></v-pagination>
          </div>
        </div>
      </v-card>
      <v-dialog v-model="dialogLogAlteracao" max-width="1200px">
        <v-card>
          <LogAlteracao :idConta="idConta" :dialogLogAlteracao.sync="dialogLogAlteracao" />
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
import LogAlteracao from "@/views/components/LogAlteracao.vue";
export default {
  components: {
    LogAlteracao
  },
  data() {
    return {
      headers: [
        { text: "Ações", value: "actions" },
        { text: "Conta", value: "numero" },
        { text: "", value: "actions2", divider: "true" },
        { text: "ID", value: "id" },
        { text: "Classe", value: "analitica" },
        { text: "Código Contábil", value: "contabil" },
        { text: "Centro Custo", value: "centroCusto" },
        { text: "Situação", value: "situacao" }
      ],
      headersAP: [
        { text: "Ações", value: "actions" },
        { text: "Conta", value: "numero" },
        { text: "", value: "actions2", divider: "true" },
        { text: "ID", value: "id" },
        { text: "Classe", value: "analitica" },
        { text: "Código Contábil", value: "contabil" },
        { text: "Situação", value: "situacao" }
      ],
      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },
      dialogLogAlteracao: false,
      idConta: "",
      direitoAdmin: false,
      direitoCriar: false,
      direitoEditar: false,
      mensagemPesquisa: "",
      filtroPesquisa: "",
      registros: [],
      registrosPagina: 50,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      carregando: true,
      ordenacao: "",
      ordem: "",

      options: {}
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    limpaFiltro() {
      this.paginaAtual = 1;
      this.pesquisaDados();
    },
    async pesquisaDados() {
      this.registros = [];
      //sinaliza que está carregando
      this.carregando = true;
      this.mensagemPesquisa = "Carregando...";

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "fin/conta?idSite=" +
            this.$store.state.usuario.idSitePrincipal +
            "&grupo=" +
            this.grupo +
            "&pagina=" +
            this.paginaAtual +
            "&tamanho=" +
            this.registrosPagina +
            "&filtro=" +
            this.filtroPesquisa +
            "&ordenacao=" +
            this.ordenacao +
            "&ordem=" +
            this.ordem
        );

        //seta as variaveis com o retorno
        this.registros = response.data.data;

        this.totalRegistros = response.data.total;
        this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
        this.rodape.pageText = this.totalRegistros + " itens";
        //fecha o loading
        this.carregando = false;
        //se não achar registro seta a mensagem senão deixa em branco
        this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else {
          this.mensagemPesquisa = "Erro ao buscar dados: " + e;
        }
      }
    },
    visualizarLog(item) {
      this.idConta = item.id + "";
      this.dialogLogAlteracao = true;
    },
    closeLogAlteracao() {
      this.dialogLogAlteracao = false;
      this.idConta = "";
    }
  },

  beforeMount() {
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_CONTAS_ACESSO"]);
    //* Verifica se o usuário logado possui direito para incluir
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA") {
        this.direitoAdmin = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_CONTAS_CRIAR") {
        this.direitoCriar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_CONTAS_EDITAR") {
        this.direitoEditar = true;
      }
    });
    if (this.$store.state.usuario.site_planoContas || this.direitoAdmin) {
      // this.headers.push({ text: "Origem", value: "origem" });
      this.headers.push({ text: "Tipo", value: "tipo" });
      this.headers.push({ text: "SubTipo", value: "subtipo" });
      // this.headersAP.push({ text: "Origem", value: "origem" });
      this.headersAP.push({ text: "Tipo", value: "tipo" });
      this.headersAP.push({ text: "SubTipo", value: "subtipo" });
    }
  },

  computed: {
    grupo() {
      return this.$route.params.grupo;
    }
  },

  watch: {
    $route() {
      this.paginaAtual = 1;
      this.pesquisaDados();
    },
    paginaAtual() {
      this.pesquisaDados();
    },
    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }
        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }
        this.pesquisaDados();
      }
    }
  }
};
</script>
