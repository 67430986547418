<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">description</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Texto Padrão</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12 md12 v-if="siteGrupo.length > 0">
            <v-autocomplete
              v-model="texto.idSite"
              :disabled="isUpdating"
              :items="siteGrupo"
              :filter="filtrarObjetos"
              label="Restrito ao Site"
              item-text="nome"
              item-value="id"
              :rules="[validacao.idSite == false || validacao.idSite]"
              autocomplete="disabled"
              :name="Math.random()"
              no-data-text
              clearable
              @change="validacao.idSite = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md12>
            <v-text-field
              autofocus
              v-model="texto.nome"
              label="Título"
              maxlength="50"
              hint="Obrigatório"
              :rules="[validacao.nome == false || validacao.nome, value => !!value || 'Obrigatório.', value => value.length > 1 || 'Preencha o título do texto']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @keyup="texto.nome = texto.nome.toUpperCase()"
              @change="validacao.nome = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md12>
            <v-textarea
              v-model="texto.texto"
              :disabled="isUpdating"
              label="Texto"
              maxlength="3000"
              counter="3000"
              rows="6"
              :rules="[validacao.texto == false || validacao.texto, value => !value || (value.length > 10 && value.length < 4000) || 'Texto deve ter entre 10 e 3000 caracteres']"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.texto = false"
            ></v-textarea>
          </v-flex>

          <v-flex xs6 md3>
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="texto.valor"
              label="Valor"
              :allowNegative="false"
              :max="9999"
              :rules="[validacao.valor == false || validacao.valor, value => !!value || 'Obrigatório.', value => (value && value.length < 9) || 'Verifique o valor']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.valor = false"
            ></v-currency-field>
          </v-flex>

          <v-flex xs6 md6>
            <v-switch v-model="texto.situacao" :label="`${texto.situacao == 1 ? 'Ativo' : 'Inativo'}`"></v-switch>
          </v-flex>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">
              {{ validacao.mensagemErro }}
            </v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn to="/comercial/textos" id="btn-rsn" color="orange lighten-2 white" style="color:white"> <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar </v-btn>
      <v-btn :loading="isUpdating" color="blue white" style="color:white" id="btn-rsn" depressed @click="salvar('false')"> <v-icon id="icon-rsn" left>update</v-icon>Salvar </v-btn>
      <v-btn :loading="isUpdating" color="orange white" style="color:white" id="btn-rsn" outlined @click="salvar('retornar')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>
      <v-btn :loading="isUpdating" color="blue white" style="color:white" id="btn-rsn" outlined @click="salvar('true')" v-if="direitoCriar">
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Novo
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>

<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      texto: {
        id: "",
        idSite: null,
        nome: "",
        texto: "",
        valor: 0,
        situacao: 1
      },
      validacao: {
        id: false,
        idSite: false,
        nome: false,
        texto: false,
        valor: false,
        situacao: false,
        mensagemErro: false
      },

      siteGrupo: [],

      idSitePrincipal: null,
      direitoCriar: false,
      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      this.isUpdating = true;
      try {
        var retorno = "";
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "com/texto/" + this.id, this.texto);
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "com/texto?idSite=" + this.idSitePrincipal, this.texto);
          this.id = retorno.data.id;
          this.texto.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "true") {
          this.$router.push("/comercial/texto");
          this.$router.go("/comercial/texto");
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push("/comercial/textos");
          }, 2000);
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },

    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    }
  },
  async created() {
    this.id = this.$route.params.id ? this.$route.params.id : "";

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_TEXTOS_CRIAR") {
        this.direitoCriar = true;
      }
    });

    //* Verifica se o site do usuário está em GRUPO - OBS: ou ele compartilha a informação ou deixa exclusiva para o seu site
    var idGrupoSite = this.$store.state.usuario.site_idGrupo;
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
    if (idGrupoSite != null) {
      this.siteGrupo = [
        {
          id: this.idSitePrincipal,
          nome: this.$store.state.usuario.site_nome
        }
      ];
    }

    try {
      //* Se é edição, carrega o item
      if (this.id == "") {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "COMERCIAL_TEXTOS_CRIAR"]);
      } else {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "COMERCIAL_TEXTOS_EDITAR"]);
        //* Carrega o item - somente se for público (idSite=null) ou for do site do usuário logado
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "com/texto/" + this.id + "?idSite=" + this.idSitePrincipal);
        if (!response.data) {
          this.$router.push("/comercial/textos");
        } else {
          this.texto = response.data;
        }
      }
    } catch {
      this.mensagem = "Ocorreu um erro ao carregar os dados";
      this.snackErro = true;
    }
  }
};
</script>
