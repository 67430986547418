<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">subtitles</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Origem de Venda</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12 md12 v-if="siteGrupo.length > 0">
            <v-autocomplete
              v-model="origem.idSite"
              :disabled="isUpdating"
              :items="siteGrupo"
              :filter="filtrarObjetos"
              label="Restrito ao Site"
              item-text="nome"
              item-value="id"
              :rules="[validacao.idSite == false || validacao.idSite]"
              clearable
              no-data-text
              @change="validacao.idSite = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md12>
            <v-text-field
              autofocus
              v-model="origem.nome"
              label="Nome da Origem de Venda"
              maxlength="50"
              hint="Obrigatório"
              :rules="[validacao.nome == false || validacao.nome, value => !!value || 'Obrigatório.', value => value.length > 2 || 'Preencha o nome da origem de venda']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="
                origem.nome = pLetra(origem.nome);
                validacao.nome = false;
              "
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md12>
            <v-text-field
              v-model="origem.descricao"
              label="Descrição da Origem de Venda"
              maxlength="100"
              hint="Obrigatório"
              :rules="[validacao.descricao == false || validacao.descricao, value => !value || value.length > 10 || 'Preencha a descrição da origem de venda']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.descricao = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs6>
            <v-switch
              hint="Padrão para captação pelo Administrativo do SuitaSIM"
              persistent-hint
              v-model="origem.sim"
              :label="`${origem.sim == 1 ? 'SuitaSIM' : 'Não é SuitaSIM'}`"
              :disabled="isUpdating || origem.online"
            ></v-switch>
          </v-flex>

          <v-flex xs6>
            <v-switch
              hint="Padrão para captação online"
              persistent-hint
              v-model="origem.online"
              :label="`${origem.online == 1 ? 'Paywall' : 'Não é Paywall'}`"
              :disabled="isUpdating || origem.sim"
            ></v-switch>
          </v-flex>
          <v-flex xs12 v-if="origem.online">
            <v-autocomplete
              v-model="origem.idPlanoPaywall"
              :items="planosPaywall"
              :filter="filtrarObjetos"
              item-text="nome"
              item-value="id"
              :rules="[validacao.idPlanoPaywall == false || validacao.idPlanoPaywall]"
              label="Plano Paywall"
              @change="validacao.idPlanoPaywall = false"
              :disabled="isUpdating"
              hint="Caso deseje uma origem para cada Plano Paywall, associe-o aqui"
              persistent-hint
              autocomplete="disabled"
              :name="Math.random()"
              clearable
            ></v-autocomplete>
          </v-flex>

          <v-flex xs6 class="mt-4">
            <v-menu ref="mnuDtInicio" v-model="mnuDtInicio" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dtInicio"
                  label="Data Inicial"
                  v-mask="'##/##/####'"
                  hint="Obrigatório"
                  :rules="[validacao.dtInicio == false || validacao.dtInicio, value => valida_data(value) || 'Data inválida', value => !!value || 'Obrigatório.']"
                  :disabled="isUpdating"
                  prepend-icon="event"
                  v-on="on"
                  color="blue-grey lighten-2"
                  autocomplete="disabled"
                  :name="Math.random()"
                  @keyup="dtInicioUS = valida_data(dtInicio) ? formatDateUS(dtInicio) : null"
                  @change="validacao.dtInicio = false"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dtInicioUS" locale="pt-br" no-title @input="mnuDtInicio = false"></v-date-picker>
            </v-menu>
          </v-flex>

          <v-flex xs6 class="mt-4">
            <v-menu ref="mnuDtFim" v-model="mnuDtFim" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dtFim"
                  label="Data Final"
                  v-mask="'##/##/####'"
                  :rules="[validacao.dtFim == false || validacao.dtFim, value => valida_data(value) || 'Data inválida']"
                  :disabled="isUpdating"
                  prepend-icon="event"
                  v-on="on"
                  color="blue-grey lighten-2"
                  autocomplete="disabled"
                  :name="Math.random()"
                  @keyup="dtFimUS = valida_data(dtFim) ? formatDateUS(dtFim) : null"
                  @change="validacao.dtFim = false"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dtFimUS" locale="pt-br" no-title @input="mnuDtFim = false"></v-date-picker>
            </v-menu>
          </v-flex>

          <v-flex xs12 md12>
            <v-switch v-model="origem.situacao" :label="`${origem.situacao == 1 ? 'Ativa' : 'Inativa'}`" :disabled="isUpdating"></v-switch>
          </v-flex>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">
              {{ validacao.mensagemErro }}
            </v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn to="/assinaturas/origens" id="btn-rsn" color="orange lighten-2 white" style="color:white"> <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar </v-btn>
      <v-btn :loading="isUpdating" color="blue white" style="color:white" id="btn-rsn" depressed @click="salvar('false')"> <v-icon id="icon-rsn" left>update</v-icon>Salvar </v-btn>
      <v-btn :loading="isUpdating" color="orange white" style="color:white" id="btn-rsn" outlined @click="salvar('retornar')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>

      <v-btn :loading="isUpdating" color="blue white" style="color:white" id="btn-rsn" outlined @click="salvar('true')" v-if="direitoCriar">
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Nova
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      origem: {
        id: "",
        idSite: null,
        nome: "",
        online: 0,
        sim: 0,
        idPlanoPaywall: null,
        dtInicio: null,
        dtFim: null,
        descricao: "",
        situacao: 1
      },
      validacao: {
        id: false,
        idSite: false,
        nome: false,
        online: false,
        sim: false,
        idPlanoPaywall: false,
        dtInicio: false,
        dtFim: false,
        descricao: false,
        situacao: false,
        mensagemErro: false
      },

      dtInicio: null,
      dtInicioUS: null,
      mnuDtInicio: false,

      dtFim: null,
      dtFimUS: null,
      mnuDtFim: false,

      siteGrupo: [],
      planosPaywall: [],

      idSitePrincipal: null,
      direitoCriar: false,
      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      if (this.dtInicio != null) {
        this.origem.dtInicio = util.formatDateUS(this.dtInicio);
      } else {
        this.origem.dtInicio = null;
      }
      if (this.dtFim != null) {
        this.origem.dtFim = util.formatDateUS(this.dtFim);
      } else {
        this.origem.dtFim = null;
      }
      if (this.origem.dtInicio && this.origem.dtFim) {
        if (new Date(this.origem.dtInicio) > new Date(this.origem.dtFim)) {
          this.mensagem = "Verifique as datas de início e fim da promoção";
          this.snackErro = true;
          return;
        }
      }
      if (this.origem.sim && this.origem.online) {
        this.mensagem = "Marque somente SuitaSIM ou Paywall";
        this.snackErro = true;
        return;
      }
      if (!this.origem.online) {
        this.origem.idPlanoPaywall = null;
      }

      this.isUpdating = true;
      try {
        var retorno = "";
        //se tiver id é um update
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "ass/origem/" + this.id, this.origem);
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "ass/origem?idSite=" + this.idSitePrincipal, this.origem);
          this.id = retorno.data.id;
          this.origem.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "true") {
          this.$router.push("/assinaturas/origem");
          this.$router.go("/assinaturas/origem");
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push("/assinaturas/origens");
          }, 2000);
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.origem.periodo = this.origem.periodo == "D" ? "Dias" : "Meses";
        this.isUpdating = false;
      }
    },
    async carregarPlanosPaywall() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/paywall/plano/lista");
        this.planosPaywall = response.data;
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de Planos Paywall";
        this.snackErro = true;
      }
    },

    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    pLetra(text) {
      return util.pLetra(text);
    },
    valida_data(date) {
      if (date) {
        return util.valida_data_BR(date);
      } else {
        return true;
      }
    },
    formatDateUS(date) {
      return util.formatDateUS(date);
    }
  },

  watch: {
    dtInicioUS() {
      this.dtInicio = util.formatDate(this.dtInicioUS);
      this.mnuDtInicio = false;
    },
    dtFimUS() {
      this.dtFim = util.formatDate(this.dtFimUS);
      this.mnuDtFim = false;
    }
  },

  async created() {
    this.id = this.$route.params.id ? this.$route.params.id : "";

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_MODALIDADES_CRIAR") {
        this.direitoCriar = true;
      }
    });

    //* Verifica se o site do usuário está em GRUPO - OBS: ou ele compartilha a informação ou deixa exclusiva para o seu site
    var idGrupoSite = this.$store.state.usuario.site_idGrupo;
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
    if (idGrupoSite != null) {
      this.siteGrupo = [
        {
          id: this.idSitePrincipal,
          nome: this.$store.state.usuario.site_nome
        }
      ];
    }

    await this.carregarPlanosPaywall();
    try {
      //* Se é edição, carrega o elemento
      if (this.id == "") {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_MODALIDADES_CRIAR"]);
      } else {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_MODALIDADES_EDITAR"]);
        //* Carrega o elemento - somente se for público (idSite=null) ou for do site do usuário logado
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/origem/" + this.id + "?idSite=" + this.idSitePrincipal);
        if (!response.data) {
          this.$router.push("/assinaturas/origens");
        } else {
          this.origem = response.data;
          if (this.origem.dtInicio != null) {
            this.dtInicioUS = util.dateToStringUS(this.origem.dtInicio);
          }
          if (this.origem.dtFim != null) {
            this.dtFimUS = util.dateToStringUS(this.origem.dtFim);
          }
        }
      }
    } catch {
      this.mensagem = "Ocorreu um erro ao carregar os dados";
      this.snackErro = true;
    }
  }
};
</script>
