<template>
  <div id="app">
    <v-app id="inspire">
      <v-card class="hide-overflow fill-height">
        <v-toolbar height="50" color="#333333">
          <v-icon color="white" class="mr-2">chrome_reader_mode</v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light">Anúncios</v-toolbar-title>
          <v-spacer></v-spacer>
          <router-link to="/comercial/anuncio" style="text-decoration: none" v-if="direitoCriar">
            <v-btn color="success" fab small>
              <v-icon color="white" title="Incluir">add</v-icon>
            </v-btn>
          </router-link>
        </v-toolbar>
        <div class="px-6 mt-4">
          <v-container fluid grid-list-md>
            <v-layout wrap>
              <v-flex md1 xs3>
                <v-dialog v-model="dialogFiltroAvancado" max-width="800px">
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" color="blue" fab small :disabled="loading || loadingExp || loadingImp">
                      <v-icon color="white" title="Filtro Avançado">search</v-icon>
                    </v-btn>

                    <v-btn
                      color="red lighten-2"
                      style="color:white"
                      class="btn-rsn ml-n2 mb-6"
                      id="btn-limpa-filtro"
                      fab
                      @click="filtroAvancadoLimpar"
                      :disabled="loading || loadingExp || loadingImp"
                    >
                      <v-icon class="icon-rsn" small center title="Limpar Filtro">clear</v-icon>
                    </v-btn>
                  </template>

                  <template>
                    <v-card>
                      <v-tabs v-model="tab" background-color="blue accent-4" center-active dark>
                        <v-tab>Anúncio</v-tab>
                        <v-tab>Cliente</v-tab>
                        <v-tab>Parcela</v-tab>
                        <v-tab>Nota Fiscal</v-tab>
                        <v-tab>Pesquisas</v-tab>
                      </v-tabs>

                      <v-tabs-items v-model="tab">
                        <v-tab-item>
                          <FiltroAnuncio ref="filtroAnuncio" />
                        </v-tab-item>
                        <v-tab-item>
                          <FiltroCliente ref="filtroCliente" prefixo="c." />
                        </v-tab-item>
                        <v-tab-item>
                          <FiltroParcela ref="filtroParcela" prefixo="p." origem="ANU" />
                        </v-tab-item>
                        <v-tab-item>
                          <FiltroNF ref="filtroNF" prefixo="n." v-bind:nfe="true" v-bind:nfsc="true" v-bind:nfse="true" />
                        </v-tab-item>
                        <v-tab-item>
                          <Pesquisas ref="pesquisas" prefixo="u" />
                        </v-tab-item>
                      </v-tabs-items>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="orange lighten-2 white" style="color:white" class="btn-rsn" @click="filtroAvancadoRetornar">
                          <v-icon class="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar
                        </v-btn>
                        <v-btn outlined color="red white" style="color:white" class="btn-rsn" @click="filtroAvancadoLimpar">
                          <v-icon class="icon-rsn" left>clear</v-icon>Limpar
                        </v-btn>
                        <v-btn v-if="tab != 4" color="blue white" style="color:white" class="btn-rsn" depressed @click="limpaFiltro">
                          <v-icon class="icon-rsn" left>tune</v-icon>Filtrar
                        </v-btn>
                        <v-btn v-if="direitoExportar" outlined color="blue white" style="color:white" class="btn-rsn" depressed @click="filtroAvancadoFiltrar(true, false)">
                          <v-icon class="icon-rsn" left>description</v-icon>Exportar
                        </v-btn>
                        <v-btn
                          v-if="tab != 4 && direitoImprimir"
                          outlined
                          color="blue white"
                          style="color:white"
                          class="btn-rsn"
                          depressed
                          @click="filtroAvancadoFiltrar(false, true)"
                        >
                          <v-icon class="icon-rsn" left>print</v-icon>Imprimir
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex md1 xs2>
                <v-btn
                  v-if="direitoExportar"
                  color="blue"
                  :loading="loadingExp"
                  :disabled="loading || loadingExp || loadingImp"
                  fab
                  small
                  @click="filtroAvancadoFiltrar(true, false)"
                >
                  <v-icon color="white" title="Exportar">description</v-icon>
                </v-btn>
              </v-flex>
              <v-flex md1 xs2>
                <v-btn
                  v-if="direitoImprimir"
                  color="blue"
                  :loading="loadingImp"
                  :disabled="loading || loadingExp || loadingImp"
                  fab
                  small
                  @click="filtroAvancadoFiltrar(false, true)"
                >
                  <v-icon color="white" title="Imprimir">print</v-icon>
                </v-btn>
              </v-flex>
              <v-flex xs6 md2>
                <v-text-field
                  background-color="white"
                  class="mb-2 elevation-0"
                  v-model="idAnuncioPesquisa"
                  v-mask="'#######'"
                  append-icon="search"
                  label="ID"
                  @keyup.enter="limpaFiltro"
                  @click:append="limpaFiltro"
                  autocomplete="disabled"
                  :name="Math.random()"
                  :disabled="loading || loadingExp || loadingImp"
                ></v-text-field>
              </v-flex>
              <v-flex xs6 md2>
                <v-text-field
                  background-color="white"
                  class="mb-2 elevation-0"
                  v-model="identificacaoPesquisa"
                  v-mask="'##############'"
                  append-icon="search"
                  label="Identificação"
                  @keyup.enter="limpaFiltro"
                  @click:append="limpaFiltro"
                  autocomplete="disabled"
                  :name="Math.random()"
                  :disabled="loading || loadingExp || loadingImp"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md2>
                <v-text-field
                  background-color="white"
                  class="mb-2 elevation-0"
                  v-model="nomePesquisa"
                  append-icon="search"
                  label="Nome"
                  @keyup.enter="limpaFiltro"
                  @click:append="limpaFiltro"
                  autocomplete="disabled"
                  :name="Math.random()"
                  :disabled="loading || loadingExp || loadingImp"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>

          <v-data-table
            mobile-breakpoint="0"
            :headers="headers"
            :items="registros"
            :options.sync="options"
            :no-data-text="mensagemPesquisa"
            :footer-props="rodape"
            :disable-sort="carregando"
            :disable-pagination="carregando"
            :loading="loading"
            loading-text="Carregando..."
            :expanded="expanded"
          >
            <template v-slot:header.vlrPublic="{ header }">
              <span title="Soma pelo filtro Período de Publicação.">{{ header.text }}</span>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td></td>
              <td :colspan="headers.length - 1">{{ item.textoAnuncio }}</td>
            </template>
            <template v-slot:[getItemActions()]="{ item }">
              <router-link :to="'/comercial/anuncio/' + item.id" style="text-decoration: none" target="_blank" v-if="direitoEditar">
                <v-icon color="blue" title="Editar">edit</v-icon>
              </router-link>
              <v-icon v-if="direitoAnunciante" color="orange" title="Anunciante" @click="visualizarAnunciante(item)">account_circle</v-icon>
              <v-icon v-if="direitoLog" color="blue" title="Visualizar Log" @click="visualizarLogAnuncio(item)">info</v-icon>
              <v-icon
                v-if="direitoNegociacao"
                :color="item.emNegociacao ? 'red' : 'blue'"
                :title="item.emNegociacao ? 'Liberar Cobrança' : 'Colocar em Negociação'"
                @click="ajustaEmNegociacao(item)"
                >thumb_up_alt</v-icon
              >
              <v-icon
                v-if="direitoPublicacao"
                :color="item.bloquearPublicacao ? 'red' : 'blue'"
                :title="item.bloquearPublicacao ? 'Liberar Publicação' : 'Bloquear Publicação'"
                @click="ajustaBloquearPublicacao(item)"
                >picture_in_picture</v-icon
              >
              <v-icon v-if="direitoParcelas" color="blue lighten-2" title="Abrir no Financeiro" @click="visualizarParcelas(item)">add_circle_outline</v-icon>
            </template>

            <template v-slot:[getItemDtCriacao()]="{ item }">{{ formatDateTime(item.dtCriacao) }}</template>
            <template v-slot:[getItemVlrLiquido()]="{ item }">{{ item.vlrLiquido != null ? formatCurrency(item.vlrLiquido) : "" }}</template>
            <template v-slot:[getItemVlrPublic()]="{ item }">{{ item.vlrPublic != null ? formatCurrency(item.vlrPublic) : "" }}</template>
            <template v-slot:[getItemStatus()]="{ item }">
              <span :class="item.status == 'CORRENTE' ? 'blue--text' : item.status == 'CANCELADO' || item.status == 'EXPIRADO' ? 'red--text' : ''">{{ item.status }}</span>
            </template>
            <template v-slot:[getItemDtInicial()]="{ item }">{{ formatDate(item.dtInicial) }}</template>
            <template v-slot:[getItemDtFinal()]="{ item }">{{ formatDate(item.dtFinal) }}</template>
            <template v-slot:[getItemTextoAnuncio()]="{ item }"
              ><v-btn v-if="item.textoAnuncio" x-small text elevation="0" outlined @click="clicked(item)">{{
                item.textoAnuncio && item.textoAnuncio.length > 10 ? item.textoAnuncio.substring(0, 10) + "..." : item.textoAnuncio
              }}</v-btn></template
            >

            <template v-slot:[getItemActions2()]="{ item }">
              <v-icon v-if="item.id && direitoPI" color="blue" title="Pedido de Inserção" @click="piContrato(item, false)">print</v-icon>
              <v-icon v-if="item.id && direitoPI" color="blue lighten-2" title="PI por Email" @click="piContrato(item, true)">email</v-icon>
            </template>
          </v-data-table>
          <div align="right">
            <v-btn-toggle v-model="calcular" group>
              <v-btn x-small text value="sim" @click="calcularTotal">
                Calcular
              </v-btn>
            </v-btn-toggle>
          </div>
          <div class="text-xs-center pt-2 marg-bt">
            <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 15" circle></v-pagination>
          </div>
        </div>
        <v-dialog v-model="dialogLogAlteracao" max-width="1200px">
          <v-card>
            <LogAlteracao :idAnuncio="idAnuncio" :dialogLogAlteracao.sync="dialogLogAlteracao" />
          </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
        <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
      </v-card>
    </v-app>
  </div>
</template>

<script>
import LogAlteracao from "@/views/components/LogAlteracao.vue";
import FiltroAnuncio from "@/views/components/FiltroAnuncio.vue";
import FiltroCliente from "@/views/components/FiltroCliente.vue";
import FiltroParcela from "@/views/components/FiltroParcela.vue";
import FiltroNF from "@/views/components/FiltroNF.vue";
import Pesquisas from "@/views/components/Pesquisas.vue";
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  components: {
    LogAlteracao,
    FiltroCliente,
    FiltroAnuncio,
    FiltroParcela,
    FiltroNF,
    Pesquisas
  },
  data() {
    return {
      headers: [
        { text: "Ações", value: "actions", width: "180" },
        { text: "ID", value: "id" },
        { text: "PI", value: "autorizacao" },
        { text: "Anunciante", value: "cliente" },
        { text: "Cobrado", value: "vlrLiquido", align: "end" },
        {
          text: "Publicado*",
          value: "vlrPublic",
          align: "end"
        },
        { text: "Status", value: "status" },
        { text: "Data Criação", value: "dtCriacao" },
        { text: "Produto", value: "nomeProduto" },
        { text: "Caderno", value: "nomeCaderno" },
        { text: "Formato", value: "nomeFormato" },
        { text: "Início", value: "dtInicial" },
        { text: "Fim", value: "dtFinal" },
        { text: "Título", value: "titulo" },
        { text: "Texto", value: "textoAnuncio" },
        { text: "", value: "actions2", width: "80" }
      ],

      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },

      expanded: [],
      dialogLogAlteracao: false,
      idAnuncio: "",

      tab: null,
      dialogFiltroAvancado: false,
      filtroAnuncio: null,
      inicial: true,

      idSitePrincipal: null,
      mensagem: "",
      snackbar: false,
      snackErro: false,
      direitoCriar: false,
      direitoEditar: false,
      direitoLog: false,
      direitoAnunciante: false,
      direitoPI: false,
      direitoNegociacao: false,
      direitoPublicacao: false,
      direitoExportar: false,
      direitoImprimir: false,
      direitoParcelas: false,
      mensagemPesquisa: "",

      idAnuncioPesquisa: null,
      identificacaoPesquisa: null,
      nomePesquisa: null,

      registros: [],
      registrosPagina: 10,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      carregando: true,
      ordenacao: "",
      ordem: "",
      options: {},
      loader: null,
      loading: false,
      loadingExp: false,
      loadingImp: false,
      calcular: "nao"
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO", "OCULTAR_MENU"]),

    getItemActions() {
      return "item.actions";
    },
    getItemDtCriacao() {
      return "item.dtCriacao";
    },
    getItemVlrLiquido() {
      return "item.vlrLiquido";
    },
    getItemVlrPublic() {
      return "item.vlrPublic";
    },
    getItemStatus() {
      return "item.status";
    },
    getItemDtInicial() {
      return "item.dtInicial";
    },
    getItemDtFinal() {
      return "item.dtFinal";
    },
    getItemTextoAnuncio() {
      return "item.textoAnuncio";
    },
    getItemActions2() {
      return "item.actions2";
    },
    clicked(value) {
      const index = this.expanded.indexOf(value);
      if (index === -1) {
        this.expanded = [];
        this.expanded.push(value);
      } else {
        this.expanded.splice(index, 1);
      }
    },
    filtroAvancadoRetornar() {
      //* Esconde a dialog do filtro avançado
      this.dialogFiltroAvancado = false;
    },
    filtroAvancadoLimpar() {
      //* Limpa os registros da tabela
      this.registros = [];
      this.totalRegistros = 0;
      this.totalPaginas = 0;
      this.mensagemPesquisa = "MONTE SEU FILTRO PARA VISUALIZAR OS ANÚNCIOS...";

      //* Limpa os campos dos filtros
      if (this.$refs.filtroCliente) {
        this.$refs.filtroCliente.limpar();
      }
      if (this.$refs.filtroAnuncio) {
        this.$refs.filtroAnuncio.limpar();
      }
      if (this.$refs.filtroParcela) {
        this.$refs.filtroParcela.limpar();
      }
      if (this.$refs.filtroNF) {
        this.$refs.filtroNF.limpar();
      }
    },
    limpaFiltro() {
      var pgAnterior = this.paginaAtual;
      this.paginaAtual = 1;
      if (pgAnterior == 1) {
        this.filtroAvancadoFiltrar(false, false);
      }
    },
    async filtroAvancadoFiltrar(exportar, imprimir) {
      //* Limpa os registros da tabela
      this.expanded = [];
      this.registros = [];
      this.totalRegistros = 0;
      this.totalPaginas = 0;

      if (this.idAnuncioPesquisa) {
        this.$router.push("/comercial/anuncio/" + this.idAnuncioPesquisa);
      }
      if (this.identificacaoPesquisa && !util.valida_cpf(this.identificacaoPesquisa) && !util.valida_cnpj(this.identificacaoPesquisa)) {
        this.mensagemPesquisa = "Identificação inválida";
        this.snackErro = true;
        return;
      }

      this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

      var l = null;
      if (exportar) {
        this.loader = "loadingExp";
        l = this.loader;
        this[l] = !this[l];
        setTimeout(() => (this[l] = false), 5000);
        this.loader = null;
      }
      if (imprimir) {
        this.loader = "loadingImp";
        l = this.loader;
        this[l] = !this[l];
      }

      if (!imprimir && !exportar) {
        this.carregando = true;
        this.mensagemPesquisa = "";
        this.loading = true;
      } else {
        this.mensagemPesquisa = "MONTE SEU FILTRO PARA VISUALIZAR OS ANÚNCIOS...";
      }

      //* Esconde a dialog do filtro avançado
      this.dialogFiltroAvancado = false;

      //* Filtros avançados do anúncio
      var filtroAnuncioUrl = this.montaFiltro();
      if (!filtroAnuncioUrl.includes("idSite")) {
        filtroAnuncioUrl += "&idSite=" + this.idSitePrincipal;
      }
      if (this.identificacaoPesquisa) {
        if (filtroAnuncioUrl.includes("identificacao")) {
          this.identificacaoPesquisa = "";
        } else {
          filtroAnuncioUrl += "&c.identificacao=" + this.identificacaoPesquisa;
        }
      }
      if (this.nomePesquisa) {
        if (filtroAnuncioUrl.includes("&c.nome=")) {
          this.nomePesquisa = "";
        } else {
          filtroAnuncioUrl += "&c.nome=" + this.nomePesquisa;
          filtroAnuncioUrl += "&c.nomeFantasia=" + this.nomePesquisa;
        }
      }

      var camposAnuncio = "";
      if (exportar) {
        if (this.$refs.pesquisas) {
          //* Carrega os filtros da nota fiscal
          camposAnuncio = this.$refs.pesquisas.filtroUrlCampos();
        }
      }

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "com/anuncio?" +
            filtroAnuncioUrl +
            "&campos=" +
            camposAnuncio +
            "&pagina=" +
            this.paginaAtual +
            "&tamanho=" +
            this.registrosPagina +
            // "&filtro=" +
            // this.filtroPesquisa +
            "&ordenacao=" +
            this.ordenacao +
            "&ordem=" +
            this.ordem +
            (exportar ? "&exportar=1" : "") +
            (imprimir ? "&imprimir=1" : "") +
            (this.calcular == "sim" ? "&calcular=1" : "")
        );

        if (imprimir) {
          util.imprimir(response.data);
        } else if (!exportar) {
          //seta as variaveis com o retorno
          this.registros = response.data.data;
          this.totalRegistros = response.data.total;
          var valorTotal = response.data.valorTotal;
          var valorTotalPublic = response.data.valorTotalPublic;
          this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
          this.rodape.pageText =
            this.totalRegistros +
            " itens" +
            (valorTotal ? " = " + util.formatCurrency(valorTotal, this.$store.state.usuario.site_pais, true) : "") +
            (valorTotalPublic ? " | Publicado: " + util.formatCurrency(valorTotalPublic, this.$store.state.usuario.site_pais, true) : "");

          //* Fecha a mensagem de carregando
          this.carregando = false;

          //se não achar registro seta a mensagem senão deixa em branco
          this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
        }
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else if (e.response && e.response.data.length > 0) {
          this.mensagemPesquisa = "ERRO: " + e.response.data[0].mensagem;
          this.mensagem = "ERRO: " + e.response.data[0].mensagem;
          this.snackErro = true;
        } else {
          this.mensagemPesquisa = "Erro ao buscar dados: " + e;
          this.mensagem = "Erro ao buscar dados: " + e;
          this.snackErro = true;
        }
      } finally {
        this.loading = false;
        if (imprimir) {
          this[l] = false;
          this.loader = null;
        }
      }
    },

    montaFiltro() {
      var filtroAnuncioUrl = "";
      try {
        //* Carrega os filtros do cliente
        if (this.$refs.filtroCliente) {
          filtroAnuncioUrl = this.$refs.filtroCliente.filtroUrlParametros(this.idSitePrincipal);
        }

        if (this.$refs.filtroAnuncio) {
          //* Carrega os filtros do anúncio
          filtroAnuncioUrl += this.$refs.filtroAnuncio.filtroUrlParametros(this.idSitePrincipal);
        }

        if (this.$refs.filtroParcela) {
          //* Carrega os filtros da parcela
          filtroAnuncioUrl += this.$refs.filtroParcela.filtroUrlParametros(this.idSitePrincipal);
        }

        if (this.$refs.filtroNF) {
          //* Carrega os filtros da nota fiscal
          filtroAnuncioUrl += this.$refs.filtroNF.filtroUrlParametros();
        }
      } catch {
        filtroAnuncioUrl = "";
      }
      return filtroAnuncioUrl;
    },

    async calcularTotal() {
      //* Filtros avançados da assinatura
      var filtroAssinaturaUrl = this.montaFiltro();

      //* Consulta os dados
      const response = await api.get(
        process.env.VUE_APP_URL_WEB_API + "com/anuncio/total?" + filtroAssinaturaUrl + "&pagina=" + this.paginaAtual + "&tamanho=" + this.registrosPagina
      );

      //* Carrega os dados de retorno
      var valorTotal = response.data.valorTotal;
      var valorTotalPublic = response.data.valorTotalPublic;
      this.rodape.pageText =
        this.totalRegistros +
        " itens " +
        (valorTotal ? " = " + util.formatCurrency(valorTotal, this.$store.state.usuario.site_pais, true) : "") +
        (valorTotalPublic ? " | Publicado: " + util.formatCurrency(valorTotalPublic, this.$store.state.usuario.site_pais, true) : "");
    },

    visualizarLogAnuncio(item) {
      this.idAnuncio = item.id + "";
      this.dialogLogAlteracao = true;
    },
    async visualizarAnunciante(item) {
      window.open("/comercial/anunciante/" + item.idCliente, "_blank");
    },
    visualizarParcelas(item) {
      window.open("/financeiro/parcelas/receber?idAnuncio=" + item.id, "_blank");
    },

    closeLogAlteracao() {
      this.dialogLogAlteracao = false;
      this.idAnuncio = "";
    },

    async piContrato(item, email) {
      try {
        //* Busca/gera o número do PI

        const retornoPI = await api.post(process.env.VUE_APP_URL_WEB_API + "com/anuncio/pi/" + item.id + "?idSite=" + this.idSitePrincipal);
        if (!retornoPI.data.numeroPI) {
          this.mensagem = "Geração automática de número do PI não habilitada. Edite o anúncio e informe manualmente.";
          this.snackErro = true;
          return;
        }

        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "com/anuncio/pi/" + item.id + (email ? "?email=1" : ""));
        if (email) {
          this.mensagem = "PI direcionado para envio por e-mail";
          this.snackbar = true;
        } else {
          util.imprimir(response.data);
        }
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },
    async ajustaEmNegociacao(item) {
      try {
        await api.put(process.env.VUE_APP_URL_WEB_API + "com/anuncio/negociacao/" + item.id, { emNegociacao: item.emNegociacao ? 0 : 1 });
        this.filtroAvancadoFiltrar(false, false);
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },
    async ajustaBloquearPublicacao(item) {
      if (item.bloquearPublicacao && util.parseDateUS(item.dtFinal) < new Date()) {
        this.mensagem = "Anúncio " + item.id + " EXPIRADO";
        this.snackErro = true;
      } else {
        try {
          await api.put(process.env.VUE_APP_URL_WEB_API + "com/anuncio/publicacao/" + item.id, { bloquearPublicacao: item.bloquearPublicacao ? 0 : 1 });
          this.filtroAvancadoFiltrar(false, false);
        } catch (e) {
          this.mensagem =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
          this.snackErro = true;
        }
      }
    },

    formatDate(date) {
      return util.formatDate(date);
    },
    formatDateTime(datetime) {
      return util.formatDateTime(datetime);
    },
    formatCurrency(valor) {
      return util.formatCurrency(valor);
    }
  },
  beforeMount() {
    this.OCULTAR_MENU();
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "COMERCIAL_ANÚNCIOS_ACESSO"]);

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;

      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_ANÚNCIOS_CRIAR") {
        this.direitoCriar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_ANÚNCIOS_EDITAR") {
        this.direitoEditar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_ANÚNCIOS_LOG") {
        this.direitoLog = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_ANÚNCIOS_EMITIR_PI") {
        this.direitoPI = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_ANUNCIANTES_EDITAR") {
        this.direitoAnunciante = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_ANÚNCIOS_LIBERAR_NEGOCIAÇÃO") {
        this.direitoNegociacao = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_ANÚNCIOS_LIBERAR_PUBLICAÇÃO") {
        this.direitoPublicacao = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_ANÚNCIOS_EXPORTAR") {
        this.direitoExportar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_ANÚNCIOS_IMPRIMIR") {
        this.direitoImprimir = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_RECEBER_ACESSO") {
        this.direitoParcelas = true;
      }
    });
  },

  watch: {
    paginaAtual() {
      this.filtroAvancadoFiltrar(false, false);
    },
    dialogLogAlteracao(val) {
      val || this.closeLogAlteracao();
    },
    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }
        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }
        if (!this.inicial) {
          this.filtroAvancadoFiltrar(false, false);
        } else {
          this.mensagemPesquisa = "MONTE SEU FILTRO PARA VISUALIZAR OS ANÚNCIOS...";
          this.inicial = false;
        }
      }
    }
  }
};
</script>
