<template>
  <v-card>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">mdi-account</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Log Parcelas</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="retornar">
        <v-icon color="white">cancel</v-icon>
      </v-btn>
    </v-toolbar>
    <div class="px-6 mt-4">
      <v-layout wrap>
        <v-flex xs12 md3>
          <v-textarea v-if="nNrosAnteriores" v-model="nNrosAnteriores" label="NN Anteriores" maxlength="1000" rows="2" disabled></v-textarea>
        </v-flex>
        <v-flex xs12 md3>
          <v-textarea v-if="lotesAnteriores" v-model="lotesAnteriores" label="Lotes Anteriores" maxlength="1000" rows="2" disabled></v-textarea>
        </v-flex>
        <v-flex xs12 offset-md-1 md5>
          <v-text-field
            background-color="white"
            class="mt-2 mb-2 elevation-0"
            v-model="filtroPesquisa"
            append-icon="search"
            label="Digite para pesquisar..."
            placeholder="Pesquisa pelo nossoNumero ou codigoRetorno"
            hide-details
            @keyup.enter="limpaFiltro"
            @click:append="limpaFiltro"
          ></v-text-field>
        </v-flex>
      </v-layout>

      <v-data-table mobile-breakpoint="0" :headers="headers" :items="registros" :options.sync="options" :no-data-text="mensagemPesquisa" :footer-props="rodape">
        <template v-slot:[getItemIdParcela()]="{ item }">
          <v-icon class="mr-1" small v-if="direitoParcelas && item.idParcela" color="blue lighten-2" title="Ações" @click="visualizarParcelas(item)">add_circle_outline</v-icon
          >{{ item.idParcela }}
        </template>

        <template v-slot:[getItemDtRegistro()]="{ item }">
          <span>
            {{ formatDateTime(item.dtRegistro) }}
          </span>
        </template>
        <template v-slot:[getItemDtRetorno()]="{ item }">
          <span>
            {{ formatDate(item.dtRetorno) }}
          </span>
        </template>
        <template v-slot:[getItemValor()]="{ item }">
          <span>
            {{ formatCurrency(item.valor) }}
          </span>
        </template>
      </v-data-table>
      <div class="text-xs-center pt-2 marg-bt">
        <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 7" circle></v-pagination>
      </div>

      <v-divider></v-divider>

      <v-card-actions class="card-btn">
        <v-spacer></v-spacer>

        <v-btn class="sub-btn" color="blue darken-1" @click="retornar">Retornar</v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  props: {
    idParcela: String,
    idLoteFaturamento: String
  },

  data() {
    return {
      headers: [
        { text: "", value: "" },
        { text: "ID", value: "id" },
        { text: "Parcela", value: "idParcela", width: "100" },
        { text: "Cliente", value: "cliente" },
        { text: "Usuário", value: "usuario" },
        { text: "Data Registro", value: "dtRegistro" },
        { text: "Nosso Número", value: "nossoNumero" },
        { text: "Valor", value: "valor", align: "end" },
        { text: "Retorno", value: "codRetorno" },
        { text: "Descrição", value: "descricao" },
        { text: "Data Retorno", value: "dtRetorno" }
      ],
      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },

      direitoParcelas: false,
      nNrosAnteriores: "",
      lotesAnteriores: "",
      mensagemPesquisa: "",
      filtroPesquisa: "",
      registros: [],
      registrosPagina: 10,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      carregando: true,
      ordenacao: "",
      ordem: "",
      options: {}
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    getItemIdParcela() {
      return "item.idParcela";
    },
    getItemDtRegistro() {
      return "item.dtRegistro";
    },
    getItemDtRetorno() {
      return "item.dtRetorno";
    },
    getItemValor() {
      return "item.valor";
    },
    limpaFiltro() {
      this.paginaAtual = 1;
      this.pesquisaDados();
    },
    async pesquisaDados() {
      //* Verifica se o usuário logado possui direitos
      this.$store.state.usuario.direitos.forEach(element => {
        var direito = element.modulo + "_" + element.grupo + "_" + element.direito;

        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_RECEBER_ACESSO" || direito == "FINANCEIRO_PARCELAS_PAGAR_ACESSO") {
          this.direitoParcelas = true;
        }
      });

      this.registros = [];
      //sinaliza que está carregando
      this.carregando = true;
      this.mensagemPesquisa = "Carregando...";

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "fin/log-parcela?idSite=" +
            this.$store.state.usuario.idSitePrincipal +
            "&pagina=" +
            this.paginaAtual +
            "&tamanho=" +
            this.registrosPagina +
            "&filtro=" +
            this.filtroPesquisa +
            (this.idParcela ? "&idParcela=" + this.idParcela : "") +
            (this.idLoteFaturamento ? "&idLoteFaturamento=" + this.idLoteFaturamento : "") +
            "&ordenacao=" +
            this.ordenacao +
            "&ordem=" +
            this.ordem
        );

        //seta as variaveis com o retorno
        this.registros = response.data.data;

        this.totalRegistros = response.data.total;
        this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
        this.rodape.pageText = this.totalRegistros + " itens";
        //fecha o loading
        this.carregando = false;
        //se não achar registro seta a mensagem senão deixa em branco
        this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";

        if (this.idParcela) {
          const responseNN = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/parcela/nNrosAnteriores/" + this.idParcela);
          if (responseNN.data) {
            this.nNrosAnteriores = responseNN.data.nNrosAnteriores;
            this.lotesAnteriores = responseNN.data.lotesAnteriores;
            if (this.nNrosAnteriores) {
              this.nNrosAnteriores = this.nNrosAnteriores.replace("NaN", "");
              this.nNrosAnteriores = this.nNrosAnteriores.replace("undefined", "");
            }
            if (this.lotesAnteriores) {
              this.lotesAnteriores = this.lotesAnteriores.replace("NaN", "");
              this.lotesAnteriores = this.lotesAnteriores.replace("undefined", "");
            }
          }
        }
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else {
          this.mensagemPesquisa = "Erro ao buscar dados: " + e;
        }
      }
    },
    retornar() {
      this.$emit("update:dialogLogBanco", false);
    },
    visualizarParcelas(item) {
      window.open("/financeiro/parcelas/" + (item.receber ? "receber" : "pagar") + "?idParcela=" + item.idParcela, "_blank");
    },

    formatDate(date) {
      return util.formatDate(date);
    },
    formatDateTime(date) {
      return util.formatDateTime(date);
    },
    formatCurrency(valor) {
      return util.formatCurrency(valor);
    }
  },
  beforeMount() {
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_LOG_PARCELAS_ACESSO"]);
  },

  watch: {
    idParcela() {
      this.paginaAtual = 1;
      this.pesquisaDados();
    },
    idLoteFaturamento() {
      this.paginaAtual = 1;
      this.pesquisaDados();
    },
    paginaAtual() {
      this.pesquisaDados();
    },
    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }
        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }
        this.pesquisaDados();
      }
    }
  }
};
</script>
