<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">adjust</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Regra de Ajuste de Preços</v-toolbar-title>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12 md12 v-if="siteGrupo.length > 0">
            <v-autocomplete
              v-model="regraAjuste.idSite"
              :disabled="isUpdating"
              :items="siteGrupo"
              :filter="filtrarObjetos"
              label="Restrita ao Site"
              item-text="nome"
              item-value="id"
              :rules="[validacao.idSite == false || validacao.idSite]"
              no-data-text
              clearable
              @change="validacao.idSite = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md12>
            <v-text-field
              autofocus
              v-model="regraAjuste.nome"
              label="Nome da Regra de Ajuste"
              maxlength="80"
              hint="Obrigatório"
              :rules="[
                validacao.nome == false || validacao.nome,
                value => !!value || 'Obrigatório.',
                value => valida_nome(value) || 'Nome inválido',
                value => value.length > 2 || 'Preencha o nome da regra de ajuste de preços'
              ]"
              :disabled="isUpdating || (id != '' && !direitoAvancado)"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="
                regraAjuste.nome = pLetra(regraAjuste.nome);
                validacao.nome = false;
              "
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md6>
            <v-autocomplete
              v-model="regraAjuste.tipo"
              :disabled="isUpdating || this.itens.length > 0"
              :items="tipos"
              :filter="filtrarObjetos"
              hint="Obrigatório"
              :rules="[validacao.tipo == false || validacao.tipo, value => !!value || 'Obrigatório.']"
              label="Selecione o Tipo da Regra de Ajuste"
              item-text="nome"
              item-value="id"
              no-data-text
              @change="validacao.tipo = false"
            >
              <template v-slot:selection="data">
                <v-avatar size="24" class="accent white--text" left>
                  {{ data.item.id }}
                </v-avatar>
                <span class="ml-2">{{ data.item.nome }}</span>
              </template>
              <template v-slot:item="data">
                <v-list-item-avatar class="accent white--text">
                  {{ data.item.id }}
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.nome"></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-flex>

          <v-flex xs12 md3>
            <v-autocomplete
              v-model="regraAjuste.origem"
              :items="origens"
              hint="Obrigatório"
              :filter="filtrarObjetos"
              :rules="[validacao.origem == false || validacao.origem]"
              label="Origem"
              item-text="nome"
              item-value="id"
              no-data-text
              @change="validacao.origem = false"
              :disabled="isUpdating || (id != '' && !direitoAvancado)"
            >
            </v-autocomplete>
          </v-flex>

          <v-flex xs12 md3>
            <v-autocomplete
              v-model="regraAjuste.disponivel"
              :items="disponiveis"
              :rules="[validacao.disponivel == false || validacao.disponivel, value => value != null || 'Obrigatório.']"
              :filter="filtrarObjetos"
              label="Disponibilidade"
              item-text="nome"
              item-value="id"
              no-data-text
              @change="
                limpaDias();
                validacao.disponivel = false;
              "
              :disabled="isUpdating || (id != '' && !direitoAvancado)"
            >
              <template v-slot:item="data">
                <v-list-item-content style="width:100px;">
                  <v-list-item-title v-html="data.item.nome"></v-list-item-title>
                  <v-list-item-subtitle v-html="data.item.descricao"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-flex>

          <v-flex xs12 md6>
            <v-radio-group v-if="regraAjuste.disponivel != 2" v-model="regraAjuste.prazoP" @change="validaPrazo">
              <template v-slot:label>
                <strong>Vencimento da Primeira Parcela</strong>
              </template>
              <v-radio value="null">
                <template v-slot:label>
                  <span class="primary--text">no 'Melhor Dia de Vencimento' do Cliente</span>
                </template>
              </v-radio>
              <v-radio value="99">
                <template v-slot:label>
                  <span class="primary--text">
                    no 'Melhor Dia de Vencimento' do Cliente no mês seguinte
                  </span>
                </template>
              </v-radio>
              <v-radio value="0">
                <template v-slot:label>
                  <span class="primary--text">imediato</span>
                </template>
              </v-radio>
              <v-radio value="1">
                <template v-slot:label>
                  <span class="primary--text">em</span>
                </template>
              </v-radio>
            </v-radio-group>
            <v-text-field
              v-if="mostraPrazo && regraAjuste.disponivel != 2"
              v-model="regraAjuste.prazoPrimeira"
              label="Nº de Dias"
              v-mask="'##'"
              hint="Obrigatório"
              :rules="[validacao.prazoPrimeira == false || validacao.prazoPrimeira, value => (value > 0 && value < 91) || 'Máximo 90 dias']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.prazoPrimeira = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md6>
            <v-radio-group v-if="regraAjuste.disponivel != 2" v-model="regraAjuste.inter" @change="validaIntervalo">
              <template v-slot:label>
                <strong>Vencimento das Próximas Parcelas</strong>
              </template>
              <v-radio value="null">
                <template v-slot:label>
                  <span class="primary--text">sempre no 'Melhor Dia de Vencimento' do Cliente</span>
                </template>
              </v-radio>
              <v-radio value="0">
                <template v-slot:label>
                  <span class="primary--text">sempre no mesmo dia do mês da primeira parcela</span>
                </template>
              </v-radio>
              <v-radio value="1">
                <template v-slot:label>
                  <span class="primary--text">a cada</span>
                </template>
              </v-radio>
            </v-radio-group>
            <v-text-field
              v-if="mostraIntervalo && regraAjuste.disponivel != 2"
              v-model="regraAjuste.intervalo"
              label="Nº de Dias"
              v-mask="'##'"
              hint="Obrigatório"
              :rules="[validacao.intervalo == false || validacao.intervalo, value => (value > 0 && value < 31) || 'Máximo 30 dias']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.intervalo = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md12>
            <v-textarea
              v-model="regraAjuste.descricao"
              label="Descrição da Regra de Ajuste"
              maxlength="255"
              counter="255"
              rows="3"
              hint="Obrigatório"
              :rules="[
                validacao.descricao == false || validacao.descricao,
                value => !!value || 'Obrigatório.',
                value => (value != null && value.length > 9 && value.length < 256) || 'Descrição deve ter entre 10 e 255 caracteres'
              ]"
              color="blue-grey lighten-2"
              @change="validacao.descricao = false"
            ></v-textarea>
          </v-flex>

          <v-flex xs12 md4>
            <v-switch
              v-model="regraAjuste.composto"
              :label="`${regraAjuste.composto == 1 ? 'Aplicar Ajuste Composto' : 'Aplicar Ajuste Simples'}`"
              :disabled="isUpdating || (id != '' && !direitoAvancado)"
            ></v-switch>
          </v-flex>

          <v-flex xs12 md4>
            <v-switch
              v-model="regraAjuste.nivelar"
              :label="`${regraAjuste.nivelar == 1 ? 'Nivelar valores das parcelas após ajuste' : 'Não nivelar valores das parcelas'}`"
              :disabled="isUpdating || (id != '' && !direitoAvancado)"
            ></v-switch>
          </v-flex>

          <v-flex xs12 md4>
            <v-switch
              v-model="regraAjuste.situacao"
              :label="`${regraAjuste.situacao == 1 ? 'Ativa' : 'Inativa'}`"
              :disabled="isUpdating || (id != '' && regraAjuste.situacao == 1 && !direitoAvancado)"
            ></v-switch>
          </v-flex>
        </v-layout>

        <v-layout wrap>
          <v-expansion-panels v-model="painel" focusable>
            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.regrasAjuste ? 'red--text' : ''">
                <strong>Itens da Regra de Ajuste</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-toolbar>
                      <v-spacer></v-spacer>
                      <v-btn v-if="regraAjuste.tipo" color="success" fab small>
                        <v-icon color="white" title="Incluir" @click="incluirItem">add</v-icon>
                      </v-btn>
                      <v-dialog persistent v-model="dialogItem" max-width="600px">
                        <v-card>
                          <v-form ref="formItem" v-model="itemValid" lazy-validation>
                            <v-toolbar>
                              <span class="headline">Item da Regra de Ajuste</span>
                              <v-spacer></v-spacer>
                              <v-btn icon @click="closeItem">
                                <v-icon color="darken-1">cancel</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-container grid-list-md>
                                <v-layout wrap>
                                  <v-flex xs12 md12>
                                    <v-autocomplete
                                      v-model="formaPgto"
                                      autofocus
                                      :items="formasPagamento"
                                      :filter="filtrarObjetos"
                                      label="Forma de Pagamento"
                                      item-text="nome"
                                      item-value="id"
                                      hint="Obrigatório"
                                      :rules="[value => (!!value && !!value.id) || 'Obrigatório.']"
                                      no-data-text
                                      :disabled="itemItem.id != null"
                                      return-object
                                    ></v-autocomplete>
                                  </v-flex>
                                  <v-flex xs12 md6>
                                    <v-text-field
                                      v-model="itemItem.de"
                                      label="Parcela Inicial"
                                      v-mask="'##'"
                                      hint="Obrigatório"
                                      :rules="[value => (value > 0 && value <= 30) || 'Máximo parcela 30']"
                                      color="blue-grey lighten-2"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      :readonly="itemItem.id != null"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12 md6>
                                    <v-text-field
                                      v-model="itemItem.ate"
                                      label="Parcela Final"
                                      v-mask="'##'"
                                      hint="Obrigatório"
                                      :rules="[
                                        validacao.ate == false || validacao.ate,
                                        value => (value > 0 && value <= 30) || 'Máximo parcela 30',
                                        value => parseInt(value) >= parseInt(itemItem.de) || 'Parcela Final não pode ser menor que Parcela Inicial'
                                      ]"
                                      color="blue-grey lighten-2"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      :readonly="itemItem.id != null"
                                    ></v-text-field>
                                  </v-flex>

                                  <v-flex xs2 md1>
                                    <v-icon
                                      :color="itemItem.isAcrescimo ? 'blue' : 'red'"
                                      title="Alterar"
                                      @click="
                                        itemItem.isAcrescimo = !itemItem.isAcrescimo;
                                        calculaVlrFinal();
                                      "
                                      :disabled="itemItem.id != null || regraAjuste.tipo == '='"
                                    >
                                      {{ itemItem.isAcrescimo ? "add_circle" : "remove_circle" }}
                                    </v-icon>
                                  </v-flex>
                                  <v-flex xs6 md4>
                                    <v-currency-field
                                      :locale="`pt-${$store.state.usuario.site_pais}`"
                                      v-model.number="itemItem.descAcr"
                                      :label="regraAjuste.tipo == '=' ? 'Valor igual a' : itemItem.isAcrescimo ? 'Acréscimo' : 'Desconto'"
                                      :decimalLength="regraAjuste.tipo == '%' ? 4 : 2"
                                      :allowNegative="false"
                                      :max="99999"
                                      color="blue-grey lighten-2"
                                      :readonly="itemItem.id != null"
                                      :prefix="regraAjuste.tipo"
                                      @change="calculaVlrFinal"
                                    ></v-currency-field>
                                  </v-flex>
                                  <v-flex xs12 offset-md4 md8>
                                    <h4 class="overline font-weight-black font-italic">
                                      Simulador
                                    </h4>
                                    <v-divider></v-divider>
                                  </v-flex>

                                  <v-flex xs6 offset-md4 md4 v-if="regraAjuste.tipo == '%'">
                                    <v-currency-field
                                      :locale="`pt-${$store.state.usuario.site_pais}`"
                                      v-model.number="vlrTabela"
                                      :hint="planos.length == 1 ? planos[0].plano : ''"
                                      persistent-hint
                                      label="Valor Tabela"
                                      :decimalLength="2"
                                      :allowNegative="false"
                                      :max="99999"
                                      color="blue-grey lighten-2"
                                      :readonly="itemItem.id != null"
                                      :prefix="$"
                                    ></v-currency-field>
                                  </v-flex>
                                  <v-flex xs6 offset-md4 md4 v-if="regraAjuste.tipo == '%' && vlrTabela">
                                    <v-currency-field
                                      :locale="`pt-${$store.state.usuario.site_pais}`"
                                      v-model.number="vlrFinal"
                                      label="Valor Final"
                                      :decimalLength="2"
                                      :allowNegative="false"
                                      :max="99999"
                                      color="blue-grey lighten-2"
                                      :readonly="itemItem.id != null"
                                      :prefix="$"
                                      @change="calculaPerc"
                                    ></v-currency-field>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" @click="closeItem">Retornar</v-btn>
                              <v-btn v-if="itemItem.id == null" color="blue darken-1" @click="saveItem">Atualizar</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="headerItens"
                      :items="itens"
                      no-data-text
                      no-results-text
                      :footer-props="{
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageOptions: [10, 25, 50]
                      }"
                    >
                      <template v-slot:item.actions="{ item }">
                        <v-icon class="mr-2" color="blue lighten-2" title="Editar" @click="editarItem(item)">{{ item.id ? "loupe" : "edit" }}</v-icon>
                        <v-icon v-if="id == '' || direitoAvancado" color="error" title="Remover" @click="removerItem(item)">delete</v-icon>
                      </template>

                      <template v-slot:item.formaPagamentoSituacao="{ item }">
                        <span v-html="item.formaPagamentoSituacao == null ? '' : item.formaPagamentoSituacao == 1 ? 'Ativa' : '<strong>Inativa</strong>'"></span>
                      </template>

                      <template v-slot:item.valor="{ item }">
                        {{
                          (item.valor > 0 ? (regraAjuste.tipo != "=" ? "+" : "=") : "") +
                            formatCurrency4(item.valor, regraAjuste.tipo) +
                            (regraAjuste.tipo != "=" ? " (" + regraAjuste.tipo + ")" : "")
                        }}
                      </template>
                    </v-data-table>
                    <v-snackbar v-model="snackItens" :timeout="2000" relative bottom left>{{ msgItens }}</v-snackbar>
                    <v-alert v-if="validacao.regrasAjuste" :value="true" color="error" icon="priority_high" outlined>{{ validacao.regrasAjuste }}</v-alert>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating" v-if="$route.path.includes('assinaturas')">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Planos Associados</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="headerPlanos"
                      :items="planos"
                      no-data-text
                      no-results-text
                      :footer-props="{
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageOptions: [10, 25, 50]
                      }"
                    >
                      <template v-slot:item.actions="{ item }">
                        <v-icon v-if="direitoPlano" class="mr-2" color="blue lighten-2" title="Visualizar" @click="visualizarPlano(item)">loupe</v-icon>
                      </template>

                      <template v-slot:item.automatica="{ item }">
                        {{
                          item.automatica == 1
                            ? "Captação (Automático)"
                            : item.automatica == 2
                            ? "Renovação (Automático)"
                            : item.automatica == 3
                            ? "Captação e Renovação (Automático)"
                            : "Usuário (Manual)"
                        }}
                      </template>
                    </v-data-table>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">
              {{ validacao.mensagemErro }}
            </v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn :to="($route.path.includes('assinaturas') ? '/assinaturas' : '/comercial') + '/regras-ajuste'" id="btn-rsn" color="orange lighten-2 white" style="color:white">
        <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar
      </v-btn>
      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="blue white" style="color:white" id="btn-rsn" depressed @click="salvar('false')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar
      </v-btn>
      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="orange white" style="color:white" id="btn-rsn" outlined @click="salvar('retornar')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>
      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="blue white" style="color:white" id="btn-rsn" outlined @click="salvar('true')" v-if="direitoCriar">
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Novo
      </v-btn>
    </v-card-actions>
    <v-dialog v-model="dialogConfirm" max-width="290">
      <v-card>
        <v-toolbar>
          <span class="headline">Confirmação</span>
        </v-toolbar>
        <v-card-text>{{ msgConfirm }}</v-card-text>
        <v-card-actions>
          <v-btn color="red darken-1" text @click="naoConfirmado">Não</v-btn>
          <v-btn color="green darken-1" text @click="confirmado">Sim</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      regraAjuste: {
        id: "",
        idSite: null,
        nome: "",
        tipo: null,
        prazoP: "",
        prazoPrimeira: null,
        inter: "",
        intervalo: null,
        descricao: null,
        origem: null,
        disponivel: null,
        composto: 0,
        nivelar: 0,
        situacao: 1
      },
      validacao: {
        id: false,
        idSite: false,
        nome: false,
        tipo: false,
        prazoPrimeira: false,
        intervalo: false,
        descricao: false,
        origem: false,
        disponivel: false,
        composto: false,
        nivelar: false,
        situacao: false,
        ate: false,
        regrasAjuste: false,
        mensagemErro: false
      },

      vlrTabela: 0,
      vlrFinal: 0,
      itens: [],
      siteGrupo: [],
      tipos: [
        { id: "=", nome: "parcela será IGUAL AO VALOR informado" },
        { id: "%", nome: "será aplicado PERCENTUAL a parcela" },
        { id: "$", nome: "será aplicado VALOR a parcela" }
      ],
      origens: [],
      disponiveis: [
        {
          id: 1,
          nome: "Captação",
          descricao: "Disponível apenas na captação/renovação"
        },
        {
          id: 2,
          nome: "Recuperação",
          descricao: "Disponível apenas para recuperação de clientes"
        },
        {
          id: 3,
          nome: "Ambos",
          descricao: "Disponível para captação/renovação e recuperação"
        },
        {
          id: 0,
          nome: "Padrão Captação",
          descricao: "Regra geral - aplicada se não houver regra disponível"
        }
      ],
      formasPagamento: [],
      planos: [],

      mostraIntervalo: false,
      mostraPrazo: false,

      headerItens: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Forma Pagamento", value: "formaPagamento" },
        { text: "Situação Forma Pagto", value: "formaPagamentoSituacao" },
        { text: "De", value: "de" },
        { text: "Até", value: "ate" },
        { text: "Valor", value: "valor" }
      ],
      headerPlanos: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Plano de Assinatura", value: "plano" },
        { text: "Aplicação", value: "automatica" }
      ],

      //* Parâmetros dos modais
      formaPgto: {},
      dialogItem: false,
      itemValid: true,
      itemIndex: -1,
      itemItem: {
        id: null,
        idRegraAjuste: null,
        idFormaPgto: null,
        formaPagamento: null,
        formaPagamentoSituacao: null,
        de: 0,
        ate: 0,
        valor: 0,
        isAcrescimo: false,
        descAcr: 0
      },
      defaultItemItem: {
        id: null,
        idRegraAjuste: null,
        idFormaPgto: null,
        formaPagamento: null,
        formaPagamentoSituacao: null,
        de: 0,
        ate: 0,
        valor: 0,
        isAcrescimo: false,
        descAcr: 0
      },

      id: "",
      idSitePrincipal: null,
      direitoCriar: false,
      direitoPlano: false,
      direitoAvancado: false,

      elementoValid: true,
      painel: [0],
      dialogConfirm: false,
      tipoConfirm: "",
      msgConfirm: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,
      msgItens: "",
      snackItens: false,

      isUpdating: false,
      bloqueiaBotoes: false
    };
  },

  watch: {
    formaPgto(val) {
      this.itemItem.idFormaPgto = val.id;
      this.itemItem.formaPagamento = val.nome;
    },
    dialogItem(val) {
      val || this.closeItem();
    }
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      var erro = false;
      if (this.itens.length == 0) {
        erro = true;
        this.validacao.regrasAjuste = "Você deve incluir pelo menos 1 item na regra";
      }

      if (!this.$refs.formElemento.validate() || erro) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      if (this.regraAjuste.prazoP == "null") {
        this.regraAjuste.prazoPrimeira = null;
      } else if (this.regraAjuste.prazoP == "0") {
        this.regraAjuste.prazoPrimeira = 0;
      } else if (this.regraAjuste.prazoP == "99") {
        this.regraAjuste.prazoPrimeira = 99;
      }
      if (this.regraAjuste.inter == "null") {
        this.regraAjuste.intervalo = null;
      } else if (this.regraAjuste.inter == "0") {
        this.regraAjuste.intervalo = 0;
      }

      //* Prepara os itens da regra de ajuste para envio a base
      var itensRegraCommit = [];
      var itensVal = [];
      var itemDuplicado = "";
      this.itens.forEach(element => {
        var valores = [];
        var de = parseInt(element.de);
        var ate = parseInt(element.ate);
        for (var i = de; i <= ate; i++) {
          valores.push(i);
        }
        valores.forEach(valor => {
          if (itensVal.includes(element.formaPagamento + "|" + valor)) {
            itemDuplicado = element.formaPagamento + "|" + valor;
          } else {
            itensVal.push(element.formaPagamento + "|" + valor);
          }
        });
        itensRegraCommit.push({
          id: element.id,
          idRegraAjuste: this.id,
          idFormaPgto: element.idFormaPgto,
          de: element.de,
          ate: element.ate,
          valor: element.descAcr * (element.isAcrescimo ? 1 : -1)
        });
      });
      if (itemDuplicado) {
        this.mensagem = "Já existe uma configuração ativa para o item [" + itemDuplicado.replace("null", "") + "]";
        this.snackErro = true;
        return;
      }

      this.regraAjuste.regrasAjuste = itensRegraCommit;

      this.isUpdating = true;
      try {
        var retorno = "";
        //se tiver id é um update
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "ass/regra-ajuste/" + this.id, this.regraAjuste);
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "ass/regra-ajuste?idSite=" + this.idSitePrincipal, this.regraAjuste);
          this.id = retorno.data.id;
          this.regraAjuste.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "true") {
          this.$router.push((this.$route.path.includes("assinaturas") ? "/assinaturas" : "/comercial") + "/regra-ajuste");
          this.$router.go((this.$route.path.includes("assinaturas") ? "/assinaturas" : "/comercial") + "/regra-ajuste");
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push((this.$route.path.includes("assinaturas") ? "/assinaturas" : "/comercial") + "/regras-ajuste");
          }, 2000);
        } else {
          this.carregarItens();
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },

    async carregarItens() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/regra-ajuste-item/regraAjuste/" + this.id);
        if (response.data) {
          this.itens = response.data;
          this.itens.forEach(element => {
            element.isAcrescimo = element.valor > 0;
            element.descAcr = Math.abs(element.valor);
          });
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os itens da regra de ajuste";
        this.snackErro = true;
      }
    },

    async carregarFormasPagamento() {
      try {
        //* Busca somente as formas de pagamento pagas (cortesia, compensação, bonificação, crédito, pacote não entram)
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API + "adm/forma-pagamento/lista?idSite=" + this.idSitePrincipal
          //"&pagas=true"//agora é obrigatório ter regra de ajuste em todo plano
        );
        if (response.data) {
          this.formasPagamento = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de formas de pagamento";
        this.snackErro = true;
      }
    },

    async carregarPlanos() {
      try {
        //* Busca os planos de assinatura que foram associados a regra de ajuste
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/plano-regra-ajuste/planos/" + this.id + "?idSite=" + this.idSitePrincipal);
        if (response.data) {
          this.planos = response.data;
          if (this.planos.length == 1) {
            //* Se regra é somente de 1 plano, seta seu preço de tabela para facilitar a configuração
            this.vlrTabela = this.planos[0].valor;
          }
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de planos da regra de ajuste";
        this.snackErro = true;
      }
    },

    // * Métodos para controle dos itens
    incluirItem() {
      this.vlrFinal = 0;
      this.itemItem = Object.assign({}, this.defaultItemItem);
      if (this.regraAjuste.tipo == "=") {
        this.itemItem.isAcrescimo = true;
      }
      this.itemIndex = -1;
      this.dialogItem = true;
      this.formaPgto = {};
    },
    editarItem(item) {
      this.itemIndex = this.itens.indexOf(item);
      this.itemItem = Object.assign({}, item);
      this.formaPgto = { id: item.idFormaPgto, nome: item.formaPagamento };
      if (this.itemItem.isAcrescimo) {
        this.vlrFinal = this.vlrTabela * (1 + this.itemItem.descAcr / 100);
      } else {
        this.vlrFinal = this.vlrTabela * (1 - this.itemItem.descAcr / 100);
      }
      this.dialogItem = true;
    },
    removerItem(item) {
      this.itemItem = Object.assign({}, item);
      this.itemIndex = this.itens.indexOf(item);
      if (this.itemItem.id == null) {
        this.itens.splice(this.itemIndex, 1);
        this.itemItem = Object.assign({}, this.defaultItemItem);
        this.itemIndex = -1;
      } else {
        this.tipoConfirm = "EXCLUIR_ITEM";
        this.msgConfirm = "Tem certeza que deseja excluir este item? (esta ação não pode ser desfeita)";
        this.dialogConfirm = true;
      }
    },
    async removeItem() {
      try {
        await api.delete(process.env.VUE_APP_URL_WEB_API + "ass/regra-ajuste-item/" + this.itemItem.id);
        this.itemItem = Object.assign({}, this.defaultItemItem);
        this.itemIndex = -1;
        this.carregarItens();
        this.msgItens = "Item foi excluído";
        this.snackItens = true;
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.msgItens = "SESSÃO EXPIRADA";
          this.snackItens = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.msgItens = "Verifique os campos indicados em vermelho neste formulário";
          this.snackItens = true;
        } else {
          this.msgItens = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackItens = true;
        }
      }
    },
    closeItem() {
      this.dialogItem = false;
      setTimeout(() => {
        this.itemItem = Object.assign({}, this.defaultItemItem);
        this.itemIndex = -1;
        this.$refs.formItem.resetValidation();
      }, 300);
    },
    saveItem() {
      if (this.$refs.formItem.validate()) {
        if (!this.itemItem.id) {
          if (parseInt(this.itemItem.de) > parseInt(this.itemItem.ate)) {
            this.validacao.ate = "Parcela Final não pode ser menor que Parcela Inicial";
            return;
          }
          if (this.itemItem.idFormaPgto != null) {
            this.itemItem.formaPagamentoSituacao = 1;
          }
          this.itemItem.valor = this.itemItem.descAcr * (this.itemItem.isAcrescimo ? 1 : -1);
        }
        if (this.itemIndex > -1) {
          Object.assign(this.itens[this.itemIndex], this.itemItem);
          this.itens.push({});
          this.itens.splice(this.itens.length - 1, 1);
        } else {
          this.itens.push(this.itemItem);
        }
        this.closeItem();
        this.itemItem = Object.assign({}, this.defaultItemItem);
        this.itemIndex = -1;
        this.$refs.formItem.resetValidation();
        this.validacao.ate = false;
        this.validacao.regrasAjuste = false;
      }
    },

    confirmado() {
      this.dialogConfirm = false;
      if (this.tipoConfirm == "EXCLUIR_ITEM") {
        this.removeItem();
      }
    },
    naoConfirmado() {
      this.dialogConfirm = false;
      if (this.tipoConfirm == "EXCLUIR_ITEM") {
        this.itemItem = Object.assign({}, this.defaultItemItem);
        this.itemIndex = -1;
        this.$refs.formItem.resetValidation();
      }
    },

    removeForma() {
      this.itemItem.idFormaPgto = null;
      this.itemItem.formaPagamento = null;
      this.itemItem.formaPagamentoSituacao = null;
    },

    visualizarPlano(item) {
      window.open("/assinaturas/plano/" + item.idPlano, "_blank");
    },

    validaIntervalo() {
      this.mostraIntervalo = this.regraAjuste.inter == "1";
    },
    validaPrazo() {
      this.mostraPrazo = this.regraAjuste.prazoP == "1";
    },
    limpaDias() {
      if (this.regraAjuste.disponivel == 2) {
        //* Se regra de ajuste é aplicada somente para recuperação de clientes, a parcela já deverá estar criada, então estes prazos não fazem sentido
        this.regraAjuste.prazoPrimeira = null;
        this.regraAjuste.intervalo = null;
        this.regraAjuste.prazoP = "";
        this.regraAjuste.inter = "";
      }
    },
    calculaPerc() {
      this.itemItem.descAcr = Math.abs(((this.vlrFinal / this.vlrTabela) * 100 - 100).toFixed(4));
      this.itemItem.isAcrescimo = this.vlrTabela < this.vlrFinal;
    },
    calculaVlrFinal() {
      if (this.itemItem.isAcrescimo) {
        this.vlrFinal = this.vlrTabela * (1 + this.itemItem.descAcr / 100);
      } else {
        this.vlrFinal = this.vlrTabela * (1 - this.itemItem.descAcr / 100);
      }
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    pLetra(text) {
      return util.pLetra(text);
    },
    formatCurrency4(valor, tipo) {
      if (tipo == "%") {
        return util.formatCurrency4(valor);
      } else {
        return util.formatCurrency(valor);
      }
    },
    valida_nome(text) {
      return util.valida_nome(text);
    }
  },

  async created() {
    this.isUpdating = false;
    this.bloqueiaBotoes = true;
    this.id = this.$route.params.id ? this.$route.params.id : "";
    if (this.$route.path.includes("assinaturas")) {
      this.origens = [
        { id: "ASS", nome: "Assinaturas" },
        { id: null, nome: "Ambos" }
      ];
      this.regraAjuste.origem = "ASS";
    } else {
      this.origens = [
        { id: "ANU", nome: "Anúncios" },
        { id: null, nome: "Ambos" }
      ];
      this.regraAjuste.origem = "ANU";
    }

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;

      if (this.$route.path.includes("assinaturas")) {
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_REGRAS_AJUSTE_CRIAR") {
          this.direitoCriar = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_PLANOS_EDITAR") {
          this.direitoPlano = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_REGRAS_AJUSTE_EDITAR_AVANÇADO") {
          this.direitoAvancado = true;
        }
      } else {
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_REGRAS_AJUSTE_CRIAR") {
          this.direitoCriar = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_REGRAS_AJUSTE_EDITAR_AVANÇADO") {
          this.direitoAvancado = true;
        }
      }
    });
    var idGrupoSite = this.$store.state.usuario.site_idGrupo;
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
    if (idGrupoSite != null) {
      this.siteGrupo = [
        {
          id: this.idSitePrincipal,
          nome: this.$store.state.usuario.site_nome
        }
      ];
    }

    //* Carrega os itens dos selects
    await this.carregarFormasPagamento();

    if (this.id == "") {
      this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_REGRAS_AJUSTE_CRIAR"]);
    } else {
      try {
        //* Se é edição, carrega a regraAjuste
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_REGRAS_AJUSTE_EDITAR"]);
        //* Carrega a regraAjuste - somente se for do site do usuário logado
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/regra-ajuste/" + this.id + "?idSite=" + this.idSitePrincipal);

        if (!response.data) {
          this.$router.push("/assinaturas/regras-ajuste");
          return;
        }
        this.regraAjuste = response.data;
        this.mostraPrazo = false;
        if (this.regraAjuste.prazoPrimeira == null) {
          this.regraAjuste.prazoP = "null";
        } else if (this.regraAjuste.prazoPrimeira == 0) {
          this.regraAjuste.prazoP = "0";
        } else if (this.regraAjuste.prazoPrimeira == 99) {
          this.regraAjuste.prazoP = "99";
        } else {
          this.regraAjuste.prazoP = "1";
          this.mostraPrazo = true;
        }
        this.mostraIntervalo = false;
        if (this.regraAjuste.intervalo == null) {
          this.regraAjuste.inter = "null";
        } else if (this.regraAjuste.intervalo == 0) {
          this.regraAjuste.inter = "0";
        } else {
          this.regraAjuste.inter = "1";
          this.mostraIntervalo = true;
        }

        await this.carregarItens();
        if (this.$route.path.includes("assinaturas")) {
          await this.carregarPlanos();
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os dados da regra de ajuste";
        this.snackErro = true;
      }
    }
    setTimeout(() => {
      this.bloqueiaBotoes = false;
    }, 1000);
  }
};
</script>
