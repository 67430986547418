<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-tabs v-model="tab" background-color="#333333" center-active dark>
      <v-tab :disabled="parcela.idLoteFaturamento != null && tab != 0">Crédito CC e OP</v-tab>
      <v-tab :disabled="parcela.idLoteFaturamento != null && tab != 1">TED</v-tab>
      <v-tab :disabled="parcela.idLoteFaturamento != null && tab != 2">Títulos</v-tab>
      <v-tab :disabled="parcela.idLoteFaturamento != null && tab != 3">GPS</v-tab>
      <v-tab :disabled="parcela.idLoteFaturamento != null && tab != 4">Arrecadação</v-tab>
      <v-tab :disabled="parcela.idLoteFaturamento != null && tab != 5">Darf</v-tab>
      <v-tab :disabled="parcela.idLoteFaturamento != null && tab != 6">PIX</v-tab>
    </v-tabs>

    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>
          <v-flex xs12>
            <v-autocomplete
              v-model="parcela.brrTipoServico"
              :items="tiposServico"
              :filter="filtrarObjetos"
              label="Selecione o Tipo de Serviço"
              item-text="nome"
              item-value="id"
              hint="Obrigatório"
              :rules="[validacao.brrTipoServico == false || validacao.brrTipoServico, value => !!value || 'Obrigatório']"
              no-data-text
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.brrTipoServico = false"
            ></v-autocomplete>
          </v-flex>
          <v-flex xs12>
            <v-autocomplete
              v-model="parcela.brrFormaLancamento"
              :items="formasLancamento"
              :filter="filtrarObjetos"
              label="Selecione a Forma de Lançamento"
              item-text="nome"
              item-value="id"
              hint="Obrigatório"
              :rules="[validacao.brrFormaLancamento == false || validacao.brrFormaLancamento, value => !!value || 'Obrigatório']"
              no-data-text
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.brrFormaLancamento = false"
            ></v-autocomplete>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-form ref="formDoc" v-model="elementoValid" lazy-validation>
          <!-- Crédito CC, OP -->
        </v-form>
      </v-tab-item>

      <v-tab-item>
        <v-form ref="formTed" v-model="elementoValid" lazy-validation>
          <!-- TED -->
          <v-container fluid grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-autocomplete
                  v-model="parcela.brrFinalidade"
                  :items="finalidadesTED"
                  :filter="filtrarObjetos"
                  label="Selecione a Finalidade"
                  item-text="nome"
                  item-value="id"
                  hint="Obrigatório"
                  :rules="[validacao.brrFinalidade == false || validacao.brrFinalidade, value => !!value || 'Obrigatório.']"
                  no-data-text
                  autocomplete="disabled"
                  :name="Math.random()"
                  @change="validacao.brrFinalidade = false"
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 v-if="parcela.brrTipoServico == '30'">
                <v-autocomplete
                  v-model="parcela.brrFinalidadeCompl"
                  :items="finalidadesCompl"
                  :filter="filtrarObjetos"
                  label="Selecione a Finalidade Complementar"
                  item-text="nome"
                  item-value="id"
                  hint="Obrigatório"
                  :rules="[validacao.brrFinalidadeCompl == false || validacao.brrFinalidadeCompl, value => !!value || 'Obrigatório.']"
                  no-data-text
                  autocomplete="disabled"
                  :name="Math.random()"
                  @change="validacao.brrFinalidadeCompl = false"
                ></v-autocomplete>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-tab-item>

      <v-tab-item>
        <v-form ref="formTitulo" v-model="elementoValid" lazy-validation>
          <!-- Títulos -->
          <v-container fluid grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="linhaDigTit"
                  label="Dados do Título"
                  v-mask="'#####.##### #####.###### #####.###### # ###############'"
                  hint="Obrigatório"
                  :rules="[
                    validacao.brrLinhaDig == false || validacao.brrLinhaDig,
                    value => (value && (value.length == 51 || value.length == 54 || value.length == 55)) || 'Obrigatório.'
                  ]"
                  :disabled="isUpdating"
                  color="blue-grey lighten-2"
                  autocomplete="disabled"
                  :name="Math.random()"
                  @change="validacao.brrLinhaDig = false"
                  @keydown.enter.stop.prevent
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <h4 class="overline font-weight-black font-italic">
                  Dados do Pagador
                </h4>
                <v-divider></v-divider>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  class="ml-4"
                  v-model="parcela.brrNomePag"
                  label="Nome"
                  :maxlength="40"
                  hint="Informe o nome do pagador caso não seja o jornal"
                  :rules="[validacao.brrNomePag == false || validacao.brrNomePag, value => !value || (value.length > 3 && value.length < 41) || 'Nome inválido']"
                  :disabled="isUpdating"
                  color="blue-grey lighten-2"
                  autocomplete="disabled"
                  :name="Math.random()"
                  @change="
                    validacao.brrNomePag = false;
                    parcela.brrNomePag = removeEspeciais(parcela.brrNomePag);
                  "
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  class="ml-4"
                  v-model="parcela.brrIdentificacaoPag"
                  :label="'Identificação (' + (parcela.brrIdentificacaoPag && parcela.brrIdentificacaoPag.length == 11 ? 'CPF' : 'CNPJ') + ')'"
                  v-mask="'##############'"
                  hint="Informe a identificação do pagador caso não seja o jornal"
                  :rules="[validacao.brrIdentificacaoPag == false || validacao.brrIdentificacaoPag, value => valida_identificacao(value)]"
                  :disabled="isUpdating"
                  color="blue-grey lighten-2"
                  autocomplete="disabled"
                  :name="Math.random()"
                  @change="validacao.brrIdentificacaoPag = false"
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <h4 class="overline font-weight-black font-italic">
                  Dados do Beneficiário
                </h4>
                <v-divider></v-divider>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  class="ml-4"
                  v-model="parcela.brrNomeBenef"
                  label="Nome"
                  :maxlength="40"
                  hint="Informe o nome do beneficiário caso não seja o jornal"
                  :rules="[validacao.brrNomeBenef == false || validacao.brrNomeBenef, value => !value || (value.length > 3 && value.length < 41) || 'Nome inválido']"
                  :disabled="isUpdating"
                  color="blue-grey lighten-2"
                  autocomplete="disabled"
                  :name="Math.random()"
                  @change="
                    validacao.brrNomeBenef = false;
                    parcela.brrNomeBenef = removeEspeciais(parcela.brrNomeBenef);
                  "
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  class="ml-4"
                  v-model="parcela.brrIdentificacaoBenef"
                  :label="'Identificação (' + (parcela.brrIdentificacaoBenef && parcela.brrIdentificacaoBenef.length == 11 ? 'CPF' : 'CNPJ') + ')'"
                  v-mask="'##############'"
                  hint="Informe a identificação do beneficiário caso não seja o jornal"
                  :rules="[validacao.brrIdentificacaoBenef == false || validacao.brrIdentificacaoBenef, value => valida_identificacao(value)]"
                  :disabled="isUpdating"
                  color="blue-grey lighten-2"
                  autocomplete="disabled"
                  :name="Math.random()"
                  @change="validacao.brrIdentificacaoBenef = false"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-tab-item>

      <v-tab-item>
        <v-form ref="formGps" v-model="elementoValid" lazy-validation>
          <!-- GPS -->
          <v-container fluid grid-list-md>
            <v-layout wrap>
              <v-flex xs12 md3>
                <v-text-field
                  v-model="parcela.brrCodRecTrib"
                  label="Código Pagamento"
                  v-mask="'####'"
                  hint="Obrigatório"
                  :rules="[validacao.brrCodRecTrib == false || validacao.brrCodRecTrib, value => !!value || 'Obrigatório.']"
                  :disabled="isUpdating"
                  color="blue-grey lighten-2"
                  autocomplete="disabled"
                  :name="Math.random()"
                  @change="validacao.brrCodRecTrib = false"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md3>
                <v-text-field
                  v-model="parcela.brrCompetGPS"
                  label="Competência"
                  v-mask="'##/####'"
                  hint="Obrigatório"
                  :rules="[
                    validacao.brrCompetGPS == false || validacao.brrCompetGPS,
                    value => !!value || 'Obrigatório.',
                    value => valida_data('01/' + value) || 'Competência inválida.'
                  ]"
                  :disabled="isUpdating"
                  color="blue-grey lighten-2"
                  autocomplete="disabled"
                  :name="Math.random()"
                  @change="validacao.brrCompetGPS = false"
                ></v-text-field>
              </v-flex>
              <v-flex xs12> </v-flex>
              <v-flex xs12 md3>
                <v-autocomplete
                  v-model="parcela.brrTipoGPS"
                  :items="tiposGPS"
                  :filter="filtrarObjetos"
                  label="Selecione o Tipo"
                  item-text="nome"
                  item-value="id"
                  hint="Obrigatório"
                  :rules="[validacao.brrTipoGPS == false || validacao.brrTipoGPS, value => !!value || 'Obrigatório.']"
                  no-data-text
                  autocomplete="disabled"
                  :name="Math.random()"
                  @change="validacao.brrTipoGPS = false"
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 md3>
                <v-text-field
                  v-model="parcela.brrIdentificadorGPS"
                  label="Identificador"
                  v-mask="'##############'"
                  hint="Obrigatório"
                  :rules="[validacao.brrIdentificadorGPS == false || validacao.brrIdentificadorGPS, value => !!value || 'Obrigatório.', value => validaGPS(value)]"
                  :disabled="isUpdating"
                  color="blue-grey lighten-2"
                  autocomplete="disabled"
                  :name="Math.random()"
                  @change="validacao.brrIdentificadorGPS = false"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 offset-md3 md3>
                <v-currency-field
                  :locale="`pt-${$store.state.usuario.site_pais}`"
                  v-model="parcela.brrVlrOutrosGPS"
                  label="Valor Outros"
                  hint="Obrigatório"
                  :rules="[validacao.brrVlrOutrosGPS == false || validacao.brrVlrOutrosGPS, value => !!value || 'Obrigatório.']"
                  :disabled="isUpdating"
                  color="blue-grey lighten-2"
                  @change="validacao.brrVlrOutrosGPS = false"
                ></v-currency-field>
              </v-flex>
              <v-flex xs12 class="text-right">
                <v-divider></v-divider>
                <div class="mt-4 mr-2">
                  <p>Valor do INSS: {{ formatCurrency(parcela.valor - parcela.brrVlrOutrosGPS + (atMonetaria < 0 ? atMonetaria : 0)) }}</p>
                  <p>Valor Outras Entidades: {{ formatCurrency(parcela.brrVlrOutrosGPS) }}</p>
                  <p>Valor Atualização Monetária: {{ formatCurrency(atMonetaria > 0 ? atMonetaria : 0) }}</p>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-tab-item>

      <v-tab-item>
        <v-form ref="formArrecadacao" v-model="elementoValid" lazy-validation>
          <!-- Arrecadação -->
          <v-container fluid grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="linhaDigArrec"
                  label="Dados do Título"
                  v-mask="'########### # ########### # ########### # ########### #'"
                  hint="Obrigatório"
                  :rules="[validacao.brrLinhaDig == false || validacao.brrLinhaDig, value => (value && (value.length == 50 || value.length == 55)) || 'Obrigatório.']"
                  :disabled="isUpdating"
                  color="blue-grey lighten-2"
                  autocomplete="disabled"
                  :name="Math.random()"
                  @change="validacao.brrLinhaDig = false"
                  @keydown.enter.stop.prevent
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-tab-item>

      <v-tab-item>
        <v-form ref="formDarf" v-model="elementoValid" lazy-validation>
          <!-- Darf -->
          <v-container fluid grid-list-md>
            <v-layout wrap>
              <v-flex xs12 md3>
                <v-text-field
                  v-model="parcela.brrCodRecTrib"
                  label="Código da Receita"
                  v-mask="'####'"
                  hint="Obrigatório"
                  :rules="[validacao.brrCodRecTrib == false || validacao.brrCodRecTrib, value => !!value || 'Obrigatório.']"
                  :disabled="isUpdating"
                  color="blue-grey lighten-2"
                  autocomplete="disabled"
                  :name="Math.random()"
                  @change="validacao.brrCodRecTrib = false"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md3>
                <v-menu v-model="mnuDtApuracao" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="dtApuracao"
                      label="Período de Apuração"
                      v-mask="'##/##/####'"
                      :rules="[validacao.brrDtApuracao == false || validacao.brrDtApuracao, value => valida_data(value) || 'Data inválida']"
                      prepend-icon="event"
                      v-on="on"
                      color="blue-grey lighten-2"
                      autocomplete="disabled"
                      :name="Math.random()"
                      @keyup="dtApuracaoUS = valida_data(dtApuracao) ? formatDateUS(dtApuracao) : null"
                      @change="validacao.brrDtApuracao = false"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="dtApuracaoUS" locale="pt-br" no-title @input="mnuDtApuracao = false"></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 offset-md3 md3>
                <v-currency-field
                  :locale="`pt-${$store.state.usuario.site_pais}`"
                  v-model="parcela.brrVlrMulta"
                  label="Multa"
                  hint="Obrigatório"
                  :rules="[validacao.brrVlrMulta == false || validacao.brrVlrMulta, value => !!value || 'Obrigatório.']"
                  :disabled="isUpdating"
                  color="blue-grey lighten-2"
                  @change="validacao.brrVlrMulta = false"
                ></v-currency-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-tab-item>

      <v-tab-item>
        <v-form ref="formPix" v-model="elementoValid" lazy-validation>
          <!-- PIX -->
        </v-form>
      </v-tab-item>
    </v-tabs-items>

    <v-container fluid grid-list-md>
      <v-layout wrap>
        <v-flex xs12 v-if="validacao.mensagemErro">
          <v-sheet :value="true" class="red--text">{{ validacao.mensagemErro }}</v-sheet>
        </v-flex>
      </v-layout>
    </v-container>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn id="sub-btn" color="orange lighten-2 white" style="color:white" @click="retornar">Retornar</v-btn>
      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes || parcela.idLoteFaturamento != null" id="sub-btn" color="blue white" style="color:white" @click="salvar(false)"
        >Salvar</v-btn
      >
      <v-btn
        :loading="isUpdating"
        :disabled="bloqueiaBotoes || parcela.idLoteFaturamento != null"
        id="sub-btn"
        color="orange white"
        style="color:white"
        @click="salvar(true)"
        outlined
        >Salvar e Retornar</v-btn
      >
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  props: {
    id: String,
    atMonetaria: { type: Number, default: 0 },
    atualiza: String
  },

  data() {
    return {
      parcela: {
        id: "",
        brrOperacao: null,
        brrTipoServico: null,
        brrFormaLancamento: null,
        brrFinalidade: null,
        brrFinalidadeCompl: null,
        brrLinhaDig: null,
        brrNomePag: null,
        brrIdentificacaoPag: null,
        brrNomeBenef: null,
        brrIdentificacaoBenef: null,
        brrCompetGPS: null,
        brrTipoGPS: null,
        brrIdentificadorGPS: null,
        brrVlrOutrosGPS: null,
        brrCodRecTrib: null,
        brrDtApuracao: null,
        brrVlrMulta: null
      },
      validacao: {
        id: false,
        brrOperacao: false,
        brrTipoServico: false,
        brrFormaLancamento: false,
        brrFinalidade: false,
        brrFinalidadeCompl: false,
        brrLinhaDig: false,
        brrNomePag: false,
        brrIdentificacaoPag: false,
        brrNomeBenef: false,
        brrIdentificacaoBenef: false,
        brrCompetGPS: false,
        brrTipoGPS: false,
        brrIdentificadorGPS: false,
        brrVlrOutrosGPS: false,
        brrCodRecTrib: false,
        brrDtApuracao: false,
        brrVlrMulta: false,
        mensagemErro: false
      },

      linhaDigTit: "",
      linhaDigArrec: "",

      dtApuracao: null,
      dtApuracaoUS: null,
      mnuDtApuracao: false,

      tiposServico: [],
      formasLancamento: [],

      // não será usado, pois DOC demora mais de 24h para compensar:
      // finalidadesDOC: [
      //   { id: "01", nome: "01 - Crédito em Conta" },
      //   { id: "02", nome: "02 - Pagamento de Aluguel/Condomínio" },
      //   { id: "03", nome: "03 - Pagamento de Duplicata/Títulos" },
      //   { id: "04", nome: "04 - Pagamento de Dividendos" },
      //   { id: "05", nome: "05 - Pagamento de Mensalidade Escolar" },
      //   { id: "06", nome: "06 - Pagamento de Salários" },
      //   { id: "07", nome: "07 - Pagamento a Fornecedores" },
      //   { id: "08", nome: "08 - Operações de Câmbios/Fundos/Bolsa de Valores" },
      //   { id: "09", nome: "09 - Repasse de Arrecadação/Pagamento de Tributos" },
      //   { id: "10", nome: "10 - Transferência Internacional em Real" },
      //   { id: "11", nome: "11 - DOC para Poupança" },
      //   { id: "12", nome: "12 - DOC para Depósito Judicial" },
      //   { id: "13", nome: "13 - Outros" },
      //   { id: "16", nome: "16 - Pagamento de bolsa auxílio" },
      //   { id: "17", nome: "17 - Remuneração à cooperado" },
      //   { id: "18", nome: "18 - Pagamento de honorários" },
      //   {
      //     id: "19",
      //     nome: "19 - Pagamento de prebenda (Remuneração a padres e sacerdotes)"
      //   }
      // ],

      finalidadesTED: [
        { id: "00001", nome: "00001 Pagamento de Impostos, Tributos e Taxas" },
        { id: "00002", nome: "00002 Pagamento a Concessionárias de Serviço Público" },
        { id: "00003", nome: "00003 Pagamentos de Dividendos" },
        { id: "00004", nome: "00004 Pagamento de Salários" },
        { id: "00005", nome: "00005 Pagamento de Fornecedores" },
        { id: "00006", nome: "00006 Pagamento de Honorários" },
        { id: "00007", nome: "00007 Pagamento de Alugueis e Taxas de Condomínio" },
        { id: "00008", nome: "00008 Pagamento de Duplicatas e Títulos" },
        { id: "00009", nome: "00009 Pagamento de Mensalidade Escolar" },
        { id: "00010", nome: "00010 Credito em Conta" },
        { id: "00011", nome: "00011 Pagamento a Corretoras" },
        { id: "00012", nome: "00012 Pagamento de Boleto Bancário em Cartório" },
        { id: "00013", nome: "00013 Pagamento de Tarifas pela Prestação de Serviços de Arrecadação de Convênios" },
        { id: "00014", nome: "00014 Repasse de valores ref. a títulos liquidados em cartórios de protesto" },
        { id: "00015", nome: "00015 Liquidação financeira de operação de cartão" },
        { id: "00018", nome: "00018 Operações seguro habitacional - SFH" },
        { id: "00019", nome: "00019 Operações de FDS - Caixa" },
        { id: "00020", nome: "00020 Pagamento de Operações de Crédito" },
        { id: "00023", nome: "00023 Taxa de Administração" },
        { id: "00027", nome: "00027 Pagamento de Acordo / Execução Judicial" },
        { id: "00029", nome: "00029 Pagamento de bolsa auxílio" },
        { id: "00031", nome: "00031 Pagamento de prebenda (Remuneração a padres e sacerdotes)" },
        { id: "00033", nome: "00033 Pagamento de juros sobre capital próprio" },
        { id: "00034", nome: "00034 Pagamento de rendimentos ou amortização s/ cotas e/ou debêntures" },
        { id: "00035", nome: "00035 Taxa de Serviço" },
        { id: "00038", nome: "00038 Estorno ou Restituição - Diversos" },
        { id: "00039", nome: "00039 Pagamento de Vale Transporte" },
        { id: "00043", nome: "00043 Patrocínio com Incentivo Fiscal" },
        { id: "00044", nome: "00044 Doação com Incentivo Fiscal" },
        { id: "00047", nome: "00047 Pagamento de Rescisão de Contrato de Trabalho" },
        { id: "00100", nome: "00100 Deposito Judicial" },
        { id: "00101", nome: "00101 Pensão Alimentícia" },
        { id: "00110", nome: "00110 Transferência entre contas de mesma titularidade" },
        { id: "00200", nome: "00200 Transferência Internacional em Reais" },
        { id: "00201", nome: "00201 Ajuste Posição Mercado Futuro" },
        { id: "00202", nome: "00202 Repasse de Valores do BNDES" },
        { id: "00203", nome: "00203 Liquidação de Compromissos junto ao BNDES" },
        { id: "00204", nome: "00204 Operação de Compra/Venda de Ações - Bolsas de Valores e Mercado de Balcão" },
        { id: "00205", nome: "00205 Contratos referenciados em ações ou índices de ações - Bolsas de Valores, de Merc. e de Futuro" },
        { id: "00206", nome: "00206 Operação de Câmbio" },
        { id: "00207", nome: "00207 Operações no Mercado de Renda Fixa e de Renda Variável com Utilização de Intermediário" },
        { id: "00209", nome: "00209 Pagamento de Operações com identificação de destinatário final" },
        { id: "00300", nome: "00300 Restituição de Imposto de Renda" },
        { id: "00302", nome: "00302 Pagamento de multas ao BACEN por atrasos de importação" },
        { id: "00400", nome: "00400 TEA - Transferencia Eletrônica Agendada" },
        { id: "00500", nome: "00500 Restituição de Prêmios de Seguros" },
        { id: "00501", nome: "00501 Pagamento de Indenização de Sinistro de Seguro" },
        { id: "00502", nome: "00502 Pagamento de Prêmio de Co-Seguro" },
        { id: "00503", nome: "00503 Restituição de Prêmio de Co-Seguro" },
        { id: "00504", nome: "00504 Pagamento de Indenização de Sinistro de Co-Seguro" },
        { id: "00505", nome: "00505 Pagamento de Prêmio de Resseguro" },
        { id: "00506", nome: "00506 Restituição de Prêmio de Resseguro" },
        { id: "00507", nome: "00507 Pagamento de Indenização de Sinistro de Resseguro" },
        { id: "00508", nome: "00508 Restituição de Indenização de Sinistro de Resseguro" },
        { id: "00509", nome: "00509 Pagamento de Despesas com Sinistros" },
        { id: "00510", nome: "00510 Pagamento de Inspeções/Vistorias Previas" },
        { id: "00511", nome: "00511 Pagamento de Resgate de Titulo da Capitalização" },
        { id: "00512", nome: "00512 Pagamento de Sorteio de Titulo de Capitalização" },
        { id: "00513", nome: "00513 Pagamento de Devolução de Mensalidade de Titulo de Capitalização" },
        { id: "00514", nome: "00514 Restituição de Contribuição de Plano Previdenciário" },
        { id: "00515", nome: "00515 Pagamento de Beneficio Previdenciário de Pecúlio" },
        { id: "00516", nome: "00516 Pagamento de Beneficio Previdenciário de Pensão" },
        { id: "00517", nome: "00517 Pagamento de Beneficio Previdenciário de Aposentadoria" },
        { id: "00518", nome: "00518 Pagamento de Resgate Previdenciário" },
        { id: "00519", nome: "00519 Pagamento de Comissão de Corretagem" },
        { id: "00520", nome: "00520 Pagamento de Transferências/Portabilidade de Reserva de Seguro/Previdência" }
      ],
      finalidadesCompl: [
        { id: "01", nome: "01 - Pagamento de Folha Mensal" },
        { id: "03", nome: "03 - Pagamento de Férias" },
        { id: "08", nome: "08 - Pagamento de Vale Refeição" },
        { id: "12", nome: "12 - Pagamento de 13° Salário - 1° Parcela" },
        { id: "13", nome: "13 - Pagamento de 13° Salário" }
      ],

      tiposGPS: [
        { id: "01", nome: "1 - CNPJ" },
        { id: "02", nome: "2 - CPF" },
        { id: "03", nome: "3 - NIT / PIS / PASEP" },
        { id: "04", nome: "4 - CEI" },
        { id: "06", nome: "6 - NB" },
        { id: "07", nome: "7 - Nº do Título" },
        { id: "08", nome: "8 - DEBCAD" },
        { id: "09", nome: "9 - REFERÊNCIA" }
      ],

      tab: null,
      elementoValid: true,
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false,
      bloqueiaBotoes: false
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    async salvar(retornar) {
      await util.limparObjeto(this.validacao, false);
      if (this.parcela.confirmada) {
        this.validacao.mensagemErro = "Operação já confirmada, não será possível alterar";
      }
      if (!this.$refs.formElemento.validate() || this.parcela.confirmada) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      var brrCommit = {
        brrOperacao: null,
        brrTipoServico: null,
        brrFormaLancamento: null,
        brrFinalidade: null,
        brrFinalidadeCompl: null,
        brrLinhaDig: null,
        brrNomePag: null,
        brrIdentificacaoPag: null,
        brrNomeBenef: null,
        brrIdentificacaoBenef: null,
        brrCompetGPS: null,
        brrTipoGPS: null,
        brrIdentificadorGPS: null,
        brrVlrOutrosGPS: 0,
        brrCodRecTrib: null,
        brrDtApuracao: null,
        brrVlrMulta: 0
      };
      brrCommit.brrOperacao = this.tab;
      brrCommit.brrTipoServico = this.parcela.brrTipoServico;
      brrCommit.brrFormaLancamento = this.parcela.brrFormaLancamento;

      if (this.tab == 0 || this.tab == 1) {
        if (brrCommit.brrFormaLancamento != "10") {
          //conta corrente não obrigatória somente para OP
          if (!this.parcela.idClienteConta) {
            this.validacao.mensagemErro = "Para Crédito CC e TED, a Conta Corrente é obrigatória na PARCELA";
            return;
          }
        }
      }

      if (this.tab == 0) {
        // Crédito CC, OP
        if (!this.$refs.formDoc.validate()) {
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
          return;
        }
      } else if (this.tab == 1) {
        // TED
        if (!this.$refs.formTed.validate()) {
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
          return;
        }

        brrCommit.brrFinalidade = this.parcela.brrFinalidade;
        brrCommit.brrFinalidadeCompl = this.parcela.brrFinalidadeCompl;
      } else if (this.tab == 2) {
        // Títulos
        if (!this.$refs.formTitulo.validate()) {
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
          return;
        }
        if (this.parcela.brrNomePag && !this.parcela.brrIdentificacaoPag) {
          this.validacao.brrIdentificacaoPag = "Informe a identificação do pagador";
          return;
        }
        if (!this.parcela.brrNomePag && this.parcela.brrIdentificacaoPag) {
          this.validacao.brrNomePag = "Informe o nome do pagador";
          return;
        }
        if (this.parcela.brrNomeBenef && !this.parcela.brrIdentificacaoBenef) {
          this.validacao.brrIdentificacaoPag = "Informe a identificação do beneficiário";
          return;
        }
        if (!this.parcela.brrNomeBenef && this.parcela.brrIdentificacaoBenef) {
          this.validacao.brrNomeBenef = "Informe o nome do beneficiário";
          return;
        }

        this.parcela.brrLinhaDig = this.linhaDigTit;
        brrCommit.brrLinhaDig = util.somenteNumeros(this.parcela.brrLinhaDig);
        brrCommit.brrNomePag = this.parcela.brrNomePag;
        brrCommit.brrIdentificacaoPag = this.parcela.brrIdentificacaoPag;
        brrCommit.brrNomeBenef = this.parcela.brrNomeBenef;
        brrCommit.brrIdentificacaoBenef = this.parcela.brrIdentificacaoBenef;
      } else if (this.tab == 3) {
        // GPS
        if (!util.valida_data_BR("01/" + this.parcela.brrCompetGPS)) {
          this.validacao.brrCompetGPS = "Competência inválida";
        }
        if (!this.$refs.formGps.validate() || !util.valida_data_BR("01/" + this.parcela.brrCompetGPS)) {
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
          return;
        }

        brrCommit.brrCodRecTrib = this.parcela.brrCodRecTrib;
        brrCommit.brrCompetGPS = util.somenteNumeros(this.parcela.brrCompetGPS);
        brrCommit.brrTipoGPS = this.parcela.brrTipoGPS;
        brrCommit.brrIdentificadorGPS = this.parcela.brrIdentificadorGPS;
        brrCommit.brrVlrOutrosGPS = this.parcela.brrVlrOutrosGPS;
      } else if (this.tab == 4) {
        // Arrecadação
        if (!this.$refs.formArrecadacao.validate()) {
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
          return;
        }

        this.parcela.brrLinhaDig = this.linhaDigArrec;
        brrCommit.brrLinhaDig = util.somenteNumeros(this.parcela.brrLinhaDig); //* Não salva código de barra, pois para arrecadação, o leitor retorna a linha digitável sem os dígitos de controle
      } else if (this.tab == 5) {
        // Darf
        if (!util.valida_data(this.dtApuracaoUS)) {
          this.validacao.brrDtApuracao = "Data inválida";
        }
        if (!this.$refs.formDarf.validate() || !util.valida_data(this.dtApuracaoUS)) {
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
          return;
        }

        brrCommit.brrCodRecTrib = this.parcela.brrCodRecTrib;
        brrCommit.brrDtApuracao = util.dateToStringUS(this.dtApuracaoUS);
        brrCommit.brrVlrMulta = this.parcela.brrVlrMulta;
      } else if (this.tab == 6) {
        if (!this.parcela.idClientePix) {
          this.validacao.mensagemErro = "Para PIX, a Chave PIX é obrigatória na PARCELA";
          return;
        }
      }

      this.isUpdating = true;
      try {
        await api.put(process.env.VUE_APP_URL_WEB_API + "fin/parcela/brr/" + this.id, brrCommit);

        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;

        if (retornar) {
          setTimeout(() => {
            this.retornar();
          }, 1500);
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },

    async carregarItem() {
      this.isUpdating = false;
      this.bloqueiaBotoes = true;
      await util.limparObjeto(this.validacao, false);

      this.parcela.id = this.idParcela;

      try {
        //* Somente se é edição, carrega o item
        if (this.parcela.id) {
          //* Carrega o item
          const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/parcela/" + this.parcela.id);

          if (response.data) {
            if (response.data.brrOperacao != null) {
              this.tab = response.data.brrOperacao;
            } else {
              this.tab = 0;
            }
            setTimeout(() => {
              this.parcela = response.data;
              this.dtApuracaoUS = null;
              this.linhaDigTit = "";
              this.linhaDigArrec = "";
              if (this.parcela.brrDtApuracao != null) {
                this.dtApuracaoUS = this.parcela.brrDtApuracao;
              }
              if (this.parcela.brrOperacao == 2) {
                this.linhaDigTit = this.parcela.brrLinhaDig;
              }
              if (this.parcela.brrOperacao == 4) {
                this.linhaDigArrec = this.parcela.brrLinhaDig;
              }
            }, 300);
          }
        }
      } catch {
        this.isUpdating = true;

        this.mensagem = "Ocorreu um erro ao carregar os dados";
        this.snackErro = true;
      }
      setTimeout(() => {
        this.bloqueiaBotoes = false;
      }, 1000);
    },
    retornar() {
      if (this.id) {
        this.$emit("update:dialogParcela", false);
      } else {
        this.$router.push("/financeiro/parcelas/pagar");
      }
    },

    formatDateUS(date) {
      return util.formatDateUS(date);
    },
    formatCurrency(valor) {
      return util.formatCurrency(valor);
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    removeEspeciais(text) {
      return util.removeEspeciais(text).toUpperCase();
    },
    valida_data(date) {
      if (date) {
        return util.valida_data_BR(date);
      } else {
        return true;
      }
    },
    validaGPS(text) {
      if (text) {
        if (this.parcela.brrTipoGPS == "01") {
          if (text.length != 14) {
            return "Identificação deve ter 14 dígitos";
          } else {
            return util.valida_cnpj(text) ? true : "CNPJ inválido";
          }
        } else if (this.parcela.brrTipoGPS == "02") {
          if (text.length != 11) {
            return "Identificação deve ter 11 dígitos";
          } else {
            return util.valida_cpf(text) ? true : "CPF inválido";
          }
        } else if (text.length > 3) {
          return true;
        } else {
          return "Identificador inválido.";
        }
      } else {
        return true;
      }
    },
    valida_identificacao(text) {
      if (!text) {
        return true;
      } else {
        if (text.length != 11 && text.length != 14) {
          return "Identificação deve ter 11 ou 14 dígitos";
        } else {
          if (text.length == 11) {
            return util.valida_cpf(text) ? true : "CPF inválido";
          } else {
            return util.valida_cnpj(text) ? true : "CNPJ inválido";
          }
        }
      }
    }
  },
  computed: {
    idParcela() {
      return this.id || this.$route.params.id;
    }
  },
  watch: {
    id() {
      this.carregarItem();
    },
    atualiza() {
      this.carregarItem();
    },
    dtApuracaoUS() {
      this.dtApuracao = util.formatDate(this.dtApuracaoUS);
      this.mnuDtApuracao = false;
    },
    tab(val) {
      if (val != null) {
        if (val == 0) {
          // Crédito CC, OP
          this.tiposServico = [
            { id: "01", nome: "01 - Cobrança" },
            { id: "20", nome: "20 - Pagamento de Fornecedor" },
            { id: "30", nome: "30 - Pagamento de Salários" },
            { id: "98", nome: "98 - Pagamentos Diversos" }
          ];
          this.parcela.brrTipoServico = null;

          this.formasLancamento = [
            { id: "01", nome: "01 - Crédito em Conta Corrente/Salário" },
            { id: "05", nome: "05 - Crédito em Conta Poupança" },
            { id: "10", nome: "10 - OP à Disposição" }
          ];
          this.parcela.brrFormaLancamento = null;
        } else if (val == 1) {
          // TED
          this.tiposServico = [
            { id: "01", nome: "01 - Cobrança" },
            { id: "20", nome: "20 - Pagamento de Fornecedor" },
            { id: "23", nome: "23 - Interoperabilidade entre Contas de Instituições de Pagamentos" },
            { id: "30", nome: "30 - Pagamento de Salários" },
            { id: "60", nome: "60 - Pagamento de Despesas Viajante em Trânsito" },
            { id: "80", nome: "80 - Pagamento de Representantes / Vendedores Autorizados" },
            { id: "98", nome: "98 - Pagamentos Diversos" }
          ];
          this.parcela.brrTipoServico = null;

          this.formasLancamento = [
            { id: "03", nome: "03 - TED" },
            { id: "41", nome: "41 - TED - Outra Titularidade" },
            { id: "43", nome: "43 - TED - Mesma Titularidade" }
          ];
          this.parcela.brrFormaLancamento = null;
        } else if (val == 2) {
          // Títulos
          this.tiposServico = [
            { id: "01", nome: "01 - Cobrança" },
            { id: "20", nome: "20 - Pagamento de Fornecedor" },
            { id: "32", nome: "32 - Pagamento de honorários" },
            { id: "50", nome: "50 - Pagamento de Sinistros Segurados" },
            { id: "70", nome: "70 - Pagamento Autorizado" },
            { id: "75", nome: "75 - Pagamento de Credenciados" },
            { id: "98", nome: "98 - Pagamentos Diversos" }
          ];
          this.parcela.brrTipoServico = null;

          this.formasLancamento = [
            { id: "20", nome: "20 - Pagamento com Autenticação" },
            { id: "30", nome: "30 - Pagamento de Títulos Banrisul" },
            { id: "31", nome: "31 - Pagamento de Títulos de Outros Bancos" }
          ];
          this.parcela.brrFormaLancamento = null;
        } else if (val == 3) {
          // GPS
          this.tiposServico = [{ id: "90", nome: "90 - Pagamento de Benefícios - INSS (GPS PJ sem código de barras)" }];
          this.parcela.brrTipoServico = "90";

          this.formasLancamento = [{ id: "17", nome: "17 - Pagamento de Tributo - GPS (Guia da Previdência Social)" }];
          this.parcela.brrFormaLancamento = "17";
        } else if (val == 4) {
          // Arrecadação
          this.tiposServico = [{ id: "22", nome: "22 - Pagamento de Contas, Tributos e Impostos" }];
          this.parcela.brrTipoServico = "22";

          this.formasLancamento = [{ id: "11", nome: "11 - Pagamento de Contas e Tributos (com código de barras)" }];
          this.parcela.brrFormaLancamento = "11";
        } else if (val == 5) {
          // Darf
          this.tiposServico = [{ id: "22", nome: "22 - Pagamento de Contas, Tributos e Impostos" }];
          this.parcela.brrTipoServico = "22";

          this.formasLancamento = [{ id: "16", nome: "16 - Pagamento de Tributo - DARF Normal (sem código de barras)" }];
          this.parcela.brrFormaLancamento = "16";
        } else if (val == 6) {
          // PIX
          this.tiposServico = [
            { id: "01", nome: "01 - Cobrança" },
            { id: "20", nome: "20 - Pagamento de Fornecedor" },
            { id: "23", nome: "23 - Interoperabilidade entre Contas de Instituições de Pagamentos" },
            { id: "30", nome: "30 - Pagamento de Salários" },
            { id: "60", nome: "60 - Pagamento de Despesas Viajante em Trânsito" },
            { id: "80", nome: "80 - Pagamento de Representantes / Vendedores Autorizados" },
            { id: "98", nome: "98 - Pagamentos Diversos" }
          ];
          this.parcela.brrTipoServico = null;

          this.formasLancamento = [{ id: "45", nome: "45 - Transferências via PIX" }];
          this.parcela.brrFormaLancamento = "45";
        }
      }
    }
  },
  async created() {
    this.carregarItem();
  }
};
</script>
