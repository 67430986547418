<template>
  <div id="app">
    <v-app id="inspire">
      <v-card>
        <v-toolbar color="#333333">
          <v-icon color="white" class="mr-2">list</v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light">
            Conciliação
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container fluid grid-list-md>
          <v-layout wrap>
            <v-flex xs12 md6>
              <v-autocomplete
                v-model="idCedente"
                autofocus
                :items="cedentes"
                :filter="filtrarObjetos"
                label="Cedente"
                item-text="nome"
                item-value="id"
                no-data-text
                clearable
                @click:clear="idCedente = null"
              ></v-autocomplete>
            </v-flex>
            <v-flex xs12 md3>
              <v-menu
                v-model="mnuDtInicio"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="periodo"
                    label="Período"
                    prepend-icon="event"
                    v-on="on"
                    color="blue-grey lighten-2"
                    @click:clear="periodoUS = ['', '']"
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker v-model="periodoUS" locale="pt-br" no-title range
                  ><v-spacer></v-spacer>
                  <v-btn text color="primary" @click="mnuDtInicio = false">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 md3>
              <v-btn
                color="accent"
                :loading="loading"
                :disabled="loading || loadingExp || loadingTxt"
                fab
                small
                @click="pesquisaDados()"
              >
                <v-icon color="white" title="Filtrar">search</v-icon>
              </v-btn>
              <v-btn
                v-if="direitoExportar"
                class="ml-2"
                color="blue"
                :loading="loadingExp"
                :disabled="loading || loadingExp || loadingTxt"
                fab
                small
                @click="pesquisaDados('CSV')"
              >
                <v-icon color="white" title="Exportar">description</v-icon>
              </v-btn>
              <v-btn
                v-if="direitoExportar"
                class="ml-2"
                color="blue"
                :loading="loadingTxt"
                :disabled="loading || loadingExp || loadingTxt"
                fab
                small
                @click="pesquisaDados('TXT')"
              >
                <v-icon color="white" title="Gerar TXT Contabilidade"
                  >open_in_new</v-icon
                >
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
        <div class="px-4 mt-3">
          <v-data-table
            mobile-breakpoint="0"
            :headers="headers"
            :items="registros"
            :no-data-text="mensagemPesquisa"
            hide-default-footer
            disable-sort
            disable-pagination
            group-by="item"
          >
            <template v-slot:[getItemDtLancamento()]="{ item }">
              {{ formatDate(item.dtLancamento) }}
            </template>
            <template v-slot:[getItemValor()]="{ item }">
              {{ formatCurrency(item.valor) }}
            </template>
            <template v-slot:[getItemId()]="{ item }">
              {{ item.idTransferencia ? item.idTransferencia : item.idParcela }}
            </template>
            <template v-slot:[getItemDtOperacao()]="{ item }">
              {{
                item.dtOperacao
                  ? formatDate(item.dtOperacao)
                  : item.dtQuitacao
                  ? formatDate(item.dtQuitacao)
                  : formatDate(item.dtOperacaoP)
              }}
            </template>
            <template v-slot:[getItemContaD()]="{ item }">
              {{ item.contaD }}
            </template>
            <template v-slot:[getItemContaC()]="{ item }">
              {{ item.contaC }}
            </template>
            <template v-slot:[getItemDescricao()]="{ item }">
              <v-icon
                v-if="item.idOperacao"
                :color="
                  item.idAssinatura
                    ? 'red'
                    : item.idAnuncio
                    ? 'yellow darken-2'
                    : item.idPacote
                    ? 'yellow darken-3'
                    : 'blue'
                "
                :title="
                  item.idAssinatura
                    ? 'Assinatura'
                    : item.idAnuncio
                    ? 'Anúncio'
                    : item.idPacote
                    ? 'Pacote'
                    : item.origem == 'VA'
                    ? 'Venda Avulsa'
                    : item.tipo == 0
                    ? 'Conta a Pagar'
                    : item.tipo == 1
                    ? 'Conta a Receber'
                    : 'Transferência'
                "
                >{{
                  item.idAssinatura
                    ? "assignment"
                    : item.idAnuncio
                    ? "chrome_reader_mode"
                    : item.idPacote
                    ? "library_books"
                    : item.origem == "VA"
                    ? "list_alt"
                    : item.tipo
                    ? "add_circle"
                    : "remove_circle"
                }}</v-icon
              >
              <span>
                {{
                  item.idAssinatura
                    ? item.idAssinatura + "/" + item.renovacao
                    : item.idAnuncio
                    ? item.idAnuncio
                    : item.idPacote
                    ? item.idPacote
                    : item.idOperacao
                }}
              </span>
            </template>
            <template v-slot:[getItemVlrLiquido()]="{ item }">
              {{
                item.vlrLiquido
                  ? formatCurrency(item.vlrLiquido)
                  : formatCurrency(item.vlrParcela)
              }}
            </template>
          </v-data-table>
        </div>
        <v-snackbar
          v-model="snackbar"
          color="info"
          :timeout="5000"
          relative
          bottom
          left
          >{{ mensagem }}</v-snackbar
        >
        <v-snackbar
          v-model="snackErro"
          color="error"
          :timeout="5000"
          relative
          bottom
          left
          >{{ mensagem }}</v-snackbar
        >
      </v-card>
    </v-app>
  </div>
</template>

<script>
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
import util from "../../../util.js";
export default {
  data() {
    return {
      headers: [
        { text: "", value: "item", divider: "true" },
        {
          text: "Data",
          value: "dtLancamento"
        },
        {
          text: "Categoria",
          value: "categoria"
        },
        {
          text: "Histórico",
          value: "historico"
        },
        {
          text: "Documento",
          value: "nroDoc"
        },
        {
          text: "Valor",
          value: "valor",
          align: "end",
          divider: "true"
        },
        {
          text: "ID",
          value: "id"
        },
        {
          text: "Data",
          value: "dtOperacao"
        },
        {
          text: "Destino/Despesa/Fornecedor",
          value: "contaD"
        },
        {
          text: "Origem/Cliente",
          value: "contaC"
        },
        {
          text: "Descrição",
          width: "140",
          value: "descricao"
        },
        {
          text: "Valor",
          value: "vlrLiquido",
          align: "end"
        }
      ],
      registros: [],
      cedentes: [],
      idCedente: null,
      periodoUS: ["", ""],
      mnuDtInicio: false,
      mensagemPesquisa: "",
      carregando: true,
      mensagem: "",
      snackbar: false,
      snackErro: false,
      options: {},
      loader: null,
      loading: false,
      loadingExp: false,
      loadingTxt: false
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    getItemDtLancamento() {
      return "item.dtLancamento";
    },
    getItemValor() {
      return "item.valor";
    },
    getItemId() {
      return "item.id";
    },
    getItemDtOperacao() {
      return "item.dtOperacao";
    },
    getItemContaD() {
      return "item.contaD";
    },
    getItemContaC() {
      return "item.contaC";
    },
    getItemDescricao() {
      return "item.descricao";
    },
    getItemVlrLiquido() {
      return "item.vlrLiquido";
    },
    async pesquisaDados(exportar) {
      this.registros = [];
      this.mensagemPesquisa =
        "MONTE SEU FILTRO PARA VISUALIZAR A CONCILIAÇÃO...";

      var dtInic = "";
      var dtFim = "";
      if (this.periodoUS && this.periodoUS.length > 1) {
        dtInic = this.periodoUS[0];
        dtFim = this.periodoUS[1];
      }
      if (!dtInic || !dtFim) {
        this.mensagem = "Verifique o período";
        this.snackErro = true;
        this.registros = [];
        return;
      }
      if (!this.idCedente && exportar != "TXT") {
        this.mensagem = "Selecione um cedente";
        this.snackErro = true;
        this.registros = [];
        return;
      }

      if (exportar == "CSV") {
        this.loader = "loadingExp";
      } else if (exportar == "TXT") {
        this.loader = "loadingTxt";
      } else {
        this.loader = "loading";
      }
      const l = this.loader;
      this[l] = !this[l];

      //sinaliza que está carregando
      this.registros = [];
      this.carregando = true;
      this.mensagemPesquisa = "Carregando...";

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "fin/extrato-conciliacao/periodo?dtInicio=" +
            dtInic +
            "&dtFim=" +
            dtFim +
            (this.idCedente ? "&idCedente=" + this.idCedente : "") +
            (exportar ? "&exportar=" + exportar : "")
        );
        this.registros = response.data.data;

        var item = 0;
        var itemP = 0;
        this.registros.forEach(element => {
          if (item == element.item) {
            element.dtRegistro = null;
            element.valor = 0;
            element.categoria = "";
            element.historico = "";
            element.nroDoc = "";
          }
          item = element.item;

          var idParcela = element.idParcela;
          if (itemP == element.idParcela) {
            itemP = element.idParcela;
            element.dtQuitacao = null;
            element.idAssinatura = null;
            element.idAnuncio = null;
            element.idPacote = null;
            element.idOperacao = null;
            element.idParcela = null;
          }
          if (idParcela) {
            element.contaD =
              element.contaDP +
              (itemP != idParcela
                ? !element.tipo
                  ? " - " + element.cliente
                  : ""
                : "");
            element.contaC =
              element.contaCP +
              (itemP != idParcela
                ? element.tipo
                  ? " - " + element.cliente
                  : ""
                : "");
            element.vlrLiquido = element.vlrLP;
          }
          itemP = idParcela;
        });
        //fecha o loading
        this.carregando = false;
        //se não achar registro seta a mensagem senão deixa em branco
        if (this.registros.length) {
          this.mensagemPesquisa = "";

          if (exportar) {
            this.mensagem = "Relatório exportado, verifique suas mensagens";
            this.snackbar = true;
          }
        } else {
          this.mensagemPesquisa = "Nenhum registro encontrado";
        }
      } catch (e) {
        this.carregando = false;
        this.mensagemPesquisa = "Erro ao buscar dados: " + e;
      } finally {
        this[l] = false;
        this.loader = null;
      }
    },

    async carregarCedentes() {
      const response = await api.get(
        process.env.VUE_APP_URL_WEB_API +
          "fin/cedente/extrato?idSite=" +
          this.$store.state.usuario.idSitePrincipal
      );
      if (response.data) {
        this.cedentes = response.data;
      }
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    formatDate(date) {
      return util.formatDate(date);
    },
    formatDateUS(date) {
      return util.formatDateUS(date);
    },
    formatCurrency(valor) {
      return valor ? util.formatCurrency(valor) : "";
    }
  },

  beforeMount() {
    this.POSSUI_DIREITO([
      "WEBSUITA_ADMIN_SUITA",
      "FINANCEIRO_RELATÓRIOS_CONCILIAÇÃO_ACESSO"
    ]);

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito =
        element.modulo + "_" + element.grupo + "_" + element.direito;
      if (
        direito == "WEBSUITA_ADMIN_SUITA" ||
        direito == "FINANCEIRO_RELATÓRIOS_CONCILIAÇÃO_EXPORTAR"
      ) {
        this.direitoExportar = true;
      }
    });

    this.mensagemPesquisa = "MONTE SEU FILTRO PARA VISUALIZAR A CONCILIAÇÃO...";
    this.carregarCedentes();
  },
  computed: {
    periodo() {
      var retorno = "";
      if (this.periodoUS && this.periodoUS.length > 1) {
        this.periodoUS.forEach(element => {
          if (element) {
            retorno += (retorno ? " - " : "") + util.formatDate(element);
          }
        });
      }
      return retorno;
    }
  }
};
</script>
