<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">mdi-account</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Transferência</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs6>
            <v-autocomplete
              :autofocus="id == ''"
              v-model="contaDSelecionada"
              :disabled="id != ''"
              :items="contas"
              :filter="filtrarObjetos"
              label="Destino ou Despesa (D)"
              item-text="nome"
              item-value="id"
              hint="D - Obrigatório"
              :rules="[validacao.idContaD == false || validacao.idContaD, value => (!!value && !!value.id) || 'D - Obrigatório.']"
              autocomplete="disabled"
              :name="Math.random()"
              clearable
              no-data-text
              return-object
              @change="validacao.idContaD = false"
            >
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.nomeS"></v-list-item-title>
                  <v-list-item-subtitle v-html="data.item.mae"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-flex>

          <v-flex xs6>
            <v-autocomplete
              v-model="contaCSelecionada"
              :disabled="id != ''"
              :items="contas"
              :filter="filtrarObjetos"
              label="Origem (C)"
              item-text="nome"
              item-value="id"
              hint="C - Obrigatório"
              :rules="[validacao.idContaC == false || validacao.idContaC, value => (!!value && !!value.id) || 'C - Obrigatório.']"
              autocomplete="disabled"
              :name="Math.random()"
              clearable
              no-data-text
              return-object
              @change="validacao.idContaC = false"
            >
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.nomeS"></v-list-item-title>
                  <v-list-item-subtitle v-html="data.item.mae"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-flex>

          <v-flex xs12 md12>
            <v-text-field
              :autofocus="id != ''"
              v-model="operacao.descricao"
              label="Descrição da Operação"
              maxlength="180"
              hint="Obrigatório"
              :rules="[
                validacao.descricao == false || validacao.descricao,
                value => !!value || 'Obrigatório.',
                value => valida_nome(value) || 'Descrição inválida',
                value => value.length > 2 || 'Preencha a descrição da operação'
              ]"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="
                operacao.descricao = pLetra(operacao.descricao);
                validacao.descricao = false;
              "
              :disabled="operacao.situacao == 0 || operacao.conciliado == 1"
            ></v-text-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-menu ref="mnuDtOperacao" v-model="mnuDtOperacao" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dtOperacao"
                  label="Data da Operação"
                  v-mask="'##/##/####'"
                  hint="Obrigatório"
                  prepend-icon="event"
                  v-on="on"
                  :rules="[
                    validacao.dtOperacao == false || validacao.dtOperacao,
                    value => valida_data(value) || 'Data inválida',
                    value => !!value || 'Obrigatório.',
                    value => (value != null && value.length == 10) || 'Preencha a data da operação'
                  ]"
                  :disabled="id != '' || !direitoCancelar"
                  color="blue-grey lighten-2"
                  @keyup="dtOperacaoUS = valida_data(dtOperacao) ? formatDateUS(dtOperacao) : null"
                  @change="validacao.dtOperacao = false"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dtOperacaoUS" locale="pt-br" no-title @input="mnuDtOperacao = false"></v-date-picker>
            </v-menu>
          </v-flex>

          <v-flex md3 xs6>
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="operacao.vlrBruto"
              label="Valor"
              :allowNegative="false"
              :max="9999999"
              hint="Obrigatório"
              :rules="[
                validacao.vlrBruto == false || validacao.vlrBruto,
                value => !!value || 'Obrigatório.',
                value => (value && parseFloat(value.replace(',', '.')) > 0 && value.length < 13) || 'Verifique o Valor'
              ]"
              color="blue-grey lighten-2"
              :disabled="operacao.situacao == 0 || operacao.conciliado == 1"
              @change="validacao.vlrBruto = false"
            ></v-currency-field>
          </v-flex>

          <v-flex md3 xs6>
            <v-text-field v-model="operacao.usuario" label="Usuário" disabled color="blue-grey lighten-2"></v-text-field>
          </v-flex>

          <v-flex xs12>
            <v-alert v-if="operacao.situacao" :value="true" color="success" icon="check_circle" outlined>Operação ATIVA {{ operacao.conciliado ? " e CONCILIADA" : "" }}.</v-alert>
            <v-alert
              v-if="!operacao.situacao"
              :value="true"
              color="error"
              icon="priority_high"
              outlined
              v-html="
                'Esta operação foi cancelada em ' +
                  formatDateTime(operacao.dtCancelamento) +
                  (operacao.usuarioCancelamento ? ', por ' + operacao.usuarioCancelamento : '') +
                  (operacao.motivoCancelamento ? '<br />Justificativa: ' + operacao.motivoCancelamento : '')
              "
            >
            </v-alert>
          </v-flex>
        </v-layout>

        <v-layout wrap>
          <v-expansion-panels v-model="painel" focusable>
            <v-expansion-panel dark class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.centrosCusto ? 'red--text' : ''">
                <strong>Centros de Custo</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-toolbar>
                      <v-spacer></v-spacer>
                      <v-dialog persistent v-model="dialogCentroCusto" max-width="600px">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            :disabled="id != '' || !editarCC || (contaCSelecionada && contaCSelecionada.grupo < 3 && contaDSelecionada && contaDSelecionada.grupo < 3)"
                            color="success"
                            fab
                            small
                            v-on="on"
                          >
                            <v-icon color="white" title="Incluir">add</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form ref="formCentroCusto" v-model="centroCustoValid" lazy-validation>
                            <v-toolbar>
                              <span class="headline">Centro de Custo</span>
                              <v-spacer></v-spacer>
                              <v-btn icon @click="closeCentroCusto">
                                <v-icon color="darken-1">cancel</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-container grid-list-md>
                                <v-layout wrap>
                                  <v-flex xs12 md8>
                                    <v-autocomplete
                                      autofocus
                                      v-model="centroCustoSelecionado"
                                      :items="centrosCusto"
                                      :rules="[value => (!!value && !!value.id) || 'Obrigatório.']"
                                      :filter="filtrarObjetos"
                                      label="Centro de Custo"
                                      item-text="nome"
                                      item-value="id"
                                      no-data-text
                                      return-object
                                    ></v-autocomplete>
                                  </v-flex>

                                  <v-flex xs12 md4>
                                    <v-currency-field
                                      :locale="`pt-${$store.state.usuario.site_pais}`"
                                      v-model="centroCustoItem.percentual"
                                      label="Percentual"
                                      :allowNegative="false"
                                      :max="100"
                                      :rules="[value => !!value || 'Obrigatório.', value => (value && parseFloat(value) > 0 && parseFloat(value) <= 100) || 'Percentual inválido']"
                                      color="blue-grey lighten-2"
                                    ></v-currency-field>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" @click="closeCentroCusto">Retornar</v-btn>
                              <v-btn v-if="centroCustoItem.id == null" color="blue darken-1" @click="saveCentroCusto">Atualizar</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="headerCentrosCusto"
                      :items="centrosCustoOperacao"
                      no-data-text
                      no-results-text
                      :footer-props="{
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageOptions: [10, 25, 50],
                        pageText: ''
                      }"
                    >
                      <template v-slot:[getItemActions()]="{ item }">
                        <v-icon v-if="id == '' && editarCC" class="mr-2" color="blue lighten-2" title="Editar" @click="editarCentroCusto(item)">edit</v-icon>
                        <v-icon v-if="id == '' && editarCC" class="mr-2" color="red" title="Remover Centro de Custo" @click="removerCentroCusto(item)">delete</v-icon>
                      </template>
                      <template v-slot:[getItemPercentual()]="{ item }">
                        {{ formatCurrency4(item.percentual) }}
                      </template>
                    </v-data-table>
                    <v-alert v-if="validacao.centrosCusto" :value="true" color="error" icon="priority_high" outlined>{{ validacao.centrosCusto }}</v-alert>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">
              {{ validacao.mensagemErro }}
            </v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-card-actions id="flex-wrap">
      <v-btn color="orange lighten-2 white" id="btn-rsn" style="color:white" @click="retornar(false)"> <v-icon left>keyboard_arrow_left</v-icon>Retornar </v-btn>
      <v-btn
        v-if="!idTransferencia && operacao.situacao == 1 && !operacao.conciliado"
        :loading="isUpdating"
        :disabled="bloqueiaBotoes"
        color="blue white"
        id="btn-rsn"
        style="color:white"
        depressed
        @click="salvar('false')"
      >
        <v-icon left>update</v-icon>Salvar
      </v-btn>
      <v-btn
        :loading="isUpdating"
        :disabled="bloqueiaBotoes"
        color="orange white"
        style="color:white"
        id="btn-rsn"
        outlined
        @click="salvar('retornar')"
        v-if="operacao.situacao && !operacao.conciliado"
      >
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>
      <v-btn
        v-if="direitoCriar && !idTransferencia && operacao.situacao && !operacao.conciliado"
        :loading="isUpdating"
        :disabled="bloqueiaBotoes"
        color="blue white"
        style="color:white"
        id="btn-rsn"
        outlined
        @click="salvar('true')"
      >
        <v-icon left>autorenew</v-icon>Salvar e Nova
      </v-btn>

      <v-btn
        v-if="direitoCancelar && operacao.id && operacao.situacao && !operacao.conciliado"
        :loading="isUpdating"
        :disabled="bloqueiaBotoes"
        color="red lighten-1"
        style="color:white"
        id="btn-rsn"
        outlined
        @click="dialogConfirmCanc = true"
      >
        <v-icon left>cancel</v-icon>Cancelar
      </v-btn>
      <v-btn
        v-if="direitoCancelar && !operacao.situacao"
        :loading="isUpdating"
        :disabled="bloqueiaBotoes"
        color="green lighten-1"
        style="color:white"
        id="btn-rsn"
        depressed
        @click="reverter()"
      >
        <v-icon left>undo</v-icon>Reverter
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackRefresh" color="info" :timeout="1" relative bottom left></v-snackbar>
    <v-dialog persistent v-model="dialogConfirmCanc" max-width="600">
      <v-card>
        <v-form ref="formConfirmCanc" v-model="confirmCancValid" lazy-validation>
          <v-toolbar>
            <span class="headline">Cancelamento da Operação</span>
          </v-toolbar>
          <br />
          <v-card-text>
            <v-container fluid grid-list-md>
              <v-layout wrap>
                <v-flex xs12 md12>
                  <v-textarea
                    autofocus
                    v-model="motivoCancelamento"
                    label="Justificativa"
                    maxlength="255"
                    counter="255"
                    rows="4"
                    hint="Obrigatório"
                    :rules="[
                      value => !!value || 'Obrigatório.',
                      value => (value != null && value.length >= 10 && value.length <= 255) || 'Texto da justificativa deve ter entre 10 e 255 caracteres'
                    ]"
                    color="blue-grey lighten-2"
                    autocomplete="disabled"
                    :name="Math.random()"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn color="red darken-1" text @click="naoCancelar">Retornar</v-btn>
            <v-btn color="green darken-1" text @click="cancelar">Cancelar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  props: {
    idTransferencia: String,
    descricao: { type: String, default: "" }
  },

  data() {
    return {
      operacao: {
        id: "",
        idSite: this.$store.state.usuario.idSitePrincipal,
        tipo: 2,
        dtOperacao: null,
        dtC: null,
        idUsuario: this.$store.state.usuario.id,
        usuario: this.$store.state.usuario.nome,
        dtCancelamento: null,
        usuarioCancelamento: null,
        motivoCancelamento: null,
        descricao: "",
        idCliente: null,
        idDeptoCliente: null,
        origem: "FIN",
        vlrBruto: 0,
        vlrLiquido: 0,
        vlrTaxado: 0,
        nroParcelas: 0,
        //frete: 9,
        duplicatas: 0,
        situacao: 1,
        mensal: 0,
        confirmada: 1,
        idContaD: null,
        idContaC: null
      },

      dtOperacao: null,
      dtOperacaoUS: null,
      mnuDtOperacao: false,

      contaDSelecionada: {},
      contaCSelecionada: {},

      validacao: {
        id: false,
        idSite: false,
        dtOperacao: false,
        idUsuario: false,
        usuario: false,
        dtCancelamento: false,
        descricao: false,
        idCliente: false,
        idDeptoCliente: false,
        origem: false,
        vlrBruto: false,
        vlrLiquido: false,
        nroParcelas: false,
        //frete: false,
        duplicatas: false,
        situacao: false,
        mensal: false,
        confirmada: false,
        idContaD: false,
        idContaC: false,
        centrosCusto: false,
        mensagemErro: false
      },

      contas: [],
      centrosCusto: [],
      centrosCustoOperacao: [],

      editarCC: true,
      tipoCCauto: "",
      centroCustoSelecionado: {},
      dialogCentroCusto: false,
      headerCentrosCusto: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Centro de Custo", value: "nome" },
        { text: "Percentual", value: "percentual" }
      ],
      centroCustoValid: true,
      centroCustoIndex: -1,
      centroCustoItem: {
        id: null,
        idCentroCusto: null,
        centroCusto: "",
        percentual: 100
      },
      defaultCentroCustoItem: {
        id: null,
        idCentroCusto: null,
        centroCusto: "",
        percentual: 100
      },

      dialogConfirmCanc: false,
      confirmCancValid: true,
      motivoCancelamento: "",

      idSitePrincipal: null,
      painel: [0],
      direitoCriar: false,
      direitoCancelar: false,
      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,
      snackRefresh: false,

      isUpdating: false,
      bloqueiaBotoes: false
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    getItemActions() {
      return "item.actions";
    },
    getItemPercentual() {
      return "item.percentual";
    },

    async carregarItem() {
      this.isUpdating = false;
      this.bloqueiaBotoes = true;
      if (this.idTransferencia) {
        if (this.idTransferencia == "novo" || this.idTransferencia == "x") {
          this.id = "";
          this.inicializa();
        } else {
          this.id = this.idTransferencia;
        }
      } else {
        this.id = this.$route.params.id ? this.$route.params.id : "";
      }

      //* Verifica se o usuário logado possui direitos
      this.$store.state.usuario.direitos.forEach(element => {
        var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_TRANSFERÊNCIAS_CRIAR") {
          this.direitoCriar = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_TRANSFERÊNCIAS_CANCELAR") {
          this.direitoCancelar = true;
        }
      });

      this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

      await this.carregarContas();
      // this.carregarContasCredito();
      await this.carregarCentrosCusto();

      //* Se é edição, carrega o item
      if (this.id == "") {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_TRANSFERÊNCIAS_CRIAR"]);
        this.dtOperacaoUS = util.dateToStringUS(new Date());
      } else {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_TRANSFERÊNCIAS_EDITAR"]);
        try {
          //* Carrega o item - somente se for público (idSite=null) ou for do site do usuário logado
          const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/operacao/" + this.id + "?idSite=" + this.idSitePrincipal);
          if (!response.data || response.data.tipo != 2) {
            this.$router.push("/financeiro/transferencias");
          } else {
            this.operacao = response.data;
            if (this.operacao.tipo != 2) {
              this.$router.push("/financeiro/transferencias");
            }

            this.dtOperacao = util.formatDateUTC(this.operacao.dtOperacao);
            await this.carregarDebitoCreditoOperacao();
            await this.carregarCentrosCustoOperacao();
          }
        } catch {
          this.isUpdating = true;
          this.mensagem = "Ocorreu um erro ao carregar os dados da transferência";
          this.snackErro = true;
        }
      }
      setTimeout(() => {
        this.bloqueiaBotoes = false;
      }, 1000);
    },
    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);

      var erro = false;
      var soma = 0;
      this.centrosCustoOperacao.forEach(element => {
        soma += parseFloat(element.percentual);
      });
      if (soma != 100 && (this.contaCSelecionada.grupo > 2 || this.contaDSelecionada.grupo > 2) && this.centrosCustoOperacao.length == 0) {
        erro = true;
        this.validacao.centrosCusto = "Soma dos percentuais dos centros de custo deve ser 100";
      }

      //* Prepara os centros de custo da operacao para envio a base
      var centrosCustoCommit = [];
      var centrosCustoVal = [];
      var centroCustoDuplicado = "";
      this.centrosCustoOperacao.forEach(element => {
        if (centrosCustoVal.includes(element.nome)) {
          centroCustoDuplicado = element.nome;
        }
        centrosCustoVal.push(element.nome);
        centrosCustoCommit.push({
          id: element.id,
          idCentroCusto: element.idCentroCusto,
          percentual: element.percentual
        });
      });
      if (centroCustoDuplicado) {
        this.validacao.centrosCusto = "Centro de Custo duplicado [" + centroCustoDuplicado + "]";
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      if (this.contaCSelecionada.grupo < 3 && this.contaDSelecionada.grupo < 3 && this.centrosCustoOperacao.length > 0) {
        this.centrosCustoOperacao = [];
        this.mensagem = "Só há necessidade de Centro de Custo para operações com Receita/Despesa";
        this.snackbar = true;
      }

      if (!this.$refs.formElemento.validate() || erro) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      this.operacao.transferencia = 1;
      this.operacao.dtOperacao = util.formatDateUS(this.dtOperacao);
      this.operacao.vlrLiquido = this.operacao.vlrBruto;
      this.operacao.centrosCusto = centrosCustoCommit;

      this.isUpdating = true;

      try {
        var retorno = "";
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "fin/operacao/transferencia/" + this.id, this.operacao);
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "fin/operacao?idSite=" + this.idSitePrincipal, this.operacao);
          this.id = retorno.data.id;
          this.operacao.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "true") {
          this.$router.push("/financeiro/transferencia");
          this.$router.go("/financeiro/transferencia");
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.retornar(null);
          }, 2000);
        } else {
          this.carregarCentrosCustoOperacao();
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },
    retornar(valor) {
      if (this.idTransferencia) {
        this.id = "";
        this.$emit("update:dialogTransferencia", valor);
      } else {
        this.$router.push("/financeiro/transferencias");
      }
    },

    naoCancelar() {
      this.dialogConfirmCanc = false;
    },
    async cancelar() {
      if (!this.$refs.formConfirmCanc.validate()) {
        return;
      }
      this.dialogConfirmCanc = false;
      try {
        await api.put(process.env.VUE_APP_URL_WEB_API + "fin/operacao/cancelar/" + this.id, {
          idUsuarioCancelamento: this.$store.state.usuario.id,
          motivoCancelamento: this.motivoCancelamento
        });
        this.operacao.situacao = 0;
        this.operacao.dtCancelamento = new Date();
        this.operacao.motivoCancelamento = this.motivoCancelamento;
        this.operacao.usuarioCancelamento = this.$store.state.usuario.nome;

        this.mensagem = "Operação cancelada com sucesso";
        this.snackbar = true;
      } catch (e) {
        if (e.response.data) {
          this.mensagem =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
          this.snackErro = true;
        }
      }
    },
    async reverter() {
      try {
        await api.put(process.env.VUE_APP_URL_WEB_API + "fin/operacao/reverter/" + this.id);
        this.operacao.situacao = 1;
        this.operacao.dtCancelamento = null;
        this.mensagem = "Operação revertida com sucesso";
        this.snackbar = true;
        setTimeout(() => {
          this.$router.push("/financeiro/transferencia/" + this.id);
          this.$router.go("/financeiro/transferencia/" + this.id);
        }, 500);
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },

    async carregarContas() {
      try {
        //* Busca todas as contas de transferência
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/conta/transferencia");
        if (response.data) {
          this.contas = response.data;
          this.contas.forEach(element => {
            element.nomeS = element.nome;
            element.nome = element.nome + " - " + element.mae;
          });
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as contas";
        this.snackErro = true;
      }
    },

    async carregarDebitoCreditoOperacao() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/lancamento/operacao/" + this.operacao.id);
        if (response.data && response.data.length > 0) {
          const lancamento = response.data[0];
          if (lancamento.idContaD && lancamento.situacaoD) {
            this.contaDSelecionada = {
              id: lancamento.idContaD,
              nome: lancamento.nomeD,
              idCentroCusto: lancamento.idCentroCustoD
            };
          }
          if (lancamento.idContaC && lancamento.situacaoC) {
            this.contaCSelecionada = {
              id: lancamento.idContaC,
              nome: lancamento.nomeC,
              idCentroCusto: lancamento.idCentroCustoC
            };
          }
          this.snackRefresh = true;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as contas D/C da transferência";
        this.snackErro = true;
      }
    },
    async carregarCentrosCustoOperacao() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/lancamento-centro-custo/operacao/" + this.operacao.id);
        if (response.data) {
          this.centrosCustoOperacao = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os centros de custo da transferência";
        this.snackErro = true;
      }
    },

    async carregarCentrosCusto() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/centro-custo/lista");
        if (response.data) {
          this.centrosCusto = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista dos centros de custo";
        this.snackErro = true;
      }
    },

    async defineCentroCusto(centroCusto, tipo) {
      if (!this.id) {
        //* Carrega o centro de custo padrão da conta, se houver - definindo-o para 100% e bloqueando edição
        if (this.tipoCCauto == tipo) {
          this.centrosCustoOperacao = [];
          this.editarCC = true;
        }
        if (centroCusto.idCentroCusto) {
          this.centrosCustoOperacao = [];
          this.centrosCustoOperacao.push(centroCusto);
          this.editarCC = false;
          this.tipoCCauto = tipo;
        }
      }
    },

    editarCentroCusto(item) {
      this.centroCustoIndex = this.centrosCustoOperacao.indexOf(item);
      this.centroCustoItem = Object.assign({}, item);
      this.centroCustoSelecionado = {
        id: item.idCentroCusto,
        nome: item.nome
      };
      this.dialogCentroCusto = true;
    },
    removerCentroCusto(item) {
      this.centroCustoIndex = this.centrosCustoOperacao.indexOf(item);
      this.centroCustoItem = Object.assign({}, item);
      this.centrosCustoOperacao.splice(this.centroCustoIndex, 1);
      this.centroCustoItem = Object.assign({}, this.defaultCentroCustoItem);
      this.centroCustoIndex = -1;
      this.centroCustoSelecionado = {};
    },
    closeCentroCusto() {
      this.dialogCentroCusto = false;
      setTimeout(() => {
        this.centroCustoItem = Object.assign({}, this.defaultCentroCustoItem);
        this.centroCustoIndex = -1;
        this.centroCustoSelecionado = {};
        this.$refs.formCentroCusto.resetValidation();
      }, 300);
    },
    saveCentroCusto() {
      if (this.$refs.formCentroCusto.validate()) {
        var soma = 0;
        if (this.centroCustoIndex > -1) {
          Object.assign(this.centrosCustoOperacao[this.centroCustoIndex], this.centroCustoItem);
          this.centrosCustoOperacao.forEach(element => {
            soma += parseFloat(element.percentual);
            if (soma > 100) {
              element.percentual = element.percentual + (100 - soma);
            }
          });
        } else {
          soma = parseFloat(this.centroCustoItem.percentual);
          this.centrosCustoOperacao.forEach(element => {
            soma += parseFloat(element.percentual);
          });
          if (soma > 100) {
            var vlr = 100 / (this.centrosCustoOperacao.length + 1);
            soma = vlr;
            this.centroCustoItem.percentual = vlr;
            vlr = parseFloat(vlr.toFixed(4));
            this.centrosCustoOperacao.forEach(element => {
              soma += vlr;
              element.percentual = vlr;
            });
          }

          if (soma > 100) {
            this.centroCustoItem.percentual = this.centroCustoItem.percentual + (100 - soma);
          }
          this.centrosCustoOperacao.push(this.centroCustoItem);
        }
        this.closeCentroCusto();
        this.centroCustoItem = Object.assign({}, this.defaultCentroCustoItem);
        this.centroCustoIndex = -1;
        this.centroCustoSelecionado = {};
        this.$refs.formCentroCusto.resetValidation();
        this.validacao.centrosCusto = false;
      }
    },

    inicializa() {
      this.operacao = {
        id: "",
        idSite: this.$store.state.usuario.idSitePrincipal,
        tipo: 2,
        dtOperacao: null,
        dtC: null,
        idUsuario: this.$store.state.usuario.id,
        usuario: this.$store.state.usuario.nome,
        dtCancelamento: null,
        descricao: this.descricao ? this.descricao : "",
        idCliente: null,
        idDeptoCliente: null,
        origem: "FIN",
        vlrBruto: 0,
        vlrLiquido: 0,
        vlrTaxado: 0,
        nroParcelas: 0,
        //frete: 9,
        duplicatas: 0,
        situacao: 1,
        mensal: 0,
        confirmada: 1,
        idContaD: null,
        idContaC: null
      };
      this.mnuDtOperacao = false;
      this.validacao = {
        id: false,
        idSite: false,
        dtOperacao: false,
        idUsuario: false,
        usuario: false,
        dtCancelamento: false,
        descricao: false,
        idCliente: false,
        idDeptoCliente: false,
        origem: false,
        vlrBruto: false,
        vlrLiquido: false,
        nroParcelas: false,
        duplicatas: false,
        situacao: false,
        mensal: false,
        confirmada: false,
        idContaD: false,
        idContaC: false,
        centrosCusto: false,
        mensagemErro: false
      };
      this.contaCSelecionada = {};
      this.contaDSelecionada = {};
      this.centrosCustoOperacao = [];
      util.limparObjeto(this.validacao, false);
      this.tipoCCauto = "";
    },

    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    pLetra(text) {
      return util.pLetra(text);
    },
    valida_nome(text) {
      return util.valida_nome(text);
    },
    formatDate(date) {
      return util.formatDate(date);
    },
    formatDateTime(datetime) {
      return util.formatDateTime(datetime);
    },
    formatDateUS(date) {
      if (date && date.length == 10) {
        return util.formatDateUS(date);
      }
    },
    formatCurrency4(valor) {
      return util.formatCurrency4(valor);
    },
    valida_data(date) {
      if (date) {
        return util.valida_data_BR(date);
      } else {
        return true;
      }
    }
  },

  watch: {
    idTransferencia() {
      this.carregarItem();
    },

    dtOperacaoUS() {
      this.dtOperacao = util.formatDate(this.dtOperacaoUS);
      this.mnuDtOperacao = false;
    },
    contaDSelecionada(val) {
      if (this.contaCSelecionada.id) {
        //* contaD não pode ser contaC
        if (val && this.contaCSelecionada.id == val.id) {
          this.mensagem = "Destino inválido";
          this.snackErro = true;
          this.contaDSelecionada = {};
          return;
        }
      }
      if (this.contaCSelecionada.id && this.contaCSelecionada.grupo == 2) {
        //* Se contaC é do PASSIVO("a pagar"), contaD não pode ser do ATIVO(conta movimento)
        if (val && val.grupo == 1) {
          this.mensagem = "Destino inválido";
          this.snackErro = true;
          this.contaDSelecionada = {};
          return;
        }
      }
      if (this.contaCSelecionada.id && this.contaCSelecionada.grupo > 2) {
        //* Se contaC é RECEITA/DESPESA, contaD não pode ser RECEITA/DESPESA
        if (val && val.grupo > 2) {
          this.mensagem = "Destino inválido";
          this.snackErro = true;
          this.contaDSelecionada = {};
          return;
        }
      }

      this.operacao.idContaD = val ? val.id : null;
      this.defineCentroCusto(
        {
          id: null,
          idCentroCusto: val ? val.idCentroCusto : null,
          nome: val ? val.centroCusto : null,
          percentual: 100
        },
        "D"
      );
    },
    contaCSelecionada(val) {
      if (this.contaDSelecionada.id) {
        //* contaC não pode ser contaD
        if (val && this.contaDSelecionada.id == val.id) {
          this.mensagem = "Origem inválida";
          this.snackErro = true;
          this.contaCSelecionada = {};
          return;
        }
      }
      // if (this.contaDSelecionada.id && this.contaDSelecionada.grupo == 1) {
      //   //* Se contaD é do ATIVO(conta movimento), contaC não pode ser do PASSIVO(pois é "a pagar") - LIBERADO, PARA REGISTRO DE MÚTUO, POR EXEMPLO, OU ZERAR UMA CONTA A PAGAR COM SALDO ERRADO
      //   if (val && val.grupo == 2) {
      //     this.mensagem = "Origem inválida";
      //     this.snackErro = true;
      //     this.contaCSelecionada = {};
      //     return;
      //   }
      // }
      if (this.contaDSelecionada.id && this.contaDSelecionada.grupo > 2) {
        //* Se contaD é RECEITA/DESPESA, contaC não pode ser RECEITA/DESPESA
        if (val && val.grupo > 2) {
          this.mensagem = "Origem inválida";
          this.snackErro = true;
          this.contaCSelecionada = {};
          return;
        }
      }

      this.operacao.idContaC = val ? val.id : null;
      this.defineCentroCusto(
        {
          id: null,
          idCentroCusto: val ? val.idCentroCusto : null,
          nome: val ? val.centroCusto : null,
          percentual: 100
        },
        "C"
      );
    },
    centroCustoSelecionado(val) {
      this.centroCustoItem.idCentroCusto = val.id;
      this.centroCustoItem.nome = val.nome;
    },
    dialogCentroCusto(val) {
      val || this.closeCentroCusto();
    }
  },
  async created() {
    this.carregarItem();
  }
};
</script>
