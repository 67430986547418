<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">assignment</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">
        {{
          idAssinatura == "" || idRenovacao == ""
            ? "Nova Assinatura"
            : "Assinatura - " + idAssinatura + " | Renovação - " + renovacao.renovacao + " '" + (renovacao.codAntigo || renovacao.id) + "'"
        }}
      </v-toolbar-title>
    </v-toolbar>

    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12>
            <v-autocomplete
              :autofocus="idAssinatura == ''"
              v-model="renovacao.idOrigem"
              :items="origensVenda"
              :rules="[validacao.idOrigem == false || validacao.idOrigem, value => !!value || 'Obrigatório.']"
              :filter="filtrarObjetos"
              label="Origem de Venda"
              item-text="nome"
              item-value="id"
              :disabled="!editar || (renovacao.renovacao > 0 && !renovacao.reinicio) || origensVenda.length == 1"
              no-data-text
              @change="validacao.idOrigem = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md3>
            <v-text-field v-model="assinatura.status" label="Status" readonly color="blue-grey lighten-2"></v-text-field>
          </v-flex>

          <v-flex xs12 md3>
            <v-text-field v-model="renovacao.dtOp" :label="renovacao.renovacao == 0 ? 'Captada em' : 'Renovada em'" readonly color="blue-grey lighten-2"></v-text-field>
          </v-flex>

          <v-flex xs12 md3>
            <v-text-field v-model="renovacao.usuario" :label="renovacao.renovacao == 0 ? 'Captada por' : 'Renovada por'" readonly color="blue-grey lighten-2"></v-text-field>
          </v-flex>

          <v-flex xs12 md3>
            <v-text-field
              v-model="renovacao.autorizacao"
              label="Autorização"
              maxlength="50"
              :rules="[validacao.autorizacao == false || validacao.autorizacao, value => valida_nome(value) || 'Autorização inválida']"
              :disabled="isUpdating"
              :readonly="!editar"
              color="blue-grey lighten-2"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md6>
            <v-layout wrap>
              <v-flex xs12>
                <v-autocomplete
                  autofocus
                  v-model="planoSelecionado"
                  :items="planos"
                  :rules="[validacao.idPlano == false || validacao.idPlano, value => (!!value && !!value.id) || 'Obrigatório.']"
                  :filter="filtrarObjetos"
                  label="Plano de Assinatura"
                  item-text="nome"
                  item-value="id"
                  :disabled="!editar || (idAssinatura != '' && !direitoAlterarPlano)"
                  no-data-text
                  clearable
                  return-object
                  @change="validacao.idPlano = false"
                ></v-autocomplete>
              </v-flex>

              <v-flex xs11>
                <v-autocomplete
                  v-model="clienteSelecionado"
                  :items="clientes"
                  :filter="filtrarObjetos"
                  label="Assinante"
                  item-text="nome"
                  item-value="id"
                  hint="Obrigatório"
                  :rules="[value => (!!value && !!value.id) || 'Obrigatório.']"
                  :disabled="!editar || (idAssinatura != '' && !direitoAlterarAssinante)"
                  no-data-text
                  placeholder="Pesquisa pelos primeiros 50 assinantes que coincidirem com o nome ou identificação digitada"
                  :loading="loadingClientes"
                  :search-input.sync="search"
                  @keyup.enter="buscarCliente()"
                  clearable
                  return-object
                  @change="validacao.idCliente = false"
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title class="ml-2">{{ data.item.nome }}</v-list-item-title>
                      <v-list-item-subtitle v-if="data.item.acervo" class="ml-2">IMPORTAR DO ACERVO</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-flex>
              <v-flex xs1>
                <v-icon class="mt-6" v-if="renovacao.idCliente == null" color="blue" @click="buscarCliente()" title="Busca pelo nome ou identificação do assinante">search</v-icon>
                <v-icon class="mt-6" v-if="direitoAssinante && renovacao.idCliente != null" color="orange" @click="editarCliente" title="Editar Assinante">account_circle</v-icon>
              </v-flex>
              <v-alert v-if="validacao.idCliente" :value="true" color="error" icon="priority_high" outlined>{{ validacao.idCliente }}</v-alert>
            </v-layout>
          </v-flex>

          <v-flex xs12 md6>
            <v-layout wrap>
              <v-flex xs12 md4 class="mt-n4">
                <v-flex xs12>
                  <h5>Exemplares</h5>
                </v-flex>
                <v-flex xs12 offset-md1 md10>
                  <v-simple-table dense>
                    <tbody>
                      <tr>
                        <td :style="`font-size: 12px; ${$vuetify.breakpoint.xs ? 'padding: 0px;' : ''}`">Total:</td>
                        <td :style="`font-size: 12px; ${$vuetify.breakpoint.xs ? 'padding: 0px;' : ''}`" class="text-right">{{ renovacao.exemplares }}</td>
                      </tr>
                      <tr>
                        <td :style="`font-size: 12px; ${$vuetify.breakpoint.xs ? 'padding: 0px;' : ''}`">Ativos:</td>
                        <td :style="`font-size: 12px; ${$vuetify.breakpoint.xs ? 'padding: 0px;' : ''}`" class="text-right">{{ renovacao.ativos }}</td>
                      </tr>
                      <tr>
                        <td :style="`font-size: 12px; ${$vuetify.breakpoint.xs ? 'padding: 0px;' : ''}`">Entregues:</td>
                        <td :style="`font-size: 12px; ${$vuetify.breakpoint.xs ? 'padding: 0px;' : ''}`" class="text-right">{{ renovacao.entregues }}</td>
                      </tr>
                      <tr>
                        <td :style="`font-size: 12px; ${$vuetify.breakpoint.xs ? 'padding: 0px;' : ''}`">Saldo:</td>
                        <td :style="`font-size: 12px; ${$vuetify.breakpoint.xs ? 'padding: 0px;' : ''}`" class="text-right">
                          {{ assinatura.id ? renovacao.ativos - renovacao.entregues : "" }}
                        </td>
                      </tr>
                      <tr>
                        <td :style="`font-size: 12px; ${$vuetify.breakpoint.xs ? 'padding: 0px;' : ''}`">Valor:</td>
                        <td :style="`font-size: 12px; ${$vuetify.breakpoint.xs ? 'padding: 0px;' : ''}`" class="text-right">
                          {{ assinatura.id ? formatCurrency(renovacao.vlrLiquido / renovacao.ativos) : "" }}
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-flex>
              </v-flex>
              <v-flex xs12 md4>
                <v-card color="grey lighten-4" min-height="130">
                  <v-container fluid grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12>
                        <h5>Último Anúncio</h5>
                      </v-flex>
                      <v-flex xs12>
                        <v-progress-linear :active="loadingUltAnu" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
                      </v-flex>
                      <div v-if="ultimoAnuncio.id">
                        <v-flex xs12>
                          <h6 class="font-italic">
                            Contrato:
                            {{ ultimoAnuncio.id }}
                          </h6>
                          <h6 class="font-italic">
                            Valor:
                            {{ formatCurrency(ultimoAnuncio.vlrLiquido) }}
                          </h6>
                          <h6 class="font-italic">
                            Tamanho:
                            {{ ultimoAnuncio.tamanho }}
                          </h6>
                        </v-flex>
                        <v-flex xs12>
                          <h6 class="font-italic">
                            Total Geral Cliente:
                            {{ formatCurrency(ultimoAnuncio.total) }}
                          </h6>
                        </v-flex>
                      </div>
                    </v-layout>
                  </v-container>
                </v-card>
              </v-flex>
              <v-flex xs12 md4>
                <v-card color="grey lighten-4" min-height="130">
                  <v-container fluid grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12>
                        <h5>Última Assinatura</h5>
                      </v-flex>
                      <v-flex xs12>
                        <v-progress-linear :active="loadingUltAss" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
                      </v-flex>
                      <div v-if="ultimaAssinatura.id">
                        <v-flex xs12>
                          <h6 class="font-italic">
                            Contrato:
                            {{ ultimaAssinatura.id }}
                          </h6>
                          <h6 class="font-italic">
                            Valor:
                            {{ formatCurrency(ultimaAssinatura.vlrLiquido) }}
                          </h6>
                          <h6 class="font-italic">
                            Plano:
                            {{ ultimaAssinatura.plano }}
                          </h6>
                        </v-flex>
                        <v-flex xs12>
                          <h6 class="font-italic">
                            Período:
                            {{ ultimaAssinatura.periodo }}
                          </h6>
                        </v-flex>
                      </div>
                    </v-layout>
                  </v-container>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex xs12 md12 v-if="departamentosCliente.length > 0">
            <v-autocomplete
              v-model="renovacao.idDeptoCliente"
              :items="departamentosCliente"
              :filter="filtrarObjetos"
              label="Departamento do Assinante"
              :rules="[validacao.idDeptoCliente == false || validacao.idDeptoCliente]"
              item-text="departamento"
              item-value="id"
              :disabled="!editar"
              no-data-text
              clearable
              @change="validacao.idDeptoCliente = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs6 md2>
            <v-menu
              ref="mnuDtInicio"
              v-model="mnuDtInicio"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
              :disabled="!editar"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dtInicio"
                  label="Data de Início"
                  v-mask="'##/##/####'"
                  hint="Obrigatório"
                  prepend-icon="event"
                  v-on="on"
                  :rules="[
                    validacao.dtInicio == false || validacao.dtInicio,
                    value => valida_data(value) || 'Data inválida',
                    value => !!value || 'Obrigatório',
                    value => (value != null && value.length == 10) || 'Preencha a data de início do período'
                  ]"
                  :disabled="isUpdating || !editar || !direitoAlterarInicio"
                  color="blue-grey lighten-2"
                  @keyup="dtInicioUS = valida_data(dtInicio) ? formatDateUS(dtInicio) : null"
                  autocomplete="disabled"
                  :name="Math.random()"
                  @change="validacao.dtInicio = false"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dtInicioUS" locale="pt-br" no-title :readonly="!editar || !direitoAlterarInicio"></v-date-picker>
            </v-menu>
          </v-flex>

          <v-flex xs6 md2>
            <v-text-field
              v-model="renovacao.dtF"
              label="Data de Término"
              v-mask="'##/##/####'"
              hint="Obrigatório"
              readonly
              :disabled="!bonificar || !direitoBonificar"
              color="blue-grey lighten-2"
            >
              <template v-slot:prepend v-if="bonificar && direitoBonificar">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="dialogBonificacao = true" :disabled="isUpdating">add_circle_outline</v-icon>
                  </template>
                  Bonificar (estender período)
                </v-tooltip>
              </template>
            </v-text-field>
          </v-flex>

          <v-flex xs6 md2 v-if="idRenovacao != ''">
            <v-text-field v-model="vlrBruto" label="Valor Tabela" disabled color="blue-grey lighten-2"></v-text-field>
          </v-flex>
          <v-flex xs6 md2 v-if="idRenovacao == ''"></v-flex>
          <v-flex xs6 md2 v-if="idRenovacao != ''">
            <v-text-field v-model="vlrLiquido" label="Valor Cobrado" disabled color="blue-grey lighten-2"></v-text-field>
          </v-flex>
          <v-flex xs6 md2 v-if="idRenovacao == ''"></v-flex>

          <v-flex xs6 md1>
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="vlrTaxado"
              label="Valor Taxado"
              :allowNegative="false"
              :max="99999"
              :rules="[validacao.vlrTaxado == false || validacao.vlrTaxado, value => (value && value.length < 10) || 'Valor Taxado inválido']"
              color="blue-grey lighten-2"
              :disabled="isUpdating || !editar"
              clearable
              @click:clear="limpaParcelas('ajuste')"
              :readonly="!editar || !taxar || !direitoTaxar"
              @change="validacao.vlrTaxado = false"
            ></v-currency-field>
          </v-flex>

          <v-flex xs6 md1>
            <v-text-field
              v-if="direitoDefinirCarencia"
              v-model="carencia"
              label="Carência (dias)"
              maxlength="3"
              :rules="[
                validacao.carencia == false || validacao.carencia,
                value => !limiteCarencia || (parseInt(value) >= limiteCarencia * -1 && parseInt(value) <= limiteCarencia) || 'Carência inválida'
              ]"
              color="blue-grey lighten-2"
              :disabled="isUpdating || !editar"
              clearable
              @click:clear="limpaParcelas('ajuste')"
              :readonly="!editar || !taxar || !direitoTaxar"
              @change="validacao.carencia = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs6 md1>
            <v-switch
              v-if="idRenovacao != '' && renovacao.renovacao != 0"
              v-model="renovacao.reinicio"
              :label="renovacao.reinicio == 1 ? 'Reiniciada' : 'Normal'"
              :disabled="!direitoReinicio || !editar || alterado"
              @change="alterado = true"
              color="red"
            ></v-switch>
          </v-flex>

          <v-flex xs6 md1>
            <v-switch v-if="idRenovacao != ''" v-model="renovacao.vip" :label="renovacao.vip == 1 ? 'VIP' : 'Simples'" :disabled="!direitoReinicio" color="green"></v-switch>
          </v-flex>
        </v-layout>

        <v-layout wrap>
          <v-expansion-panels v-model="painel" focusable>
            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.produtos ? 'red--text' : ''">
                <strong>Produtos</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-toolbar>
                      <v-spacer></v-spacer>
                      <v-dialog persistent v-model="dialogProduto" max-width="600px">
                        <template v-slot:activator="{ on }">
                          <v-btn v-if="editar && (idAssinatura == '' || direitoAlterarProdutos)" color="success" fab small v-on="on">
                            <v-icon color="white" title="Incluir">add</v-icon>
                            <!-- se incluir um produto em uma renovação existente, a mesma será encerrada na data de hoje e iniciada nova com a nova configuração de produtos -->
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form ref="formProduto" v-model="produtoValid" lazy-validation>
                            <v-toolbar>
                              <span class="headline">Produto</span>
                              <v-spacer></v-spacer>
                              <v-btn icon @click="closeProduto">
                                <v-icon color="darken-1">cancel</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-container fluid grid-list-md>
                                <v-layout wrap>
                                  <v-flex xs12 md12>
                                    <v-autocomplete
                                      autofocus
                                      v-model="produtoSelecionado"
                                      :filter="filtrarObjetos"
                                      :items="produtosPlano"
                                      :rules="[value => (!!value && !!value.idProduto) || 'Obrigatório.']"
                                      hint="Obrigatório"
                                      label="Selecione o Produto"
                                      item-text="nome"
                                      item-value="idProduto"
                                      :disabled="produtoSelecionado.obrigatorio == 1"
                                      no-data-text
                                      return-object
                                    ></v-autocomplete>
                                  </v-flex>
                                  <v-flex xs6 md6 v-if="['IMPRESSO', 'ONLINE'].includes(produtoItem.produtoTipo)">
                                    <v-text-field
                                      v-model="produtoItem.edicoes"
                                      :label="`Número de ${produtoItem.produtoTipo == 'IMPRESSO' ? 'Edições' : 'Acessos'}`"
                                      v-mask="'###'"
                                      hint="Obrigatório"
                                      :rules="[value => !!value || 'Obrigatório.', value => value > 0 || 'Preencha o número de edições do produto impresso']"
                                      color="blue-grey lighten-2"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs6 md6>
                                    <v-currency-field
                                      :locale="`pt-${$store.state.usuario.site_pais}`"
                                      v-model="produtoItem.valor"
                                      label="Preço de Tabela do Produto"
                                      readonly
                                      color="blue-grey lighten-2"
                                    ></v-currency-field>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" @click="closeProduto">Retornar</v-btn>
                              <v-btn color="blue darken-1" @click="saveProduto">Atualizar</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="headerProdutos"
                      :items="produtos"
                      no-data-text
                      no-results-text
                      :footer-props="{
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageOptions: [10, 25, 50],
                        pageText: ''
                      }"
                    >
                      <template v-slot:[getItemActions()]="{ item }">
                        <v-icon
                          v-if="editar && (idAssinatura == '' || direitoAlterarProdutos) && !item.obrigatorio"
                          class="mr-2"
                          color="red"
                          title="Remover Produto"
                          @click="removerProduto(item)"
                          >delete</v-icon
                        >
                        <v-icon v-if="editar && (idAssinatura == '' || direitoAlterarProdutos)" class="mr-2" color="blue lighten-2" title="Editar" @click="editarProduto(item)"
                          >edit</v-icon
                        >
                      </template>
                    </v-data-table>
                    <v-alert v-if="validacao.produtos" :value="true" color="error" icon="priority_high" outlined>{{ validacao.produtos }}</v-alert>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-if="produtoImpresso && edicoes.length != 0" dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.edicoes ? 'red--text' : ''">
                <strong>Edições</strong>
                <v-spacer></v-spacer>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-toolbar>
                      <v-spacer></v-spacer>
                      <v-dialog persistent v-model="dialogEdicao" max-width="800px">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-if="
                              (editar && !['RECUPERAÇÃO', 'CANCELADA', 'EXPIRADA'].includes(assinatura.status)) || renovacaoCorrente || assinatura.ultimaRenovacao == idRenovacao
                            "
                            color="success"
                            fab
                            small
                            v-on="on"
                          >
                            <v-icon color="white" title="Agendar Endereço - Todas Edições">edit</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form ref="formEdicao" v-model="edicaoValid" lazy-validation>
                            <v-toolbar>
                              <span class="headline">
                                Endereço de Entrega -
                                {{ edicaoItem.edicao }}
                              </span>
                              <v-spacer></v-spacer>
                              <v-btn icon @click="closeEdicao">
                                <v-icon color="darken-1">cancel</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-container fluid grid-list-md>
                                <v-layout wrap>
                                  <v-flex xs12 v-if="edicaoItem.edicao == '[TODAS]'">
                                    <v-autocomplete
                                      v-model="enderecoItem.edicao"
                                      :name="Math.random()"
                                      :items="itensEdicoes"
                                      hint="Obrigatório"
                                      label="Agendar para TODOS os dias da edição"
                                    ></v-autocomplete>
                                  </v-flex>

                                  <v-flex xs12 md12>
                                    <v-autocomplete
                                      v-model="enderecoSelecionado"
                                      :items="enderecosEntregaClienteEdicao"
                                      :filter="filtrarObjetos"
                                      hint="Obrigatório"
                                      :rules="[validacao.edicao_idEndereco == false || validacao.edicao_idEndereco, value => (!!value && !!value.id) || 'Obrigatório.']"
                                      label="Selecione o Endereço de Entrega"
                                      item-text="nome"
                                      item-value="id"
                                      no-data-text
                                      return-object
                                    >
                                      <template v-slot:selection="data">{{ data.item.enderecoCompleto }}</template>
                                      <template v-slot:item="data">
                                        <v-list-item-content>
                                          <v-list-item-title>{{ data.item.enderecoCompleto }}</v-list-item-title>
                                          <v-list-item-subtitle>{{ data.item.enderecoComplemento }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                      </template>
                                    </v-autocomplete>
                                  </v-flex>
                                  <v-flex xs6 md6>
                                    <v-menu
                                      ref="mnuDtEnderecoItem"
                                      v-model="mnuDtEnderecoItem"
                                      :close-on-content-click="false"
                                      transition="scale-transition"
                                      offset-y
                                      max-width="290px"
                                      min-width="290px"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-text-field
                                          v-model="dtInicioEndereco"
                                          label="Data de Início"
                                          v-mask="'##/##/####'"
                                          prepend-icon="event"
                                          v-on="on"
                                          :rules="[
                                            validacao.edicao_dtInicio == false || validacao.edicao_dtInicio,
                                            value => !!value || 'Obrigatório.',
                                            value => (value != null && value.length == 10) || 'Preencha a data corretamente',
                                            value => valida_data_edicao(value) || 'Data inválida - não é futura ou está fora do período'
                                          ]"
                                          hint="Obrigatório"
                                          :disabled="enderecoItem.ultimo"
                                          color="blue-grey lighten-2"
                                          @keyup="dtInicioEnderecoUS = valida_data_edicao(dtInicioEndereco) ? formatDateUS(dtInicioEndereco) : null"
                                          autocomplete="disabled"
                                          :name="Math.random()"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker v-model="dtInicioEnderecoUS" locale="pt-br" no-title @input="mnuDtEnderecoItem = false"></v-date-picker>
                                    </v-menu>
                                  </v-flex>
                                  <v-flex xs6 md6 v-if="direitoCorrigirEdicao">
                                    <v-switch v-model="enderecoItem.corrigir" :label="`${enderecoItem.corrigir == 1 ? 'Corrigir' : 'Incluir'}`"></v-switch>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" @click="closeEdicao">Retornar</v-btn>
                              <v-btn color="blue darken-1" @click="saveEdicao">Atualizar</v-btn>
                            </v-card-actions>
                          </v-form>
                          <v-flex xs12 v-if="validacao.mensagemErro">
                            <v-sheet :value="true" class="red--text">{{ validacao.mensagemErro }}</v-sheet>
                          </v-flex>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="headerEdicoes"
                      :items="edicoes"
                      :expanded.sync="expanded"
                      single-expand
                      show-expand
                      expand-icon="menu"
                      no-data-text
                      no-results-text
                      :footer-props="{
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageOptions: [12, 25, 50]
                      }"
                    >
                      <template v-slot:[getItemActions()]="{ item }">
                        <v-icon
                          v-if="(editar && !['RECUPERAÇÃO', 'CANCELADA', 'EXPIRADA'].includes(assinatura.status)) || renovacaoCorrente || assinatura.ultimaRenovacao == idRenovacao"
                          color="green lighten-2"
                          title="Agendar Endereço"
                          @click="addEndereco(item)"
                          >add_circle</v-icon
                        >
                      </template>

                      <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                          <v-data-table mobile-breakpoint="0" :headers="headerEnderecos" :items="item.enderecos" no-data-text no-results-text hide-default-footer>
                            <template v-slot:[getItemActions()]="{ item }">
                              <!-- {{ item }} -->
                              <v-icon v-if="item.editar && !item.ultimo" class="mr-2" color="red" title="Excluir Endereço" @click="excluirEndereco(item)">delete</v-icon>
                              <v-icon v-if="item.editar" color="blue lighten-2" title="Editar Endereço" @click="editarEndereco(item)">edit</v-icon>
                            </template>
                            <template v-slot:[getItemId()]="{ item }">
                              {{ item.id + "/" + item.idEndereco }}
                            </template>
                            <template v-slot:[getItemEnderecoCompleto()]="{ item }">
                              {{ item.enderecoCompleto + (item.enderecoComplemento ? " | " + item.enderecoComplemento : "") }}
                            </template>
                            <template v-slot:[getItemDtRegistro()]="{ item }">
                              {{ formatDateTime(item.dtRegistro) }}
                            </template>
                            <template v-slot:item.online="{ item }">
                              <span v-html="item.online == 1 ? 'Sim' : ''"></span>
                            </template>
                          </v-data-table>
                        </td>
                      </template>
                    </v-data-table>
                    <v-alert v-if="validacao.edicoes" :value="true" color="error" icon="priority_high" outlined>{{ validacao.edicoes }}</v-alert>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.agentes ? 'red--text' : ''">
                <strong>Agentes/Agências</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-toolbar>
                      <v-spacer></v-spacer>
                      <v-dialog persistent v-model="dialogAgente" max-width="600px">
                        <template v-slot:activator="{ on }">
                          <v-btn v-if="editar && (idAssinatura == '' || direitoAlterarAgentes)" color="success" fab small v-on="on">
                            <v-icon color="white" title="Incluir">add</v-icon>
                            <!-- se incluir um agente em uma renovação existente, a mesma será encerrada na data de hoje e iniciada nova com a nova configuração de agentes -->
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form ref="formAgente" v-model="agenteValid" lazy-validation>
                            <v-toolbar>
                              <span class="headline">Agente/Agência</span>
                              <v-spacer></v-spacer>
                              <v-btn icon @click="closeAgente">
                                <v-icon color="darken-1">cancel</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-container fluid grid-list-md>
                                <v-layout wrap>
                                  <v-flex xs12 md12>
                                    <v-autocomplete
                                      autofocus
                                      v-model="agenteSelecionado"
                                      :items="agentesPlano"
                                      :filter="filtrarObjetosIdentificacao"
                                      :rules="[validacao.agente == false || validacao.agente, value => (!!value && !!value.idAgente) || 'Obrigatório.']"
                                      hint="Obrigatório"
                                      label="Selecione o Agente"
                                      item-text="agente"
                                      item-value="idAgente"
                                      no-data-text
                                      return-object
                                    ></v-autocomplete>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" @click="closeAgente">Retornar</v-btn>
                              <v-btn color="blue darken-1" @click="saveAgente">Atualizar</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="headerAgentes"
                      :items="agentes"
                      no-data-text
                      no-results-text
                      :footer-props="{
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageOptions: [10, 25, 50],
                        pageText: ''
                      }"
                    >
                      <template v-slot:[getItemActions()]="{ item }">
                        <v-icon v-if="editar && (idAssinatura == '' || direitoAlterarAgentes)" class="mr-2" color="red" title="Remover Agente" @click="removerAgente(item)"
                          >delete</v-icon
                        >
                        <v-icon v-if="editar && (idAssinatura == '' || direitoAlterarAgentes)" class="mr-2" color="blue lighten-2" title="Editar" @click="editarAgente(item)"
                          >edit</v-icon
                        >
                      </template>
                    </v-data-table>
                    <v-alert v-if="validacao.agentes" :value="true" color="error" icon="priority_high" outlined>{{ validacao.agentes }}</v-alert>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.parcelas ? 'red--text' : ''">
                <strong>Parcelas</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout wrap>
                  <v-flex xs12 md3 v-if="formaPagamentoSelecionar || parcelas.length == 0">
                    <v-autocomplete
                      v-model="formaPagamentoSelecionada"
                      :items="formasPgtoPlano"
                      :filter="filtrarObjetos"
                      label="Selecione a Forma de Pagamento"
                      item-text="formaPagamento"
                      item-value="idFormaPgto"
                      no-data-text
                      @change="carregaItensFormaPgto"
                      return-object
                      clearable
                    ></v-autocomplete>
                  </v-flex>

                  <v-flex xs12 md3 v-if="parcelas.length == 0">
                    <v-autocomplete
                      v-model="parcelamentoSelecionado"
                      :items="parcelamento"
                      :filter="filtrarObjetos"
                      label="Selecione o Parcelamento"
                      item-text="nome"
                      item-value="nome"
                      no-data-text
                      return-object
                    >
                      <template v-slot:item="data">
                        <v-list-item-content>
                          <v-list-item-title class="ml-2">{{ data.item.nro + " x " + data.item.vlrUnit }}</v-list-item-title>
                          <v-list-item-subtitle class="ml-2" v-html="'Total: <b>' + data.item.vlrTot + '</b>'"></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-flex>
                </v-layout>

                <v-flex
                  xs12
                  md12
                  v-if="formaPagamentoSelecionada && (formaPagamentoSelecionada.formaPagamentoTipo == 'CELESC' || formaPagamentoSelecionada.formaPagamentoTipo == 'SAMAE')"
                >
                  <v-autocomplete
                    v-model="idClienteCelesc"
                    :items="celescsCliente"
                    :filter="filtrarObjetos"
                    hint="Obrigatório"
                    :rules="[validacao.idClienteCelesc == false || validacao.idClienteCelesc, value => !!value || 'Obrigatório.']"
                    label="Selecione a Unidade Consumidora"
                    item-text="nome"
                    item-value="id"
                    no-data-text
                  ></v-autocomplete>
                </v-flex>

                <v-flex xs12 md12 v-if="formaPagamentoSelecionada && formaPagamentoSelecionada.formaPagamentoTipo == 'CARTAO_CREDITO'">
                  <v-autocomplete
                    v-model="idClienteCartao"
                    :items="cartoesCliente"
                    :filter="filtrarObjetos"
                    hint="Obrigatório"
                    :rules="[
                      validacao.idClienteCartao == false || validacao.idClienteCartao,
                      value => !cartoesCliente.length || (cartoesCliente.length && !!value) || 'Obrigatório.'
                    ]"
                    label="Selecione o Cartão de Crédito"
                    item-text="nome"
                    item-value="id"
                    no-data-text
                  ></v-autocomplete>
                </v-flex>
                <!-- se não desejar que seja obrigatório, usar forma CARTAO_MANUAL -->

                <v-flex xs12 md12 v-if="formaPagamentoSelecionada && formaPagamentoSelecionada.formaPagamentoTipo == 'DEBITO_CONTA'">
                  <v-autocomplete
                    v-model="idClienteConta"
                    :items="contasCliente"
                    :filter="filtrarObjetos"
                    hint="Obrigatório"
                    :rules="[validacao.idClienteConta == false || validacao.idClienteConta, value => !!value || 'Obrigatório.']"
                    label="Selecione a Conta Corrente"
                    item-text="nome"
                    item-value="id"
                    no-data-text
                  ></v-autocomplete>
                </v-flex>

                <v-flex
                  xs12
                  md12
                  v-if="formaPagamentoSelecionada && (formaPagamentoSelecionada.formaPagamentoTipo == 'BOLETO' || formaPagamentoSelecionada.formaPagamentoTipo == 'CARTEIRA')"
                >
                  <v-autocomplete
                    v-model="idClienteEndereco"
                    :items="enderecosCobrancaCliente"
                    :filter="filtrarObjetos"
                    hint="Obrigatório"
                    :rules="[validacao.idClienteEndereco == false || validacao.idClienteEndereco, value => !!value || 'Obrigatório.']"
                    label="Selecione o Endereço de Cobrança"
                    item-text="nome"
                    item-value="id"
                    no-data-text
                  ></v-autocomplete>
                </v-flex>

                <v-flex xs12 md12>
                  <v-card>
                    <v-toolbar>
                      <v-spacer></v-spacer>
                      <v-dialog persistent v-model="dialogParcela" max-width="700px">
                        <v-card>
                          <Parcela :id="idParcela" receber="true" :dialogParcela.sync="dialogParcela" />
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="headerParcelas"
                      :items="parcelas"
                      no-data-text
                      no-results-text
                      :footer-props="{
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageOptions: [12, 25, 50]
                      }"
                    >
                      <template v-slot:[getItemActions()]="{ item }">
                        <!-- NÃO VAI ABRIR A RENOVAÇÃO SE ESTIVER EXCLUÍDA <span class="red--text" v-if="!item.situacao"
                          >EXCLUÍDA</span
                        > -->
                        <v-icon v-if="item.id && editarParcelas" class="mr-2" color="blue lighten-2" title="Editar" @click="editarParcela(item)">edit</v-icon>
                      </template>
                    </v-data-table>
                    <v-alert v-if="validacao.parcelas" :value="true" color="error" icon="priority_high" outlined>{{ validacao.parcelas }}</v-alert>
                  </v-card>
                </v-flex>

                <v-flex xs12 md3 v-if="parcelas.length > 0 && parcelas[0].id && direitoParcelas">
                  <v-icon color="blue lighten-2" title="Abrir no Financeiro" @click="visualizarParcelas">add_circle_outline</v-icon>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">{{ validacao.mensagemErro }}</v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>
    <v-card-actions id="flex-wrap">
      <!-- salva como proposta e recarrega para edição de parcelas por exemplo -->
      <v-btn
        :to="idAssinatura == '' || idRenovacao == '' ? '/assinaturas/assinaturas' : '/assinaturas/assinatura/' + idAssinatura"
        color="orange lighten-2 white"
        style="color:white "
        id="btn-rsn"
      >
        <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar
      </v-btn>
      <v-btn
        v-if="editar || idAssinatura == '' || idRenovacao == ''"
        :loading="isUpdating"
        :disabled="bloqueiaBotoes"
        color="blue white"
        style="color:white"
        id="btn-rsn"
        depressed
        @click="salvar('corrente')"
        :title="renovacao.renovacao || assinatura.status != 'PROPOSTA' ? 'Salva a alteração na renovação' : 'Salva a assinatura tornando-a CORRENTE'"
      >
        <v-icon id="icon-rsn" left>update</v-icon>Salvar
      </v-btn>
      <v-btn
        v-if="assinatura.ultimaRenovacao == idRenovacao && (renovacaoCorrente || assinaturaExpirada) && direitoRenovar"
        :loading="isUpdating"
        :disabled="bloqueiaBotoes"
        color="blue lighten-2"
        style="color:white"
        id="btn-rsn"
        depressed
        @click="renovar('renovar')"
        :title="titleRenovar"
      >
        <v-icon id="icon-rsn" left>update</v-icon>Renovar
      </v-btn>
      <v-btn
        v-if="
          assinatura.ultimaRenovacao == idRenovacao && (renovacaoCorrente || assinaturaExpirada) && renovacao.dtFim && new Date(renovacao.dtFim) < new Date() && direitoRenovarUnir
        "
        :loading="isUpdating"
        :disabled="bloqueiaBotoes"
        color="blue lighten-2"
        style="color:white"
        id="btn-rsn"
        depressed
        @click="renovar('emendar')"
        :title="titleRenovarUnir"
      >
        <v-icon id="icon-rsn" left>update</v-icon>Renovar e Unir
      </v-btn>
      <v-btn
        v-if="renovacaoCorrente && assinatura.ultimaRenovacao == idRenovacao && renovacao.dtFim && new Date(renovacao.dtFim) > dataInicio && direitoFinalizarRenovar"
        :loading="isUpdating"
        :disabled="bloqueiaBotoes"
        color="blue lighten-2"
        style="color:white"
        id="btn-rsn"
        depressed
        @click="renovar('finalizar')"
        :title="titleFinalizarRenovar"
      >
        <v-icon id="icon-rsn" left>update</v-icon>Finalizar e Renovar
      </v-btn>
      <v-btn
        v-if="idAssinatura == '' || idRenovacao == ''"
        :loading="isUpdating"
        :disabled="bloqueiaBotoes"
        color="blue white"
        style="color:white"
        id="btn-rsn"
        outlined
        @click="salvar('nova')"
        title="Salva e limpa a tela para nova captação"
      >
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Nova
      </v-btn>
      <v-btn
        v-if="idAssinatura == '' || idRenovacao == ''"
        :loading="isUpdating"
        :disabled="bloqueiaBotoes"
        color="blue lighten-2"
        style="color:white"
        id="btn-rsn"
        depressed
        @click="salvar('proposta')"
        title="Salva a assinatura como Proposta"
      >
        <v-icon id="icon-rsn" left>update</v-icon>Salvar Proposta
      </v-btn>
      <v-btn
        v-if="forcarExclusao ? direitoForcarExclusao : direitoExcluir"
        :loading="isUpdating"
        :disabled="bloqueiaBotoes"
        color="red white"
        style="color:white"
        id="btn-rsn"
        depressed
        @click="excluirRenovacao"
        :title="forcarExclusao ? 'Exclui a renovação e todo seu histórico de entregas e cobrança' : 'Exclui a renovação'"
      >
        <v-icon id="icon-rsn" left>delete</v-icon>{{ forcarExclusao ? "Forçar Exclusão" : "Excluir" }}
      </v-btn>
    </v-card-actions>

    <v-dialog persistent v-model="dialogConfirm" max-width="290">
      <v-card>
        <v-toolbar>
          <span class="headline">Confirmação</span>
        </v-toolbar>
        <v-card-text style="font-weight: bold;"><br />{{ msgConfirm }}</v-card-text>
        <v-card-actions>
          <v-btn :loading="excluindo" :disabled="excluindo" color="red darken-1" text @click="naoConfirmado">Não</v-btn>
          <v-btn :loading="excluindo" :disabled="excluindo" color="green darken-1" text @click="confirmado">Sim</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="dialogBonificacao" max-width="600">
      <v-card>
        <v-toolbar>
          <span class="headline">Bonificação</span>
        </v-toolbar>
        <v-form ref="formBonificacao" v-model="bonificacaoValid" lazy-validation>
          <v-card-text>
            <v-container>
              <v-flex xs-12 text-center>
                <h3>
                  Informe o número de dias a adicionar ao final do período
                </h3>
                <br />
              </v-flex>
              <v-flex offset-xs4 xs4>
                <v-text-field
                  autofocus
                  v-model="nDias"
                  label="Bonificar"
                  suffix="dias"
                  maxlength="2"
                  v-mask="'##'"
                  hint="Obrigatório"
                  :rules="[value => !!value || 'Obrigatório.']"
                  color="blue-grey lighten-2"
                  autocomplete="disabled"
                  :name="Math.random()"
                ></v-text-field>
              </v-flex>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn color="red darken-1" text @click="dialogBonificacao = false">Retornar</v-btn>
            <v-btn color="green darken-1" text @click="bonifica">Bonificar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import Parcela from "@/views/fin/Parcela.vue";
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  components: {
    Parcela
  },
  data() {
    return {
      assinatura: {
        status: "PROPOSTA",
        idSite: null,
        online: 0,
        rede: 0
      },
      renovacao: {
        idAssinatura: null,
        renovacao: 0,
        idOrigem: null,
        autorizacao: "",
        idPlano: null,
        idCliente: null,
        idDeptoCliente: null,
        dtInicio: null,
        dtF: "",
        dtFim: null,
        recriarParcelas: false,
        reinicio: 0,
        vip: 0
      },

      dataInicio: null,
      titleRenovar: "",
      titleRenovarUnir: "",
      titleFinalizarRenovar: "",
      dtInicio: null,
      dtInicioUS: null,
      mnuDtInicio: false,
      vlrBruto: "",
      vlrLiquido: "",
      vlrTaxado: 0,
      carencia: 0,
      limiteCarencia: null,
      taxar: false,
      alterado: false, //para permitir alterar a origem (pois a única coisa que justifica sua alteração após a captação é um reinício)

      ultimoAnuncio: {},
      loadingUltAnu: false,
      ultimaAssinatura: {},
      loadingUltAss: false,

      origensVenda: [],
      produtos: [],
      produtoAnterior: {},
      edicoes: [],
      agentes: [],
      parcelas: [],
      validacao: {
        status: false,
        idSite: false,
        idOrigem: false,
        autorizacao: false,
        idPlano: false,
        idCliente: false,
        idDeptoCliente: false,
        dtInicio: false,
        dtFim: false,
        vlrTaxado: false,
        carencia: false,
        produtos: false,
        edicoes: false,
        agente: false,
        agentes: false,
        parcelas: false,
        edicao_idEndereco: false,
        edicao_dtInicio: false,
        idFormaPgto: false,
        idClienteCartao: false,
        idClienteConta: false,
        idClienteEndereco: false,
        idClienteCelesc: false,
        reinicio: false,
        mensagemErro: false
      },

      idCliente: null,
      idClienteCartao: null,
      idClienteConta: null,
      idClienteEndereco: null,
      idClienteCelesc: null,

      planos: [],
      clientes: [],
      departamentosCliente: [],
      nroDependentes: 1,
      produtosPlano: [],
      agentesPlano: [],
      formasPgtoPlano: [],
      planoSelecionado: {},
      clienteSelecionado: {},
      formaPagamentoSelecionada: {
        idFormaPgto: null,
        nome: "",
        formaPagamento: "",
        formaPagamentoTipo: "",
        maxParcelas: 0
      },
      formaPagamentoSelecionar: false,
      parcelamentoSelecionado: {},
      parcelamento: [],

      cartoesCliente: [],
      celescsCliente: [],
      contasCliente: [],
      enderecosCobrancaCliente: [],
      enderecosEntregaCliente: [],
      enderecosEntregaClienteEdicao: [],
      produtoImpresso: false,
      itensEdicoes: ["TODAS"],

      headerProdutos: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Produto", value: "produto" },
        { text: "Edições/Acessos", value: "edicoes" },
        { text: "Valor", value: "bruto" }
      ],
      headerEdicoes: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Edicao", value: "edicao" },
        { text: "Número", value: "nroEdicao" }
      ],
      headerEnderecos: [
        { text: "Ações", value: "actions", width: "100" },
        { text: "ID", value: "id" },
        { text: "Endereço", value: "enderecoCompleto" },
        { text: "Início", value: "dtI" },
        { text: "Usuário", value: "usuario" },
        { text: "Registro", value: "dtRegistro" },
        { text: "Online", value: "online" }
      ],
      headerAgentes: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Agente/Agência", value: "agente" }
      ],
      headerParcelas: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Índice", value: "indice" },
        { text: "Parcela", value: "numero" },
        { text: "Status", value: "status" },
        { text: "Forma de Pagamento", value: "formaPagamento" },
        { text: "Vencimento Original", value: "dtVectoO" },
        { text: "Promessa", value: "dtPromessa" },
        { text: "Lote", value: "idLoteFaturamento" },
        { text: "NF", value: "idNF" },
        { text: "Valor", value: "liquido", align: "end" }
      ],

      //* Parâmetros dos modais
      produtoSelecionado: {},
      dialogProduto: false,
      produtoValid: true,
      produtoIndex: -1,
      produtoItem: {
        id: null,
        idProduto: null,
        edicoes: 1,
        valor: 0
      },
      defaultProdutoItem: {
        id: null,
        idProduto: null,
        edicoes: 1,
        valor: 0
      },

      expanded: [],
      enderecoSelecionado: {},
      dialogEdicao: false,
      edicaoValid: true,
      edicaoIndex: -1,
      edicaoItem: {
        id: null,
        edicao: "[TODAS]",
        enderecos: []
      },
      defaultEdicaoItem: {
        id: null,
        edicao: "[TODAS]",
        enderecos: []
      },
      enderecoIndex: -1,

      dtInicioEndereco: null,
      dtInicioEnderecoUS: null,
      mnuDtEnderecoItem: false,

      enderecoItem: {
        id: null,
        edicao: "TODAS",
        idRenovacaoEdicao: null,
        idEndereco: null,
        dtInicio: null,
        corrigir: 0
      },
      defaultEnderecoItem: {
        id: null,
        edicao: "TODAS",
        idRenovacaoEdicao: null,
        idEndereco: null,
        dtInicio: null,
        corrigir: 0
      },

      agenteSelecionado: {},
      dialogAgente: false,
      agenteValid: true,
      agenteIndex: -1,
      agenteItem: {
        id: null,
        idAgente: null,
        agente: "",
        comissao: 0
      },
      defaultAgenteItem: {
        id: null,
        idAgente: null,
        agente: "",
        comissao: 0
      },

      //* Dados para o componente DialogParcela
      idParcela: "",
      dialogParcela: false,

      idAssinatura: "",
      idRenovacao: "",
      idSitePrincipal: null,
      elementoValid: true,
      painel: [0, 0, 0, 0],
      direitoTaxar: false,
      direitoForcarExclusao: false,
      forcarExclusao: false,
      direitoAssinante: false,
      direitoParcelas: false,
      direitoReinicio: false,
      direitoCorrigirEdicao: false,
      direitoMigrarAcervo: false,

      direitoAlterarPlano: false,
      direitoAlterarAssinante: false,
      direitoAlterarInicio: false,
      direitoBonificar: false,
      direitoDefinirCarencia: false,
      direitoAlterarProdutos: false,
      direitoAlterarAgentes: false,
      direitoRenovar: false,
      direitoRenovarUnir: false,
      direitoFinalizarRenovar: false,
      direitoExcluir: false,

      editar: true,
      editarParcelas: true,
      renovacaoCorrente: false,
      assinaturaExpirada: false,

      dialogConfirm: false,
      msgConfirm: "",
      tipoConfirm: "",
      excluindo: false,

      dialogBonificacao: false,
      bonificacaoValid: true,
      nDias: 0,
      bonificar: false,

      snackbar: false,
      snackErro: false,
      mensagem: "",
      search: "",
      loadingClientes: false,

      isUpdating: false,
      bloqueiaBotoes: false,
      carregandoCli: true,
      carregandoPla: true
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    getItemActions() {
      return "item.actions";
    },
    getItemId() {
      return "item.id";
    },
    getItemEnderecoCompleto() {
      return "item.enderecoCompleto";
    },
    getItemDtRegistro() {
      return "item.dtRegistro";
    },

    async salvar(tipoSalvar) {
      await util.limparObjeto(this.validacao, false);
      var erro = false;
      if (this.produtos.length == 0) {
        erro = true;
        this.validacao.produtos = "Inclua pelo menos 1 produto";
      }
      if (this.agentes.length == 0) {
        erro = true;
        this.validacao.agentes = "Inclua pelo menos 1 agente";
      }
      if (this.agentes.length > 4) {
        erro = true;
        this.validacao.agentes = "Máximo de agentes permitidos: 4";
      }
      if (this.parcelas.length == 0) {
        erro = true;
        this.validacao.parcelas = "Inclua pelo menos 1 parcela";
      }

      if (this.parcelas.length > 0) {
        //* Validação incluída pois 7 contratos geraram parcelas com número 1 duplicado
        if (this.parcelas[0].numero == 1) {
          for (let index = 1; index <= this.parcelas.length; index++) {
            const element = this.parcelas[index - 1];
            if (index != element.numero) {
              erro = true;
              this.validacao.parcelas = "Número de parcela inválido: " + element.numero;
            }
          }
        } else {
          var x = 0;
          for (let index = this.parcelas.length; index > 0; index--) {
            x++;
            const element = this.parcelas[index - 1];
            if (x != element.numero) {
              erro = true;
              this.validacao.parcelas = "Número de parcela inválido: " + element.numero;
            }
          }
        }
      }

      if (!this.$refs.formElemento.validate() || erro) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      //* Se entrou aqui é porque a renovação é futura, permite alterar

      //* tipoSalvar:
      //'corrente' = salva, deixando como CORRENTE (se status for PROPOSTA)
      //'proposta' = salva como proposta para ajuste antes de tornar CORRENTE - salvar('corrente')
      //'nova' - salva e abre tela /assinatura para incluir uma nova

      if (tipoSalvar != "proposta" && this.assinatura.status == "PROPOSTA") {
        this.assinatura.status = "CORRENTE";
      }
      var assinaturaCommit = {
        idSite: this.assinatura.idSite,
        idOrigem: this.renovacao.idOrigem,
        status: this.assinatura.status,
        online: this.assinatura.online,
        rede: this.assinatura.rede,
        carencia: this.carencia,
        idRegraAjuste: this.assinatura.idRegraAjuste
      };

      var renovacaoCommit = {
        idAssinatura: this.idAssinatura ? parseInt(this.idAssinatura) : null,
        id: this.idRenovacao ? parseInt(this.idRenovacao) : null,
        renovacao: this.renovacao.renovacao,
        idOrigem: this.renovacao.idOrigem,
        idPlano: this.renovacao.idPlano,
        dtInicio: util.formatDateUS(this.dtInicio),
        dtFim: util.formatDateUS(this.renovacao.dtF),
        autorizacao: this.renovacao.autorizacao,
        recriarParcelas: this.renovacao.recriarParcelas,
        dtFimAnterior: this.renovacao.dtFimAnterior,
        reinicio: this.renovacao.reinicio,
        vip: this.renovacao.vip,
        trocaTitularidade: this.renovacao.trocaTitularidade
      };

      var existeImpresso = false;
      var vlrBruto = 0; //* valor de tabela total da renovação
      this.produtos.forEach(element => {
        if (element.produtoTipo == "IMPRESSO") {
          existeImpresso = true;
        }
      });
      this.produtos.forEach(element => {
        if (existeImpresso && element.produtoTipo == "ONLINE") {
          //* Se existe impresso, multiplica somente as edições do impresso - não multiplica as edições do online
          vlrBruto += parseFloat(element.valor);
        } else {
          vlrBruto += parseFloat(element.valor) * parseInt(element.edicoes);
        }
      });
      var vlrCobrado = 0; //* valor cobrado original da renovação
      // if (this.editar) {
      //* Só alterar até o início da renovação
      this.parcelas.forEach(element => {
        vlrCobrado += parseFloat(element.valor); //+ parseFloat(element.descAcr);//cobrado é a soma das parcelas - descAcr é dado depois
      });
      // }

      var operacaoCommit = {
        id: this.renovacao.idOperacao,
        idSite: this.assinatura.idSite,
        tipo: 1,
        idCliente: this.renovacao.idCliente,
        idDeptoCliente: this.renovacao.idDeptoCliente,
        origem: "ASS",
        vlrBruto: vlrBruto,
        vlrLiquido: this.vlrTaxado ? this.vlrTaxado : vlrCobrado,
        vlrTaxado: this.vlrTaxado,
        nroParcelas: this.parcelas.length,
        idUsuario: this.$store.state.usuario.id,
        mensal: 0,
        confirmada: 1
      };

      //* Prepara os produtos da assinatura para envio a base
      var produtosCommit = [];
      var produtosVal = [];
      var produtoDuplicado = "";
      this.produtos.forEach(element => {
        if (produtosVal.includes(element.produto)) {
          produtoDuplicado = element.produto;
        }
        produtosVal.push(element.produto);
        produtosCommit.push({
          id: element.id,
          idRenovacao: this.idRenovacao,
          idProduto: element.idProduto,
          tipo: element.produtoTipo,
          nome: element.nome,
          edicoes: element.edicoes,
          valor: element.valor
        });
      });
      if (produtoDuplicado) {
        this.validacao.produtos = "Produto duplicado [" + produtoDuplicado + "]";
      }

      //* Prepara os agentes da assinatura para envio a base
      var agentesCommit = [];
      var agentesVal = [];
      var agenteDuplicado = "";
      this.agentes.forEach(element => {
        if (agentesVal.includes(element.agente)) {
          agenteDuplicado = element.agente;
        }
        agentesVal.push(element.agente);
        agentesCommit.push({
          id: element.id,
          idOperacao: this.renovacao.idOperacao,
          idAgente: element.idAgente,
          comissao: 0
        });
      });
      if (agenteDuplicado) {
        this.validacao.agentes = "Agente duplicado [" + agenteDuplicado + "]";
      }

      if (existeImpresso && this.enderecosEntregaCliente.length == 0) {
        produtoDuplicado = true;
        this.validacao.produtos = "Para produto IMPRESSO, cliente precisa ter pelo menos 1 endereço de entrega ativo";
      }

      if (produtoDuplicado || agenteDuplicado) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      //* Prepara as parcelas da assinatura para envio a base
      var idFormaPgtoPadrao = null;
      var nroParcelasPadrao = this.parcelas.length;
      var parcelasCommit = [];
      this.parcelas.forEach(element => {
        if (!idFormaPgtoPadrao) {
          idFormaPgtoPadrao = element.idFormaPgto;
        }
        parcelasCommit.push({
          id: element.id,
          idOperacao: element.idOperacao,
          indice: element.indice,
          numero: element.numero,
          status: this.assinatura.status == "PROPOSTA" ? "PROPOSTA" : util.isFormaPgtoValorZero(element.formaPagamentoTipo) ? "QUITADA" : "ABERTA",
          idFormaPgto: element.idFormaPgto,
          formaPagamentoTipo: element.formaPagamentoTipo,
          dtVectoOriginal: element.dtVectoOriginal,
          dtVecto: element.dtVecto ? element.dtVecto : element.dtVectoOriginal,
          idClienteCartao: element.idClienteCartao ? element.idClienteCartao : this.idClienteCartao,
          idClienteConta: element.idClienteConta ? element.idClienteConta : this.idClienteConta,
          idClienteEndereco: element.idClienteEndereco ? element.idClienteEndereco : this.idClienteEndereco,
          idClienteCelesc: element.idClienteCelesc ? element.idClienteCelesc : this.idClienteCelesc,
          //valorBruto: será utilizado para desconto de comissão (Comercial)
          valor: element.valor,
          descAcr: element.descAcr,
          //valorExcedente: não seria melhor já gerar o título com este valor proporcional até o fim do mês?
          //descontoComissao: já usar o próprio vlrBruto (ou vlrLiquido), pois era gambiarra porque o Comercial não possuia
          obsCobranca: element.obsCobranca,
          ajustada: element.ajustada
        });
      });

      var edicoesCommit = [];
      //* Prepara as edições da assinatura para envio a base
      if (this.idAssinatura && this.idRenovacao && this.edicoes.length != 0) {
        //* Se for somente alteração de edição (inclusão/agendamento de endereço), envia a lista alterada
        assinaturaCommit.alteracao = true;
        this.edicoes.forEach(element => {
          var edicaoCommit = {
            id: element.id,
            idRenovacao: this.idRenovacao,
            edicao: element.edicao,
            nroEdicao: element.nroEdicao,
            enderecos: []
          };

          var enderecosCommit = [];
          element.enderecos.forEach(endereco => {
            enderecosCommit.push({
              id: endereco.id,
              idRenovacaoEdicao: element.id,
              idEndereco: endereco.idEndereco,
              dtInicio: endereco.dtInicio,
              idUsuario: endereco.idUsuario
            });
          });

          Array.prototype.push.apply(edicaoCommit.enderecos, enderecosCommit);
          edicoesCommit.push(edicaoCommit);
        });
      } else {
        //* Se for captação (ou houve alteração do número de edições no produto impresso), calcula as edições
        assinaturaCommit.alteracao = false;

        if (existeImpresso) {
          var idEndereco = this.enderecosEntregaCliente[0].id;
          this.produtos.forEach(prod => {
            if (prod.produtoTipo == "IMPRESSO") {
              this.produtosPlano.forEach(prodPlano => {
                if (prod.idProduto == prodPlano.idProduto) {
                  var itens = prodPlano.periodo.split(";");
                  //* Agenda um endereço (renovacao_edicao) para cada edição (renovacao_produto.edicoes)
                  for (var x = 1; x <= prod.edicoes; x++) {
                    itens.forEach(item => {
                      if (item) {
                        var edicaoCommit = {
                          edicao: item,
                          nroEdicao: x,
                          enderecos: []
                        };

                        var enderecosCommit = [];
                        enderecosCommit.push({
                          id: null,
                          idRenovacaoEdicao: null,
                          idEndereco: idEndereco,
                          dtInicio: util.formatDateUS(this.dtInicio),
                          idUsuario: this.$store.state.usuario.id
                        });

                        Array.prototype.push.apply(edicaoCommit.enderecos, enderecosCommit);
                        edicoesCommit.push(edicaoCommit);
                      }
                    });
                  }
                }
              });
            }
          });
        }
      }

      assinaturaCommit.renovacao = renovacaoCommit;
      assinaturaCommit.operacao = operacaoCommit;
      assinaturaCommit.produtos = produtosCommit;
      assinaturaCommit.agentes = agentesCommit;
      assinaturaCommit.parcelas = parcelasCommit;
      assinaturaCommit.edicoes = edicoesCommit;

      this.isUpdating = true;
      try {
        var retorno = "";
        //se tiver id é um update
        if (this.idAssinatura && this.idRenovacao) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "ass/assinatura/renovacao/" + this.idAssinatura + "/" + this.idRenovacao, assinaturaCommit);
        } else {
          assinaturaCommit.idFormaPgto = idFormaPgtoPadrao;
          assinaturaCommit.nroParcelas = nroParcelasPadrao;

          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "ass/assinatura/renovacao?idSite=" + this.idSitePrincipal, assinaturaCommit);
          if (tipoSalvar == "nova") {
            this.$router.push("/assinaturas/assinatura");
            this.$router.go("/assinaturas/assinatura");
            return;
          } else {
            this.idAssinatura = retorno.data.idAssinatura + "";
            this.idRenovacao = retorno.data.idRenovacao + "";
          }
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;

        //* É melhor recarregar toda a tela, pois há muitos parâmetros a serem ajustados aqui
        this.$router.push("/assinaturas/assinatura/" + this.idAssinatura + "/" + this.idRenovacao);
        this.$router.go("/assinaturas/assinatura/" + this.idAssinatura + "/" + this.idRenovacao);
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
        this.isUpdating = false;
      }
    },

    excluirRenovacao() {
      this.msgConfirm = this.forcarExclusao
        ? "ESTE PERÍODO JÁ FOI INICIADO, ENTÃO TODO O HISTÓRICO DE ENTREGAS E COBRANÇA SERÁ EXCLUÍDO!! Tem absoluta certeza que deseja excluir esta renovação?"
        : "Tem certeza que deseja excluir esta renovação?";
      this.tipoConfirm = "REN";
      this.dialogConfirm = true;
    },
    async excluiRenovacao() {
      try {
        this.excluindo = true;
        await api.delete(
          process.env.VUE_APP_URL_WEB_API +
            "ass/renovacao/" +
            this.idAssinatura +
            "/" +
            this.idRenovacao +
            "?idSite=" +
            this.idSitePrincipal +
            (this.forcarExclusao ? "&forcarExclusao=1" + (!this.renovacao.renovacao ? "&excluirAssinatura=1" : "") : "")
        );
        this.dialogConfirm = false;
        this.mensagem = "Renovação excluída com sucesso. Retornando à tela principal da assinatura...";
        this.snackbar = true;
        setTimeout(() => {
          this.dialogConfirm = false;
          this.$router.push("/assinaturas/assinatura/" + this.idAssinatura);
        }, 3000);
      } catch (e) {
        if (this.direitoForcarExclusao) {
          this.forcarExclusao = true;
        }
        this.dialogConfirm = false;
        this.mensagem = "Ocorreu um erro indeterminado ao tentar excluir a renovação.";
        if (e.response.data && e.response.data.length > 0) {
          this.mensagem = e.response.data[0].mensagem;
        }
        this.snackErro = true;
      } finally {
        this.excluindo = false;
      }
    },
    async importarAcervo() {
      try {
        this.excluindo = true;
        await api.post(process.env.VUE_APP_URL_WEB_API + "adm/acervo-cliente/producao/" + this.clienteSelecionado.id + "?idSite=" + this.idSitePrincipal);
        this.mensagem = "Assinante foi enviado para a base de produção";
        this.snackbar = true;
        this.dialogConfirm = false;
        this.buscarCliente(this.clienteSelecionado.identificacao);
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      } finally {
        this.excluindo = false;
      }
    },
    async bonifica() {
      if (!this.$refs.formBonificacao.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      var dtFim = new Date(this.renovacao.dtFim);
      dtFim.setDate(dtFim.getDate() + parseInt(this.nDias));

      var dtInicioEnd = new Date(this.renovacao.dtFim);
      dtInicioEnd.setDate(dtInicioEnd.getDate() + 1);

      var assinaturaFimCommit = {};

      var renovacaoFimCommit = {
        idAssinatura: this.idAssinatura,
        id: this.idRenovacao,
        renovacao: this.renovacao.renovacao,
        idPlano: this.renovacao.idPlano,
        dtInicio: this.renovacao.dtInicio,
        dtFim: dtFim,
        autorizacao: this.renovacao.autorizacao,
        recriarParcelas: this.renovacao.recriarParcelas,
        reinicio: this.renovacao.reinicio
      };

      var edicoesFimCommit = [];
      assinaturaFimCommit.alteracao = true; //para não excluir as edições (somente recalcular com base na data de fim)
      assinaturaFimCommit.finalizar = true;
      this.edicoes.forEach(element => {
        var edicaoCommit = {
          id: element.id,
          idRenovacao: this.idRenovacao,
          edicao: element.edicao,
          nroEdicao: element.nroEdicao,
          enderecos: []
        };

        var enderecosCommit = [];
        element.enderecos.forEach(endereco => {
          enderecosCommit.push({
            id: endereco.id,
            idRenovacaoEdicao: element.id,
            idEndereco: endereco.idEndereco,
            dtInicio: dtInicioEnd, //new Date(),
            idUsuario: endereco.idUsuario
          });
        });

        Array.prototype.push.apply(edicaoCommit.enderecos, enderecosCommit);
        edicoesFimCommit.push(edicaoCommit);
      });

      assinaturaFimCommit.edicoes = edicoesFimCommit;
      assinaturaFimCommit.renovacao = renovacaoFimCommit;

      try {
        await api.put(process.env.VUE_APP_URL_WEB_API + "ass/renovacao/bonificar/" + this.idRenovacao, assinaturaFimCommit);

        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;

        //* É melhor recarregar toda a tela, pois há muitos parâmetros a serem ajustados aqui
        this.$router.push("/assinaturas/assinatura/" + this.idAssinatura + "/" + this.idRenovacao);
        this.$router.go("/assinaturas/assinatura/" + this.idAssinatura + "/" + this.idRenovacao);
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
        this.isUpdating = false;
      }
    },
    confirmado() {
      if (this.tipoConfirm == "END") {
        this.dialogConfirm = false;
        this.excluiEndereco();
      } else if (this.tipoConfirm == "ACERVO") {
        this.importarAcervo();
      } else {
        this.excluiRenovacao();
      }
    },
    naoConfirmado() {
      this.dialogConfirm = false;
      if (this.tipoConfirm == "END") {
        this.edicaoItem = Object.assign({}, this.defaultEdicaoItem);
        this.edicaoIndex = -1;

        this.enderecoItem = Object.assign({}, this.defaultEnderecoItem);
        this.dtInicioEnderecoUS = null;
        this.enderecoIndex = -1;
        this.enderecoSelecionado = {};
      } else if (this.tipoConfirm == "ACERVO") {
        this.clienteSelecionado = {};
      }
    },

    editarCliente() {
      if (this.direitoAssinante) {
        window.open("/assinaturas/assinante/" + this.renovacao.idCliente, "_blank");
      }
    },
    limpaCliente() {
      this.clientes = [];
      this.cartoesCliente = [];
      this.celescsCliente = [];
      this.contasCliente = [];
      this.enderecosCobrancaCliente = [];
      this.enderecosEntregaCliente = [];
      this.enderecosEntregaClienteEdicao = [];
      this.renovacao.idCliente = null;
      this.renovacao.idDeptoCliente = null;
      this.departamentosCliente = [];
    },

    async renovar(tipoRenovar) {
      await util.limparObjeto(this.validacao, false);
      //* tipoRenovar:
      //'renovar' - cria nova renovação e abre tela para edita-la
      //'finalizar' - altera data de fim para hoje, salva, cria nova renovação e abre tela para edita-la
      //'emendar' - cria nova renovação emendando com a renovação anterior e abre tela para edita-la

      if (!["EXPIRADA", "CORRENTE"].includes(this.assinatura.status)) {
        this.mensagem = "Não é possível renovar assinatura " + this.assinatura.status + ". Deixe a assinatura CORRENTE e tente novamente";
        this.snackErro = true;
        return;
      }

      if (!this.clienteSelecionado.situacao || !this.clienteSelecionado.statusCredito) {
        this.mensagem = "Cliente encontra-se INATIVO ou com crédito BLOQUEADO";
        this.snackErro = true;
        return;
      }

      this.isUpdating = true;

      try {
        var assinaturaFimCommit = {};

        if (tipoRenovar == "finalizar") {
          //* Finaliza a renovação atual na data de hoje
          var renovacaoFimCommit = {
            idAssinatura: this.idAssinatura,
            id: this.idRenovacao,
            renovacao: this.renovacao.renovacao,
            idPlano: this.renovacao.idPlano,
            dtInicio: new Date(),
            dtFim: new Date(),
            autorizacao: this.renovacao.autorizacao,
            recriarParcelas: this.renovacao.recriarParcelas,
            reinicio: this.renovacao.reinicio,
            vip: this.renovacao.vip
          };

          var edicoesFimCommit = [];
          assinaturaFimCommit.alteracao = true; //para não excluir as edições (somente recalcular com base na data de fim)
          assinaturaFimCommit.finalizar = true;
          this.edicoes.forEach(element => {
            var edicaoCommit = {
              id: element.id,
              idRenovacao: this.idRenovacao,
              edicao: element.edicao,
              nroEdicao: element.nroEdicao,
              enderecos: []
            };

            var enderecosCommit = [];
            element.enderecos.forEach(endereco => {
              enderecosCommit.push({
                id: endereco.id,
                idRenovacaoEdicao: element.id,
                idEndereco: endereco.idEndereco,
                dtInicio: new Date(),
                idUsuario: endereco.idUsuario
              });
            });

            Array.prototype.push.apply(edicaoCommit.enderecos, enderecosCommit);
            edicoesFimCommit.push(edicaoCommit);
          });

          assinaturaFimCommit.edicoes = edicoesFimCommit;
          assinaturaFimCommit.renovacao = renovacaoFimCommit;
        }

        //* Renova a assinatura - usando única requisição para Finalizar e Renovar - caso dê erro em uma delas, nada fará
        var retorno = await api.post(
          process.env.VUE_APP_URL_WEB_API +
            "ass/renovacao/renovar?idSite=" +
            this.idSitePrincipal +
            "&idAssinatura=" +
            this.idAssinatura +
            "&idUsuario=" +
            this.$store.state.usuario.id +
            (tipoRenovar == "finalizar"
              ? "&dtFim=" + util.formatDateUS(this.renovacao.dtF) + "&idRenovacaoFinalizar=" + this.idRenovacao
              : tipoRenovar == "emendar"
              ? "&emendar=1"
              : ""),
          assinaturaFimCommit
        );

        if (retorno.data.idAssinatura) {
          this.idAssinatura = retorno.data.idAssinatura + "";
          this.idRenovacao = retorno.data.idRenovacao + "";

          this.mensagem = "Dados foram atualizados";
          this.snackbar = true;
        }

        setTimeout(() => {
          this.$router.push("/assinaturas/assinatura/" + this.idAssinatura + "/" + this.idRenovacao);
          this.$router.go("/assinaturas/assinatura/" + this.idAssinatura + "/" + this.idRenovacao);
        }, 1000);
      } catch (e) {
        this.renovacaoCorrente = false;

        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }

        setTimeout(() => {
          this.$router.push("/assinaturas/assinatura/" + this.idAssinatura + "/" + this.idRenovacao);
          this.$router.go("/assinaturas/assinatura/" + this.idAssinatura + "/" + this.idRenovacao);
        }, 3000);
        return;
      } finally {
        this.isUpdating = false;
      }
    },

    async carregarOrigensVenda(tipo) {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/origem/" + tipo);
        if (response.data) {
          this.origensVenda = response.data;
          if (this.origensVenda.length == 1) {
            this.renovacao.idOrigem = this.origensVenda[0].id;
          } else {
            this.origensVenda.forEach(element => {
              if (element.sim) {
                this.renovacao.idOrigem = element.id;
              }
            });
          }
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as origens de venda";
        this.snackErro = true;
      }
    },
    async carregarPlanosSite(idSite) {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/plano/lista?idSite=" + idSite);
        if (response.data) {
          this.planos = response.data;
          this.planos.forEach(element => {
            if (element.id == this.renovacao.idPlano) {
              this.planoSelecionado = element;
              this.limiteCarencia = 0;
              if (this.planoSelecionado.periodo == "D") {
                this.limiteCarencia = this.planoSelecionado.tempo;
              } else {
                this.limiteCarencia = 90;
              }
            }
          });
          //* Obs: o site nunca muda, pois se o usuário mudar para outro site, não terá mais acesso a esta assinatura
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os planos de assinatura";
        this.snackErro = true;
      }
    },
    async carregarDepartamentosCliente() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-departamento/cliente/" + this.renovacao.idCliente + "?ativos=1");
        if (response.data) {
          this.departamentosCliente = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os departamentos do cliente";
        this.snackErro = true;
      }
    },
    async carregarNroDependentes() {
      try {
        this.nroDependentes = 1;
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-dependente/nroDependentes?idCliente=" + this.renovacao.idCliente);
        if (response.data) {
          this.nroDependentes += parseInt(response.data);
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar o número de dependentes do cliente";
        this.snackErro = true;
      }
    },

    async carregarUltimoAnuncio() {
      this.ultimoAnuncio = {};
      this.loadingUltAnu = true;
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "com/anuncio/cliente/ultimo/" + this.renovacao.idCliente);
      this.loadingUltAnu = false;
      if (response.data) {
        this.ultimoAnuncio = response.data;
        this.ultimoAnuncio.id = this.ultimoAnuncio.id + " - " + this.ultimoAnuncio.status;
        if (this.ultimoAnuncio.tipoCobranca == 1) {
          this.ultimoAnuncio.tamanho = `${this.ultimoAnuncio.altura} Centímetro${this.ultimoAnuncio.altura > 1 ? "s" : ""} x ${this.ultimoAnuncio.colunas} Coluna${
            this.ultimoAnuncio.colunas > 1 ? "s" : ""
          }`;
        } else if (this.ultimoAnuncio.tipoCobranca == 2) {
          this.ultimoAnuncio.tamanho = `${this.ultimoAnuncio.altura} x ${this.ultimoAnuncio.colunas}`;
        } else if (this.ultimoAnuncio.tipoCobranca == 3) {
          this.ultimoAnuncio.tamanho = `Largura ${this.ultimoAnuncio.colunas} cm x Altura ${this.ultimoAnuncio.altura} cm`;
        } else if (this.ultimoAnuncio.tipoCobranca == 4) {
          this.ultimoAnuncio.tamanho = `${this.ultimoAnuncio.palavras} Palavras`;
        } else if (this.ultimoAnuncio.tipoCobranca == 5) {
          this.ultimoAnuncio.tamanho = `${this.ultimoAnuncio.caracteres} Caracteres`;
        }
      }
    },
    async carregarUltimaAssinatura() {
      this.ultimaAssinatura = {};
      this.loadingUltAss = true;
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/assinatura/cliente/ultima/" + this.renovacao.idCliente + "?idAssinatura=" + this.idAssinatura);
      this.loadingUltAss = false;
      if (response.data) {
        this.ultimaAssinatura = response.data;
        this.ultimaAssinatura.id = this.ultimaAssinatura.id + " - " + this.ultimaAssinatura.status;
        this.ultimaAssinatura.periodo = `${util.formatDate(this.ultimaAssinatura.dtInicio)} a ${util.formatDate(this.ultimaAssinatura.dtFim)}`;
      }
    },

    async buscarCliente(identificacao) {
      if (identificacao) {
        this.search = identificacao;
      }
      if (this.loadingClientes || !this.search) {
        return;
      }

      this.loadingClientes = true;

      var filtro = "";
      if (util.valida_cpf(this.search) || util.valida_cnpj(this.search)) {
        filtro = "identificacao=" + this.search;
      } else {
        filtro = "nome=" + this.search + "&nomeFantasia=" + this.search;
      }

      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente?" + filtro + "&ordenacao=nome&ordem=asc&pagina=1&tamanho=50");
      if (response.data.data) {
        if (response.data.data.length == 1) {
          this.clientes = response.data.data;
          this.clienteSelecionado = this.clientes[0];
          this.renovacao.idCliente = this.clientes[0].id;
          this.recarregarItensCliente();
        } else if (response.data.data.length == 0) {
          var responseAcervo = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/acervo-cliente?" + filtro + "&ordenacao=nome&ordem=asc&pagina=1&tamanho=50");
          //this.clientes = responseAcervo.data.data;
          responseAcervo.data.data.forEach(element => {
            element.nome += " - " + element.identificacao;
            this.clientes.push(element);
          });
        } else {
          this.clientes = response.data.data;
        }
      }
      this.formaPagamentoSelecionada = {
        idFormaPgto: null,
        nome: "",
        formaPagamento: "",
        formaPagamentoTipo: "",
        maxParcelas: 0
      };
      this.idClienteEndereco = null;
      this.idClienteCelesc = null;
      this.idClienteCartao = null;
      this.idClienteConta = null;

      this.loadingClientes = false;
    },

    async carregarProdutos() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/renovacao-produto/renovacao/" + this.idRenovacao);
        if (response.data) {
          this.produtos = response.data;
          this.produtoImpresso = false;
          this.produtos.forEach(element => {
            element.nome = element.produto;
            element.bruto = util.formatCurrency(element.valor);
            if (element.produtoTipo == "IMPRESSO") {
              this.produtoImpresso = true;
              for (let index = 1; index <= element.edicoes; index++) {
                this.itensEdicoes.push(index.toString());
              }
            }
          });
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os produtos da assinatura";
        this.snackErro = true;
      }
    },

    async carregarEdicoes() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/renovacao-edicao/renovacao/" + this.idRenovacao);
        if (response.data) {
          var edi = response.data;
          const processar = edi.map(async element => {
            try {
              const responseEnd = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/renovacao-edicao-endereco/renovacaoEdicao/" + element.id);
              element.enderecos = responseEnd.data;
              var indice = 0;
              element.enderecos.forEach(endereco => {
                endereco.enderecoCompleto =
                  endereco.cep +
                  " - " +
                  endereco.tipo +
                  " " +
                  endereco.logradouro +
                  ", " +
                  endereco.numero +
                  (endereco.faixa ? " - " + endereco.faixa : "") +
                  " - " +
                  endereco.bairro +
                  " - " +
                  endereco.municipio +
                  "/" +
                  endereco.uf;

                endereco.enderecoComplemento =
                  (endereco.complemento ? endereco.complemento : "") +
                  (endereco.complementoTipo ? " - " + endereco.complementoTipo : "") +
                  (endereco.complementoNumero ? " - " + endereco.complementoNumero : "") +
                  (endereco.complementoBloco ? " - " + endereco.complementoBloco : "") +
                  (endereco.complementoEdificio ? " - " + endereco.complementoEdificio : "") +
                  (endereco.modoEntrega ? " - " + endereco.modoEntrega : "") +
                  (endereco.entregador ? " | " + endereco.entregador : "") +
                  (endereco.rota ? " | " + endereco.rota : "");

                endereco.dtI = this.formatDate(endereco.dtInicio);
                endereco.editar = util.parseDateUS(endereco.dtInicio) >= new Date();

                indice++;
                if (indice == element.enderecos.length) {
                  endereco.ultimo = true;
                }
              });
            } catch {
              this.isUpdating = true;
              this.mensagem = "Ocorreu um erro ao carregar os endereços da edição";
              this.snackErro = true;
            }
          });
          await Promise.all(processar);
          this.edicoes = edi;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as edições da renovação";
        this.snackErro = true;
      }
    },

    async carregarAgentesOperacao() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/operacao-agente/operacao/" + this.renovacao.idOperacao);
        if (response.data) {
          this.agentes = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os agentes da renovação";
        this.snackErro = true;
      }
    },

    async carregarParcelas() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/parcela/renovacao/" + this.idRenovacao);
        if (response.data) {
          this.parcelas = response.data;
          this.parcelas.forEach(element => {
            element.dtVectoO = util.formatDate(element.dtVectoOriginal);
            element.dtPromessa = util.formatDate(element.dtPromessa);
            element.liquido = util.formatCurrency(element.valor + element.descAcr);
            element.idNF = element.idNFe || element.idNFSC || element.idNFSe;
          });
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as parcelas da renovação";
        this.snackErro = true;
      }
    },

    async recarregarItensPlano() {
      if (this.carregando) {
        return;
      }

      //* limpa produtos/edicoes/agentes e parcelas da renovação (ao salvar, serão excluídos da base - exceto se algum título QUITADO ou EM COBRANÇA, pois renovação ainda não entrou em vigor)
      this.limpaParcelas("plano");
      this.produtos = [];
      this.agentes = [];
      this.edicoes = [];

      await this.carregarProdutosPlano();
      var existeImpresso = false;
      for (let index = 0; index < this.produtos.length; index++) {
        const element = this.produtos[index];
        if (element.produtoTipo == "IMPRESSO") {
          existeImpresso = true;
        }
      }
      if (!this.idRenovacao && existeImpresso) {
        await this.calcularDtInicio();
      } else {
        this.dataInicio = new Date();
        this.dataInicio.setDate(this.dataInicio.getDate() + 1);
      }

      //* Recalcula a data de fim
      await this.recalcularDtFim(this.idRenovacao ? false : true);

      await this.carregarAgentesPlano();
      await this.carregarFormasPgtoPlano();
    },

    recarregarItensCliente() {
      this.edicoes = [];
      this.carregarDepartamentosCliente();
      this.carregarNroDependentes();
      this.carregarEnderecos("entrega");
      this.carregarEnderecosEntregaCliente();
      this.carregaItensFormaPgto();
      if (this.idAssinatura) {
        //* Se cliente foi alterado
        this.editarParcelas = false;
      }

      this.renovacao.recriarParcelas = 1;
      this.verificaParcelaEmCobranca();
    },

    async carregarEnderecosEntregaCliente() {
      try {
        //* Carrega os endereços de entrega do cliente
        const responseEnderecosCliente = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-endereco/lista?idCliente=" + this.renovacao.idCliente + "&entrega=1");
        this.enderecosEntregaClienteEdicao = responseEnderecosCliente.data;

        this.enderecosEntregaClienteEdicao.forEach(endereco => {
          endereco.enderecoCompleto =
            endereco.cep + " - " + endereco.tipo + " " + endereco.logradouro + ", " + endereco.numero + " - " + endereco.bairro + " - " + endereco.municipio + "/" + endereco.uf;

          endereco.enderecoComplemento =
            (endereco.complemento ? endereco.complemento : "") +
            (endereco.complementoTipo ? " - " + endereco.complementoTipo : "") +
            (endereco.complementoNumero ? " - " + endereco.complementoNumero : "") +
            (endereco.complementoBloco ? " - " + endereco.complementoBloco : "") +
            (endereco.complementoEdificio ? " - " + endereco.complementoEdificio : "") +
            (endereco.modoEntrega ? " - " + endereco.modoEntrega : "") +
            (endereco.entregador ? " | " + endereco.entregador : "") +
            (endereco.rota ? " | " + endereco.rota : "");

          endereco.nome = endereco.enderecoComplemento + " - " + endereco.enderecoCompleto;
        });
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os endereços de entrega do cliente";
        this.snackErro = true;
      }
    },

    async recalcularDtFim(alterar) {
      //* Recalcula data de fim
      this.mnuDtInicio = false;
      if (this.planoSelecionado && this.planoSelecionado.id) {
        if (alterar || !this.dtInicioUS) {
          this.dtInicioUS = util.dateToStringUS(this.dataInicio);
        }

        this.renovacao.dtInicio = util.parseDateUS(this.dtInicioUS);

        var dtFim = util.parseDateUS(this.renovacao.dtInicio);
        if (this.planoSelecionado.periodo == "D") {
          dtFim.setDate(dtFim.getDate() + parseInt(this.planoSelecionado.tempo));
        } else {
          dtFim.setMonth(dtFim.getMonth() + parseInt(this.planoSelecionado.tempo));
        }
        dtFim.setDate(dtFim.getDate() - 1); //Remove 1 dia pois foi contado o dia inicial
        this.renovacao.dtF = util.formatDate(dtFim);
        this.renovacao.dtFim = dtFim;

        this.edicoes.map(async element => {
          var indice = 0;
          element.enderecos.forEach(endereco => {
            indice++;
            if (indice == element.enderecos.length) {
              endereco.dtInicio = this.renovacao.dtInicio;
              this.dtInicioEndereco = this.formatDate(endereco.dtInicio);
              endereco.editar = new Date(endereco.dtInicio) >= new Date();
              endereco.ultimo = true;
            }
          });
        });
      }
    },
    async calcularDtInicio() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/config-fechamento-roteiro/dtInicio?idSite=" + this.idSitePrincipal);
        if (response.data) {
          this.dataInicio = util.parseDateUS(response.data.dtInicio);
        } else {
          throw "";
        }
      } catch (e) {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a data de início";
        this.snackErro = true;
      }
    },

    async carregarProdutosPlano() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/plano-produto/plano/" + this.renovacao.idPlano + "?ativos=1");
        if (response.data) {
          var incluir = this.produtos.length == 0;
          this.produtosPlano = response.data;
          this.produtosPlano.forEach(element => {
            element.nome = element.produto;
            if (incluir && element.obrigatorio) {
              //* Se não há produtos no list, inclui os obrigatórios
              const produto = {
                id: null,
                idRenovacao: this.idRenovacao,
                idProduto: element.idProduto,
                edicoes: 1,
                valor: element.valor,
                bruto: util.formatCurrency(element.valor),
                valorMinimo: element.valorMinimo,
                valorMaximo: element.valorMaximo,
                produto: element.produto,
                nome: element.produto,
                produtoTipo: element.produtoTipo,
                obrigatorio: element.obrigatorio,
                dependentes: element.dependentes
              };
              this.produtos.push(produto);
              this.validacao.produtos = false;
            }
          });
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os produtos do plano de assinatura";
        this.snackErro = true;
      }
    },

    async carregarAgentesPlano() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/plano-agente/plano/" + this.renovacao.idPlano + "?ativos=1");
        if (response.data) {
          this.agentesPlano = response.data;
          var incluir = this.agentes.length == 0 && this.agentesPlano.length == 1;
          this.agentesPlano.forEach(element => {
            element.nome = element.agente;
            if (incluir) {
              const agente = {
                id: null,
                idAgente: element.idAgente,
                agente: element.agente,
                nome: element.nome
              };
              this.agentes.push(agente);
              this.validacao.agentes = false;
            }
          });
        }
        if (this.agentesPlano.length == 0) {
          this.carregarAgentes();
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os agentes de venda do plano de assinatura";
        this.snackErro = true;
      }
    },
    async carregarAgentes() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-agente/lista?idSite=" + this.idSitePrincipal);
        if (response.data) {
          this.agentesPlano = response.data;
          var incluir = this.agentes.length == 0 && this.agentesPlano.length == 1;
          this.agentesPlano.forEach(element => {
            element.idAgente = element.id;
            element.agente = element.nome;
            if (incluir) {
              const agente = {
                id: null,
                idAgente: element.id,
                agente: element.nome,
                nome: element.nome
              };
              this.agentes.push(agente);
              this.validacao.agentes = false;
            }
          });
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de agentes de venda";
        this.snackErro = true;
      }
    },

    async carregarFormasPgtoPlano() {
      try {
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API + "ass/plano-forma-pagamento/plano/" + this.renovacao.idPlano + "?ativos=1&idUsuario=" + this.$store.state.usuario.id
        );
        if (response.data) {
          this.formasPgtoPlano = response.data;
          this.formasPgtoPlano.forEach(element => {
            element.nome = element.formaPagamento;
          });
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as formas de pagamento do plano de assinatura";
        this.snackErro = true;
      }
    },

    carregaItensFormaPgto() {
      if (this.formaPagamentoSelecionada && (!this.clienteSelecionado || !this.clienteSelecionado.id)) {
        this.mensagem = "Selecione um assinante";
        this.snackErro = true;
        return;
      }
      if (this.formaPagamentoSelecionada && ["PERMUTA_PAGAR", "PERMUTA_RECEBER"].includes(this.formaPagamentoSelecionada.formaPagamentoTipo)) {
        this.formaPagamentoSelecionada = {
          idFormaPgto: null,
          nome: "",
          formaPagamento: "",
          formaPagamentoTipo: "",
          maxParcelas: 0
        };
        this.parcelamento = [];
        this.mensagem = "Permuta de Assinatura não disponível";
        this.snackErro = true;
        return;
      }

      if (this.formaPagamentoSelecionada) {
        this.idClienteEndereco = null;
        this.idClienteCartao = null;
        this.idClienteCelesc = null;
        this.idClienteConta = null;
        var tipo_forma_pgto = this.formaPagamentoSelecionada.formaPagamentoTipo;
        if (tipo_forma_pgto == "BOLETO" || tipo_forma_pgto == "CARTEIRA") {
          this.carregarEnderecos("cobranca");
        }
        if (tipo_forma_pgto == "CARTAO_CREDITO") {
          this.carregarCartoes();
        }
        if (tipo_forma_pgto == "CELESC" || tipo_forma_pgto == "SAMAE") {
          this.carregarCelescs();
        }
        if (tipo_forma_pgto == "DEBITO_CONTA") {
          this.carregarContas();
        }

        this.carregarParcelamento();
      } else {
        this.parcelas = [];
        this.idClienteEndereco = null;
        this.idClienteCelesc = null;
        this.idClienteCartao = null;
        this.idClienteConta = null;
        this.parcelamento = [];
        this.parcelamentoSelecionado = {};
      }
    },

    async carregarEnderecos(tipo) {
      try {
        //* Carrega os endereços do cliente
        const responseEnderecosCliente = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-endereco/lista?idCliente=" + this.renovacao.idCliente + "&" + tipo + "=1");
        if (tipo == "cobranca") {
          this.enderecosCobrancaCliente = responseEnderecosCliente.data;
          this.enderecosCobrancaCliente.forEach(element => {
            element.nome = element.tipo + " " + element.logradouro + ", " + element.numero + " - " + element.bairro + " - " + element.municipio + "/" + element.uf;
          });
          if (this.enderecosCobrancaCliente.length == 1) {
            this.idClienteEndereco = this.enderecosCobrancaCliente[0].id;
          }
        } else {
          this.enderecosEntregaCliente = responseEnderecosCliente.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os endereços do cliente";
        this.snackErro = true;
      }
    },
    async carregarCartoes() {
      try {
        //* Carrega os cartões de crédito do cliente
        const responseCartoesCliente = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-cartao/lista?idCliente=" + this.renovacao.idCliente);
        this.cartoesCliente = responseCartoesCliente.data;
        this.cartoesCliente.forEach(element => {
          element.nome = util.escondeCartao(element.numero) + " - " + element.validade;
        });
        if (this.cartoesCliente.length == 1) {
          this.idClienteCartao = this.cartoesCliente[0].id;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os cartões de crédito do cliente";
        this.snackErro = true;
      }
    },
    async carregarCelescs() {
      try {
        //* Carrega as unidades consumidoras do cliente
        const responseCelescsCliente = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-celesc/lista?idCliente=" + this.renovacao.idCliente);
        this.celescsCliente = responseCelescsCliente.data;
        this.celescsCliente.forEach(element => {
          element.nome = element.unidadeConsumidora + " (" + element.identificacao + ")";
        });
        if (this.celescsCliente.length == 1) {
          this.idClienteCelesc = this.celescsCliente[0].id;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as unidades consumidoras do cliente";
        this.snackErro = true;
      }
    },
    async carregarContas() {
      try {
        //* Carrega as contas correntes do cliente
        const responseContasCliente = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-conta/lista?idCliente=" + this.renovacao.idCliente);
        this.contasCliente = responseContasCliente.data;
        this.contasCliente.forEach(element => {
          element.nome = element.bancoNumero + ": " + element.agencia + " - " + element.numero;
        });
        if (this.contasCliente.length == 1) {
          this.idClienteConta = this.contasCliente[0].id;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as contas correntes do cliente";
        this.snackErro = true;
      }
    },

    async carregarParcelamento() {
      try {
        this.formaPagamentoSelecionar = true;

        this.parcelas = [];
        this.idClienteEndereco = null;
        this.idClienteCelesc = null;
        this.idClienteCartao = null;
        this.idClienteConta = null;

        this.validacao.dtInicio = false;
        this.validacao.produtos = false;
        this.validacao.vlrTaxado = false;
        this.validacao.carencia = false;
        this.validacao.parcelas = false;

        var erro = false;
        if (!this.clienteSelecionado.id) {
          this.mensagem = "Selecione um assinante";
          this.snackErro = true;
          erro = true;
        }
        if (this.dtInicio == "") {
          this.validacao.dtInicio = "Preencha a data de início";
          this.mensagem = "Preencha a data de início";
          this.snackErro = true;
          erro = true;
        }
        if (this.produtos.length == 0) {
          this.validacao.produtos = "Selecione pelo menos 1 produto";
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
          erro = true;
        }

        var erroProd = "";
        this.produtosPlano.forEach(prodPlano => {
          var achou = false;
          if (prodPlano.obrigatorio) {
            this.produtos.forEach(prod => {
              if (prod.idProduto == prodPlano.idProduto) {
                achou = true;
              }
            });
            if (!achou) {
              erroProd += "Produto " + prodPlano.nome + " é obrigatório. ";
            }
          }
        });

        if (this.vlrTaxado) {
          if (util.isFormaPgtoValorZero(this.formaPagamentoSelecionada.formaPagamentoTipo)) {
            this.validacao.vlrTaxado = "Não é possível taxar valor para a forma de pagamento selecionada";
            this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
            this.snackErro = true;
            erro = true;
          } else {
            var vlrMinimo = 0;
            var vlrMaximo = 0;
            this.produtos.forEach(element => {
              vlrMinimo += parseFloat(element.valorMinimo) * parseInt(element.edicoes);
              vlrMaximo += parseFloat(element.valorMaximo) * parseInt(element.edicoes);
            });
            if (parseFloat(this.vlrTaxado) < vlrMinimo || parseFloat(this.vlrTaxado) > vlrMaximo) {
              this.validacao.vlrTaxado = "Valor taxado está fora da faixa: " + util.formatCurrency(vlrMinimo) + " a " + util.formatCurrency(vlrMaximo);
              this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
              this.snackErro = true;
              erro = true;
            }
          }
        }

        if (parseInt(this.carencia) > this.limiteCarencia) {
          this.validacao.carencia = "Carência inválida";
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
          erro = true;
        }

        if (erro || erroProd) {
          this.formaPagamentoSelecionada = {
            idFormaPgto: null,
            nome: "",
            formaPagamento: "",
            formaPagamentoTipo: "",
            maxParcelas: 0
          };
          if (erroProd) {
            this.validacao.produtos = erroProd;
          }
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
          return;
        }

        this.taxar = false; //* para alterar será preciso limpar o parcelamento novamente
        var vlrBruto = 0; //* valor de tabela total da renovação (impresso+digital)
        var vlrFixo = 0; //* valor de tabela do clube do assinante
        var existeImpresso = false;
        this.produtos.forEach(element => {
          if (element.produtoTipo == "IMPRESSO") {
            existeImpresso = true;
          }
        });
        this.produtos.forEach(element => {
          if (element.produtoTipo == "CLUBE" && element.dependentes) {
            vlrFixo += parseFloat(element.valor) * parseInt(this.nroDependentes);
          } else {
            if (existeImpresso && element.produtoTipo == "ONLINE") {
              //* Se existe impresso, multiplica somente as edições do impresso - não multiplica as edições do online
              vlrBruto += parseFloat(element.valor);
            } else {
              vlrBruto += parseFloat(element.valor) * parseInt(element.edicoes);
            }
          }
        });

        if (util.isFormaPgtoValorZero(this.formaPagamentoSelecionada.formaPagamentoTipo)) {
          //* Cria a parcela para CORTESIA, BONIFICAÇÃO ou COMPENSAÇÃO
          var dtVecto = new Date(util.formatDateUSTime(this.dtInicio + " 00:00:00"));
          var parcela = {
            id: null,
            idOperacao: this.renovacao.idOperacao,
            indice: null,
            numero: 1,
            status: this.assinatura.status == "PROPOSTA" ? "PROPOSTA" : "QUITADA",
            idFormaPgto: this.formaPagamentoSelecionada.idFormaPgto,
            formaPagamento: this.formaPagamentoSelecionada.formaPagamento,
            formaPagamentoTipo: this.formaPagamentoSelecionada.formaPagamentoTipo,
            dtVectoOriginal: dtVecto,
            dtVecto: dtVecto,
            dtVectoO: util.formatDate(dtVecto),
            dtQuitacao: dtVecto,
            idClienteCartao: this.idClienteCartao,
            idClienteConta: this.idClienteConta,
            idClienteEndereco: this.idClienteEndereco,
            idClienteCelesc: this.idClienteCelesc,
            valor: 0,
            liquido: "0,00",
            descAcr: 0,
            obsCobranca: this.renovacao.autorizacao,
            ajustada: 0
          };
          this.parcelas.push(parcela);

          //* Carrega os itens do parcelamento
          const responseValorZero = await api.get(
            process.env.VUE_APP_URL_WEB_API +
              "ass/regra-ajuste/planoFormaPgto/" +
              this.renovacao.idPlano +
              "/" +
              this.formaPagamentoSelecionada.idFormaPgto +
              "?idSite=" +
              this.idSitePrincipal
          );
          this.assinatura.idRegraAjuste = responseValorZero.data[0].id;
          return;
        }

        //* Carrega os itens do parcelamento
        const responseParcelamento = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "ass/regra-ajuste/planoFormaPgto/" +
            this.renovacao.idPlano +
            "/" +
            this.formaPagamentoSelecionada.idFormaPgto +
            "?idSite=" +
            this.idSitePrincipal
        );
        this.parcelamento = [];
        this.parcelamentoSelecionado = {};

        var parcelamentoItens = [];
        var parcItens = [];
        var idRegra = 0;

        for (let iparc = 0; iparc < responseParcelamento.data.length; iparc++) {
          const element = responseParcelamento.data[iparc];

          var ate = Math.min(this.formaPagamentoSelecionada.maxParcelas, element.ate);
          if (element.id != idRegra) {
            parcItens = [];
          }
          idRegra = element.id;
          //* Se 1 a 10, irá gerar 1 parcelamentoItens para 1-1, 1-2, 1-2-3 até 10 e apresentar: 1x=30,24, 2x=30,24 etc
          for (var x = element.de; x <= ate; x++) {
            var parcTemp = {
              id: element.id,
              nome: x + "x",
              tipo: element.tipo,
              composto: element.composto,
              nivelar: element.nivelar,
              prazoPrimeira: element.prazoPrimeira,
              intervalo: element.intervalo,
              automatica: element.automatica,
              itens: []
            };
            if (element.tipo == "$") {
              parcItens.push({
                numero: x,
                descAcr: element.valor
              });
            } else {
              parcItens = [];
              for (var y = 1; y <= x; y++) {
                parcItens.push({
                  numero: x,
                  descAcr: element.valor
                });
              }
            }
            parcTemp.itens = Object.assign([], parcItens);
            parcelamentoItens.push(parcTemp);
          }
        }

        var vlrLimite = 0;
        //* Se jornal tiver limitação por preço ajustado e valor foi taxado, calcula parcelamentos sem considerar o taxado, deixando passar somente o que está na faixa
        if (this.$store.state.usuario.site_limiteTaxacao && this.vlrTaxado) {
          for (let iparcI = 0; iparcI < parcelamentoItens.length; iparcI++) {
            var regra = parcelamentoItens[iparcI];

            var itensRegra = regra.itens;
            var vlrBase = vlrBruto / itensRegra.length;
            var vlrBaseFixo = vlrFixo / itensRegra.length;
            var vlrAjBase = 0;
            var vlrAjuste = 0;

            var contador = 0;
            regra.itens.forEach(item => {
              contador++;
              var vlr = 0;
              if (regra.tipo == "%") {
                vlr = vlrBase * (1 + item.descAcr / 100) + vlrBaseFixo;

                vlrAjBase += vlr;
                if (contador == regra.itens.length) {
                  vlr = vlrAjBase - vlrAjuste;
                }
              } else if (regra.tipo == "$") {
                vlr = vlrBase + item.descAcr + vlrBaseFixo;
              } else {
                //=
                vlr = item.descAcr + vlrBaseFixo;
              }
              if (vlr < 0) {
                vlr = 0;
              }
              if (regra.composto) {
                vlrBase = vlr;
              }
              vlrAjuste += vlr;
              console.log(vlr);
            });

            vlrLimite = (vlrAjuste * (100 - parseFloat(this.$store.state.usuario.site_limiteTaxacao))) / 100;
            if (this.vlrTaxado < vlrLimite) {
              regra.bloquear = true;
            }
          }
        }

        var bloqueado = false;
        for (let iparcI = 0; iparcI < parcelamentoItens.length; iparcI++) {
          var regra = parcelamentoItens[iparcI];
          if (regra.bloquear) {
            bloqueado = true;
            continue;
          }

          var itensRegra = regra.itens;
          var vlrBase = (this.vlrTaxado ? parseFloat(this.vlrTaxado) : vlrBruto) / itensRegra.length;
          var vlrBaseFixo = vlrFixo / itensRegra.length;
          var vlrAjBase = 0;
          var vlrAjuste = 0;

          var contador = 0;
          regra.itens.forEach(item => {
            contador++;
            if (regra.tipo == "%") {
              item.valor = vlrBase * (this.vlrTaxado ? 1 : 1 + item.descAcr / 100) + vlrBaseFixo;

              vlrAjBase += item.valor;
              if (contador == regra.itens.length) {
                item.valor = vlrAjBase - vlrAjuste;
              }
            } else if (regra.tipo == "$") {
              item.valor = vlrBase + (this.vlrTaxado ? 0 : item.descAcr) + vlrBaseFixo;
            } else {
              //=
              item.valor = (this.vlrTaxado ? parseFloat(this.vlrTaxado) : item.descAcr) + vlrBaseFixo;
            }
            if (item.valor < 0) {
              item.valor = 0;
            }
            if (regra.composto) {
              vlrBase = item.valor;
            }
            vlrAjuste += parseFloat(item.valor);
            item.valor = item.valor.toFixed(2);
          });
          regra.valor = vlrAjuste;
          regra.valor = parseFloat(regra.valor.toFixed(2));

          regra.nro = util.somenteNumeros(regra.nome);
          regra.vlrUnit = vlrAjuste / regra.nro;
          regra.vlrUnit = regra.vlrUnit.toFixed(2);
          regra.vlrUnit = util.formatCurrency(regra.vlrUnit, this.$store.state.usuario.site_pais, true);
          regra.vlrTot = util.formatCurrency(vlrAjuste.toFixed(2), this.$store.state.usuario.site_pais, true);

          regra.nome = regra.nome + " = " + regra.valor;
          this.parcelamento.push(regra);
        }

        if (!this.parcelamento.length && bloqueado) {
          this.validacao.vlrTaxado = "Valor taxado está abaixo do mínimo permitido: " + util.formatCurrency(vlrLimite);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
          return;
        }

        var parcelamentoAuto = null;
        //* Nivela os valores das parcelas, se foi solicitado
        this.parcelamento.forEach(parc => {
          if (parc.nivelar) {
            var vlrNivelado = parc.valor / parc.itens.length;
            vlrNivelado = vlrNivelado.toFixed(2);
            var somaParc = 0;
            parc.itens.forEach(item => {
              item.valor = parseFloat(vlrNivelado);
              somaParc += item.valor;
            });
            if (somaParc != parc.valor) {
              //* Ajusta diferença na primeira parcela
              var vlrAjNiv = parc.itens[0].valor + (parc.valor - somaParc);
              vlrAjNiv = vlrAjNiv.toFixed(2);
              parc.itens[0].valor = parseFloat(vlrAjNiv);
            }
          }
          if ((!this.idAssinatura && (parc.automatica == 1 || parc.automatica == 3)) || (this.idAssinatura && (parc.automatica == 2 || parc.automatica == 3))) {
            //* Se é regra automática da captação, aplica automaticamente
            parcelamentoAuto = Object.assign({}, parc);
          }
        });

        if (this.vlrTaxado) {
          this.parcelamento.forEach(parc => {
            parc.valor = parseFloat(this.vlrTaxado);
            parc.nome = parc.itens.length + "x = " + this.vlrTaxado;

            parc.nro = parc.itens.length;
            parc.vlrUnit = parc.valor / parc.nro;
            parc.vlrUnit = parc.vlrUnit.toFixed(2);
            parc.vlrUnit = util.formatCurrency(parc.vlrUnit, this.$store.state.usuario.site_pais, true);
            parc.vlrTot = util.formatCurrency(parc.valor.toFixed(2), this.$store.state.usuario.site_pais, true);

            var vlrP = (parc.valor / parc.itens.length).toFixed(2);
            vlrP = parseFloat(vlrP);
            var ajuste = parc.valor;
            parc.itens.forEach(item => {
              if (item.numero == parc.itens.length) {
                item.valor = ajuste.toFixed(2);
              } else {
                item.valor = vlrP.toFixed(2);
                ajuste -= vlrP;
              }
            });

            if ((!this.idAssinatura && (parc.automatica == 1 || parc.automatica == 3)) || (this.idAssinatura && (parc.automatica == 2 || parc.automatica == 3))) {
              //* Se é regra automática da captação, aplica automaticamente
              parcelamentoAuto = Object.assign({}, parc);
            }
          });
        }

        if (parcelamentoAuto && this.parcelamento.length == 1) {
          this.criarParcelas(parcelamentoAuto);
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as parcelas";
        this.snackErro = true;
      }
    },

    async criarParcelas(parcelamento) {
      this.parcelas = [];
      if (parcelamento && parcelamento.itens && parcelamento.itens.length > 0) {
        var vlrBruto = 0; //* valor de tabela total da renovação (impresso+digital)
        var vlrFixo = 0; //* valor de tabela do clube do assinante
        var existeImpresso = false;
        this.produtos.forEach(element => {
          if (element.produtoTipo == "IMPRESSO") {
            existeImpresso = true;
          }
        });
        this.produtos.forEach(element => {
          if (element.produtoTipo == "CLUBE" && element.dependentes) {
            vlrFixo += parseFloat(element.valor) * parseInt(this.nroDependentes);
          } else {
            if (existeImpresso && element.produtoTipo == "ONLINE") {
              //* Se existe impresso, multiplica somente as edições do impresso - não multiplica as edições do online
              vlrBruto += parseFloat(element.valor);
            } else {
              vlrBruto += parseFloat(element.valor) * parseInt(element.edicoes);
            }
          }
        });
        var formaPagamento = {
          id: this.formaPagamentoSelecionada.idFormaPgto,
          tipo: this.formaPagamentoSelecionada.formaPagamentoTipo,
          maxParcelas: parcelamento.itens[parcelamento.itens.length - 1].numero,
          idClienteCartao: this.idClienteCartao,
          idClienteConta: this.idClienteConta,
          idClienteEndereco: this.idClienteEndereco,
          idClienteCelesc: this.idClienteCelesc
        };

        var dtInicioCarencia = util.parseDate(this.dtInicio);
        if (this.carencia) {
          dtInicioCarencia.setDate(dtInicioCarencia.getDate() + parseInt(this.carencia));
        }

        var dadosParcelas = {
          idPlano: this.renovacao.idPlano,
          idFormaPgto: this.formaPagamentoSelecionada.idFormaPgto,
          idRegraAjuste: parcelamento.id,
          autorizacao: this.renovacao.autorizacao,
          diaVencimento: this.clienteSelecionado.diaVencimento ? this.clienteSelecionado.diaVencimento : 1,
          vlrFixo: vlrFixo,
          vlrBruto: vlrBruto,
          vlrTaxado: this.vlrTaxado,
          dtInicio: util.dateToStringUS(dtInicioCarencia),
          formaPagamento: formaPagamento,
          produtos: this.produtos
        };

        try {
          const retornoParcelas = await api.post(
            process.env.VUE_APP_URL_WEB_API + "ass/renovacao/parcelas?idAssinatura=" + this.idAssinatura + "&idSite=" + this.idSitePrincipal,
            dadosParcelas
          );
          this.parcelas = retornoParcelas.data;
          this.assinatura.idRegraAjuste = parcelamento.id;
        } catch (e) {
          this.mensagem =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
          this.snackErro = true;
          return;
        }

        this.parcelas.forEach(element => {
          element.formaPagamento = this.formaPagamentoSelecionada.formaPagamento;
          element.dtVectoO = util.formatDate(element.dtVecto);
          element.liquido = util.formatCurrency(element.valor);
        });
      }
    },

    closeEdicao() {
      this.dialogEdicao = false;
      setTimeout(() => {
        this.edicaoItem = Object.assign({}, this.defaultEdicaoItem);
        this.edicaoIndex = -1;

        this.enderecoItem = Object.assign({}, this.defaultEnderecoItem);
        this.dtInicioEnderecoUS = null;
        this.enderecoIndex = -1;
        this.enderecoSelecionado = {};

        this.$refs.formEdicao.resetValidation();
      }, 300);
    },
    async saveEdicao() {
      await util.limparObjeto(this.validacao, false);
      var erroData = false;
      var erroRepetido = false;
      var enderecosCommit = [];
      const salvar = this.idAssinatura != "" && this.idRenovacao != "";
      if (this.$refs.formEdicao.validate()) {
        if (this.edicaoItem.edicao == "[TODAS]") {
          //* Inclui o agendamento do endereço para todas as edições
          this.edicoes.forEach(element => {
            if (this.enderecoItem.edicao == "TODAS" || element.nroEdicao == parseInt(this.enderecoItem.edicao)) {
              //* Verifica inconsistências no endereço adicionado
              if (this.enderecoItem.idEndereco == element.enderecos[0].idEndereco) {
                erroRepetido = true;
              }
              element.enderecos.forEach(endereco => {
                if (!this.enderecoItem.corrigir && util.parseDateUS(this.dtInicioEnderecoUS) <= util.parseDateUS(endereco.dtInicio)) {
                  erroData = true;
                }
              });
              if (!erroData && !erroRepetido) {
                var endereco = Object.assign({}, this.enderecoItem); //é necessária esta clonagem do objeto para evitar atribuições em todas as edições (ao alterar em uma)
                endereco.idRenovacaoEdicao = element.id;
                endereco.dtInicio = this.dtInicioEnderecoUS;
                endereco.dtI = this.dtInicioEndereco;
                endereco.idUsuario = this.$store.state.usuario.id;
                endereco.editar = true;
                element.enderecos.push(endereco);
                if (salvar) {
                  enderecosCommit.push(endereco);
                }
              }
            }
          });
          if (erroData) {
            this.mensagem = "Não é possível agendar endereço para data inferior ou igual a de endereço já agendado";
            this.snackErro = true;
          }
          if (erroRepetido) {
            this.mensagem = "Não é possível agendamento para o mesmo endereço já agendado";
            this.snackErro = true;
          }
        } else {
          this.edicaoItem.enderecos.forEach(endereco => {
            if (!this.enderecoItem.id) {
              if (this.enderecoItem.idEndereco == this.edicaoItem.enderecos[0].idEndereco) {
                erroRepetido = true;
              }
              if (util.parseDateUS(this.dtInicioEnderecoUS) <= util.parseDateUS(endereco.dtInicio)) {
                erroData = true;
              }
            } else {
              if (
                (this.enderecoItem.id < endereco.id && util.parseDateUS(this.dtInicioEnderecoUS) >= util.parseDateUS(endereco.dtInicio)) ||
                (this.enderecoItem.id > endereco.id && util.parseDateUS(this.dtInicioEnderecoUS) <= util.parseDateUS(endereco.dtInicio))
              ) {
                erroData = true;
              }
            }
          });
          if (!erroData && !erroRepetido) {
            this.enderecoItem.dtInicio = this.dtInicioEnderecoUS;
            this.enderecoItem.dtI = this.dtInicioEndereco;
            this.enderecoItem.idUsuario = this.$store.state.usuario.id;
            if (this.enderecoIndex > -1) {
              if (this.enderecoItem.id) {
                await this.alterarEndereco();
                this.closeEdicao();
                return;
              } else {
                Object.assign(this.edicoes[this.edicaoIndex].enderecos[this.enderecoIndex], this.enderecoItem);
              }
            } else {
              this.enderecoItem.editar = true;
              this.enderecoItem.idRenovacaoEdicao = this.edicaoItem.id;
              this.edicaoItem.enderecos.push(this.enderecoItem);
              if (salvar) {
                enderecosCommit.push(this.enderecoItem);
              }
            }
          } else {
            if (erroData) {
              this.mensagem = "Inconsistência nas datas de agendamento";
              this.snackErro = true;
            } else {
              this.mensagem = "Não é possível agendamento para o mesmo endereço já agendado";
              this.snackErro = true;
            }
          }
        }
        if (enderecosCommit.length > 0) {
          this.incluirEnderecos(enderecosCommit);
        }
        this.closeEdicao();
      }
    },
    addEndereco(item) {
      this.edicaoIndex = this.edicoes.indexOf(item);
      this.edicaoItem = Object.assign({}, item);
      this.dialogEdicao = true;
    },
    editarEndereco(subitem) {
      var contador = 0;
      this.edicoes.forEach(element => {
        if (element.id == subitem.idRenovacaoEdicao) {
          this.edicaoIndex = contador;
        }
        contador++;
      });
      this.edicaoItem = this.edicoes[this.edicaoIndex];

      this.enderecoIndex = this.edicaoItem.enderecos.indexOf(subitem);
      this.enderecoItem = Object.assign({}, subitem);
      this.dtInicioEnderecoUS = subitem.dtInicio;
      this.enderecoSelecionado = {
        id: subitem.idEndereco,
        enderecoCompleto: subitem.enderecoCompleto,
        enderecoComplemento: subitem.enderecoComplemento
      };
      this.dialogEdicao = true;
    },
    excluirEndereco(subitem) {
      var contador = 0;
      this.edicoes.forEach(element => {
        if (element.id == subitem.idRenovacaoEdicao) {
          this.edicaoIndex = contador;
        }
        contador++;
      });
      this.edicaoItem = this.edicoes[this.edicaoIndex];

      this.enderecoIndex = this.edicaoItem.enderecos.indexOf(subitem);
      this.enderecoItem = Object.assign({}, subitem);

      if (this.enderecoItem.id == null) {
        this.edicaoItem.enderecos.splice(this.enderecoIndex, 1);

        this.edicaoItem = Object.assign({}, this.defaultEdicaoItem);
        this.edicaoIndex = -1;

        this.enderecoItem = Object.assign({}, this.defaultEnderecoItem);
        this.dtInicioEnderecoUS = null;
        this.enderecoIndex = -1;
        this.enderecoSelecionado = {};
        return;
      }

      this.msgConfirm = "Tem certeza que deseja excluir este endereço?";
      this.tipoConfirm = "END";
      this.dialogConfirm = true;
    },
    async excluiEndereco() {
      try {
        await api.delete(process.env.VUE_APP_URL_WEB_API + "ass/renovacao-edicao-endereco/" + this.enderecoItem.id + "/" + this.enderecoItem.idRenovacaoEdicao);
        this.edicaoItem.enderecos.splice(this.enderecoIndex, 1);
        this.closeEdicao();
        this.mensagem = "Endereço foi excluído";
        this.snackbar = true;
      } catch (e) {
        if (e.response.data) {
          //função realiza todas as validações de erro
          util.validaErros(this.validacao, e.response.data);
        }
      }
    },
    async alterarEndereco() {
      var enderecoCommit = {
        renovacao: {
          idAssinatura: this.idAssinatura,
          id: this.idRenovacao,
          renovacao: this.renovacao.renovacao,
          dtInicio: this.renovacao.dtInicio,
          dtFim: this.renovacao.dtFim
        },
        idRenovacaoEdicao: this.enderecoItem.idRenovacaoEdicao,
        idEndereco: this.enderecoItem.idEndereco,
        dtInicio: this.enderecoItem.dtInicio
      };
      try {
        await api.put(process.env.VUE_APP_URL_WEB_API + "ass/renovacao-edicao-endereco/" + this.enderecoItem.id, enderecoCommit);
        Object.assign(this.edicoes[this.edicaoIndex].enderecos[this.enderecoIndex], this.enderecoItem);
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      }
    },
    async incluirEnderecos(enderecos) {
      const enderecosCommit = {
        renovacao: {
          idAssinatura: this.idAssinatura,
          id: this.idRenovacao,
          renovacao: this.renovacao.renovacao,
          dtInicio: this.renovacao.dtInicio,
          dtFim: this.renovacao.dtFim
        },
        enderecos: enderecos,
        corrigir: this.enderecoItem.corrigir
      };
      try {
        await api.post(process.env.VUE_APP_URL_WEB_API + "ass/renovacao-edicao-endereco?idSite=" + this.idSitePrincipal, enderecosCommit);
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        this.carregarEdicoes();
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      }
    },

    editarProduto(item) {
      this.produtoIndex = this.produtos.indexOf(item);
      this.produtoItem = Object.assign({}, item);
      this.produtoAnterior = Object.assign({}, item);
      this.produtoSelecionado = {
        idProduto: item.idProduto,
        nome: item.produto,
        produto: item.produto,
        produtoTipo: item.produtoTipo,
        edicoes: item.edicoes,
        valor: item.valor,
        valorMinimo: item.valorMinimo,
        valorMaximo: item.valorMaximo,
        bruto: util.formatCurrency(item.valor),
        obrigatorio: item.obrigatorio
      };
      this.dialogProduto = true;
    },
    removerProduto(item) {
      this.produtoIndex = this.produtos.indexOf(item);
      this.produtoItem = Object.assign({}, item);
      this.produtos.splice(this.produtoIndex, 1);
      this.produtoItem = Object.assign({}, this.defaultProdutoItem);
      this.produtoIndex = -1;
      this.produtoSelecionado = {};
      this.limpaParcelas("");
      this.edicoes = [];
    },
    closeProduto() {
      this.dialogProduto = false;
      setTimeout(() => {
        this.produtoItem = Object.assign({}, this.defaultProdutoItem);
        this.produtoIndex = -1;
        this.produtoSelecionado = {};
        this.$refs.formProduto.resetValidation();
      }, 300);
    },
    saveProduto() {
      if (this.$refs.formProduto.validate()) {
        if (this.produtoIndex > -1) {
          Object.assign(this.produtos[this.produtoIndex], this.produtoItem);
        } else {
          this.produtos.push(this.produtoItem);
          this.limpaParcelas("");
          this.edicoes = [];
        }
        if (this.produtoAnterior.idProduto != this.produtoItem.idProduto || this.produtoAnterior.edicoes != this.produtoItem.edicoes) {
          //* Se houve alteração de produtos, limpa as parcelas para recalcular
          this.limpaParcelas("");
          this.edicoes = [];
        }
        this.closeProduto();
        this.produtoItem = Object.assign({}, this.defaultProdutoItem);
        this.produtoIndex = -1;
        this.produtoSelecionado = {};
        this.$refs.formProduto.resetValidation();
        this.validacao.produtos = false;
      }
    },

    editarAgente(item) {
      this.agenteIndex = this.agentes.indexOf(item);
      this.agenteItem = Object.assign({}, item);
      this.agenteSelecionado = {
        idAgente: item.idAgente,
        agente: item.agente
      };
      this.dialogAgente = true;
    },
    removerAgente(item) {
      this.agenteIndex = this.agentes.indexOf(item);
      this.agenteItem = Object.assign({}, item);
      this.agentes.splice(this.agenteIndex, 1);
      this.agenteItem = Object.assign({}, this.defaultAgenteItem);
      this.agenteIndex = -1;
      this.agenteSelecionado = {};
    },
    closeAgente() {
      this.dialogAgente = false;
      setTimeout(() => {
        this.agenteItem = Object.assign({}, this.defaultAgenteItem);
        this.agenteIndex = -1;
        this.agenteSelecionado = {};
        this.$refs.formAgente.resetValidation();
      }, 300);
    },
    saveAgente() {
      if (this.$refs.formAgente.validate()) {
        if (this.agentes.length == 2) {
          this.validacao.agente = "Máximo 2 agentes de venda por anúncio (1º Agência, 2º Agente)";
          return;
        }
        var incluido = false;
        this.agentes.forEach(element => {
          if (this.agenteItem.idAgente == element.idAgente) {
            this.validacao.agente = "Agente/Agência já foi incluído";
            incluido = true;
          }
        });
        if (!incluido) {
          if (this.agenteIndex > -1) {
            Object.assign(this.agentes[this.agenteIndex], this.agenteItem);
          } else {
            this.agentes.push(this.agenteItem);
          }
          this.closeAgente();
          this.agenteItem = Object.assign({}, this.defaultAgenteItem);
          this.agenteIndex = -1;
          this.agenteSelecionado = {};
          this.$refs.formAgente.resetValidation();
          this.validacao.agentes = false;
        }
      }
    },

    editarParcela(item) {
      this.idParcela = item.id + "";
      this.dialogParcela = true;
    },
    closeParcela() {
      this.dialogParcela = false;
      this.idParcela = "";
    },

    limpaParcelas(tipo) {
      if (tipo == "plano") {
        if (this.produtos.length > 0) {
          this.mensagem = "Produtos, Edições, Agentes/Agências e Parcelas foram redefinidos";
          this.snackErro = true;
        }
      } else {
        if (this.parcelas.length > 0) {
          this.mensagem = "Parcelas " + (tipo != "ajuste" ? "e Edições " : "") + "foram removidas para serem recriadas";
          this.snackErro = true;
        } else if (tipo != "ajuste" && this.edicoes.length > 0) {
          this.mensagem = "Edições foram removidas para serem recriadas";
          this.snackErro = true;
        }
      }
      this.taxar = true;
      this.renovacao.recriarParcelas = 1;
      this.parcelas = [];
      this.formaPagamentoSelecionada = {
        idFormaPgto: null,
        nome: "",
        formaPagamento: "",
        formaPagamentoTipo: "",
        maxParcelas: 0
      };
      this.idClienteEndereco = null;
      this.idClienteCelesc = null;
      this.idClienteCartao = null;
      this.idClienteConta = null;
      this.parcelamento = [];
      this.parcelamentoSelecionado = {};

      this.verificaParcelaEmCobranca();
    },
    visualizarParcelas() {
      window.open("/financeiro/parcelas/receber?idRenovacao=" + this.renovacao.id, "_blank");
    },

    async verificaParcelaEmCobranca() {
      try {
        //* Verifica se há alguma parcela da operação da renovação em cobrança
        if (this.renovacao.idOperacao) {
          const responseCobranca = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/parcela/emCobranca/" + this.renovacao.idOperacao);
          var erro = false;
          if (responseCobranca.data) {
            erro = true;
            this.mensagem = "Não será possível alterar esta renovação pois já existe parcela EM COBRANÇA";
          } else {
            //* Verifica se o cliente possui o dado de cobrança para as parcelas
            const processar = this.parcelas.map(async element => {
              if (element.formaPagamentoTipo == "BOLETO" || element.formaPagamentoTipo == "CARTEIRA") {
                await this.carregarEnderecos("cobranca");
                if (this.enderecosCobrancaCliente.length == 0) {
                  erro = true;
                  this.mensagem = "Não será possível alterar esta renovação pois não há endereço de cobrança ativo para o novo cliente";
                } else {
                  element.idClienteEndereco = this.enderecosCobrancaCliente[0].id;
                }
              }
              if (element.formaPagamentoTipo == "CARTAO_CREDITO") {
                await this.carregarCartoes();
                if (this.cartoesCliente.length == 0) {
                  erro = true;
                  this.mensagem = "Não será possível alterar esta renovação pois não há cartão de crédito ativo para o novo cliente";
                } else {
                  element.idClienteCartao = this.cartoesCliente[0].id;
                }
              }
              if (element.formaPagamentoTipo == "CELESC" || element.formaPagamentoTipo == "SAMAE") {
                await this.carregarCelescs();
                if (this.celescsCliente.length == 0) {
                  erro = true;
                  this.mensagem = "Não será possível alterar esta renovação pois não há unidade consumidora ativa para o novo cliente";
                } else {
                  element.idClienteCelesc = this.celescsCliente[0].id;
                }
              }
              if (element.formaPagamentoTipo == "DEBITO_CONTA") {
                await this.carregarContas();
                if (this.contasCliente.length == 0) {
                  erro = true;
                  this.mensagem = "Não será possível alterar esta renovação pois não há conta corrente ativa para o novo cliente";
                } else {
                  element.idClienteConta = this.contasCliente[0].id;
                }
              }
            });
            await Promise.all(processar);
          }
          if (erro) {
            this.isUpdating = true;
            this.snackErro = true;

            setTimeout(() => {
              //* É melhor recarregar toda a tela, pois há muitos parâmetros a serem ajustados aqui
              this.$router.push("/assinaturas/assinatura/" + this.idAssinatura + "/" + this.idRenovacao);
              this.$router.go("/assinaturas/assinatura/" + this.idAssinatura + "/" + this.idRenovacao);
            }, 3000);
          }
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao verificar a cobrança das parcelas";
        this.snackErro = true;
      }
    },
    formatDate(date) {
      return util.formatDate(date);
    },
    formatDateTime(datetime) {
      return util.formatDateTime(datetime);
    },
    formatDateUS(date) {
      if (date && date.length == 10) {
        return util.formatDateUS(date);
      }
    },
    formatCurrency(valor) {
      return util.formatCurrency(valor);
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    filtrarObjetosIdentificacao(item, queryText, itemText) {
      return util.filtrarObjetosIdentificacao(item, queryText, itemText);
    },
    valida_data(date) {
      if (date) {
        var retorno = util.valida_data_BR(date);
        if (retorno) {
          var dtLimite = new Date();
          dtLimite.setFullYear(dtLimite.getFullYear() + 1);
          if (util.parseDate(date) > dtLimite) {
            return false;
          }
          if (this.renovacao.dtFimAnterior) {
            return util.parseDate(date) > util.parseDateUS(this.renovacao.dtFimAnterior);
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    valida_data_edicao(date) {
      var retorno = false;
      if (date) {
        var data = util.parseDate(date);
        if (data && data >= this.dataInicio && data <= util.parseDate(this.renovacao.dtF)) {
          retorno = true;
        }
      }
      return retorno;
    },
    valida_nome(text) {
      return util.valida_nome(text);
    }
  },

  watch: {
    planoSelecionado(val) {
      if (val) {
        this.renovacao.idPlano = val.id;
        this.limiteCarencia = 0;
        if (val.periodo == "D") {
          this.limiteCarencia = val.tempo;
        } else {
          this.limiteCarencia = 90;
        }

        if (!this.carregandoPla) {
          this.recarregarItensPlano();
          this.carregandoPla = false;
        }
      } else {
        this.limpaParcelas("plano");
        this.renovacao.idPlano = null;
        this.produtos = [];
        this.agentes = [];
        this.edicoes = [];
        this.produtosPlano = [];
        this.agentesPlano = [];
        this.formasPgtoPlano = [];
      }
    },
    parcelamentoSelecionado(val) {
      this.criarParcelas(val);
    },
    clienteSelecionado(val) {
      if (val && val.id) {
        if (val.acervo) {
          if (this.direitoMigrarAcervo) {
            this.tipoConfirm = "ACERVO";
            this.msgConfirm = "Tem certeza que deseja importar o assinante do ACERVO";
            this.dialogConfirm = true;
          } else {
            this.mensagem = "Você não possui direito para migrar o cliente do ACERVO";
            this.snackErro = true;
            this.clienteSelecionado = {};
          }
          return;
        }
        if (!this.carregandoCli || this.$route.query.idCliente) {
          if (!val.situacao) {
            this.mensagem = "Cliente [" + val.nome + "] encontra-se INATIVO.";
            this.snackErro = true;
            this.limpaCliente();
            return;
          }
          if (!val.statusCredito) {
            this.mensagem = "Cliente [" + val.nome + "] encontra-se com crédito BLOQUEADO.";
            this.snackErro = true;
            this.limpaCliente();
            return;
          }
          if (val.statusCredito == 2) {
            this.mensagem = "Cliente encontra-se com crédito EM ANÁLISE.";
            this.snackErro = true;
          }
        }
        if (this.idCliente && this.idCliente != val.id) {
          this.renovacao.trocaTitularidade = 1;
        }
        this.renovacao.idCliente = val.id;
        if (!this.carregandoCli) {
          this.recarregarItensCliente();
          this.carregandoCli = false;
        }
        this.carregarUltimoAnuncio();
        this.carregarUltimaAssinatura();
      } else {
        this.limpaCliente();
      }
    },
    produtoSelecionado(val) {
      this.produtoItem.idProduto = val.idProduto;
      this.produtoItem.nome = val.produto;
      this.produtoItem.produto = val.produto;
      this.produtoItem.produtoTipo = val.produtoTipo;
      this.produtoItem.edicoes = ["IMPRESSO", "ONLINE"].includes(val.produtoTipo) ? val.edicoes : 1;
      this.produtoItem.valor = val.valor;
      this.produtoItem.bruto = util.formatCurrency(val.valor);
      this.produtoItem.valorMinimo = val.valorMinimo;
      this.produtoItem.valorMaximo = val.valorMaximo;
    },
    enderecoSelecionado(val) {
      this.enderecoItem.idEndereco = val.id;
      this.enderecoItem.enderecoCompleto = val.enderecoCompleto;
      this.enderecoItem.enderecoComplemento = val.enderecoComplemento;
    },
    agenteSelecionado(val) {
      this.validacao.agente = "";
      this.agenteItem.idAgente = val.idAgente;
      this.agenteItem.agente = val.agente;
    },

    dialogProduto(val) {
      val || this.closeProduto();
    },
    dialogEdicao(val) {
      val || this.closeEdicao();
    },
    dialogAgente(val) {
      val || this.closeAgente();
    },
    dialogParcela(val) {
      if (val == null) {
        this.carregarParcelas();
      }
      val || this.closeParcela();
    },
    dtInicioUS(val) {
      this.dtInicio = util.formatDate(val);
      this.mnuDtInicio = false;
      if (!this.carregandoPla) {
        this.recalcularDtFim(false);
        this.limpaParcelas("");
        this.edicoes = [];
        this.idClienteEndereco = null;
        this.idClienteCelesc = null;
        this.idClienteCartao = null;
        this.idClienteConta = null;
      }
    },
    dtInicioEnderecoUS() {
      this.dtInicioEndereco = util.formatDate(this.dtInicioEnderecoUS);
      this.mnuDtEnderecoItem = false;
    }
  },

  async created() {
    this.isUpdating = false;
    this.bloqueiaBotoes = true;

    this.idAssinatura = this.$route.params.idAssinatura ? this.$route.params.idAssinatura : "";
    this.idRenovacao = this.$route.params.idRenovacao ? this.$route.params.idRenovacao : "";

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA") {
        this.headerEnderecos.push({
          text: "Entregas",
          value: "entregas",
          width: "700"
        });
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_RENOVAÇÃO_TAXAR") {
        this.direitoTaxar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_RENOVAÇÃO_FORÇAR_EXCLUSÃO") {
        this.direitoForcarExclusao = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_ASSINANTES_EDITAR") {
        this.direitoAssinante = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_RENOVAÇÃO_REINÍCIO") {
        this.direitoReinicio = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_RENOVAÇÃO_CORRIGIR_EDIÇÃO") {
        this.direitoCorrigirEdicao = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_RENOVAÇÃO_ALTERAR_PLANO") {
        this.direitoAlterarPlano = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_RENOVAÇÃO_ALTERAR_ASSINANTE") {
        this.direitoAlterarAssinante = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_RENOVAÇÃO_ALTERAR_INÍCIO") {
        this.direitoAlterarInicio = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_RENOVAÇÃO_BONIFICAR") {
        this.direitoBonificar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_RENOVAÇÃO_DEFINIR_CARÊNCIA") {
        this.direitoDefinirCarencia = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_RENOVAÇÃO_ALTERAR_PRODUTOS") {
        this.direitoAlterarProdutos = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_RENOVAÇÃO_ALTERAR_AGENTES") {
        this.direitoAlterarAgentes = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_RENOVAÇÃO_RENOVAR") {
        this.direitoRenovar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_RENOVAÇÃO_RENOVAR_UNIR") {
        this.direitoRenovarUnir = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_RENOVAÇÃO_FINALIZAR_RENOVAR") {
        this.direitoFinalizarRenovar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_RENOVAÇÃO_EXCLUIR") {
        this.direitoExcluir = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_RECEBER_ACESSO") {
        this.direitoParcelas = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ADMINISTRATIVO_ACERVO_CLIENTES_MIGRAR") {
        this.direitoMigrarAcervo = true;
      }
    });

    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

    if (this.idAssinatura == "" || this.idRenovacao == "") {
      this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_ASSINATURAS_CRIAR"]);

      this.assinatura.idSite = this.idSitePrincipal;
      this.taxar = true;

      //* Carrega os itens dos selects
      await this.carregarOrigensVenda("sim");
      await this.carregarPlanosSite(this.idSitePrincipal);

      if (this.$route.query.idCliente) {
        try {
          //* Carrega o cliente recebido na url
          const responseCliente = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente/" + this.$route.query.idCliente + "?idSite=" + this.idSitePrincipal);
          if (responseCliente.data) {
            var cliente = responseCliente.data;
            this.renovacao.idCliente = cliente.id;
            this.clientes.push({
              id: cliente.id,
              nome: cliente.nome,
              diaVencimento: cliente.diaVencimento,
              statusCredito: cliente.statusCredito,
              situacao: cliente.situacao
            });
            this.clienteSelecionado = {
              id: cliente.id,
              nome: cliente.nome,
              diaVencimento: cliente.diaVencimento,
              statusCredito: cliente.statusCredito,
              situacao: cliente.situacao
            };
            await this.carregarDepartamentosCliente();
            await this.carregarNroDependentes();
            await this.carregarEnderecos("entrega");
            await this.carregarEnderecosEntregaCliente();
          }
        } catch {
          this.isUpdating = true;
          this.mensagem = "Ocorreu um erro ao carregar o cliente";
          this.snackErro = true;
        }
      }
    } else {
      this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_ASSINATURAS_EDITAR"]);

      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/assinatura/" + this.idAssinatura + "?idSite=" + this.idSitePrincipal);

        if (!response.data) {
          this.$router.push("/assinaturas/assinaturas");
          return;
        }
        this.assinatura = response.data;
        await this.carregarOrigensVenda("lista");

        const responseRenovacao = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/renovacao/" + this.idRenovacao + "?idAssinatura=" + this.idAssinatura);
        if (!responseRenovacao.data) {
          this.$router.push("/assinaturas/assinatura/" + this.idAssinatura);
          return;
        }
        this.renovacao = responseRenovacao.data;
        this.renovacao.dtOp = util.formatDateTime(this.renovacao.dtCriacao);
        this.idCliente = this.renovacao.idCliente;

        //* Já dispara o carregamento das edições, pois é bem demorado
        this.carregarEdicoes();

        const responseUltimaDtFim = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/renovacao/ultimaDtFim/" + this.idAssinatura + "?renovacao=" + this.renovacao.renovacao);
        if (responseUltimaDtFim.data) {
          this.renovacao.dtFimAnterior = responseUltimaDtFim.data;
        }

        this.dtInicioUS = util.dateToStringUS(this.renovacao.dtInicio);
        this.renovacao.dtF = util.formatDate(this.renovacao.dtFim);
        this.vlrTaxado = this.renovacao.vlrTaxado;
        this.carencia = this.assinatura.carencia;
        this.vlrBruto = util.formatCurrency(this.renovacao.vlrBruto);
        this.vlrLiquido = util.formatCurrency(this.renovacao.vlrLiquido);
        this.renovacao.recriarParcelas = false;
        this.editar = new Date() < new Date(this.renovacao.dtInicio) || this.assinatura.status == "PROPOSTA"; //* dtInicio é futura (ou é proposta)
        this.renovacaoCorrente = !this.editar && new Date() < new Date(this.renovacao.dtFim) && this.assinatura.status == "CORRENTE";
        this.assinaturaExpirada = new Date() > new Date(this.renovacao.dtFim) && ["CORRENTE", "EXPIRADA"].includes(this.assinatura.status); //* indica se a renovação em edição faz parte de uma assinatura expirada
        this.editarParcelas = this.assinatura.status != "PROPOSTA" || (this.assinatura.status == "PROPOSTA" && this.editar); // * Só edita parcelas de proposta enquanto puder tornar-se CORRENTE (uma proposta expirada não pode mais passar a CORRENTE - é uma proposta perdida [deletar suas parcelas?] - senão, teria que atualizar a dtInicio e os vencimentos das parcelas e talvez seus valores)

        this.bonificar = this.assinatura.ultimaRenovacao == this.renovacao.id; //&&          this.renovacaoCorrente;

        //* Carrega os itens dos selects

        //* Carrega inicialmente, somente o cliente da renovação (se permitida alteração, poderá buscar outros clientes)
        this.clientes.push({
          id: this.renovacao.idCliente,
          nome: this.renovacao.cliente,
          diaVencimento: this.renovacao.diaVencimento,
          statusCredito: this.renovacao.statusCredito,
          situacao: this.renovacao.situacaoCliente
        });
        this.clienteSelecionado = {
          id: this.renovacao.idCliente,
          nome: this.renovacao.cliente,
          diaVencimento: this.renovacao.diaVencimento,
          statusCredito: this.renovacao.statusCredito,
          situacao: this.renovacao.situacaoCliente
        };
        await this.carregarDepartamentosCliente();
        await this.carregarNroDependentes();
        await this.carregarEnderecos("entrega");
        await this.carregarEnderecosEntregaCliente();

        if (!this.editar) {
          //* se data de início já passou, carrega somente os dados principais, pois não haverá edição
          this.planos = [{ id: this.renovacao.idPlano, nome: this.renovacao.plano }];
          this.planoSelecionado = {
            id: this.renovacao.idPlano,
            nome: this.renovacao.plano,
            periodo: this.renovacao.periodo,
            tempo: this.renovacao.tempo
          };

          this.limiteCarencia = 0;
          if (this.planoSelecionado.periodo == "D") {
            this.limiteCarencia = this.planoSelecionado.tempo;
          } else {
            // this.limiteCarencia = 30 * this.planoSelecionado.tempo; //jornais não estavam conseguindo trabalhar com apenas 30 dias
            // if (this.limiteCarencia > 90) {
            this.limiteCarencia = 90;
            // }
          }
        } else {
          //* se data de início ainda não chegou, carrega os planos do site e os produtos e agentes do plano para permitir alteração
          await this.carregarPlanosSite(this.assinatura.idSite);
          await this.carregarProdutosPlano(this.renovacao.idPlano);
          await this.carregarAgentesPlano(this.renovacao.idPlano);
        }
        await this.carregarFormasPgtoPlano(this.renovacao.idPlano);

        //* Carrega os subitens da renovação
        await this.carregarProdutos();
        var existeImpresso = false;
        for (let index = 0; index < this.produtos.length; index++) {
          const element = this.produtos[index];
          if (element.produtoTipo == "IMPRESSO") {
            existeImpresso = true;
          }
        }
        if (existeImpresso) {
          await this.calcularDtInicio();
        } else {
          this.dataInicio = new Date();
          this.dataInicio.setDate(this.dataInicio.getDate() + 1);
        }

        var dataTermino = util.parseDateUS(this.dataInicio);
        dataTermino.setDate(dataTermino.getDate() - 1);
        var dtProxInicio = util.parseDateUS(this.renovacao.dtFim);
        dtProxInicio.setDate(dtProxInicio.getDate() + 1);
        this.titleRenovar = `Renova com data de início em ${util.formatDate(this.dataInicio > dtProxInicio ? this.dataInicio : dtProxInicio)}`;
        this.titleRenovarUnir = `Renova com data de início em ${util.formatDate(dtProxInicio)}`;
        this.titleFinalizarRenovar = `Finaliza esta renovação com data de término em ${util.formatDate(dataTermino)} e cria outra iniciando-se em ${util.formatDate(
          this.dataInicio
        )}`;

        await this.carregarAgentesOperacao();
        await this.carregarParcelas();
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os dados da renovação";
        this.snackErro = true;
      }
    }
    setTimeout(() => {
      this.carregandoPla = false;
      this.carregandoCli = false;
      this.bloqueiaBotoes = false;
    }, 1000);
  }
};
</script>
