<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">colorize</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Formato</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12 md12 v-if="siteGrupo.length > 0">
            <v-autocomplete
              v-model="formato.idSite"
              :disabled="isUpdating"
              :items="siteGrupo"
              :filter="filtrarObjetos"
              label="Restrito ao Site"
              item-text="nome"
              item-value="id"
              :rules="[validacao.idSite == false || validacao.idSite]"
              no-data-text
              clearable
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.idSite = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md6>
            <v-autocomplete
              v-model="formato.tipo"
              autofocus
              label="Selecione o Tipo de Formato"
              :disabled="isUpdating || (id != '' && !direitoAvancado)"
              :items="tipos"
              :filter="filtrarObjetos"
              hint="Obrigatório"
              :rules="[validacao.tipo == false || validacao.tipo, value => !!value || 'Obrigatório']"
              item-text="nome"
              item-value="id"
              autocomplete="disabled"
              :name="Math.random()"
              no-data-text
              @change="
                nomeDefault();
                validacao.tipo = false;
              "
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md6>
            <v-text-field
              v-model="formato.nome"
              label="Nome do Formato"
              maxlength="50"
              hint="Obrigatório"
              :rules="[
                validacao.nome == false || validacao.nome,
                value => !!value || 'Obrigatório.',
                value => valida_nome(value) || 'Nome inválido',
                value => value.length > 2 || 'Preencha o nome do produto'
              ]"
              :disabled="isUpdating"
              autocomplete="disabled"
              :name="Math.random()"
              @change="
                formato.nome = pLetra(formato.nome);
                validacao.nome = false;
              "
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md12>
            <v-textarea
              v-model="formato.comandosEstilos"
              :disabled="isUpdating"
              label="Estilos"
              maxlength="4000"
              counter="4000"
              rows="6"
              :rules="[
                validacao.comandosEstilos == false || validacao.comandosEstilos,
                value => !value || (value.length > 10 && value.length < 4000) || 'Descrição dos estilos deve ter entre 10 e 4000 caracteres'
              ]"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.comandosEstilos = false"
            ></v-textarea>
          </v-flex>

          <v-flex xs6 md3>
            <v-text-field
              v-model="formato.colunas"
              :disabled="isUpdating"
              label="Nº de Colunas Padrão"
              :rules="[validacao.colunas == false || validacao.colunas, value => value == 0 || parseInt(value) < 13 || 'Verifique o número de colunas']"
              v-mask="'##'"
              maxlength="2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.colunas = false"
            ></v-text-field>
          </v-flex>
          <v-flex xs6 md3>
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="formato.altura"
              label="Altura Padrão"
              :allowNegative="false"
              :max="32"
              :rules="[validacao.altura == false || validacao.altura, value => valida_altura(value)]"
              :disabled="isUpdating"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.altura = false"
            ></v-currency-field>
          </v-flex>
          <v-flex xs12 md6> </v-flex>

          <v-flex xs6 md3>
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="formato.valorMinimo"
              label="Valor Mínimo"
              :allowNegative="false"
              :max="999999"
              hint="Obrigatório"
              :rules="[validacao.valorMinimo == false || validacao.valorMinimo, value => (value && value.length < 11) || 'Valor mínimo inválido']"
              :disabled="isUpdating"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.valorMinimo = false"
            ></v-currency-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="formato.valorMaximo"
              label="Valor Máximo"
              :allowNegative="false"
              :max="999999"
              hint="Obrigatório"
              :rules="[validacao.valorMaximo == false || validacao.valorMaximo, value => (value && value.length < 11) || 'Valor máximo inválido']"
              :disabled="isUpdating"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.valorMaximo = false"
            ></v-currency-field>
          </v-flex>

          <v-flex xs6 md3 v-if="formato.tipo != 'P'">
            <v-text-field
              v-model="formato.maxCaracteres"
              label="Máximo de Caracteres"
              v-mask="'#####'"
              hint="Obrigatório - use 0 para ilimitado"
              :rules="[validacao.maxCaracteres == false || validacao.maxCaracteres, value => parseInt(value) < 10000 || 'Máximo de caracteres inválido']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.maxCaracteres = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs6 md3 v-if="formato.tipo == 'P'"></v-flex>
          <v-flex xs6 md3>
            <v-text-field
              v-model="formato.codAntigoComercial"
              label="Código Comercial"
              v-mask="'#######'"
              :rules="[validacao.codAntigoComercial == false || validacao.codAntigoComercial]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.codAntigoComercial = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-switch v-model="formato.cor" :disabled="isUpdating" :label="`${formato.cor == 1 ? 'Colorido' : 'Preto/Branco'}`"></v-switch>
          </v-flex>

          <v-flex xs6 md3>
            <v-switch v-model="formato.diagramar" :disabled="isUpdating" :label="`${formato.diagramar == 1 ? 'Diagramação Ativa' : 'Diagramação inativa'}`"></v-switch>
          </v-flex>

          <v-flex xs6 md3>
            <v-switch
              v-model="formato.situacao"
              :label="`${formato.situacao == 1 ? 'Ativo' : 'Inativo'}`"
              :disabled="isUpdating || (id != '' && formato.situacao == 1 && !direitoAvancado)"
            ></v-switch>
          </v-flex>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">{{ validacao.mensagemErro }}</v-sheet>
          </v-flex>
        </v-layout>

        <v-layout wrap>
          <v-expansion-panels v-model="painel" focusable>
            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.cadernos ? 'red--text' : ''">
                <strong>Cadernos</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout wrap>
                  <v-flex xs11 md6>
                    <v-autocomplete
                      v-model="cadernosSelecionados"
                      autofocus
                      label="Cadernos"
                      :disabled="isUpdating"
                      :items="cadernos"
                      :filter="filtrarObjetos"
                      item-text="nome"
                      item-value="id"
                      clearable
                      multiple
                      autocomplete="disabled"
                      :name="Math.random()"
                      no-data-text
                      return-object
                      chips
                    >
                      <template v-slot:item="data">
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.nomeS"></v-list-item-title>
                          <v-list-item-subtitle v-html="data.item.produto"></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs1>
                    <v-btn color="success" fab small @click="incluiCaderno" class="mt-2">
                      <v-icon color="white" title="Incluir">add</v-icon>
                    </v-btn>
                  </v-flex>
                  <v-spacer></v-spacer>
                  <v-flex md4>
                    <v-text-field
                      background-color="white"
                      class="mt-2 mb-2 elevation-0"
                      v-model="searchCadernos"
                      append-icon="search"
                      label="Digite para pesquisar..."
                      placeholder="Pesquisa pelo nome ou produto"
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md12>
                    <v-card>
                      <v-data-table
                        mobile-breakpoint="0"
                        :headers="headerCadernos"
                        :items="cadernosFormato"
                        :search="searchCadernos"
                        no-data-text
                        no-results-text
                        :footer-props="{
                          itemsPerPageText: 'Registros por página',
                          itemsPerPageOptions: [10, 25, 50],
                          pageText: ''
                        }"
                      >
                        <template v-slot:[getItemActions()]="{ item }">
                          <router-link :to="'/comercial/caderno/' + item.idCaderno" style="text-decoration: none" v-if="direitoCaderno" target="_blank">
                            <v-icon color="green" title="Visualizar Caderno">loupe</v-icon>
                          </router-link>
                          <v-icon
                            :color="item.situacao == 1 ? 'blue lighten-1' : 'blue-grey lighten-3'"
                            :title="item.situacao == 1 ? 'Desativar' : 'Desativado'"
                            @click="desativarCaderno(item)"
                            :disabled="!item.situacao || (id != '' && !direitoAvancado)"
                          >
                            {{ item.situacao == 1 ? "radio_button_checked" : "radio_button_unchecked" }}
                          </v-icon>
                        </template>

                        <template v-slot:[getItemCadernoSituacao()]="{ item }">
                          <span v-html="item.cadernoSituacao == 1 ? 'Ativo' : item.cadernoSituacao == 0 ? '<strong>Inativo</strong>' : ''"></span>
                        </template>
                        <template v-slot:[getItemDtRegistro()]="{ item }">
                          {{ formatDateTime(item.dtRegistro) }}
                        </template>
                        <template v-slot:[getItemDtExclusao()]="{ item }">
                          {{ formatDateTime(item.dtExclusao) }}
                        </template>

                        <template v-slot:no-results>
                          <v-alert :value="true" color="error" outlined icon="warning">"{{ searchCadernos }}" não encontrado.</v-alert>
                        </template>
                      </v-data-table>
                      <v-alert v-if="validacao.cadernos" :value="true" color="error" icon="priority_high" outlined>{{ validacao.cadernos }}</v-alert>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.agentes ? 'red--text' : ''">
                <strong>Agentes/Agências</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout wrap>
                  <v-flex xs12 md6>
                    <v-autocomplete
                      v-model="agenteSelecionado"
                      autofocus
                      label="Incluir Agente/Agência"
                      :disabled="isUpdating"
                      :items="agentes"
                      :filter="filtrarObjetos"
                      item-text="nome"
                      item-value="id"
                      autocomplete="disabled"
                      :name="Math.random()"
                      no-data-text
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                  <v-spacer></v-spacer>
                  <v-flex md4>
                    <v-text-field
                      background-color="white"
                      class="mt-2 mb-2 elevation-0"
                      v-model="searchAgentes"
                      append-icon="search"
                      label="Digite para pesquisar..."
                      placeholder="Pesquisa pelo nome"
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md12>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="headerAgentes"
                      :items="agentesFormato"
                      :search="searchAgentes"
                      no-data-text="Deixe em branco caso deseje que TODOS os agentes ATIVOS estejam disponíveis para este formato."
                      no-results-text
                      :footer-props="{
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageOptions: [10, 25, 50],
                        pageText: ''
                      }"
                    >
                      <template v-slot:[getItemActions()]="{ item }">
                        <router-link :to="'/administrativo/agente/' + item.idAgente" style="text-decoration: none" v-if="direitoAgente" target="_blank">
                          <v-icon color="green" title="Visualizar Agente">loupe</v-icon>
                        </router-link>
                        <v-icon
                          :color="item.situacao == 1 ? 'blue lighten-1' : 'blue-grey lighten-3'"
                          :title="item.situacao == 1 ? 'Desativar' : 'Desativado'"
                          @click="desativarAgente(item)"
                          :disabled="!item.situacao || (id != '' && !direitoAvancado)"
                        >
                          {{ item.situacao == 1 ? "radio_button_checked" : "radio_button_unchecked" }}
                        </v-icon>
                      </template>

                      <template v-slot:[getItemAgenteSituacao()]="{ item }">
                        <span v-html="item.agenteSituacao == 1 ? 'Ativo' : item.agenteSituacao == 0 ? '<strong>Inativo</strong>' : ''"></span>
                      </template>
                      <template v-slot:[getItemDtRegistro()]="{ item }">
                        {{ formatDateTime(item.dtRegistro) }}
                      </template>
                      <template v-slot:[getItemDtExclusao()]="{ item }">
                        {{ formatDateTime(item.dtExclusao) }}
                      </template>

                      <template v-slot:no-results>
                        <v-alert :value="true" color="error" outlined icon="warning">"{{ searchAgentes }}" não encontrado.</v-alert>
                      </template>
                    </v-data-table>
                  </v-flex>
                  <v-alert v-if="validacao.agentes" :value="true" color="error" icon="priority_high" outlined>{{ validacao.agentes }}</v-alert>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.condicoesEspeciais ? 'red--text' : ''">
                <strong>Condições Especiais</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-toolbar>
                      <v-spacer></v-spacer>
                      <v-dialog persistent v-model="dialogCondicaoEspecial" max-width="600px">
                        <template v-slot:activator="{ on }">
                          <v-btn color="success" fab small v-on="on">
                            <v-icon color="white" title="Incluir">add</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form ref="formCondicaoEspecial" v-model="condicaoEspecialValid" lazy-validation>
                            <v-toolbar>
                              <span class="headline">Condição Especial</span>
                              <v-spacer></v-spacer>
                              <v-btn icon @click="closeCondicaoEspecial">
                                <v-icon color="darken-1">cancel</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-container grid-list-md>
                                <v-layout wrap>
                                  <v-flex xs12 md12>
                                    <v-autocomplete
                                      autofocus
                                      v-model="condicaoEspecialSelecionada"
                                      :items="condicoesEspeciais"
                                      :filter="filtrarObjetos"
                                      :rules="[validacao.idCondicaoEspecial == false || validacao.idCondicaoEspecial, value => (!!value && !!value.id) || 'Obrigatório.']"
                                      hint="Obrigatório"
                                      label="Condição Especial"
                                      item-text="nome"
                                      item-value="id"
                                      no-data-text
                                      return-object
                                    ></v-autocomplete>
                                  </v-flex>

                                  <v-flex xs12 md12>
                                    <v-autocomplete
                                      v-model="condicaoEspecialItem.automatica"
                                      :items="automaticos"
                                      :filter="filtrarObjetos"
                                      :rules="[validacao.automatica == false || validacao.automatica, value => value != null || 'Obrigatório.']"
                                      label="Aplicação"
                                      item-text="nome"
                                      item-value="id"
                                      no-data-text
                                    ></v-autocomplete>
                                  </v-flex>

                                  <v-flex xs12>
                                    <v-autocomplete
                                      v-model="condicaoEspecialItem.tipo"
                                      :items="tiposCondicao"
                                      :filter="filtrarObjetos"
                                      hint="Obrigatório"
                                      :rules="[validacao.tipo == false || validacao.tipo, value => !!value || 'Obrigatório.']"
                                      label="Selecione o Tipo"
                                      item-text="nome"
                                      item-value="id"
                                      no-data-text
                                    >
                                      <template v-slot:selection="data">
                                        <v-avatar size="24" class="accent white--text" left>
                                          {{ data.item.id }}
                                        </v-avatar>
                                        <span class="ml-2">{{ data.item.nome }}</span>
                                      </template>
                                      <template v-slot:item="data">
                                        <v-list-item-avatar class="accent white--text">
                                          {{ data.item.id }}
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                          <v-list-item-title v-html="data.item.nome"></v-list-item-title>
                                        </v-list-item-content>
                                      </template>
                                    </v-autocomplete>
                                  </v-flex>

                                  <v-flex xs1>
                                    <v-icon
                                      :color="condicaoEspecialItem.isAcrescimo ? 'blue' : 'red'"
                                      title="Alterar"
                                      @click="condicaoEspecialItem.isAcrescimo = !condicaoEspecialItem.isAcrescimo"
                                    >
                                      {{ condicaoEspecialItem.isAcrescimo ? "add_circle" : "remove_circle" }}
                                    </v-icon>
                                  </v-flex>

                                  <v-flex xs11>
                                    <v-currency-field
                                      :locale="`pt-${$store.state.usuario.site_pais}`"
                                      v-model.number="condicaoEspecialItem.valor"
                                      :label="condicaoEspecialItem.isAcrescimo ? 'Acréscimo' : 'Desconto'"
                                      :allowNegative="false"
                                      :max="999"
                                      color="blue-grey lighten-2"
                                      :prefix="condicaoEspecialItem.tipo"
                                      :rules="[validacao.valor == false || validacao.valor]"
                                    ></v-currency-field>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" @click="closeCondicaoEspecial">Retornar</v-btn>
                              <v-btn color="blue darken-1" @click="saveCondicaoEspecial">Atualizar</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="headerCondicoesEspeciais"
                      :items="condicoesEspeciaisFormato"
                      no-data-text
                      no-results-text
                      :footer-props="{
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageOptions: [10, 25, 50],
                        pageText: ''
                      }"
                    >
                      <template v-slot:[getItemActions()]="{ item }">
                        <router-link :to="'/comercial/regra-ajuste/' + item.idCondicaoEspecial" style="text-decoration: none" v-if="direitoCondicaoEspecial" target="_blank">
                          <v-icon color="green" title="Visualizar Condição Especial">loupe</v-icon>
                        </router-link>
                        <v-icon
                          :color="item.situacao == 1 ? 'blue lighten-1' : 'blue-grey lighten-3'"
                          :title="item.situacao == 1 ? 'Desativar' : 'Desativado'"
                          @click="desativarCondicaoEspecial(item)"
                          :disabled="!item.situacao || (id != '' && !direitoAvancado)"
                        >
                          {{ item.situacao == 1 ? "radio_button_checked" : "radio_button_unchecked" }}
                        </v-icon>
                      </template>

                      <template v-slot:[getItemAutomatica()]="{ item }">
                        <span v-html="item.automatica == 1 ? 'Sim' : ''"></span>
                      </template>

                      <template v-slot:[getItemCondicaoEspecialSituacao()]="{ item }">
                        <span v-html="item.condicaoEspecialSituacao == 1 ? 'Ativa' : '<strong>Inativa</strong>'"></span>
                      </template>
                      <template v-slot:[getItemValor()]="{ item }">
                        <v-icon>{{ item.isAcrescimo ? "add_circle" : "remove_circle" }}</v-icon>
                        {{ formatCurrency(item.valor) }}
                      </template>
                      <template v-slot:[getItemDtRegistro()]="{ item }">
                        {{ formatDateTime(item.dtRegistro) }}
                      </template>
                      <template v-slot:[getItemDtExclusao()]="{ item }">
                        {{ formatDateTime(item.dtExclusao) }}
                      </template>
                    </v-data-table>
                    <v-alert v-if="validacao.condicoesEspeciais" :value="true" color="error" icon="priority_high" outlined>{{ validacao.condicoesEspeciais }}</v-alert>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.formasPagamento ? 'red--text' : ''">
                <strong>Formas de Pagamento</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-toolbar>
                      <v-spacer></v-spacer>
                      <v-dialog persistent v-model="dialogFormaPagamento" max-width="600px">
                        <template v-slot:activator="{ on }">
                          <v-btn color="success" fab small v-on="on">
                            <v-icon color="white" title="Incluir">add</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form ref="formFormaPagamento" v-model="formaPagamentoValid" lazy-validation>
                            <v-toolbar>
                              <span class="headline">Forma de Pagamento</span>
                              <v-spacer></v-spacer>
                              <v-btn icon @click="closeFormaPagamento">
                                <v-icon color="darken-1">cancel</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-container grid-list-md>
                                <v-layout wrap>
                                  <v-flex xs12 md12>
                                    <v-autocomplete
                                      autofocus
                                      v-model="formaPagamentoSelecionada"
                                      :items="formasPagamento"
                                      :filter="filtrarObjetos"
                                      :rules="[validacao.formaPagamento == false || validacao.formaPagamento, value => (!!value && !!value.id) || 'Obrigatório.']"
                                      hint="Obrigatório"
                                      label="Forma de Pagamento"
                                      item-text="nome"
                                      item-value="id"
                                      no-data-text
                                      return-object
                                    ></v-autocomplete>
                                  </v-flex>
                                  <v-flex xs12 md6>
                                    <v-text-field
                                      v-model="formaPagamentoItem.maxParcelas"
                                      label="Limite de Parcelas"
                                      v-mask="'##'"
                                      hint="Obrigatório"
                                      :rules="[
                                        validacao.maxParcelas == false || validacao.maxParcelas,
                                        value => !!value || 'Obrigatório.',
                                        value => (value > 0 && value < 25) || 'Limite deve ser de 1 a 24'
                                      ]"
                                      :disabled="isUpdating"
                                      color="blue-grey lighten-2"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      :readonly="isFormaPgto1Parcela(formaPagamentoItem.formaPagamentoTipo)"
                                    ></v-text-field>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" @click="closeFormaPagamento">Retornar</v-btn>
                              <v-btn color="blue darken-1" @click="saveFormaPagamento">Atualizar</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="headerFormasPagamento"
                      :items="formasPagamentoFormato"
                      no-data-text
                      no-results-text
                      :footer-props="{
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageOptions: [10, 25, 50],
                        pageText: ''
                      }"
                    >
                      <template v-slot:[getItemActions()]="{ item }">
                        <router-link :to="'/administrativo/forma-pagamento/' + item.idFormaPgto" style="text-decoration: none" v-if="direitoFormaPgto" target="_blank">
                          <v-icon color="green" title="Visualizar Forma de Pagamento">loupe</v-icon>
                        </router-link>
                        <v-icon
                          :color="item.situacao == 1 ? 'blue lighten-1' : 'blue-grey lighten-3'"
                          :title="item.situacao == 1 ? 'Desativar' : 'Desativado'"
                          @click="desativarFormaPagamento(item)"
                          :disabled="!item.situacao || (id != '' && !direitoAvancado)"
                        >
                          {{ item.situacao == 1 ? "radio_button_checked" : "radio_button_unchecked" }}
                        </v-icon>
                      </template>

                      <template v-slot:[getItemFormaPagamentoSituacao()]="{ item }">
                        <span v-html="item.formaPagamentoSituacao == 1 ? 'Ativa' : '<strong>Inativa</strong>'"></span>
                      </template>
                      <template v-slot:[getItemDtRegistro()]="{ item }">
                        {{ formatDateTime(item.dtRegistro) }}
                      </template>
                      <template v-slot:[getItemDtExclusao()]="{ item }">
                        {{ formatDateTime(item.dtExclusao) }}
                      </template>
                    </v-data-table>
                    <v-alert v-if="validacao.formasPagamento" :value="true" color="error" icon="priority_high" outlined>{{ validacao.formasPagamento }}</v-alert>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.regrasAjuste ? 'red--text' : ''">
                <strong>Regras de Ajuste de Preços</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-toolbar>
                      <v-spacer></v-spacer>
                      <v-dialog persistent v-model="dialogRegraAjuste" max-width="600px">
                        <template v-slot:activator="{ on }">
                          <v-btn color="success" fab small v-on="on">
                            <v-icon color="white" title="Incluir">add</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form ref="formRegraAjuste" v-model="regraAjusteValid" lazy-validation>
                            <v-toolbar>
                              <span class="headline">Regra de Ajuste</span>
                              <v-spacer></v-spacer>
                              <v-btn icon @click="closeRegraAjuste">
                                <v-icon color="darken-1">cancel</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-container grid-list-md>
                                <v-layout wrap>
                                  <v-flex xs12 md12>
                                    <v-autocomplete
                                      autofocus
                                      v-model="regraAjusteSelecionada"
                                      :items="regrasAjuste"
                                      :filter="filtrarObjetos"
                                      :rules="[validacao.regraAjuste == false || validacao.regraAjuste, value => (!!value && !!value.id) || 'Obrigatório.']"
                                      hint="Obrigatório"
                                      label="Regra de Ajuste"
                                      item-text="nome"
                                      item-value="id"
                                      no-data-text
                                      return-object
                                    ></v-autocomplete>
                                  </v-flex>
                                  <v-flex xs12 md12>
                                    <v-autocomplete
                                      v-model="regraAjusteItem.automatica"
                                      :items="automaticos"
                                      :filter="filtrarObjetos"
                                      :rules="[validacao.automatica == false || validacao.automatica, value => value != null || 'Obrigatório.']"
                                      label="Aplicação"
                                      item-text="nome"
                                      item-value="id"
                                      no-data-text
                                    ></v-autocomplete>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" @click="closeRegraAjuste">Retornar</v-btn>
                              <v-btn v-if="regraAjusteItem.id == null" color="blue darken-1" @click="saveRegraAjuste">Atualizar</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="headerRegrasAjuste"
                      :items="regrasAjusteFormato"
                      no-data-text="Deixe em branco caso deseje utilizar a regra de ajuste PADRÃO para Anúncios."
                      no-results-text
                      :footer-props="{
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageOptions: [10, 25, 50],
                        pageText: ''
                      }"
                    >
                      <template v-slot:[getItemActions()]="{ item }">
                        <router-link :to="'/comercial/regra-ajuste/' + item.idRegraAjuste" style="text-decoration: none" v-if="direitoRegraAjuste" target="_blank">
                          <v-icon color="green" title="Visualizar Regra de Ajuste">loupe</v-icon>
                        </router-link>
                        <v-icon
                          :color="item.situacao == 1 ? 'blue lighten-1' : 'blue-grey lighten-3'"
                          :title="item.situacao == 1 ? 'Desativar' : 'Desativado'"
                          @click="desativarRegraAjuste(item)"
                          :disabled="!item.situacao || (id != '' && !direitoAvancado)"
                        >
                          {{ item.situacao == 1 ? "radio_button_checked" : "radio_button_unchecked" }}
                        </v-icon>
                      </template>
                      <template v-slot:[getItemAutomatica()]="{ item }">
                        {{
                          item.automatica == 1
                            ? "Captação (Automático)"
                            : item.automatica == 2
                            ? "Renovação (Automático)"
                            : item.automatica == 3
                            ? "Captação e Renovação (Automático)"
                            : "Usuário (Manual)"
                        }}
                      </template>

                      <template v-slot:[getItemRegraAjusteSituacao()]="{ item }">
                        <span v-html="item.regraAjusteSituacao == 1 ? 'Ativa' : '<strong>Inativa</strong>'"></span>
                      </template>
                      <template v-slot:[getItemDtRegistro()]="{ item }">
                        {{ formatDateTime(item.dtRegistro) }}
                      </template>
                      <template v-slot:[getItemDtExclusao()]="{ item }">
                        {{ formatDateTime(item.dtExclusao) }}
                      </template>
                    </v-data-table>
                    <v-alert v-if="validacao.regrasAjuste" :value="true" color="error" icon="priority_high" outlined>{{ validacao.regrasAjuste }}</v-alert>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn to="/comercial/formatos" id="btn-rsn" color="orange lighten-2 white" style="color:white"> <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar </v-btn>

      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="blue white" style="color:white" id="btn-rsn" depressed @click="salvar('false')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar
      </v-btn>
      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="orange white" style="color:white" id="btn-rsn" outlined @click="salvar('retornar')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>
      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="blue white" style="color:white" id="btn-rsn" outlined @click="salvar('true')" v-if="direitoCriar">
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Novo
      </v-btn>
    </v-card-actions>

    <v-dialog v-model="dialogConfirm" max-width="290">
      <v-card>
        <v-toolbar>
          <span class="headline">Confirmação</span>
        </v-toolbar>
        <v-card-text>{{ msgConfirm }}</v-card-text>
        <v-card-actions>
          <v-btn color="red darken-1" text @click="naoConfirmado">Não</v-btn>
          <v-btn color="green darken-1" text @click="confirmado">Sim</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>

<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      formato: {
        id: "",
        idSite: null,
        nome: "",
        tipo: null,
        valorMinimo: 0,
        valorMaximo: 0,
        maxCaracteres: 0,
        comandosEstilos: "",
        cor: 1,
        diagramar: 1,
        colunas: 0,
        altura: 0,
        situacao: 1,
        codAntigoComercial: null
      },

      validacao: {
        id: false,
        idSite: false,
        nome: false,
        tipo: false,
        valorMinimo: false,
        valorMaximo: false,
        maxCaracteres: false,
        colunas: false,
        altura: false,
        comandosEstilos: false,
        cor: false,
        diagramar: false,
        situacao: false,
        codAntigoComercial: false,
        cadernos: false,
        agentes: false,
        idCondicaoEspecial: false,
        automatica: false,
        valor: false,
        condicoesEspeciais: false,
        formasPagamento: false,
        formaPagamento: false,
        maxParcelas: false,
        regrasAjuste: false,
        regraAjuste: false,
        mensagemErro: false
      },

      idSitePrincipal: null,
      siteGrupo: [],
      produtos: [],
      cadernos: [],
      agentes: [],
      condicoesEspeciais: [],
      formasPagamento: [],
      regrasAjuste: [],
      tipos: [
        { id: "LS", nome: "Linha Simples" },
        { id: "LSI", nome: "Linha Simples Imagem" },
        { id: "LT", nome: "Linha Titulada" },
        { id: "LTI", nome: "Linha Titulada Imagem" },
        { id: "D", nome: "Destacado" },
        { id: "P", nome: "Publicidade" },
        { id: "S", nome: "Site" }
      ],
      automaticos: [
        { id: 0, nome: "Usuário (Manual)" },
        { id: 1, nome: "Captação (Automático)" }
      ],
      tiposCondicao: [
        { id: "%", nome: "será aplicado PERCENTUAL ao anúncio" },
        { id: "$", nome: "será aplicado VALOR ao anúncio" }
      ],

      estiloSimples: `<ParaStyle:LINHA_SIMPLES>
<$TITULO>
<$CARACTER:ENTER>
<$TEXTO>
<$CARACTER:ENTER>`,

      estiloTitulada: `<ParaStyle:LINHA_TITULADA>
<$TITULO>
<$CARACTER:ENTER>
<$TEXTO>
<$CARACTER:ENTER>`,

      headerCadernos: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Nome", value: "nome" },
        { text: "Produto", value: "produto" },
        { text: "Situação", value: "cadernoSituacao" },
        { text: "Registro", value: "dtRegistro" },
        { text: "Desativado em", value: "dtExclusao" }
      ],
      headerAgentes: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Agente", value: "nome" },
        { text: "Situação", value: "agenteSituacao" },
        { text: "Registro", value: "dtRegistro" },
        { text: "Desativado em", value: "dtExclusao" }
      ],
      headerCondicoesEspeciais: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Condição Especial", value: "nome" },
        { text: "Automática", value: "automatica" },
        { text: "Valor", value: "valor" },
        { text: "Tipo", value: "tipo" },
        { text: "Situação", value: "condicaoEspecialSituacao" },
        { text: "Registro", value: "dtRegistro" },
        { text: "Desativado em", value: "dtExclusao" }
      ],
      headerFormasPagamento: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Forma de Pagamento", value: "nome" },
        { text: "Parcelas", value: "maxParcelas" },
        { text: "Situação", value: "formaPagamentoSituacao" },
        { text: "Registro", value: "dtRegistro" },
        { text: "Desativado em", value: "dtExclusao" }
      ],
      headerRegrasAjuste: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Regra", value: "nome" },
        { text: "Aplicação", value: "automatica" },
        { text: "Situação", value: "regraAjusteSituacao" },
        { text: "Registro", value: "dtRegistro" },
        { text: "Desativado em", value: "dtExclusao" }
      ],

      cadernosFormato: [],
      agentesFormato: [],
      condicoesEspeciaisFormato: [],
      formasPagamentoFormato: [],
      regrasAjusteFormato: [],

      // searchInput: "",

      searchCadernos: "",
      cadernosSelecionados: [],
      cadernoItem: {},

      searchAgentes: "",
      agenteSelecionado: {},
      agenteItem: {},

      condicaoEspecialSelecionada: {},
      dialogCondicaoEspecial: false,
      condicaoEspecialValid: true,
      condicaoEspecialIndex: -1,
      condicaoEspecialItem: {
        id: null,
        idCondicaoEspecial: null,
        condicaoEspecial: "",
        condicaoEspecialSituacao: 1,
        automatica: null,
        tipo: null,
        valor: 0,
        situacao: 1,
        isAcrescimo: true
      },
      defaultCondicaoEspecialItem: {
        id: null,
        idCondicaoEspecial: null,
        condicaoEspecial: "",
        condicaoEspecialSituacao: 1,
        automatica: null,
        tipo: null,
        valor: 0,
        situacao: 1,
        isAcrescimo: true
      },

      formaPagamentoSelecionada: {},
      dialogFormaPagamento: false,
      formaPagamentoValid: true,
      formaPagamentoIndex: -1,
      formaPagamentoItem: {
        id: null,
        idFormaPgto: null,
        formaPagamento: "",
        formaPagamentoSituacao: 1,
        formaPagamentoTipo: "",
        maxParcelas: 1,
        situacao: 1
      },
      defaultFormaPagamentoItem: {
        id: null,
        idFormaPgto: null,
        formaPagamento: "",
        formaPagamentoSituacao: 1,
        formaPagamentoTipo: "",
        maxParcelas: 1,
        situacao: 1
      },

      regraAjusteSelecionada: {},
      dialogRegraAjuste: false,
      regraAjusteValid: true,
      regraAjusteIndex: -1,
      regraAjusteItem: {
        id: null,
        idRegraAjuste: null,
        regraAjuste: "",
        regraAjusteSituacao: 1,
        automatica: 0,
        situacao: 1
      },
      defaultRegraAjusteItem: {
        id: null,
        idRegraAjuste: null,
        regraAjuste: "",
        regraAjusteSituacao: 1,
        automatica: 0,
        situacao: 1
      },

      painel: [0],
      direitoCriar: false,
      direitoCaderno: false,
      direitoAgente: false,
      direitoCondicaoEspecial: false,
      direitoFormaPgto: false,
      direitoRegraAjuste: false,
      direitoAvancado: false,

      dialogConfirm: false,
      tipoConfirm: "",
      msgConfirm: "",

      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false,
      bloqueiaBotoes: false
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    getItemActions() {
      return "item.actions";
    },
    getItemCadernoSituacao() {
      return "item.cadernoSituacao";
    },
    getItemDtRegistro() {
      return "item.dtRegistro";
    },
    getItemDtExclusao() {
      return "item.dtExclusao";
    },
    getItemAgenteSituacao() {
      return "item.agenteSituacao";
    },
    getItemCondicaoEspecialSituacao() {
      return "item.condicaoEspecialSituacao";
    },
    getItemFormaPagamentoSituacao() {
      return "item.formaPagamentoSituacao";
    },
    getItemValor() {
      return "item.valor";
    },
    getItemAutomatica() {
      return "item.automatica";
    },
    getItemRegraAjusteSituacao() {
      return "item.regraAjusteSituacao";
    },

    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      this.isUpdating = true;
      try {
        var retorno = "";
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "com/formato/" + this.id, this.formato);
        } else {
          var cadernosCommit = [];
          this.cadernosFormato.forEach(element => {
            cadernosCommit.push({ idCaderno: element.idCaderno });
          });
          this.formato.cadernos = cadernosCommit;

          var agentesCommit = [];
          this.agentesFormato.forEach(element => {
            agentesCommit.push({ idAgente: element.idAgente });
          });
          this.formato.agentes = agentesCommit;

          var condicoesEspeciaisCommit = [];
          this.condicoesEspeciaisFormato.forEach(element => {
            condicoesEspeciaisCommit.push({
              idCondicaoEspecial: element.idCondicaoEspecial,
              automatica: element.automatica,
              tipo: element.tipo,
              valor: element.valor * (element.isAcrescimo ? 1 : -1)
            });
          });
          this.formato.condicoesEspeciais = condicoesEspeciaisCommit;

          var formasPagamentoCommit = [];
          this.formasPagamentoFormato.forEach(element => {
            formasPagamentoCommit.push({
              idFormaPgto: element.idFormaPgto,
              maxParcelas: element.maxParcelas
            });
          });
          this.formato.formasPagamento = formasPagamentoCommit;

          var regrasAjusteCommit = [];
          this.regrasAjusteFormato.forEach(element => {
            regrasAjusteCommit.push({
              idRegraAjuste: element.idRegraAjuste,
              automatica: element.automatica
            });
          });
          this.formato.regrasAjuste = regrasAjusteCommit;

          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "com/formato?idSite=" + this.idSitePrincipal, this.formato);
          this.id = retorno.data.id;
          this.formato.id = retorno.data.id;
          this.carregarCadernosFormato();
          this.carregarAgentesFormato();
          this.carregarCondicoesEspeciaisFormato();
          this.carregarFormasPagamentoFormato();
          this.carregarRegrasAjusteFormato();
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "true") {
          this.$router.push("/comercial/formato");
          this.$router.go("/comercial/formato");
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push("/comercial/formatos");
          }, 2000);
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },

    async carregarCadernos() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "com/caderno/lista");
        if (response.data) {
          this.cadernos = response.data;
          this.cadernos.forEach(element => {
            element.nomeS = element.nome;
            element.nome = element.nome + " - " + element.produto;
          });
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de cadernos";
        this.snackErro = true;
      }
    },
    async carregarCadernosFormato() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "com/formato-caderno/formato/" + this.formato.id);
        if (response.data) {
          this.cadernosFormato = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os formatos do caderno";
        this.snackErro = true;
      }
    },
    async incluiCaderno() {
      var incluidos = "";
      this.cadernosSelecionados.forEach(val => {
        var incluido = false;
        this.cadernosFormato.forEach(element => {
          if (element.situacao && val.id == element.idCaderno) {
            incluido = true;
            incluidos += (incluidos ? " | " : "") + val.nome;
          }
        });
        if (!incluido) {
          if (this.formato.id) {
            this.incluirCaderno(val);
          } else {
            this.cadernosFormato.push({
              id: null,
              idCaderno: val.id,
              idFormato: this.formato.id,
              nome: val.nomeS,
              produto: val.produto,
              cadernoSituacao: 1,
              situacao: 1
            });
          }
        }
      });
      if (incluidos) {
        this.mensagem = "Caderno(s) [" + incluidos + "] já incluído(s)";
        this.snackErro = true;
      }
      this.validacao.cadernos = false;
    },
    async incluirCaderno(item) {
      this.cadernosFormato = [];

      var itemCommit = {
        idCaderno: item.id,
        idFormato: this.formato.id
      };
      try {
        await api.post(process.env.VUE_APP_URL_WEB_API + "com/formato-caderno?idSite=" + this.idSitePrincipal, itemCommit);
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }

      this.carregarCadernosFormato();
    },
    async desativarCaderno(item) {
      if (item.id) {
        if (item.situacao) {
          this.cadernoItem = item;
          this.tipoConfirm = "DESATIVAR_CADERNO";
          this.msgConfirm = "Tem certeza que deseja desativar este caderno? (esta ação não pode ser desfeita)";
          this.dialogConfirm = true;
        }
      } else {
        this.cadernosFormato.splice(item, 1);
      }
    },
    async desativaCaderno() {
      try {
        this.cadernosFormato = [];

        await api.put(process.env.VUE_APP_URL_WEB_API + "com/formato-caderno/desativar/" + this.cadernoItem.id);

        this.carregarCadernosFormato();
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },

    async carregarAgentes() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-agente/lista?idSite=" + this.idSitePrincipal);
        if (response.data) {
          this.agentes = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de agentes";
        this.snackErro = true;
      }
    },
    async carregarAgentesFormato() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "com/formato-agente/formato/" + this.formato.id);
        if (response.data) {
          this.agentesFormato = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os agentes do formato";
        this.snackErro = true;
      }
    },
    async incluirAgente(item) {
      this.agentesFormato = [];

      var itemCommit = {
        idAgente: item.id,
        idFormato: this.formato.id
      };
      try {
        await api.post(process.env.VUE_APP_URL_WEB_API + "com/formato-agente?idSite=" + this.idSitePrincipal, itemCommit);
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
      this.agenteSelecionado = {};

      this.carregarAgentesFormato();
    },
    async desativarAgente(item) {
      if (item.id) {
        if (item.situacao) {
          this.agenteItem = item;
          this.tipoConfirm = "DESATIVAR_AGENTE";
          this.msgConfirm = "Tem certeza que deseja desativar este agente? (esta ação não pode ser desfeita)";
          this.dialogConfirm = true;
        }
      } else {
        this.agentesFormato.splice(item, 1);
      }
    },
    async desativaAgente() {
      try {
        this.agentesFormato = [];

        await api.put(process.env.VUE_APP_URL_WEB_API + "com/formato-agente/desativar/" + this.agenteItem.id);

        this.carregarAgentesFormato();
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },

    //* CONDIÇÕES ESPECIAIS
    async carregarCondicoesEspeciais() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "com/condicao-especial/lista");
        if (response.data) {
          this.condicoesEspeciais = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de condições especiais";
        this.snackErro = true;
      }
    },
    async carregarCondicoesEspeciaisFormato() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "com/formato-condicao-especial/formato/" + this.formato.id);
        if (response.data) {
          this.condicoesEspeciaisFormato = response.data;
          this.condicoesEspeciaisFormato.forEach(element => {
            element.isAcrescimo = element.valor > 0;
            element.valor = Math.abs(element.valor);
          });
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as condições especiais do formato";
        this.snackErro = true;
      }
    },
    async incluirCondicaoEspecial(item) {
      this.condicoesEspeciaisFormato = [];

      var itemCommit = {
        idCondicaoEspecial: item.idCondicaoEspecial,
        idFormato: this.formato.id,
        automatica: item.automatica,
        tipo: item.tipo,
        valor: item.valor * (item.isAcrescimo ? 1 : -1)
      };
      try {
        await api.post(process.env.VUE_APP_URL_WEB_API + "com/formato-condicao-especial?idSite=" + this.idSitePrincipal, itemCommit);
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }

      this.carregarCondicoesEspeciaisFormato();
    },
    desativarCondicaoEspecial(item) {
      if (item.id) {
        if (item.situacao) {
          this.condicaoEspecialItem = item;
          this.tipoConfirm = "DESATIVAR_CONDIÇÃO_ESPECIAL";
          this.msgConfirm = "Tem certeza que deseja desativar esta condição especial? (esta ação não pode ser desfeita)";
          this.dialogConfirm = true;
        }
      } else {
        this.condicoesEspeciaisFormato.splice(item, 1);
      }
    },
    async desativaCondicaoEspecial() {
      try {
        this.condicoesEspeciaisFormato = [];

        await api.put(process.env.VUE_APP_URL_WEB_API + "com/formato-condicao-especial/desativar/" + this.condicaoEspecialItem.id);

        this.carregarCondicoesEspeciaisFormato();
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },
    closeCondicaoEspecial() {
      this.dialogCondicaoEspecial = false;
      setTimeout(() => {
        this.condicaoEspecialItem = Object.assign({}, this.defaultCondicaoEspecialItem);
        this.condicaoEspecialIndex = -1;
        this.condicaoEspecialSelecionada = {};

        this.$refs.formCondicaoEspecial.resetValidation();
      }, 300);
    },
    saveCondicaoEspecial() {
      if (this.$refs.formCondicaoEspecial.validate()) {
        var incluido = false;
        this.condicaoEspecialItem.idCondicaoEspecial = this.condicaoEspecialSelecionada.id;
        this.condicoesEspeciaisFormato.forEach(element => {
          if (element.situacao) {
            if (this.condicaoEspecialItem.idCondicaoEspecial == element.idCondicaoEspecial) {
              incluido = true;
              this.mensagem = "Condição Especial já incluída";
              this.snackErro = true;
            }
          }
        });
        if (!incluido) {
          this.condicaoEspecialItem.nome = this.condicaoEspecialSelecionada.nome;
          if (this.formato.id) {
            this.incluirCondicaoEspecial(this.condicaoEspecialItem);
          } else {
            this.condicoesEspeciaisFormato.push(this.condicaoEspecialItem);
          }
          this.closeCondicaoEspecial();
          this.condicaoEspecialSelecionada = {};
          this.$refs.formCondicaoEspecial.resetValidation();
          this.validacao.condicoesEspeciais = false;
        }
      }
    },

    //* FORMAS DE PAGAMENTO
    async carregarFormasPagamento() {
      try {
        //* Busca as formas de pagamento a receber (pois não há o parâmetro pagar=1)
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/forma-pagamento/lista");
        if (response.data) {
          this.formasPagamento = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de formas de pagamento";
        this.snackErro = true;
      }
    },
    async carregarFormasPagamentoFormato() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "com/formato-forma-pagamento/formato/" + this.formato.id);
        if (response.data) {
          this.formasPagamentoFormato = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as formas de pagamento do formato";
        this.snackErro = true;
      }
    },
    async incluirFormaPagamento(item) {
      this.formasPagamentoFormato = [];

      var itemCommit = {
        idFormaPgto: item.idFormaPgto,
        idFormato: this.formato.id,
        maxParcelas: item.maxParcelas
      };
      try {
        await api.post(process.env.VUE_APP_URL_WEB_API + "com/formato-forma-pagamento?idSite=" + this.idSitePrincipal, itemCommit);
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }

      this.carregarFormasPagamentoFormato();
    },
    desativarFormaPagamento(item) {
      if (item.id) {
        if (item.situacao) {
          this.formaPagamentoItem = item;
          this.tipoConfirm = "DESATIVAR_FORMA_PAGAMENTO";
          this.msgConfirm = "Tem certeza que deseja desativar esta forma de pagamento? (esta ação não pode ser desfeita)";
          this.dialogConfirm = true;
        }
      } else {
        this.formasPagamentoFormato.splice(item, 1);
      }
    },
    async desativaFormaPagamento() {
      try {
        this.formasPagamentoFormato = [];

        await api.put(process.env.VUE_APP_URL_WEB_API + "com/formato-forma-pagamento/desativar/" + this.formaPagamentoItem.id);

        this.carregarFormasPagamentoFormato();
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },
    closeFormaPagamento() {
      this.dialogFormaPagamento = false;
      setTimeout(() => {
        this.formaPagamentoItem = Object.assign({}, this.defaultFormaPagamentoItem);
        this.formaPagamentoIndex = -1;
        this.formaPagamentoSelecionada = {};

        this.$refs.formFormaPagamento.resetValidation();
      }, 300);
    },
    saveFormaPagamento() {
      if (this.$refs.formFormaPagamento.validate()) {
        var incluido = false;
        this.formaPagamentoItem.idFormaPgto = this.formaPagamentoSelecionada.id;
        this.formasPagamentoFormato.forEach(element => {
          if (element.situacao) {
            if (this.formaPagamentoItem.idFormaPgto == element.idFormaPgto) {
              incluido = true;
              this.mensagem = "Forma de Pagamento já incluída";
              this.snackErro = true;
            }
          }
        });
        if (!incluido) {
          this.formaPagamentoItem.nome = this.formaPagamentoSelecionada.nome;
          this.formaPagamentoItem.formaPagamentoTipo = this.formaPagamentoSelecionada.tipo;
          if (this.formato.id) {
            this.incluirFormaPagamento(this.formaPagamentoItem);
          } else {
            this.formasPagamentoFormato.push(this.formaPagamentoItem);
          }
          setTimeout(() => {
            this.formaPagamentoItem = Object.assign({}, this.defaultFormaPagamentoItem);
            this.formaPagamentoIndex = -1;
            this.formaPagamentoSelecionada = {};

            this.$refs.formFormaPagamento.resetValidation();
            this.validacao.formasPagamento = false;
          }, 300);
        }
      }
    },

    //* REGRAS DE AJUSTE
    async carregarRegrasAjuste() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/regra-ajuste/lista?origem=ANU");
        if (response.data) {
          this.regrasAjuste = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de regras de ajuste";
        this.snackErro = true;
      }
    },
    async carregarRegrasAjusteFormato() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "com/formato-regra-ajuste/formato/" + this.formato.id);
        if (response.data) {
          this.regrasAjusteFormato = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as regras de ajuste do formato";
        this.snackErro = true;
      }
    },
    async incluirRegraAjuste(item) {
      this.regrasAjusteFormato = [];

      var itemCommit = {
        idRegraAjuste: item.idRegraAjuste,
        idFormato: this.formato.id,
        automatica: item.automatica
      };
      try {
        await api.post(process.env.VUE_APP_URL_WEB_API + "com/formato-regra-ajuste?idSite=" + this.idSitePrincipal, itemCommit);
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }

      this.carregarRegrasAjusteFormato();
    },
    desativarRegraAjuste(item) {
      if (item.id) {
        if (item.situacao) {
          this.regraAjusteItem = item;
          this.tipoConfirm = "DESATIVAR_REGRA_AJUSTE";
          this.msgConfirm = "Tem certeza que deseja desativar esta regra de ajuste? (esta ação não pode ser desfeita)";
          this.dialogConfirm = true;
        }
      } else {
        this.regrasAjusteFormato.splice(item, 1);
      }
    },
    async desativaRegraAjuste() {
      try {
        this.regrasAjusteFormato = [];

        await api.put(process.env.VUE_APP_URL_WEB_API + "com/formato-regra-ajuste/desativar/" + this.regraAjusteItem.id);

        this.carregarRegrasAjusteFormato();
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },
    closeRegraAjuste() {
      this.dialogRegraAjuste = false;
      setTimeout(() => {
        this.regraAjusteItem = Object.assign({}, this.defaultRegraAjusteItem);
        this.regraAjusteIndex = -1;
        this.regraAjusteSelecionada = {};

        this.$refs.formRegraAjuste.resetValidation();
      }, 300);
    },
    saveRegraAjuste() {
      if (this.$refs.formRegraAjuste.validate()) {
        var incluido = false;
        this.regraAjusteItem.idRegraAjuste = this.regraAjusteSelecionada.id;
        this.regrasAjusteFormato.forEach(element => {
          if (element.situacao) {
            if (this.regraAjusteItem.idRegraAjuste == element.idRegraAjuste) {
              incluido = true;
              this.mensagem = "Regra de Ajuste já incluída";
              this.snackErro = true;
            }
          }
        });
        if (!incluido) {
          this.regraAjusteItem.nome = this.regraAjusteSelecionada.nome;
          if (this.formato.id) {
            this.incluirRegraAjuste(this.regraAjusteItem);
          } else {
            this.regrasAjusteFormato.push(this.regraAjusteItem);
          }
          this.closeRegraAjuste();
          this.regraAjusteSelecionada = {};
          this.$refs.formRegraAjuste.resetValidation();
          this.validacao.regrasAjuste = false;
        }
      }
    },

    confirmado() {
      this.dialogConfirm = false;
      if (this.tipoConfirm == "DESATIVAR_CADERNO") {
        this.desativaCaderno();
      } else if (this.tipoConfirm == "DESATIVAR_AGENTE") {
        this.desativaAgente();
      } else if (this.tipoConfirm == "DESATIVAR_CONDIÇÃO_ESPECIAL") {
        this.desativaCondicaoEspecial();
      } else if (this.tipoConfirm == "DESATIVAR_FORMA_PAGAMENTO") {
        this.desativaFormaPagamento();
      } else if (this.tipoConfirm == "DESATIVAR_REGRA_AJUSTE") {
        this.desativaRegraAjuste();
      }
    },
    naoConfirmado() {
      this.dialogConfirm = false;
    },
    isFormaPgto1Parcela(formaPagamento) {
      var retorno = util.isFormaPgto1Parcela(formaPagamento);
      if (retorno) {
        this.formaPagamentoItem.maxParcelas = 1;
      }
      return retorno;
    },

    nomeDefault() {
      var item = this.tipos.find(tipo => tipo.id == this.formato.tipo);
      if (!this.formato.nome || !this.id) {
        //* Só define o nome padrão se for novo item ou o nome estiver vazio
        this.formato.nome = item.nome;
      }
      if (this.formato.tipo == "LS") {
        this.formato.comandosEstilos = this.estiloSimples;
      } else if (this.formato.tipo == "LSI") {
        this.formato.comandosEstilos = this.estiloSimples;
      } else if (this.formato.tipo == "LT") {
        this.formato.comandosEstilos = this.estiloTitulada;
      } else if (this.formato.tipo == "LTI") {
        this.formato.comandosEstilos = this.estiloTitulada;
      } else {
        this.formato.comandosEstilos = "";
      }
    },
    valida_altura(value) {
      value = value.toString();
      var retorno = true;
      if (
        value &&
        parseFloat(
          value
            .replace(".", "")
            .replace(" ", "")
            .replace(",", ".")
        ) > 32
      ) {
        retorno = "Verifique a altura padrão";
      }
      return retorno;
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    formatCurrency(valor) {
      return util.formatCurrency(valor);
    },
    formatDateTime(datetime) {
      return util.formatDateTime(datetime);
    },
    pLetra(text) {
      return util.pLetra(text);
    },
    valida_nome(text) {
      return util.valida_nome(text);
    }
  },

  async created() {
    this.isUpdating = false;
    this.bloqueiaBotoes = true;
    this.id = this.$route.params.id ? this.$route.params.id : "";

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_FORMATOS_CRIAR") {
        this.direitoCriar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_CADERNOS_CRIAR" || direito == "COMERCIAL_CADERNOS_EDITAR") {
        this.direitoCaderno = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ADMINISTRATIVO_AGENTES_CRIAR" || direito == "ADMINISTRATIVO_AGENTES_EDITAR") {
        this.direitoAgente = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ADMINISTRATIVO_FORMAS_PAGAMENTO_CRIAR" || direito == "ADMINISTRATIVO_FORMAS_PAGAMENTO_EDITAR") {
        this.direitoFormaPgto = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_REGRAS_AJUSTE_CRIAR" || direito == "COMERCIAL_REGRAS_AJUSTE_EDITAR") {
        this.direitoRegraAjuste = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_CONDIÇÕES_ESPECIAIS_CRIAR" || direito == "COMERCIAL_CONDIÇÕES_ESPECIAIS_EDITAR") {
        this.direitoCondicaoEspecial = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_FORMATOS_EDITAR_AVANÇADO") {
        this.direitoAvancado = true;
      }
    });

    //* Verifica se o site do usuário está em GRUPO - OBS: ou ele compartilha a informação ou deixa exclusiva para o seu site
    var idGrupoSite = this.$store.state.usuario.site_idGrupo;
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
    if (idGrupoSite != null) {
      this.siteGrupo = [
        {
          id: this.idSitePrincipal,
          nome: this.$store.state.usuario.site_nome
        }
      ];
    }

    await this.carregarCadernos();
    await this.carregarAgentes();
    await this.carregarCondicoesEspeciais();
    await this.carregarFormasPagamento();
    await this.carregarRegrasAjuste();

    //* Se é edição, carrega o item
    if (this.id == "") {
      this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "COMERCIAL_FORMATOS_CRIAR"]);
    } else {
      this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "COMERCIAL_FORMATOS_EDITAR"]);
      try {
        //* Carrega o item - somente se for público (idSite=null) ou for do site do usuário logado
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "com/formato/" + this.id + "?idSite=" + this.idSitePrincipal);
        if (!response.data) {
          this.$router.push("/comercial/formatos");
        } else {
          this.formato = response.data;
          await this.carregarCadernosFormato();
          await this.carregarAgentesFormato();
          await this.carregarCondicoesEspeciaisFormato();
          await this.carregarFormasPagamentoFormato();
          await this.carregarRegrasAjusteFormato();
        }
      } catch {
        this.mensagem = "Ocorreu um erro ao carregar os dados do formato";
        this.snackErro = true;
      }
    }
    setTimeout(() => {
      this.bloqueiaBotoes = false;
    }, 1000);
  },
  watch: {
    agenteSelecionado(val) {
      if (val && val.id) {
        var incluido = false;
        this.agentesFormato.forEach(element => {
          if (element.situacao) {
            if (val.id == element.idAgente) {
              incluido = true;
              this.mensagem = "Agente/Agência já incluído";
              this.agenteSelecionado = {};
              this.snackErro = true;
            }
          }
        });
        if (!incluido) {
          if (this.formato.id) {
            this.incluirAgente(val);
          } else {
            this.agentesFormato.push({
              id: null,
              idAgente: val.id,
              idFormato: this.formato.id,
              nome: val.nome,
              agenteSituacao: 1,
              situacao: 1
            });
            this.agenteSelecionado = {};
          }
          this.validacao.agentes = false;
        }
      }
    },
    condicaoEspecialSelecionada() {
      this.validacao.idCondicaoEspecial = false;
      this.validacao.automatica = false;
      this.validacao.tipo = false;
      this.validacao.valor = false;
    },
    formaPagamentoSelecionada(val) {
      this.validacao.formaPagamento = false;
      this.validacao.maxParcelas = false;
      this.formaPagamentoItem.formaPagamentoTipo = val.tipo;
    },
    regraAjusteSelecionada() {
      this.validacao.regraAjuste = false;
      this.validacao.automatica = false;
    },
    dialogCondicaoEspecial(val) {
      val || this.closeCondicaoEspecial();
    },
    dialogFormaPagamento(val) {
      val || this.closeFormaPagamento();
    },
    dialogRegraAjuste(val) {
      val || this.closeRegraAjuste();
    }
  }
};
</script>
