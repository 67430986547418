<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar height="50" color="#333333">
      <v-icon color="white" class="mr-2">perm_identity</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Contabilista</v-toolbar-title>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12 md12 v-if="siteGrupo.length > 0">
            <v-autocomplete
              v-model="contabilista.idSite"
              :disabled="isUpdating"
              :items="siteGrupo"
              :rules="[validacao.idSite == false || validacao.idSite]"
              :filter="filtrarObjetos"
              label="Restrito ao Site"
              item-text="nome"
              item-value="id"
              autocomplete="disabled"
              :name="Math.random()"
              no-data-text
              clearable
              @change="validacao.idSite = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md6>
            <v-text-field
              v-model="contabilista.identificacao"
              ref="focusIdentificacao"
              :label="'Identificação (' + ($store.state.usuario.site_pais == 'BR' ? (contabilista.identificacao.length == 14 ? 'CNPJ' : 'CPF') : 'NIF') + ')'"
              v-mask="'##############'"
              hint="Obrigatório"
              :rules="[validacao.identificacao == false || validacao.identificacao, value => !!value || 'Obrigatório.', value => valida_identificacao(value)]"
              :disabled="isUpdating || id != ''"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="isContabilistaExiste"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md6>
            <v-text-field
              v-model="contabilista.crc"
              ref="focusCRC"
              label="CRC"
              maxlength="15"
              hint="Obrigatório"
              :rules="[
                validacao.crc == false || validacao.crc,
                value => !!value || 'Obrigatório.',
                value => valida_nome(value) || 'CRC válido',
                value => value.length > 2 || 'Preencha a CRC'
              ]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="
                contabilista.crc = pLetra(contabilista.crc);
                validacao.crc = false;
              "
              autocomplete="disabled"
              :name="Math.random()"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md6>
            <v-text-field
              v-model="contabilista.nome"
              label="Nome"
              maxlength="100"
              hint="Obrigatório"
              :rules="[
                validacao.nome == false || validacao.nome,
                value => !!value || 'Obrigatório.',
                value => valida_nome(value) || 'Nome inválido',
                value => value.length > 2 || 'Preencha o campo contabilista'
              ]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="
                contabilista.nome = pLetra(contabilista.nome);
                validacao.nome = false;
              "
              autocomplete="disabled"
              :name="Math.random()"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md6>
            <v-text-field
              v-model="contabilista.email"
              label="E-mail"
              maxlength="100"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              :rules="[validacao.email == false || validacao.email, value => valida_email(value)]"
              @change="
                contabilista.email = contabilista.email.toLowerCase();
                validacao.email = false;
              "
              autocomplete="disabled"
              :name="Math.random()"
            ></v-text-field>
          </v-flex>

          <v-flex xs6 md6>
            <v-switch v-model="contabilista.situacao" :label="`${contabilista.situacao == 1 ? 'Ativo' : 'Inativo'}`"></v-switch>
          </v-flex>

          <v-flex xs12>
            <v-card>
              <v-toolbar>
                <v-toolbar-title>Telefones</v-toolbar-title>
              </v-toolbar>
              <v-container fluid grid-list-md>
                <v-layout wrap>
                  <v-flex xs4 v-if="$store.state.usuario.site_pais == 'BR'">
                    <v-text-field
                      v-model="contabilista.foneDDD1"
                      label="DDD"
                      v-mask="'##'"
                      :rules="[validacao.foneDDD1 == false || validacao.foneDDD1, value => isDDDvalido(value) || 'DDD inválido']"
                      :disabled="isUpdating"
                      color="blue-grey lighten-2"
                      @change="validacao.foneDDD1 = false"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs8>
                    <v-text-field
                      v-model="contabilista.foneNumero1"
                      label="Número"
                      v-mask="'###########'"
                      :rules="[validacao.foneNumero1 == false || validacao.foneNumero1, value => (value != null && value.length > 7) || 'Número inválido']"
                      :disabled="isUpdating"
                      color="blue-grey lighten-2"
                      @change="validacao.foneNumero1 = false"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs4 v-if="$store.state.usuario.site_pais == 'BR'">
                    <v-text-field
                      v-model="contabilista.foneDDD2"
                      label="DDD"
                      v-mask="'##'"
                      :rules="[validacao.foneDDD2 == false || validacao.foneDDD2, value => isDDDvalido(value) || 'DDD inválido']"
                      :disabled="isUpdating"
                      color="blue-grey lighten-2"
                      @change="validacao.foneDDD2 = false"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs8>
                    <v-text-field
                      v-model="contabilista.foneNumero2"
                      label="Número"
                      v-mask="'###########'"
                      :rules="[validacao.foneNumero2 == false || validacao.foneNumero2, value => (value != null && value.length > 7) || 'Número inválido']"
                      :disabled="isUpdating"
                      color="blue-grey lighten-2"
                      @change="validacao.foneNumero2 = false"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>
          </v-flex>

          <v-flex xs12>
            <v-card>
              <v-toolbar>
                <v-toolbar-title>Endereço</v-toolbar-title>
              </v-toolbar>
              <v-container fluid grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 md6 v-if="$store.state.usuario.site_pais == 'BR'">
                    <v-subheader>Informe o CEP e clique na lupa</v-subheader>
                    <v-text-field
                      v-model="contabilista.endCEP"
                      label="CEP"
                      v-mask="'#####-###'"
                      hint="Obrigatório"
                      :rules="[
                        validacao.endCEP == false || validacao.endCEP,
                        value => !!value || 'Obrigatório.',
                        value => (value != null && value.length == 9) || 'Deve conter 8 dígitos numéricos'
                      ]"
                      :disabled="isUpdating"
                      color="blue-grey lighten-2"
                      autocomplete="disabled"
                      :name="Math.random()"
                      append-icon="search"
                      @keyup="buscarCep"
                      @keyup.enter="buscarCep"
                      @click:append="buscarCep"
                      @change="validacao.endCEP = false"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 md6 v-if="$store.state.usuario.site_pais == 'PT'">
                    <v-text-field
                      v-model="contabilista.endCEP"
                      label="CEP"
                      v-mask="'####-###'"
                      hint="Obrigatório"
                      :rules="[
                        validacao.endCEP == false || validacao.endCEP,
                        value => !!value || 'Obrigatório.',
                        value => (value != null && value.length == 8) || 'Deve conter 7 dígitos numéricos'
                      ]"
                      :disabled="isUpdating"
                      color="blue-grey lighten-2"
                      autocomplete="disabled"
                      :name="Math.random()"
                      @change="validacao.endCEP = false"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 md6></v-flex>

                  <v-flex xs12 md6>
                    <v-autocomplete
                      v-model="idUF"
                      :disabled="isUpdating"
                      :items="ufs"
                      hint="Obrigatório"
                      :rules="[value => !!value || 'Obrigatório.']"
                      :filter="filtrarObjetos"
                      :label="'Selecione o ' + ($store.state.usuario.site_pais == 'BR' ? 'Estado' : 'Distrito')"
                      item-text="nome"
                      item-value="id"
                      autocomplete="disabled"
                      :name="Math.random()"
                      no-data-text
                      @change="filtrarMunicipios"
                    >
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12 md6>
                    <v-autocomplete
                      v-model="contabilista.idMunicipio"
                      :disabled="isUpdating"
                      :items="municipios"
                      hint="Obrigatório"
                      :rules="[validacao.idMunicipio == false || validacao.idMunicipio, value => !!value || 'Obrigatório.']"
                      :filter="filtrarObjetos"
                      :label="'Selecione o ' + ($store.state.usuario.site_pais == 'BR' ? 'Município' : 'Concelho')"
                      item-text="nome"
                      item-value="id"
                      no-data-text
                      @change="validacao.idMunicipio = false"
                      autocomplete="disabled"
                      :name="Math.random()"
                    ></v-autocomplete>
                  </v-flex>

                  <v-flex xs12 md6>
                    <v-text-field
                      v-model="contabilista.endBairro"
                      :label="$store.state.usuario.site_pais == 'BR' ? 'Bairro' : 'Freguesia/Localidade'"
                      maxlength="60"
                      hint="Obrigatório"
                      :rules="[
                        validacao.endBairro == false || validacao.endBairro,
                        value => !!value || 'Obrigatório.',
                        value => valida_nome(value) || ($store.state.usuario.site_pais == 'BR' ? 'Bairro inválido' : 'Freguesia/Localidade inválida'),
                        value => value.length > 1 || 'Preencha o nome ' + ($store.state.usuario.site_pais == 'BR' ? 'do bairro' : 'da freguesia/localidade')
                      ]"
                      :disabled="isUpdating"
                      color="blue-grey lighten-2"
                      @change="
                        contabilista.endBairro = pLetra(contabilista.endBairro);
                        validacao.endBairro = false;
                      "
                      autocomplete="disabled"
                      :name="Math.random()"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 md6>
                    <v-text-field
                      v-model="contabilista.endLogradouro"
                      label="Logradouro (Avenida, Rua, etc)"
                      maxlength="100"
                      hint="Obrigatório"
                      :rules="[
                        validacao.endLogradouro == false || validacao.endLogradouro,
                        value => !!value || 'Obrigatório.',
                        value => valida_nome(value) || 'Logradouro inválido',
                        value => value.length > 2 || 'Preencha o nome do logradouro'
                      ]"
                      :disabled="isUpdating"
                      color="blue-grey lighten-2"
                      @change="
                        contabilista.endLogradouro = pLetra(contabilista.endLogradouro);
                        validacao.endLogradouro = false;
                      "
                      autocomplete="disabled"
                      :name="Math.random()"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 md6>
                    <v-text-field
                      v-model="contabilista.endComplemento"
                      label="Complemento do Endereço"
                      maxlength="100"
                      :disabled="isUpdating"
                      :rules="[validacao.endComplemento == false || validacao.endComplemento, value => valida_nome(value) || 'Complemento inválido']"
                      color="blue-grey lighten-2"
                      autocomplete="disabled"
                      :name="Math.random()"
                      @change="
                        contabilista.endComplemento = pLetra(contabilista.endComplemento);
                        validacao.endComplemento = false;
                      "
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6>
                    <v-text-field
                      v-model="contabilista.endNumero"
                      label="Número"
                      v-mask="'#####'"
                      :rules="[validacao.endNumero == false || validacao.endNumero, value => parseInt(value) >= 0 || 'Número inválido']"
                      :disabled="isUpdating"
                      color="blue-grey lighten-2"
                      @change="validacao.endNumero = false"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>
          </v-flex>
          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">
              {{ validacao.mensagemErro }}
            </v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn to="/financeiro/contabilistas" color="orange lighten-2 white" id="btn-rsn" style="color:white">
        <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar
      </v-btn>
      <v-btn :loading="isUpdating" color="blue white" style="color:white" id="btn-rsn" depressed @click="salvar('false')"> <v-icon id="icon-rsn" left>update</v-icon>Salvar </v-btn>
      <v-btn :loading="isUpdating" color="orange white" style="color:white" id="btn-rsn" outlined @click="salvar('retornar')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>
      <v-btn :loading="isUpdating" color="blue white" style="color:white" id="btn-rsn" outlined @click="salvar('true')" v-if="direitoCriar">
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Novo
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import axios from "axios";
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      contabilista: {
        id: "",
        idSite: null,
        identificacao: "",
        crc: "",
        inscricaoEstadual: null,
        nome: "",
        foneDDD1: "",
        foneNumero1: "",
        foneDDD2: "",
        foneNumero2: "",
        idMunicipio: null,
        endCEP: "",
        endBairro: "",
        endLogradouro: "",
        endNumero: null,
        endComplemento: "",
        email: "",
        contato: "",
        situacao: 1
      },

      validacao: {
        id: false,
        idSite: false,
        identificacao: false,
        crc: false,
        inscricaoEstadual: false,
        nome: false,
        foneDDD1: false,
        foneNumero1: false,
        foneDDD2: false,
        foneNumero2: false,
        endCEP: false,
        idMunicipio: false,
        endBairro: false,
        endLogradouro: false,
        endNumero: false,
        endComplemento: false,
        email: false,
        contato: false,
        situacao: false,
        mensagemErro: false
      },

      idUF: null,

      comissoesConfig: [],

      siteGrupo: [],

      cep: null,
      ufs: [],
      municipios: [],
      ceps: [],

      idSitePrincipal: null,
      direitoCriar: false,
      elementoValid: true,
      id: "",
      erroData: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);

      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      this.isUpdating = true;
      try {
        var retorno = "";

        var contabilistaCommit = Object.assign({}, this.contabilista);
        contabilistaCommit.endCEP = util.somenteNumeros(contabilistaCommit.endCEP);

        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "fin/contabilista/" + this.id, contabilistaCommit);
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "fin/contabilista?idSite=" + this.idSitePrincipal, contabilistaCommit);
          this.id = retorno.data.id;
          this.contabilista.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "true") {
          this.$router.push("/financeiro/contabilista");
          this.$router.go("/financeiro/contabilista");
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push("/financeiro/contabilistas");
          }, 2000);
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },

    filtrarMunicipios() {
      this.carregarMunicipios(this.idUF);
    },
    carregarMunicipios() {
      //* Carrega os municípios da UF
      api
        .get(process.env.VUE_APP_URL_WEB_API + "adm/municipio/lista?idUF=" + this.idUF)
        .then(response => {
          this.municipios = response.data;
        })
        .catch(e => {
          this.mensagem = "Ocorreu um erro ao carregar a lista de " + (this.$store.state.usuario.site_pais == "BR" ? "municípios da UF" : "concelhos do distrito") + ": " + e;
          this.snackErro = true;
        });
    },
    buscarCep() {
      if (this.contabilista.endCEP != null && this.contabilista.endCEP.length == 9) {
        this.idUF = null;
        this.contabilista.idMunicipio = null;
        this.contabilista.endBairro = "";
        this.contabilista.endLogradouro = "";

        //* Busca o cep no webservice gratuito, tem que ser axios, senão dá erro crossorigin
        axios
          .get("https://viacep.com.br/ws/" + this.contabilista.endCEP + "/json/")
          .then(response => {
            var cep = response.data;
            if (cep.erro) {
              this.mensagem = "CEP não localizado";
              this.snackErro = true;
            } else {
              //* Busca o município pelo código IBGE
              api
                .get(process.env.VUE_APP_URL_WEB_API + "adm/municipio/ibge/" + cep.ibge)
                .then(response => {
                  var municipio = response.data[0];
                  if (municipio != null && municipio != undefined) {
                    this.idUF = municipio.idUF;
                    this.carregarMunicipios(municipio.idUF);
                    this.contabilista.idMunicipio = municipio.id;
                    this.contabilista.endBairro = cep.bairro;
                    this.contabilista.endLogradouro = cep.logradouro;
                  }
                })
                .catch(() => {
                  this.mensagem = "Ocorreu um erro ao carregar a lista de " + (this.$store.state.usuario.site_pais == "BR" ? "municípios da UF" : "concelhos do distrito");
                  this.snackErro = true;
                });
            }
          })
          .catch(() => {
            this.mensagem = "Ocorreu um erro ao buscar os dados do cep";
            this.snackErro = true;
          });
      }
    },

    isContabilistaExiste() {
      this.validacao.identificacao = false;
      //* Verifica se o contabilista existe
      api
        .get(process.env.VUE_APP_URL_WEB_API + "fin/contabilista/identificacao/" + this.contabilista.identificacao)
        .then(response => {
          if (response.data) {
            this.validacao.identificacao = "Identificação já consta na base";
          }
        })
        .catch(e => {
          this.mensagem = "Ocorreu um erro ao verificar a existência da identificação na base: " + e;
          this.snackErro = true;
        });
    },

    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    pLetra(text) {
      return util.pLetra(text);
    },
    valida_nome(text) {
      return util.valida_nome(text);
    },
    valida_identificacao(text) {
      if (this.$store.state.usuario.site_pais == "BR") {
        if (text.length != 11 && text.length != 14) {
          return "Identificação deve ter 11 ou 14 dígitos";
        } else {
          if (text.length == 11) {
            return util.valida_cpf(text) ? true : "CPF inválido";
          } else {
            return util.valida_cnpj(text) ? true : "CNPJ inválido";
          }
        }
      } else {
        if (text.length != 9) {
          return "Identificação deve ter 9 dígitos";
        } else {
          return util.valida_nif(text) ? true : "NIF inválido";
        }
      }
    },
    valida_email(text) {
      if (!util.valida_email(text)) {
        return "E-mail inválido.";
      } else {
        return true;
      }
    },
    isDDDvalido(ddd) {
      return util.isDDDvalido(ddd);
    }
  },

  async created() {
    this.id = this.$route.params.id ? this.$route.params.id : "";

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA") {
        this.direitoCriar = true;
      }
    });

    try {
      //* Carrega as UFs
      api
        .get(process.env.VUE_APP_URL_WEB_API + "adm/uf/lista")
        .then(response => {
          this.ufs = response.data;
          if (this.$store.state.usuario.site_pais != "BR") {
            this.ufs.forEach(element => {
              element.nome += "/" + element.pais;
            });
          }
        })
        .catch(() => {
          this.mensagem = "Ocorreu um erro ao carregar a lista de estados";
          this.snackErro = true;
        });

      //* Verifica se o site do usuário está em GRUPO - OBS: ou ele compartilha a informação ou deixa exclusiva para o seu site
      var idGrupoSite = this.$store.state.usuario.site_idGrupo;
      this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
      if (idGrupoSite != null) {
        this.siteGrupo = [
          {
            id: this.idSitePrincipal,
            nome: this.$store.state.usuario.site_nome
          }
        ];
      }

      //* Se é edição, carrega o contabilista
      if (this.id == "") {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA"]);
        setTimeout(() => {
          this.$refs.focusIdentificacao.focus();
        }, 300);
      } else {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA"]);
        //* Carrega o contabilista - somente se for público (idSite=null) ou for do site do usuário logado
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/contabilista/" + this.id + "?idSite=" + this.idSitePrincipal);

        if (!response.data) {
          this.$router.push("/financeiro/contabilistas");
          return;
        }
        this.contabilista = response.data;

        //* Define a UF do contabilista e carrega seus municípios
        if (this.contabilista.idMunicipio) {
          api
            .get(process.env.VUE_APP_URL_WEB_API + "adm/municipio/" + this.contabilista.idMunicipio)
            .then(response => {
              this.idUF = response.data.idUF;
              this.carregarMunicipios(this.idUF);
            })
            .catch(e => {
              this.mensagem = "Ocorreu um erro ao carregar " + (this.$store.state.usuario.site_pais == "BR" ? "a UF do município" : "o distrito do concelho") + ": " + e;
              this.snackErro = true;
            });
        }
        this.$refs.focusCRC.focus();
      }
    } catch {
      this.mensagem = "Ocorreu um erro ao carregar os dados";
      this.snackErro = true;
    }
  }
};
</script>
