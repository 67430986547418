<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">switch_account</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Regras de Comissionamento de Usuários</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12 md12 v-if="siteGrupo.length > 0">
            <v-autocomplete
              v-model="regraComissionamento.idSite"
              :disabled="isUpdating"
              :items="siteGrupo"
              :filter="filtrarObjetos"
              label="Restrito ao Site"
              item-text="nome"
              item-value="id"
              :rules="[validacao.idSite == false || validacao.idSite]"
              clearable
              no-data-text
              @change="validacao.idSite = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12>
            <h4 class="overline font-weight-black font-italic">
              Comissionamento RECUPERAÇÃO IMPRESSO
            </h4>
            <v-divider></v-divider>
          </v-flex>
          <v-flex xs6>
            <v-currency-field
              class="ml-4"
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="regraComissionamento.percRecuperacaoImpresso"
              label="Percentual"
              :allowNegative="false"
              :max="999"
              :rules="[value => !!value || 'Obrigatório.', value => (value && value.length < 6) || 'Percentual da Comissão IMPRESSO inválido']"
              color="blue-grey lighten-2"
              :disabled="isUpdating"
            ></v-currency-field>
          </v-flex>
          <v-flex xs6>
            <v-currency-field
              class="mr-4"
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="regraComissionamento.vlrRecuperacaoImpresso"
              label="Valor Fixo"
              :allowNegative="false"
              :max="999"
              :rules="[value => !!value || 'Obrigatório.', value => (value && value.length < 6) || 'Valor Fixo da Comissão IMPRESSO inválido']"
              color="blue-grey lighten-2"
              :disabled="isUpdating"
            ></v-currency-field>
          </v-flex>

          <v-flex xs12>
            <h4 class="overline font-weight-black font-italic">
              Comissionamento RECUPERAÇÃO ONLINE
            </h4>
            <v-divider></v-divider>
          </v-flex>
          <v-flex xs6>
            <v-currency-field
              class="ml-4"
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="regraComissionamento.percRecuperacaoOnline"
              label="Percentual"
              :allowNegative="false"
              :max="999"
              :rules="[value => !!value || 'Obrigatório.', value => (value && value.length < 6) || 'Percentual da Comissão ONLINE inválido']"
              color="blue-grey lighten-2"
              :disabled="isUpdating"
            ></v-currency-field>
          </v-flex>
          <v-flex xs6>
            <v-currency-field
              class="mr-4"
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="regraComissionamento.vlrRecuperacaoOnline"
              label="Valor Fixo"
              :allowNegative="false"
              :max="999"
              :rules="[value => !!value || 'Obrigatório.', value => (value && value.length < 6) || 'Valor Fixo da Comissão ONLINE inválido']"
              color="blue-grey lighten-2"
              :disabled="isUpdating"
            ></v-currency-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-switch v-model="regraComissionamento.recorrente" :label="`${regraComissionamento.recorrente == 1 ? 'Recorrente' : 'Não recorrente'}`"></v-switch>
          </v-flex>

          <v-flex xs6 md3>
            <v-switch v-model="regraComissionamento.situacao" :label="`${regraComissionamento.situacao == 1 ? 'Ativa' : 'Inativa'}`"></v-switch>
          </v-flex>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text"> {{ validacao.mensagemErro }} </v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn to="/assinaturas/desktop" id="btn-rsn" color="orange lighten-2 white" style="color:white"> <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar </v-btn>
      <v-btn v-if="direitoEditar" :loading="isUpdating" color="blue white" style="color:white" id="btn-rsn" depressed @click="salvar('false')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      regraComissionamento: {
        id: "",
        idSite: null,
        percRecuperacaoImpresso: 0,
        vlrRecuperacaoImpresso: 0,
        percRecuperacaoOnline: 0,
        vlrRecuperacaoOnline: 0,
        recorrente: 0,
        situacao: 1
      },
      validacao: {
        id: false,
        idSite: false,
        percRecuperacaoImpresso: false,
        vlrRecuperacaoImpresso: false,
        percRecuperacaoOnline: false,
        vlrRecuperacaoOnline: false,
        recorrente: false,
        situacao: false,
        mensagemErro: false
      },

      siteGrupo: [],

      idSitePrincipal: null,
      direitoEditar: false,
      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    async salvar() {
      await util.limparObjeto(this.validacao, false);

      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      this.isUpdating = true;
      try {
        var retorno = "";
        //se tiver id é um update
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "ass/regra-comissao-usuario/" + this.id, this.regraComissionamento);
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "ass/regra-comissao-usuario?idSite=" + this.idSitePrincipal, this.regraComissionamento);
          this.id = retorno.data.id;
          this.regraComissionamento.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },

    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    }
  },

  async created() {
    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_REGRAS_COMISSIONAMENTO_USUÁRIOS_EDITAR") {
        this.direitoEditar = true;
      }
    });

    //* Verifica se o site do usuário está em GRUPO - OBS: ou ele compartilha a informação ou deixa exclusiva para o seu site
    var idGrupoSite = this.$store.state.usuario.site_idGrupo;
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
    if (idGrupoSite != null) {
      this.siteGrupo = [
        {
          id: this.idSitePrincipal,
          nome: this.$store.state.usuario.site_nome
        }
      ];
    }
    try {
      this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_REGRAS_COMISSIONAMENTO_USUÁRIOS_ACESSO"]);
      //* Carrega o elemento - somente se for público (idSite=null) ou for do site do usuário logado
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/regra-comissao-usuario?idSite=" + this.idSitePrincipal);
      if (!response.data) {
        this.id = "";
      } else {
        this.id = response.data.id;
        this.regraComissionamento = response.data;
      }
    } catch {
      this.mensagem = "Ocorreu um erro ao carregar os dados";
      this.snackErro = true;
    }
  }
};
</script>
