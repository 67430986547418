<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar height="50" color="#333333">
      <v-icon color="white" class="mr-2">location_city</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">{{ $store.state.usuario.site_pais == "BR" ? "Município" : "Concelho" }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12>
            <v-text-field
              autofocus
              background-color="white"
              v-model="municipio.nome"
              :label="'Nome do ' + ($store.state.usuario.site_pais == 'BR' ? 'Município' : 'Concelho')"
              maxlength="32"
              hint="Obrigatório"
              :rules="[
                validacao.nome == false || validacao.nome,
                value => !!value || 'Obrigatório.',
                value => valida_nome(value) || 'Nome inválido',
                value => value.length > 2 || 'Preencha o nome do ' + ($store.state.usuario.site_pais == 'BR' ? 'município' : 'concelho')
              ]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="
                municipio.nome = pLetra(municipio.nome);
                validacao.nome = false;
              "
              autocomplete="disabled"
              :name="Math.random()"
            ></v-text-field>
          </v-flex>

          <v-flex xs12>
            <v-autocomplete
              v-model="municipio.idUF"
              :disabled="isUpdating"
              :filter="filtrarUFs"
              :items="ufs"
              hint="Obrigatório"
              :rules="[validacao.idUF == false || validacao.idUF, value => !!value || 'Obrigatório.']"
              :label="'Selecione o ' + ($store.state.usuario.site_pais == 'BR' ? 'Estado' : 'Distrito')"
              item-text="nome"
              item-value="id"
              no-data-text
              @change="validacao.idUF = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md6 v-if="$store.state.usuario.site_pais == 'BR'">
            <v-text-field
              background-color="white"
              v-model="municipio.codigoIBGE"
              label="Código IBGE"
              v-mask="'#######'"
              hint="7 dígitos numéricos"
              :rules="
                $store.state.usuario.site_pais == 'BR'
                  ? [validacao.codigoIBGE == false || validacao.codigoIBGE, value => !!value || 'Obrigatório.', value => value.length == 7 || 'Deve conter 7 dígitos numéricos']
                  : []
              "
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.codigoIBGE = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md6 v-if="$store.state.usuario.site_pais == 'BR'">
            <v-text-field
              background-color="white"
              v-model="municipio.codigoSIAFI"
              label="Código SIAFI"
              v-mask="'####'"
              hint="4 dígitos numéricos"
              :rules="
                $store.state.usuario.site_pais == 'BR'
                  ? [validacao.codigoSIAFI == false || validacao.codigoSIAFI, value => !!value || 'Obrigatório.', value => value.length == 4 || 'Deve conter 4 dígitos numéricos']
                  : []
              "
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.codigoSIAFI = false"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">{{ validacao.mensagemErro }}</v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn to="/administrativo/municipios" id="btn-rsn" color="orange lighten-2 white" style="color:white">
        <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar
      </v-btn>
      <v-btn :loading="isUpdating" color="blue white" style="color:white" id="btn-rsn" depressed @click="salvar('false')"> <v-icon id="icon-rsn" left>update</v-icon>Salvar </v-btn>
      <v-btn :loading="isUpdating" color="orange white" style="color:white" id="btn-rsn" outlined @click="salvar('retornar')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>
      <v-btn :loading="isUpdating" color="blue white" style="color:white" id="btn-rsn" outlined @click="salvar('true')" v-if="direitoCriar">
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Novo
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      this.isUpdating = true;
      try {
        var retorno = "";
        //se tiver id é um update
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "adm/municipio/" + this.id, this.municipio);
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "adm/municipio?idSite=" + this.idSitePrincipal, this.municipio);
          this.id = retorno.data.id;
          this.municipio.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "true") {
          this.$router.push("/administrativo/municipio");
          this.$router.go("/administrativo/municipio");
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push("/administrativo/municipios");
          }, 2000);
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },
    filtrarUFs(item, queryText, itemText) {
      return util.filtrarUFs(item, queryText, itemText);
    },
    pLetra(text) {
      return util.pLetra(text);
    },
    valida_nome(text) {
      return util.valida_nome(text);
    }
  },
  data() {
    return {
      municipio: {
        id: "",
        codigoIBGE: "",
        codigoSIAFI: "",
        idUF: null,
        nome: ""
      },
      validacao: {
        id: false,
        codigoIBGE: false,
        codigoSIAFI: false,
        idUF: false,
        nome: false,
        mensagemErro: false
      },
      ufs: [],

      idSitePrincipal: null,
      direitoCriar: false,
      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false
    };
  },
  async created() {
    this.id = this.$route.params.id ? this.$route.params.id : "";
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

    try {
      //* Verifica se o usuário logado possui direitos
      this.$store.state.usuario.direitos.forEach(element => {
        var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ADMINISTRATIVO_MUNICÍPIOS_CRIAR") {
          this.direitoCriar = true;
        }
      });

      const responseUfs = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/uf/lista");
      this.ufs = responseUfs.data;
      if (this.$store.state.usuario.site_pais != "BR") {
        this.ufs.forEach(element => {
          element.nome += "/" + element.pais;
        });
      }

      //* Se é edição, carrega o município
      if (this.id == "") {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ADMINISTRATIVO_MUNICÍPIOS_CRIAR"]);
      } else {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ADMINISTRATIVO_MUNICÍPIOS_EDITAR"]);
        //* Carrega o município
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/municipio/" + this.id);
        if (!response.data) {
          this.$router.push("/administrativo/municipios");
        } else {
          this.municipio = response.data;
        }
      }
    } catch {
      this.mensagem = "Ocorreu um erro ao carregar os dados";
      this.snackErro = true;
    }
  }
};
</script>
