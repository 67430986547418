<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-form ref="formElemento">
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-expansion-panels v-model="painel" focusable>
            <v-expansion-panel dark class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Dados Básicos</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider></v-divider>
                <v-container fluid grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 md2>
                      <v-autocomplete
                        v-model="filtrosSelecionados.online"
                        :items="itensSimNao"
                        :filter="filtrarObjetos"
                        label="Online"
                        item-text="nome"
                        item-value="id"
                        autocomplete="disabled"
                        :name="Math.random()"
                        no-data-text
                        clearable
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12 md2>
                      <v-text-field
                        v-model="filtrosSelecionados.idAnuncio"
                        label="ID"
                        v-mask="'######'"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        clearable
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md2 v-if="prefixo != ''">
                      <v-text-field
                        v-model="filtrosSelecionados.idPacote"
                        label="ID Pacote"
                        v-mask="'######'"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        clearable
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md3>
                      <v-text-field
                        v-model="filtrosSelecionados.idPacoteAnuncios"
                        label="ID Pacote (Anúncios)"
                        v-mask="'######'"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        clearable
                        hint="Anúncios do Pacote"
                        persistent-hint
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md3>
                      <v-autocomplete
                        v-model="filtrosSelecionados.status"
                        :items="itensStatus"
                        :filter="filtrarItens"
                        label="Status"
                        autocomplete="disabled"
                        :name="Math.random()"
                        no-data-text
                        multiple
                        clearable
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12>
                      <v-autocomplete
                        v-model="filtrosSelecionados.idUsuario"
                        :items="usuarios"
                        :filter="filtrarObjetos"
                        label="Usuário Captação"
                        item-text="nome"
                        item-value="id"
                        autocomplete="disabled"
                        :name="Math.random()"
                        no-data-text
                        clearable
                        multiple
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-menu v-model="mnuCaptacao" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="intervaloCaptacao"
                            label="Período Captação"
                            prepend-icon="event"
                            v-on="on"
                            color="blue-grey lighten-2"
                            clearable
                            @click:clear="intervaloCaptacaoUS = ['', '']"
                            readonly
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="intervaloCaptacaoUS" locale="pt-br" no-title range
                          ><v-spacer></v-spacer>
                          <v-btn text color="primary" @click="mnuCaptacao = false">
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-menu v-model="mnuCancelamento" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="intervaloCancelamento"
                            label="Período Cancelamento"
                            prepend-icon="event"
                            v-on="on"
                            color="blue-grey lighten-2"
                            clearable
                            @click:clear="intervaloCancelamentoUS = ['', '']"
                            readonly
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="intervaloCancelamentoUS" locale="pt-br" no-title range
                          ><v-spacer></v-spacer>
                          <v-btn text color="primary" @click="mnuCancelamento = false">
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-flex>

                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">
                        Valor Cobrado
                      </h4>
                      <v-divider></v-divider>
                      <v-layout wrap>
                        <v-flex xs6 md6>
                          <v-currency-field
                            :locale="`pt-${$store.state.usuario.site_pais}`"
                            v-model="filtrosSelecionados.vlrCobrado_min"
                            label="Mínimo"
                            :allowNegative="false"
                            :max="999999"
                            color="blue-grey lighten-2"
                          ></v-currency-field>
                        </v-flex>
                        <v-flex xs6 md6>
                          <v-currency-field
                            :locale="`pt-${$store.state.usuario.site_pais}`"
                            v-model="filtrosSelecionados.vlrCobrado_max"
                            label="Máximo"
                            :allowNegative="false"
                            :max="999999"
                            color="blue-grey lighten-2"
                          ></v-currency-field>
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">
                        Valor Taxado
                      </h4>
                      <v-divider></v-divider>
                      <v-layout wrap>
                        <v-flex xs6 md6>
                          <v-currency-field
                            :locale="`pt-${$store.state.usuario.site_pais}`"
                            v-model="filtrosSelecionados.vlrTaxado_min"
                            label="Mínimo"
                            :allowNegative="false"
                            :max="999999"
                            color="blue-grey lighten-2"
                          ></v-currency-field>
                        </v-flex>
                        <v-flex xs6 md6>
                          <v-currency-field
                            :locale="`pt-${$store.state.usuario.site_pais}`"
                            v-model="filtrosSelecionados.vlTaxado_max"
                            label="Máximo"
                            :allowNegative="false"
                            :max="999999"
                            color="blue-grey lighten-2"
                          ></v-currency-field>
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-text-field v-model="filtrosSelecionados.autorizacao" label="Autorização" maxlength="100" color="blue-grey lighten-2" clearable></v-text-field>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-autocomplete
                        v-model="filtrosSelecionados.idProduto"
                        :items="produtos"
                        :filter="filtrarObjetos"
                        label="Produto"
                        item-text="nome"
                        item-value="id"
                        autocomplete="disabled"
                        :name="Math.random()"
                        no-data-text
                        clearable
                        multiple
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-autocomplete
                        v-model="filtrosSelecionados.idCaderno"
                        :items="cadernos"
                        :filter="filtrarObjetos"
                        label="Caderno"
                        item-text="nome"
                        item-value="id"
                        autocomplete="disabled"
                        :name="Math.random()"
                        no-data-text
                        clearable
                        multiple
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-autocomplete
                        v-model="filtrosSelecionados.idFormato"
                        :items="formatos"
                        :filter="filtrarObjetos"
                        label="Formato"
                        item-text="nome"
                        item-value="id"
                        autocomplete="disabled"
                        :name="Math.random()"
                        no-data-text
                        clearable
                        multiple
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-autocomplete
                        v-model="filtrosSelecionados.idDeterminacao"
                        :items="determinacoes"
                        :filter="filtrarObjetos"
                        label="Determinação"
                        item-text="nome"
                        item-value="id"
                        autocomplete="disabled"
                        :name="Math.random()"
                        no-data-text
                        clearable
                        multiple
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-autocomplete
                        v-model="filtrosSelecionados.idColocacao"
                        :items="colocacoes"
                        :filter="filtrarObjetos"
                        label="Colocação"
                        item-text="nome"
                        item-value="id"
                        autocomplete="disabled"
                        :name="Math.random()"
                        no-data-text
                        clearable
                        multiple
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-autocomplete
                        v-model="filtrosSelecionados.idSecao"
                        :items="secoes"
                        :filter="filtrarObjetos"
                        label="Seção"
                        item-text="nome"
                        item-value="id"
                        autocomplete="disabled"
                        :name="Math.random()"
                        no-data-text
                        clearable
                        multiple
                      >
                        <template v-slot:selection="data">
                          <span class="ml-2">{{ data.item.nome }}</span>
                        </template>
                        <template v-slot:item="data">
                          <v-list-item-avatar size="12" :class="data.item.titulo ? 'grey' : 'blue'"> </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title v-html="data.item.nomeS"></v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-autocomplete
                        v-model="filtrosSelecionados.tipoCobranca"
                        :items="tiposCobranca"
                        :filter="filtrarObjetos"
                        label="Tipo de Cobrança"
                        item-text="nome"
                        item-value="id"
                        autocomplete="disabled"
                        :name="Math.random()"
                        no-data-text
                        clearable
                        multiple
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12 md3>
                      <v-autocomplete
                        v-model="filtrosSelecionados.cor"
                        :items="itensSimNao"
                        :filter="filtrarObjetos"
                        label="Colorido"
                        item-text="nome"
                        item-value="id"
                        autocomplete="disabled"
                        :name="Math.random()"
                        no-data-text
                        clearable
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12 md3>
                      <v-autocomplete
                        v-model="filtrosSelecionados.descComissao"
                        :items="itensSimNao"
                        :filter="filtrarObjetos"
                        label="Desconto Comissão"
                        item-text="nome"
                        item-value="id"
                        autocomplete="disabled"
                        :name="Math.random()"
                        no-data-text
                        clearable
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12 md3>
                      <v-autocomplete
                        v-model="filtrosSelecionados.emNegociacao"
                        :items="itensSimNao"
                        :filter="filtrarObjetos"
                        label="Em Negociação"
                        item-text="nome"
                        item-value="id"
                        autocomplete="disabled"
                        :name="Math.random()"
                        no-data-text
                        clearable
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12 md3>
                      <v-autocomplete
                        v-model="filtrosSelecionados.bloquearPublicacao"
                        :items="itensSimNao"
                        :filter="filtrarObjetos"
                        label="Bloqueio Publicação"
                        item-text="nome"
                        item-value="id"
                        autocomplete="disabled"
                        :name="Math.random()"
                        no-data-text
                        clearable
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12>
                      <v-text-field v-model="filtrosSelecionados.titulo" label="Título" maxlength="100" color="blue-grey lighten-2" clearable></v-text-field>
                    </v-flex>

                    <v-flex xs12>
                      <v-text-field v-model="filtrosSelecionados.texto" label="Texto" maxlength="100" color="blue-grey lighten-2" clearable></v-text-field>
                    </v-flex>

                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">
                        Colunas/Largura
                      </h4>
                      <v-divider></v-divider>
                      <v-layout wrap>
                        <v-flex xs6 md6>
                          <v-currency-field
                            :locale="`pt-${$store.state.usuario.site_pais}`"
                            v-model="filtrosSelecionados.colunas_min"
                            label="Mínimo"
                            :allowNegative="false"
                            :max="5000"
                            color="blue-grey lighten-2"
                          ></v-currency-field>
                        </v-flex>
                        <v-flex xs6 md6>
                          <v-currency-field
                            :locale="`pt-${$store.state.usuario.site_pais}`"
                            v-model="filtrosSelecionados.colunas_max"
                            label="Máximo"
                            :allowNegative="false"
                            :max="5000"
                            color="blue-grey lighten-2"
                          ></v-currency-field>
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">
                        Altura
                      </h4>
                      <v-divider></v-divider>
                      <v-layout wrap>
                        <v-flex xs6 md6>
                          <v-currency-field
                            :locale="`pt-${$store.state.usuario.site_pais}`"
                            v-model="filtrosSelecionados.altura_min"
                            label="Mínimo"
                            :allowNegative="false"
                            :max="5000"
                            color="blue-grey lighten-2"
                          ></v-currency-field>
                        </v-flex>
                        <v-flex xs6 md6>
                          <v-currency-field
                            :locale="`pt-${$store.state.usuario.site_pais}`"
                            v-model="filtrosSelecionados.altura_max"
                            label="Máximo"
                            :allowNegative="false"
                            :max="5000"
                            color="blue-grey lighten-2"
                          ></v-currency-field>
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">
                        Caracteres
                      </h4>
                      <v-divider></v-divider>
                      <v-layout wrap>
                        <v-flex xs6 md6>
                          <v-currency-field
                            :locale="`pt-${$store.state.usuario.site_pais}`"
                            v-model="filtrosSelecionados.caracteres_min"
                            label="Mínimo"
                            :allowNegative="false"
                            :max="9999999"
                            color="blue-grey lighten-2"
                          ></v-currency-field>
                        </v-flex>
                        <v-flex xs6 md6>
                          <v-currency-field
                            :locale="`pt-${$store.state.usuario.site_pais}`"
                            v-model="filtrosSelecionados.caracteres_max"
                            label="Máximo"
                            :allowNegative="false"
                            :max="9999999"
                            color="blue-grey lighten-2"
                          ></v-currency-field>
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">
                        Palavras
                      </h4>
                      <v-divider></v-divider>
                      <v-layout wrap>
                        <v-flex xs6 md6>
                          <v-currency-field
                            :locale="`pt-${$store.state.usuario.site_pais}`"
                            v-model="filtrosSelecionados.palavras_min"
                            label="Mínimo"
                            :allowNegative="false"
                            :max="9999999"
                            color="blue-grey lighten-2"
                          ></v-currency-field>
                        </v-flex>
                        <v-flex xs6 md6>
                          <v-currency-field
                            :locale="`pt-${$store.state.usuario.site_pais}`"
                            v-model="filtrosSelecionados.palavras_max"
                            label="Máximo"
                            :allowNegative="false"
                            :max="9999999"
                            color="blue-grey lighten-2"
                          ></v-currency-field>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark elevation-0>
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Publicação</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider></v-divider>
                <v-container fluid grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 md6>
                      <v-menu v-model="mnuPublicacao" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="intervaloPublicacao"
                            label="Período Publicação"
                            prepend-icon="event"
                            v-on="on"
                            color="blue-grey lighten-2"
                            clearable
                            @click:clear="intervaloPublicacaoUS = ['', '']"
                            readonly
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="intervaloPublicacaoUS" locale="pt-br" no-title range
                          ><v-spacer></v-spacer>
                          <v-btn text color="primary" @click="mnuPublicacao = false">
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-autocomplete
                        v-model="filtrosSelecionados.publicacaoStatus"
                        :items="itensStatusPublicacao"
                        :filter="filtrarObjetos"
                        label="Status"
                        item-text="nome"
                        item-value="id"
                        autocomplete="disabled"
                        :name="Math.random()"
                        no-data-text
                        clearable
                        multiple
                      ></v-autocomplete>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark elevation-0>
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Condição Especial</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider></v-divider>
                <v-container fluid grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 md12>
                      <v-autocomplete
                        v-model="filtrosSelecionados.idCondicaoEspecial"
                        :items="condicoesEspeciais"
                        :filter="filtrarObjetos"
                        label="Condição"
                        item-text="nome"
                        item-value="id"
                        autocomplete="disabled"
                        :name="Math.random()"
                        no-data-text
                        clearable
                        multiple
                      ></v-autocomplete>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark elevation-0>
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Agente/Agência</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider></v-divider>
                <v-container fluid grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 md12>
                      <v-autocomplete
                        v-model="filtrosSelecionados.idAgente"
                        :items="agentes"
                        :filter="filtrarObjetos"
                        label="Agente/Agência"
                        item-text="nome"
                        item-value="id"
                        autocomplete="disabled"
                        :name="Math.random()"
                        no-data-text
                        clearable
                        multiple
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">
                        Comissão
                      </h4>
                      <v-divider></v-divider>
                      <v-layout wrap>
                        <v-flex xs6 md6>
                          <v-currency-field
                            :locale="`pt-${$store.state.usuario.site_pais}`"
                            v-model="filtrosSelecionados.comissao_min"
                            label="Mínimo"
                            :allowNegative="false"
                            :max="9999999"
                            color="blue-grey lighten-2"
                          ></v-currency-field>
                        </v-flex>
                        <v-flex xs6 md6>
                          <v-currency-field
                            :locale="`pt-${$store.state.usuario.site_pais}`"
                            v-model="filtrosSelecionados.comissao_max"
                            label="Máximo"
                            :allowNegative="false"
                            :max="9999999"
                            color="blue-grey lighten-2"
                          ></v-currency-field>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark elevation-0>
              <v-expansion-panel-header>
                <strong>Observação</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider></v-divider>
                <v-flex xs12 md12>
                  <v-container fluid grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12>
                        <v-autocomplete
                          v-model="filtrosSelecionados.idUsuarioObs"
                          :items="usuarios"
                          :filter="filtrarObjetos"
                          label="Usuário"
                          item-text="nome"
                          item-value="id"
                          autocomplete="disabled"
                          :name="Math.random()"
                          no-data-text
                          clearable
                          multiple
                        ></v-autocomplete>
                      </v-flex>
                      <v-flex xs12>
                        <v-text-field v-model="filtrosSelecionados.observacao" label="Observação" maxlength="100" color="blue-grey lighten-2" clearable></v-text-field>
                      </v-flex>

                      <v-flex xs12 md6>
                        <v-menu v-model="mnuObservacao" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="intervaloObservacao"
                              label="Período Registro"
                              prepend-icon="event"
                              v-on="on"
                              color="blue-grey lighten-2"
                              clearable
                              @click:clear="intervaloObservacaoUS = ['', '']"
                              readonly
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="intervaloObservacaoUS" locale="pt-br" no-title range
                            ><v-spacer></v-spacer>
                            <v-btn text color="primary" @click="mnuObservacao = false">
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
export default {
  props: { prefixo: { type: String, default: "" } },
  data() {
    return {
      filtrosSelecionados: {
        online: null,
        idAnuncio: null,
        idPacote: null,
        idPacoteAnuncios: null,
        status: null,
        idUsuario: null,
        dtCaptacaoInicio: null,
        dtCaptacaoFim: null,
        dtCancelamentoInicio: null,
        dtCancelamentoFim: null,
        vlrCobrado_min: 0,
        vlrCobrado_max: 0,
        vlrTaxado_min: 0,
        vlrTaxado_max: 0,
        autorizacao: null,
        idProduto: null,
        idCaderno: null,
        idFormato: null,
        idDeterminacao: null,
        idColocacao: null,
        idSecao: null,
        tipoCobranca: null,
        cor: null,
        descComissao: null,
        emNegociacao: null,
        bloquearPublicacao: null,
        titulo: null,
        texto: null,
        colunas_min: 0,
        colunas_max: 0,
        altura_min: 0,
        altura_max: 0,
        caracteres_min: 0,
        caracteres_max: 0,
        palavras_min: 0,
        palavras_max: 0,

        dtPublicacaoInicio: null,
        dtPublicacaoFim: null,
        publicacaoStatus: null,

        idCondicaoEspecial: null,

        idAgente: null,
        comissao_min: 0,
        comissao_max: 0,

        idUsuarioObs: null,
        dtObsInicio: null,
        dtObsFim: null,
        observacao: ""
      },
      anuncioDefault: {
        online: null,
        id: null,
        idPacote: null,
        idPacoteAnuncios: null,
        status: null,
        idUsuario: null,
        dtCaptacaoInicio: null,
        dtCaptacaoFim: null,
        dtCancelamentoInicio: null,
        dtCancelamentoFim: null,
        vlrCobrado_min: 0,
        vlrCobrado_max: 0,
        vlrTaxado_min: 0,
        vlrTaxado_max: 0,
        autorizacao: null,
        idProduto: null,
        idCaderno: null,
        idFormato: null,
        idDeterminacao: null,
        idColocacao: null,
        idSecao: null,
        tipoCobranca: null,
        cor: null,
        descComissao: null,
        emNegociacao: null,
        bloquearPublicacao: null,
        titulo: null,
        texto: null,
        colunas_min: 0,
        colunas_max: 0,
        altura_min: 0,
        altura_max: 0,
        caracteres_min: 0,
        caracteres_max: 0,
        palavras_min: 0,
        palavras_max: 0,

        dtPublicacaoInicio: null,
        dtPublicacaoFim: null,
        publicacaoStatus: null,

        idCondicaoEspecial: null,

        idAgente: null,
        comissao_min: 0,
        comissao_max: 0,

        idUsuarioObs: null,
        dtObsInicio: null,
        dtObsFim: null,
        observacao: ""
      },

      mnuCaptacao: false,
      intervaloCaptacaoUS: ["", ""],
      mnuCancelamento: false,
      intervaloCancelamentoUS: ["", ""],
      mnuPublicacao: false,
      intervaloPublicacaoUS: ["", ""],
      mnuObservacao: false,
      intervaloObservacaoUS: ["", ""],

      idSitePrincipal: null,

      usuarios: [],
      produtos: [],
      cadernos: [],
      formatos: [],
      determinacoes: [],
      colocacoes: [],
      secoes: [],
      tiposCobranca: [
        { id: 1, nome: "Centímetro x Coluna" },
        { id: 2, nome: "Preço Fixo" },
        { id: 3, nome: "Largura x Altura" },
        { id: 4, nome: "Número de Palavras" },
        { id: 5, nome: "Número de Caracteres" }
      ],
      condicoesEspeciais: [],
      agentes: [],

      itensSimNao: [
        { id: 1, nome: "SIM" },
        { id: 0, nome: "NÃO" }
      ],
      itensStatus: ["CORRENTE", "CANCELADO", "EXPIRADO"],
      itensStatusPublicacao: [
        { id: 0, nome: "Não publicado" },
        { id: 1, nome: "Excluido Diagrama" },
        { id: 2, nome: "Alterado Comercial" },
        { id: 3, nome: "Publicado" },
        { id: 4, nome: "Cancelado comercial" }
      ],

      painel: [0, 0, 0, 0, 0],

      autoUpdate: true
    };
  },

  methods: {
    filtroUrlParametros() {
      var filtro = "";

      //* Verifica os campos que foram preenchidos
      if (this.filtrosSelecionados.online != null) {
        filtro += "&" + this.prefixo + "online=" + this.filtrosSelecionados.online;
      }

      if (this.filtrosSelecionados.idAnuncio) {
        filtro += "&idAnuncio=" + this.filtrosSelecionados.idAnuncio;
      }

      if (this.filtrosSelecionados.idPacote) {
        filtro += "&" + this.prefixo + "idPacote=" + this.filtrosSelecionados.idPacote;
      }

      if (this.filtrosSelecionados.idPacoteAnuncios) {
        filtro += "&" + this.prefixo + "idPacoteAnuncios=" + this.filtrosSelecionados.idPacoteAnuncios;
      }

      if (this.filtrosSelecionados.status && this.filtrosSelecionados.status.length) {
        filtro += "&" + this.prefixo + "status=" + this.filtrosSelecionados.status.join(",");
      }

      if (this.filtrosSelecionados.idUsuario && this.filtrosSelecionados.idUsuario.length) {
        filtro += "&" + this.prefixo + "idUsuario=" + this.filtrosSelecionados.idUsuario.join(",");
      }

      if (this.intervaloCaptacaoUS && this.intervaloCaptacaoUS.length > 1 && this.intervaloCaptacaoUS[0] && this.intervaloCaptacaoUS[1]) {
        filtro += "&" + this.prefixo + "dtCaptacaoInicio=" + this.intervaloCaptacaoUS[0];
        filtro += "&" + this.prefixo + "dtCaptacaoFim=" + this.intervaloCaptacaoUS[1];
      }

      if (this.intervaloCancelamentoUS && this.intervaloCancelamentoUS.length > 1 && this.intervaloCancelamentoUS[0] && this.intervaloCancelamentoUS[1]) {
        filtro += "&" + this.prefixo + "dtCancelamentoInicio=" + this.intervaloCancelamentoUS[0];
        filtro += "&" + this.prefixo + "dtCancelamentoFim=" + this.intervaloCancelamentoUS[1];
      }

      if (this.filtrosSelecionados.vlrCobrado_min) {
        filtro += "&" + this.prefixo + "vlrCobrado_min=" + this.filtrosSelecionados.vlrCobrado_min;
      }
      if (this.filtrosSelecionados.vlrCobrado_max) {
        filtro += "&" + this.prefixo + "vlrCobrado_max=" + this.filtrosSelecionados.vlrCobrado_max;
      }

      if (this.filtrosSelecionados.vlrTaxado_min) {
        filtro += "&" + this.prefixo + "vlrTaxado_min=" + this.filtrosSelecionados.vlrTaxado_min;
      }
      if (this.filtrosSelecionados.vlrTaxado_max) {
        filtro += "&" + this.prefixo + "vlrTaxado_max=" + this.filtrosSelecionados.vlrTaxado_max;
      }

      if (this.filtrosSelecionados.autorizacao) {
        filtro += "&" + this.prefixo + "autorizacao=" + this.filtrosSelecionados.autorizacao;
      }

      if (this.filtrosSelecionados.idProduto && this.filtrosSelecionados.idProduto.length) {
        filtro += "&" + this.prefixo + "idProduto=" + this.filtrosSelecionados.idProduto.join(",");
      }

      if (this.filtrosSelecionados.idCaderno && this.filtrosSelecionados.idCaderno.length) {
        filtro += "&" + this.prefixo + "idCaderno=" + this.filtrosSelecionados.idCaderno.join(",");
      }

      if (this.filtrosSelecionados.idFormato && this.filtrosSelecionados.idFormato.length) {
        filtro += "&" + this.prefixo + "idFormato=" + this.filtrosSelecionados.idFormato.join(",");
      }

      if (this.filtrosSelecionados.idDeterminacao && this.filtrosSelecionados.idDeterminacao.length) {
        filtro += "&" + this.prefixo + "idDeterminacao=" + this.filtrosSelecionados.idDeterminacao.join(",");
      }

      if (this.filtrosSelecionados.idColocacao && this.filtrosSelecionados.idColocacao.length) {
        filtro += "&" + this.prefixo + "idColocacao=" + this.filtrosSelecionados.idColocacao.join(",");
      }

      if (this.filtrosSelecionados.idSecao && this.filtrosSelecionados.idSecao.length) {
        filtro += "&" + this.prefixo + "idSecao=" + this.filtrosSelecionados.idSecao.join(",");
      }

      if (this.filtrosSelecionados.tipoCobranca && this.filtrosSelecionados.tipoCobranca.length) {
        filtro += "&" + this.prefixo + "tipoCobranca=" + this.filtrosSelecionados.tipoCobranca.join(",");
      }

      if (this.filtrosSelecionados.cor != null) {
        filtro += "&" + this.prefixo + "cor=" + this.filtrosSelecionados.cor;
      }

      if (this.filtrosSelecionados.descComissao != null) {
        filtro += "&" + this.prefixo + "descComissao=" + this.filtrosSelecionados.descComissao;
      }

      if (this.filtrosSelecionados.emNegociacao != null) {
        filtro += "&" + this.prefixo + "emNegociacao=" + this.filtrosSelecionados.emNegociacao;
      }

      if (this.filtrosSelecionados.bloquearPublicacao != null) {
        filtro += "&" + this.prefixo + "bloquearPublicacao=" + this.filtrosSelecionados.bloquearPublicacao;
      }

      if (this.filtrosSelecionados.titulo) {
        filtro += "&" + this.prefixo + "titulo=" + this.filtrosSelecionados.titulo;
      }

      if (this.filtrosSelecionados.texto) {
        filtro += "&" + this.prefixo + "texto=" + this.filtrosSelecionados.texto;
      }

      if (this.filtrosSelecionados.colunas_min) {
        filtro += "&" + this.prefixo + "colunas_min=" + this.filtrosSelecionados.colunas_min;
      }

      if (this.filtrosSelecionados.colunas_max) {
        filtro += "&" + this.prefixo + "colunas_max=" + this.filtrosSelecionados.colunas_max;
      }

      if (this.filtrosSelecionados.altura_min) {
        filtro += "&" + this.prefixo + "altura_min=" + this.filtrosSelecionados.altura_min;
      }

      if (this.filtrosSelecionados.altura_max) {
        filtro += "&" + this.prefixo + "altura_max=" + this.filtrosSelecionados.altura_max;
      }

      if (this.filtrosSelecionados.caracteres_min) {
        filtro += "&" + this.prefixo + "caracteres_min=" + this.filtrosSelecionados.caracteres_min;
      }

      if (this.filtrosSelecionados.caracteres_max) {
        filtro += "&" + this.prefixo + "caracteres_max=" + this.filtrosSelecionados.caracteres_max;
      }

      if (this.filtrosSelecionados.palavras_min) {
        filtro += "&" + this.prefixo + "palavras_min=" + this.filtrosSelecionados.palavras_min;
      }

      if (this.filtrosSelecionados.palavras_max) {
        filtro += "&" + this.prefixo + "palavras_max=" + this.filtrosSelecionados.palavras_max;
      }

      if (this.intervaloPublicacaoUS && this.intervaloPublicacaoUS.length > 1 && this.intervaloPublicacaoUS[0] && this.intervaloPublicacaoUS[1]) {
        filtro += "&" + this.prefixo + "dtPublicacaoInicio=" + this.intervaloPublicacaoUS[0];
        filtro += "&" + this.prefixo + "dtPublicacaoFim=" + this.intervaloPublicacaoUS[1];
      }
      if (this.filtrosSelecionados.publicacaoStatus != null && this.filtrosSelecionados.publicacaoStatus.length) {
        filtro += "&" + this.prefixo + "publicacaoStatus=" + this.filtrosSelecionados.publicacaoStatus.join(",");
      }

      if (this.filtrosSelecionados.idCondicaoEspecial && this.filtrosSelecionados.idCondicaoEspecial.length) {
        filtro += "&" + this.prefixo + "idCondicaoEspecial=" + this.filtrosSelecionados.idCondicaoEspecial.join(",");
      }

      if (this.filtrosSelecionados.idAgente && this.filtrosSelecionados.idAgente.length) {
        filtro += "&" + this.prefixo + "idAgente=" + this.filtrosSelecionados.idAgente.join(",");
      }

      if (this.filtrosSelecionados.comissao_min) {
        filtro += "&" + this.prefixo + "comissao_min=" + this.filtrosSelecionados.comissao_min;
      }

      if (this.filtrosSelecionados.comissao_max) {
        filtro += "&" + this.prefixo + "comissao_max=" + this.filtrosSelecionados.comissao_max;
      }

      if (this.filtrosSelecionados.idUsuarioObs && this.filtrosSelecionados.idUsuarioObs.length) {
        filtro += "&" + this.prefixo + "idUsuarioObs=" + this.filtrosSelecionados.idUsuarioObs.join(",");
      }

      if (this.intervaloObservacaoUS && this.intervaloObservacaoUS.length > 1 && this.intervaloObservacaoUS[0] && this.intervaloObservacaoUS[1]) {
        filtro += "&" + this.prefixo + "dtObsInicio=" + this.intervaloObservacaoUS[0];
        filtro += "&" + this.prefixo + "dtObsFim=" + this.intervaloObservacaoUS[1];
      }

      if (this.filtrosSelecionados.observacao) {
        filtro += "&" + this.prefixo + "observacao=" + this.filtrosSelecionados.observacao;
      }

      if (filtro) {
        filtro = filtro + "&" + this.prefixo + "idSite=" + this.idSitePrincipal;
      }

      return filtro;
    },

    carregarUsuarios() {
      api.get(process.env.VUE_APP_URL_WEB_API + "adm/site-usuario/usuariosSite/" + this.idSitePrincipal).then(response => {
        this.usuarios = response.data;
      });
    },
    carregarProdutos() {
      api.get(process.env.VUE_APP_URL_WEB_API + "com/produto/all").then(response => {
        this.produtos = response.data;
      });
    },
    carregarCadernos() {
      api.get(process.env.VUE_APP_URL_WEB_API + "com/caderno/all").then(response => {
        this.cadernos = response.data;
      });
    },
    carregarFormatos() {
      api.get(process.env.VUE_APP_URL_WEB_API + "com/formato/all").then(response => {
        this.formatos = response.data;
      });
    },
    carregarDeterminacoes() {
      api.get(process.env.VUE_APP_URL_WEB_API + "com/determinacao/all").then(response => {
        this.determinacoes = response.data;
      });
    },
    carregarColocacoes() {
      api.get(process.env.VUE_APP_URL_WEB_API + "com/colocacao/all").then(response => {
        this.colocacoes = response.data;
      });
    },
    carregarSecoes() {
      api.get(process.env.VUE_APP_URL_WEB_API + "com/secao/all").then(response => {
        this.secoes = response.data;
        this.secoes.forEach(element => {
          element.secao = "";
          if (element.numero.includes(".")) {
            element.secao = "&emsp;".repeat(element.numero.split(".").length - 1);
          }
          element.nomeS = element.secao + element.nome; //element.numero + " - " +
          element.nome =
            // element.numero +
            // " - " +
            (element.nome2 ? element.nome2 + "/" : "") + (element.nome1 ? element.nome1 + "/" : "") + element.nome;
        });
      });
    },
    carregarCondicoesEspeciais() {
      api.get(process.env.VUE_APP_URL_WEB_API + "com/condicao-especial/all").then(response => {
        this.condicoesEspeciais = response.data;
      });
    },
    carregarAgentes() {
      api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-agente/all?idSite=" + this.idSitePrincipal).then(response => {
        this.agentes = response.data;
      });
    },
    limpar() {
      this.filtrosSelecionados = JSON.parse(JSON.stringify(this.anuncioDefault));
      this.painel = [0, 0, 0, 0, 0];
      this.intervaloCaptacaoUS = ["", ""];
      this.intervaloCancelamentoUS = ["", ""];
      this.intervaloPublicacaoUS = ["", ""];
      this.intervaloObservacaoUS = ["", ""];
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    filtrarItens(item, queryText, itemText) {
      return util.filtrarItens(item, queryText, itemText);
    },
    formatDate(date) {
      return util.formatDate(date);
    },
    formatDateTime(datetime) {
      return util.formatDateTime(datetime);
    }
  },

  computed: {
    intervaloCaptacao() {
      var retorno = "";
      if (this.intervaloCaptacaoUS && this.intervaloCaptacaoUS.length > 1) {
        this.intervaloCaptacaoUS.forEach(element => {
          if (element) {
            retorno += (retorno ? " - " : "") + util.formatDate(element);
          }
        });
      }
      return retorno;
    },
    intervaloCancelamento() {
      var retorno = "";
      if (this.intervaloCancelamentoUS && this.intervaloCancelamentoUS.length > 1) {
        this.intervaloCancelamentoUS.forEach(element => {
          if (element) {
            retorno += (retorno ? " - " : "") + util.formatDate(element);
          }
        });
      }
      return retorno;
    },
    intervaloPublicacao() {
      var retorno = "";
      if (this.intervaloPublicacaoUS && this.intervaloPublicacaoUS.length > 1) {
        this.intervaloPublicacaoUS.forEach(element => {
          if (element) {
            retorno += (retorno ? " - " : "") + util.formatDate(element);
          }
        });
      }
      return retorno;
    },
    intervaloObservacao() {
      var retorno = "";
      if (this.intervaloObservacaoUS && this.intervaloObservacaoUS.length > 1) {
        this.intervaloObservacaoUS.forEach(element => {
          if (element) {
            retorno += (retorno ? " - " : "") + util.formatDate(element);
          }
        });
      }
      return retorno;
    }
  },

  async created() {
    try {
      this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

      //* Carrega os itens dos selects
      this.carregarUsuarios();
      this.carregarProdutos();
      this.carregarCadernos();
      this.carregarFormatos();
      this.carregarDeterminacoes();
      this.carregarColocacoes();
      this.carregarSecoes();
      this.carregarCondicoesEspeciais();
      this.carregarAgentes();
    } catch {
      // this.mensagem = "Ocorreu um erro ao carregar os dados";
      // this.snackErro = true;
    }
  }
};
</script>
