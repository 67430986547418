<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">add_circle_outline</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">
        Parcela
        {{
          idParcela +
            " - " +
            (parcela.idAssinatura ? parcela.indice + "-" : "") +
            parcela.numero +
            (parcela.idAssinatura ? " - Assinatura " + parcela.idAssinatura : "") +
            (parcela.idAnuncio ? " - Anúncio " + parcela.idAnuncio : "")
        }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-btn icon @click="retornar(false)">
        <v-icon color="white">cancel</v-icon>
      </v-btn>
    </v-toolbar>
    <div v-if="!bloqueado">
      <v-card>
        <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
          <v-card-text>
            <v-container fluid grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    v-model="parcela.cliente"
                    :label="rotaReceber ? 'Cliente' : 'Fornecedor'"
                    readonly
                    color="blue-grey lighten-2"
                    append-icon="account_circle"
                    @click:append="editarCliente"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md6>
                  <v-autocomplete v-model="parcela.status" :items="status" :filter="filtrarItens" readonly label="Status"></v-autocomplete>
                </v-flex>
                <v-flex xs12 md3>
                  <v-autocomplete
                    v-model="parcela.statusCobranca"
                    :items="statusCobranca"
                    readonly
                    :filter="filtrarObjetos"
                    label="Cobrança"
                    item-text="nome"
                    item-value="id"
                    no-data-text
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12 md3>
                  <v-text-field
                    v-model="nroNF"
                    :label="lblNF"
                    v-mask="'########'"
                    :rules="[validacao.nroNF == false || validacao.nroNF]"
                    color="blue-grey lighten-2"
                    :readonly="rotaReceber || (!rotaReceber && parcela.confirmada == 1 && $store.state.usuario.site_confirmaOpPagar == 1)"
                    autocomplete="disabled"
                    :name="Math.random()"
                    @change="validacao.nroNF = false"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md6>
                  <v-menu ref="mnuDtVecto" v-model="mnuDtVecto" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="dtVecto"
                        label="Data de Vencimento"
                        v-mask="'##/##/####'"
                        return-v-masked-value
                        hint="Obrigatório"
                        prepend-icon="event"
                        :readonly="
                          !direitoVecto ||
                            !['ABERTA', 'BLOQUEADA'].includes(parcela.status) ||
                            (!rotaReceber && parcela.confirmada == 1 && $store.state.usuario.site_confirmaOpPagar == 1)
                        "
                        v-on="on"
                        :rules="[
                          validacao.dtVecto == false || validacao.dtVecto,
                          value => valida_data(value) || 'Data inválida',
                          value => !!value || 'Obrigatório.',
                          value => (value != null && value.length == 10) || 'Preencha a data de vencimento'
                        ]"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        @keyup="dtVectoUS = valida_data(dtVecto) ? formatDateUS(dtVecto) : null"
                        @change="validacao.dtVecto = false"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dtVectoUS"
                      locale="pt-br"
                      no-title
                      :readonly="
                        !direitoVecto ||
                          !['ABERTA', 'BLOQUEADA'].includes(parcela.status) ||
                          (!rotaReceber && parcela.confirmada == 1 && $store.state.usuario.site_confirmaOpPagar == 1)
                      "
                      @input="mnuDtVecto = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12 md3>
                  <v-text-field v-model="parcela.dtVectoO" label="Vencimento Original" readonly color="blue-grey lighten-2"></v-text-field>
                </v-flex>
                <v-flex xs12 md3>
                  <v-text-field
                    v-model="emissaoNF"
                    label="Emissão NF"
                    v-mask="'##/##/####'"
                    :rules="[validacao.emissaoNF == false || validacao.emissaoNF, value => valida_data(value) || 'Data inválida']"
                    :readonly="rotaReceber"
                    color="blue-grey lighten-2"
                    autocomplete="disabled"
                    :name="Math.random()"
                    @change="validacao.emissaoNF = false"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 md6>
                  <v-autocomplete
                    v-model="formaPagamentoSelecionada"
                    :items="formasPgto"
                    :readonly="
                      !direitoFormaPgto ||
                        !['ABERTA', 'BLOQUEADA'].includes(parcela.status) ||
                        (!rotaReceber && parcela.confirmada == 1 && $store.state.usuario.site_confirmaOpPagar == 1)
                    "
                    hint="Obrigatório"
                    :rules="[validacao.idFormaPgto == false || validacao.idFormaPgto, value => (!!value && !!value.id) || 'Obrigatório.']"
                    :filter="filtrarObjetos"
                    label="Forma de Pagamento"
                    item-text="nome"
                    item-value="id"
                    no-data-text
                    return-object
                    @change="
                      carregaItensFormaPgto();
                      validacao.idFormaPgto = false;
                    "
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12 md3>
                  <v-text-field v-model="parcela.dtPromessa" label="Promessa Pagamento" readonly color="blue-grey lighten-2"></v-text-field>
                </v-flex>
                <v-flex xs12 md3>
                  <v-currency-field
                    :locale="`pt-${$store.state.usuario.site_pais}`"
                    v-model="parcela.valorBruto"
                    label="Valor Bruto"
                    readonly
                    color="blue-grey lighten-2"
                  ></v-currency-field>
                </v-flex>
                <v-flex xs12 md2>
                  <v-btn class="mt-3" depressed v-if="btnEmailBoleto" title="Enviar Boleto por e-mail" @click="boletoEmail()">
                    <v-icon color="blue lighten-2" left>email</v-icon>
                    <v-icon left color="indigo">format_bold</v-icon>
                  </v-btn>
                </v-flex>
                <v-flex xs12 md2>
                  <v-btn class="mt-3" depressed v-if="btnEmailNF" title="Enviar NF por e-mail" @click="nfEmail()">
                    <v-icon color="blue lighten-2" left>email</v-icon>
                    <v-icon left color="teal">account_balance</v-icon>
                  </v-btn>
                </v-flex>
                <v-flex xs12 md2>
                  <v-btn class="mt-3" depressed v-if="btnEmailBoleto && btnEmailNF" title="Enviar Boleto + NF por e-mail" @click="boletoNfEmail()">
                    <v-icon color="blue lighten-2" left>email</v-icon>
                    <v-icon left color="indigo">format_bold</v-icon>
                    <v-icon left color="teal">account_balance</v-icon>
                  </v-btn>
                </v-flex>
                <v-flex xs5>
                  <v-switch
                    v-model="cobBloqueada"
                    :label="`${cobBloqueada == 1 ? 'Cobrança Bloqueada' : 'Cobrança Liberada'}`"
                    :disabled="!direitoBloquear || (parcela.status != 'ABERTA' && parcela.status != 'BLOQUEADA')"
                    @change="carregarMotivosParcela"
                  ></v-switch>
                </v-flex>
                <v-flex xs1 md1>
                  <v-icon v-if="tela != 'parcelas' && direitoParcelas" color="blue lighten-2" title="Visualizar no Financeiro" @click="visualizarParcelas"
                    >add_circle_outline</v-icon
                  >
                </v-flex>

                <v-flex xs12 md12 v-if="rotaReceber && cobBloqueada && !parcela.motivoBloqueio">
                  <v-autocomplete
                    v-model="idMotivoBloqueio"
                    :items="motivosParcelas"
                    hint="Obrigatório"
                    :rules="[value => !!value || 'Obrigatório.']"
                    :filter="filtrarObjetos"
                    label="Motivo do Bloqueio"
                    item-text="nome"
                    item-value="id"
                    autocomplete="disabled"
                    :name="Math.random()"
                    no-data-text
                    return-object
                    @change="parcela.motivoBloqueio = idMotivoBloqueio.nome"
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12 md12 v-if="rotaReceber && cobBloqueada && idMotivoBloqueio && parcela.motivoBloqueio">
                  <v-text-field
                    v-model="parcela.motivoBloqueio"
                    label="Motivo do Bloqueio"
                    maxlength="255"
                    color="blue-grey lighten-2"
                    autocomplete="disabled"
                    :name="Math.random()"
                    :rules="[
                      validacao.motivoBloqueio == false || validacao.motivoBloqueio,
                      value => !!value || 'Obrigatório.',
                      value => (value != null && value.length > 3) || 'Preencha o motivo do bloqueio'
                    ]"
                    @change="validacao.motivoBloqueio = false"
                    clearable
                    @click:clear="
                      parcela.motivoBloqueio = null;
                      idMotivoBloqueio = null;
                    "
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md12 v-if="parcela.status == 'CANCELADA' && parcela.motivoCancelamento">
                  <v-text-field v-model="parcela.motivoCancelamento" label="Motivo do Cancelamento" color="blue-grey lighten-2" readonly></v-text-field>
                </v-flex>

                <v-flex xs12 md12 v-if="rotaReceber && (formaPagamentoSelecionada.tipo == 'CELESC' || formaPagamentoSelecionada.tipo == 'SAMAE')">
                  <v-autocomplete
                    v-model="parcela.idClienteCelesc"
                    :items="celescsCliente"
                    :readonly="
                      !direitoFormaPgto ||
                        !['ABERTA', 'BLOQUEADA'].includes(parcela.status) ||
                        (!rotaReceber && parcela.confirmada == 1 && $store.state.usuario.site_confirmaOpPagar == 1)
                    "
                    hint="Obrigatório"
                    :rules="[validacao.idClienteCelesc == false || validacao.idClienteCelesc, value => !!value || 'Obrigatório.']"
                    :filter="filtrarObjetos"
                    label="Unidade Consumidora"
                    item-text="nome"
                    item-value="id"
                    autocomplete="disabled"
                    :name="Math.random()"
                    no-data-text
                    @change="validacao.idClienteCelesc = false"
                  ></v-autocomplete>
                </v-flex>

                <v-flex xs12 md12 v-if="rotaReceber && formaPagamentoSelecionada.tipo == 'CARTAO_CREDITO'">
                  <v-autocomplete
                    v-model="parcela.idClienteCartao"
                    :items="cartoesCliente"
                    :readonly="
                      !direitoFormaPgto ||
                        !['ABERTA', 'BLOQUEADA'].includes(parcela.status) ||
                        (!rotaReceber && parcela.confirmada == 1 && $store.state.usuario.site_confirmaOpPagar == 1)
                    "
                    :filter="filtrarObjetos"
                    label="Cartão de Crédito"
                    item-text="nome"
                    item-value="id"
                    :rules="[
                      validacao.idClienteCartao == false || validacao.idClienteCartao,
                      value => !cartoesCliente.length || (cartoesCliente.length && !!value) || 'Obrigatório.'
                    ]"
                    autocomplete="disabled"
                    :name="Math.random()"
                    no-data-text
                    @change="validacao.idClienteCartao = false"
                  ></v-autocomplete>
                </v-flex>

                <v-flex xs12 md12 v-if="formaPagamentoSelecionada.tipo == 'DEBITO_CONTA'">
                  <v-autocomplete
                    v-model="parcela.idClienteConta"
                    :items="contasCliente"
                    :readonly="
                      !direitoFormaPgto ||
                        !['ABERTA', 'BLOQUEADA'].includes(parcela.status) ||
                        (!rotaReceber && parcela.confirmada == 1 && $store.state.usuario.site_confirmaOpPagar == 1)
                    "
                    hint="Obrigatório"
                    :rules="[validacao.idClienteConta == false || validacao.idClienteConta, value => !!value || 'Obrigatório.']"
                    :filter="filtrarObjetos"
                    label="Conta Corrente"
                    item-text="nome"
                    item-value="id"
                    autocomplete="disabled"
                    :name="Math.random()"
                    no-data-text
                    @change="validacao.idClienteConta = false"
                  ></v-autocomplete>
                </v-flex>

                <v-flex xs12 md12 v-if="formaPagamentoSelecionada.tipo == 'BOLETO' || formaPagamentoSelecionada.tipo == 'CARTEIRA'">
                  <v-autocomplete
                    v-model="parcela.idClienteEndereco"
                    :items="enderecosCliente"
                    :readonly="
                      !direitoFormaPgto ||
                        !['ABERTA', 'BLOQUEADA'].includes(parcela.status) ||
                        (!rotaReceber && parcela.confirmada == 1 && $store.state.usuario.site_confirmaOpPagar == 1)
                    "
                    hint="Obrigatório"
                    :rules="[validacao.idClienteEndereco == false || validacao.idClienteEndereco, value => !!value || 'Obrigatório.']"
                    :filter="filtrarObjetos"
                    label="Endereço de Cobrança"
                    item-text="nome"
                    item-value="id"
                    autocomplete="disabled"
                    :name="Math.random()"
                    no-data-text
                    @change="validacao.idClienteEndereco = false"
                  ></v-autocomplete>
                </v-flex>

                <v-flex xs12 md12 v-if="!rotaReceber && formaPagamentoSelecionada.tipo == 'PIX'">
                  <v-autocomplete
                    v-model="parcela.idClientePix"
                    :items="pixsCliente"
                    :readonly="
                      !direitoFormaPgto ||
                        !['ABERTA', 'BLOQUEADA'].includes(parcela.status) ||
                        (!rotaReceber && parcela.confirmada == 1 && $store.state.usuario.site_confirmaOpPagar == 1)
                    "
                    hint="Obrigatório"
                    :rules="[validacao.idClientePix == false || validacao.idClientePix, value => !!value || 'Obrigatório.']"
                    :filter="filtrarObjetos"
                    label="Chave PIX"
                    item-text="nome"
                    item-value="id"
                    autocomplete="disabled"
                    :name="Math.random()"
                    no-data-text
                    @change="validacao.idClientePix = false"
                  ></v-autocomplete>
                </v-flex>

                <v-flex xs5 md4>
                  <v-currency-field :locale="`pt-${$store.state.usuario.site_pais}`" v-model="parcela.valor" label="Valor" readonly color="blue-grey lighten-2"></v-currency-field>
                </v-flex>

                <v-flex xs1 md1>
                  <v-icon
                    :disabled="
                      !direitoValor ||
                        !['ABERTA', 'BLOQUEADA'].includes(parcela.status) ||
                        (!rotaReceber && parcela.confirmada == 1 && $store.state.usuario.site_confirmaOpPagar == 1)
                    "
                    :color="isAcrescimo ? 'blue' : 'red'"
                    title="Alterar"
                    @click="
                      isAcrescimo = !isAcrescimo;
                      descAcr = 0;
                    "
                    >{{ isAcrescimo ? "add_circle" : "remove_circle" }}</v-icon
                  >
                </v-flex>
                <v-flex xs5 md3>
                  <v-currency-field
                    :locale="`pt-${$store.state.usuario.site_pais}`"
                    v-model="descAcr"
                    :label="isAcrescimo ? 'Acréscimo' : 'Desconto'"
                    :readonly="
                      !direitoValor ||
                        !['ABERTA', 'BLOQUEADA'].includes(parcela.status) ||
                        (!rotaReceber && parcela.confirmada == 1 && $store.state.usuario.site_confirmaOpPagar == 1)
                    "
                    :rules="[validacao.descAcr == false || validacao.descAcr]"
                    :allowNegative="false"
                    :max="9999999"
                    color="blue-grey lighten-2"
                    @change="validacao.descAcr = false"
                  ></v-currency-field>
                </v-flex>
                <v-flex xs12 md4>
                  <v-currency-field :locale="`pt-${$store.state.usuario.site_pais}`" v-model="total" label="Total" readonly color="blue-grey lighten-2"></v-currency-field>
                </v-flex>
                <v-flex xs12 md12>
                  <v-text-field
                    v-model="parcela.obsCobranca"
                    label="Texto para Fatura"
                    maxlength="100"
                    color="blue-grey lighten-2"
                    autocomplete="disabled"
                    :name="Math.random()"
                    :rules="[validacao.obsCobranca == false || validacao.obsCobranca]"
                    @change="validacao.obsCobranca = false"
                    :readonly="!rotaReceber && parcela.confirmada == 1 && $store.state.usuario.site_confirmaOpPagar == 1"
                  ></v-text-field>
                  <!-- não pode ocultar, pois mesmo quitada, a obs pode ser alterada para NF -->
                </v-flex>
                <v-flex xs12 md12 v-if="parcela.obsCobranca && parcela.obsCobranca.length > 40">
                  <v-alert :value="true" color="red" outlined>
                    Excedente NFSC:
                    {{ parcela.obsCobranca.length > 40 ? parcela.obsCobranca.substring(40) : "" }}
                  </v-alert>
                </v-flex>
                <v-flex xs12 md12 v-if="rotaReceber && textoEstendidoNF">
                  <v-textarea
                    v-model="parcela.textoEstendido"
                    label="Texto Estendido para NFSC"
                    maxlength="1000"
                    counter="1000"
                    rows="4"
                    :rules="[
                      validacao.textoEstendido == false || validacao.textoEstendido,
                      value => !value || value.split('\n').length < 12 || 'Você digitou ' + value.split('\n').length + ' linhas. Máximo = 11 linhas'
                    ]"
                    @change="validacao.textoEstendido = false"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 md6 v-if="parcela.ajustada">
                  <v-alert :value="true" color="info" icon="info" outlined>Regra de Ajuste aplicada.</v-alert>
                </v-flex>
                <v-flex xs12 md6 v-if="parcela.conciliado">
                  <v-alert :value="true" color="green" icon="copyright" outlined>Conciliada.</v-alert>
                </v-flex>
                <v-flex xs12 v-if="validacao.mensagemErro">
                  <v-sheet :value="true" class="red--text">
                    {{ validacao.mensagemErro }}
                  </v-sheet>
                </v-flex>
                <v-flex xs12 md6 v-if="parcela.codAntigoCirculacao">
                  <v-alert :value="true" color="grey" icon="info" outlined dense>ID Antigo: {{ parcela.codAntigoCirculacao }}</v-alert>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-form>
      </v-card>

      <v-card-actions class="card-btn">
        <v-spacer></v-spacer>

        <v-btn id="sub-btn" color="orange white" style="color:white" @click="retornar(false)">Retornar</v-btn>
        <v-btn v-if="!rotaReceber" :loading="isUpdating" :disabled="bloqueiaBotoes" id="sub-btn" color="blue white" style="color:white" @click="salvar(false)">Salvar</v-btn>
        <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" id="sub-btn" color="orange white" style="color:white" outlined @click="salvar(true)">Salvar e Retornar</v-btn>
      </v-card-actions>
    </div>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  props: {
    id: String,
    receber: String,
    tela: String,
    atMonetaria: { type: Number, default: 0 },
    atualiza: String
  },

  data() {
    return {
      parcelaOld: {},
      parcela: {},
      validacao: {
        nroNF: false,
        dtVecto: false,
        emissaoNF: false,
        idFormaPgto: false,
        idClienteCartao: false,
        idClienteConta: false,
        idClienteEndereco: false,
        idClienteCelesc: false,
        idClientePix: false,
        descAcr: false,
        obsCobranca: false,
        textoEstendido: false,
        motivoBloqueio: false,
        mensagemErro: false
      },
      isAcrescimo: false,
      descAcr: 0,
      total: 0,

      idSitePrincipal: null,
      idCliente: null,

      dtVecto: null,
      dtVectoUS: null,
      mnuDtVecto: false,

      nroNF: "",
      lblNF: "",
      emissaoNF: null,

      formaPagamentoSelecionada: {
        id: null,
        nome: "",
        tipo: ""
      },

      cartoesCliente: [],
      celescsCliente: [],
      contasCliente: [],
      enderecosCliente: [],
      pixsCliente: [],
      obsCobranca: "",
      textoEstendido: "",
      textoEstendidoNF: false,

      formasPgto: [],
      status: ["PROPOSTA", "ABERTA", "BLOQUEADA", "QUITADA", "CANCELADA"],
      statusCobranca: [
        { id: 0, nome: "Não Emitida" },
        { id: 10, nome: "Emitida" },
        { id: 11, nome: "Confirmada" },
        { id: 20, nome: "Alterada" },
        // { id: 21, nome: "Alterada Banco" }, será 11
        { id: 30, nome: "Cancelada" },
        // { id: 31, nome: "Cancelada Banco" }, será 0
        { id: 40, nome: "Erro" }
      ],

      direitoAssinante: false,
      direitoAnunciante: false,
      direitoCliente: false,
      direitoFornecedor: false,
      direitoVecto: false,
      direitoFormaPgto: false,
      direitoValor: false,
      direitoBloquear: false,
      direitoCobranca: false,
      direitoParcelas: false,

      cobBloqueada: false,
      bloqueado: false,
      idMotivoBloqueio: null,
      motivosParcelas: [],

      btnEmailBoleto: false,
      btnEmailNF: false,

      erro: false,
      elementoValid: true,
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false,
      bloqueiaBotoes: false
    };
  },

  methods: {
    ...mapMutations(["MOSTRAR_MENU"]),
    async salvar(retornar) {
      await util.limparObjeto(this.validacao, false);

      this.erro = false;
      //* Tenta alterar somente a obsCobranca
      await this.salvaObs();

      if (this.parcela.status == "ABERTA" || this.parcela.status == "BLOQUEADA") {
        //* Se parcela aberta, altera dados de cobrança
        await this.salvaDados();
        this.$emit("update:atualiza", this.atualiza == "sim" ? "não" : "sim");
      }

      if (retornar && !this.erro) {
        setTimeout(() => {
          this.retornar(null);
        }, 1500);
      }
    },

    async salvaObs() {
      if (
        this.parcela.obsCobranca != this.obsCobranca ||
        this.parcela.textoEstendido != this.textoEstendido ||
        (!this.rotaReceber && (this.parcela.numeroNFpg != this.nroNF || util.formatDate(this.parcela.dtEmissaoNFpg) != this.emissaoNF))
      ) {
        if (!this.$refs.formElemento.validate()) {
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
          this.erro = true;
          return;
        }
        if (
          (this.parcela.idNFe && this.parcela.statusNFe == "Processada") ||
          (this.parcela.idNFSC && this.parcela.situacaoNFSC == "N" && this.parcela.pdfNFSC == 2) ||
          (this.parcela.idNFSe && this.parcela.statusNFSe == "Processada")
        ) {
          this.mensagem = "Nota fiscal já emitida. Texto não pode mais ser alterado.";
          this.snackErro = true;
          this.erro = true;
          return;
        }
        this.isUpdating = true;
        try {
          var parcelaCommit = {
            obsCobranca: util.removeEspeciais(util.removeAcentos(this.parcela.obsCobranca)),
            textoEstendido: this.parcela.textoEstendido,
            numeroNFpg: this.nroNF,
            dtEmissaoNFpg: util.formatDateUS(this.emissaoNF)
          };
          await api.put(process.env.VUE_APP_URL_WEB_API + "fin/parcela/obsCobranca/" + this.parcela.id, parcelaCommit);

          this.mensagem = "Dados foram atualizados";
          this.snackbar = true;
        } catch (e) {
          if (e.toString().includes("status code 400")) {
            this.mensagem = "SESSÃO EXPIRADA";
            this.snackErro = true;
          } else if (e.response && e.response.data) {
            util.validaErros(this.validacao, e.response.data);
            this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
            this.snackErro = true;
          } else {
            this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
            this.snackErro = true;
          }
          this.erro = true;
        } finally {
          this.isUpdating = false;
        }
      }
    },

    async salvaDados() {
      if (
        util.dateToStringUS(this.dtVectoUS) != util.dateToStringUS(this.parcelaOld.dtVecto) ||
        this.descAcr * (this.isAcrescimo ? 1 : -1) != this.parcelaOld.descAcr ||
        this.formaPagamentoSelecionada.id != this.parcelaOld.idFormaPgto ||
        this.parcela.idClienteEndereco != this.parcelaOld.idClienteEndereco ||
        this.parcela.idClienteCartao != this.parcelaOld.idClienteCartao ||
        this.parcela.idClienteCelesc != this.parcelaOld.idClienteCelesc ||
        this.parcela.idClienteConta != this.parcelaOld.idClienteConta ||
        this.parcela.idClientePix != this.parcelaOld.idClientePix ||
        this.parcela.statusCobranca != this.parcelaOld.statusCobranca ||
        (this.cobBloqueada && this.parcela.status == "ABERTA") ||
        (!this.cobBloqueada && this.parcela.status == "BLOQUEADA")
      ) {
        var erro = false;
        this.total = this.parcela.valor + this.descAcr * (this.isAcrescimo ? 1 : -1);
        if (this.total < 0 || this.descAcr > this.parcela.valor) {
          erro = true;
          this.validacao.descAcr = "Desconto / Acréscimo inválido";
          this.total = this.parcela.valor;
          this.descAcr = 0;
        }
        if (util.parseDateUS(this.dtVectoUS) < util.parseDateUS(this.parcela.dtCriacao)) {
          this.validacao.dtVecto = "Data de vencimento inferior a data da emissão";
        }
        if (util.dateToStringUS(this.parcela.dtVecto) != this.dtVectoUS) {
          var dtHoje = util.parseDateUS(new Date());
          if (util.parseDateUS(this.dtVectoUS) < dtHoje) {
            erro = true;
            this.validacao.dtVecto = "Data inválida";
          } else {
            //* Se parcela foi alterada nesta tela, só permite até 90 dias de diferença do vencimento original - alteração maior, fazer pelo Financeiro (alteração em lote)
            var dtLimiteMin = util.parseDateUS(this.parcela.dtVectoOriginal);
            dtLimiteMin.setMonth(dtLimiteMin.getMonth() - 1);

            var dtLimiteMax = util.parseDateUS(this.parcela.dtVectoOriginal);
            dtLimiteMax.setMonth(dtLimiteMax.getMonth() + 3);
            if (util.parseDateUS(this.dtVectoUS) < dtLimiteMin || util.parseDateUS(this.dtVectoUS) > dtLimiteMax) {
              erro = true;
              this.validacao.dtVecto = "Alteração além de 3 meses não permitida nesta tela";
            }
          }
        }
        if (this.parcela.idLoteFaturamento) {
          if (!this.parcela.remessa) {
            //* Se parcela está em lote ainda não gerado, não permite alterar - tem que gerar a remessa ou excluir o lote
            erro = true;
            this.validacao.mensagemErro = "Alteração não permitida até a geração da remessa (ou exclusão do lote)";
          }
        }
        if (this.parcela.statusCobranca == 30) {
          if (this.parcela.tipoLote < 3 || this.parcela.remessa != "ENVIADA") {
            //* Se parcela está com statusCobranca cancelada, só permite alterar se ja estiver em lote de alteração enviado
            erro = true;
            this.validacao.mensagemErro = "Alteração não permitida até o envio da remessa de cancelamento ao banco";
          }
        }

        if (this.parcela.formaPagamentoTipo == "DEBITO_CONTA" && this.parcela.idLoteFaturamento && this.parcela.statusCobranca != 40) {
          erro = true;
          this.validacao.mensagemErro = "Alteração não permitida - cancele o Débito em Conta antes";
        }

        if (this.parcela.formaPagamentoTipo == "PIX" && this.parcela.idLoteFaturamento && this.parcela.statusCobranca != 40) {
          erro = true;
          this.validacao.mensagemErro = "Alteração não permitida - cancele o PIX antes";
        }

        if (this.parcela.formaPagamentoTipo == "BOLETO" && this.parcela.idLoteFaturamento && this.parcela.statusCobranca != 40) {
          erro = true;
          this.validacao.mensagemErro = "Alteração não permitida - cancele o Boleto antes";
        }

        if (this.parcela.formaPagamentoTipo == "CELESC" && this.parcela.idLoteFaturamento && this.parcela.statusCobranca != 40) {
          //* Se parcela está em lote celesc sem erro, não permite alterar - tem que aguardar o retorno: QUITADA ou ERRO
          erro = true;
          this.validacao.mensagemErro = "Alteração não permitida até o retorno da Celesc";
        }
        if (!this.$refs.formElemento.validate() || erro) {
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
          this.erro = true;
          return;
        }

        //* Se é alteração de débito em conta já no banco, cancelar no banco
        //* Se é alteração de boleto já no banco, alterar no banco
        //* Se é alteração de celesc já enviada, só altera parcela, pois não adianta reenviar a Celesc (só vai 1 título por contrato por mês)

        this.isUpdating = true;
        try {
          var bloqParcela = this.cobBloqueada && this.parcela.status == "ABERTA";
          var parcelaCommit = {
            id: this.parcela.id,
            dtVecto: this.dtVectoUS,
            idFormaPgto: this.formaPagamentoSelecionada.id,
            formaPagamentoTipo: this.formaPagamentoSelecionada.tipo,
            idClienteEndereco: this.parcela.idClienteEndereco,
            idClienteCartao: this.parcela.idClienteCartao,
            idClienteCelesc: this.parcela.idClienteCelesc,
            idClienteConta: this.parcela.idClienteConta,
            idClientePix: this.parcela.idClientePix,
            statusCobranca: this.parcela.statusCobranca,
            nossoNumero: this.parcela.nossoNumero, //envia somente para validação na api
            idLoteFaturamento: this.parcela.idLoteFaturamento, //envia somente para validação na api
            dtEmissaoNFpg: this.parcela.dtEmissaoNFpg,
            numeroNFpg: this.parcela.numeroNFpg
          };
          if (bloqParcela) {
            parcelaCommit.status = "BLOQUEADA";
            parcelaCommit.motivoBloqueio = this.parcela.motivoBloqueio;
          } else if (!this.cobBloqueada && this.parcela.status == "BLOQUEADA") {
            parcelaCommit.status = "ABERTA";
          }

          if (this.parcela.idLoteFaturamento && (this.parcela.formaPagamentoTipo == "DEBITO_CONTA" || this.parcela.formaPagamentoTipo == "BOLETO")) {
            if (
              bloqParcela ||
              this.parcela.idFormaPgto != this.formaPagamentoSelecionada.id ||
              parseFloat(this.parcela.descAcr) != parseFloat(this.descAcr * (this.isAcrescimo ? 1 : -1))
            ) {
              //* Se forma de pagamento ou valor foi alterado ou foi bloqueada, altera o status da cobrança para que seja cancelada no banco
              parcelaCommit.statusCobranca = 30;
            } else {
              if (util.dateToStringUS(this.parcela.dtVecto) != this.dtVectoUS) {
                //* Se vencimento foi alterado
                if (this.parcela.formaPagamentoTipo == "DEBITO_CONTA") {
                  //* Se for débito em conta altera o status da cobrança para que seja cancelada no banco
                  parcelaCommit.statusCobranca = 30;
                } else {
                  //* Se for boleto altera o status da cobrança para que seja alterada no banco
                  parcelaCommit.statusCobranca = 20;
                }
              }
            }
          }

          await api.put(process.env.VUE_APP_URL_WEB_API + "fin/parcela/cobranca/" + this.parcela.id, parcelaCommit);

          if (this.parcela.descAcr != this.descAcr * (this.isAcrescimo ? 1 : -1) && ["ABERTA", "BLOQUEADA"].includes(this.parcela.status)) {
            //* Se houve alteração de descAcr, commita
            var descAcrCommit = {
              descAcr: this.descAcr * (this.isAcrescimo ? 1 : -1),
              statusCobranca: parcelaCommit.statusCobranca, //apesar de já ter sido commitado acima, envia aqui para manter integridade do método
              valor: this.parcela.valor, //envia somente para validação na api
              nossoNumero: this.parcela.nossoNumero, //envia somente para validação na api
              idLoteFaturamento: this.parcela.idLoteFaturamento //envia somente para validação na api
            };
            await api.put(process.env.VUE_APP_URL_WEB_API + "fin/parcela/descAcr/" + this.parcela.id, descAcrCommit);
          }
          if (!this.snackErro) {
            this.mensagem = "Dados foram atualizados";
            this.snackbar = true;
          }
        } catch (e) {
          if (e.toString().includes("status code 400")) {
            this.mensagem = "SESSÃO EXPIRADA";
            this.snackErro = true;
          } else if (e.response && e.response.data) {
            util.validaErros(this.validacao, e.response.data);
            this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
            this.snackErro = true;
          } else {
            this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
            this.snackErro = true;
          }
          this.erro = true;
        } finally {
          this.isUpdating = false;
        }
      }
    },

    async getTextoEstendidoNF() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/config-nfsc/site/" + this.idSitePrincipal);
        if (response && response.data && response.data.textoEstendido) {
          this.textoEstendidoNF = true;
        }

        const responseE = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/config-nfse/site/" + this.idSitePrincipal);
        if (responseE && responseE.data && responseE.data.textoEstendido) {
          this.textoEstendidoNF = true;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as configurações para nota fiscal";
        this.snackErro = true;
      }
    },

    async carregarItem() {
      this.isUpdating = false;
      this.bloqueiaBotoes = true;
      await util.limparObjeto(this.validacao, false);

      this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
      this.parcela.id = this.idParcela;

      await this.getTextoEstendidoNF();

      try {
        //* Somente se é edição, carrega o item
        if (this.parcela.id) {
          //* Carrega o item
          const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/parcela/" + this.parcela.id + "?idSite=" + this.idSitePrincipal);

          if (!response.data) {
            this.bloqueado = true;
          } else {
            this.parcela = response.data;
            if (this.parcela.motivoBloqueio) {
              this.idMotivoBloqueio = 1;
            }

            //* Verifica se o usuário logado possui direitos
            this.$store.state.usuario.direitos.forEach(element => {
              var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
              if (this.rotaReceber) {
                if (this.parcela.idAssinatura && (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_ASSINANTES_EDITAR")) {
                  this.direitoAssinante = true;
                } else if (this.parcela.idAnuncio && (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_ANUNCIANTE_EDITAR")) {
                  this.direitoAnunciante = true;
                } else if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_CLIENTE_EDITAR") {
                  this.direitoCliente = true;
                }

                if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_RECEBER_ALTERAR_VENCIMENTO") {
                  this.direitoVecto = true;
                }
                if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_RECEBER_ALTERAR_FORMA_PAGAMENTO") {
                  this.direitoFormaPgto = true;
                }
                if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_RECEBER_ALTERAR_VALOR") {
                  this.direitoValor = true;
                }
                if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_RECEBER_ALTERAR_BLOQUEIO") {
                  this.direitoBloquear = true;
                }
                if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_RECEBER_COBRANÇA") {
                  this.direitoCobranca = true;
                }
                if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_RECEBER_ACESSO") {
                  this.direitoParcelas = true;
                }
              } else {
                if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_FORNECEDOR_EDITAR") {
                  this.direitoFornecedor = true;
                }
                if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_PAGAR_ALTERAR_VENCIMENTO") {
                  this.direitoVecto = true;
                }
                if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_PAGAR_ALTERAR_FORMA_PAGAMENTO") {
                  this.direitoFormaPgto = true;
                }
                if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_PAGAR_ALTERAR_VALOR") {
                  this.direitoValor = true;
                }
                if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_PAGAR_ACESSO") {
                  this.direitoParcelas = true;
                }
              }
            });

            this.parcelaOld = Object.assign({}, response.data);

            this.btnEmailBoleto =
              this.direitoCobranca &&
              this.rotaReceber &&
              this.parcela.status == "ABERTA" &&
              this.parcela.idLoteFaturamento &&
              this.parcela.nossoNumero &&
              this.parcela.formaPagamentoTipo == "BOLETO" &&
              parseInt(this.parcela.statusCobranca) >= 10 &&
              parseInt(this.parcela.statusCobranca) <= 20;

            this.btnEmailNF =
              this.direitoCobranca &&
              this.rotaReceber &&
              ((this.parcela.idNFe && this.parcela.statusNFe == "Processada") || (this.parcela.idNFSC && this.parcela.situacaoNFSC == "N" && this.parcela.pdfNFSC == 2));
            //||(this.parcela.idNFSe && this.parcela.statusNFSe == "Processada") - DANFE é gerado na Prefeitura
            if (this.rotaReceber) {
              this.nroNF = this.parcela.numeroNFe || this.parcela.numeroNFSC || this.parcela.numeroNFSe;
              this.lblNF = this.parcela.idNFe ? "Nº NFe" : this.parcela.idNFSC ? "Nº NFSC" : this.parcela.idNFSe ? "Nº NFSe" : "Nº NF";
              this.emissaoNF = util.formatDate(this.parcela.dtEmissaoNFe || this.parcela.dtEmissaoNFSC || this.parcela.dtEmissaoNFSe);
            } else {
              this.nroNF = this.parcela.numeroNFpg;
              this.lblNF = "Nº NF";
              this.emissaoNF = util.formatDate(this.parcela.dtEmissaoNFpg);
            }

            this.dtVectoUS = util.dateToStringUS(this.parcela.dtVecto);
            this.obsCobranca = this.parcela.obsCobranca;
            this.textoEstendido = this.parcela.textoEstendido;
            this.idCliente = this.parcela.idCliente;

            //* Carrega as formas de pagamento
            this.formasPgto = [];
            //* Para captação deve ser bloqueado somente nas formas do plano, mas para cobrança, quem tem permissão para alterar pode tentar cobrar por qualquer outra forma
            var responseFormasPgto = await api.get(
              process.env.VUE_APP_URL_WEB_API +
                "adm/forma-pagamento/lista?idSite=" +
                this.idSitePrincipal +
                "&pagas=" +
                (util.isFormaPgtoValorZero(this.parcela.formaPagamentoTipo) ? "false" : "true") +
                (this.rotaReceber ? "" : "&pagar=1")
            );
            // }
            var formas = responseFormasPgto.data;
            formas.forEach(element => {
              //* Processa as formas de pagamento para remover aquelas inconsistentes com a da parcela (ex: se CORTESIA, só poderá retornar CORTESIA, se BONIFICACAO idem - A_VISTA, pode ser alterada para outra)
              if (util.isFormaPgtoEspeciais(this.parcela.formaPagamentoTipo)) {
                //* Se forma de pagamento original da parcela for especial, irá incluir somente ela
                if (this.parcela.formaPagamentoTipo == element.tipo) {
                  this.formasPgto.push(element);
                }
              } else {
                //* Se forma de pagamento da parcela não for especial, irá incluir as não especiais
                if (!util.isFormaPgtoEspeciais(element.tipo)) {
                  this.formasPgto.push(element);
                }
              }
            });
            this.formaPagamentoSelecionada = {
              id: this.parcela.idFormaPgto,
              nome: this.parcela.formaPagamento,
              tipo: this.parcela.formaPagamentoTipo
            };

            if (this.formaPagamentoSelecionada.tipo == "BOLETO" || this.formaPagamentoSelecionada.tipo == "CARTEIRA") {
              await this.carregarEnderecos();
            }
            if (this.formaPagamentoSelecionada.tipo == "CARTAO_CREDITO") {
              await this.carregarCartoes();
            }
            if (this.formaPagamentoSelecionada.tipo == "CELESC" || this.formaPagamentoSelecionada.tipo == "SAMAE") {
              await this.carregarCelescs();
            }
            if (this.formaPagamentoSelecionada.tipo == "DEBITO_CONTA") {
              await this.carregarContas();
            }
            if (this.formaPagamentoSelecionada.tipo == "PIX") {
              this.carregarPixs();
            }

            this.bloqueado = false;
            this.cobBloqueada = this.parcela.status == "BLOQUEADA";
            this.parcela.dtVectoO = util.formatDate(this.parcela.dtVectoOriginal);
            this.parcela.dtPromessa = util.formatDate(this.parcela.dtPromessa);
            this.total = this.parcela.valor + this.parcela.descAcr;
            this.isAcrescimo = this.parcela.descAcr > 0;
            this.descAcr = Math.abs(this.parcela.descAcr);
          }
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os dados da parcela";
        this.snackErro = true;
      }
      setTimeout(() => {
        this.bloqueiaBotoes = false;
      }, 1000);
    },

    async carregarEnderecos() {
      try {
        //* Carrega os endereços de cobrança do cliente
        const responseEnderecosCliente = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-endereco/lista?idCliente=" + this.idCliente + "&cobranca=1");
        this.enderecosCliente = responseEnderecosCliente.data;
        this.enderecosCliente.forEach(element => {
          element.nome = element.tipo + " " + element.logradouro + ", " + element.numero + " - " + element.bairro + " - " + element.municipio + "/" + element.uf;
        });
      } catch (e) {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os endereços de cobrança do cliente: " + e;
        this.snackErro = true;
      }
    },
    async carregarCartoes() {
      try {
        //* Carrega os cartões de crédito do cliente
        const responseCartoesCliente = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-cartao/lista?idCliente=" + this.idCliente);
        this.cartoesCliente = responseCartoesCliente.data;
        this.cartoesCliente.forEach(element => {
          element.nome = util.escondeCartao(element.numero) + " - " + element.validade;
        });
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os cartões de crédito do cliente";
        this.snackErro = true;
      }
    },
    async carregarCelescs() {
      try {
        //* Carrega as unidades consumidoras do cliente
        const responseCelescsCliente = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-celesc/lista?idCliente=" + this.idCliente);
        this.celescsCliente = responseCelescsCliente.data;
        this.celescsCliente.forEach(element => {
          element.nome = element.unidadeConsumidora + " (" + element.identificacao + ")";
        });
      } catch (e) {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as unidades consumidoras do cliente: " + e;
        this.snackErro = true;
      }
    },
    async carregarContas() {
      try {
        //* Carrega as contas correntes do cliente
        const responseContasCliente = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-conta/lista?idCliente=" + this.idCliente);
        this.contasCliente = responseContasCliente.data;
        this.contasCliente.forEach(element => {
          element.nome = element.bancoNumero + ": " + element.agencia + " - " + element.numero + " | " + util.formatIdentificacao(element.identificacao);
        });
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as contas correntes do cliente";
        this.snackErro = true;
      }
    },
    async carregarPixs() {
      try {
        //* Carrega os pix do cliente
        const responsePixsCliente = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-pix/lista?idCliente=" + this.idCliente);
        this.pixsCliente = responsePixsCliente.data;
        this.pixsCliente.forEach(element => {
          element.nome =
            (element.tipoChave == "01"
              ? "Telefone: "
              : element.tipoChave == "02"
              ? "E-mail: "
              : element.tipoChave == "03"
              ? "CPF/CNPJ: "
              : element.tipoChave == "04"
              ? "Aleatória: "
              : "Conta: ") + element.chave;
        });
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as chaves pix do cliente";
        this.snackErro = true;
      }
    },
    async carregarMotivosParcela() {
      try {
        if (this.cobBloqueada && !this.motivosParcelas.length) {
          //* Carrega os motivos de bloqueio/cancelamento de parcelas
          const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/motivo-parcela/lista");
          this.motivosParcelas = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os motivos de bloqueio/cancelamento de parcelas";
        this.snackErro = true;
      }
    },

    async boletoEmail() {
      this.btnEmailBoleto = false;
      var boletosCommit = [];
      var caminhoBoleto = `${this.idSitePrincipal}/boletos/${this.parcela.bancoNumero}/boletos`;
      var boleto = {
        idSite: this.idSitePrincipal,
        idCliente: this.parcela.idCliente,
        origem: this.parcela.origem,
        idUsuario: this.$store.state.usuario.id,
        tipo: 5,
        pastaBoleto: caminhoBoleto,
        boleto: this.parcela.nossoNumero,
        pastaNF: null,
        nf: null,
        recibo: null,
        pi: null
      };
      boletosCommit.push(boleto);
      try {
        await api.post(process.env.VUE_APP_URL_WEB_API + "adm/log-email/lista?idSite=" + this.idSitePrincipal, boletosCommit);

        this.mensagem = "Boleto direcionado para envio por e-mail";
        this.snackbar = true;
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },

    async nfEmail() {
      this.btnEmailNF = false;
      var notasCommit = [];
      var caminhoNota = "";
      var numeroNota = "";
      if (this.parcela.idNFe) {
        caminhoNota = `${this.idSitePrincipal}/notas-fiscais/nfe/notas/${this.parcela.anoMesNFe}`;
        numeroNota = `NFe${this.parcela.chaveNFe}`;
      } else if (this.parcela.idNFSC) {
        caminhoNota = `${this.idSitePrincipal}/notas-fiscais/nfsc/notas/${this.parcela.anoMesNFSC}`;
        numeroNota = this.parcela.numeroNFSC.toString().padStart(6, "0");
      } else if (this.parcela.idNFSe) {
        caminhoNota = (this.parcela.urlImpressao.startsWith("http") ? "" : "http://") + this.parcela.urlImpressao;
        numeroNota = this.parcela.numeroNFSe;
      }
      if (caminhoNota) {
        var boleto = {
          idSite: this.idSitePrincipal,
          idCliente: this.parcela.idCliente,
          origem: this.parcela.origem,
          idUsuario: this.$store.state.usuario.id,
          tipo: 4,
          pastaBoleto: null,
          boleto: null,
          pastaNF: caminhoNota,
          nf: numeroNota,
          recibo: null,
          pi: null
        };
        notasCommit.push(boleto);
      }
      try {
        await api.post(process.env.VUE_APP_URL_WEB_API + "adm/log-email/lista?idSite=" + this.idSitePrincipal, notasCommit);

        this.mensagem = "Nota fiscal direcionada para envio por e-mail";
        this.snackbar = true;
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },

    async boletoNfEmail() {
      this.btnEmailBoleto = false;
      this.btnEmailNF = false;
      var boletosNfCommit = [];
      var caminhoBoleto = `${this.idSitePrincipal}/boletos/${this.parcela.idCedente}/boletos`;
      var caminhoNota = "";
      var numeroNota = "";
      if (this.parcela.idNFe) {
        caminhoNota = `${this.idSitePrincipal}/notas-fiscais/nfe/notas/${this.parcela.anoMesNFe}`;
        numeroNota = `NFe${this.parcela.chaveNFe}`;
      } else if (this.parcela.idNFSC) {
        caminhoNota = `${this.idSitePrincipal}/notas-fiscais/nfsc/notas/${this.parcela.anoMesNFSC}`;
        numeroNota = this.parcela.numeroNFSC.toString().padStart(6, "0");
      } else if (this.parcela.idNFSe) {
        caminhoNota = (this.parcela.urlImpressao.startsWith("http") ? "" : "http://") + this.parcela.urlImpressao;
        numeroNota = this.parcela.numeroNFSe;
      }
      if (caminhoNota) {
        var boletoNF = {
          idSite: this.idSitePrincipal,
          idCliente: this.parcela.idCliente,
          origem: this.parcela.origem,
          idUsuario: this.$store.state.usuario.id,
          tipo: 6,
          pastaBoleto: caminhoBoleto,
          boleto: this.parcela.nossoNumero,
          pastaNF: caminhoNota,
          nf: numeroNota,
          recibo: null,
          pi: null
        };
        boletosNfCommit.push(boletoNF);
      }
      try {
        await api.post(process.env.VUE_APP_URL_WEB_API + "adm/log-email/lista?idSite=" + this.idSitePrincipal, boletosNfCommit);

        this.mensagem = "Boletos/Notas Fiscais direcionados para envio por e-mail";
        this.snackbar = true;
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },

    ajustaBloqueio() {
      if (this.parcela.bloqueio) {
        if (!this.parcela.dtBloqueio) {
          this.parcela.dtBloqueio = util.formatDateTime(new Date());
        }
      } else {
        this.parcela.dtBloqueio = null;
      }
    },
    carregaItensFormaPgto() {
      if (this.formaPagamentoSelecionada.tipo == "BOLETO" || this.formaPagamentoSelecionada.tipo == "CARTEIRA") {
        this.carregarEnderecos();
      }
      if (this.formaPagamentoSelecionada.tipo == "CARTAO_CREDITO") {
        this.carregarCartoes();
      }
      if (this.formaPagamentoSelecionada.tipo == "CELESC" || this.formaPagamentoSelecionada.tipo == "SAMAE") {
        this.carregarCelescs();
      }
      if (this.formaPagamentoSelecionada.tipo == "DEBITO_CONTA") {
        this.carregarContas();
      }
      if (this.formaPagamentoSelecionada.tipo == "PIX") {
        this.carregarPixs();
      }
    },
    retornar(valor) {
      if (this.id) {
        this.$emit("update:dialogParcela", valor);
      } else {
        this.$router.push("/financeiro/parcelas/" + (this.rotaReceber ? "receber" : "pagar"));
      }
    },

    editarCliente() {
      if (this.direitoAssinante) {
        window.open("/assinaturas/assinante/" + this.idCliente, "_blank");
      }
      if (this.direitoAnunciante) {
        window.open("/comercial/anunciante/" + this.idCliente, "_blank");
      }
      if (this.direitoCliente) {
        window.open("/financeiro/cliente/" + this.idCliente, "_blank");
      }
      if (this.direitoFornecedor) {
        window.open("/financeiro/fornecedor/" + this.idCliente, "_blank");
      }
    },
    visualizarParcelas() {
      window.open("/financeiro/parcelas/" + (this.rotaReceber ? "receber" : "pagar") + "?idParcela=" + this.parcela.id, "_blank");
    },

    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    filtrarItens(item, queryText, itemText) {
      return util.filtrarItens(item, queryText, itemText);
    },
    valida_data(date) {
      if (date) {
        return util.valida_data_BR(date);
      } else {
        return true;
      }
    },
    formatDateUS(date) {
      return util.formatDateUS(date);
    }
  },

  computed: {
    rotaReceber() {
      return this.receber == "true" || this.$route.path.indexOf("/receber") > 1;
    },
    idParcela() {
      return this.id || this.$route.params.id;
    }
  },
  watch: {
    id() {
      this.carregarItem();
    },
    descAcr(val) {
      this.total = this.parcela.valor + this.descAcr * (this.isAcrescimo ? 1 : -1);
      if (this.total < 0 || this.descAcr > this.parcela.valor) {
        this.mensagem = "Desconto/Acréscimo inválido";
        this.snackErro = true;
        setTimeout(() => {
          this.total = this.parcela.valor;
          this.descAcr = 0;
        }, 300);
      } else {
        if (!this.rotaReceber) {
          var atMon = val * (this.isAcrescimo ? 1 : -1);
          this.$emit("update:atMonetaria", atMon);
        }
      }
    },
    dtVectoUS() {
      this.dtVecto = util.formatDate(this.dtVectoUS);
      this.mnuDtVecto = false;
    }
  },
  async created() {
    this.carregarItem();
  }
};
</script>
