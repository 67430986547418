<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">format_list_numbered</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">
        Editoria
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear
              :active="isUpdating"
              class="ma-0"
              color="cyan accent-3"
              height="3"
              indeterminate
            ></v-progress-linear>
          </v-flex>

          <v-flex xs12 md12 v-if="siteGrupo.length > 0">
            <v-autocomplete
              v-model="editoria.idSite"
              :disabled="isUpdating"
              :items="siteGrupo"
              :filter="filtrarObjetos"
              label="Restrita ao Site"
              item-text="nome"
              item-value="id"
              :rules="[validacao.idSite == false || validacao.idSite]"
              autocomplete="disabled"
              :name="Math.random()"
              no-data-text
              clearable
              @change="validacao.idSite = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12>
            <v-text-field
              v-model="hierarquia"
              label="Hierarquia"
              disabled
              color="blue-grey lighten-2"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md10>
            <v-text-field
              autofocus
              v-model="editoria.nome"
              label="Nome"
              maxlength="60"
              hint="Obrigatório"
              :rules="[
                validacao.nome == false || validacao.nome,
                value => !!value || 'Obrigatório'
              ]"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="
                editoria.nome = pLetra(editoria.nome);
                validacao.nome = false;
              "
            ></v-text-field>
          </v-flex>

          <v-flex xs6 md2>
            <v-text-field
              v-model="editoria.ordem"
              label="Ordem"
              v-mask="'#######'"
              hint="Obrigatório - ordem desta editoria dentro da hierarquia acima: 1,2..."
              :rules="[
                validacao.ordem == false || validacao.ordem,
                value =>
                  !!value ||
                  'Obrigatório - ordem desta editoria dentro da hierarquia acima: 1,2...'
              ]"
              color="blue-grey lighten-2"
              @change="validacao.ordem = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md12>
            <v-textarea
              v-model="editoria.aliasInternet"
              label="Alias Internet"
              maxlength="1024"
              counter="1024"
              rows="3"
              :rules="[
                validacao.aliasInternet == false || validacao.aliasInternet,
                value =>
                  !value ||
                  (value.length > 10 && value.length < 4000) ||
                  'Alias Internet deve ter entre 10 e 1024 caracteres'
              ]"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.aliasInternet = false"
            ></v-textarea>
          </v-flex>

          <v-flex xs6 md3>
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="editoria.tempoLimpeza"
              label="Tempo de Limpeza"
              :allowNegative="false"
              :max="999"
              :rules="[
                validacao.tempoLimpeza == false || validacao.tempoLimpeza,
                value =>
                  (value && parseInt(value) <= 999) ||
                  'Verifique a tempo de limpeza'
              ]"
              :disabled="isUpdating"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.tempoLimpeza = false"
            ></v-currency-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-autocomplete
              v-model="editoria.tipo"
              :disabled="isUpdating"
              :items="tipos"
              :filter="filtrarObjetos"
              label="Tipo"
              hint="Obrigatório"
              :rules="[
                validacao.tipo == false || validacao.tipo,
                value => value != null || 'Obrigatório.'
              ]"
              item-text="nome"
              item-value="id"
              autocomplete="disabled"
              :name="Math.random()"
              no-data-text
              @change="validacao.tipo = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs6 md3>
            <v-switch
              v-model="editoria.situacao"
              :label="`${editoria.situacao == 1 ? 'Ativa' : 'Inativa'}`"
            ></v-switch>
          </v-flex>
          
          <v-flex xs6 md3>
            <v-switch
              v-model="editoria.salvaLimpeza"
              :label="`${editoria.salvaLimpeza == 1 ? 'Enviar Acervo' : 'Não Enviar Acervo'}`"
            ></v-switch>
          </v-flex>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">{
              validacao.mensagemErro
            }</v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn
        to="/editorial/editorias"
        color="orange lighten-2 white"
        id="btn-rsn"
        style="color:white"
      >
        <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar
      </v-btn>
      <v-btn
        :loading="isUpdating"
        color="blue white"
        id="btn-rsn"
        style="color:white"
        depressed
        @click="salvar('false')"
      >
        <v-icon id="icon-rsn" left>update</v-icon>Salvar
      </v-btn>
      <v-btn
        :loading="isUpdating"
        color="orange white"
        style="color:white"
        id="btn-rsn"
        outlined
        @click="salvar('retornar')"
      >
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>
      <v-btn
        :loading="isUpdating"
        color="blue white"
        id="btn-rsn"
        style="color:white"
        outlined
        @click="salvar('true')"
        v-if="direitoCriar"
      >
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Nova
      </v-btn>
    </v-card-actions>
    <v-snackbar
      v-model="snackbar"
      color="info"
      :timeout="5000"
      relative
      bottom
      left
      >{{ mensagem }}</v-snackbar
    >
    <v-snackbar
      v-model="snackErro"
      color="error"
      :timeout="5000"
      relative
      bottom
      left
      >{{ mensagem }}</v-snackbar
    >
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      editoria: {
        id: "",
        idSite: null,
        idSuperior: null,
        ordem: 0,
        nome: "",
        aliasInternet: "",
        tempoLimpeza: 0,
        tipo: 0,
        salvaLimpeza: 0,
        situacao: 1
      },
      validacao: {
        id: false,
        idSite: false,
        idSuperior: false,
        ordem: false,
        nome: false,
        aliasInternet: false,
        tempoLimpeza: false,
        tipo: false,
        salvaLimpeza: false,
        situacao: false,
        mensagemErro: false
      },

      hierarquia: "",
      siteGrupo: [],
      tipos: [
        { id: 0, nome: "Edição" },
        { id: 1, nome: "Internet" },
        { id: 2, nome: "Lixeira" },
        { id: 3, nome: "Produção" }
      ],

      idSitePrincipal: null,
      direitoCriar: false,
      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,
      isUpdating: false
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem =
          "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      this.isUpdating = true;
      try {
        var retorno = "";
        //se tiver id é um update
        if (this.id) {
          retorno = await api.put(
            process.env.VUE_APP_URL_WEB_API + "edt/editoria/" + this.id,
            this.editoria
          );
        } else {
          retorno = await api.post(
            process.env.VUE_APP_URL_WEB_API +
              "edt/editoria?idSite=" +
              this.idSitePrincipal,
            this.editoria
          );
          this.id = retorno.data.id;
          this.editoria.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "true") {
          this.$router.push(
            "/editorial/editoria" + (this.mae ? "/" + this.mae : "")
          ); //para alterar a url
          this.$router.go(
            "/editorial/editoria" + (this.mae ? "/" + this.mae : "")
          ); //para recarregar a página
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push("/editorial/editorias"); //para alterar a url
          }, 2000);
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem =
            "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem =
            "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },
    async carregarHierarquia() {
      //* Carrega a hierarquia da editoria - nomes das seções mãe
      var idMae = this.editoria.idSuperior;
      var itens = [];
      console.log("edt/editoria/"+idMae+"?idSite="+this.idSitePrincipal)
      if (idMae) {
        while (idMae) {
          const responseMae = await api.get(
            process.env.VUE_APP_URL_WEB_API +
              "edt/editoria/" +
              idMae +
              "?idSite=" +
              this.idSitePrincipal
              
          );
          if (responseMae.data) {
            idMae = responseMae.data.idSuperior;
            this.editoria.idSite = responseMae.data.idSite; //* Todas as seções filhas estarão no mesmo site da editoria de primeiro nível
            itens.push(responseMae.data.nome);
            if (!this.editoria.ordem) {
              this.editoria.ordem = parseInt(responseMae.data.filhas) + 1;
            }
          } else {
            idMae = null;
          }
        }
      } else {
        const responseMaes = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "edt/editoria/maes?idSite=" +
            this.idSitePrincipal
        );
        if (responseMaes.data) {
          if (!this.editoria.ordem) {
            this.editoria.ordem = parseInt(responseMaes.data.filhas) + 1;
          }
        }
      }
      itens.forEach(element => {
        this.hierarquia = element + "\\" + this.hierarquia;
      });
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    pLetra(text) {
      return util.pLetra(text);
    }
  },

  computed: {
    mae() {
      return this.$route.params.mae;
    }
  },

  async created() {
    this.id = this.$route.params.id ? this.$route.params.id : "";
    if (this.id == "null") {
      this.id = "";
    }

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito =
        element.modulo + "_" + element.grupo + "_" + element.direito;
      if (
        direito == "WEBSUITA_ADMIN_SUITA" ||
        direito == "EDITORIAL_EDITORIAS_CRIAR"
      ) {
        this.direitoCriar = true;
      }
    });
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

    //* Verifica se o site do usuário está em GRUPO - OBS: ou ele compartilha a informação ou deixa exclusiva para o seu site
    var idGrupoSite = this.$store.state.usuario.site_idGrupo;
    if (idGrupoSite != null) {
      this.siteGrupo = [
        {
          id: this.idSitePrincipal,
          nome: this.$store.state.usuario.site_nome
        }
      ];
    }

    try {
      //* Se é edição, carrega o elemento
      if (this.id == "") {
        this.POSSUI_DIREITO([
          "WEBSUITA_ADMIN_SUITA",
          "EDITORIAL_EDITORIAS_CRIAR"
        ]);
        this.editoria.idSuperior = this.mae
          ? this.mae == "null"
            ? null
            : this.mae
          : null;
        this.carregarHierarquia();
      } else {
        this.POSSUI_DIREITO([
          "WEBSUITA_ADMIN_SUITA",
          "EDITORIAL_EDITORIAS_EDITAR"
        ]);
        //* Carrega o elemento - somente se for público (idSite=null) ou for do site do usuário logado
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "edt/editoria/" +
            this.id +
            "?idSite=" +
            this.idSitePrincipal
        );
        if (!response.data) {
          this.$router.push("/editorial/editorias");
        } else {
          this.editoria = response.data;
          this.carregarHierarquia();
        }
      }
    } catch {
      this.mensagem = "Ocorreu um erro ao carregar os dados";
      this.snackErro = true;
    }
  }
};
</script>
