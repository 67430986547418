<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">open_with</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Faturamento de Venda Avulsa</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12 mb-4>
            <h4 class="overline font-weight-black font-italic">
              Filtro
            </h4>
            <v-divider></v-divider>
          </v-flex>

          <v-flex xs11 md6>
            <v-autocomplete
              autofocus
              v-model="operacao.idCliente"
              :items="bancas"
              :filter="filtrarObjetos"
              label="Banca"
              item-text="nome"
              item-value="id"
              hint="Obrigatório"
              :rules="[validacao.idCliente == false || validacao.idCliente]"
              no-data-text
              dense
              @change="
                limpaFiltro(true);
                validacao.idCliente = false;
              "
            ></v-autocomplete>
          </v-flex>
          <v-flex xs1 class="text-left">
            <v-spacer></v-spacer>
            <v-icon v-if="direitoBanca && operacao.idCliente != null" color="orange" @click="editarBanca" title="Editar Banca">account_circle</v-icon>
          </v-flex>

          <v-flex xs12 md5>
            <v-autocomplete
              v-model="idProduto"
              :items="produtos"
              :filter="filtrarObjetos"
              label="Produto"
              item-text="nome"
              item-value="id"
              hint="Obrigatório"
              :rules="[validacao.idProduto == false || validacao.idProduto, value => !!value || 'Obrigatório.']"
              no-data-text
              dense
              @change="
                limpaFiltro(true);
                validacao.idProduto = false;
              "
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md6>
            <v-autocomplete
              :disabled="isUpdating"
              v-model="idEntregador"
              :items="entregadores"
              :filter="filtrarObjetos"
              hint="Obrigatório"
              :rules="[validacao.idEntregador == false || validacao.idEntregador]"
              label="Entregador"
              item-text="nome"
              item-value="id"
              autocomplete="disabled"
              :name="Math.random()"
              no-data-text
              @change="
                limpaFiltro(true);
                validacao.idEntregador = false;
              "
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 offset-md1 md5>
            <v-autocomplete
              :disabled="isUpdating"
              v-model="idTipoBanca"
              :items="tiposBanca"
              :filter="filtrarObjetos"
              hint="Obrigatório"
              :rules="[validacao.idTipoBanca == false || validacao.idTipoBanca]"
              label="Tipo de Banca"
              item-text="nome"
              item-value="id"
              autocomplete="disabled"
              :name="Math.random()"
              no-data-text
              @change="
                limpaFiltro(true);
                validacao.idTipoBanca = false;
              "
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md6>
            <v-text-field
              v-model="operacao.descricao"
              label="Descrição da Operação"
              maxlength="180"
              hint="Obrigatório"
              :rules="[
                validacao.descricao == false || validacao.descricao,
                value => valida_nome(value) || 'Descrição inválida',
                value => value.length > 2 || 'Preencha a descrição da operação'
              ]"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="
                operacao.descricao = pLetra(operacao.descricao);
                validacao.descricao = false;
              "
            ></v-text-field>
          </v-flex>

          <v-flex xs12 offset-md1 md3>
            <v-menu v-model="mnuReparte" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="intervaloReparte"
                  label="Período do Reparte"
                  prepend-icon="event"
                  v-on="on"
                  color="blue-grey lighten-2"
                  clearable
                  @click:clear="
                    intervaloReparteUS = ['', ''];
                    limpaFiltro(false);
                  "
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker v-model="intervaloReparteUS" locale="pt-br" no-title range
                ><v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="
                    mnuReparte = false;
                    limpaFiltro(false);
                  "
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-flex>

          <v-flex xs6 md1>
            <v-autocomplete
              v-model="encalhe"
              :items="simnao"
              :filter="filtrarObjetos"
              label="Encalhe"
              item-text="nome"
              item-value="id"
              no-data-text
              clearable
              @change="limpaFiltro(false)"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs6 md1 text-right>
            <v-btn rounded outlined :loading="isUpdating" color="blue white" class="btn-rsn mt-4" style="color:white" depressed>
              <v-icon class="icon-rsn" @click="limpaFiltro(false)" title="Filtrar">search</v-icon>
            </v-btn>
          </v-flex>

          <v-flex xs8 md3 ml-4>
            <v-checkbox v-model="selectAll" :label="selectAll ? 'Desmarcar todos' : 'Marcar todos da página'" @change="marcarDesmarcarTodos"></v-checkbox>
          </v-flex>
          <v-flex xs12>
            <v-data-table
              mobile-breakpoint="0"
              :headers="headers"
              :items="registros"
              :options.sync="options"
              :no-data-text="mensagemPesquisa"
              :footer-props="rodape"
              :disable-sort="carregando"
              :disable-pagination="carregando"
            >
              <template v-slot:item.actions="{ item }">
                <v-icon v-if="item.id && !item.idParcela" color="blue" title="Selecionar" @click="ativarDesativarItem(item)">
                  {{ item.selecionado == 1 ? "check_box" : "check_box_outline_blank" }}
                </v-icon>
              </template>

              <template v-slot:item.dtEntrega="{ item }">
                <span v-html="formatDate(item.dtEntrega)"></span>
              </template>

              <template v-slot:item.soma="{ item }">
                {{ (item.reparte ? parseInt(item.reparte) : 0) + (item.adicional ? parseInt(item.adicional) : 0) }}
              </template>

              <template v-slot:item.saldo="{ item }">
                <span
                  style="font-weight: bold"
                  v-html="(item.reparte ? parseInt(item.reparte) : 0) + (item.adicional ? parseInt(item.adicional) : 0) - (item.encalhe ? parseInt(item.encalhe) : 0)"
                ></span>
              </template>

              <template v-slot:item.vlrReparte="{ item }">
                <span v-html="formatCurrency(item.reparte * item.vlrUnitario)"></span>
              </template>

              <template v-slot:item.vlrAdicional="{ item }">
                <span v-html="formatCurrency(item.adicional * item.vlrUnitario)"></span>
              </template>

              <template v-slot:item.vlrSoma="{ item }">
                <span v-html="formatCurrency((item.reparte + item.adicional) * item.vlrUnitario)"></span>
              </template>

              <template v-slot:item.vlrEncalhe="{ item }">
                <span v-html="formatCurrency(item.encalhe * item.vlrUnitario)"></span>
              </template>

              <template v-slot:item.vlrSaldo="{ item }">
                <span style="font-weight: bold" v-html="formatCurrency((item.reparte + item.adicional - item.encalhe) * item.vlrUnitario)"></span>
              </template>
            </v-data-table>
            <div class="text-xs-center pt-2 marg-bt">
              <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 7" circle></v-pagination>
            </div>
          </v-flex>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">
              {{ validacao.mensagemErro }}
            </v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn to="/assinaturas/venda-avulsa-repartes" color="orange lighten-2 white" depressed style="color:white">
        <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar
      </v-btn>
      <v-btn :loading="isUpdating" color="blue white" class="btn-rsn" style="color:white" depressed @click="faturamento">
        <v-icon class="icon-rsn" left>account_balance</v-icon>Faturamento
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      headers: [
        { text: "Ações", value: "actions", width: "110" },
        { text: "ID", value: "id" },
        { text: "Banca", value: "banca" },
        { text: "Data Reparte", value: "dtEntrega" },
        { text: "Prévia Padrão", value: "previaPadrao", align: "end" },
        {
          text: "Prévia Média",
          value: "previaMedia",
          divider: "true",
          align: "end"
        },
        { text: "REPARTE", value: "reparte", align: "end" },
        { text: "ADICIONAL", value: "adicional", align: "end" },
        { text: "Soma", value: "soma", divider: "true", align: "end" },
        { text: "ENCALHE", value: "encalhe", divider: "true", align: "end" },
        { text: "Saldo", value: "saldo", divider: "true", align: "end" },
        { text: "Valor Reparte", value: "vlrReparte", align: "end" },
        { text: "Valor Adicional", value: "vlrAdicional", align: "end" },
        { text: "Valor Soma", value: "vlrSoma", divider: "true", align: "end" },
        {
          text: "Valor Encalhe",
          value: "vlrEncalhe",
          divider: "true",
          align: "end"
        },
        { text: "Valor Saldo", value: "vlrSaldo", align: "end" },
        { text: "Fatura", value: "idParcela", divider: "true" }
      ],
      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50, 100],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },

      operacao: {
        id: "",
        idSite: this.$store.state.usuario.idSitePrincipal,
        tipo: 1,
        dtOperacao: new Date(),
        idUsuario: this.$store.state.usuario.id,
        dtCancelamento: null,
        descricao: "",
        idCliente: null,
        idDeptoCliente: null,
        origem: "AVU",
        vlrBruto: 0,
        vlrLiquido: 0,
        vlrTaxado: 0,
        nroParcelas: 1,
        duplicatas: 0,
        situacao: 1,
        mensal: 0,
        confirmada: 1,
        idContaC: null,
        idContaD: null
      },

      idProduto: null,
      idEntregador: null,
      idTipoBanca: null,

      mnuReparte: false,
      intervaloReparteUS: ["", ""],

      validacao: {
        idCliente: false,
        idProduto: false,
        idEntregador: false,
        idTipoBanca: false,
        descricao: false,
        mensagemErro: false
      },
      selectAll: 0,

      bancas: [],
      produtos: [],
      entregadores: [],
      tiposBanca: [],

      encalhe: null,
      simnao: [
        { id: 1, nome: "Sim" },
        { id: 0, nome: "Não" }
      ],

      registros: [],
      registrosPagina: 10,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      carregando: true,
      ordenacao: "",
      ordem: "",
      options: {},
      mensagemPesquisa: "",

      idSitePrincipal: null,
      direitoBanca: false,
      elementoValid: true,
      id: "",
      inicial: true,
      mensagem: "",
      snackbar: false,
      snackErro: false,
      search: "",

      isUpdating: false
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    limpaFiltro(aguardar) {
      this.paginaAtual = 1;
      this.pesquisaDados(aguardar);
    },
    async pesquisaDados(aguardar) {
      //sinaliza que está carregando
      await util.limparObjeto(this.validacao, false);
      this.registros = [];

      this.parametrosPesquisa = "";
      if (this.operacao.idCliente) {
        this.parametrosPesquisa += "&idBanca=" + this.operacao.idCliente;
      }
      if (this.idProduto) {
        this.parametrosPesquisa += "&idProduto=" + this.idProduto;
      }
      if (this.idEntregador) {
        this.parametrosPesquisa += "&idEntregador=" + this.idEntregador;
      }
      if (this.idTipoBanca) {
        this.parametrosPesquisa += "&idTipoBanca=" + this.idTipoBanca;
      }
      if (this.intervaloReparteUS && this.intervaloReparteUS.length > 1 && util.valida_data(this.intervaloReparteUS[0]) && util.valida_data(this.intervaloReparteUS[1])) {
        this.parametrosPesquisa += "&dtRepInicio=" + this.intervaloReparteUS[0];
        this.parametrosPesquisa += "&dtRepFim=" + this.intervaloReparteUS[1];
      } else {
        if (!aguardar) {
          this.mensagem = "Selecione o período";
          this.snackErro = true;
        }
        return;
      }

      if (this.encalhe != null) {
        this.parametrosPesquisa += "&encalhe=" + this.encalhe;
      }

      this.carregando = true;
      this.mensagemPesquisa = "Carregando...";
      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "ass/venda-avulsa-item/itens?pagina=" +
            this.paginaAtual +
            "&tamanho=" +
            this.registrosPagina +
            this.parametrosPesquisa +
            "&ordenacao=" +
            this.ordenacao +
            "&ordem=" +
            this.ordem
        );

        //seta as variaveis com o retorno
        this.registros = response.data.data;
        this.selectAll = 0;

        this.totalRegistros = response.data.total;
        this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
        this.rodape.pageText = this.totalRegistros + " itens";
        //fecha o loading
        this.carregando = false;
        //se não achar registro seta a mensagem senão deixa em branco
        this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else {
          this.mensagemPesquisa = "Erro ao buscar dados: " + e;
        }
      }
    },
    editarBanca() {
      if (this.direitoBanca && this.operacao.idCliente) {
        window.open("/assinaturas/banca/" + this.operacao.idCliente, "_blank");
      }
    },
    async carregarProdutos() {
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/produto/lista?vendaAvulsa=1");
      if (response.data) {
        this.produtos = response.data;
        if (this.produtos.length == 1) {
          this.idProduto = this.produtos[0].id;
        }
      }
    },
    async carregarBancas() {
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/venda-avulsa-item/bancas");
      if (response.data) {
        this.bancas = [{ id: 0, nome: "TODAS" }];
        response.data.forEach(element => {
          element.nome = element.nome + " - " + element.identificacao;
          this.bancas.push(element);
        });
      }
    },
    async carregarEntregadores() {
      try {
        //* Busca entregadores ativos, pois se está inativo, não deveria estar associado a um CEP
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/entregador/lista?vendaAvulsa=1");
        this.entregadores = response.data;
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de Entregadores";
        this.snackErro = true;
      }
    },
    async carregarTiposBanca() {
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/tipo-banca/lista");
      if (response.data) {
        this.tiposBanca = response.data;
      }
    },

    async faturamento() {
      await util.limparObjeto(this.validacao, false);

      var erro = false;
      var registrosSelec = [];
      this.registros.forEach(element => {
        if (element.selecionado && !element.idParcela && parseInt(element.reparte) + parseInt(element.adicional) > 0) {
          registrosSelec.push({ idCliente: element.idCliente, id: element.id });
        } else {
          element.selecionado = 0;
        }
      });
      if (registrosSelec.length == 0) {
        erro = true;
        this.validacao.mensagemErro = "Selecione pelo menos 1 item que possua saldo";
      }
      if (!this.$refs.formElemento.validate() || erro) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }
      var idCliente = null;
      var selecionados = [];

      for (let index = 0; index < registrosSelec.length; index++) {
        const element = registrosSelec[index];
        if (!idCliente) {
          idCliente = element.idCliente;
        }
        if (idCliente != element.idCliente) {
          //* Mudou o cliente, commita o anterior
          try {
            this.isUpdating = true;
            var operacaoCommit = Object.assign({}, this.operacao);
            operacaoCommit.idCliente = idCliente;
            operacaoCommit.itens = selecionados;
            operacaoCommit.dtInicio = this.intervaloReparteUS[0];
            operacaoCommit.dtFim = this.intervaloReparteUS[1];

            await api.post(process.env.VUE_APP_URL_WEB_API + "fin/operacao/vendaAvulsa?idSite=" + this.idSitePrincipal, operacaoCommit);
          } catch (e) {
            if (e.toString().includes("status code 400")) {
              this.mensagem = "SESSÃO EXPIRADA";
              this.snackErro = true;
            } else if (e.response && e.response.data) {
              util.validaErros(this.validacao, e.response.data);
              this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
              this.snackErro = true;
            } else {
              this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
              this.snackErro = true;
            }
          } finally {
            this.isUpdating = false;
          }
          selecionados = [];
        }
        idCliente = element.idCliente;
        selecionados.push(element.id);
      }

      //* Commita o último
      this.isUpdating = true;
      try {
        operacaoCommit = Object.assign({}, this.operacao);
        operacaoCommit.idCliente = idCliente;
        operacaoCommit.itens = selecionados;
        operacaoCommit.dtInicio = this.intervaloReparteUS[0];
        operacaoCommit.dtFim = this.intervaloReparteUS[1];

        await api.post(process.env.VUE_APP_URL_WEB_API + "fin/operacao/vendaAvulsa?idSite=" + this.idSitePrincipal, operacaoCommit);

        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        this.limpaFiltro(false);
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },

    marcarDesmarcarTodos() {
      this.registros.forEach(element => {
        if (!element.idParcela) {
          element.selecionado = this.selectAll;
        }
      });
      this.validacao.mensagemErro = false;
    },
    ativarDesativarItem(item) {
      if (!item.idParcela) {
        var dadoIndex = this.registros.indexOf(item);
        var dadoItem = Object.assign({}, item);
        dadoItem.selecionado = dadoItem.selecionado == 1 ? 0 : 1;
        Object.assign(this.registros[dadoIndex], dadoItem);
      }
      this.validacao.mensagemErro = false;
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    pLetra(text) {
      return util.pLetra(text);
    },
    valida_nome(text) {
      return util.valida_nome(text);
    },
    formatDate(date) {
      return util.formatDate(date);
    },
    formatDateUS(date) {
      return util.formatDateUS(date);
    },
    formatCurrency(valor) {
      return util.formatCurrency(valor);
    }
  },
  beforeMount() {
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_VENDA_AVULSA_FATURAMENTO_ACESSO"]);
  },
  computed: {
    intervaloReparte() {
      var retorno = "";
      if (this.intervaloReparteUS && this.intervaloReparteUS.length > 1) {
        this.intervaloReparteUS.forEach(element => {
          if (element) {
            retorno += (retorno ? " - " : "") + util.formatDate(element);
          }
        });
      }
      return retorno;
    }
  },
  async created() {
    this.id = this.$route.params.id ? this.$route.params.id : "";

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_BANCAS_EDITAR") {
        this.direitoBanca = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_VENDA_AVULSA_TABELA_EDITAR") {
        this.direitoTabelaVA = true;
      }
    });

    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

    this.carregarBancas();
    this.carregarProdutos();
    this.carregarEntregadores();
    this.carregarTiposBanca();
  },
  watch: {
    paginaAtual: function() {
      this.pesquisaDados();
    },
    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }

        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }

        if (!this.inicial) {
          this.pesquisaDados();
        } else {
          this.mensagemPesquisa = "MONTE SEU FILTRO PARA VISUALIZAR OS REPARTES...";
          this.inicial = false;
        }
      }
    }
  }
};
</script>
