<template>
  <v-container fluid grid-list-md>
    <v-card>
      <v-toolbar color="#333333">
        <v-icon color="white" class="mr-2">flip</v-icon>
        <v-toolbar-title style="color:white;" class="font-weight-light">Edições</v-toolbar-title>
        <v-spacer></v-spacer>
        <router-link to="/flip/edicao" style="text-decoration: none" v-if="direitoCriar">
          <v-btn color="success" fab small>
            <v-icon color="white" title="Incluir">add</v-icon>
          </v-btn>
        </router-link>
      </v-toolbar>
      <div class="px-6 mt-2">
        <v-layout wrap>
          <v-flex xs12 md2>
            <v-menu v-model="mnuDtInicio" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dtInicio"
                  autofocus
                  label="Período"
                  prepend-icon="event"
                  v-on="on"
                  color="blue-grey lighten-2"
                  @click:clear="dtInicioUS = ['', '']"
                  readonly
                  class="mt-2 mb-2 elevation-0"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dtInicioUS" locale="pt-br" no-title range style="background-color: #ddd"
                ><v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="
                    mnuDtInicio = false;
                    limpaFiltro();
                  "
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs12 offset-md1 md1>
            <v-autocomplete
              v-if="$store.state.usuario.site_publicacaoLegal"
              v-model="filtroPL"
              :filter="filtrarObjetos"
              :items="itensPL"
              label="Publicação Legal"
              item-text="nome"
              item-value="id"
              @change="limpaFiltro"
              class="mt-2 mb-2 elevation-0"
            ></v-autocomplete>
          </v-flex>
          <v-flex xs12 md2>
            <v-text-field
              v-if="$store.state.usuario.site_publicacaoLegal && filtroPL != 0"
              background-color="white"
              class="mt-2 mb-2 ml-4 elevation-0"
              v-model="filtroCNPJ"
              append-icon="search"
              label="Pesquisar CNPJ..."
              v-mask="'##############'"
              placeholder="Pesquisa pelo CNPJ no período"
              hide-details
              @keyup.enter="limpaFiltro"
              @click:append="limpaFiltro"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              background-color="white"
              class="mt-2 mb-2 ml-4 elevation-0"
              v-model="filtroEdicao"
              append-icon="search"
              label="Pesquisar nome edição..."
              placeholder="Pesquisa pelo nome da edição no período"
              hide-details
              @keyup.enter="limpaFiltro"
              @click:append="limpaFiltro"
            ></v-text-field>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs12 md3>
            <v-text-field
              background-color="white"
              class="mt-2 mb-2 ml-4 elevation-0"
              v-model="filtroTexto"
              append-icon="search"
              label="Pesquisar texto nas edições..."
              placeholder="Pesquisa pelo texto nas edições do período"
              hide-details
              @keyup.enter="limpaFiltro"
              @click:append="limpaFiltro"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-progress-linear :active="carregando" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>

        <v-data-iterator :items="registros" :items-per-page="12" hide-default-footer :no-data-text="mensagemPesquisa" class="text-center">
          <template v-slot:default="props">
            <v-row>
              <v-col v-for="item in props.items" :key="item.name" cols="12" sm="4" md="3" lg="2">
                <v-card class="mx-auto text-left" max-width="220">
                  <v-layout align-center>
                    <v-flex xs1 class="mb-6 ml-2" style="padding-bottom: 0px;">
                      <router-link :to="'/flip/edicao/' + item._id" target="_blank" style="text-decoration: none" v-if="direitoEditar">
                        <v-icon color="blue" title="Editar">edit</v-icon>
                      </router-link>
                      <v-spacer></v-spacer>
                      <v-icon v-if="direitoExcluir" color="red lighten-3" title="Excluir" @click="exclui(item)">cancel</v-icon>
                      <v-spacer></v-spacer>
                      <v-icon color="blue lighten-2" title="Visualizar Flip" @click="visualizarFlip(item)">flip</v-icon>
                      <v-spacer></v-spacer>
                      <v-btn class="mt-1" fab x-small max-height="24" max-width="24" title="Visualizar PDF" @click="visualizarPDF(item)">pdf</v-btn>
                      <v-icon
                        v-if="$store.state.usuario.site_publicacaoLegal"
                        :color="item.publicacaoLegal ? 'green lighten-1' : 'grey lighten-1'"
                        class="mt-2"
                        :title="item.publicacaoLegal ? 'Desmarcar Publicação Legal' : 'Marcar como Publicação Legal'"
                        @click="ativaDesativaPL(item)"
                        >gavel</v-icon
                      >
                      <v-icon :color="item.assinada ? 'green lighten-1' : 'grey lighten-1'" class="mt-2" :title="item.assinada ? 'Assinada' : 'Não assinada'">verified_user</v-icon>
                    </v-flex>
                    <v-flex xs10>
                      <div class="text-center">
                        <a target="_blank" class="estilo-link-site" @click="visualizarFlip(item)">
                          <img class="img-responsive" width="156" height="196" v-bind:src="item.capa" title="Abrir" />
                        </a>
                      </div>
                    </v-flex>
                  </v-layout>

                  <v-card-title class="mt-n4">
                    <h5>{{ item.edicaoNome }}</h5>
                  </v-card-title>

                  <v-card-subtitle>
                    Edição Nº {{ item.edicaoNumero }}<br />
                    Data Edição: {{ formatDate(item.edicaoData) }}<br />
                    <span style="font-size: 12px;">
                      Publicar em:
                      {{ formatDateTime(item.edicaoDataEntrada) }} </span
                    ><br />
                    <span v-if="item.publicacaoLegal && item.cnpj" style="font-size: 12px;">
                      CNPJ:
                      {{ formatCNPJ(item.cnpj) }}
                    </span>

                    <v-divider v-if="item.paginasBusca.length"></v-divider>

                    <div v-if="item.paginasBusca.length">
                      <v-data-iterator :items="item.paginasBusca" :items-per-page="24" hide-default-footer no-data-text>
                        <template v-slot:default="props">
                          <v-row class="mt-2 ml-n4 mb-n8">
                            <div class="col-xs-1" v-for="item in props.items" :key="item.paginasNumero">
                              <a href="#" @click="abrirTexto(item)" color="grey lighten-3" v-if="item.paginasNumero"
                                ><span style="font-size: 12px; margin-left: 4px">{{ item.paginasNumero }}</span></a
                              >
                            </div>
                          </v-row>
                        </template>
                      </v-data-iterator>
                      <br />
                    </div>
                  </v-card-subtitle>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
        <div class="px-6 mt-2">
          <v-layout wrap>
            <v-flex xs10 class="text-xs-center">
              <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 7" circle></v-pagination>
            </v-flex>
            <v-flex xs2 class="text-right">
              <v-btn-toggle class="mt-3">
                <v-btn x-small text>
                  {{ "Total: " + tamanhoRegistros }}
                </v-btn>
              </v-btn-toggle>
            </v-flex>
          </v-layout>
        </div>
      </div>
    </v-card>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-dialog v-model="dialogFlip" width="70vw">
      <v-card height="90vh">
        <Flip :urlFlip="urlFlip" :dialogFlip.sync="dialogFlip" />
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogTexto" max-width="1000">
      <v-card>
        <v-toolbar color="blue lighten-2">
          <span class="headline">{{ textoTitulo }} </span>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogTexto = false">
            <v-icon>cancel</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-container fluid grid-list-md>
            <v-layout wrap>
              <v-flex xs12 md12>
                <v-textarea v-model="textoFlip" counter rows="10" color="blue-grey lighten-2"></v-textarea>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="dialogConfirm" max-width="350">
      <v-card>
        <v-toolbar>
          <span class="headline">Confirmação</span>
        </v-toolbar>
        <v-card-text v-html="msgConfirm"></v-card-text>
        <v-card-actions>
          <v-btn color="red darken-1" text @click="dialogConfirm = false">Não</v-btn>
          <v-btn color="green darken-1" text @click="excluir">Sim</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Flip from "@/views/flip/Flip.vue";
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  components: {
    Flip
  },
  data() {
    return {
      direitoCriar: false,
      direitoEditar: false,
      direitoExcluir: false,

      dtInicioUS: ["", ""],
      mnuDtInicio: false,
      filtroPL: null,
      filtroCNPJ: "",
      filtroEdicao: "",
      filtroTexto: "",

      itensPL: [
        { id: null, nome: "" },
        { id: 1, nome: "Somente" },
        { id: 0, nome: "Exceto" }
      ],

      dialogConfirm: false,
      msgConfirm: "",
      itemExcluir: null,

      dialogFlip: false,
      urlFlip: "",

      dialogTexto: false,
      textoTitulo: "",
      textoFlip: "",

      snackbar: false,
      snackErro: false,
      mensagem: "",

      paginaAtual: 1,
      registrosPagina: 12,
      totalRegistros: 0,
      tamanhoRegistros: 0,
      totalPaginas: 0,

      idSitePrincipal: null,
      mensagemPesquisa: "",
      registros: [],
      carregando: true
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO", "OCULTAR_MENU"]),

    limpaFiltro() {
      this.paginaAtual = 1;
      this.pesquisaDados();
    },
    async pesquisaDados() {
      this.registros = [];
      this.tamanhoRegistros = 0;
      this.mensagemPesquisa = "";

      var inic = "";
      var fim = "";
      if (this.dtInicioUS && this.dtInicioUS.length > 1) {
        inic = this.dtInicioUS[0];
        fim = this.dtInicioUS[1];
      }
      if (!inic || !fim) {
        this.mensagem = "Verifique o período";
        this.snackErro = true;
        return;
      }
      var dtInic = util.parseDateUS(this.dtInicioUS[0]);
      var dtFim = util.parseDateUS(this.dtInicioUS[1]);
      if (dtInic > dtFim) {
        this.mensagem = "Data inicial não pode ser superior a data final";
        this.snackErro = true;
        return;
      }

      //sinaliza que está carregando
      this.carregando = true;

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "flip/carregaedicoes?idSite=" +
            this.idSitePrincipal +
            "&idEdicao=" +
            "&dataInicioPesq=" +
            inic +
            "&dataFimPesq=" +
            fim +
            "&edicaoNome=" +
            this.filtroEdicao +
            "&textoPesquisa=" +
            this.filtroTexto +
            (this.filtroPL != null ? "&publicacaoLegal=" + this.filtroPL : "") +
            (this.filtroPL != 0 && this.filtroCNPJ ? "&cnpj=" + this.filtroCNPJ : "") +
            "&pagina=" +
            this.paginaAtual
        );

        //seta as variaveis com o retorno
        response.data.data.forEach(registro => {
          // if (registro.edicaoTamanho > 1024 * 1024 * 1024 * 1024) {
          //   registro.edicaoTamanho =
          //     (registro.edicaoTamanho / 1024 / 1024 / 1024 / 1024).toFixed(2) +
          //     " TB";
          // } else if (registro.edicaoTamanho > 1024 * 1024 * 1024) {
          //   registro.edicaoTamanho =
          //     (registro.edicaoTamanho / 1024 / 1024 / 1024).toFixed(2) + " GB";
          // } else if (registro.edicaoTamanho > 1024 * 1024) {
          //   registro.edicaoTamanho =
          //     (registro.edicaoTamanho / 1024 / 1024).toFixed(2) + " MB";
          // } else if (registro.edicaoTamanho > 1024) {
          //   registro.edicaoTamanho =
          //     (registro.edicaoTamanho / 1024).toFixed(2) + " KB";
          // } else if (registro.edicaoTamanho > 0) {
          //   registro.edicaoTamanho = registro.edicaoTamanho + " B";
          // }

          registro.capa = `${process.env.VUE_APP_URL_WEB_API}arquivos/${this.idSitePrincipal}/flip/${registro.edicaoData}/${registro.edicaoNomePasta}/jpg/${
            registro.Paginas[0].paginasNumero
          }.jpeg?${new Date().getTime()}`;

          registro.edicaoPaginasBusca.sort(function(a, b) {
            if (a > b) {
              return 1;
            }
            if (a < b) {
              return -1;
            }
            return 0;
          });

          registro.paginasBusca = [];
          registro.edicaoPaginasBusca.forEach(busca => {
            registro.Paginas.forEach(pagina => {
              if (pagina.paginasNumero == busca) {
                registro.paginasBusca.push({
                  edicaoNome: registro.edicaoNome,
                  edicaoNumero: registro.edicaoNumero,
                  edicaoData: util.formatDate(registro.edicaoData),
                  paginasNumero: pagina.paginasNumero,
                  paginasTexto: pagina.paginasTexto,
                  edicaoNomePasta: registro.edicaoNomePasta
                });
              }
            });
          });

          this.registros.push(registro);
        });

        this.totalRegistros = response.data.total.total;

        this.tamanhoRegistros = response.data.total.tamanho;
        if (this.tamanhoRegistros > 1024 * 1024 * 1024 * 1024) {
          this.tamanhoRegistros = (this.tamanhoRegistros / 1024 / 1024 / 1024 / 1024).toFixed(2) + " TB";
        } else if (this.tamanhoRegistros > 1024 * 1024 * 1024) {
          this.tamanhoRegistros = (this.tamanhoRegistros / 1024 / 1024 / 1024).toFixed(2) + " GB";
        } else if (this.tamanhoRegistros > 1024 * 1024) {
          this.tamanhoRegistros = (this.tamanhoRegistros / 1024 / 1024).toFixed(2) + " MB";
        } else if (this.tamanhoRegistros > 1024) {
          this.tamanhoRegistros = (this.tamanhoRegistros / 1024).toFixed(2) + " KB";
        } else if (this.tamanhoRegistros > 0) {
          this.tamanhoRegistros = this.tamanhoRegistros + " B";
        }

        this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);

        //fecha o loading
        this.carregando = false;
        //se não achar registro seta a mensagem senão deixa em branco
        this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else {
          this.mensagemPesquisa = "Erro ao buscar dados: " + e;
        }
      }
    },
    exclui(item) {
      this.itemExcluir = item;
      this.msgConfirm = "<br />Tem certeza que deseja EXCLUIR?<br /><span style='color:red'>(esta operação não pode ser desfeita)</span>";
      this.dialogConfirm = true;
    },
    async excluir() {
      this.dialogConfirm = false;

      try {
        await api.post(process.env.VUE_APP_URL_WEB_API + "flip/excluiedicao?idSite=" + this.idSitePrincipal, {
          idEdicao: this.itemExcluir._id
        });
        this.mensagem = "Item foi excluído";
        this.snackbar = true;
        this.pesquisaDados();
      } catch (e) {
        this.mensagem = e.response.data[0].mensagem;
        this.snackErro = true;
      }
    },
    async ativaDesativaPL(item) {
      try {
        await api.put(process.env.VUE_APP_URL_WEB_API + "flip/ativaDesativaPL?idSite=" + this.idSitePrincipal, {
          idEdicao: item._id,
          publicacaoLegal: !item.publicacaoLegal
        });
        var itemIndex = this.registros.indexOf(item);
        var edicaoItem = Object.assign({}, item);
        edicaoItem.publicacaoLegal = edicaoItem.publicacaoLegal ? false : true;
        Object.assign(this.registros[itemIndex], edicaoItem);

        this.mensagem = "Publicação Legal foi alterada";
        this.snackbar = true;
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },

    visualizarPDF(item) {
      var caminhoPDF = `/${this.idSitePrincipal}/flip/${item.edicaoData}/${item.edicaoNomePasta}/${item.edicaoNomePasta}-${item.edicaoData}.pdf`;
      window.open(process.env.VUE_APP_URL_WEB_API + "fin/arquivo/pdf?caminho=" + caminhoPDF, "_blank");
    },
    visualizarFlip(item) {
      this.urlFlip = `/${this.idSitePrincipal}/flip/${item.edicaoData}/${item.edicaoNomePasta}&paginas=${item.Paginas.length}`;
      this.dialogFlip = true;
    },
    closeFlip() {
      this.dialogFlip = false;
      this.urlFlip = "";
    },
    abrirTexto(item) {
      this.textoFlip = item.paginasTexto;
      this.textoTitulo = `Texto encontrado na página ${item.paginasNumero} da edição '${item.edicaoNome}' número '${item.edicaoNumero}' de '${item.edicaoData}'`;
      this.dialogTexto = true;
    },

    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    formatDate(date) {
      return util.formatDate(date);
    },
    formatDateTime(datetime) {
      return util.formatDateTime(datetime);
    },
    formatCNPJ(cnpj) {
      return util.formatIdentificacao(cnpj);
    }
  },
  computed: {
    dtInicio() {
      var retorno = "";
      if (this.dtInicioUS && this.dtInicioUS.length > 1) {
        var dtInic = util.parseDateUS(this.dtInicioUS[0]);
        var dtFim = util.parseDateUS(this.dtInicioUS[1]);
        if (dtInic > dtFim) {
          retorno = "";
        } else {
          retorno = util.formatDate(this.dtInicioUS[0]) + " - " + util.formatDate(this.dtInicioUS[1]);
        }
      }
      return retorno;
    }
  },
  beforeMount() {
    this.OCULTAR_MENU();
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FLIP_EDIÇÕES_ACESSO"]);

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FLIP_EDIÇÕES_CRIAR") {
        this.direitoCriar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FLIP_EDIÇÕES_EDITAR") {
        this.direitoEditar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FLIP_EDIÇÕES_EXCLUIR") {
        this.direitoExcluir = true;
      }
    });
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

    var dtDia1 = new Date();
    dtDia1.setDate(1);

    var dtDia31 = new Date();
    dtDia31.setMonth(dtDia31.getMonth() + 1);
    dtDia31.setDate(1);
    dtDia31.setDate(dtDia31.getDate() - 1);

    this.dtInicioUS = [util.dateToStringUS(dtDia1), util.dateToStringUS(dtDia31)];

    this.pesquisaDados();
  },
  watch: {
    paginaAtual() {
      this.pesquisaDados();
    }
  }
};
</script>
