<template>
  <div id="app">
    <v-app id="inspire">
      <v-card>
        <v-toolbar color="#333333">
          <v-icon color="white" class="mr-2">toc</v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light">Réguas de Cobrança</v-toolbar-title>
          <v-spacer></v-spacer>
          <router-link
            :to="($route.path.includes('assinaturas') ? '/assinaturas' : $route.path.includes('comercial') ? '/comercial' : '/financeiro') + '/etapa-cobranca-grupo'"
            style="text-decoration: none"
            v-if="direitoCriar"
          >
            <v-btn color="success" fab small>
              <v-icon color="white" title="Incluir">add</v-icon>
            </v-btn>
          </router-link>
        </v-toolbar>
        <div class="px-6 mt-4">
          <v-layout wrap>
            <v-spacer></v-spacer>
            <v-flex xs12 md5>
              <v-text-field
                background-color="white"
                class="mt-2 mb-2 elevation-0"
                v-model="filtroPesquisaGrupo"
                append-icon="search"
                label="Digite para pesquisar..."
                placeholder="Pesquisa pelo nome"
                hide-details
                @keyup.enter="limpaFiltroGrupo"
                @click:append="limpaFiltroGrupo"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-data-table mobile-breakpoint="0" :headers="headersGrupo" :items="registrosGrupo" :options.sync="optionsGrupo" :no-data-text="mensagemPesquisa" :footer-props="rodape">
            <template v-slot:[getItemActions()]="{ item }">
              <router-link
                :to="($route.path.includes('assinaturas') ? '/assinaturas' : $route.path.includes('comercial') ? '/comercial' : '/financeiro') + '/etapa-cobranca-grupo/' + item.id"
                style="text-decoration: none"
                v-if="direitoEditar"
              >
                <v-icon color="blue" title="Editar">edit</v-icon>
              </router-link>
            </template>

            <template v-slot:[getItemNovas()]="{ item }">
              <span
                v-html="
                  item.novas == null
                    ? 'Assinaturas Manual'
                    : item.novas == 1
                    ? 'Assinaturas Novas'
                    : item.novas == 0
                    ? 'Assinaturas Renovadas'
                    : item.novas == 2
                    ? 'Comercial'
                    : 'Financeiro'
                "
              ></span>
            </template>

            <template v-slot:[getItemSituacao()]="{ item }">
              <span v-html="item.situacao == 1 ? 'Ativo' : '<strong>Inativo</strong>'"></span>
            </template>
          </v-data-table>
          <div class="text-xs-center pt-2 marg-bt">
            <v-pagination v-model="paginaAtualGrupo" :length="totalPaginasGrupo" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 7" circle></v-pagination>
          </div>
        </div>
      </v-card>

      <v-card>
        <v-toolbar color="#333333">
          <v-icon color="white" class="mr-2">toc</v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light">Etapas de Cobrança</v-toolbar-title>
          <v-spacer></v-spacer>
          <router-link
            :to="($route.path.includes('assinaturas') ? '/assinaturas' : $route.path.includes('comercial') ? '/comercial' : '/financeiro') + '/etapa-cobranca'"
            style="text-decoration: none"
            v-if="direitoCriar"
          >
            <v-btn color="success" fab small>
              <v-icon color="white" title="Incluir">add</v-icon>
            </v-btn>
          </router-link>
        </v-toolbar>
        <div class="px-6 mt-4">
          <v-layout wrap>
            <v-spacer></v-spacer>
            <v-flex xs12 md5>
              <v-text-field
                background-color="white"
                class="mt-2 mb-2 elevation-0"
                v-model="filtroPesquisa"
                append-icon="search"
                label="Digite para pesquisar..."
                placeholder="Pesquisa pela régua ou nome"
                hide-details
                @keyup.enter="limpaFiltro"
                @click:append="limpaFiltro"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-data-table
            mobile-breakpoint="0"
            :headers="headers"
            :items="registros"
            :options.sync="options"
            :no-data-text="mensagemPesquisa"
            :footer-props="rodape"
            disable-sort
            disable-pagination
            group-by="regua"
          >
            <template v-slot:[getItemActions()]="{ item }">
              <router-link
                :to="($route.path.includes('assinaturas') ? '/assinaturas' : $route.path.includes('comercial') ? '/comercial' : '/financeiro') + '/etapa-cobranca/' + item.id"
                style="text-decoration: none"
                v-if="direitoEditar"
              >
                <v-icon color="blue" title="Editar">edit</v-icon>
              </router-link>
            </template>

            <template v-slot:[getItemDialer()]="{ item }">
              <span v-html="item.dialer == 1 ? 'Sim' : ''"></span>
            </template>

            <template v-slot:[getItemEmail()]="{ item }">
              <span v-html="item.email == 1 ? 'Sim' : ''"></span>
            </template>

            <template v-slot:[getItemCorte()]="{ item }">
              <span v-html="item.corte == 1 ? 'Sim' : ''"></span>
            </template>

            <template v-slot:[getItemCancelamento()]="{ item }">
              <span v-html="item.cancelamento == 1 ? 'Sim' : ''"></span>
            </template>

            <template v-slot:[getItemSituacao()]="{ item }">
              <span v-html="item.situacao == 1 ? 'Ativo' : '<strong>Inativo</strong>'"></span>
            </template>
          </v-data-table>
          <div class="text-xs-center pt-2 marg-bt">
            <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 7" circle></v-pagination>
          </div>
        </div>
      </v-card>
    </v-app>
  </div>
</template>

<script>
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      headersGrupo: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Nome", value: "nome" },
        { text: "Tipo", value: "novas" },
        { text: "Situação", value: "situacao" }
      ],

      headers: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Régua", value: "regua" },
        { text: "Nome", value: "nome" },
        { text: "Início", value: "inicio" },
        { text: "Fim", value: "fim" },
        { text: "Discagem", value: "dialer" },
        { text: "E-mail", value: "email" },
        { text: "Corte", value: "corte" },
        { text: "Cancelamento", value: "cancelamento" },
        { text: "Situação", value: "situacao" }
      ],
      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },

      direitoCriar: false,
      direitoEditar: false,
      mensagemPesquisaGrupo: "",
      filtroPesquisaGrupo: "",
      registrosGrupo: [],
      registrosPaginaGrupo: 5,
      totalRegistrosGrupo: 0,
      totalPaginasGrupo: 0,
      paginaAtualGrupo: 1,
      carregandoGrupo: true,
      ordenacaoGrupo: "",
      ordemGrupo: "",

      mensagemPesquisa: "",
      filtroPesquisa: "",
      registros: [],
      registrosPagina: 5,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      carregando: true,
      ordenacao: "",
      ordem: "",

      options: {},
      optionsGrupo: {}
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    getItemActions() {
      return "item.actions";
    },
    getItemNovas() {
      return "item.novas";
    },
    getItemSituacao() {
      return "item.situacao";
    },
    getItemDialer() {
      return "item.dialer";
    },
    getItemEmail() {
      return "item.email";
    },
    getItemCorte() {
      return "item.corte";
    },
    getItemCancelamento() {
      return "item.cancelamento";
    },

    limpaFiltro() {
      this.paginaAtual = 1;
      this.pesquisaDados();
    },
    async pesquisaDados() {
      this.registros = [];
      //sinaliza que está carregando
      this.carregando = true;
      this.mensagemPesquisa = "Carregando...";

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "ass/etapa-cobranca?idSite=" +
            this.$store.state.usuario.idSitePrincipal +
            "&pagina=" +
            this.paginaAtual +
            "&tamanho=" +
            this.registrosPagina +
            "&filtro=" +
            this.filtroPesquisa +
            "&ordenacao=" +
            this.ordenacao +
            "&ordem=" +
            this.ordem +
            (this.$route.path.includes("assinaturas") ? "&assinaturas=1" : this.$route.path.includes("comercial") ? "&comercial=1" : "")
        );

        //seta as variaveis com o retorno
        this.registros = response.data.data;
        this.totalRegistros = response.data.total;
        this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
        this.rodape.pageText = this.totalRegistros + " itens";
        //fecha o loading
        this.carregando = false;
        //se não achar registro seta a mensagem senão deixa em branco
        this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else {
          this.mensagemPesquisa = "Erro ao buscar dados: " + e;
        }
      }
    },
    limpaFiltroGrupo() {
      this.paginaAtualGrupo = 1;
      this.pesquisaDadosGrupo();
    },
    async pesquisaDadosGrupo() {
      this.registrosGrupo = [];
      //sinaliza que está carregando
      this.carregandoGrupo = true;
      this.mensagemPesquisaGrupo = "Carregando...";

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "ass/etapa-cobranca-grupo?idSite=" +
            this.$store.state.usuario.idSitePrincipal +
            "&pagina=" +
            this.paginaAtualGrupo +
            "&tamanho=" +
            this.registrosPaginaGrupo +
            "&filtro=" +
            this.filtroPesquisaGrupo +
            "&ordenacao=" +
            this.ordenacaoGrupo +
            "&ordem=" +
            this.ordemGrupo +
            (this.$route.path.includes("assinaturas") ? "&assinaturas=1" : this.$route.path.includes("comercial") ? "&comercial=1" : "")
        );

        //seta as variaveis com o retorno
        this.registrosGrupo = response.data.data;
        this.totalRegistrosGrupo = response.data.total;
        this.totalPaginasGrupo = Math.ceil(this.totalRegistrosGrupo / this.registrosPaginaGrupo);
        this.rodape.pageText = this.totalRegistros + " itens";
        //fecha o loading
        this.carregandoGrupo = false;
        //se não achar registro seta a mensagem senão deixa em branco
        this.mensagemPesquisaGrupo = this.registrosGrupo.length > 1 ? "" : "Nenhum registro encontrado";
      } catch (e) {
        this.carregandoGrupo = false;
        this.mensagemPesquisaGrupo = "Erro ao buscar dados: " + e;
      }
    }
  },

  beforeMount() {
    if (this.$route.path.includes("assinaturas")) {
      this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_ETAPAS_COBRANÇA_ACESSO"]);
    } else if (this.$route.path.includes("assinaturas")) {
      this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "COMERCIAL_ETAPAS_COBRANÇA_ACESSO"]);
    } else {
      this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_ETAPAS_COBRANÇA_ACESSO"]);
    }
    //* Verifica se o usuário logado possui direito para incluir
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;

      if (
        direito == "WEBSUITA_ADMIN_SUITA" ||
        (this.$route.path.includes("assinaturas") && direito == "ASSINATURAS_ETAPAS_COBRANÇA_CRIAR") ||
        (this.$route.path.includes("comercial") && direito == "COMERCIAL_ETAPAS_COBRANÇA_CRIAR") ||
        (this.$route.path.includes("financeiro") && direito == "FINANCEIRO_ETAPAS_COBRANÇA_CRIAR")
      ) {
        this.direitoCriar = true;
      }
      if (
        direito == "WEBSUITA_ADMIN_SUITA" ||
        (this.$route.path.includes("assinaturas") && direito == "ASSINATURAS_ETAPAS_COBRANÇA_EDITAR") ||
        (this.$route.path.includes("comercial") && direito == "COMERCIAL_ETAPAS_COBRANÇA_EDITAR") ||
        (this.$route.path.includes("financeiro") && direito == "FINANCEIRO_ETAPAS_COBRANÇA_EDITAR")
      ) {
        this.direitoEditar = true;
      }
    });
  },
  watch: {
    paginaAtual() {
      this.pesquisaDados();
      this.pesquisaDadosGrupo();
    },
    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }
        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }
        this.pesquisaDados();
      }
    },
    optionsGrupo: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }
        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }
        this.pesquisaDadosGrupo();
      }
    }
  }
};
</script>
