<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">flip</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Flip</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="retornar(false)">
        <v-icon color="white">cancel</v-icon>
      </v-btn>
    </v-toolbar>
    <iframe allowfullscreen :src="urlFlipPage + '?flippage=' + urlFlip" title="Flip" width="100%" height="88%" style="border: none;"></iframe>
  </v-card>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  props: {
    urlFlip: String
  },
  data() {
    return { urlFlipPage: null };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    retornar(valor) {
      this.$emit("update:dialogFlip", valor);
    }
  },
  beforeMount() {
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FLIP_EDIÇÕES_ACESSO"]);
    var url = process.env.VUE_APP_URL_WEB_API;
    if (url.includes("localhost")) {
      url = "http://localhost";
    }
    url = url.replace("/api/", "");
    this.urlFlipPage = url + "/flippage/";
  }
};
</script>
