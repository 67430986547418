<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">eject</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Motivo de Reclamação</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12 md12 v-if="siteGrupo.length > 0">
            <v-autocomplete
              v-model="reclamacao.idSite"
              :disabled="isUpdating"
              :items="siteGrupo"
              :filter="filtrarObjetos"
              hint="Obrigatório"
              label="Restrita ao Site"
              item-text="nome"
              item-value="id"
              :rules="[validacao.idSite == false || validacao.idSite]"
              no-data-text
              clearable
              @change="validacao.idSite = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md12>
            <v-text-field
              autofocus
              v-model="reclamacao.nome"
              label="Nome da Reclamação"
              maxlength="50"
              hint="Obrigatório"
              :rules="[validacao.nome == false || validacao.nome, value => !!value || 'Obrigatório.', value => value.length > 2 || 'Preencha o nome da reclamação']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="
                reclamacao.nome = pLetra(reclamacao.nome);
                validacao.nome = false;
              "
            ></v-text-field>
          </v-flex>

          <v-flex xs6>
            <v-autocomplete
              v-model="reclamacao.idSetor"
              :items="setores"
              :filter="filtrarObjetos"
              label="Selecione o Setor"
              :rules="[validacao.idSetor == false || validacao.idSetor, value => !!value || 'Obrigatório.']"
              item-text="nome"
              item-value="id"
              :disabled="isUpdating"
              @change="validacao.idSetor = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs6>
            <v-switch v-model="reclamacao.situacao" :label="`${reclamacao.situacao == 1 ? 'Ativo' : 'Inativo'}`"></v-switch>
          </v-flex>
          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">
              {{ validacao.mensagemErro }}
            </v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn to="/assinaturas/reclamacoes-padrao" color="orange lighten-2 white" style="color:white" id="btn-rsn">
        <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar
      </v-btn>
      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="blue white" style="color:white" id="btn-rsn" depressed @click="salvar('false')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar
      </v-btn>
      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="orange white" style="color:white" id="btn-rsn" outlined @click="salvar('retornar')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>
      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="blue white" style="color:white" id="btn-rsn" outlined @click="salvar('true')" v-if="direitoCriar">
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Nova
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      reclamacao: {
        id: "",
        idSite: null,
        nome: "",
        idSetor: null,
        situacao: 1
      },
      validacao: {
        id: false,
        idSite: false,
        nome: false,
        idSetor: false,
        situacao: false,
        mensagemErro: false
      },

      siteGrupo: [],
      setores: [],

      idSitePrincipal: null,
      direitoCriar: false,
      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false,
      bloqueiaBotoes: false
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      this.isUpdating = true;
      try {
        var retorno = "";
        //se tiver id é um update
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "ass/reclamacao-padrao/" + this.id, this.reclamacao);
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "ass/reclamacao-padrao?idSite=" + this.idSitePrincipal, this.reclamacao);
          this.id = retorno.data.id;
          this.reclamacao.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "true") {
          this.$router.push("/assinaturas/reclamacao-padrao");
          this.$router.go("/assinaturas/reclamacao-padrao");
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push("/assinaturas/reclamacoes-padrao");
          }, 2000);
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },
    async carregarSetores() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/setor/lista?idSite=" + this.idSitePrincipal);
        if (response.data) {
          this.setores = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de setores";
        this.snackErro = true;
      }
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    pLetra(text) {
      return util.pLetra(text);
    }
  },

  async created() {
    this.isUpdating = false;
    this.bloqueiaBotoes = true;
    this.id = this.$route.params.id ? this.$route.params.id : "";

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_RECLAMAÇÕES_PADRÃO_CRIAR") {
        this.direitoCriar = true;
      }
    });

    //* Verifica se o site do usuário está em GRUPO - OBS: ou ele compartilha a informação ou deixa exclusiva para o seu site
    var idGrupoSite = this.$store.state.usuario.site_idGrupo;
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
    if (idGrupoSite != null) {
      this.siteGrupo = [
        {
          id: this.idSitePrincipal,
          nome: this.$store.state.usuario.site_nome
        }
      ];
    }
    await this.carregarSetores();

    //* Se é edição, carrega o elemento
    if (this.id == "") {
      this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_RECLAMAÇÕES_PADRÃO_CRIAR"]);
    } else {
      this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_RECLAMAÇÕES_PADRÃO_EDITAR"]);
      try {
        //* Carrega o elemento - somente se for público (idSite=null) ou for do site do usuário logado
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/reclamacao-padrao/" + this.id + "?idSite=" + this.idSitePrincipal);
        if (!response.data) {
          this.$router.push("/assinaturas/reclamacoes-padrao");
        } else {
          this.reclamacao = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os dados da reclamação";
        this.snackErro = true;
      }
    }
    setTimeout(() => {
      this.bloqueiaBotoes = false;
    }, 1000);
  }
};
</script>
