<template>
  <div id="app">
    <v-app id="inspire">
      <v-card class="hide-overflow fill-height">
        <v-toolbar height="50" color="#333333">
          <v-icon color="white" class="mr-2">compare_arrows</v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light">Transferências</v-toolbar-title>
          <v-spacer></v-spacer>
          <router-link to="/financeiro/transferencia" style="text-decoration: none" v-if="direitoCriar">
            <v-btn color="success" fab small>
              <v-icon color="white" title="Incluir">add</v-icon>
            </v-btn>
          </router-link>
        </v-toolbar>
        <div class="px-6 mt-4">
          <v-layout wrap>
            <v-spacer></v-spacer>
            <v-flex xs12 md5>
              <v-text-field
                background-color="white"
                class="mt-2 mb-2 elevation-0"
                v-model="filtroPesquisa"
                append-icon="search"
                label="Digite para pesquisar..."
                placeholder="Pesquisa pela descrição (ou 1=conciliado/0=não conciliado)"
                hide-details
                @keyup.enter="limpaFiltro"
                @click:append="limpaFiltro"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-data-table
            mobile-breakpoint="0"
            :headers="headers"
            :items="registros"
            :options.sync="options"
            :no-data-text="mensagemPesquisa"
            :footer-props="rodape"
            :disable-sort="carregando"
            :disable-pagination="carregando"
          >
            <template v-slot:[getItemActions()]="{ item }">
              <router-link v-if="direitoEditar" :to="'/financeiro/transferencia/' + item.id" style="text-decoration: none">
                <v-icon color="blue" title="Editar">edit</v-icon>
              </router-link>
              <v-icon v-if="item.conciliado" color="green" title="Conciliada">copyright </v-icon>
            </template>

            <template v-slot:[getItemDtRegistro()]="{ item }">
              <span>{{ formatDateTime(item.dtRegistro) }}</span>
            </template>
            <template v-slot:[getItemDtOperacao()]="{ item }">
              <span>{{ formatDate(item.dtOperacao) }}</span>
            </template>
            <template v-slot:[getItemSituacao()]="{ item }">
              <span v-html="item.situacao == null ? '' : item.situacao == 1 ? 'Ativa' : '<strong>Cancelada</strong>'"></span>
            </template>
          </v-data-table>
          <div class="text-xs-center pt-2 marg-bt">
            <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 7" circle></v-pagination>
          </div>
        </div>
      </v-card>
    </v-app>
  </div>
</template>

<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      headers: [
        { text: "Ações", value: "actions", width: "80" },
        { text: "ID", value: "id" },
        { text: "Registro", value: "dtCriacao" },
        { text: "Operação", value: "dtOperacao" },
        { text: "Destino/Despesa", value: "contaD" },
        { text: "Origem", value: "contaC" },
        { text: "Descricao", value: "descricao" },
        { text: "Situação", value: "situacao" },
        { text: "Valor", value: "vlrBruto", align: "end" }
      ],
      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },
      direitoCriar: false,
      direitoEditar: false,
      mensagemPesquisa: "",
      filtroPesquisa: "",
      registros: [],
      registrosPagina: 10,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      carregando: true,
      ordenacao: "",
      ordem: "",
      options: {}
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    getItemActions() {
      return "item.actions";
    },
    getItemSituacao() {
      return "item.situacao";
    },
    getItemDtRegistro() {
      return "item.dtRegistro";
    },
    getItemDtOperacao() {
      return "item.dtOperacao";
    },
    limpaFiltro() {
      this.paginaAtual = 1;
      this.pesquisaDados();
    },
    async pesquisaDados() {
      this.registros = [];
      //sinaliza que está carregando
      this.carregando = true;
      this.mensagemPesquisa = "Carregando...";

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "fin/operacao/tipo/2?idSite=" +
            this.$store.state.usuario.idSitePrincipal +
            "&pagina=" +
            this.paginaAtual +
            "&tamanho=" +
            this.registrosPagina +
            "&filtro=" +
            this.filtroPesquisa +
            "&ordenacao=" +
            this.ordenacao +
            "&ordem=" +
            this.ordem
        );

        //seta as variaveis com o retorno
        this.registros = response.data.data;

        this.registros.forEach(element => {
          element.vlrBruto = element.vlrBruto ? util.formatCurrency(element.vlrBruto) : "";
          element.dtCriacao = util.formatDateTime(element.dtCriacao);
        });

        this.totalRegistros = response.data.total;
        this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
        this.rodape.pageText = this.totalRegistros + " itens";
        //fecha o loading
        this.carregando = false;
        //se não achar registro seta a mensagem senão deixa em branco
        this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else {
          this.mensagemPesquisa =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
        }
      }
    },
    formatDate(date) {
      return util.formatDate(date);
    },
    formatDateTime(datetime) {
      return util.formatDateTime(datetime);
    }
  },
  beforeMount() {
    if (this.receber) {
      this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_TRANSFERÊNCIAS_ACESSO"]);
    } else {
      this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_TRANSFERÊNCIAS_ACESSO"]);
    }

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;

      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_TRANSFERÊNCIAS_CRIAR") {
        this.direitoCriar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_TRANSFERÊNCIAS_EDITAR") {
        this.direitoEditar = true;
      }
    });
  },

  watch: {
    paginaAtual() {
      this.pesquisaDados();
    },
    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }

        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }

        this.pesquisaDados();
      }
    }
  }
};
</script>
