<template>
  <div id="app">
    <v-app id="inspire">
      <v-card>
        <v-toolbar color="#333333">
          <v-icon color="white" class="mr-2">mail_outline</v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light">E-mails</v-toolbar-title>
        </v-toolbar>
        <div class="px-6 mt-4">
          <v-layout wrap>
            <v-spacer></v-spacer>
            <v-flex xs12 md5>
              <v-text-field
                background-color="white"
                class="mt-2 mb-2 elevation-0"
                v-model="filtroPesquisa"
                append-icon="search"
                label="Digite para pesquisar..."
                placeholder="Pesquisa pelo nome, e-mail, status(Enviado,Erro)"
                hide-details
                @keyup.enter="limpaFiltro"
                @click:append="limpaFiltro"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-data-table
            mobile-breakpoint="0"
            :headers="headers"
            :items="registros"
            :options.sync="options"
            :no-data-text="mensagemPesquisa"
            :footer-props="rodape"
            :disable-sort="carregando"
            :disable-pagination="carregando"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon v-if="direitoCliente" color="orange" title="Cliente" @click="visualizarCliente(item)">account_circle</v-icon>
              <v-icon v-if="item.processado && item.retorno != 'Enviado'" color="blue" title="Reenviar" @click="reenviarEmail(item)">forward</v-icon>
            </template>

            <template v-slot:item.origem="{ item }">{{ item.origem == "ASS" ? "Assinaturas" : "Financeiro" }}</template>

            <template v-slot:item.tipo="{ item }">
              {{
                item.tipo == 0
                  ? "Recibo"
                  : item.tipo == 1
                  ? "Cobrança Email"
                  : item.tipo == 2
                  ? "Cobrança Corte"
                  : item.tipo == 3
                  ? "Cobrança Cancelamento"
                  : item.tipo == 4
                  ? "Nota Fiscal"
                  : item.tipo == 5
                  ? "Boleto"
                  : item.tipo == 6
                  ? "NF + Boleto"
                  : item.tipo == 7
                  ? "PI"
                  : item.tipo == 8
                  ? "Cupom"
                  : item.tipo == 9
                  ? "Nova Assinatura"
                  : item.tipo == 10
                  ? "Códigos Promocionais"
                  : ""
              }}
            </template>

            <template v-slot:item.dtRegistro="{ item }">{{ formatDateTime(item.dtRegistro) }}</template>

            <template v-slot:item.dtEnvio="{ item }">{{ formatDateTime(item.dtEnvio) }}</template>
            <template v-slot:item.processado="{ item }">{{ item.processado == 1 ? "Sim" : "" }}</template>
            <template v-slot:item.retorno="{ item }">
              <span :class="item.retorno == 'Enviado' ? 'blue--text' : 'red--text'">{{ item.retorno }}</span>
            </template>
          </v-data-table>
          <div class="text-xs-center pt-2 marg-bt">
            <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 7" circle></v-pagination>
          </div>
        </div>
        <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
        <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
      </v-card>
    </v-app>
  </div>
</template>

<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      headers: [
        { text: "Ações", value: "actions", width: "80" },
        { text: "ID", value: "id" },
        { text: "Cliente", value: "cliente" },
        { text: "Origem", value: "origem" },
        { text: "Tipo", value: "tipo" },
        { text: "Usuário", value: "usuario" },
        { text: "Data Registro", value: "dtRegistro" },
        { text: "Data Envio", value: "dtEnvio" },
        { text: "E-mail", value: "email" },
        { text: "Processado", value: "processado" },
        { text: "Retorno", value: "retorno" },
        { text: "Boleto", value: "boleto" },
        { text: "Nota Fiscal", value: "nf" },
        { text: "Recibo", value: "recibo" },
        { text: "PI", value: "pi" },
        { text: "Cupom", value: "cupom" }
      ],
      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },

      direitoAssinante: false,
      direitoCliente: false,

      mensagem: "",
      snackbar: false,
      snackErro: false,

      mensagemPesquisa: "",
      filtroPesquisa: "",
      registros: [],
      registrosPagina: 10,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      carregando: true,
      ordenacao: "",
      ordem: "",
      options: {}
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    limpaFiltro() {
      this.paginaAtual = 1;
      this.pesquisaDados();
    },
    async pesquisaDados() {
      this.registros = [];
      //sinaliza que está carregando
      this.carregando = true;
      this.mensagemPesquisa = "Carregando...";

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "adm/log-email?idSite=" +
            this.$store.state.usuario.idSitePrincipal +
            "&pagina=" +
            this.paginaAtual +
            "&tamanho=" +
            this.registrosPagina +
            "&filtro=" +
            this.filtroPesquisa +
            "&ordenacao=" +
            this.ordenacao +
            "&ordem=" +
            this.ordem
        );

        //seta as variaveis com o retorno
        this.registros = response.data.data;
        this.totalRegistros = response.data.total;
        this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
        this.rodape.pageText = this.totalRegistros + " itens";
        //fecha o loading
        this.carregando = false;
        //se não achar registro seta a mensagem senão deixa em branco
        this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else {
          this.mensagemPesquisa = "Erro ao buscar dados: " + e;
        }
      }
    },
    visualizarCliente(item) {
      if (this.direitoCliente) {
        window.open("/financeiro/cliente/" + item.idCliente, "_blank");
      } else if (this.direitoAssinante) {
        window.open("/assinaturas/assinante/" + item.idCliente, "_blank");
      }
    },
    async reenviarEmail(item) {
      try {
        var processado = { processado: 0 };
        await api.put(process.env.VUE_APP_URL_WEB_API + "adm/log-email/processado/" + item.id, processado);

        item.processado = 0;
        this.mensagem = "Item redirecionado para envio por e-mail";
        this.snackbar = true;
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },
    formatDateTime(date) {
      return util.formatDateTime(date);
    }
  },
  beforeMount() {
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_EMAILS_ACESSO"]);
    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;

      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_ASSINANTES_EDITAR") {
        this.direitoAssinante = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_CLIENTES_EDITAR") {
        this.direitoCliente = true;
      }
    });
  },
  watch: {
    paginaAtual() {
      this.pesquisaDados();
    },
    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }
        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }
        this.pesquisaDados();
      }
    }
  }
};
</script>
