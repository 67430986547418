<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">toys</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Promoção</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="success" fab small @click="dialogUpload = true" v-if="id != ''">
        <v-icon color="white" title="Envio da Arte da Promoção">cloud_upload</v-icon>
      </v-btn>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12 md12 v-if="siteGrupo.length > 0">
            <v-autocomplete
              v-model="promocao.idSite"
              :disabled="isUpdating"
              :items="siteGrupo"
              :filter="filtrarObjetos"
              label="Restrita ao Site"
              item-text="nome"
              item-value="id"
              :rules="[validacao.idSite == false || validacao.idSite]"
              no-data-text
              clearable
              @change="validacao.idSite = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md7>
            <v-text-field
              autofocus
              v-model="promocao.nome"
              label="Nome da Promoção"
              maxlength="50"
              hint="Obrigatório"
              :rules="[validacao.nome == false || validacao.nome, value => !!value || 'Obrigatório.', value => value.length > 2 || 'Preencha o nome da promoção']"
              :disabled="isUpdating || cupons != 0"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="
                promocao.nome = pLetra(promocao.nome);
                validacao.nome = false;
              "
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md2>
            <v-autocomplete
              v-model="promocao.tipoCliente"
              :disabled="isUpdating || cupons != 0"
              :items="tipos"
              :filter="filtrarObjetos"
              hint="Obrigatório"
              :rules="[validacao.tipoCliente == false || validacao.tipoCliente, value => value != null || 'Obrigatório']"
              label="Selecione o Tipo de Cliente"
              item-text="nome"
              item-value="id"
              no-data-text
              @change="validacao.tipoCliente = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md3>
            <v-autocomplete
              v-model="promocao.tipoPromocao"
              :disabled="isUpdating || id != ''"
              :items="tiposPromocao"
              :filter="filtrarObjetos"
              :hint="promocao.tipoPromocao > 0 ? '*Funcionalidade em desenvolvimento' : ''"
              persistent-hint
              :rules="[validacao.tipoPromocao == false || validacao.tipoPromocao, value => value != null || 'Obrigatório']"
              label="Selecione o Tipo de Promoção"
              item-text="nome"
              item-value="id"
              no-data-text
              @change="validacao.tipoPromocao = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12>
            <v-text-field
              autofocus
              v-model="promocao.descricao"
              label="Descrição da Promoção"
              maxlength="100"
              hint="Obrigatório"
              :rules="[validacao.descricao == false || validacao.descricao, value => !!value || 'Obrigatório.', value => value.length > 10 || 'Preencha a descrição da promoção']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.descricao = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md3>
            <v-menu ref="mnuDtInicio" v-model="mnuDtInicio" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dtInicio"
                  label="Data Inicial"
                  v-mask="'##/##/####'"
                  hint="Obrigatório"
                  :rules="[validacao.dtInicio == false || validacao.dtInicio, value => valida_data(value) || 'Data inválida', value => !!value || 'Obrigatório.']"
                  :disabled="isUpdating || cupons != 0 || premios != 0"
                  prepend-icon="event"
                  v-on="on"
                  color="blue-grey lighten-2"
                  autocomplete="disabled"
                  :name="Math.random()"
                  @keyup="dtInicioUS = valida_data(dtInicio) ? formatDateUS(dtInicio) : null"
                  @change="validacao.dtInicio = false"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dtInicioUS" locale="pt-br" no-title @input="mnuDtInicio = false"></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs12 md3>
            <v-menu ref="mnuDtFim" v-model="mnuDtFim" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dtFim"
                  label="Data Final"
                  v-mask="'##/##/####'"
                  hint="Obrigatório"
                  :rules="[validacao.dtFim == false || validacao.dtFim, value => valida_data(value) || 'Data inválida', value => !!value || 'Obrigatório.']"
                  :disabled="isUpdating || cupons != 0 || premios != 0"
                  prepend-icon="event"
                  v-on="on"
                  color="blue-grey lighten-2"
                  autocomplete="disabled"
                  :name="Math.random()"
                  @keyup="dtFimUS = valida_data(dtFim) ? formatDateUS(dtFim) : null"
                  @change="validacao.dtFim = false"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dtFimUS" locale="pt-br" no-title @input="mnuDtFim = false"></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs6></v-flex>

          <v-flex xs12 md4 v-if="promocao.tipoPromocao != 2">
            <v-text-field
              v-model="promocao.numeros"
              label="Limite Numeração"
              v-mask="'#####'"
              hint="Quantidade de números a serem gerados para o sorteio."
              :rules="[
                validacao.numeros == false || validacao.numeros,
                value => !!value || 'Obrigatório.',
                value => (value > 0 && value < 100000) || 'Numeração de cupons não pode ser superior a 99.999'
              ]"
              :disabled="isUpdating || id != ''"
              color="blue-grey lighten-2"
              @change="
                validacao.numeros = false;
                promocao.numeros = promocao.numeros ? parseInt(promocao.numeros) : 0;
              "
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md4 v-if="promocao.tipoPromocao != 2">
            <v-text-field
              v-model="promocao.cupons"
              label="Nº de Cupons na Captação"
              v-mask="'###'"
              hint="Gera estes cupons para o cliente na captação de uma nova assinatura"
              :rules="[
                validacao.cupons == false || validacao.cupons,
                value => !!value || 'Obrigatório.',
                value => (value > 0 && value <= 100) || 'Número de cupons gerados não pode ser superior a 100'
              ]"
              :disabled="isUpdating || cupons != 0 || promocao.numeros == 0"
              color="blue-grey lighten-2"
              @change="
                validacao.cupons = false;
                promocao.cupons = promocao.cupons ? parseInt(promocao.cupons) : 0;
              "
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md4 v-if="promocao.tipoPromocao != 2">
            <v-text-field
              v-model="promocao.cuponsDesconto"
              label="Nº de Cupons Descontados na Renovação"
              v-mask="'###'"
              hint="Este número de cupons é descontado a cada renovação"
              :rules="[validacao.cuponsDesconto == false || validacao.cuponsDesconto, value => value <= 100 || 'Número de cupons descontados não pode ser superior a 100']"
              :disabled="isUpdating || cupons != 0 || promocao.numeros == 0"
              color="blue-grey lighten-2"
              @change="
                validacao.cuponsDesconto = false;
                promocao.cuponsDesconto = promocao.cuponsDesconto ? parseInt(promocao.cuponsDesconto) : 0;
              "
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md4>
            <v-text-field
              v-model="promocao.premios"
              label="Quantidade de Prêmios"
              v-mask="'#####'"
              hint="Número de prêmios a serem distribuídos na promoção."
              :rules="[
                validacao.premios == false || validacao.premios,
                value => !!value || 'Obrigatório.',
                value => (value > 0 && value <= 10000) || 'Quantidade de prêmios não pode ser superior a 10.000'
              ]"
              :disabled="isUpdating || premios != 0"
              color="blue-grey lighten-2"
              @change="
                validacao.premios = false;
                promocao.premios = promocao.premios ? parseInt(promocao.premios) : 0;
              "
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md4>
            <v-text-field
              v-model="promocao.premiosCliente"
              label="Limite de Prêmios por Assinante"
              v-mask="'#####'"
              hint="Máximo de prêmios permitido por assinante."
              :rules="[
                validacao.premiosCliente == false || validacao.premiosCliente,
                value => !!value || 'Obrigatório.',
                value => (value > 0 && value < 100) || 'Limite de prêmios por assinante não pode ser superior a 10.000'
              ]"
              :disabled="isUpdating || premios != 0"
              color="blue-grey lighten-2"
              @change="
                validacao.premiosCliente = false;
                promocao.premiosCliente = promocao.premiosCliente ? parseInt(promocao.premiosCliente) : 0;
              "
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md4>
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="promocao.valor"
              label="Valor do Prêmio"
              :allowNegative="false"
              :max="9999999"
              :rules="[validacao.valor == false || validacao.valor, value => !!value || 'Obrigatório.', value => value.length < 11 || 'Valor inválido']"
              :disabled="isUpdating || premios != 0"
              color="blue-grey lighten-2"
              @change="validacao.valor = false"
            ></v-currency-field>
          </v-flex>

          <v-flex xs12 md12>
            <v-switch
              v-model="promocao.situacao"
              :label="`${promocao.situacao == 1 ? 'Ativo' : 'Inativo'}`"
              :disabled="isUpdating || (id != '' && promocao.situacao == 1 && !direitoAvancado)"
            ></v-switch>
          </v-flex>
          <span v-if="promocao.tipoPromocao && promocao.tipoPromocao != 0" style="color:red"> *Funcionalidade em desenvolvimento.</span>

          <v-flex xs12 md12 v-if="cupons != 0">
            <v-alert :value="true" color="success" icon="check_circle" outlined>{{
              (cupons == 1 ? "Existe 1 cupom associado" : `Existem ${cupons} cupons associados`) + " a esta promoção."
            }}</v-alert>
          </v-flex>
          <v-flex xs12 md12 v-if="premios != 0">
            <v-alert :value="true" color="success" icon="check_circle" outlined>
              {{ (premios == 1 ? "Existe 1 prêmio associado" : `Existem ${premios} prêmios associados`) + " a esta promoção." }}
            </v-alert>
          </v-flex>

          <v-flex xs12 v-if="promocao.imagem">
            <img class="img-responsive" width="1063" height="768" v-bind:src="promocao.imagem" />
          </v-flex>
          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">
              {{ validacao.mensagemErro }}
            </v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn to="/assinaturas/promocoes" id="btn-rsn" color="orange lighten-2 white" style="color:white"> <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar </v-btn>
      <v-btn :loading="isUpdating" color="blue white" id="btn-rsn" style="color:white" depressed @click="salvar('false')"> <v-icon id="icon-rsn" left>update</v-icon>Salvar </v-btn>
      <v-btn :loading="isUpdating" color="orange white" style="color:white" id="btn-rsn" outlined @click="salvar('retornar')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>
      <v-btn :loading="isUpdating" color="blue white" style="color:white" id="btn-rsn" outlined @click="salvar('true')" v-if="direitoCriar">
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Novo
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-dialog persistente v-model="dialogUpload" max-width="400px">
      <v-card>
        <v-toolbar>
          <span class="headline">Envio da Arte da Promoção</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogUpload = false">
            <v-icon color="darken-1">cancel</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <FileUpload :promocao="true" :imagem.sync="imagem" />
          <span style="color:red">Atenção: Envie imagem com nome igual a {{ id + ".png" }}<br />tamanho 1063 x 768</span>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import FileUpload from "@/views/components/FileUpload.vue";
import util from "../../util.js";
import { api } from "@/services/api";
import { mapMutations } from "vuex";
export default {
  components: {
    FileUpload
  },
  data() {
    return {
      promocao: {
        id: "",
        idSite: null,
        nome: "",
        descricao: "",
        tipoCliente: null,
        tipoPromocao: null,
        dtInicio: null,
        dtFim: null,
        numeros: 0,
        cupons: 0,
        cuponsDesconto: 0,
        premios: 0,
        premiosCliente: 0,
        valor: 0,
        situacao: 1,
        imagem: ""
      },
      validacao: {
        id: false,
        idSite: false,
        nome: false,
        descricao: false,
        tipoCliente: false,
        tipoPromocao: false,
        dtInicio: false,
        dtFim: false,
        numeros: false,
        cupons: false,
        cuponsDesconto: false,
        premios: false,
        premiosCliente: false,
        valor: false,
        situacao: false,
        mensagemErro: false
      },

      dtInicio: null,
      dtInicioUS: null,
      mnuDtInicio: false,

      dtFim: null,
      dtFimUS: null,
      mnuDtFim: false,

      siteGrupo: [],
      tipos: [
        { id: 0, nome: "Todos" },
        { id: 1, nome: "Pessoa Física" },
        { id: 2, nome: "Pessoa Jurídica" }
      ],
      tiposPromocao: [
        { id: 0, nome: "Sorteio Assinaturas" },
        { id: 1, nome: "Sorteio Aniversariantes*" },
        { id: 2, nome: "Premiação Direta*" },
        { id: 3, nome: "Sorteio Assinaturas + Premiação Direta*" },
        { id: 4, nome: "Sorteio Aniversariantes* + Premiação Direta*" }
      ],

      idSitePrincipal: null,
      direitoCriar: false,
      direitoAvancado: false,
      dialogUpload: false,
      imagem: false,

      cupons: 0,
      premios: 0,

      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      if (this.dtInicio != null) {
        this.promocao.dtInicio = util.formatDateUS(this.dtInicio);
      } else {
        this.promocao.dtInicio = null;
      }
      if (this.dtFim != null) {
        this.promocao.dtFim = util.formatDateUS(this.dtFim);
      } else {
        this.promocao.dtFim = null;
      }
      if (this.promocao.dtInicio && this.promocao.dtFim) {
        if (new Date(this.promocao.dtInicio) > new Date(this.promocao.dtFim)) {
          this.mensagem = "Verifique as datas de início e fim da promoção";
          this.snackErro = true;
          return;
        }
      }

      if (this.promocao.tipoPromocao == 2) {
        this.promocao.numeros = 0;
        this.promocao.cupons = 0;
        this.promocao.cuponsDesconto = 0;
      }

      this.isUpdating = true;
      try {
        var retorno = "";
        //se tiver id é um update
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "ass/promocao/" + this.id, this.promocao);
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "ass/promocao?idSite=" + this.idSitePrincipal, this.promocao);
          this.id = retorno.data.id;
          this.promocao.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.validacao.mensagemErro = "Utilize o botão de Upload para enviar imagem com nome igual a " + this.id + ".png - tamanho 1063 x 768.";
        this.snackbar = true;
        if (novo == "true") {
          this.$router.push("/assinaturas/promocao");
          this.$router.go("/assinaturas/promocao");
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push("/assinaturas/promocoes");
          }, 2000);
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    pLetra(text) {
      return util.pLetra(text);
    },
    valida_data(date) {
      if (date) {
        return util.valida_data_BR(date);
      } else {
        return true;
      }
    },
    formatDateUS(date) {
      return util.formatDateUS(date);
    }
  },

  watch: {
    dtInicioUS() {
      this.dtInicio = util.formatDate(this.dtInicioUS);
      this.mnuDtInicio = false;
    },
    dtFimUS() {
      this.dtFim = util.formatDate(this.dtFimUS);
      this.mnuDtFim = false;
    },
    imagem(val) {
      if (val) {
        this.validacao.mensagemErro = "";
        this.promocao.imagem = process.env.VUE_APP_URL_WEB_API + "arquivos/" + this.idSitePrincipal + "/promocoes/" + this.id + ".png";
      }
    }
  },

  async created() {
    this.id = this.$route.params.id ? this.$route.params.id : "";

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_PROMOÇÕES_CRIAR") {
        this.direitoCriar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_PROMOÇÕES_EDITAR_AVANÇADO") {
        this.direitoAvancado = true;
      }
    });

    //* Verifica se o site do usuário está em GRUPO - OBS: ou ele compartilha a informação ou deixa exclusiva para o seu site
    var idGrupoSite = this.$store.state.usuario.site_idGrupo;
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
    if (idGrupoSite != null) {
      this.siteGrupo = [
        {
          id: this.idSitePrincipal,
          nome: this.$store.state.usuario.site_nome
        }
      ];
    }

    try {
      //* Se é edição, carrega o elemento
      if (this.id == "") {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_PROMOÇÕES_CRIAR"]);
      } else {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_PROMOÇÕES_EDITAR"]);
        //* Carrega o elemento - somente se for público (idSite=null) ou for do site do usuário logado
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/promocao/" + this.id + "?idSite=" + this.idSitePrincipal);
        if (!response.data) {
          this.$router.push("/assinaturas/promocoes");
          return;
        }
        this.promocao = response.data;
        if (this.promocao.dtInicio != null) {
          this.dtInicioUS = util.dateToStringUS(this.promocao.dtInicio);
        }
        if (this.promocao.dtFim != null) {
          this.dtFimUS = util.dateToStringUS(this.promocao.dtFim);
        }
        this.promocao.imagem = process.env.VUE_APP_URL_WEB_API + "arquivos/" + this.idSitePrincipal + "/promocoes/" + this.id + ".png";

        try {
          //* Verifica se algum cupom já foi associado a renovação
          const responseCupom = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/renovacao-promocao-cupom/promocao/" + this.id + "?utilizados=1");
          this.cupons = responseCupom.data.qtde;
        } catch {}
        try {
          //* Verifica se algum cupom já foi associado a assinante
          const responsePremio = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/promocao-assinante/promocao/" + this.id);
          this.premios = responsePremio.data.length;
        } catch {}
      }
    } catch {
      this.mensagem = "Ocorreu um erro ao carregar os dados";
      this.snackErro = true;
    }
  }
};
</script>
