import { api } from "@/services/api.js";
import axios from "axios";
import Router from "@/router";
import VueCookies from "vue-cookies";

export default {
  async getUsuario(context) {
    try {
      VueCookies.set("erros", "false");
      //pega as informações do usuário passando o token (que estão nos cookies)
      const usuario = await api.get("/login");
      await context.commit("UPDATE_USUARIO", usuario.data);
      await context.commit("UPDATE_LOGIN", true);
    } catch (e) {
      VueCookies.set("erros", "true");
      this.dispatch("deslogarUsuario");
    }
  },
  async logarUsuario(context, payload) {
    //* Busca o ip local do usuário
    var ip = { ip: "" };

    try {
      const response = await axios.get("https://ipinfo.io/ip");
      ip = { ip: response.data };
    } catch {}
    // try {
    //   const response = await axios.get("https://api.ipify.org?format=json"); //ficou muito lento
    //   ip = response.data;
    //   console.log(ip);
    // } catch {}

    return api
      .loginAuth({
        username: payload.email,
        password: payload.senha,
        chave: "Su1t@SIM"
      })
      .then(response => {
        //se deu tudo certo, seta o token
        return api
          .login({
            client_id: response.data.sigla + response.data.idSite,
            client_secret: "SuitaSIM",
            code: response.data.token,
            ip: ip.ip
          })
          .then(response => {
            //se deu tudo certo, seta o token
            VueCookies.set("token", `${response.data.token}`);
          });
      });
  },
  deslogarUsuario(context) {
    context.commit("UPDATE_USUARIO", {
      id: "",
      nome: "",
      email: "",
      direitos: ""
    });
    VueCookies.remove("token");
    context.commit("UPDATE_LOGIN", false);
    Router.go("/login");
  }
};
