<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">flight_takeoff</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Feriado</v-toolbar-title>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs6 v-if="siteGrupo.length > 0">
            <v-autocomplete
              v-model="feriado.idSite"
              :items="siteGrupo"
              :filter="filtrarObjetos"
              label="Restrito ao Site"
              :disabled="isUpdating"
              :rules="[validacao.idSite == false || validacao.idSite]"
              item-text="nome"
              item-value="id"
              no-data-text
              clearable
              @change="validacao.idSite = false"
            ></v-autocomplete>
          </v-flex>
          <v-flex xs6 v-if="siteGrupo.length == 0"> </v-flex>
          <v-flex xs6> </v-flex>

          <v-flex xs12 md6>
            <v-text-field
              autofocus
              background-color="white"
              v-model="feriado.nome"
              :rules="[validacao.nome == false || validacao.nome, value => !!value || 'Obrigatório.']"
              label="Nome"
              maxlength="35"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="
                feriado.nome = pLetra(feriado.nome);
                validacao.nome = false;
              "
            ></v-text-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-menu ref="mnuDtFeriado" v-model="mnuDtFeriado" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dtFeriado"
                  label="Data"
                  v-mask="'##/##/####'"
                  hint="Obrigatório"
                  :rules="[validacao.dtFeriado == false || validacao.dtFeriado, value => valida_data(value) || 'Data inválida', value => !!value || 'Obrigatório.']"
                  :disabled="isUpdating"
                  prepend-icon="event"
                  v-on="on"
                  color="blue-grey lighten-2"
                  autocomplete="disabled"
                  :name="Math.random()"
                  @keyup="dtFeriadoUS = valida_data(dtFeriado) ? formatDateUS(dtFeriado) : null"
                  @change="validacao.dtFeriado = false"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dtFeriadoUS" locale="pt-br" no-title @input="mnuDtFeriado = false"></v-date-picker>
            </v-menu>
          </v-flex>

          <v-flex xs6 md3>
            <v-switch
              class="mt-6"
              v-model="feriado.recorrente"
              :rules="[validacao.recorrente == false || validacao.recorrente]"
              :label="`${feriado.recorrente == 1 ? 'Recorrente' : 'Não é recorrente'}`"
            ></v-switch>
          </v-flex>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">{{ validacao.mensagemErro }}</v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn to="/administrativo/feriados" id="btn-rsn" color="orange lighten-2 white" style="color:white"> <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar </v-btn>
      <v-btn :loading="isUpdating" color="blue white" style="color:white" id="btn-rsn" depressed @click="salvar('false')"> <v-icon id="icon-rsn" left>update</v-icon>Salvar </v-btn>
      <v-btn :loading="isUpdating" color="orange white" style="color:white" id="btn-rsn" outlined @click="salvar('retornar')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>
      <v-btn :loading="isUpdating" color="blue white" style="color:white" id="btn-rsn" outlined @click="salvar('true')" v-if="direitoCriar">
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Nova
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      if (this.dtFeriado != null) {
        if (util.valida_data(util.formatDateUS(this.dtFeriado))) {
          this.feriado.dtFeriado = util.formatDateUS(this.dtFeriado);
        } else {
          this.validacao.dtFeriado = "Data inválida";
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário"; //não deveria cair aqui
          this.snackErro = true;
          return;
        }
      } else {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário"; //não deveria cair aqui
        this.snackErro = true;
        return;
      }

      this.isUpdating = true;
      try {
        var retorno = "";
        //se tiver id é um update
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "adm/feriado/" + this.id, this.feriado);
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "adm/feriado?idSite=" + this.idSitePrincipal, this.feriado);
          this.id = retorno.data.id;
          this.feriado.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "true") {
          this.$router.push("/administrativo/feriado");
          this.$router.go("/administrativo/feriado");
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push("/administrativo/feriados");
          }, 2000);
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },

    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    pLetra(text) {
      return util.pLetra(text);
    },
    valida_data(date) {
      return util.valida_data_BR(date);
    },
    formatDateUS(date) {
      return util.formatDateUS(date);
    }
  },
  data() {
    return {
      feriado: {
        id: "",
        idSite: null,
        dtFeriado: null,
        nome: null,
        recorrente: 0
      },
      validacao: {
        id: false,
        idSite: false,
        dtFeriado: false,
        nome: false,
        recorrente: false,
        mensagemErro: false
      },

      siteGrupo: [],

      dtFeriado: null,
      dtFeriadoUS: null,
      mnuDtFeriado: false,

      idSitePrincipal: null,
      direitoCriar: false,
      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false
    };
  },

  watch: {
    dtFeriadoUS() {
      this.dtFeriado = util.formatDate(this.dtFeriadoUS);
      this.mnuDtFeriado = false;
    }
  },

  async created() {
    this.id = this.$route.params.id ? this.$route.params.id : "";

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ADMINISTRATIVO_FERIADOS_CRIAR") {
        this.direitoCriar = true;
      }
    });

    //* Verifica se o site do usuário está em GRUPO - OBS: ou ele compartilha a informação ou deixa exclusiva para o seu site
    var idGrupoSite = this.$store.state.usuario.site_idGrupo;
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
    if (idGrupoSite != null) {
      this.siteGrupo = [
        {
          id: this.idSitePrincipal,
          nome: this.$store.state.usuario.site_nome
        }
      ];
    }

    try {
      //* Se é edição, carrega o item
      if (this.id == "") {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ADMINISTRATIVO_FERIADOS_CRIAR"]);
        this.dtFeriado = util.formatDate(new Date());
        this.dtFeriadoUS = util.dateToStringUS(new Date());
      } else {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ADMINISTRATIVO_FERIADOS_EDITAR"]);
        //* Carrega o item - somente se for público (idSite=null) ou for do site do usuário logado
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/feriado/" + this.id + "?idSite=" + this.idSitePrincipal);

        if (!response.data) {
          this.$router.push("/administrativo/feriados");
        } else {
          this.feriado = response.data;
          this.dtFeriadoUS = util.dateToStringUS(this.feriado.dtFeriado);
        }
      }
    } catch {
      this.mensagem = "Ocorreu um erro ao carregar os dados";
      this.snackErro = true;
    }
  }
};
</script>
