<template>
  <div id="app">
    <v-app id="inspire">
      <v-card>
        <v-toolbar color="#333333">
          <v-icon color="white" class="mr-2">import_contacts</v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light">Reclamações</v-toolbar-title>
        </v-toolbar>
        <div class="px-6 mt-4">
          <v-container fluid grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <h4 class="overline font-weight-black font-italic">
                  Filtrar Reclamações
                </h4>
                <v-divider></v-divider>
              </v-flex>
              <v-flex xs12 md3>
                <v-autocomplete autofocus v-model="idStatus" :items="status" label="Status" no-data-text clearable></v-autocomplete>
              </v-flex>
              <v-flex xs12 md3>
                <v-autocomplete v-model="idSetor" :items="setores" :filter="filtrarObjetos" label="Setor" item-text="nome" item-value="id" no-data-text clearable></v-autocomplete>
              </v-flex>
              <v-flex xs12 md6>
                <v-autocomplete
                  v-model="idReclamacao"
                  :items="reclamacoes"
                  :filter="filtrarObjetos"
                  label="Motivo de Reclamação"
                  item-text="nome"
                  item-value="id"
                  no-data-text
                  clearable
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 md2>
                <v-menu v-model="mnuRegistro" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="intervaloRegistro"
                      label="Período do Registro"
                      prepend-icon="event"
                      v-on="on"
                      color="blue-grey lighten-2"
                      clearable
                      @click:clear="intervaloRegistroUS = ['', '']"
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="intervaloRegistroUS" locale="pt-br" no-title range
                    ><v-spacer></v-spacer>
                    <v-btn text color="primary" @click="mnuRegistro = false">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 md2>
                <v-menu v-model="mnuExecucao" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="intervaloExecucao"
                      label="Período da Execução"
                      prepend-icon="event"
                      v-on="on"
                      color="blue-grey lighten-2"
                      clearable
                      @click:clear="intervaloExecucaoUS = ['', '']"
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="intervaloExecucaoUS" locale="pt-br" no-title range
                    ><v-spacer></v-spacer>
                    <v-btn text color="primary" @click="mnuExecucao = false">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 md2>
                <v-menu v-model="mnuSolucao" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="intervaloSolucao"
                      label="Período da Solução"
                      prepend-icon="event"
                      v-on="on"
                      color="blue-grey lighten-2"
                      clearable
                      @click:clear="intervaloSolucaoUS = ['', '']"
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="intervaloSolucaoUS" locale="pt-br" no-title range
                    ><v-spacer></v-spacer>
                    <v-btn text color="primary" @click="mnuSolucao = false">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs6 md1>
                <v-btn v-if="direitoExportar" color="blue" :loading="loadingExp" :disabled="loadingExp" fab small @click="pesquisaDados(1)">
                  <v-icon color="white" title="Exportar">description</v-icon>
                </v-btn>
                <v-btn class="ml-2" v-if="direitoExportar" color="cyan" :loading="loadingExp" :disabled="loadingExp" fab small @click="pesquisaDados(2)">
                  <v-icon color="white" title="Recibos">insert_drive_file</v-icon>
                </v-btn>
              </v-flex>
              <v-flex xs6 md1>
                <v-switch v-model="mostrarRoteiro" :label="`${mostrarRoteiro == 1 ? 'Com Roteiro' : 'Sem roteiro'}`"></v-switch>
              </v-flex>
              <v-flex xs12 md4>
                <v-text-field
                  background-color="white"
                  class="mb-2 elevation-0"
                  v-model="filtroPesquisa"
                  append-icon="search"
                  label="Digite para pesquisar..."
                  placeholder="Pesquisa pela assinatura, cliente, reclamação, usuário"
                  hide-details
                  @keyup.enter="limpaFiltro"
                  @click:append="limpaFiltro"
                  autocomplete="disabled"
                  :name="Math.random()"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>

          <v-data-table
            mobile-breakpoint="0"
            :headers="headers"
            :items="registros"
            :options.sync="options"
            :no-data-text="mensagemPesquisa"
            :footer-props="rodape"
            :disable-sort="carregando"
            :disable-pagination="carregando"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon v-if="direitoEditar && item.id" class="mr-2" color="blue lighten-2" title="Editar" @click="editarReclamacao(item)">edit</v-icon>
              <v-icon v-if="direitoAssinante && item.id" color="orange" title="Assinante" @click="visualizarAssinante(item)">account_circle</v-icon>
              <v-icon v-if="direitoAssinatura && item.id" color="red" title="Assinatura" @click="visualizarAssinatura(item)">assignment</v-icon>
            </template>
            <template v-slot:item.idRenovacao="{ item }">
              {{ item.idRenovacao }}
            </template>
            <template v-slot:item.status="{ item }">
              <v-icon v-if="item.id" color="blue lighten-2" :title="item.status">{{
                item.status == "ABERTA" ? "lock_open" : item.status == "EXECUÇÃO" ? "hourglass_empty" : "lock"
              }}</v-icon>
            </template>
            <template v-slot:item.setor="{ item }">
              {{ item.setor }}
            </template>
            <template v-slot:item.dtRegistro="{ item }">
              <span>{{ formatDateTime(item.dtRegistro) }}</span>
            </template>
            <template v-slot:item.dtEdicao="{ item }">
              <span>{{ formatDate(item.dtEdicao) }}</span>
            </template>
            <template v-slot:item.dtExecucao="{ item }">
              <span>{{ formatDateTime(item.dtExecucao) }}</span>
            </template>
            <template v-slot:item.dtSolucao="{ item }">
              <span>{{ formatDateTime(item.dtSolucao) }}</span>
            </template>
            <template v-slot:item.tempo="{ item }">
              <span>{{ getTempo(item) }}</span>
            </template>
          </v-data-table>
          <div class="text-xs-center pt-2 marg-bt">
            <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 15" circle></v-pagination>
          </div>
          <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
          <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
        </div>
      </v-card>
      <v-dialog persistent v-model="dialogReclamacao" max-width="600px">
        <v-card>
          <v-form ref="formReclamacao" v-model="reclamacaoValid" lazy-validation>
            <v-toolbar>
              <span class="headline">Reclamação ({{ reclamacaoItem.id }})</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="closeReclamacao">
                <v-icon color="darken-1">cancel</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-autocomplete
                      v-model="reclamacaoItem.idReclamacao"
                      :items="reclamacoes"
                      label="Motivo da Reclamação"
                      item-text="nome"
                      item-value="id"
                      no-data-text
                      readonly
                    ></v-autocomplete>
                  </v-flex>
                  <v-autocomplete
                    v-model="reclamacaoItem.status"
                    :items="status"
                    label="Status"
                    no-data-text
                    :readonly="reclamacaoItem.dtSolucao != null"
                    :rules="[validacao.status == false || validacao.status]"
                  ></v-autocomplete>
                  <v-flex xs12 md12>
                    <v-textarea
                      v-model="reclamacaoItem.reclamacao"
                      label="Descrição da Reclamação"
                      maxlength="1000"
                      counter="1000"
                      rows="5"
                      color="blue-grey lighten-2"
                      readonly
                    ></v-textarea>
                  </v-flex>
                  <v-flex xs12 md12>
                    <v-textarea
                      v-if="reclamacaoItem.status == 'EXECUÇÃO' || reclamacaoItem.dtExecucao != null"
                      v-model="reclamacaoItem.execucao"
                      label="Texto para Execução"
                      rows="5"
                      maxlength="255"
                      :rules="[
                        validacao.execucao == false || validacao.execucao,
                        value => !!value || 'Obrigatório.',
                        value => (value != null && value.length > 10 && value.length < 255) || 'Texto para execução deve ter entre 10 e 255 caracteres'
                      ]"
                      counter="255"
                      :readonly="reclamacaoItem.dtExecucao != null"
                      color="blue-grey lighten-2"
                    ></v-textarea>
                  </v-flex>
                  <v-flex xs12 md12>
                    <v-textarea
                      v-if="reclamacaoItem.status == 'EXECUÇÃO' && reclamacaoItem.dtExecucao != null && reclamacaoItem.idUsuarioExecucao == $store.state.usuario.id"
                      v-model="reclamacaoItem.execucao2"
                      label="Texto Complementar para Execução"
                      rows="5"
                      maxlength="255"
                      :rules="[
                        validacao.execucao == false || validacao.execucao,
                        value => (value != null && reclamacaoItem.execucao.length + value.length + 22 < 255) || 'Texto para execução deve ter até 255 caracteres'
                      ]"
                      counter="255"
                      color="blue-grey lighten-2"
                    ></v-textarea>
                  </v-flex>
                  <v-flex xs12 md12>
                    <v-textarea
                      v-if="reclamacaoItem.status == 'FECHADA'"
                      v-model="reclamacaoItem.solucao"
                      label="Resolução da Reclamação"
                      rows="5"
                      maxlength="255"
                      :rules="[
                        validacao.solucao == false || validacao.solucao,
                        value => !!value || 'Obrigatório.',
                        value => (value != null && value.length > 10 && value.length < 255) || 'Texto da solução deve ter entre 10 e 255 caracteres'
                      ]"
                      counter="255"
                      :readonly="reclamacaoItem.dtSolucao != null"
                      color="blue-grey lighten-2"
                    ></v-textarea>
                  </v-flex>
                  <v-flex xs12 v-if="validacao.mensagemErro">
                    <v-sheet :value="true" class="red--text">
                      {{ validacao.mensagemErro }}
                    </v-sheet>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" @click="closeReclamacao">Retornar</v-btn>
              <v-btn
                v-if="
                  (reclamacaoItem.status == 'EXECUÇÃO' && (reclamacaoItem.dtExecucao == null || reclamacaoItem.idUsuarioExecucao == $store.state.usuario.id)) ||
                    (reclamacaoItem.status == 'FECHADA' && reclamacaoItem.dtSolucao == null)
                "
                color="blue darken-1"
                @click="saveReclamacao"
                >Atualizar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      validacao: {
        status: false,
        execucao: false,
        solucao: false,
        mensagemErro: false
      },
      headers: [
        { text: "Ações", value: "actions", width: "120" },
        { text: "ID", value: "id" },
        { text: "Assinatura", value: "idAssinatura" },
        { text: "Renovação", value: "idRenovacao" },
        { text: "Status", value: "status" },
        { text: "Plano", value: "plano" },
        { text: "Assinante", value: "cliente" },
        { text: "Setor", value: "setor" },
        { text: "Reclamação", value: "reclamacaoPadrao", divider: "true" },
        { text: "Usuário", value: "usuario" },
        { text: "Registro", value: "dtRegistro" },
        { text: "Data Edição", value: "dtEdicao", divider: "true" },
        { text: "Usuário Execução", value: "usuarioExecucao" },
        { text: "Registro Execução", value: "dtExecucao", divider: "true" },
        { text: "Usuário Solução", value: "usuarioSolucao" },
        { text: "Registro Solução", value: "dtSolucao" },
        { text: "Tempo Solução", value: "tempo" }
      ],
      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },
      status: ["ABERTA", "EXECUÇÃO", "FECHADA"],
      setores: [],

      direitoEditar: false,
      direitoAssinante: false,
      direitoAssinatura: false,
      direitoExportar: false,

      idSitePrincipal: null,
      idReclamacao: [],
      idStatus: null,
      idSetor: null,
      reclamacoes: [],
      mostrarRoteiro: 0,

      mnuRegistro: false,
      intervaloRegistroUS: ["", ""],
      mnuExecucao: false,
      intervaloExecucaoUS: ["", ""],
      mnuSolucao: false,
      intervaloSolucaoUS: ["", ""],

      dialogReclamacao: false,
      reclamacaoValid: true,
      reclamacaoIndex: -1,
      reclamacaoItem: {
        id: null,
        status: "",
        reclamacao: "",
        execucao: "",
        execucao2: "",
        solucao: ""
      },

      inicial: true,
      mensagem: "",
      snackbar: false,
      snackErro: false,
      mensagemPesquisa: "",
      filtroPesquisa: "",
      registros: [],
      registrosPagina: 10,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      carregando: true,
      ordenacao: "",
      ordem: "",
      options: {},
      loader: null,
      loadingExp: false
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO", "OCULTAR_MENU"]),
    limpaFiltro() {
      this.paginaAtual = 1;
      this.pesquisaDados(0);
    },
    async pesquisaDados(exportar) {
      this.loader = "loadingExp";
      var l = this.loader;
      if (exportar == 1) {
        this[l] = !this[l];
        setTimeout(() => (this[l] = false), 3000);
        this.loader = null;
      } else if (exportar == 2) {
        this[l] = !this[l];
      }
      if (!exportar) {
        //sinaliza que está carregando
        this.carregando = true;
        this.mensagemPesquisa = "Carregando...";

        if (this.mostrarRoteiro) {
          this.headers = [
            { text: "Ações", value: "actions", width: "120" },
            { text: "ID", value: "id" },
            { text: "Assinatura", value: "idAssinatura" },
            { text: "Renovação", value: "idRenovacao" },
            { text: "Status", value: "status" },
            { text: "Plano", value: "plano" },
            { text: "Assinante", value: "cliente" },
            { text: "Setor", value: "setor" },
            { text: "Reclamação", value: "reclamacaoPadrao", divider: "true" },
            { text: "Usuário", value: "usuario" },
            { text: "Registro", value: "dtRegistro" },
            { text: "Data Edição", value: "dtEdicao", divider: "true" },
            { text: "Usuário Execução", value: "usuarioExecucao" },
            { text: "Registro Execução", value: "dtExecucao", divider: "true" },
            { text: "Usuário Solução", value: "usuarioSolucao" },
            { text: "Registro Solução", value: "dtSolucao" },
            { text: "Tempo Solução", value: "tempo", divider: "true" },
            { text: "Endereço", value: "endereco" },
            { text: "Entregador", value: "entregador" },
            { text: "Rota", value: "rota" }
          ];
        } else {
          this.headers = [
            { text: "Ações", value: "actions", width: "120" },
            { text: "ID", value: "id" },
            { text: "Assinatura", value: "idAssinatura" },
            { text: "Renovação", value: "idRenovacao" },
            { text: "Status", value: "status" },
            { text: "Plano", value: "plano" },
            { text: "Assinante", value: "cliente" },
            { text: "Setor", value: "setor" },
            { text: "Reclamação", value: "reclamacaoPadrao", divider: "true" },
            { text: "Usuário", value: "usuario" },
            { text: "Registro", value: "dtRegistro" },
            { text: "Data Edição", value: "dtEdicao", divider: "true" },
            { text: "Usuário Execução", value: "usuarioExecucao" },
            { text: "Registro Execução", value: "dtExecucao", divider: "true" },
            { text: "Usuário Solução", value: "usuarioSolucao" },
            { text: "Registro Solução", value: "dtSolucao" },
            { text: "Tempo Solução", value: "tempo" }
          ];
        }
      } else {
        this.mensagemPesquisa = "MONTE SEU FILTRO PARA VISUALIZAR AS RECLAMAÇÕES...";
      }

      this.registros = [];
      this.totalRegistros = 0;
      this.totalPaginas = 0;
      this.rodape.pageText = "";

      var dtRegInicio = "";
      var dtRegFim = "";
      if (this.intervaloRegistroUS && this.intervaloRegistroUS.length > 1) {
        dtRegInicio = this.intervaloRegistroUS[0];
        dtRegFim = this.intervaloRegistroUS[1];
      }
      var dtExeInicio = "";
      var dtExeFim = "";
      if (this.intervaloExecucaoUS && this.intervaloExecucaoUS.length > 1) {
        dtExeInicio = this.intervaloExecucaoUS[0];
        dtExeFim = this.intervaloExecucaoUS[1];
      }
      var dtSolInicio = "";
      var dtSolFim = "";
      if (this.intervaloSolucaoUS && this.intervaloSolucaoUS.length > 1) {
        dtSolInicio = this.intervaloSolucaoUS[0];
        dtSolFim = this.intervaloSolucaoUS[1];
      }
      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "ass/assinatura-reclamacao?pagina=" +
            this.paginaAtual +
            "&tamanho=" +
            this.registrosPagina +
            "&filtro=" +
            this.filtroPesquisa +
            (this.idStatus ? "&status=" + this.idStatus : "") +
            (this.idSetor ? "&idSetor=" + this.idSetor : "") +
            (this.idReclamacao ? "&idReclamacao=" + this.idReclamacao : "") +
            (dtRegInicio ? "&dtRegInicio=" + dtRegInicio : "") +
            (dtRegFim ? "&dtRegFim=" + dtRegFim : "") +
            (dtExeInicio ? "&dtExeInicio=" + dtExeInicio : "") +
            (dtExeFim ? "&dtExeFim=" + dtExeFim : "") +
            (dtSolInicio ? "&dtSolInicio=" + dtSolInicio : "") +
            (dtSolFim ? "&dtSolFim=" + dtSolFim : "") +
            "&ordenacao=" +
            this.ordenacao +
            "&ordem=" +
            this.ordem +
            (exportar ? "&exportar=" + exportar : "") +
            (this.mostrarRoteiro && exportar != 2 ? "&mostrarRoteiro=1" : "")
        );

        if (!exportar) {
          //seta as variaveis com o retorno
          this.registros = response.data.data;
          this.totalRegistros = response.data.total;
          this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
          this.rodape.pageText = this.totalRegistros + " itens";
          //fecha o loading
          this.carregando = false;
          //se não achar registro seta a mensagem senão deixa em branco
          this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
        } else if (exportar == 2) {
          this[l] = false;
          this.loader = null;
          if (response.data) {
            util.imprimir(response.data);
          } else {
            this.mensagemPesquisa = "Nenhum registro encontrado";
          }
        }
      } catch (e) {
        this.carregando = false;
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },
    async carregarSetores() {
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/setor/lista?idSite=" + this.idSitePrincipal);
      if (response.data) {
        this.setores = response.data;
      }
    },
    async carregarReclamacoesPadrao() {
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/reclamacao-padrao/lista?idSite=" + this.idSitePrincipal);
      if (response.data) {
        this.reclamacoes = response.data;
      }
    },
    editarReclamacao(item) {
      this.reclamacaoIndex = this.registros.indexOf(item);
      this.reclamacaoItem = Object.assign({}, item);
      this.reclamacaoItem.execucao2 = "";
      this.dialogReclamacao = true;
    },
    closeReclamacao() {
      this.dialogReclamacao = false;
    },
    async saveReclamacao() {
      if (this.$refs.formReclamacao.validate()) {
        if (this.reclamacaoItem.status == "ABERTA") {
          this.validacao.status = "Status inválido"; //* não deve cair aqui, pois o botão fica desabilitado para este status
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
          return;
        }
        if (this.reclamacaoItem.status == "EXECUÇÃO") {
          if (!this.reclamacaoItem.dtExecucao) {
            this.reclamacaoItem.dtExecucao = new Date();
          }
          if (!this.reclamacaoItem.usuarioExecucao) {
            this.reclamacaoItem.usuarioExecucao = this.$store.state.usuario.nome;
          }
          if (!this.reclamacaoItem.idUsuarioExecucao) {
            this.reclamacaoItem.idUsuarioExecucao = this.$store.state.usuario.id;
          }
        } else {
          this.reclamacaoItem.dtSolucao = new Date();
          this.reclamacaoItem.usuarioSolucao = this.$store.state.usuario.nome;
          this.reclamacaoItem.idUsuarioSolucao = this.$store.state.usuario.id;
        }

        var textoExec = this.reclamacaoItem.execucao;
        if (this.reclamacaoItem.execucao2) {
          textoExec += "\n[" + util.formatDateTime(new Date()) + "] " + this.reclamacaoItem.execucao2;
          if (textoExec.length > 255) {
            textoExec = textoExec.substr(0, 255);
          }
        }

        var reclamacaoCommit = {
          id: this.reclamacaoItem.id,
          status: this.reclamacaoItem.status
        };
        if (this.reclamacaoItem.status == "EXECUÇÃO") {
          reclamacaoCommit.idUsuarioExecucao = this.reclamacaoItem.idUsuarioExecucao;
          reclamacaoCommit.execucao = textoExec;
        } else {
          reclamacaoCommit.idUsuarioSolucao = this.reclamacaoItem.idUsuarioSolucao;
          reclamacaoCommit.solucao = this.reclamacaoItem.solucao;
        }

        try {
          await api.put(
            process.env.VUE_APP_URL_WEB_API + "ass/assinatura-reclamacao/" + (this.reclamacaoItem.status == "EXECUÇÃO" ? "execucao" : "solucao") + "/" + this.reclamacaoItem.id,
            reclamacaoCommit
          );
          this.reclamacaoItem.execucao = textoExec;
          Object.assign(this.registros[this.reclamacaoIndex], this.reclamacaoItem);

          this.closeReclamacao();
          this.$refs.formReclamacao.resetValidation();
          this.mensagem = "Item alterado";
          this.snackbar = true;
        } catch (e) {
          if (e.toString().includes("status code 400")) {
            this.mensagem = "SESSÃO EXPIRADA";
            this.snackErro = true;
          } else if (e.response && e.response.data) {
            util.validaErros(this.validacao, e.response.data);
            this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
            this.snackErro = true;
          } else {
            this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
            this.snackErro = true;
          }
        }
      }
    },
    async visualizarAssinante(item) {
      window.open("/assinaturas/assinante/" + item.idCliente, "_blank");
    },
    async visualizarAssinatura(item) {
      window.open("/assinaturas/assinatura/" + item.idAssinatura, "_blank");
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    formatDate(date) {
      return util.formatDate(date);
    },
    formatDateTime(date) {
      return util.formatDateTime(date);
    },
    getTempo(item) {
      if (item.dtSolucao) {
        const endDate = new Date(item.dtSolucao);
        const begDate = new Date(item.dtRegistro);
        const days = parseInt((endDate - begDate) / (1000 * 60 * 60 * 24));
        const hours = parseInt((Math.abs(endDate - begDate) / (1000 * 60 * 60)) % 24);
        const minutes = parseInt((Math.abs(endDate.getTime() - begDate.getTime()) / (1000 * 60)) % 60);
        const seconds = parseInt((Math.abs(endDate.getTime() - begDate.getTime()) / 1000) % 60);
        return `${days.toString().padStart(3, " ")}d, ${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
      } else {
        return null;
      }
    }
  },
  computed: {
    intervaloRegistro() {
      var retorno = "";
      if (this.intervaloRegistroUS && this.intervaloRegistroUS.length > 1) {
        this.intervaloRegistroUS.forEach(element => {
          if (element) {
            retorno += (retorno ? " - " : "") + util.formatDate(element);
          }
        });
      }
      return retorno;
    },
    intervaloExecucao() {
      var retorno = "";
      if (this.intervaloExecucaoUS && this.intervaloExecucaoUS.length > 1) {
        this.intervaloExecucaoUS.forEach(element => {
          if (element) {
            retorno += (retorno ? " - " : "") + util.formatDate(element);
          }
        });
      }
      return retorno;
    },
    intervaloSolucao() {
      var retorno = "";
      if (this.intervaloSolucaoUS && this.intervaloSolucaoUS.length > 1) {
        this.intervaloSolucaoUS.forEach(element => {
          if (element) {
            retorno += (retorno ? " - " : "") + util.formatDate(element);
          }
        });
      }
      return retorno;
    }
  },
  beforeMount() {
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_RECLAMAÇÕES_ACESSO"]);
    this.OCULTAR_MENU();
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_RECLAMAÇÕES_EDITAR") {
        this.direitoEditar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_ASSINANTES_EDITAR") {
        this.direitoAssinante = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_ASSINATURAS_EDITAR") {
        this.direitoAssinatura = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_RECLAMAÇÕES_EXPORTAR") {
        this.direitoExportar = true;
      }
    });
    this.carregarReclamacoesPadrao();
    this.carregarSetores();
  },

  watch: {
    dialogReclamacao(val) {
      val || this.closeReclamacao();
    },
    paginaAtual: function() {
      this.pesquisaDados(0);
    },
    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }

        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }

        if (!this.inicial) {
          this.pesquisaDados(0);
        } else {
          this.mensagemPesquisa = "MONTE SEU FILTRO PARA VISUALIZAR AS RECLAMAÇÕES...";
          this.inicial = false;
        }
      }
    }
  }
};
</script>
