<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar height="50" color="#333333">
      <v-icon color="white" class="mr-2">web</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Grupo de Sites</v-toolbar-title>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12>
            <h4 class="overline font-weight-black font-italic">
              Dados Básicos
            </h4>
            <v-divider></v-divider>
          </v-flex>

          <v-flex xs12>
            <v-text-field
              autofocus
              background-color="white"
              v-model="grupo.nome"
              label="Nome do Grupo"
              maxlength="100"
              hint="Obrigatório"
              :rules="[
                validacao.nome == false || validacao.nome,
                value => !!value || 'Obrigatório.',
                value => valida_nome(value) || 'Nome inválido',
                value => value.length > 2 || 'Preencha o nome do grupo'
              ]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="
                grupo.nome = pLetra(grupo.nome);
                validacao.nome = false;
              "
            ></v-text-field>
          </v-flex>

          <v-flex xs3>
            <v-switch v-model="grupo.situacao" :label="`${grupo.situacao == 1 ? 'Ativo' : 'Inativo'}`"></v-switch>
          </v-flex>

          <v-flex xs12>
            <h4 class="overline font-weight-black font-italic">
              Sites do Grupo
            </h4>
            <v-divider></v-divider>
          </v-flex>

          <v-flex offset-md-8 md4>
            <v-text-field background-color="white" v-model="searchSitesGrupo" append-icon="search" label="Digite para pesquisar..." hide-details single-line></v-text-field>
          </v-flex>
        </v-layout>

        <v-data-table
          mobile-breakpoint="0"
          :headers="headersSitesGrupo"
          :items="sitesGrupo"
          :search="searchSitesGrupo"
          no-data-text
          no-results-text
          :footer-props="{
            itemsPerPageText: 'Registros por página',
            itemsPerPageOptions: [10, 25, 50],
            pageText: ''
          }"
        >
          <template v-slot:[getItemSituacao()]="{ item }">
            <span v-html="item.situacao == 1 ? 'Ativo' : item.situacao == 0 ? '<strong>Inativo</strong>' : ''"></span>
          </template>
        </v-data-table>

        <v-flex xs12 v-if="validacao.mensagemErro">
          <v-sheet :value="true" class="red--text">{{ validacao.mensagemErro }}</v-sheet>
        </v-flex>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn to="/administrativo/grupos" id="btn-rsn" color="orange lighten-2 white" style="color:white"> <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar </v-btn>
      <v-btn :loading="isUpdating" color="blue white" style="color:white" id="btn-rsn" depressed @click="salvar('false')"> <v-icon id="icon-rsn" left>update</v-icon>Salvar </v-btn>
      <v-btn :loading="isUpdating" color="orange white" style="color:white" id="btn-rsn" outlined @click="salvar('retornar')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>
      <v-btn :loading="isUpdating" color="blue white" style="color:white" id="btn-rsn" outlined @click="salvar('true')">
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Novo
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api";
import { mapMutations } from "vuex";
export default {
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    getItemSituacao() {
      return "item.situacao";
    },

    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      this.isUpdating = true;
      try {
        var retorno = "";
        //se tiver id é um update
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "adm/grupo/" + this.id, this.grupo);
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "adm/grupo?idSite=" + this.idSitePrincipal, this.grupo);
          this.id = retorno.data.id;
          this.grupo.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "true") {
          this.$router.push("/administrativo/grupo");
          this.$router.go("/administrativo/grupo");
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push("/administrativo/grupos");
          }, 2000);
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },
    pLetra(text) {
      return util.pLetra(text);
    },
    valida_nome(text) {
      return util.valida_nome(text);
    }
  },
  data() {
    return {
      grupo: {
        id: "",
        nome: "",
        situacao: 1
      },
      validacao: {
        id: false,
        nome: false,
        situacao: false,
        mensagemErro: false
      },
      sitesGrupo: [],

      headersSitesGrupo: [
        { text: "ID", value: "id" },
        { text: "Nome", value: "nome" },
        {
          text: this.$store.state.usuario.site_pais == "BR" ? "Município" : "Concelho",
          value: "municipio"
        },
        { text: "Situação", value: "situacao" }
      ],

      idSitePrincipal: null,
      elementoValid: true,
      id: "",
      searchSitesGrupo: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false
    };
  },
  async created() {
    this.id = this.$route.params.id ? this.$route.params.id : "";
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA"]);
    //* Se é edição, carrega o grupo
    if (this.id != "") {
      //* Carrega o grupo
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/grupo/" + this.id);
        if (!response.data) {
          this.$router.push("/administrativo/grupos");
          return;
        }
        this.grupo = response.data;

        const responseSites = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/site/grupo/" + this.grupo.id);

        this.sitesGrupo = responseSites.data;
        this.sitesGrupo.forEach(element => {
          element.municipio += "/" + element.uf;
        });
      } catch {
        this.mensagem = "Ocorreu um erro ao carregar os dados";
        this.snackErro = true;
      }
    }
  }
};
</script>
