<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">play_for_work</v-icon>
      <v-toolbar-title style="color: white" class="font-weight-light">Tela do 'Assine'</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12 md3>
            <v-text-field
              autofocus
              v-model="assine.url"
              label="URL"
              maxlength="45"
              hint="Obrigatório"
              :rules="[validacao.url == false || validacao.url, value => !!value || 'Obrigatório.', value => value.length > 2 || 'Preencha a url da tela do `Assine`']"
              :disabled="isUpdating || id != ''"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="
                assine.url = removeEspeciais(assine.url);
                validacao.url = false;
              "
            ></v-text-field>
          </v-flex>

          <v-flex xs12>
            <form @submit.prevent="onSubmit" enctype="multipart/form-data">
              <v-container fluid grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-file-input
                      v-model="files"
                      ref="file"
                      label="Selecione o HTML e imagens da página"
                      multiple
                      chips
                      :rules="[validacao.files == false || validacao.files]"
                      accept="text/html, image/png, image/jpeg, image/bmp"
                    >
                      <template v-slot:selection="{ index, text }">
                        <v-chip v-if="index < 8" label small>
                          {{ text }}
                        </v-chip>

                        <span v-else-if="index == 8" class="text-overline grey--text text--darken-3 mx-2"> +{{ files.length - 8 }} arquivos(s) </span>
                      </template>
                    </v-file-input>
                  </v-flex>
                </v-layout>
              </v-container>
            </form>
          </v-flex>

          <v-flex xs9>
            <v-switch v-model="assine.situacao" :label="`${assine.situacao == 1 ? 'Ativo' : 'Inativo'}`"></v-switch>
          </v-flex>
          <v-flex xs3 v-if="id != ''" class="text-right">
            <v-btn class="mt-4" x-small outlined :href="`${$store.state.usuario.site_url}/api/public/ass/paywall/assine?url=${assine.url}`" target="_blank">Testar</v-btn>
          </v-flex>

          <v-flex xs12 v-if="assine.url">
            <p>
              <strong>ATENÇÃO: </strong>
              {{
                `No seu HTML, utilize a seguinte url para suas imagens: src="${$store.state.usuario.site_url}/api/arquivos/${$store.state.usuario.idSitePrincipal}/assine/${assine.url}/NOME_DO_ARQUIVO"`
              }}
            </p>
          </v-flex>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">
              {{ validacao.mensagemErro }}
            </v-sheet>
          </v-flex>

          <v-flex xs12>
            <v-data-table mobile-breakpoint="0" :headers="headers" :items="arquivos" no-data-text> </v-data-table>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn to="/assinaturas/paywall/assines" id="btn-rsn" color="orange lighten-2 white" style="color: white">
        <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar
      </v-btn>
      <v-btn :loading="isUpdating" color="blue white" style="color: white" id="btn-rsn" depressed @click="salvar('false')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar
      </v-btn>
      <v-btn :loading="isUpdating" color="orange white" style="color: white" id="btn-rsn" outlined @click="salvar('retornar')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>

      <v-btn :loading="isUpdating" color="blue white" style="color: white" id="btn-rsn" outlined @click="salvar('true')" v-if="direitoCriar">
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Novo
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      assine: {
        id: "",
        url: "",
        situacao: 1
      },
      validacao: {
        id: false,
        url: false,
        situacao: false,
        files: false,
        mensagemErro: false
      },

      headers: [{ text: "Arquivos Enviados", value: "nome" }],

      files: [],
      arquivos: [],

      idSitePrincipal: null,
      direitoCriar: false,
      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      this.isUpdating = true;
      try {
        var retorno = "";
        //se tiver id é um update
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "ass/assine/" + this.id, this.assine);
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "ass/assine?idSite=" + this.idSitePrincipal, this.assine);
          this.id = retorno.data.id;
          this.assine.id = retorno.data.id;
        }

        if (this.files.length) {
          for (let index = 0; index < this.files.length; index++) {
            const file = this.files[index];

            const formData = new FormData();
            formData.append("arquivo", file);
            formData.append("url", this.assine.url);
            formData.append("situacao", this.assine.situacao);

            try {
              const retornoArquivos = await api.put(process.env.VUE_APP_URL_WEB_API + "ass/assine/arquivos/" + this.id + "?idSite=" + this.idSitePrincipal, formData);
              this.arquivos = retornoArquivos.data.arquivos;
            } catch (e) {
              this.mensagem =
                "ERRO: " +
                (e.toString().includes("status code 400")
                  ? "SESSÃO EXPIRADA"
                  : e.response && e.response.data && e.response.data.length > 0
                  ? e.response.data[0].mensagem
                  : "Indeterminado, verifique sua conexão");
              this.snackErro = true;
            }
          }
          this.files = [];
        }

        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "true") {
          this.$router.push("/assinaturas/paywall/assine");
          this.$router.go("/assinaturas/paywall/assine");
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push("/assinaturas/paywall/assines");
          }, 2000);
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },

    removeEspeciais(text) {
      text = util.removeEspeciais(text);
      text = text.replace(/\s+/g, "-");
      return text;
    }
  },

  async created() {
    this.id = this.$route.params.id ? this.$route.params.id : "";
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_PAYWALL_ASSINE_CRIAR") {
        this.direitoCriar = true;
      }
    });

    try {
      //* Se é edição, carrega o elemento
      if (this.id == "") {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_PAYWALL_ASSINE_CRIAR"]);
      } else {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_PAYWALL_ASSINE_EDITAR"]);
        //* Carrega o elemento - somente se for público (idSite=null) ou for do site do usuário logado
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/assine/" + this.id);
        if (!response.data) {
          this.$router.push("/assinaturas/paywall/assines");
        } else {
          this.assine = {
            id: response.data.id,
            url: response.data.url,
            situacao: response.data.situacao
          };
          this.arquivos = response.data.arquivos;
        }
      }
    } catch {
      this.mensagem = "Ocorreu um erro ao carregar os dados";
      this.snackErro = true;
    }
  }
};
</script>
