<template>
  <div id="app">
    <v-app id="inspire">
      <v-card>
        <v-toolbar color="#333333">
          <v-icon color="white" class="mr-2">location_on</v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light">ACERVO - Logradouros</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <div class="px-6 mt-4">
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="12" md="5">
              <v-text-field
                background-color="white"
                class="mt-2 mb-2 elevation-0"
                v-model="filtroPesquisa"
                append-icon="search"
                label="Digite para pesquisar..."
                :placeholder="
                  'Pesquisa por ID, ' +
                    ($store.state.usuario.site_pais == 'BR' ? 'UF, município' : 'distrito, concelho') +
                    ', CEP, ' +
                    ($store.state.usuario.site_pais == 'BR' ? 'bairro' : 'freguesia/localidade') +
                    ', logradouro, faixa, entregador, rota'
                "
                filled
                hide-details
                @keyup.enter="limpaFiltro"
                @click:append="limpaFiltro"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-data-table
            mobile-breakpoint="0"
            :headers="headers"
            :items="registros"
            :options.sync="options"
            :no-data-text="mensagemPesquisa"
            :footer-props="rodape"
            :disable-sort="carregando"
            :disable-pagination="carregando"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon v-if="direitoMigrar" color="orange" title="Enviar para Produção" @click="enviar(item)">forward</v-icon>
            </template>
          </v-data-table>
          <div class="text-xs-center pt-2 marg-bt">
            <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 7" circle></v-pagination>
          </div>
          <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
          <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
        </div>
      </v-card>
    </v-app>
  </div>
</template>

<script>
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      headers: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        {
          text: this.$store.state.usuario.site_pais == "BR" ? "UF" : "Distrito",
          value: "uf"
        },
        {
          text: this.$store.state.usuario.site_pais == "BR" ? "Município" : "Concelho",
          value: "municipio"
        },
        { text: "CEP", value: "cep" },
        {
          text: this.$store.state.usuario.site_pais == "BR" ? "Bairro" : "Freguesia/Localidade",
          value: "bairro"
        },
        { text: "Logradouro", value: "logradouro", divider: "true" },
        { text: "Faixa", value: "faixa", divider: "true" }
      ],
      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },

      direitoMigrar: false,
      direitoEditar: false,
      mensagem: "",
      snackbar: false,
      snackErro: false,
      mensagemPesquisa: "",
      filtroPesquisa: "",
      registros: [],
      registrosPagina: 10,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      carregando: true,
      ordenacao: "",
      ordem: "",
      options: {}
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    limpaFiltro() {
      this.paginaAtual = 1;
      this.pesquisaDados();
    },

    async pesquisaDados() {
      this.registros = [];
      //sinaliza que está carregando
      this.carregando = true;
      this.mensagemPesquisa = "Carregando...";

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "adm/acervo-cep?pagina=" +
            this.paginaAtual +
            "&tamanho=" +
            this.registrosPagina +
            "&filtro=" +
            this.filtroPesquisa +
            "&ordenacao=" +
            this.ordenacao +
            "&ordem=" +
            this.ordem
        );

        //seta as variaveis com o retorno
        this.registros = response.data.data;
        this.registros.forEach(element => {
          element.logradouro = element.tipo + " " + element.logradouro;
        });
        this.totalRegistros = response.data.total;
        this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
        this.rodape.pageText = this.totalRegistros + " itens";
        //fecha o loading
        this.carregando = false;
        //se não achar registro seta a mensagem senão deixa em branco
        this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else {
          this.mensagemPesquisa = "Erro ao buscar dados: " + e;
        }
      }
    },

    async enviar(cep) {
      try {
        var index = this.registros.indexOf(cep);
        this.registros.splice(index, 1);

        const retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "adm/acervo-cep/producao/" + cep.id + "?idSite=" + this.$store.state.usuario.idSitePrincipal);
        this.mensagem = "Logradouro foi enviado para a base de produção";
        this.snackbar = true;
        if (retorno.data.id && this.direitoEditar) {
          this.mensagem = "Logradouro foi enviado para a base de produção. Acessando seu cadastro...";
          this.snackbar = true;
          setTimeout(() => {
            this.$router.push("/administrativo/cep/" + retorno.data.id);
          }, 3000);
        }
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    }
  },

  beforeMount() {
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ADMINISTRATIVO_ACERVO_CEPS_ACESSO"]);
    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ADMINISTRATIVO_ACERVO_CEPS_MIGRAR") {
        this.direitoMigrar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ADMINISTRATIVO_CEPS_EDITAR") {
        this.direitoEditar = true;
      }
    });
  },

  watch: {
    paginaAtual() {
      this.pesquisaDados();
    },
    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }

        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }

        this.pesquisaDados();
      }
    }
  }
};
</script>
