<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">toc</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Etapa de Cobrança</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12 md12>
            <v-autocomplete
              autofocus
              v-model="grupoSelecionado"
              :disabled="isUpdating"
              :filter="filtrarObjetos"
              :items="gruposEtapas"
              hint="Obrigatório"
              :rules="[validacao.idGrupo == false || validacao.idGrupo, value => (!!value && !!value.id) || 'Obrigatório.']"
              label="Selecione o Grupo da Etapa"
              item-text="nome"
              item-value="id"
              no-data-text
              return-object
              @change="validacao.idGrupo = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md12>
            <v-text-field
              v-model="etapaCobranca.nome"
              label="Nome da Etapa"
              maxlength="20"
              hint="Obrigatório"
              :rules="[validacao.nome == false || validacao.nome, value => !!value || 'Obrigatório.', value => value.length > 2 || 'Preencha o nome da etapa de cobrança']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="
                etapaCobranca.nome = pLetra(etapaCobranca.nome);
                validacao.nome = false;
              "
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md6>
            <v-text-field
              v-model="etapaCobranca.inicio"
              label="Dias de Início"
              v-mask="'#####'"
              hint="Obrigatório"
              :rules="[
                validacao.inicio == false || validacao.inicio,
                value => !!value || 'Obrigatório.',
                value => (value > 0 && value < 32001) || 'Verifique o número de dias de início da etapa'
              ]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.inicio = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md6>
            <v-text-field
              v-model="etapaCobranca.fim"
              label="Dias de Término"
              v-mask="'#####'"
              hint="Obrigatório"
              :rules="[
                validacao.fim == false || validacao.fim,
                value => !!value || 'Obrigatório.',
                value => (value > 0 && value < 32001) || 'Verifique o número de dias de término da etapa'
              ]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.fim = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md6>
            <v-switch v-model="etapaCobranca.dialer" :label="`${etapaCobranca.dialer == 1 ? 'Etapa de ligação telefônica' : 'Não é etapa de ligação telefônica'}`"></v-switch>
          </v-flex>
          <v-flex xs12 md6>
            <v-switch
              v-model="etapaCobranca.email"
              :label="`${etapaCobranca.email == 1 ? 'Etapa de envio de e-mail' : 'Não é etapa de e-mail'}`"
              :disabled="grupoSelecionado == null || grupoSelecionado.novas == null"
            ></v-switch>
          </v-flex>

          <v-flex xs12 md6 v-if="$route.path.includes('assinaturas') || ($route.path.includes('financeiro') && $store.state.usuario.idSitePrincipal == 1)">
            <v-switch
              v-model="etapaCobranca.corte"
              :label="`${etapaCobranca.corte == 1 ? 'Etapa de corte de entrega' : 'Não é etapa de corte'}`"
              :disabled="grupoSelecionado == null || grupoSelecionado.novas == null"
            ></v-switch>
          </v-flex>
          <v-flex xs12 md6 v-if="$route.path.includes('assinaturas')">
            <v-switch
              v-model="etapaCobranca.cancelamento"
              :label="`${etapaCobranca.cancelamento == 1 ? 'Etapa de cancelamento de assinatura' : 'Não é etapa de cancelamento'}`"
              :disabled="grupoSelecionado == null || grupoSelecionado.novas == null"
            ></v-switch>
          </v-flex>

          <v-flex xs12 md12>
            <v-switch v-model="etapaCobranca.situacao" :label="`${etapaCobranca.situacao == 1 ? 'Ativo' : 'Inativo'}`"></v-switch>
          </v-flex>
          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">{{ validacao.mensagemErro }}</v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn
        :to="($route.path.includes('assinaturas') ? '/assinaturas' : $route.path.includes('comercial') ? '/comercial' : '/financeiro') + '/etapas-cobranca'"
        id="btn-rsn"
        color="orange lighten-2 white"
        style="color:white"
      >
        <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar
      </v-btn>
      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="blue white" style="color:white" id="btn-rsn" depressed @click="salvar('false')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar
      </v-btn>
      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="orange white" style="color:white" id="btn-rsn" outlined @click="salvar('retornar')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>
      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="blue white" style="color:white" id="btn-rsn" outlined @click="salvar('true')" v-if="direitoCriar">
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Nova
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      etapaCobranca: {
        id: "",
        idGrupo: null,
        nome: "",
        inicio: 0,
        fim: 0,
        dialer: 0,
        email: 0,
        corte: 0,
        cancelamento: 0,
        situacao: 1
      },
      validacao: {
        id: false,
        idGrupo: false,
        nome: false,
        inicio: false,
        fim: false,
        dialer: false,
        email: false,
        corte: false,
        cancelamento: false,
        situacao: false,
        mensagemErro: false
      },

      gruposEtapas: [],

      grupoSelecionado: {},

      idSitePrincipal: null,
      direitoCriar: false,
      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false,
      bloqueiaBotoes: false
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }
      if (this.etapaCobranca.corte && this.etapaCobranca.cancelamento) {
        this.validacao.mensagemErro = "Etapa não pode ser de corte e cancelamento ao mesmo tempo";
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      this.isUpdating = true;
      try {
        var retorno = "";
        //se tiver id é um update
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "ass/etapa-cobranca/" + this.id, this.etapaCobranca);
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "ass/etapa-cobranca?idSite=" + this.idSitePrincipal, this.etapaCobranca);
          this.id = retorno.data.id;
          this.etapaCobranca.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "true") {
          this.$router.push(
            (this.$route.path.includes("assinaturas") ? "/assinaturas" : this.$route.path.includes("comercial") ? "/comercial" : "/financeiro") + "/etapa-cobranca"
          );
          this.$router.go((this.$route.path.includes("assinaturas") ? "/assinaturas" : this.$route.path.includes("comercial") ? "/comercial" : "/financeiro") + "/etapa-cobranca");
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push(
              (this.$route.path.includes("assinaturas") ? "/assinaturas" : this.$route.path.includes("comercial") ? "/comercial" : "/financeiro") + "/etapas-cobranca"
            );
          }, 2000);
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },

    async carregarGrupos() {
      try {
        //* Carrega os grupos de etapas de cobrança
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "ass/etapa-cobranca-grupo/lista" +
            (this.$route.path.includes("assinaturas") ? "?assinaturas=1" : this.$route.path.includes("comercial") ? "?comercial=1" : "")
        );
        this.gruposEtapas = response.data;
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os grupos de etapas de cobrança";
        this.snackErro = true;
      }
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    pLetra(text) {
      return util.pLetra(text);
    }
  },

  watch: {
    grupoSelecionado(val) {
      if (val) {
        this.etapaCobranca.idGrupo = val.id;
        if (val.novas == null) {
          this.etapaCobranca.email = 0;
          this.etapaCobranca.corte = 0;
          this.etapaCobranca.cancelamento = 0;
        }
      }
    }
  },

  async created() {
    this.isUpdating = false;
    this.bloqueiaBotoes = true;
    this.id = this.$route.params.id ? this.$route.params.id : "";
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

    await this.carregarGrupos();

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (
        direito == "WEBSUITA_ADMIN_SUITA" ||
        (this.$route.path.includes("assinaturas") && direito == "ASSINATURAS_ETAPAS_COBRANÇA_CRIAR") ||
        (this.$route.path.includes("comercial") && direito == "COMERCIAL_ETAPAS_COBRANÇA_CRIAR") ||
        (this.$route.path.includes("financeiro") && direito == "FINANCEIRO_ETAPAS_COBRANÇA_CRIAR")
      ) {
        this.direitoCriar = true;
      }
    });

    //* Se é edição, carrega o elemento
    if (this.id == "") {
      if (this.$route.path.includes("assinaturas")) {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_ETAPAS_COBRANÇA_CRIAR"]);
      } else if (this.$route.path.includes("comercial")) {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "COMERCIAL_ETAPAS_COBRANÇA_CRIAR"]);
      } else {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_ETAPAS_COBRANÇA_CRIAR"]);
      }
    } else {
      if (this.$route.path.includes("assinaturas")) {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_ETAPAS_COBRANÇA_EDITAR"]);
      } else if (this.$route.path.includes("comercial")) {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "COMERCIAL_ETAPAS_COBRANÇA_EDITAR"]);
      } else {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_ETAPAS_COBRANÇA_EDITAR"]);
      }
      try {
        //* Carrega o elemento - somente se for público (idSite=null) ou for do site do usuário logado
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/etapa-cobranca/" + this.id + "?idSite=" + this.idSitePrincipal);

        if (!response.data) {
          this.$router.push(
            (this.$route.path.includes("assinaturas") ? "/assinaturas" : this.$route.path.includes("comercial") ? "/comercial" : "/financeiro") + "/etapas-cobranca"
          );
          return;
        }
        this.etapaCobranca = response.data;
        this.gruposEtapas.forEach(element => {
          if (element.id == this.etapaCobranca.idGrupo) {
            this.grupoSelecionado = element;
          }
        });
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os dados";
        this.snackErro = true;
      }
    }
    setTimeout(() => {
      this.bloqueiaBotoes = false;
    }, 1000);
  }
};
</script>
