<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-form ref="formElemento" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-expansion-panels v-model="painel" focusable>
            <v-expansion-panel dark class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>{{ receber ? "Cliente" : "Fornecedor" }} </strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider></v-divider>
                <v-container fluid grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 md3>
                      <v-text-field
                        v-model="filtrosSelecionados.cliente.id"
                        label="ID"
                        v-mask="'######'"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        clearable
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md9>
                      <v-text-field
                        v-model="filtrosSelecionados.cliente.identificacao"
                        :label="
                          'Identificação (' + ($store.state.usuario.site_pais == 'BR' ? (filtrosSelecionados.cliente.identificacao.length == 14 ? 'CNPJ' : 'CPF') : 'NIF') + ')'
                        "
                        v-mask="'##############'"
                        color="blue-grey lighten-2"
                        clearable
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12>
                      <v-text-field
                        v-model="filtrosSelecionados.cliente.nome"
                        label="Nome/Razão Social do Cliente"
                        maxlength="100"
                        color="blue-grey lighten-2"
                        @change="filtrosSelecionados.cliente.nome = pLetra(filtrosSelecionados.cliente.nome)"
                        clearable
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Operação</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider></v-divider>
                <v-container fluid grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 md4 v-if="receber">
                      <v-autocomplete
                        v-model="filtrosSelecionados.operacao.origem"
                        :items="itensOrigem"
                        :filter="filtrarObjetos"
                        label="Origem"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12 md4>
                      <v-text-field
                        v-model="filtrosSelecionados.operacao.id"
                        label="ID Operação"
                        v-mask="'######'"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        clearable
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md4 v-if="$store.state.usuario.site_protheus">
                      <v-text-field
                        v-model="filtrosSelecionados.operacao.idProtheus"
                        label="ID Protheus"
                        v-mask="'######'"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        clearable
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md4>
                      <v-autocomplete
                        v-model="filtrosSelecionados.operacao.situacao"
                        :items="itensSituacao"
                        :filter="filtrarObjetos"
                        label="Situação"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        clearable
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12 md4>
                      <v-text-field v-model="filtrosSelecionados.operacao.nroParcelas" label="Parcelas" v-mask="'##'" color="blue-grey lighten-2" clearable></v-text-field>
                    </v-flex>

                    <v-flex xs12 v-if="!receber">
                      <v-autocomplete
                        v-model="filtrosSelecionados.operacao.idContaD"
                        :items="despesas"
                        :filter="filtrarObjetos"
                        label="Conta Destino (D)"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        clearable
                        multiple
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12>
                      <v-autocomplete
                        v-model="filtrosSelecionados.operacao.idContaC"
                        :items="receitas"
                        :filter="filtrarObjetos"
                        label="Conta Origem (C)"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        clearable
                        multiple
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12>
                      <v-autocomplete
                        v-model="filtrosSelecionados.operacao.idCentroCusto"
                        :items="centrosCusto"
                        :filter="filtrarObjetos"
                        label="Centro de Custo"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        clearable
                        multiple
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12>
                      <v-autocomplete
                        v-model="filtrosSelecionados.operacao.idUsuario"
                        :items="usuarios"
                        :filter="filtrarObjetos"
                        label="Usuário"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        clearable
                        multiple
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12 md6 v-if="!receber">
                      <v-autocomplete
                        v-model="filtrosSelecionados.operacao.mensal"
                        :items="itensSimNao"
                        :filter="filtrarObjetos"
                        label="Mensal"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        clearable
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12 md6 v-if="!receber">
                      <v-autocomplete
                        v-model="filtrosSelecionados.operacao.confirmada"
                        :items="itensConfirmada"
                        :filter="filtrarObjetos"
                        label="Confirmada"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        clearable
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12 md12>
                      <v-text-field v-model="filtrosSelecionados.operacao.descricao" label="Descrição" maxlength="100" color="blue-grey lighten-2" clearable></v-text-field>
                    </v-flex>

                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">
                        Bruto
                      </h4>
                      <v-divider></v-divider>
                      <v-layout wrap>
                        <v-flex xs6 md6>
                          <v-currency-field
                            :locale="`pt-${$store.state.usuario.site_pais}`"
                            v-model="filtrosSelecionados.operacao.vlrBrutoInicio"
                            label="Mínimo"
                            :allowNegative="false"
                            :max="9999999"
                            color="blue-grey lighten-2"
                          ></v-currency-field>
                        </v-flex>
                        <v-flex xs6 md6>
                          <v-currency-field
                            :locale="`pt-${$store.state.usuario.site_pais}`"
                            v-model="filtrosSelecionados.operacao.vlrBrutoFim"
                            label="Máximo"
                            :allowNegative="false"
                            :max="9999999"
                            color="blue-grey lighten-2"
                          ></v-currency-field>
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">
                        Líquido
                      </h4>
                      <v-divider></v-divider>
                      <v-layout wrap>
                        <v-flex xs6 md6>
                          <v-currency-field
                            :locale="`pt-${$store.state.usuario.site_pais}`"
                            v-model="filtrosSelecionados.operacao.vlrLiquidoInicio"
                            label="Mínimo"
                            :allowNegative="false"
                            :max="9999999"
                            color="blue-grey lighten-2"
                          ></v-currency-field>
                        </v-flex>
                        <v-flex xs6 md6>
                          <v-currency-field
                            :locale="`pt-${$store.state.usuario.site_pais}`"
                            v-model="filtrosSelecionados.operacao.vlrLiquidoFim"
                            label="Máximo"
                            :allowNegative="false"
                            :max="9999999"
                            color="blue-grey lighten-2"
                          ></v-currency-field>
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">
                        Registro
                      </h4>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-menu v-model="mnudtCriacaoInicio" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="filtrosSelecionados.operacao.dtCriacaoInicio"
                            label="Início"
                            v-mask="'##/##/####'"
                            :rules="[value => valida_data(value) || 'Data inválida']"
                            color="blue-grey lighten-2"
                            clearable
                            prepend-icon="event"
                            v-on="on"
                            autocomplete="disabled"
                            :name="Math.random()"
                            @click:clear="dtCriacaoInicioUS = null"
                            @keyup="
                              dtCriacaoInicioUS = valida_data(filtrosSelecionados.operacao.dtCriacaoInicio) ? formatDateUS(filtrosSelecionados.operacao.dtCriacaoInicio) : null
                            "
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="dtCriacaoInicioUS" locale="pt-br" no-title @input="mnudtCriacaoInicio = false"></v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-menu v-model="mnudtCriacaoFim" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="filtrosSelecionados.operacao.dtCriacaoFim"
                            label="Fim"
                            v-mask="'##/##/####'"
                            :rules="[value => valida_data(value) || 'Data inválida']"
                            color="blue-grey lighten-2"
                            clearable
                            prepend-icon="event"
                            v-on="on"
                            autocomplete="disabled"
                            :name="Math.random()"
                            @click:clear="dtCriacaoFimUS = null"
                            @keyup="dtCriacaoFimUS = valida_data(filtrosSelecionados.operacao.dtCriacaoFim) ? formatDateUS(filtrosSelecionados.operacao.dtCriacaoFim) : null"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="dtCriacaoFimUS" locale="pt-br" no-title @input="mnudtCriacaoFim = false"></v-date-picker>
                      </v-menu>
                    </v-flex>

                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">
                        Operação
                      </h4>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-menu v-model="mnudtOperacaoInicio" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="filtrosSelecionados.operacao.dtOperacaoInicio"
                            label="Início"
                            v-mask="'##/##/####'"
                            :rules="[value => valida_data(value) || 'Data inválida']"
                            color="blue-grey lighten-2"
                            clearable
                            prepend-icon="event"
                            v-on="on"
                            autocomplete="disabled"
                            :name="Math.random()"
                            @click:clear="dtOperacaoInicioUS = null"
                            @keyup="
                              dtOperacaoInicioUS = valida_data(filtrosSelecionados.operacao.dtOperacaoInicio) ? formatDateUS(filtrosSelecionados.operacao.dtOperacaoInicio) : null
                            "
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="dtOperacaoInicioUS" locale="pt-br" no-title @input="mnudtOperacaoInicio = false"></v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-menu v-model="mnudtOperacaoFim" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="filtrosSelecionados.operacao.dtOperacaoFim"
                            label="Fim"
                            v-mask="'##/##/####'"
                            :rules="[value => valida_data(value) || 'Data inválida']"
                            color="blue-grey lighten-2"
                            clearable
                            prepend-icon="event"
                            v-on="on"
                            autocomplete="disabled"
                            :name="Math.random()"
                            @click:clear="dtOperacaoFimUS = null"
                            @keyup="dtOperacaoFimUS = valida_data(filtrosSelecionados.operacao.dtOperacaoFim) ? formatDateUS(filtrosSelecionados.operacao.dtOperacaoFim) : null"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="dtOperacaoFimUS" locale="pt-br" no-title @input="mnudtOperacaoFim = false"></v-date-picker>
                      </v-menu>
                    </v-flex>

                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">
                        Cancelamento
                      </h4>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-container fluid grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12>
                          <v-autocomplete
                            v-model="filtrosSelecionados.operacao.idUsuarioCancelamento"
                            :items="usuarios"
                            :filter="filtrarObjetos"
                            label="Usuário"
                            item-text="nome"
                            item-value="id"
                            autocomplete="disabled"
                            :name="Math.random()"
                            no-data-text
                            clearable
                            multiple
                          ></v-autocomplete>
                        </v-flex>

                        <v-flex xs12>
                          <h4 class="overline font-weight-black font-italic">
                            Registro
                          </h4>
                          <v-divider></v-divider>
                        </v-flex>
                        <v-flex xs12 md6>
                          <v-menu v-model="mnudtCancelamentoInicio" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="filtrosSelecionados.operacao.dtCancelamentoInicio"
                                label="Início"
                                v-mask="'##/##/####'"
                                :rules="[value => valida_data(value) || 'Data inválida']"
                                color="blue-grey lighten-2"
                                clearable
                                prepend-icon="event"
                                v-on="on"
                                autocomplete="disabled"
                                :name="Math.random()"
                                @click:clear="dtCancelamentoInicioUS = null"
                                @keyup="
                                  dtCancelamentoInicioUS = valida_data(filtrosSelecionados.operacao.dtCancelamentoInicio)
                                    ? formatDateUS(filtrosSelecionados.operacao.dtCancelamentoInicio)
                                    : null
                                "
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="dtCancelamentoInicioUS" locale="pt-br" no-title @input="mnudtCancelamentoInicio = false"></v-date-picker>
                          </v-menu>
                        </v-flex>
                        <v-flex xs12 md6>
                          <v-menu v-model="mnudtCancelamentoFim" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="filtrosSelecionados.operacao.dtCancelamentoFim"
                                label="Fim"
                                v-mask="'##/##/####'"
                                :rules="[value => valida_data(value) || 'Data inválida']"
                                color="blue-grey lighten-2"
                                clearable
                                prepend-icon="event"
                                v-on="on"
                                autocomplete="disabled"
                                :name="Math.random()"
                                @click:clear="dtCancelamentoFimUS = null"
                                @keyup="
                                  dtCancelamentoFimUS = valida_data(filtrosSelecionados.operacao.dtCancelamentoFim)
                                    ? formatDateUS(filtrosSelecionados.operacao.dtCancelamentoFim)
                                    : null
                                "
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="dtCancelamentoFimUS" locale="pt-br" no-title @input="mnudtCancelamentoFim = false"></v-date-picker>
                          </v-menu>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-layout>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-layout>
      </v-container>
    </v-form>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
export default {
  props: {
    receber: { type: Boolean, default: false }
  },
  data() {
    this.$vuetify.theme.primary = "blue lighten-2";
    return {
      filtrosSelecionados: {
        cliente: {
          id: null,
          identificacao: "",
          // email: null,
          nome: null
          // nomeFantasia: null
        },
        operacao: {
          origem: "FIN",
          situacao: null,
          id: null,
          idProtheus: null,
          dtCriacaoInicio: null,
          dtCriacaoFim: null,
          dtOperacaoInicio: null,
          dtOperacaoFim: null,
          dtCancelamentoInicio: null,
          dtCancelamentoFim: null,
          nroParcelas: null,
          vlrBrutoInicio: 0,
          vlrBrutoFim: 0,
          vlrLiquidoInicio: 0,
          vlrLiquidoFim: 0,
          idContaD: null,
          idContaC: null,
          idCentroCusto: null,
          idUsuario: null,
          idUsuarioCancelamento: null,
          mensal: null,
          confirmada: null,
          descricao: null
        }
      },

      dtCriacaoInicioUS: null,
      mnudtCriacaoInicio: false,
      dtCriacaoFimUS: null,
      mnudtCriacaoFim: false,

      dtOperacaoInicioUS: null,
      mnudtOperacaoInicio: false,
      dtOperacaoFimUS: null,
      mnudtOperacaoFim: false,

      dtCancelamentoInicioUS: null,
      mnudtCancelamentoInicio: false,
      dtCancelamentoFimUS: null,
      mnudtCancelamentoFim: false,

      operacaoDefault: {
        cliente: {
          id: null,
          identificacao: "",
          // email: null,
          nome: null
          // nomeFantasia: null
        },
        operacao: {
          origem: "FIN",
          situacao: null,
          id: null,
          idProtheus: null,
          dtCriacaoInicio: null,
          dtCriacaoFim: null,
          dtOperacaoInicio: null,
          dtOperacaoFim: null,
          dtCancelamentoInicio: null,
          dtCancelamentoFim: null,
          nroParcelas: null,
          vlrBrutoInicio: 0,
          vlrBrutoFim: 0,
          vlrLiquidoInicio: 0,
          vlrLiquidoFim: 0,
          idContaD: null,
          idContaC: null,
          idCentroCusto: null,
          idUsuario: null,
          idUsuarioCancelamento: null,
          mensal: null,
          confirmada: null,
          descricao: null
        }
      },

      itensOrigem: [
        { id: "FIN", nome: "Financeiro" },
        { id: "ANU", nome: "Anúncio" },
        { id: "ASS", nome: "Assinatura" },
        { id: "PAC", nome: "Pacote" },
        { id: "AVU", nome: "Venda Avulsa" }
      ],
      itensSituacao: [
        { id: 1, nome: "Ativa" },
        { id: 0, nome: "Cancelada" }
      ],
      itensSimNao: [
        { id: 1, nome: "SIM" },
        { id: 0, nome: "NÃO" }
      ],
      itensConfirmada: [
        { id: 0, nome: "Orçamento" },
        { id: 1, nome: "Pagamento" }
      ],

      usuarios: [],
      receitas: [],
      despesas: [],
      centrosCusto: [],

      idSitePrincipal: null,
      id: "",
      painel: [0, 0, 0],

      autoUpdate: true
    };
  },

  methods: {
    async carregarUsuarios() {
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/site-usuario/usuariosSite/" + this.idSitePrincipal);
      this.usuarios = response.data;
    },
    async carregarReceitas() {
      //* Busca todas as contas de receitas de venda
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/conta/receitas?idSite=" + this.idSitePrincipal);
      this.receitas = response.data;
    },
    async carregarDespesas() {
      //* Busca todas as contas de despesas
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/conta/despesas?idSite=" + this.idSitePrincipal);
      this.despesas = response.data;
      this.despesas.forEach(element => {
        element.nomeS = element.nome;
        element.nome = element.nome + " - " + element.mae;
      });
    },
    async carregarDestino() {
      //* Busca a conta FORNECEDOR
      const response = await api.get(
        process.env.VUE_APP_URL_WEB_API + "fin/conta/tipo?tipo=CONTA_PAGAR" //FORNECEDOR
      );
      this.receitas = [];
      this.contaCSelecionada = {};
      response.data.forEach(element => {
        if (!["PERMUTA_PAGAR"].includes(element.tipo)) {
          //"CREDITO", descontinuado
          this.receitas.push(element);
        }
        if (!this.contaCSelecionada.id && element.tipo == "FORNECEDOR") {
          this.contaCSelecionada = element;
        }
      });
    },
    async carregarCentrosCusto() {
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/centro-custo/lista");
      this.centrosCusto = response.data;
    },

    filtroUrlParametros() {
      var filtro = "";

      //* Verifica os campos que foram preenchidos

      //* Monta o filtro para Cliente
      if (this.filtrosSelecionados.cliente) {
        if (this.filtrosSelecionados.cliente.id) {
          filtro += "&idCliente=" + this.filtrosSelecionados.cliente.id;
        }
        if (this.filtrosSelecionados.cliente.identificacao) {
          filtro += "&identificacao=" + this.filtrosSelecionados.cliente.identificacao;
        }
        // if (this.filtrosSelecionados.cliente.email) {
        //   filtro += "&email=" + this.filtrosSelecionados.cliente.email;
        // }
        if (this.filtrosSelecionados.cliente.nome) {
          filtro += "&nome=" + this.filtrosSelecionados.cliente.nome;
        }
        // if (this.filtrosSelecionados.cliente.nomeFantasia) {
        //   filtro +=
        //     "&nomeFantasia=" + this.filtrosSelecionados.cliente.nomeFantasia;
        // }
      }

      //* Monta o filtro para Operação
      if (this.filtrosSelecionados.operacao) {
        if (this.filtrosSelecionados.operacao.id) {
          filtro += "&idOperacao=" + this.filtrosSelecionados.operacao.id;
        }
        if (this.filtrosSelecionados.operacao.idProtheus) {
          filtro += "&idProtheus=" + this.filtrosSelecionados.operacao.idProtheus;
        }
        if (this.filtrosSelecionados.operacao.situacao != null) {
          filtro += "&situacao=" + this.filtrosSelecionados.operacao.situacao;
        }
        if (this.filtrosSelecionados.operacao.nroParcelas) {
          filtro += "&nroParcelas=" + this.filtrosSelecionados.operacao.nroParcelas;
        }
        if (!this.receber && this.filtrosSelecionados.operacao.idContaD && this.filtrosSelecionados.operacao.idContaD.length) {
          filtro += "&idContaD=" + this.filtrosSelecionados.operacao.idContaD.join(",");
        }
        if (this.filtrosSelecionados.operacao.idContaC && this.filtrosSelecionados.operacao.idContaC.length) {
          filtro += "&idContaC=" + this.filtrosSelecionados.operacao.idContaC.join(",");
        }
        if (this.filtrosSelecionados.operacao.idCentroCusto && this.filtrosSelecionados.operacao.idCentroCusto.length) {
          filtro += "&idCentroCusto=" + this.filtrosSelecionados.operacao.idCentroCusto.join(",");
        }
        if (this.filtrosSelecionados.operacao.idUsuario && this.filtrosSelecionados.operacao.idUsuario.length) {
          filtro += "&idUsuario=" + this.filtrosSelecionados.operacao.idUsuario.join(",");
        }
        if (this.filtrosSelecionados.operacao.mensal != null) {
          filtro += "&mensal=" + this.filtrosSelecionados.operacao.mensal;
        }
        if (this.filtrosSelecionados.operacao.confirmada != null) {
          filtro += "&confirmada=" + this.filtrosSelecionados.operacao.confirmada;
        }
        if (this.filtrosSelecionados.operacao.descricao) {
          filtro += "&descricao=" + this.filtrosSelecionados.operacao.descricao;
        }

        if (this.filtrosSelecionados.operacao.vlrBrutoInicio) {
          filtro += "&vlrBrutoInicio=" + this.filtrosSelecionados.operacao.vlrBrutoInicio;
        }
        if (this.filtrosSelecionados.operacao.vlrBrutoFim) {
          filtro += "&vlrBrutoFim=" + this.filtrosSelecionados.operacao.vlrBrutoFim;
        }

        if (this.filtrosSelecionados.operacao.vlrLiquidoInicio) {
          filtro += "&vlrLiquidoInicio=" + this.filtrosSelecionados.operacao.vlrLiquidoInicio;
        }
        if (this.filtrosSelecionados.operacao.vlrLiquidoFim) {
          filtro += "&vlrLiquidoFim=" + this.filtrosSelecionados.operacao.vlrLiquidoFim;
        }

        if (this.filtrosSelecionados.operacao.dtCriacaoInicio) {
          filtro += "&dtCriacaoInicio=" + this.filtrosSelecionados.operacao.dtCriacaoInicio;
        }
        if (this.filtrosSelecionados.operacao.dtCriacaoFim) {
          filtro += "&dtCriacaoFim=" + this.filtrosSelecionados.operacao.dtCriacaoFim;
        }

        if (this.filtrosSelecionados.operacao.dtOperacaoInicio) {
          filtro += "&dtOperacaoInicio=" + this.filtrosSelecionados.operacao.dtOperacaoInicio;
        }
        if (this.filtrosSelecionados.operacao.dtOperacaoFim) {
          filtro += "&dtOperacaoFim=" + this.filtrosSelecionados.operacao.dtOperacaoFim;
        }

        if (this.filtrosSelecionados.operacao.idUsuarioCancelamento && this.filtrosSelecionados.operacao.idUsuarioCancelamento.length) {
          filtro += "&idUsuarioCancelamento=" + this.filtrosSelecionados.operacao.idUsuarioCancelamento.join(",");
        }
        if (this.filtrosSelecionados.operacao.dtCancelamentoInicio) {
          filtro += "&dtCancelamentoInicio=" + this.filtrosSelecionados.operacao.dtCancelamentoInicio;
        }
        if (this.filtrosSelecionados.operacao.dtCancelamentoFim) {
          filtro += "&dtCancelamentoFim=" + this.filtrosSelecionados.operacao.dtCancelamentoFim;
        }
      }

      if (this.filtrosSelecionados.operacao.origem) {
        filtro += "&origem=" + this.filtrosSelecionados.operacao.origem;
      } else {
        filtro += "&origem=FIN";
      }

      if (filtro) {
        filtro += "&idSite=" + this.idSitePrincipal;
      }

      return filtro;
    },

    pLetra(text) {
      return util.pLetra(text);
    },
    formatDateUS(date) {
      if (date && date.length == 10) {
        return util.formatDateUS(date);
      }
    },
    valida_data(date) {
      if (date) {
        return util.valida_data_BR(date);
      } else {
        return true;
      }
    },

    limpar() {
      this.filtrosSelecionados = JSON.parse(JSON.stringify(this.operacaoDefault));
      this.painel = [0, 0, 0];
      this.dtCriacaoInicioUS = null;
      this.dtCriacaoFimUS = null;
      this.dtOperacaoInicioUS = null;
      this.dtOperacaoFimUS = null;
      this.dtCancelamentoInicioUS = null;
      this.dtCancelamentoFimUS = null;
    },
    //* Chamadas a métodos públicos
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    filtrarItens(item, queryText, itemText) {
      return util.filtrarItens(item, queryText, itemText);
    }
  },

  watch: {
    receber() {
      if (this.receber) {
        this.carregarReceitas();
      } else {
        this.carregarDespesas();
        this.carregarDestino();
      }
    },
    dtCriacaoInicioUS(val) {
      this.filtrosSelecionados.operacao.dtCriacaoInicio = util.formatDate(val);
      this.mnudtCriacaoInicio = false;
    },
    dtCriacaoFimUS(val) {
      this.filtrosSelecionados.operacao.dtCriacaoFim = util.formatDate(val);
      this.mnudtCriacaoFim = false;
    },
    dtOperacaoInicioUS(val) {
      this.filtrosSelecionados.operacao.dtOperacaoInicio = util.formatDate(val);
      this.mnudtOperacaoInicio = false;
    },
    dtOperacaoFimUS(val) {
      this.filtrosSelecionados.operacao.dtOperacaoFim = util.formatDate(val);
      this.mnudtOperacaoFim = false;
    },
    dtCancelamentoInicioUS(val) {
      this.filtrosSelecionados.operacao.dtCancelamentoInicio = util.formatDate(val);
      this.mnudtCancelamentoInicio = false;
    },
    dtCancelamentoFimUS(val) {
      this.filtrosSelecionados.operacao.dtCancelamentoFim = util.formatDate(val);
      this.mnudtCancelamentoFim = false;
    }
  },

  async created() {
    try {
      this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

      //* Carrega os itens dos selects
      this.carregarUsuarios();
      this.carregarCentrosCusto();

      if (this.receber) {
        await this.carregarReceitas();
      } else {
        await this.carregarDespesas();
        await this.carregarDestino();
      }
    } catch {
      // this.mensagem = "Ocorreu um erro ao carregar os dados";
      // this.snackErro = true;
    }
  }
};
</script>
