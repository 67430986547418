<template>
  <div id="app">
    <v-app id="inspire">
      <v-card>
        <v-toolbar color="#333333">
          <v-icon color="white" class="mr-2">supervisor_account</v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light">Recuperação</v-toolbar-title>
        </v-toolbar>

        <v-container fluid grid-list-md>
          <v-layout wrap>
            <v-flex xs12 md6>
              <v-autocomplete
                autofocus
                v-model="motivoCancelamento"
                :items="motivosCancelamento"
                :filter="filtrarObjetos"
                label="Motivo de Cancelamento"
                item-text="nome"
                item-value="id"
                clearable
                no-data-text
              ></v-autocomplete>
            </v-flex>
            <v-spacer></v-spacer>

            <v-flex xs5 offset-md2 md2>
              <v-menu v-model="mnuDtInicio" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dtInicial"
                    label="Data Inicial"
                    v-mask="'##/##/####'"
                    :rules="[value => valida_data(value) || 'Data inválida']"
                    prepend-icon="event"
                    v-on="on"
                    color="blue-grey lighten-2"
                    autocomplete="disabled"
                    :name="Math.random()"
                    @keyup="dtInicioUS = valida_data(dtInicial) ? formatDateUS(dtInicial) : null"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dtInicioUS" locale="pt-br" no-title @input="mnuDtInicio = false"></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs5 md2>
              <v-menu v-model="mnuDtFim" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dtFinal"
                    label="Data Final"
                    v-mask="'##/##/####'"
                    :rules="[value => valida_data(value) || 'Data inválida']"
                    prepend-icon="event"
                    v-on="on"
                    color="blue-grey lighten-2"
                    autocomplete="disabled"
                    :name="Math.random()"
                    @keyup="dtFimUS = valida_data(dtFinal) ? formatDateUS(dtFinal) : null"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dtFimUS" locale="pt-br" no-title @input="mnuDtFim = false"></v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
        </v-container>

        <v-layout wrap>
          <v-spacer></v-spacer>
          <v-flex xs1>
            <v-btn v-if="direitoExportar" color="blue" :loading="loadingExp" :disabled="loadingExp" fab small @click="pesquisaDados(true)">
              <v-icon color="white" title="Exportar">description</v-icon>
            </v-btn>
          </v-flex>
          <v-flex xs4>
            <v-text-field
              v-model="filtroPesquisa"
              append-icon="search"
              label="Digite para pesquisar..."
              placeholder="Pesquisa pela assinatura, cliente, plano"
              single-line
              hide-details
              @keyup.enter="limpaFiltro"
              @click:append="limpaFiltro"
              autocomplete="disabled"
              :name="Math.random()"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <br />

        <v-data-table
          mobile-breakpoint="0"
          :headers="headers"
          :items="registros"
          :options.sync="options"
          no-results-text
          :no-data-text="mensagemPesquisa"
          :footer-props="rodape"
          disable-sort
          :disable-pagination="carregando"
          :loading="loading"
          loading-text="Carregando..."
        >
          <template v-slot:[getItemActions()]="{ item }">
            <v-icon
              v-if="direitoAssinante && (item.atendente == null || item.atendente == $store.state.usuario.nome)"
              color="orange"
              title="Assinante"
              @click="visualizarAssinante(item)"
              >account_circle</v-icon
            >
            <v-icon
              v-if="direitoAssinatura && (item.atendente == null || item.atendente == $store.state.usuario.nome)"
              color="red"
              title="Assinatura"
              @click="visualizarAssinatura(item)"
              >assignment</v-icon
            >
            <v-icon v-if="item.atendente == null || item.atendente == $store.state.usuario.nome" color="blue" title="Contato" @click="editarContato(item)">call</v-icon>
          </template>

          <template v-slot:[getItemAtendente()]="{ item }">
            {{ item.atendente }}
            <v-icon color="blue-grey" title="Liberar Assinante" v-if="item.atendente != null" @click="bloquearLiberarCliente(item, false)">call_end</v-icon>
          </template>

          <template v-slot:[getItemDtCriacao()]="{ item }">{{ formatDateTime(item.dtCriacao) }}</template>
          <template v-slot:[getItemDtFim()]="{ item }">{{ formatDate(item.dtFim) }}</template>
          <template v-slot:[getItemDtRegistro()]="{ item }">{{ formatDate(item.dtRegistro) }}</template>
          <template v-slot:[getItemDtAgendamento()]="{ item }">{{ formatDate(item.dtAgendamento) }}</template>
        </v-data-table>
        <div class="text-xs-center pt-2 marg-bt">
          <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 7" circle></v-pagination>
        </div>

        <v-dialog persistent v-model="dialogContato" max-width="1200px">
          <v-card>
            <Contato
              idEtapa=""
              idParcela=""
              :idAssinatura="idAssinatura"
              :idRenovacao="idRenovacao"
              idAnuncio=""
              :idCliente="idCliente"
              :cliente="cliente"
              :tipo="tipo"
              :dialogContato.sync="dialogContato"
            />
          </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
        <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
      </v-card>
    </v-app>
  </div>
</template>

<script>
import Contato from "@/views/ass/Contato.vue";
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  components: {
    Contato
  },
  data() {
    return {
      headers: [
        { text: "Ações", value: "actions", width: "110" },
        { text: "Atendente", value: "atendente", divider: true },
        { text: "Assinatura", value: "idAssinatura" },
        { text: "Assinante", value: "cliente" },
        { text: "Telefones", value: "telefones" },
        { text: "Plano", value: "plano" },
        { text: "Criação", value: "dtCriacao" },
        { text: "Término", value: "dtFim", divider: true },
        { text: "Registro", value: "dtRegistro" },
        { text: "Agendamento", value: "dtAgendamento" },
        { text: "Usuário", value: "usuario" },
        { text: "Motivo Agendamento", value: "motivoCancelamento" }
      ],
      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },
      motivoCancelamento: null,
      motivosCancelamento: [],

      dtInicial: null,
      dtFinal: null,

      dtInicioUS: null,
      mnuDtInicio: false,

      dtFimUS: null,
      mnuDtFim: false,

      idAssinatura: "",
      idRenovacao: "",
      idCliente: "",
      cliente: "",
      tipo: "",

      direitoAssinante: false,
      direitoAssinatura: false,
      direitoExportar: false,

      mensagemPesquisa: "",
      filtroPesquisa: "",
      registros: [],
      registrosPagina: 10,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      carregando: true,
      options: {},
      snackbar: false,
      snackErro: false,
      mensagem: false,
      ordenacao: "",
      ordem: "",
      loading: false,
      loadingExp: false,
      dialogContato: false
    };
  },
  methods: {
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    valida_data(date) {
      if (date) {
        return util.valida_data_BR(date);
      } else {
        return true;
      }
    },
    formatDateUS(date) {
      return util.formatDateUS(date);
    },

    formatDate(date) {
      return util.formatDate(date);
    },
    formatDateTime(date) {
      return util.formatDateTime(date);
    },

    ...mapMutations(["POSSUI_DIREITO"]),

    getItemActions() {
      return "item.actions";
    },
    getItemAtendente() {
      return "item.atendente";
    },
    getItemDtCriacao() {
      return "item.dtCriacao";
    },
    getItemDtFim() {
      return "item.dtFim";
    },
    getItemDtRegistro() {
      return "item.dtRegistro";
    },
    getItemDtAgendamento() {
      return "item.dtAgendamento";
    },

    limpaFiltro() {
      this.paginaAtual = 1;
      this.pesquisaDados(false);
    },

    async pesquisaDados(exportar) {
      this.registros = [];
      var filtroAdicional = "";
      if (!this.dtInicial || !this.dtFinal) {
        this.mensagem = "Informe a data inicial e final";
        this.snackbar = true;
        this.itens = [];
        this.totalItens = 0;
        this.pages = 0;
        return;
      } else {
        if (util.valida_data(util.formatDateUS(this.dtInicial)) && util.valida_data(util.formatDateUS(this.dtFinal))) {
          filtroAdicional = "&dtInicial=" + util.formatDateUS(this.dtInicial) + "&dtFinal=" + util.formatDateUS(this.dtFinal);
        } else {
          this.mensagem = "Período (Data Inicial e Final) inválido";
          this.snackErro = true;
          this.itens = [];
          this.totalItens = 0;
          this.pages = 0;
          return;
        }
        if (this.dtInicial && this.dtFinal) {
          if (new Date(this.dtInicial) > new Date(this.dtFinal)) {
            this.mensagem = "Verifique as datas de início e fim";
            this.snackErro = true;
            return;
          }
        }
      }
      if (this.motivoCancelamento) {
        filtroAdicional += "&idMotivoCancelamento=" + this.motivoCancelamento;
      }

      var l = null;
      if (exportar) {
        this.loader = "loadingExp";
        l = this.loader;
        this[l] = !this[l];
        setTimeout(() => (this[l] = false), 3000);
        this.loader = null;
      }
      if (!exportar) {
        //sinaliza que está carregando
        this.carregando = true;
        this.mensagemPesquisa = "";
        this.loading = true;
      } else {
        this.mensagemPesquisa = "MONTE SEU FILTRO PARA VISUALIZAR AS ASSINATURAS...";
      }

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "ass/assinatura/recuperacao?pagina=" +
            this.paginaAtual +
            "&tamanho=" +
            this.registrosPagina +
            "&filtro=" +
            this.filtroPesquisa +
            filtroAdicional +
            "&ordenacao=" +
            this.ordenacao +
            "&ordem=" +
            this.ordem +
            (exportar ? "&exportar=1" : "")
        );

        if (!exportar) {
          //seta as variaveis com o retorno
          this.registros = response.data.data;
          this.totalRegistros = response.data.total;
          this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
          this.rodape.pageText = this.totalRegistros + " itens";
          //fecha o loading
          this.carregando = false;
          //se não achar registro seta a mensagem senão deixa em branco
          this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
        }
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else {
          this.mensagem = "Erro ao buscar dados: " + e;
          this.snackErro = true;
        }
      } finally {
        this.loading = false;
      }
    },
    async carregarMotivos() {
      try {
        var idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
        //* Carrega os motivos de cancelamento de recuperacao
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/motivo-cancelamento/lista?idSite=" + idSitePrincipal + "&recuperacao=1");
        this.motivosCancelamento = response.data;
      } catch {
        this.mensagem = "Ocorreu um erro ao carregar os motivos de cancelamento";
        this.snackErro = true;
      }
    },

    async clienteLiberado(idCliente) {
      const retorno = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente/atendimento/" + idCliente + "?idUsuario=" + this.$store.state.usuario.id);
      return retorno.data.length == 1;
    },

    async bloquearLiberarCliente(item, bloquear) {
      var cobrancaItem = Object.assign({}, item);
      cobrancaItem.atendente = null;

      var clienteItem = { idUsuarioCall: null };
      if (bloquear) {
        clienteItem = { idUsuarioCall: this.$store.state.usuario.id };
        cobrancaItem.atendente = this.$store.state.usuario.nome;
      }
      try {
        await api.put(process.env.VUE_APP_URL_WEB_API + "adm/cliente/call/" + cobrancaItem.idCliente, clienteItem);

        var itemIndex = this.registros.indexOf(item);
        Object.assign(this.registros[itemIndex], cobrancaItem);
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },

    async visualizarAssinante(item) {
      const liberado = await this.clienteLiberado(item.idCliente);
      if (!liberado) {
        this.mensagem = "Este assinante está sendo atendido por outro usuário.";
        this.snackErro = true;
        return;
      }
      await this.bloquearLiberarCliente(item, true);
      window.open("/assinaturas/assinante/" + item.idCliente, "_blank");
    },
    async visualizarAssinatura(item) {
      const liberado = await this.clienteLiberado(item.idCliente);
      if (!liberado) {
        this.mensagem = "Este assinante está sendo atendido por outro usuário.";
        this.snackErro = true;
        return;
      }
      await this.bloquearLiberarCliente(item, true);
      window.open("/assinaturas/assinatura/" + item.idAssinatura, "_blank");
    },

    removeMotivo() {
      this.motivoCancelamento = null;
    },

    async editarContato(item) {
      const liberado = await this.clienteLiberado(item.idCliente);
      if (!liberado) {
        this.mensagem = "Este assinante está sendo atendido por outro usuário.";
        this.snackErro = true;
        return;
      }
      await this.bloquearLiberarCliente(item, true);
      this.idAssinatura = item.idAssinatura + "";
      this.idRenovacao = item.idRenovacao + "";
      this.idCliente = item.idCliente + "";
      this.cliente = item.cliente;
      this.tipo = "1"; //recuperação
      this.dialogContato = true;
    },
    closeContato() {
      this.dialogContato = false;
      this.idAssinatura = "";
      this.idRenovacao = "";
    }
  },
  beforeMount() {
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_RECUPERAÇÃO_ACESSO"]);
    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_ASSINANTES_EDITAR") {
        this.direitoAssinante = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_ASSINATURAS_EDITAR") {
        this.direitoAssinatura = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_RECUPERAÇÃO_EXPORTAR") {
        this.direitoExportar = true;
      }
    });
  },

  watch: {
    dtInicioUS() {
      this.dtInicial = util.formatDate(this.dtInicioUS);
      this.mnuDtInicio = false;
    },
    dtFimUS() {
      this.dtFinal = util.formatDate(this.dtFimUS);
      this.mnuDtFim = false;
    },

    dialogContato(val) {
      if (val == null) {
        this.pesquisaDados(false);
      }
      val || this.closeContato();
    },
    paginaAtual() {
      this.pesquisaDados(false);
    },
    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }
        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }
        this.pesquisaDados(false);
      }
    }
  },
  mounted() {
    this.carregarMotivos();
  }
};
</script>
