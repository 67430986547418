<template>
  <div id="app">
    <v-app id="inspire">
      <v-card class="hide-overflow fill-height">
        <v-toolbar height="50" color="#333333">
          <v-icon color="white" class="mr-2">{{ remessa ? "call_made" : "call_received" }}</v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light">
            {{ remessa ? "Remessas" : "Retornos" }} - Contas a
            {{ receber ? "Receber" : "Pagar" }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <router-link v-if="direitoParcelas && remessa" :to="'/financeiro/parcelas/' + (receber ? 'receber' : 'pagar') + '?idLoteFaturamento=0'" style="text-decoration: none">
            <v-btn v-if="cedentes" color="success" fab small>
              <v-icon color="white" title="Criação de Lote Inclusão">add</v-icon>
            </v-btn>
          </router-link>

          <v-btn color="success" fab small v-if="remessa && cedentes" @click="loteAltCanc" class="ma-1" :disabled="isUpdating">
            <v-icon color="white" title="Criação de Lote Alteração/Cancelamento">add_circle_outline</v-icon>
          </v-btn>

          <v-btn color="success" fab small v-if="remessa && celesc && receber" @click="loteCelesc" class="ma-1" :disabled="isUpdating">
            <v-icon color="white" title="Criação de Lote Celesc">offline_bolt</v-icon>
          </v-btn>
          <v-btn v-if="!remessa && cedentes" color="success" fab small @click="dialogUpload = true">
            <v-icon color="white" title="Envio de Arquivo Retorno">cloud_upload</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="px-6 mt-4">
          <v-layout wrap>
            <v-spacer></v-spacer>
            <v-flex xs12 md5>
              <v-text-field
                background-color="white"
                class="mt-2 mb-2 elevation-0"
                v-model="filtroPesquisa"
                append-icon="search"
                label="Digite para pesquisar..."
                placeholder="Pesquisa pelo cedente, tipo(1=Inc/2=Alt/3=Can), remessa, arquivo, número ou ID do lote"
                hide-details
                @keyup.enter="limpaFiltro"
                @click:append="limpaFiltro"
              ></v-text-field>
              <v-switch v-if="remessa" v-model="expandir" label="Expandir"></v-switch>
            </v-flex>
          </v-layout>

          <v-dialog persistente v-model="dialogUpload" max-width="400px">
            <v-card>
              <v-toolbar>
                <span class="headline">Envio de Arquivo</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="dialogUpload = false">
                  <v-icon color="darken-1">cancel</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <FileUpload :retorno="true" :receber="receber" />
              </v-card-text>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogLogBanco" max-width="1200px">
            <v-card>
              <LogBanco :idParcela="idParcela" :idLoteFaturamento="idLoteFaturamento" :dialogLogBanco.sync="dialogLogBanco" />
            </v-card>
          </v-dialog>

          <v-data-table
            mobile-breakpoint="0"
            :headers="!expandir ? (remessa ? (receber ? headers : headersPagar) : headersRetorno) : headersExpand"
            :items="registros"
            :options.sync="options"
            :no-data-text="mensagemPesquisa"
            :footer-props="rodape"
            :disable-sort="carregando"
            :disable-pagination="carregando"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon v-if="remessa && direitoParcelas && item.status != 'EXCLUÍDO'" color="blue" title="Visualizar Parcelas" @click="visualizarParcelas(item)"
                >add_circle_outline</v-icon
              >
              <v-icon v-if="!remessa && direitoParcelas && item.status != 'EXCLUÍDO'" color="blue" title="Visualizar Log do Banco" @click="visualizarLogBanco(item)">loupe</v-icon>
              <v-icon v-if="item.status == 'CORRENTE' && !item.remessa && remessa" color="blue" title="Gerar Remessa" @click="geracaoRemessa(item)" :disabled="isUpdating"
                >call_made</v-icon
              >
              <v-icon
                v-if="
                  item.status == 'CORRENTE' &&
                    item.remessa == 'SOLICITADA' &&
                    item.cedenteForma != 'CELESC' &&
                    remessa &&
                    direitoDestravar &&
                    item.dtLimite > new Date(item.dtRemessa)
                "
                color="red"
                title="Destravar Remessa"
                @click="geracaoRemessa(item)"
                :disabled="isUpdating"
                >call_made</v-icon
              >
              <v-icon
                v-if="
                  (idSitePrincipal == 41 || idSitePrincipal == 51) &&
                    item.tipo == 1 &&
                    item.status == 'CORRENTE' &&
                    (item.remessa == 'CRIADA' || item.remessa == 'ENVIADA') &&
                    remessa
                "
                color="green"
                title="Exportar"
                @click="gerarTxt(item)"
                :disabled="isUpdating"
                >call_made</v-icon
              >
              <v-icon
                v-if="
                  item.tipo != 0 &&
                    item.status == 'CORRENTE' &&
                    item.cedenteForma != 'CELESC' &&
                    (item.remessa == 'CRIADA' || item.remessa == 'ENVIADA') &&
                    remessa &&
                    direitoDestravar &&
                    item.dtLimite > new Date(item.dtRemessa)
                "
                color="orange"
                title="Regerar Remessa"
                @click="geracaoRemessa(item)"
                :disabled="isUpdating"
                >call_made</v-icon
              >
              <v-icon
                v-if="item.status == 'CORRENTE' && remessa && !item.remessa && item.tipo == 1"
                color="red lighten-3"
                title="Excluir Lote"
                @click="excluiLote(item)"
                :disabled="isUpdating"
                >cancel</v-icon
              >
              <v-icon
                v-if="item.cedenteForma == 'BOLETO' && remessa && item.status == 'CORRENTE' && item.remessa != 'ENVIADA' && item.remessa != 'PROCESSADA'"
                :color="item.emissaoBanco ? 'blue lighten-1' : 'blue-grey lighten-3'"
                :title="(item.emissaoBanco ? 'Desmarcar' : 'Marcar') + ' como Emissão pelo Banco'"
                @click="marcarDesmarcarEmissaoBanco(item)"
                :disabled="isUpdating"
                >account_balance</v-icon
              >
              <v-icon
                v-if="item.status == 'CORRENTE' && (item.remessa == 'CRIADA' || item.remessa == 'ENVIADA') && item.arquivo && remessa"
                :color="item.remessa == 'ENVIADA' ? 'red' : 'blue'"
                title="Download Arquivo Remessa"
                @click="downloadRemessa(item)"
                :disabled="isUpdating"
                >cloud_download</v-icon
              >
              <v-icon
                v-if="item.status == 'CORRENTE' && item.arquivo && !remessa"
                color="red lighten-4"
                title="Download Arquivo Retorno"
                @click="downloadRetorno(item)"
                :disabled="isUpdating"
                >cloud_download</v-icon
              >
              <v-icon
                v-if="item.status == 'CORRENTE' && item.remessa == 'ENVIADA' && item.tipo == 3 && item.cedenteForma == 'DEBITO_CONTA' && remessa && direitoDestravar"
                color="blue lighten-1"
                title="Liberar Parcelas"
                @click="liberarParcelas(item)"
                :disabled="isUpdating"
                >open_in_new</v-icon
              >
            </template>

            <template v-slot:item.cedente="{ item }">{{ item.idCedente + " - " + item.cedente }}</template>

            <template v-slot:item.tipo="{ item }">
              <span :class="item.tipo == 3 ? 'red--text' : item.tipo == 2 ? 'orange--text' : 'blue--text'">
                {{ item.tipo == 3 ? "Cancelamento" : item.tipo == 2 ? "Alteração" : item.tipo == 1 ? "Inclusão" : "Retorno" }}
              </span>
            </template>

            <template v-slot:item.status="{ item }">
              <span :class="item.status == 'EXCLUÍDO' ? 'red--text' : 'blue--text'">{{ item.status }}</span>
            </template>
            <template v-slot:item.dtCriacao="{ item }">{{ formatDateTime(item.dtCriacao) }}</template>

            <template v-slot:item.remessa="{ item }">
              <span v-if="remessa">{{ item.remessa }}</span>
            </template>

            <template v-slot:item.dtRemessa="{ item }">
              <span v-if="expandir && remessa">{{ formatDateTime(item.dtRemessa) }}</span>
            </template>

            <template v-slot:item.dtExclusao="{ item }">
              <span v-if="expandir && remessa">{{ formatDateTime(item.dtExclusao) }}</span>
            </template>

            <template v-slot:item.arquivo="{ item }">
              <span v-if="expandir || !remessa">{{ item.arquivo }}</span>
            </template>

            <template v-slot:item.inclOptantes="{ item }">
              <span v-if="remessa">{{ item.inclOptantes ? "Sim" : "" }}</span>
            </template>

            <template v-slot:item.valor="{ item }">{{ formatCurrency(item.valor) }}</template>
          </v-data-table>
          <div class="text-xs-center pt-2 marg-bt">
            <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 7" circle></v-pagination>
          </div>
        </div>
        <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
        <v-snackbar v-model="snackErro" color="error" :timeout="10000" relative bottom left>{{ mensagem }}</v-snackbar>
      </v-card>
      <v-dialog persistent v-model="dialogConfirm" max-width="400">
        <v-card>
          <v-toolbar>
            <span class="headline">Confirmação</span>
          </v-toolbar>
          <v-card-text v-html="msgConfirm"></v-card-text>
          <v-card-actions>
            <v-btn color="red darken-1" text @click="naoConfirmado">Não</v-btn>
            <v-btn color="green darken-1" text @click="confirmado">Sim</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialogGerarRemessa" max-width="400">
        <v-card>
          <v-toolbar>
            <span class="headline">Geração de Remessa</span>
          </v-toolbar>
          <v-form ref="formGerarRemessa" v-model="gerarRemessaValid" lazy-validation>
            <v-container>
              <v-layout wrap>
                <v-flex offset-xs3 xs6>
                  <v-text-field
                    v-model="numeroRemessa"
                    label="Número da Remessa"
                    v-mask="'#######'"
                    color="blue-grey lighten-2"
                    hint="Obrigatório"
                    :rules="[value => !!value || 'Obrigatório']"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-progress-linear v-model="value" :active="loading" class="ma-0" color="cyan accent-3" height="16" :indeterminate="value > 100"
                    >Gerando arquivo(s). Aguarde...</v-progress-linear
                  >
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
          <v-card-actions>
            <v-btn color="red darken-1" text @click="dialogGerarRemessa = false" :loading="isUpdating">Retornar</v-btn>
            <v-btn color="green darken-1" text @click="gerarRemessa" :loading="isUpdating">Gerar Remessa</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import LogBanco from "@/views/fin/LogBanco.vue";
import FileUpload from "@/views/components/FileUpload.vue";
import util from "../../util.js";
import { api } from "@/services/api.js";
import download from "downloadjs";
import { mapMutations } from "vuex";
export default {
  components: {
    LogBanco,
    FileUpload
  },
  data() {
    return {
      headers: [
        { text: "Ações", value: "actions", width: "160" },
        { text: "ID", value: "id" },
        { text: "Número", value: "numero" },
        { text: "Cedente", value: "cedente" },
        { text: "Forma", value: "cedenteForma" },
        { text: "Tipo", value: "tipo" },
        { text: "Situação", value: "status" },
        { text: "Emissão", value: "dtCriacao" },
        { text: "Usuário", value: "usuario" },
        { text: "Remessa", value: "remessa" },
        { text: "Optantes", value: "inclOptantes" },
        { text: "Valor", value: "valor", align: "end" },
        { text: "Itens", value: "qtde", align: "end" }
      ],
      headersPagar: [
        { text: "Ações", value: "actions", width: "130" },
        { text: "ID", value: "id" },
        { text: "Número", value: "numero" },
        { text: "Cedente", value: "cedente" },
        { text: "Forma", value: "cedenteForma" },
        { text: "Tipo", value: "tipo" },
        { text: "Situação", value: "status" },
        { text: "Emissão", value: "dtCriacao" },
        { text: "Usuário", value: "usuario" },
        { text: "Remessa", value: "remessa" },
        { text: "BRR", value: "brrOperacao" },
        { text: "Tipo Serviço", value: "brrTipoServico" },
        { text: "Forma Lançamento", value: "brrFormaLancamento" },
        { text: "Valor", value: "valor", align: "end" },
        { text: "Itens", value: "qtde", align: "end" }
      ],
      headersRetorno: [
        { text: "Ações", value: "actions", width: "110" },
        { text: "ID", value: "id" },
        { text: "Número", value: "numero" },
        { text: "Cedente", value: "cedente" },
        { text: "Forma", value: "cedenteForma" },
        { text: "Tipo", value: "tipo" },
        { text: "Emissão", value: "dtCriacao" },
        { text: "Usuário", value: "usuario" },
        { text: "Arquivo", value: "arquivo" },
        { text: "Valor", value: "valor", align: "end" },
        { text: "Itens", value: "qtde", align: "end" },
        { text: "Retorno", value: "retorno" }
      ],
      headersExpand: [
        { text: "Ações", value: "actions", width: "160" },
        { text: "ID", value: "id" },
        { text: "Número", value: "numero" },
        { text: "Cedente", value: "cedente" },
        { text: "Forma", value: "cedenteForma" },
        { text: "Tipo", value: "tipo" },
        { text: "Situação", value: "status" },
        { text: "Emissão", value: "dtCriacao" },
        { text: "Usuário", value: "usuario" },
        { text: "Status Remessa", value: "remessa" },
        { text: "Remessa", value: "dtRemessa" },
        { text: "Exclusão", value: "dtExclusao" },
        { text: "Usuário Exclusão", value: "usuarioExclusao" },
        { text: "Arquivo", value: "arquivo" },
        { text: "Optantes", value: "inclOptantes" },
        { text: "Valor", value: "valor", align: "end" },
        { text: "Itens", value: "qtde", align: "end" }
      ],
      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },
      expandir: false,

      cedentes: false,
      celesc: false,

      dialogUpload: false,

      //* Gerar Remessa
      dialogGerarRemessa: false,
      gerarRemessaValid: true,
      numeroRemessa: 0,
      isUpdating: false,

      //* Confirmação
      dialogConfirm: false,
      acaoConfirm: "",
      msgConfirm: "",
      dadoIndex: null,
      dadoItem: null,

      //* Dados para o componente DialogLogBanco
      dialogLogBanco: false,
      idParcela: "",
      idLoteFaturamento: "",

      direitoParcelas: false,
      direitoDestravar: false,

      mensagem: "",
      snackbar: false,
      snackErro: false,

      loading: false,
      interval: {},
      processando: 0,
      mensagemPDFs: "Gerando PDFs dos boletos...",

      value: 0,
      isUpdating: false,
      idSitePrincipal: null,
      mensagemPesquisa: "",
      filtroPesquisa: "",
      registros: [],
      registrosPagina: 10,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      carregando: true,
      ordenacao: "",
      ordem: "",
      options: {}
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    limpaFiltro() {
      this.paginaAtual = 1;
      this.pesquisaDados();
    },
    async pesquisaDados() {
      this.registros = [];
      if (!this.remessa) {
        this.expandir = false;
      }
      //sinaliza que está carregando
      this.carregando = true;
      this.mensagemPesquisa = "Carregando...";
      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "fin/lote-faturamento/pagamento/origem" +
            (this.receber ? "/0" : "/1") +
            (this.remessa ? "/0" : "/1") +
            "?idSite=" +
            this.idSitePrincipal +
            "&pagina=" +
            this.paginaAtual +
            "&tamanho=" +
            this.registrosPagina +
            "&filtro=" +
            this.filtroPesquisa +
            "&ordenacao=" +
            this.ordenacao +
            "&ordem=" +
            this.ordem
        );

        //seta as variaveis com o retorno
        this.registros = response.data.data;
        this.registros.forEach(element => {
          element.dtLimite = new Date();
          element.dtLimite.setMinutes(element.dtLimite.getMinutes() - 30);
        });
        this.totalRegistros = response.data.total;
        this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
        this.rodape.pageText = this.totalRegistros + " itens";
        //fecha o loading
        this.carregando = false;
        //se não achar registro seta a mensagem senão deixa em branco
        this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else {
          this.mensagemPesquisa = "Erro ao buscar dados: " + e;
        }
      }
    },

    async loteCelesc() {
      this.isUpdating = true;
      const loteCommit = {
        idSite: this.idSitePrincipal,
        tipo: 1,
        origem: 0,
        parcelas: [] //* Parcelas Celesc serão buscadas por script
      };

      try {
        await api.post(process.env.VUE_APP_URL_WEB_API + "fin/lote-faturamento?idSite=" + this.idSitePrincipal + "&forma=CELESC", loteCommit);

        this.mensagem = "Lote(s) criado(s) com sucesso";
        this.snackbar = true;
        this.pesquisaDados();
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      } finally {
        this.isUpdating = false;
      }
    },

    async loteAltCanc() {
      this.isUpdating = true;
      const loteCommit = {
        idSite: this.idSitePrincipal,
        origem: 0,
        pagamento: this.receber ? 0 : 1
      };

      try {
        await api.post(process.env.VUE_APP_URL_WEB_API + "fin/lote-faturamento/altcanc?idSite=" + this.idSitePrincipal, loteCommit);

        this.mensagem = "Lote(s) criado(s) com sucesso";
        this.snackbar = true;
        this.pesquisaDados();
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      } finally {
        this.isUpdating = false;
      }
    },

    async geracaoRemessa(item) {
      this.dadoIndex = this.registros.indexOf(item);
      this.dadoItem = Object.assign({}, item);
      if (item.cedenteForma == "CELESC") {
        this.acaoConfirm = "enviarCelesc";
        this.msgConfirm = "<u>Esta Remessa:</u></br>quantidade: " + item.qtde + "<br>valor: " + item.valor + "<br><br><strong>Confirma o envio desta remessa?</strong>";
        this.dialogConfirm = true;
      } else if (item.cedenteForma == "BOLETO" || item.cedenteForma == "DEBITO_CONTA" || item.cedenteForma == "BRR") {
        this.numeroRemessa = 0;
        if (item.numero) {
          this.numeroRemessa = item.numero;
        } else {
          const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/lote-faturamento/ultimoNumero?idCedente=" + item.idCedente + "&idLote=" + item.id);
          if (response.data) {
            this.numeroRemessa = parseInt(response.data) + 1;
          }
        }
        this.dialogGerarRemessa = true;
      } else {
        this.gerarRemessa();
      }
    },
    async gerarRemessa() {
      try {
        //* chama api para gerar o arquivo json com dados para o wsJava gerar o arquivo remessa a ser lido pela Neogrid ou ficar disponível para download
        //* se json salvo, status do lote é alterado para SOLICITADA
        //* no caso de CELESC:
        // CELESC=Adjori - o service irá realizar a remessa com este status SOLICITADA
        // CELESC_OLD=Antiga / SAMAE - o arquivo remessa é gerado diretamente
        if (this.dadoItem.cedenteForma == "BOLETO" || this.dadoItem.cedenteForma == "DEBITO_CONTA") {
          if (!this.$refs.formGerarRemessa.validate()) {
            return;
          }
        }
        this.isUpdating = true;

        this.value = 0;
        clearInterval(this.interval);
        var intervalo = parseInt((this.dadoItem.qtde * 500) / 100);
        this.interval = setInterval(() => {
          this.value += 1;
        }, intervalo);
        this.loading = true;

        if (this.dadoItem.cedenteForma == "BOLETO" || this.dadoItem.cedenteForma == "DEBITO_CONTA" || this.dadoItem.cedenteForma == "CELESC") {
          //* Já altera o status para bloquear dupla solicitação
          this.dadoItem.remessa = "CRIADA";
          this.dadoItem.dtCriacao = new Date();
          this.dadoItem.numero = this.dadoItem.inclOptantes ? parseInt(this.numeroRemessa) + 1 : this.numeroRemessa;
          Object.assign(this.registros[this.dadoIndex], this.dadoItem);
        }

        await api.put(
          process.env.VUE_APP_URL_WEB_API + "fin/lote-faturamento/remessa/" + (this.receber ? "" : "pagar/") + this.dadoItem.id + "?numeroRemessa=" + this.numeroRemessa
        );

        if (this.dadoItem.cedenteForma == "CELESC_OLD" || this.dadoItem.cedenteForma == "SAMAE" || this.dadoItem.cedenteForma == "BRR") {
          this.pesquisaDados();
        } else {
          this.mensagem = this.dadoItem.tipo == 3 || this.dadoItem.cedenteForma != "BOLETO" ? "Arquivo remessa gerado" : "PDFs dos boletos e arquivo remessa gerados";
          this.snackbar = true;
        }
      } catch (e) {
        this.mensagem = e.toString().includes("status code 400")
          ? "SESSÃO EXPIRADA"
          : e.response && e.response.data && e.response.data.length > 0
          ? e.response.data[0].mensagem
          : "Indeterminado, verifique sua conexão";
        this.snackErro = true;
      } finally {
        this.isUpdating = false;
        this.loading = false;
        this.dialogGerarRemessa = false;
      }
    },

    async gerarTxt(item) {
      this.isUpdating = true;
      try {
        //* chama api para gerar o arquivo txt com dados para a gráfica
        const caminhoRemessa = await api.put(process.env.VUE_APP_URL_WEB_API + "fin/lote-faturamento/txt/" + item.id);
        download(process.env.VUE_APP_URL_WEB_API + caminhoRemessa.data.arquivo);
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },

    excluiLote(item) {
      this.dadoIndex = this.registros.indexOf(item);
      this.dadoItem = Object.assign({}, item);
      this.msgConfirm = "<br>TODAS as parcelas serão removidas deste lote!<br /><br /><strong>Tem certeza que deseja EXCLUIR este lote?</strong>";
      this.acaoConfirm = "excluir";
      this.dialogConfirm = true;
    },

    async excluirLote() {
      if (!this.dadoItem.remessa) {
        this.isUpdating = true;
        try {
          await api.put(process.env.VUE_APP_URL_WEB_API + "fin/lote-faturamento/excluir/" + this.dadoItem.id + "?tipo=" + this.dadoItem.tipo);

          this.dadoItem.status = "EXCLUÍDO";
          this.dadoItem.remessa = "";
          this.dadoItem.dtExclusao = new Date();
          this.dadoItem.numero = "";
          Object.assign(this.registros[this.dadoIndex], this.dadoItem);

          this.mensagem = "Parcelas removidas do lote";
          this.snackbar = true;
        } catch (e) {
          if (e.toString().includes("status code 400")) {
            this.mensagem = "SESSÃO EXPIRADA";
            this.snackErro = true;
          } else if (e.response && e.response.data) {
            util.validaErros(this.validacao, e.response.data);
            this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
            this.snackErro = true;
          } else {
            this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
            this.snackErro = true;
          }
        } finally {
          this.isUpdating = false;
        }
      }
    },

    async marcarDesmarcarEmissaoBanco(item) {
      if (item.status == "CORRENTE" && (!item.remessa || item.remessa == "CRIADA")) {
        this.isUpdating = true;
        this.dadoIndex = this.registros.indexOf(item);
        this.dadoItem = Object.assign({}, item);
        this.dadoItem.emissaoBanco = this.dadoItem.emissaoBanco == 1 ? 0 : 1;
        var emissaoBanco = { emissaoBanco: this.dadoItem.emissaoBanco };
        try {
          await api.put(process.env.VUE_APP_URL_WEB_API + "fin/lote-faturamento/emissaoBanco/" + this.dadoItem.id, emissaoBanco);
          Object.assign(this.registros[this.dadoIndex], this.dadoItem);
        } catch (e) {
          this.mensagem =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
          this.snackErro = true;
        } finally {
          this.isUpdating = false;
        }
      } else {
        this.mensagem = item.remessa ? "Remessa encontra-se " + item.remessa : "Lote encontra-se " + item.status;
        this.snackErro = true;
      }
    },

    async liberarParcelas(item) {
      this.isUpdating = true;
      try {
        await api.put(process.env.VUE_APP_URL_WEB_API + "fin/lote-faturamento/liberarParcelas/" + item.id);
        this.mensagem = "Parcelas da remessa liberadas";
        this.snackbar = true;
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      } finally {
        this.isUpdating = false;
      }
    },

    confirmado() {
      this.dialogConfirm = false;
      if (this.acaoConfirm == "excluir") {
        this.excluirLote();
      } else {
        this.gerarRemessa();
      }
    },
    naoConfirmado() {
      this.dialogConfirm = false;
    },

    visualizarParcelas(item) {
      window.open("/financeiro/parcelas/" + (this.receber ? "receber" : "pagar") + "?idLoteFaturamento=" + item.id, "_blank");
      //para listar as parcelas do retorno, teria que buscar as parcelas com join em log_parcela.idParcela e onde log_parcela.idLoteFaturamento = item.id - porém pode ficar muito lento com este join a mais
    },
    visualizarLogBanco(item) {
      this.idLoteFaturamento = item.id + "";
      this.dialogLogBanco = true;
    },
    closeLogBanco() {
      this.dialogLogBanco = false;
      this.idLoteFaturamento = "";
    },

    async downloadRemessa(item) {
      this.dadoIndex = this.registros.indexOf(item);
      this.dadoItem = Object.assign({}, item);

      var caminhoRemessa = "";
      var msg = "";
      if (item.arquivo) {
        var forma =
          item.cedenteForma == "DEBITO_CONTA"
            ? "debitos-conta"
            : item.cedenteForma == "CELESC_OLD"
            ? "celescs"
            : item.cedenteForma == "SAMAE"
            ? "samaes"
            : item.cedenteForma == "BRR"
            ? "brr"
            : "boletos";
        var nomeArq = item.arquivo;
        if (item.bancoNumero == "748") {
          if (nomeArq.indexOf("_")) {
            nomeArq = nomeArq.split("_")[1];
          }
        }
        var pastaBanco = "";
        if (!item.remessaPastaUnica) {
          pastaBanco = `/${item.bancoNumero}`;
        }
        var processados = "";
        caminhoRemessa = `arquivos/${this.idSitePrincipal}/${forma}${pastaBanco}/remessas/${processados}${nomeArq}`;
      } else {
        msg = "Arquivo remessa não emitido";
      }
      if (caminhoRemessa) {
        this.isUpdating = true;
        download(process.env.VUE_APP_URL_WEB_API + caminhoRemessa);
        if (item.inclOptantes) {
          var segundo = "00";
          try {
            var partes = nomeArq.split(".");
            var parte = partes[0].substring(partes[0].length - 2);
            var seg = parseInt(parte) - 1;
            segundo = seg.toString().padStart(2, "0");
          } catch {
            segundo = "00";
          }
          const nomeArqOpt = caminhoRemessa.substring(0, caminhoRemessa.length - 6) + segundo + ".REM";
          download(process.env.VUE_APP_URL_WEB_API + nomeArqOpt);
        }
        if (item.remessa != "ENVIADA") {
          try {
            await api.put(process.env.VUE_APP_URL_WEB_API + "fin/lote-faturamento/enviada/" + item.id + "?idSite=" + this.idSitePrincipal + "&tipo=" + item.tipo);
            this.dadoItem.remessa = "ENVIADA";
            this.dadoItem.dtRemessa = new Date();
            Object.assign(this.registros[this.dadoIndex], this.dadoItem);
          } catch (e) {
            this.mensagem =
              "ERRO: " +
              (e.toString().includes("status code 400")
                ? "SESSÃO EXPIRADA"
                : e.response && e.response.data && e.response.data.length > 0
                ? e.response.data[0].mensagem
                : "Indeterminado, verifique sua conexão");
            this.snackErro = true;
          }
        }
        this.isUpdating = false;
      } else {
        this.mensagem = msg;
        this.snackErro = true;
      }
    },
    async downloadRetorno(item) {
      this.dadoIndex = this.registros.indexOf(item);
      this.dadoItem = Object.assign({}, item);

      var caminhoRetorno = "";
      var msg = "";
      if (item.arquivo) {
        var forma = item.cedenteForma == "DEBITO_CONTA" ? "debitos-conta" : item.cedenteForma == "CELESC_OLD" ? "celescs" : item.cedenteForma == "SAMAE" ? "samaes" : "boletos";
        caminhoRetorno = `arquivos/${this.idSitePrincipal}/${forma}/${item.bancoNumero}/retornos/processados/${item.arquivo}`;
      } else {
        msg = "Arquivo retorno não processado";
      }
      if (caminhoRetorno) {
        download(process.env.VUE_APP_URL_WEB_API + caminhoRetorno);
      } else {
        this.mensagem = msg;
        this.snackErro = true;
      }
    },
    async verificaCedentes() {
      const responseBoleto = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/cedente/boleto?idSite=" + this.idSitePrincipal);
      if (responseBoleto.data) {
        this.cedentes = true;
      }

      const responseDebito = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/cedente/debito?idSite=" + this.idSitePrincipal);
      if (responseDebito.data && responseDebito.data.length > 0) {
        this.cedentes = true;
      }

      const responseCelesc = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/cedente/celesc?idSite=" + this.idSitePrincipal + "&forma=CELESC");
      if (responseCelesc.data) {
        this.celesc = true;
      }
    },
    formatDate(date) {
      return util.formatDate(date);
    },
    formatDateTime(datetime) {
      return util.formatDateTime(datetime);
    },
    formatCurrency(valor) {
      return util.formatCurrency(valor);
    }
  },

  beforeMount() {
    if (this.remessa) {
      if (this.receber) {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_REMESSAS_RECEBER_ACESSO"]);
      } else {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_REMESSAS_PAGAR_ACESSO"]);
      }
    } else {
      if (this.receber) {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_RETORNOS_RECEBER_ACESSO"]);
      } else {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_RETORNOS_PAGAR_ACESSO"]);
      }
    }

    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;

      if (this.receber) {
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_RECEBER_ACESSO") {
          this.direitoParcelas = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_REMESSAS_RECEBER_DESTRAVAR") {
          this.direitoDestravar = true;
        }
      } else {
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_PAGAR_ACESSO") {
          this.direitoParcelas = true;
        }
      }
    });
    this.verificaCedentes();
  },

  computed: {
    receber() {
      return this.$route.path.indexOf("/receber") > -1;
    },
    remessa() {
      return this.$route.path.indexOf("/remessas") > -1;
    }
  },

  watch: {
    $route() {
      this.pesquisaDados();
    },
    dialogLogBanco(val) {
      val || this.closeLogBanco();
    },
    paginaAtual() {
      this.pesquisaDados();
    },
    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }

        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }

        this.pesquisaDados();
      }
    }
  }
};
</script>
