<template>
  <div id="app">
    <v-app id="inspire">
      <v-card class="hide-overflow fill-height">
        <v-toolbar height="50" color="#333333">
          <v-icon color="white" class="mr-2">web_asset</v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light">Sites</v-toolbar-title>
          <v-spacer></v-spacer>
          <router-link to="/administrativo/site" style="text-decoration: none" v-if="direitoCriar">
            <v-btn color="success" fab small>
              <v-icon color="white" title="Incluir">add</v-icon>
            </v-btn>
          </router-link>
        </v-toolbar>
        <div class="px-6 mt-4">
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="12" md="5">
              <v-text-field
                v-model="filtroPesquisa"
                background-color="white"
                class="mt-2 mb-2 elevation-0"
                append-icon="search"
                label="Digite para pesquisar..."
                :placeholder="'Pesquisa pelo nome, identificacao, sigla, email ou ' + ($store.state.usuario.site_pais == 'BR' ? 'município' : 'concelho') + ' do site'"
                hide-details
                filled
                @keyup.enter="limpaFiltro"
                @click:append="limpaFiltro"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-data-table mobile-breakpoint="0" :headers="headers" :items="registros" :options.sync="options" :no-data-text="mensagemPesquisa" :footer-props="rodape">
            <template v-slot:item.actions="{ item }">
              <router-link :to="'/administrativo/site/' + item.id" style="text-decoration: none" v-if="direitoEditar">
                <v-icon color="blue" title="Editar">edit</v-icon>
              </router-link>
              <v-icon color="blue" title="Visualizar Log" @click="visualizarLog(item)">info</v-icon>
            </template>

            <template v-slot:item.dtCriacao="{ item }">
              {{ formatDate(item.dtCriacao) }}
            </template>

            <template v-slot:item.rede="{ item }">
              <span v-html="item.rede == 1 ? 'Sim' : ''"></span>
            </template>

            <template v-slot:item.assinaturas="{ item }">
              <v-btn v-if="item.assinaturas" fab dark small color="red" class="ma-1 btn-ass" title="Módulo Assinaturas" style="height:30px;width:30px;">
                <v-badge inline color="dark" content="ASS"></v-badge>
              </v-btn>
            </template>
            <template v-slot:item.comercial="{ item }">
              <v-btn v-if="item.comercial" fab dark small color="yellow" class="ma-1 btn-com" title="Módulo Comercial" style="height:30px;width:30px;">
                <v-badge inline color="dark" content="COM"></v-badge>
              </v-btn>
            </template>
            <template v-slot:item.editorial="{ item }">
              <v-btn v-if="item.editorial" fab dark small color="blue" class="ma-1 btn-edt" title="Módulo Editorial" style="height:30px;width:30px;">
                <v-badge inline color="dark" content="EDT"></v-badge>
              </v-btn>
            </template>
            <template v-slot:item.flip="{ item }">
              <v-btn v-if="item.flip" fab dark small color="blue lighten-3" class="ma-1 btn-flip" title="Módulo Flip" style="height:30px;width:30px;">
                <v-badge inline color="dark" content="FLIP"></v-badge>
              </v-btn>
            </template>
            <template v-slot:item.paywall="{ item }">
              <v-btn
                v-if="item.paywall"
                fab
                dark
                small
                :color="'orange' + (item.assinante && item.paywall ? '' : ' lighten-4')"
                class="ma-1 btn-pay"
                :title="'Paywall' + (item.assinante ? ' + Módulo Assinante ' : '')"
                style="height:30px;width:30px;"
              >
                <v-badge inline color="dark" content="Pay"></v-badge>
              </v-btn>
              <v-btn v-if="item.assinante && !item.paywall" fab dark small color="orange" class="ma-1 btn-pay" title="Módulo Assinante" style="height:30px;width:30px;">
                <span style="font-size:7px">Assi<br />nante</span>
              </v-btn>
              <v-btn v-if="item.gateway.includes('CIELO')" fab dark small color="orange" class="ma-1 btn-pay" title="Cielo" style="height:30px;width:30px;">
                <span style="font-size:8px">Cielo</span>
              </v-btn>
              <v-btn v-if="item.gateway.includes('MUNDIPAGG')" fab dark small color="orange" class="ma-1 btn-pay" title="Mundipagg" style="height:30px;width:30px;">
                <span style="font-size:7px">Mundi<br />pagg</span>
              </v-btn>
              <v-btn v-if="item.gateway.includes('ASAAS')" fab dark small color="orange" class="ma-1 btn-pay" title="Asaas" style="height:30px;width:30px;">
                <span style="font-size:8px">Asaas</span>
              </v-btn>
            </template>

            <template v-slot:item.situacao="{ item }">
              <span v-html="item.situacao == 1 ? 'Ativo' : item.situacao == 0 ? '<strong>Inativo</strong>' : ''"></span>
            </template>
            <template v-slot:item.celesc="{ item }">
              <span v-html="item.celesc == 1 ? 'Sim' : ''"></span>
            </template>
          </v-data-table>
          <div class="text-xs-center pt-2 marg-bt">
            <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 7" circle></v-pagination>
          </div>
        </div>
      </v-card>

      <v-dialog v-model="dialogLogAlteracao" max-width="1200px">
        <v-card>
          <LogAlteracao :idSite="idSite" :dialogLogAlteracao.sync="dialogLogAlteracao" />
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import { api } from "@/services/api.js";
import util from "../../util.js";
import { mapMutations } from "vuex";
import LogAlteracao from "@/views/components/LogAlteracao.vue";
export default {
  components: {
    LogAlteracao
  },
  data() {
    return {
      headers: [
        { text: "Ações", value: "actions", width: "80" },
        { text: "ID", value: "id" },
        { text: "Sigla", value: "sigla" },
        { text: "Nome", value: "nome" },
        { text: "Identificacao", value: "identificacao" },
        { text: "E-mail", value: "email" },
        { text: "Site", value: "enderecoSite" },

        {
          text: this.$store.state.usuario.site_pais == "BR" ? "Município" : "Concelho",
          value: "municipio"
        },
        { text: "Criação", value: "dtCriacao" },
        { text: "Rede", value: "nomeRede" },
        { text: "Celesc", value: "celesc" },
        { text: "Gestor de Rede", value: "rede" },
        { text: "Situação", value: "situacao" },
        { text: "ASS", value: "assinaturas" },
        { text: "COM", value: "comercial" },
        { text: "EDT", value: "editorial" },
        { text: "FLIP", value: "flip" },
        { text: "PAY", value: "paywall", width: 110 },
        { text: "Assinante", value: "enderecoAssinante" }
      ],
      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },
      dialogLogAlteracao: false,
      idSite: "",
      direitoCriar: false,
      direitoEditar: false,
      mensagemPesquisa: "",
      filtroPesquisa: "",
      registros: [],
      registrosPagina: 10,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      carregando: true,
      ordenacao: "",
      ordem: "",
      options: {}
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    limpaFiltro() {
      this.paginaAtual = 1;
      this.pesquisaDados();
    },
    async pesquisaDados() {
      this.registros = [];
      //sinaliza que está carregando
      this.carregando = true;
      this.mensagemPesquisa = "Carregando...";

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "adm/site?pagina=" +
            this.paginaAtual +
            "&tamanho=" +
            this.registrosPagina +
            "&filtro=" +
            this.filtroPesquisa +
            "&ordenacao=" +
            this.ordenacao +
            "&ordem=" +
            this.ordem
        );

        //seta as variaveis com o retorno
        this.registros = response.data.data;
        this.registros.forEach(element => {
          element.municipio = element.municipio + "/" + element.uf;
        });
        this.totalRegistros = response.data.total;
        this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
        this.rodape.pageText = this.totalRegistros + " itens";
        //fecha o loading
        this.carregando = false;
        //se não achar registro seta a mensagem senão deixa em branco
        this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else {
          this.mensagemPesquisa = "Erro ao buscar dados: " + e;
        }
      }
    },
    visualizarLog(item) {
      this.idSite = item.id + "";
      this.dialogLogAlteracao = true;
    },
    closeLogAlteracao() {
      this.dialogLogAlteracao = false;
      this.idSite = "";
    },
    formatDate(date) {
      return util.formatDate(date);
    }
  },
  beforeMount() {
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA"]);
    //* Verifica se o usuário logado possui direito para incluir
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA") {
        this.direitoCriar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA") {
        this.direitoEditar = true;
      }
    });
  },

  watch: {
    paginaAtual() {
      this.pesquisaDados();
    },
    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
          this.pesquisaDados();
        }

        if (this.options.sortBy.length > 0) {
          if (this.options.sortBy[0] == "municipio") {
            this.ordenacao = "m.nome";
          } else if (this.options.sortBy[0] == "paywall") {
            this.ordenacao = "enderecoAssinante";
          } else {
            this.ordenacao = this.options.sortBy[0];
          }
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }

        this.pesquisaDados();
      }
    }
  }
};
</script>
