<template>
  <div id="app">
    <v-app id="inspire">
      <v-card class="hide-overflow fill-height">
        <v-toolbar height="50" color="#333333">
          <v-icon color="white" class="mr-2">library_books</v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light">Extratos</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="success" fab small @click="dialogUpload = true">
            <v-icon color="white" title="Envio de Arquivo de Extrato">cloud_upload</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="px-6 mt-4">
          <v-layout wrap>
            <v-spacer></v-spacer>
            <v-text-field
              background-color="white"
              class="mt-2 mb-2 elevation-0"
              v-model="filtroPesquisa"
              append-icon="search"
              label="Digite para pesquisar..."
              placeholder="Pesquisa pelo ID do extrato ou nome do banco"
              hide-details
              @keyup.enter="limpaFiltro"
              @click:append="limpaFiltro"
            ></v-text-field>
          </v-layout>

          <v-data-table
            mobile-breakpoint="0"
            :headers="headers"
            :items="registros"
            :options.sync="options"
            :no-data-text="mensagemPesquisa"
            :footer-props="rodape"
            :disable-sort="carregando"
            :disable-pagination="carregando"
          >
            <template v-slot:[getItemActions()]="{ item }">
              <router-link :to="'/financeiro/extrato/' + item.id" style="text-decoration: none" v-if="direitoVisualizar" target="_blank">
                <v-icon color="blue" title="Conciliar">library_books</v-icon>
              </router-link>
              <v-icon v-if="direitoExcluir" color="error" title="Excluir" @click="excluir(item)">delete</v-icon>
              <v-icon v-if="item.conciliar == 0" color="green" title="Conciliado">copyright </v-icon>
            </template>
            <template v-slot:[getItemDtCriacao()]="{ item }">
              {{ formatDateTime(item.dtCriacao) }}
            </template>
            <template v-slot:[getItemDtFinal()]="{ item }">
              {{ formatDate(item.dtFinal) }}
            </template>
          </v-data-table>
          <div class="text-xs-center pt-2 marg-bt">
            <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 7" circle></v-pagination>
          </div>
        </div>

        <v-dialog persistente v-model="dialogUpload" max-width="400px">
          <v-card>
            <v-toolbar>
              <span class="headline">Envio de Arquivo</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="dialogUpload = false">
                <v-icon color="darken-1">cancel</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <FileUpload :retorno="true" :extrato="true" />
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-card>

      <v-snackbar v-model="snackErro" color="error" :timeout="3000" relative bottom left>{{ validacao.mensagemErro }}</v-snackbar>
    </v-app>
  </div>
</template>

<script>
import FileUpload from "@/views/components/FileUpload.vue";
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  components: {
    FileUpload
  },
  data() {
    return {
      headers: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Banco", value: "nome" },
        { text: "Sequencial", value: "sequencial" },
        { text: "Data Arquivo", value: "dtCriacao" },
        { text: "Data Extrato", value: "dtFinal" },
        { text: "Arquivo", value: "arquivo" }
      ],
      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },

      validacao: { mensagemErro: false },
      snackErro: false,
      direitoVisualizar: false,
      direitoExcluir: false,
      mensagemPesquisa: "",
      filtroPesquisa: "",
      registros: [],
      contasExtrato: [],
      contaSelecionada: null,
      dialogUpload: false,
      registrosPagina: 10,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      carregando: true,
      ordenacao: "",
      ordem: "",
      options: {}
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    getItemActions() {
      return "item.actions";
    },
    getItemDtCriacao() {
      return "item.dtCriacao";
    },
    getItemDtFinal() {
      return "item.dtFinal";
    },

    limpaFiltro() {
      this.paginaAtual = 1;
      this.pesquisaDados();
    },
    async pesquisaDados() {
      this.registros = [];
      //sinaliza que está carregando
      this.carregando = true;
      this.mensagemPesquisa = "Carregando...";

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "fin/extrato?idSite=" +
            this.$store.state.usuario.idSitePrincipal +
            "&pagina=" +
            this.paginaAtual +
            "&tamanho=" +
            this.registrosPagina +
            "&filtro=" +
            this.filtroPesquisa +
            "&ordenacao=" +
            this.ordenacao +
            "&ordem=" +
            this.ordem
        );

        //seta as variaveis com o retorno
        this.registros = response.data.data;

        this.totalRegistros = response.data.total;
        this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
        this.rodape.pageText = this.totalRegistros + " itens";
        //fecha o loading
        this.carregando = false;
        //se não achar registro seta a mensagem senão deixa em branco
        this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else {
          this.mensagemPesquisa = "Erro ao buscar dados: " + e;
        }
      }
    },
    async excluir(item) {
      try {
        await api.delete(process.env.VUE_APP_URL_WEB_API + "fin/extrato/" + item.id);
        this.pesquisaDados();
      } catch (e) {
        if (e.response.data) {
          //função realiza todas as validações de erro
          await util.limparObjeto(this.validacao, false);
          util.validaErros(this.validacao, e.response.data);
          this.snackErro = true;
        }
      }
    },

    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    formatDate(date) {
      return util.formatDate(date);
    },
    formatDateTime(datetime) {
      return util.formatDateTime(datetime);
    }
  },
  beforeMount() {
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_EXTRATOS_ACESSO"]);
    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_EXTRATOS_VISUALIZAR") {
        this.direitoVisualizar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_EXTRATOS_EXCLUIR") {
        this.direitoExcluir = true;
      }
    });
  },

  watch: {
    paginaAtual() {
      this.pesquisaDados();
    },
    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }
        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }
        this.pesquisaDados();
      }
    }
  }
};
</script>
