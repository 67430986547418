<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-form ref="formElemento" lazy-validation>
      <v-container>
        <v-layout wrap>
          <v-expansion-panels v-model="painel" focusable>
            <v-expansion-panel dark class="elevation-0">
              <v-expansion-panel-header>
                <strong>Dados Básicos</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider></v-divider>
                <v-container fluid grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 md6 v-if="siteGrupo.length > 0">
                      <v-autocomplete
                        v-model="filtrosSelecionados.idSite"
                        :items="siteGrupo"
                        :filter="filtrarObjetos"
                        label="Restrito ao Site"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        clearable
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12 md4>
                      <v-text-field
                        v-model="filtrosSelecionados.identificacao"
                        :label="'Identificação (' + ($store.state.usuario.site_pais == 'BR' ? (filtrosSelecionados.identificacao.length == 14 ? 'CNPJ' : 'CPF') : 'NIF') + ')'"
                        v-mask="'##############'"
                        color="blue-grey lighten-2"
                        clearable
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md2>
                      <v-text-field
                        v-model="filtrosSelecionados.id"
                        label="ID"
                        v-mask="'######'"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        clearable
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-text-field
                        v-model="filtrosSelecionados.email"
                        label="E-mail"
                        maxlength="100"
                        color="blue-grey lighten-2"
                        @change="filtrosSelecionados.email = filtrosSelecionados.email ? filtrosSelecionados.email.toLowerCase() : ''"
                        clearable
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-autocomplete
                        v-model="filtrosSelecionados.idRamo"
                        :items="ramos"
                        :filter="filtrarObjetos"
                        label="Ramo de Atividade"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        clearable
                        multiple
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-autocomplete
                        v-model="filtrosSelecionados.statusCredito"
                        :items="statusCreditos"
                        :filter="filtrarObjetos"
                        label="Status de Crédito"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        clearable
                        multiple
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12 md12>
                      <v-text-field
                        v-model="filtrosSelecionados.nome"
                        label="Nome/Razão Social do Cliente"
                        maxlength="100"
                        color="blue-grey lighten-2"
                        @change="filtrosSelecionados.nome = pLetra(filtrosSelecionados.nome)"
                        clearable
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md12>
                      <v-text-field
                        v-model="filtrosSelecionados.nomeFantasia"
                        label="Nome Fantasia do Cliente"
                        maxlength="100"
                        color="blue-grey lighten-2"
                        @change="filtrosSelecionados.nomeFantasia = pLetra(filtrosSelecionados.nomeFantasia)"
                        clearable
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-text-field
                        v-model="filtrosSelecionados.inscricaoEstadual"
                        label="Inscrição Estadual"
                        v-mask="'##############'"
                        color="blue-grey lighten-2"
                        clearable
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-text-field
                        v-model="filtrosSelecionados.inscricaoMunicipal"
                        label="Inscrição Municipal"
                        v-mask="'####################'"
                        color="blue-grey lighten-2"
                        clearable
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md4>
                      <v-text-field
                        v-model="filtrosSelecionados.codAntigoCirculacao"
                        label="Código Circulação®"
                        v-mask="'######'"
                        color="blue-grey lighten-2"
                        clearable
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md4>
                      <v-text-field
                        v-model="filtrosSelecionados.codAntigoComercial"
                        label="Código Comercial®"
                        v-mask="'######'"
                        color="blue-grey lighten-2"
                        clearable
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md4>
                      <v-text-field v-model="filtrosSelecionados.codFinanceiro" label="Código Financeiro®" color="blue-grey lighten-2" clearable></v-text-field>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-text-field v-model="filtrosSelecionados.diaVencimento" label="Dia de Vencimento" v-mask="'##'" color="blue-grey lighten-2" clearable></v-text-field>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-menu
                        ref="mnfiltroSelDtCriacaoUS"
                        v-model="mnfiltroSelDtCriacaoUS"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="filtrosSelecionados.dtCriacao"
                            label="Data de Cadastro"
                            v-mask="'##/##/####'"
                            :rules="[value => valida_data(value) || 'Data inválida']"
                            color="blue-grey lighten-2"
                            clearable
                            prepend-icon="event"
                            v-on="on"
                            autocomplete="disabled"
                            :name="Math.random()"
                            @click:clear="filtroSelDtCriacaoUS = null"
                            @keyup="filtroSelDtCriacaoUS = valida_data(filtrosSelecionados.dtCriacao) ? formatDateUS(filtrosSelecionados.dtCriacao) : null"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="filtroSelDtCriacaoUS" locale="pt-br" no-title @input="mnfiltroSelDtCriacaoUS = false"></v-date-picker>
                      </v-menu>
                    </v-flex>

                    <v-flex xs12 md6 v-if="!filtrosSelecionados.identificacao || (filtrosSelecionados.identificacao && filtrosSelecionados.identificacao.length == 11)">
                      <v-menu
                        ref="mnfiltroSelDtNascimentoUS"
                        v-model="mnfiltroSelDtNascimentoUS"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="filtrosSelecionados.dtNascimento"
                            label="Data de Nascimento"
                            v-mask="'##/##/####'"
                            :rules="[value => valida_data(value) || 'Data inválida']"
                            color="blue-grey lighten-2"
                            clearable
                            prepend-icon="event"
                            v-on="on"
                            autocomplete="disabled"
                            :name="Math.random()"
                            @click:clear="filtroSelDtNascimentoUS = null"
                            @keyup="filtroSelDtNascimentoUS = valida_data(filtrosSelecionados.dtNascimento) ? formatDateUS(filtrosSelecionados.dtNascimento) : null"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="filtroSelDtNascimentoUS" locale="pt-br" no-title @input="mnfiltroSelDtNascimentoUS = false"></v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex xs12 md6 v-if="filtrosSelecionados.identificacao && filtrosSelecionados.identificacao.length != 11"></v-flex>

                    <v-flex xs12 md6>
                      <v-autocomplete
                        v-model="filtrosSelecionados.sexo"
                        :items="sexos"
                        :filter="filtrarObjetos"
                        label="Sexo"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        clearable
                        multiple
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-autocomplete
                        v-model="filtrosSelecionados.renovacaoAutomatica"
                        :items="itensSimNao"
                        :filter="filtrarObjetos"
                        label="Renovação Automática"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        clearable
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-autocomplete
                        v-model="filtrosSelecionados.situacao"
                        :items="itensSituacao"
                        :filter="filtrarObjetos"
                        label="Situação"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        clearable
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-autocomplete
                        v-model="filtrosSelecionados.emailCliente"
                        :items="itensEmailCliente"
                        :filter="filtrarObjetos"
                        label="E-mail"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        clearable
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-autocomplete
                        v-model="filtrosSelecionados.online"
                        :items="itensSimNao"
                        :filter="filtrarObjetos"
                        label="Online"
                        item-text="nome"
                        item-value="id"
                        autocomplete="disabled"
                        :name="Math.random()"
                        no-data-text
                        clearable
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-autocomplete
                        v-model="filtrosSelecionados.funcionario"
                        :items="itensSimNao"
                        :filter="filtrarObjetos"
                        label="Funcionário"
                        item-text="nome"
                        item-value="id"
                        autocomplete="disabled"
                        :name="Math.random()"
                        no-data-text
                        clearable
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-autocomplete
                        v-model="filtrosSelecionados.lgpd"
                        :items="itensLGPD"
                        :filter="filtrarObjetos"
                        label="LGPD"
                        item-text="nome"
                        item-value="id"
                        autocomplete="disabled"
                        :name="Math.random()"
                        no-data-text
                        clearable
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 v-if="$store.state.usuario.site_protheus">
                      <v-autocomplete
                        v-model="filtrosSelecionados.tipoProtheus"
                        :items="itensProtheus"
                        :filter="filtrarObjetos"
                        label="Protheus"
                        item-text="nome"
                        item-value="id"
                        autocomplete="disabled"
                        :name="Math.random()"
                        no-data-text
                        clearable
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 md6 v-if="$store.state.usuario.site_assinaturas && !prefixo">
                      <v-autocomplete
                        v-model="filtrosSelecionados.assinaturaCliente"
                        :items="itensAssinaturaCliente"
                        :filter="filtrarObjetos"
                        label="Assinatura"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        clearable
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 md6 v-if="$store.state.usuario.site_comercial && !prefixo">
                      <v-autocomplete
                        v-model="filtrosSelecionados.anuncioCliente"
                        :items="itensAnuncioCliente"
                        :filter="filtrarObjetos"
                        label="Anúncios"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        clearable
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-autocomplete>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Endereço</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider></v-divider>
                <v-container fluid grid-list-md>
                  <v-layout wrap>
                    <v-flex xs6>
                      <v-btn fab @click="clickEndereco('cobranca')">
                        <v-icon
                          :color="filtrosSelecionados.enderecos.cobranca ? 'blue lighten-1' : 'blue-grey lighten-3'"
                          :title="(filtrosSelecionados.enderecos.cobranca ? 'Desmarcar' : 'Marcar') + ' como Endereço de Cobrança'"
                          >monetization_on</v-icon
                        >
                      </v-btn>
                    </v-flex>
                    <v-flex xs6>
                      <v-btn fab @click="clickEndereco('entrega')">
                        <v-icon
                          :color="filtrosSelecionados.enderecos.entrega ? 'blue lighten-1' : 'blue-grey lighten-3'"
                          :title="(filtrosSelecionados.enderecos.entrega ? 'Desmarcar' : 'Marcar') + ' como Endereço de Entrega'"
                          >local_shipping</v-icon
                        >
                      </v-btn>
                    </v-flex>
                    <v-flex xs12 md12 v-if="$store.state.usuario.site_pais == 'BR'">
                      <v-subheader>Informe o CEP e clique na lupa</v-subheader>
                      <v-text-field
                        v-model="filtrosSelecionados.enderecos.cep"
                        label="CEP"
                        v-mask="'#####-###'"
                        color="blue-grey lighten-2"
                        @keyup.enter="buscarCep"
                        @click:append="buscarCep"
                        append-icon="search"
                        clearable
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md12 v-if="$store.state.usuario.site_pais == 'PT'">
                      <v-text-field v-model="filtrosSelecionados.enderecos.cep" label="CEP" v-mask="'####-###'" color="blue-grey lighten-2" clearable></v-text-field>
                    </v-flex>
                    <v-flex xs12 md12>
                      <v-autocomplete
                        v-model="filtrosSelecionados.enderecos.idUF"
                        :items="ufs"
                        :filter="filtrarUFs"
                        :label="$store.state.usuario.site_pais == 'BR' ? 'Estado' : 'Distrito'"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        @change="filtrarMunicipios"
                        clearable
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 md12>
                      <v-autocomplete
                        v-model="filtrosSelecionados.enderecos.idMunicipio"
                        :items="municipios"
                        :filter="filtrarObjetos"
                        :label="$store.state.usuario.site_pais == 'BR' ? 'Município' : 'Concelho'"
                        item-text="nome"
                        item-value="id"
                        :no-data-text="'Selecione um ' + ($store.state.usuario.site_pais == 'BR' ? 'Estado' : 'Distrito')"
                        @change="filtrarCEPs"
                        clearable
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">
                        Logradouro
                      </h4>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-container fluid grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12 md12>
                          <v-autocomplete
                            v-model="filtrosSelecionados.enderecos.idCEP"
                            :items="ceps"
                            :filter="filtrarObjetos"
                            label="Logradouro (seleção)"
                            item-text="nome"
                            item-value="id"
                            :no-data-text="'Selecione um ' + ($store.state.usuario.site_pais == 'BR' ? 'Município' : 'Concelho')"
                            clearable
                            autocomplete="disabled"
                            :name="Math.random()"
                          ></v-autocomplete>
                        </v-flex>

                        <v-flex xs12 md12>
                          <v-text-field
                            v-model="filtrosSelecionados.enderecos.logradouro"
                            label="Logradouro (texto)"
                            maxlength="100"
                            color="blue-grey lighten-2"
                            @change="filtrosSelecionados.enderecos.logradouro = pLetra(filtrosSelecionados.enderecos.logradouro)"
                            clearable
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-container>
                    <v-flex xs12 md12>
                      <v-text-field v-model="filtrosSelecionados.enderecos.numero" label="Número" v-mask="'#####'" color="blue-grey lighten-2" clearable></v-text-field>
                    </v-flex>

                    <v-flex xs12 md12>
                      <v-text-field
                        v-model="filtrosSelecionados.enderecos.complemento"
                        label="Complemento"
                        maxlength="100"
                        color="blue-grey lighten-2"
                        @change="filtrosSelecionados.enderecos.complemento = pLetra(filtrosSelecionados.enderecos.complemento)"
                        clearable
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md12>
                      <v-text-field
                        v-model="filtrosSelecionados.enderecos.complementoTipo"
                        label="Tipo de Complemento"
                        maxlength="20"
                        color="blue-grey lighten-2"
                        @change="filtrosSelecionados.enderecos.complementoTipo = pLetra(filtrosSelecionados.enderecos.complementoTipo)"
                        clearable
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md12>
                      <v-text-field
                        v-model="filtrosSelecionados.enderecos.complementoNumero"
                        label="Número Complemento"
                        maxlength="7"
                        color="blue-grey lighten-2"
                        clearable
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md12>
                      <v-text-field
                        v-model="filtrosSelecionados.enderecos.complementoBloco"
                        label="Bloco"
                        maxlength="7"
                        color="blue-grey lighten-2"
                        @change="filtrosSelecionados.enderecos.complementoBloco = pLetra(filtrosSelecionados.enderecos.complementoBloco)"
                        clearable
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md12>
                      <v-text-field
                        v-model="filtrosSelecionados.enderecos.complementoEdificio"
                        label="Edifício"
                        maxlength="50"
                        color="blue-grey lighten-2"
                        @change="filtrosSelecionados.enderecos.complementoEdificio = pLetra(filtrosSelecionados.enderecos.complementoEdificio)"
                        clearable
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md12>
                      <v-autocomplete
                        v-model="filtrosSelecionados.enderecos.idModoEntrega"
                        :items="modosEntrega"
                        :filter="filtrarObjetos"
                        label="Modo de Entrega"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        clearable
                        multiple
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-autocomplete
                        v-model="filtrosSelecionados.enderecos.situacao"
                        :items="itensSituacao"
                        :filter="filtrarObjetos"
                        label="Situação"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        clearable
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-autocomplete
                        v-model="filtrosSelecionados.enderecos.online"
                        :items="itensSimNao"
                        :filter="filtrarObjetos"
                        label="Online"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        clearable
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">
                        Registrado entre
                      </h4>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-container fluid grid-list-md>
                      <v-layout wrap>
                        <v-flex xs6 md6>
                          <v-menu
                            ref="mnfiltroSelEnderecosInicioUS"
                            v-model="mnfiltroSelEnderecosInicioUS"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="filtrosSelecionados.enderecos.dtCriacaoInicio"
                                label="Início"
                                v-mask="'##/##/####'"
                                :rules="[value => valida_data(value) || 'Data inválida']"
                                color="blue-grey lighten-2"
                                clearable
                                prepend-icon="event"
                                v-on="on"
                                autocomplete="disabled"
                                :name="Math.random()"
                                @click:clear="filtroSelEnderecosInicioUS = null"
                                @keyup="
                                  filtroSelEnderecosInicioUS = valida_data(filtrosSelecionados.enderecos.dtCriacaoInicio)
                                    ? formatDateUS(filtrosSelecionados.enderecos.dtCriacaoInicio)
                                    : null
                                "
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="filtroSelEnderecosInicioUS" locale="pt-br" no-title @input="mnfiltroSelEnderecosInicioUS = false"></v-date-picker>
                          </v-menu>
                        </v-flex>
                        <v-flex xs6 md6>
                          <v-menu
                            ref="mnfiltroSelEnderecosFimUS"
                            v-model="mnfiltroSelEnderecosFimUS"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="filtrosSelecionados.enderecos.dtCriacaoFim"
                                label="Fim"
                                v-mask="'##/##/####'"
                                :rules="[value => valida_data(value) || 'Data inválida']"
                                color="blue-grey lighten-2"
                                clearable
                                prepend-icon="event"
                                v-on="on"
                                autocomplete="disabled"
                                :name="Math.random()"
                                @click:clear="filtroSelEnderecosFimUS = null"
                                @keyup="
                                  filtroSelEnderecosFimUS = valida_data(filtrosSelecionados.enderecos.dtCriacaoFim)
                                    ? formatDateUS(filtrosSelecionados.enderecos.dtCriacaoFim)
                                    : null
                                "
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="filtroSelEnderecosFimUS" locale="pt-br" no-title @input="mnfiltroSelEnderecosFimUS = false"></v-date-picker>
                          </v-menu>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-layout>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Telefone</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider></v-divider>
                <v-flex xs12 md12>
                  <v-form ref="formTelefone" lazy-validation>
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs3>
                          <v-btn fab @click="clickTelefone('comercial')">
                            <v-icon
                              :color="filtrosSelecionados.telefones.comercial ? 'blue lighten-1' : 'blue-grey lighten-3'"
                              :title="(filtrosSelecionados.telefones.comercial ? 'Desmarcar' : 'Marcar') + ' como Telefone Comercial'"
                              >contact_phone</v-icon
                            >
                          </v-btn>
                        </v-flex>
                        <v-flex xs3>
                          <v-btn fab @click="clickTelefone('residencial')">
                            <v-icon
                              :color="filtrosSelecionados.telefones.residencial ? 'blue lighten-1' : 'blue-grey lighten-3'"
                              :title="(filtrosSelecionados.telefones.residencial ? 'Desmarcar' : 'Marcar') + ' como Telefone Residencial'"
                              >home</v-icon
                            >
                          </v-btn>
                        </v-flex>
                        <v-flex xs3>
                          <v-btn fab @click="clickTelefone('celular')">
                            <v-icon
                              :color="filtrosSelecionados.telefones.celular ? 'blue lighten-1' : 'blue-grey lighten-3'"
                              :title="(filtrosSelecionados.telefones.celular ? 'Desmarcar' : 'Marcar') + ' como Telefone Celular'"
                              >smartphone</v-icon
                            >
                          </v-btn>
                        </v-flex>
                        <v-flex xs3>
                          <v-btn fab @click="clickTelefone('whatsapp')">
                            <v-icon
                              :color="filtrosSelecionados.telefones.whatsapp ? 'blue lighten-1' : 'blue-grey lighten-3'"
                              :title="(filtrosSelecionados.telefones.whatsapp ? 'Desmarcar' : 'Marcar') + ' como Whatsapp'"
                              >mdi-whatsapp</v-icon
                            >
                          </v-btn>
                        </v-flex>
                        <v-flex xs12 md12 v-if="$store.state.usuario.site_pais == 'BR'">
                          <v-text-field v-model="filtrosSelecionados.telefones.ddd" label="DDD" v-mask="'##'" color="blue-grey lighten-2" clearable></v-text-field>
                        </v-flex>
                        <v-flex xs12 md12>
                          <v-text-field v-model="filtrosSelecionados.telefones.numero" label="Número" v-mask="'###########'" color="blue-grey lighten-2" clearable></v-text-field>
                        </v-flex>
                        <v-flex xs12 md12>
                          <v-autocomplete
                            v-model="filtrosSelecionados.telefones.naoIncomodar"
                            :items="itensNaoIncomodar"
                            :filter="filtrarObjetos"
                            label="Ligação"
                            item-text="nome"
                            item-value="id"
                            no-data-text
                            clearable
                            autocomplete="disabled"
                            :name="Math.random()"
                          ></v-autocomplete>
                        </v-flex>
                        <v-flex xs12 md6>
                          <v-autocomplete
                            v-model="filtrosSelecionados.telefones.situacao"
                            :items="itensSituacao"
                            :filter="filtrarObjetos"
                            label="Situação"
                            item-text="nome"
                            item-value="id"
                            no-data-text
                            clearable
                            autocomplete="disabled"
                            :name="Math.random()"
                          ></v-autocomplete>
                        </v-flex>

                        <v-flex xs12>
                          <h4 class="overline font-weight-black font-italic">
                            Registrado entre
                          </h4>
                          <v-divider></v-divider>
                        </v-flex>
                        <v-container fluid grid-list-md>
                          <v-layout wrap>
                            <v-flex xs6 md6>
                              <v-menu
                                ref="mnfiltroSelTelefonesInicioUS"
                                v-model="mnfiltroSelTelefonesInicioUS"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                    v-model="filtrosSelecionados.telefones.dtCriacaoInicio"
                                    label="Início"
                                    v-mask="'##/##/####'"
                                    :rules="[value => valida_data(value) || 'Data inválida']"
                                    color="blue-grey lighten-2"
                                    clearable
                                    prepend-icon="event"
                                    v-on="on"
                                    autocomplete="disabled"
                                    :name="Math.random()"
                                    @click:clear="filtroSelTelefonesInicioUS = null"
                                    @keyup="
                                      filtroSelTelefonesInicioUS = valida_data(filtrosSelecionados.telefones.dtCriacaoInicio)
                                        ? formatDateUS(filtrosSelecionados.telefones.dtCriacaoInicio)
                                        : null
                                    "
                                  ></v-text-field>
                                </template>
                                <v-date-picker v-model="filtroSelTelefonesInicioUS" locale="pt-br" no-title @input="mnfiltroSelTelefonesInicioUS = false"></v-date-picker>
                              </v-menu>
                            </v-flex>
                            <v-flex xs6 md6>
                              <v-menu
                                ref="mnfiltroSelTelefonesFimUS"
                                v-model="mnfiltroSelTelefonesFimUS"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                    v-model="filtrosSelecionados.telefones.dtCriacaoFim"
                                    label="Fim"
                                    v-mask="'##/##/####'"
                                    :rules="[value => valida_data(value) || 'Data inválida']"
                                    color="blue-grey lighten-2"
                                    clearable
                                    prepend-icon="event"
                                    v-on="on"
                                    autocomplete="disabled"
                                    :name="Math.random()"
                                    @click:clear="filtroSelTelefonesFimUS = null"
                                    @keyup="
                                      filtroSelTelefonesFimUS = valida_data(filtrosSelecionados.telefones.dtCriacaoFim)
                                        ? formatDateUS(filtrosSelecionados.telefones.dtCriacaoFim)
                                        : null
                                    "
                                  ></v-text-field>
                                </template>
                                <v-date-picker v-model="filtroSelTelefonesFimUS" locale="pt-br" no-title @input="mnfiltroSelTelefonesFimUS = false"></v-date-picker>
                              </v-menu>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-layout>
                    </v-container>
                  </v-form>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>E-mail</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider></v-divider>
                <v-flex xs12 md12>
                  <v-form ref="formEmail" lazy-validation>
                    <v-card-text>
                      <v-container grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12 md12>
                            <v-text-field
                              v-model="filtrosSelecionados.emails.email"
                              label="E-mail"
                              maxlength="100"
                              @change="filtrosSelecionados.emails.email = filtrosSelecionados.emails.email ? filtrosSelecionados.emails.email.toLowerCase() : ''"
                              color="blue-grey lighten-2"
                              clearable
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 md12>
                            <v-autocomplete
                              v-model="filtrosSelecionados.emails.fatura"
                              :items="itensEmailFatura"
                              :filter="filtrarObjetos"
                              label="Tipo E-mail"
                              item-text="nome"
                              item-value="id"
                              no-data-text
                              clearable
                              autocomplete="disabled"
                              :name="Math.random()"
                            ></v-autocomplete>
                          </v-flex>
                          <v-flex xs12 md6>
                            <v-autocomplete
                              v-model="filtrosSelecionados.emails.situacao"
                              :items="itensSituacao"
                              :filter="filtrarObjetos"
                              label="Situação"
                              item-text="nome"
                              item-value="id"
                              no-data-text
                              clearable
                              autocomplete="disabled"
                              :name="Math.random()"
                            ></v-autocomplete>
                          </v-flex>

                          <v-flex xs12>
                            <h4 class="overline font-weight-black font-italic">
                              Registrado entre
                            </h4>
                            <v-divider></v-divider>
                          </v-flex>
                          <v-container fluid grid-list-md>
                            <v-layout wrap>
                              <v-flex xs6 md6>
                                <v-menu
                                  ref="mnfiltroSelEmailsInicioUS"
                                  v-model="mnfiltroSelEmailsInicioUS"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="filtrosSelecionados.emails.dtCriacaoInicio"
                                      label="Início"
                                      v-mask="'##/##/####'"
                                      :rules="[value => valida_data(value) || 'Data inválida']"
                                      color="blue-grey lighten-2"
                                      clearable
                                      prepend-icon="event"
                                      v-on="on"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      @click:clear="filtroSelEmailsInicioUS = null"
                                      @keyup="
                                        filtroSelEmailsInicioUS = valida_data(filtrosSelecionados.emails.dtCriacaoInicio)
                                          ? formatDateUS(filtrosSelecionados.emails.dtCriacaoInicio)
                                          : null
                                      "
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker v-model="filtroSelEmailsInicioUS" locale="pt-br" no-title @input="mnfiltroSelEmailsInicioUS = false"></v-date-picker>
                                </v-menu>
                              </v-flex>
                              <v-flex xs6 md6>
                                <v-menu
                                  ref="mnfiltroSelEmailsFimUS"
                                  v-model="mnfiltroSelEmailsFimUS"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="filtrosSelecionados.emails.dtCriacaoFim"
                                      label="Fim"
                                      v-mask="'##/##/####'"
                                      :rules="[value => valida_data(value) || 'Data inválida']"
                                      color="blue-grey lighten-2"
                                      clearable
                                      prepend-icon="event"
                                      v-on="on"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      @click:clear="filtroSelEmailsFimUS = null"
                                      @keyup="
                                        filtroSelEmailsFimUS = valida_data(filtrosSelecionados.emails.dtCriacaoFim) ? formatDateUS(filtrosSelecionados.emails.dtCriacaoFim) : null
                                      "
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker v-model="filtroSelEmailsFimUS" locale="pt-br" no-title @input="mnfiltroSelEmailsFimUS = false"></v-date-picker>
                                </v-menu>
                              </v-flex>
                            </v-layout>
                          </v-container>
                        </v-layout>
                      </v-container>
                    </v-card-text>
                  </v-form>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Cartão de Crédito</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider></v-divider>
                <v-flex xs12 md12>
                  <v-spacer></v-spacer>
                  <v-form ref="formCartao" lazy-validation>
                    <v-card-text>
                      <v-container grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12 md12>
                            <v-text-field v-model="filtrosSelecionados.cartoes.cvv" label="CVV" v-mask="'###'" color="blue-grey lighten-2" clearable></v-text-field>
                          </v-flex>
                          <v-flex xs12 md12>
                            <v-text-field
                              v-model="filtrosSelecionados.cartoes.identificacao"
                              :label="
                                'Identificação (' +
                                  ($store.state.usuario.site_pais == 'BR' ? (filtrosSelecionados.cartoes.identificacao.length == 14 ? 'CNPJ' : 'CPF') : 'NIF') +
                                  ')'
                              "
                              v-mask="'##############'"
                              color="blue-grey lighten-2"
                              clearable
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 md12>
                            <v-text-field
                              v-model="filtrosSelecionados.cartoes.nome"
                              label="Nome no Cartão"
                              maxlength="100"
                              color="blue-grey lighten-2"
                              @change="filtrosSelecionados.cartoes.nome = filtrosSelecionados.cartoes.nome ? filtrosSelecionados.cartoes.nome.toUpperCase() : ''"
                              clearable
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 md12>
                            <v-text-field
                              v-model="filtrosSelecionados.cartoes.email"
                              label="E-mail"
                              maxlength="100"
                              color="blue-grey lighten-2"
                              @change="filtrosSelecionados.cartoes.email = filtrosSelecionados.cartoes.email ? filtrosSelecionados.cartoes.email.toLowerCase() : ''"
                              clearable
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 md12>
                            <v-text-field
                              v-model="filtrosSelecionados.cartoes.numero"
                              label="Número"
                              v-mask="'################'"
                              color="blue-grey lighten-2"
                              clearable
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 md12>
                            <v-text-field v-model="filtrosSelecionados.cartoes.validade" label="Validade" v-mask="'##/####'" color="blue-grey lighten-2" clearable></v-text-field>
                          </v-flex>
                          <v-flex xs12 md6>
                            <v-autocomplete
                              v-model="filtrosSelecionados.cartoes.situacao"
                              :items="itensSituacao"
                              :filter="filtrarObjetos"
                              label="Situação"
                              item-text="nome"
                              item-value="id"
                              no-data-text
                              clearable
                              autocomplete="disabled"
                              :name="Math.random()"
                            ></v-autocomplete>
                          </v-flex>

                          <v-flex xs12>
                            <h4 class="overline font-weight-black font-italic">
                              Registrado entre
                            </h4>
                            <v-divider></v-divider>
                          </v-flex>
                          <v-container fluid grid-list-md>
                            <v-layout wrap>
                              <v-flex xs6 md6>
                                <v-menu
                                  ref="mnfiltroSelCartoesInicioUS"
                                  v-model="mnfiltroSelCartoesInicioUS"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="filtrosSelecionados.cartoes.dtCriacaoInicio"
                                      label="Início"
                                      v-mask="'##/##/####'"
                                      :rules="[value => valida_data(value) || 'Data inválida']"
                                      color="blue-grey lighten-2"
                                      clearable
                                      prepend-icon="event"
                                      v-on="on"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      @click:clear="filtroSelCartoesInicioUS = null"
                                      @keyup="
                                        filtroSelCartoesInicioUS = valida_data(filtrosSelecionados.cartoes.dtCriacaoInicio)
                                          ? formatDateUS(filtrosSelecionados.cartoes.dtCriacaoInicio)
                                          : null
                                      "
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker v-model="filtroSelCartoesInicioUS" locale="pt-br" no-title @input="mnfiltroSelCartoesInicioUS = false"></v-date-picker>
                                </v-menu>
                              </v-flex>
                              <v-flex xs6 md6>
                                <v-menu
                                  ref="mnfiltroSelCartoesFimUS"
                                  v-model="mnfiltroSelCartoesFimUS"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="filtrosSelecionados.cartoes.dtCriacaoFim"
                                      label="Fim"
                                      v-mask="'##/##/####'"
                                      :rules="[value => valida_data(value) || 'Data inválida']"
                                      color="blue-grey lighten-2"
                                      clearable
                                      prepend-icon="event"
                                      v-on="on"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      @click:clear="filtroSelCartoesFimUS = null"
                                      @keyup="
                                        filtroSelCartoesFimUS = valida_data(filtrosSelecionados.cartoes.dtCriacaoFim)
                                          ? formatDateUS(filtrosSelecionados.cartoes.dtCriacaoFim)
                                          : null
                                      "
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker v-model="filtroSelCartoesFimUS" locale="pt-br" no-title @input="mnfiltroSelCartoesFimUS = false"></v-date-picker>
                                </v-menu>
                              </v-flex>
                            </v-layout>
                          </v-container>
                        </v-layout>
                      </v-container>
                    </v-card-text>
                  </v-form>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Conta Corrente</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider></v-divider>
                <v-flex xs12 md12>
                  <v-spacer></v-spacer>
                  <v-form ref="formConta" lazy-validation>
                    <v-card-text>
                      <v-container grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12 md12>
                            <v-autocomplete
                              v-model="filtrosSelecionados.contas.idBanco"
                              :items="bancosDebitoConta"
                              :filter="filtrarObjetos"
                              label="Banco"
                              item-text="nome"
                              item-value="id"
                              no-data-text
                              clearable
                              multiple
                              autocomplete="disabled"
                              :name="Math.random()"
                            ></v-autocomplete>
                          </v-flex>
                          <v-flex xs12 md12>
                            <v-text-field
                              v-model="filtrosSelecionados.contas.identificacao"
                              :label="
                                'Identificação (' +
                                  ($store.state.usuario.site_pais == 'BR' ? (filtrosSelecionados.contas.identificacao.length == 14 ? 'CNPJ' : 'CPF') : 'NIF') +
                                  ')'
                              "
                              v-mask="'##############'"
                              color="blue-grey lighten-2"
                              clearable
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 md12>
                            <v-text-field v-model="filtrosSelecionados.contas.agencia" label="Agência" v-mask="'####'" color="blue-grey lighten-2" clearable></v-text-field>
                          </v-flex>
                          <v-flex xs12 md12>
                            <v-text-field
                              v-model="filtrosSelecionados.contas.numero"
                              label="Número"
                              v-mask="'################'"
                              color="blue-grey lighten-2"
                              clearable
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 md6>
                            <v-text-field v-model="filtrosSelecionados.contas.codFinanceiro" label="Código Financeiro®" color="blue-grey lighten-2" clearable></v-text-field>
                          </v-flex>
                          <v-flex xs12 md6>
                            <v-autocomplete
                              v-model="filtrosSelecionados.contas.situacao"
                              :items="itensSituacao"
                              :filter="filtrarObjetos"
                              label="Situação"
                              item-text="nome"
                              item-value="id"
                              no-data-text
                              clearable
                              autocomplete="disabled"
                              :name="Math.random()"
                            ></v-autocomplete>
                          </v-flex>

                          <v-flex xs12>
                            <h4 class="overline font-weight-black font-italic">
                              Registrada entre
                            </h4>
                            <v-divider></v-divider>
                          </v-flex>
                          <v-container fluid grid-list-md>
                            <v-layout wrap>
                              <v-flex xs6 md6>
                                <v-menu
                                  ref="mnfiltroSelContasInicioUS"
                                  v-model="mnfiltroSelContasInicioUS"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="filtrosSelecionados.contas.dtCriacaoInicio"
                                      label="Início"
                                      v-mask="'##/##/####'"
                                      :rules="[value => valida_data(value) || 'Data inválida']"
                                      color="blue-grey lighten-2"
                                      clearable
                                      prepend-icon="event"
                                      v-on="on"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      @click:clear="filtroSelContasInicioUS = null"
                                      @keyup="
                                        filtroSelContasInicioUS = valida_data(filtrosSelecionados.contas.dtCriacaoInicio)
                                          ? formatDateUS(filtrosSelecionados.contas.dtCriacaoInicio)
                                          : null
                                      "
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker v-model="filtroSelContasInicioUS" locale="pt-br" no-title @input="mnfiltroSelContasInicioUS = false"></v-date-picker>
                                </v-menu>
                              </v-flex>
                              <v-flex xs6 md6>
                                <v-menu
                                  ref="mnfiltroSelContasFimUS"
                                  v-model="mnfiltroSelContasFimUS"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="filtrosSelecionados.contas.dtCriacaoFim"
                                      label="Fim"
                                      v-mask="'##/##/####'"
                                      :rules="[value => valida_data(value) || 'Data inválida']"
                                      color="blue-grey lighten-2"
                                      clearable
                                      prepend-icon="event"
                                      v-on="on"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      @click:clear="filtroSelContasFimUS = null"
                                      @keyup="
                                        filtroSelContasFimUS = valida_data(filtrosSelecionados.contas.dtCriacaoFim) ? formatDateUS(filtrosSelecionados.contas.dtCriacaoFim) : null
                                      "
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker v-model="filtroSelContasFimUS" locale="pt-br" no-title @input="mnfiltroSelContasFimUS = false"></v-date-picker>
                                </v-menu>
                              </v-flex>
                            </v-layout>
                          </v-container>
                        </v-layout>
                      </v-container>
                    </v-card-text>
                  </v-form>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Unidade Consumidora</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider></v-divider>
                <v-flex xs12 md12>
                  <v-spacer></v-spacer>
                  <v-form ref="formCelesc" lazy-validation>
                    <v-card-text>
                      <v-container grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12 md12>
                            <v-text-field
                              v-model="filtrosSelecionados.celescs.identificacao"
                              :label="
                                'Identificação (' +
                                  ($store.state.usuario.site_pais == 'BR' ? (filtrosSelecionados.celescs.identificacao.length == 14 ? 'CNPJ' : 'CPF') : 'NIF') +
                                  ')'
                              "
                              v-mask="'##############'"
                              color="blue-grey lighten-2"
                              clearable
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 md12>
                            <v-text-field
                              v-model="filtrosSelecionados.celescs.unidadeConsumidora"
                              label="Unidade Consumidora"
                              v-mask="'#############'"
                              color="blue-grey lighten-2"
                              clearable
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 md6>
                            <v-autocomplete
                              v-model="filtrosSelecionados.celescs.situacao"
                              :items="itensSituacao"
                              :filter="filtrarObjetos"
                              label="Situação"
                              item-text="nome"
                              item-value="id"
                              no-data-text
                              clearable
                              autocomplete="disabled"
                              :name="Math.random()"
                            ></v-autocomplete>
                          </v-flex>

                          <v-flex xs12>
                            <h4 class="overline font-weight-black font-italic">
                              Registrada entre
                            </h4>
                            <v-divider></v-divider>
                          </v-flex>
                          <v-container fluid grid-list-md>
                            <v-layout wrap>
                              <v-flex xs6 md6>
                                <v-menu
                                  ref="mnfiltroSelCelescsInicioUS"
                                  v-model="mnfiltroSelCelescsInicioUS"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="filtrosSelecionados.celescs.dtCriacaoInicio"
                                      label="Início"
                                      v-mask="'##/##/####'"
                                      :rules="[value => valida_data(value) || 'Data inválida']"
                                      color="blue-grey lighten-2"
                                      clearable
                                      prepend-icon="event"
                                      v-on="on"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      @click:clear="filtroSelCelescsInicioUS = null"
                                      @keyup="
                                        filtroSelCelescsInicioUS = valida_data(filtrosSelecionados.celescs.dtCriacaoInicio)
                                          ? formatDateUS(filtrosSelecionados.celescs.dtCriacaoInicio)
                                          : null
                                      "
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker v-model="filtroSelCelescsInicioUS" locale="pt-br" no-title @input="mnfiltroSelCelescsInicioUS = false"></v-date-picker>
                                </v-menu>
                              </v-flex>
                              <v-flex xs6 md6>
                                <v-menu
                                  ref="mnfiltroSelCelescsFimUS"
                                  v-model="mnfiltroSelCelescsFimUS"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="filtrosSelecionados.celescs.dtCriacaoFim"
                                      label="Fim"
                                      v-mask="'##/##/####'"
                                      :rules="[value => valida_data(value) || 'Data inválida']"
                                      color="blue-grey lighten-2"
                                      clearable
                                      prepend-icon="event"
                                      v-on="on"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      @click:clear="filtroSelCelescsFimUS = null"
                                      @keyup="
                                        filtroSelCelescsFimUS = valida_data(filtrosSelecionados.celescs.dtCriacaoFim)
                                          ? formatDateUS(filtrosSelecionados.celescs.dtCriacaoFim)
                                          : null
                                      "
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker v-model="filtroSelCelescsFimUS" locale="pt-br" no-title @input="mnfiltroSelCelescsFimUS = false"></v-date-picker>
                                </v-menu>
                              </v-flex>
                            </v-layout>
                          </v-container>
                        </v-layout>
                      </v-container>
                    </v-card-text>
                  </v-form>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Dependente/Integrante</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-spacer></v-spacer>

                  <v-form ref="formDependente" lazy-validation>
                    <v-card-text>
                      <v-container grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12 md12>
                            <v-text-field
                              v-model="filtrosSelecionados.dependentes.identificacao"
                              :label="
                                'Identificação (' + ($store.state.usuario.site_pais == 'BR' ? (filtrosSelecionados.identificacao.length == 14 ? 'CNPJ' : 'CPF') : 'NIF') + ')'
                              "
                              v-mask="'##############'"
                              color="blue-grey lighten-2"
                              clearable
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 md12>
                            <v-text-field
                              v-model="filtrosSelecionados.dependentes.nome"
                              label="Nome"
                              maxlength="100"
                              color="blue-grey lighten-2"
                              @change="filtrosSelecionados.dependentes.nome = pLetra(filtrosSelecionados.dependentes.nome)"
                              clearable
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 md12>
                            <v-text-field
                              v-model="filtrosSelecionados.dependentes.associacao"
                              label="Associação"
                              maxlength="30"
                              color="blue-grey lighten-2"
                              @change="filtrosSelecionados.dependentes.associacao = pLetra(filtrosSelecionados.dependentes.associacao)"
                              clearable
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 md12>
                            <v-text-field
                              v-model="filtrosSelecionados.dependentes.email"
                              label="E-mail"
                              maxlength="100"
                              color="blue-grey lighten-2"
                              @change="filtrosSelecionados.dependentes.email = filtrosSelecionados.dependentes.email ? filtrosSelecionados.dependentes.email.toLowerCase() : ''"
                              clearable
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 md2 v-if="$store.state.usuario.site_pais == 'BR'">
                            <v-text-field v-model="filtrosSelecionados.dependentes.foneDDD" label="DDD" v-mask="'##'" color="blue-grey lighten-2" clearable></v-text-field>
                          </v-flex>
                          <v-flex xs12 md10>
                            <v-text-field
                              v-model="filtrosSelecionados.dependentes.foneNumero"
                              label="Telefone"
                              v-mask="'###########'"
                              color="blue-grey lighten-2"
                              clearable
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 md6>
                            <v-menu
                              ref="mnfiltroSelDtNascDependenteUS"
                              v-model="mnfiltroSelDtNascDependenteUS"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="filtrosSelecionados.dependentes.dtNascimento"
                                  label="Data de Nascimento"
                                  v-mask="'##/##/####'"
                                  :rules="[value => valida_data(value) || 'Data inválida']"
                                  color="blue-grey lighten-2"
                                  clearable
                                  prepend-icon="event"
                                  v-on="on"
                                  autocomplete="disabled"
                                  :name="Math.random()"
                                  @click:clear="filtroSelDtNascDependenteUS = null"
                                  @keyup="
                                    filtroSelDtNascDependenteUS = valida_data(filtrosSelecionados.dependentes.dtNascimento)
                                      ? formatDateUS(filtrosSelecionados.dependentes.dtNascimento)
                                      : null
                                  "
                                ></v-text-field>
                              </template>
                              <v-date-picker v-model="filtroSelDtNascDependenteUS" locale="pt-br" no-title @input="mnfiltroSelDtNascDependenteUS = false"></v-date-picker>
                            </v-menu>
                          </v-flex>

                          <v-flex xs12 md6>
                            <v-autocomplete
                              v-model="filtrosSelecionados.dependentes.clubeCortesia"
                              :items="itensClubeForma"
                              :filter="filtrarObjetos"
                              label="Forma do Clube"
                              item-text="nome"
                              item-value="id"
                              no-data-text
                              clearable
                              autocomplete="disabled"
                              :name="Math.random()"
                            ></v-autocomplete>
                          </v-flex>
                          <v-flex xs12 md6>
                            <v-autocomplete
                              v-model="filtrosSelecionados.dependentes.situacao"
                              :items="itensSituacao"
                              :filter="filtrarObjetos"
                              label="Situação"
                              item-text="nome"
                              item-value="id"
                              no-data-text
                              clearable
                              autocomplete="disabled"
                              :name="Math.random()"
                            ></v-autocomplete>
                          </v-flex>
                          <v-flex xs12 md6>
                            <v-text-field
                              v-model="filtrosSelecionados.dependentes.codAntigoCirculacao"
                              label="Código Circulação®"
                              v-mask="'######'"
                              color="blue-grey lighten-2"
                              clearable
                            ></v-text-field>
                          </v-flex>

                          <v-flex xs12>
                            <h4 class="overline font-weight-black font-italic">
                              Registrado entre
                            </h4>
                            <v-divider></v-divider>
                          </v-flex>
                          <v-container fluid grid-list-md>
                            <v-layout wrap>
                              <v-flex xs6 md6>
                                <v-menu
                                  ref="mnfiltroSelDependentesInicioUS"
                                  v-model="mnfiltroSelDependentesInicioUS"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="filtrosSelecionados.dependentes.dtCriacaoInicio"
                                      label="Início"
                                      v-mask="'##/##/####'"
                                      :rules="[value => valida_data(value) || 'Data inválida']"
                                      color="blue-grey lighten-2"
                                      clearable
                                      prepend-icon="event"
                                      v-on="on"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      @click:clear="filtroSelDependentesInicioUS = null"
                                      @keyup="
                                        filtroSelDependentesInicioUS = valida_data(filtrosSelecionados.dependentes.dtCriacaoInicio)
                                          ? formatDateUS(filtrosSelecionados.dependentes.dtCriacaoInicio)
                                          : null
                                      "
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker v-model="filtroSelDependentesInicioUS" locale="pt-br" no-title @input="mnfiltroSelDependentesInicioUS = false"></v-date-picker>
                                </v-menu>
                              </v-flex>
                              <v-flex xs6 md6>
                                <v-menu
                                  ref="mnfiltroSelDependentesFimUS"
                                  v-model="mnfiltroSelDependentesFimUS"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="filtrosSelecionados.dependentes.dtCriacaoFim"
                                      label="Fim"
                                      v-mask="'##/##/####'"
                                      :rules="[value => valida_data(value) || 'Data inválida']"
                                      color="blue-grey lighten-2"
                                      clearable
                                      prepend-icon="event"
                                      v-on="on"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      @click:clear="filtroSelDependentesFimUS = null"
                                      @keyup="
                                        filtroSelDependentesFimUS = valida_data(filtrosSelecionados.dependentes.dtCriacaoFim)
                                          ? formatDateUS(filtrosSelecionados.dependentes.dtCriacaoFim)
                                          : null
                                      "
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker v-model="filtroSelDependentesFimUS" locale="pt-br" no-title @input="mnfiltroSelDependentesFimUS = false"></v-date-picker>
                                </v-menu>
                              </v-flex>
                            </v-layout>
                          </v-container>
                        </v-layout>
                      </v-container>
                    </v-card-text>
                  </v-form>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Departamento</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-spacer></v-spacer>
                  <v-form ref="formDepartamento" lazy-validation>
                    <v-card-text>
                      <v-container grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12 md12>
                            <v-text-field
                              v-model="filtrosSelecionados.departamentos.departamento"
                              label="Nome do Departamento"
                              maxlength="30"
                              color="blue-grey lighten-2"
                              @change="filtrosSelecionados.departamentos.departamento = pLetra(filtrosSelecionados.departamentos.departamento)"
                              clearable
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 md6>
                            <v-autocomplete
                              v-model="filtrosSelecionados.departamentos.situacao"
                              :items="itensSituacao"
                              :filter="filtrarObjetos"
                              label="Situação"
                              item-text="nome"
                              item-value="id"
                              no-data-text
                              clearable
                              autocomplete="disabled"
                              :name="Math.random()"
                            ></v-autocomplete>
                          </v-flex>

                          <v-flex xs12>
                            <h4 class="overline font-weight-black font-italic">
                              Registrado entre
                            </h4>
                            <v-divider></v-divider>
                          </v-flex>
                          <v-container fluid grid-list-md>
                            <v-layout wrap>
                              <v-flex xs6 md6>
                                <v-menu
                                  ref="mnfiltroSelDepartamentosInicioUS"
                                  v-model="mnfiltroSelDepartamentosInicioUS"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="filtrosSelecionados.departamentos.dtCriacaoInicio"
                                      label="Início"
                                      v-mask="'##/##/####'"
                                      :rules="[value => valida_data(value) || 'Data inválida']"
                                      color="blue-grey lighten-2"
                                      clearable
                                      prepend-icon="event"
                                      v-on="on"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      @click:clear="filtroSelDepartamentosInicioUS = null"
                                      @keyup="
                                        filtroSelDepartamentosInicioUS = valida_data(filtrosSelecionados.departamentos.dtCriacaoInicio)
                                          ? formatDateUS(filtrosSelecionados.departamentos.dtCriacaoInicio)
                                          : null
                                      "
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker v-model="filtroSelDepartamentosInicioUS" locale="pt-br" no-title @input="mnfiltroSelDepartamentosInicioUS = false"></v-date-picker>
                                </v-menu>
                              </v-flex>
                              <v-flex xs6 md6>
                                <v-menu
                                  ref="mnfiltroSelDepartamentosFimUS"
                                  v-model="mnfiltroSelDepartamentosFimUS"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="filtrosSelecionados.departamentos.dtCriacaoFim"
                                      label="Fim"
                                      v-mask="'##/##/####'"
                                      :rules="[value => valida_data(value) || 'Data inválida']"
                                      color="blue-grey lighten-2"
                                      clearable
                                      prepend-icon="event"
                                      v-on="on"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      @click:clear="filtroSelDepartamentosFimUS = null"
                                      @keyup="
                                        filtroSelDepartamentosFimUS = valida_data(filtrosSelecionados.departamentos.dtCriacaoFim)
                                          ? formatDateUS(filtrosSelecionados.departamentos.dtCriacaoFim)
                                          : null
                                      "
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker v-model="filtroSelDepartamentosFimUS" locale="pt-br" no-title @input="mnfiltroSelDepartamentosFimUS = false"></v-date-picker>
                                </v-menu>
                              </v-flex>
                            </v-layout>
                          </v-container>
                        </v-layout>
                      </v-container>
                    </v-card-text>
                  </v-form>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Observação</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-form ref="formObservacao" lazy-validation>
                    <v-card-text>
                      <v-container grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12 md12>
                            <v-text-field
                              v-model="filtrosSelecionados.observacoes.observacao"
                              label="Texto da Observação"
                              maxlength="100"
                              color="blue-grey lighten-2"
                              clearable
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 md6>
                            <v-autocomplete
                              v-model="filtrosSelecionados.observacoes.situacao"
                              :items="itensSituacao"
                              :filter="filtrarObjetos"
                              label="Situação"
                              item-text="nome"
                              item-value="id"
                              no-data-text
                              clearable
                              autocomplete="disabled"
                              :name="Math.random()"
                            ></v-autocomplete>
                          </v-flex>

                          <v-flex xs12>
                            <h4 class="overline font-weight-black font-italic">
                              Registrada entre
                            </h4>
                            <v-divider></v-divider>
                          </v-flex>
                          <v-container fluid grid-list-md>
                            <v-layout wrap>
                              <v-flex xs6 md6>
                                <v-menu
                                  ref="mnfiltroSelObservacoesInicioUS"
                                  v-model="mnfiltroSelObservacoesInicioUS"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="filtrosSelecionados.observacoes.dtCriacaoInicio"
                                      label="Início"
                                      v-mask="'##/##/####'"
                                      :rules="[value => valida_data(value) || 'Data inválida']"
                                      color="blue-grey lighten-2"
                                      clearable
                                      prepend-icon="event"
                                      v-on="on"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      @click:clear="filtroSelObservacoesInicioUS = null"
                                      @keyup="
                                        filtroSelObservacoesInicioUS = valida_data(filtrosSelecionados.observacoes.dtCriacaoInicio)
                                          ? formatDateUS(filtrosSelecionados.observacoes.dtCriacaoInicio)
                                          : null
                                      "
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker v-model="filtroSelObservacoesInicioUS" locale="pt-br" no-title @input="mnfiltroSelObservacoesInicioUS = false"></v-date-picker>
                                </v-menu>
                              </v-flex>
                              <v-flex xs6 md6>
                                <v-menu
                                  ref="mnfiltroSelObservacoesFimUS"
                                  v-model="mnfiltroSelObservacoesFimUS"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="filtrosSelecionados.observacoes.dtCriacaoFim"
                                      label="Fim"
                                      v-mask="'##/##/####'"
                                      :rules="[value => valida_data(value) || 'Data inválida']"
                                      color="blue-grey lighten-2"
                                      clearable
                                      prepend-icon="event"
                                      v-on="on"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      @click:clear="filtroSelObservacoesFimUS = null"
                                      @keyup="
                                        filtroSelObservacoesFimUS = valida_data(filtrosSelecionados.observacoes.dtCriacaoFim)
                                          ? formatDateUS(filtrosSelecionados.observacoes.dtCriacaoFim)
                                          : null
                                      "
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker v-model="filtroSelObservacoesFimUS" locale="pt-br" no-title @input="mnfiltroSelObservacoesFimUS = false"></v-date-picker>
                                </v-menu>
                              </v-flex>
                            </v-layout>
                          </v-container>
                        </v-layout>
                      </v-container>
                    </v-card-text>
                  </v-form>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Contato</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider></v-divider>
                <v-flex xs12 md12>
                  <v-container fluid grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12>
                        <h4 class="overline font-weight-black font-italic">
                          Contato
                        </h4>
                        <v-divider></v-divider>
                      </v-flex>

                      <v-container fluid grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12 md6>
                            <v-autocomplete
                              v-model="filtrosSelecionados.contatos.status"
                              :items="statusContato"
                              :filter="filtrarItens"
                              label="Status"
                              autocomplete="disabled"
                              :name="Math.random()"
                              no-data-text
                              clearable
                            ></v-autocomplete>
                          </v-flex>
                          <v-flex xs12 md6>
                            <v-text-field v-model="filtrosSelecionados.contatos.contato" label="Contato" maxlength="100" color="blue-grey lighten-2" clearable></v-text-field>
                          </v-flex>
                          <v-flex xs12>
                            <v-autocomplete
                              v-model="filtrosSelecionados.contatos.idAssunto"
                              :items="assuntos"
                              :filter="filtrarObjetos"
                              label="Assunto"
                              item-text="nome"
                              item-value="id"
                              autocomplete="disabled"
                              :name="Math.random()"
                              no-data-text
                              clearable
                              multiple
                            ></v-autocomplete>
                          </v-flex>
                          <v-flex xs12>
                            <v-autocomplete
                              v-model="filtrosSelecionados.contatos.idMotivo"
                              :items="motivos"
                              :filter="filtrarObjetos"
                              label="Motivo"
                              item-text="nome"
                              item-value="id"
                              autocomplete="disabled"
                              :name="Math.random()"
                              no-data-text
                              clearable
                              multiple
                            ></v-autocomplete>
                          </v-flex>
                          <v-flex xs12>
                            <v-autocomplete
                              v-model="filtrosSelecionados.contatos.idProcedimento"
                              :items="procedimentos"
                              :filter="filtrarObjetos"
                              label="Procedimento"
                              item-text="nome"
                              item-value="id"
                              autocomplete="disabled"
                              :name="Math.random()"
                              no-data-text
                              clearable
                              multiple
                            ></v-autocomplete>
                          </v-flex>
                          <v-flex xs12>
                            <v-autocomplete
                              v-model="filtrosSelecionados.contatos.idUsuario"
                              :items="usuarios"
                              :filter="filtrarObjetos"
                              label="Usuário"
                              item-text="nome"
                              item-value="id"
                              autocomplete="disabled"
                              :name="Math.random()"
                              no-data-text
                              clearable
                              multiple
                            ></v-autocomplete>
                          </v-flex>

                          <v-flex xs12>
                            <h4 class="overline font-weight-black font-italic">
                              Registrada entre
                            </h4>
                            <v-divider></v-divider>
                          </v-flex>
                          <v-container fluid grid-list-md>
                            <v-layout wrap>
                              <v-flex xs12 md6>
                                <v-menu
                                  ref="mnfiltroSelContatosInicioUS"
                                  v-model="mnfiltroSelContatosInicioUS"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="filtrosSelecionados.contatos.dtRegistroInicio"
                                      label="Início"
                                      v-mask="'##/##/####'"
                                      :rules="[value => valida_data(value) || 'Data inválida']"
                                      color="blue-grey lighten-2"
                                      clearable
                                      prepend-icon="event"
                                      v-on="on"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      @click:clear="filtroSelContatosInicioUS = null"
                                      @keyup="
                                        filtroSelContatosInicioUS = valida_data(filtrosSelecionados.contatos.dtRegistroInicio)
                                          ? formatDateUS(filtrosSelecionados.contatos.dtRegistroInicio)
                                          : null
                                      "
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker v-model="filtroSelContatosInicioUS" locale="pt-br" no-title @input="mnfiltroSelContatosInicioUS = false"></v-date-picker>
                                </v-menu>
                              </v-flex>
                              <v-flex xs12 md6>
                                <v-menu
                                  ref="mnfiltroSelContatosFimUS"
                                  v-model="mnfiltroSelContatosFimUS"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="filtrosSelecionados.contatos.dtRegistroFim"
                                      label="Fim"
                                      v-mask="'##/##/####'"
                                      :rules="[value => valida_data(value) || 'Data inválida']"
                                      color="blue-grey lighten-2"
                                      clearable
                                      prepend-icon="event"
                                      v-on="on"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      @click:clear="filtroSelContatosFimUS = null"
                                      @keyup="
                                        filtroSelContatosFimUS = valida_data(filtrosSelecionados.contatos.dtRegistroFim)
                                          ? formatDateUS(filtrosSelecionados.contatos.dtRegistroFim)
                                          : null
                                      "
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker v-model="filtroSelContatosFimUS" locale="pt-br" no-title @input="mnfiltroSelContatosFimUS = false"></v-date-picker>
                                </v-menu>
                              </v-flex>
                            </v-layout>
                          </v-container>
                        </v-layout>
                      </v-container>

                      <v-flex xs12>
                        <h4 class="overline font-weight-black font-italic">
                          Execução
                        </h4>
                        <v-divider></v-divider>
                      </v-flex>
                      <v-container fluid grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12>
                            <v-text-field v-model="filtrosSelecionados.contatos.execucao" label="Execução" maxlength="100" color="blue-grey lighten-2" clearable></v-text-field>
                          </v-flex>
                          <v-flex xs12>
                            <v-autocomplete
                              v-model="filtrosSelecionados.contatos.idUsuarioExecucao"
                              :items="usuarios"
                              :filter="filtrarObjetos"
                              label="Usuário"
                              item-text="nome"
                              item-value="id"
                              autocomplete="disabled"
                              :name="Math.random()"
                              no-data-text
                              clearable
                              multiple
                            ></v-autocomplete>
                          </v-flex>
                        </v-layout>
                      </v-container>
                      <v-container fluid grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12>
                            <h4 class="overline font-weight-black font-italic">
                              Executada entre
                            </h4>
                            <v-divider></v-divider>
                          </v-flex>
                          <v-container fluid grid-list-md>
                            <v-layout wrap>
                              <v-flex xs12 md6>
                                <v-menu
                                  ref="mnfiltroSelContatosDtExecucaoInicioUS"
                                  v-model="mnfiltroSelContatosDtExecucaoInicioUS"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="filtrosSelecionados.contatos.dtExecucaoInicio"
                                      label="Início"
                                      v-mask="'##/##/####'"
                                      :rules="[value => valida_data(value) || 'Data inválida']"
                                      color="blue-grey lighten-2"
                                      clearable
                                      prepend-icon="event"
                                      v-on="on"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      @click:clear="filtroSelContatosDtExecucaoInicioUS = null"
                                      @keyup="
                                        filtroSelContatosDtExecucaoInicioUS = valida_data(filtrosSelecionados.contatos.dtExecucaoInicio)
                                          ? formatDateUS(filtrosSelecionados.contatos.dtExecucaoInicio)
                                          : null
                                      "
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="filtroSelContatosDtExecucaoInicioUS"
                                    locale="pt-br"
                                    no-title
                                    @input="mnfiltroSelContatosDtExecucaoInicioUS = false"
                                  ></v-date-picker>
                                </v-menu>
                              </v-flex>
                              <v-flex xs12 md6>
                                <v-menu
                                  ref="mnfiltroSelContatosDtExecucaoFimUS"
                                  v-model="mnfiltroSelContatosDtExecucaoFimUS"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="filtrosSelecionados.contatos.dtExecucaoFim"
                                      label="Fim"
                                      v-mask="'##/##/####'"
                                      :rules="[value => valida_data(value) || 'Data inválida']"
                                      color="blue-grey lighten-2"
                                      clearable
                                      prepend-icon="event"
                                      v-on="on"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      @click:clear="filtroSelContatosDtExecucaoFimUS = null"
                                      @keyup="
                                        filtroSelContatosDtExecucaoFimUS = valida_data(filtrosSelecionados.contatos.dtExecucaoFim)
                                          ? formatDateUS(filtrosSelecionados.contatos.dtExecucaoFim)
                                          : null
                                      "
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="filtroSelContatosDtExecucaoFimUS"
                                    locale="pt-br"
                                    no-title
                                    @input="mnfiltroSelContatosDtExecucaoFimUS = false"
                                  ></v-date-picker>
                                </v-menu>
                              </v-flex>
                            </v-layout>
                          </v-container>
                        </v-layout>
                      </v-container>

                      <v-flex xs12>
                        <h4 class="overline font-weight-black font-italic">
                          Solução
                        </h4>
                        <v-divider></v-divider>
                      </v-flex>
                      <v-container fluid grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12>
                            <v-text-field v-model="filtrosSelecionados.contatos.solucao" label="Resolução" maxlength="100" color="blue-grey lighten-2" clearable></v-text-field>
                          </v-flex>
                          <v-flex xs12>
                            <v-autocomplete
                              v-model="filtrosSelecionados.contatos.idUsuarioSolucao"
                              :items="usuarios"
                              :filter="filtrarObjetos"
                              label="Usuário"
                              item-text="nome"
                              item-value="id"
                              autocomplete="disabled"
                              :name="Math.random()"
                              no-data-text
                              clearable
                              multiple
                            ></v-autocomplete>
                          </v-flex>
                        </v-layout>
                      </v-container>
                      <v-container fluid grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12>
                            <h4 class="overline font-weight-black font-italic">
                              Solucionada entre
                            </h4>
                            <v-divider></v-divider>
                          </v-flex>
                          <v-container fluid grid-list-md>
                            <v-layout wrap>
                              <v-flex xs12 md6>
                                <v-menu
                                  ref="mnfiltroSelContatosDtSolucaoInicioUS"
                                  v-model="mnfiltroSelContatosDtSolucaoInicioUS"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="filtrosSelecionados.contatos.dtSolucaoInicio"
                                      label="Início"
                                      v-mask="'##/##/####'"
                                      :rules="[value => valida_data(value) || 'Data inválida']"
                                      color="blue-grey lighten-2"
                                      clearable
                                      prepend-icon="event"
                                      v-on="on"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      @click:clear="filtroSelContatosDtSolucaoInicioUS = null"
                                      @keyup="
                                        filtroSelContatosDtSolucaoInicioUS = valida_data(filtrosSelecionados.contatos.dtSolucaoInicio)
                                          ? formatDateUS(filtrosSelecionados.contatos.dtSolucaoInicio)
                                          : null
                                      "
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="filtroSelContatosDtSolucaoInicioUS"
                                    locale="pt-br"
                                    no-title
                                    @input="mnfiltroSelContatosDtSolucaoInicioUS = false"
                                  ></v-date-picker>
                                </v-menu>
                              </v-flex>
                              <v-flex xs12 md6>
                                <v-menu
                                  ref="mnfiltroSelContatosDtSolucaoFimUS"
                                  v-model="mnfiltroSelContatosDtSolucaoFimUS"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="filtrosSelecionados.contatos.dtSolucaoFim"
                                      label="Fim"
                                      v-mask="'##/##/####'"
                                      :rules="[value => valida_data(value) || 'Data inválida']"
                                      color="blue-grey lighten-2"
                                      clearable
                                      prepend-icon="event"
                                      v-on="on"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      @click:clear="filtroSelContatosDtSolucaoFimUS = null"
                                      @keyup="
                                        filtroSelContatosDtSolucaoFimUS = valida_data(filtrosSelecionados.contatos.dtSolucaoFim)
                                          ? formatDateUS(filtrosSelecionados.contatos.dtSolucaoFim)
                                          : null
                                      "
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="filtroSelContatosDtSolucaoFimUS"
                                    locale="pt-br"
                                    no-title
                                    @input="mnfiltroSelContatosDtSolucaoFimUS = false"
                                  ></v-date-picker>
                                </v-menu>
                              </v-flex>
                            </v-layout>
                          </v-container>
                        </v-layout>
                      </v-container>
                    </v-layout>
                  </v-container>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Promoção</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-form ref="formPromocao" lazy-validation>
                    <v-card-text>
                      <v-container grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12 md12>
                            <v-autocomplete
                              v-model="filtrosSelecionados.promocoes.idPromocao"
                              :items="promocoes"
                              :filter="filtrarObjetos"
                              label="Promoção"
                              item-text="nome"
                              item-value="id"
                              no-data-text
                              clearable
                              multiple
                              autocomplete="disabled"
                              :name="Math.random()"
                            ></v-autocomplete>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-card-text>
                  </v-form>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Cortesia</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-form ref="formCortesia" lazy-validation>
                    <v-card-text>
                      <v-container grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12 md12>
                            <v-autocomplete
                              v-model="filtrosSelecionados.cortesias.idCliente"
                              :items="cortesias"
                              :filter="filtrarObjetos"
                              label="Empresa com Cortesia"
                              item-text="nome"
                              item-value="id"
                              no-data-text
                              clearable
                              multiple
                              autocomplete="disabled"
                              :name="Math.random()"
                            ></v-autocomplete>
                          </v-flex>

                          <v-flex xs12>
                            <h4 class="overline font-weight-black font-italic">
                              Registrada entre
                            </h4>
                            <v-divider></v-divider>
                          </v-flex>
                          <v-container fluid grid-list-md>
                            <v-layout wrap>
                              <v-flex xs6 md6>
                                <v-menu
                                  ref="mnfiltroSelCortesiasInicioUS"
                                  v-model="mnfiltroSelCortesiasInicioUS"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="filtrosSelecionados.cortesias.dtCriacaoInicio"
                                      label="Início"
                                      v-mask="'##/##/####'"
                                      :rules="[value => valida_data(value) || 'Data inválida']"
                                      color="blue-grey lighten-2"
                                      clearable
                                      prepend-icon="event"
                                      v-on="on"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      @click:clear="filtroSelCortesiasInicioUS = null"
                                      @keyup="
                                        filtroSelCortesiasInicioUS = valida_data(filtrosSelecionados.cortesias.dtCriacaoInicio)
                                          ? formatDateUS(filtrosSelecionados.cortesias.dtCriacaoInicio)
                                          : null
                                      "
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker v-model="filtroSelCortesiasInicioUS" locale="pt-br" no-title @input="mnfiltroSelCortesiasInicioUS = false"></v-date-picker>
                                </v-menu>
                              </v-flex>
                              <v-flex xs6 md6>
                                <v-menu
                                  ref="mnfiltroSelCortesiasFimUS"
                                  v-model="mnfiltroSelCortesiasFimUS"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="filtrosSelecionados.cortesias.dtCriacaoFim"
                                      label="Fim"
                                      v-mask="'##/##/####'"
                                      :rules="[value => valida_data(value) || 'Data inválida']"
                                      color="blue-grey lighten-2"
                                      clearable
                                      prepend-icon="event"
                                      v-on="on"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      @click:clear="filtroSelCortesiasFimUS = null"
                                      @keyup="
                                        filtroSelCortesiasFimUS = valida_data(filtrosSelecionados.cortesias.dtCriacaoFim)
                                          ? formatDateUS(filtrosSelecionados.cortesias.dtCriacaoFim)
                                          : null
                                      "
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker v-model="filtroSelCortesiasFimUS" locale="pt-br" no-title @input="mnfiltroSelCortesiasFimUS = false"></v-date-picker>
                                </v-menu>
                              </v-flex>
                            </v-layout>
                          </v-container>
                        </v-layout>
                      </v-container>
                    </v-card-text>
                  </v-form>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Banca</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-form ref="formBanca" lazy-validation>
                    <v-card-text>
                      <v-container grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12 md12>
                            <v-autocomplete
                              v-model="filtrosSelecionados.banca.idTipoBanca"
                              :items="tiposBanca"
                              :filter="filtrarObjetos"
                              label="Tipo de Banca"
                              item-text="nome"
                              item-value="id"
                              no-data-text
                              clearable
                              multiple
                              autocomplete="disabled"
                              :name="Math.random()"
                            ></v-autocomplete>
                          </v-flex>
                          <v-flex xs12 md12>
                            <v-autocomplete
                              v-model="filtrosSelecionados.banca.idFormaPgto"
                              :items="formasPgto"
                              :filter="filtrarObjetos"
                              label="Forma de Pagamento"
                              item-text="nome"
                              item-value="id"
                              no-data-text
                              clearable
                              multiple
                              autocomplete="disabled"
                              :name="Math.random()"
                            ></v-autocomplete>
                          </v-flex>
                          <v-flex xs12 md4>
                            <v-autocomplete
                              v-model="filtrosSelecionados.banca.situacao"
                              :items="itensSituacao"
                              :filter="filtrarObjetos"
                              label="Situação"
                              item-text="nome"
                              item-value="id"
                              no-data-text
                              clearable
                              autocomplete="disabled"
                              :name="Math.random()"
                            ></v-autocomplete>
                          </v-flex>
                          <v-flex xs12 md4>
                            <v-autocomplete
                              v-model="filtrosSelecionados.banca.distribuidora"
                              :items="itensSimNao"
                              :filter="filtrarObjetos"
                              label="Distribuidora"
                              item-text="nome"
                              item-value="id"
                              no-data-text
                              clearable
                              autocomplete="disabled"
                              :name="Math.random()"
                            ></v-autocomplete>
                          </v-flex>
                          <v-flex xs12 md4>
                            <v-text-field
                              v-model="filtrosSelecionados.banca.codAntigoBanca"
                              label="Código Antigo"
                              v-mask="'#######'"
                              color="blue-grey lighten-2"
                              clearable
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-card-text>
                  </v-form>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Agente</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-form ref="formAgente" lazy-validation>
                    <v-card-text>
                      <v-container grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12 md4>
                            <v-autocomplete
                              v-model="filtrosSelecionados.agente.situacao"
                              :items="itensSituacao"
                              :filter="filtrarObjetos"
                              label="Situação"
                              item-text="nome"
                              item-value="id"
                              no-data-text
                              clearable
                              autocomplete="disabled"
                              :name="Math.random()"
                            ></v-autocomplete>
                          </v-flex>
                          <v-flex xs12 md8>
                            <v-text-field v-model="filtrosSelecionados.agente.origem" label="Área/Origem de Venda" color="blue-grey lighten-2" clearable></v-text-field>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-card-text>
                  </v-form>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
export default {
  props: {
    prefixo: { type: String, default: "" }
  },
  data() {
    this.$vuetify.theme.primary = "blue lighten-2";
    return {
      filtrosSelecionados: {
        idSite: null,
        identificacao: "",
        id: null,
        inscricaoEstadual: null,
        inscricaoMunicipal: null,
        nome: "",
        nomeFantasia: "",
        idRamo: null,
        email: "",
        dtCriacao: null,
        dtNascimento: null,
        sexo: null,
        statusCredito: null,
        diaVencimento: null,
        codAntigoComercial: null,
        codAntigoCirculacao: null,
        codFinanceiro: null,
        renovacaoAutomatica: null,
        situacao: null,
        emailCliente: null,
        assinaturaCliente: null,
        anuncioCliente: null,
        funcionario: null,
        lgpd: null,
        tipoProtheus: null,
        enderecos: {
          cobranca: null,
          entrega: null,
          idUF: null,
          idMunicipio: null,
          cep: null,
          idCEP: null,
          logradouro: "",
          numero: null,
          complemento: "",
          idModoEntrega: null,
          complementoTipo: "",
          complementoNumero: "",
          complementoBloco: "",
          complementoEdificio: "",
          situacao: null,
          dtCriacaoInicio: null,
          dtCriacaoFim: null,
          online: null
        },
        telefones: {
          comercial: null,
          residencial: null,
          celular: null,
          whatsapp: null,
          ddd: "",
          numero: "",
          naoIncomodar: null,
          situacao: null,
          dtCriacaoInicio: null,
          dtCriacaoFim: null
        },
        emails: {
          email: "",
          fatura: null,
          situacao: null,
          dtCriacaoInicio: null,
          dtCriacaoFim: null
        },
        cartoes: {
          identificacao: "",
          nome: "",
          email: "",
          numero: "",
          cvv: "",
          validade: "",
          situacao: null,
          dtCriacaoInicio: null,
          dtCriacaoFim: null
        },
        contas: {
          identificacao: "",
          idCedente: "",
          agencia: "",
          numero: "",
          codFinanceiro: null,
          situacao: null,
          dtCriacaoInicio: null,
          dtCriacaoFim: null
        },
        celescs: {
          identificacao: "",
          unidadeConsumidora: "",
          situacao: null,
          dtCriacaoInicio: null,
          dtCriacaoFim: null
        },
        dependentes: {
          identificacao: "",
          nome: "",
          dtNascimento: null,
          clubeCortesia: null,
          associacao: "",
          email: "",
          foneDDD: "",
          foneNumero: "",
          situacao: null,
          codAntigoCirculacao: null,
          dtCriacaoInicio: null,
          dtCriacaoFim: null
        },
        departamentos: {
          departamento: "",
          situacao: null,
          dtCriacaoInicio: null,
          dtCriacaoFim: null
        },
        observacoes: {
          observacao: "",
          situacao: null,
          dtCriacaoInicio: null,
          dtCriacaoFim: null
        },
        contatos: {
          status: null,
          dtRegistroInicio: null,
          dtRegistroFim: null,
          idUsuario: null,
          idAssunto: null,
          idMotivo: null,
          idProcedimento: null,
          contato: "",
          idUsuarioExecucao: null,
          dtExecucaoInicio: null,
          dtExecucaoFim: null,
          execucao: "",
          idUsuarioSolucao: null,
          dtSolucaoInicio: null,
          dtSolucaoFim: null,
          solucao: ""
        },
        promocoes: {
          idPromocao: null
        },
        cortesias: {
          idCliente: null,
          dtCriacaoInicio: null,
          dtCriacaoFim: null
        },
        banca: {
          idTipoBanca: null,
          situacao: null,
          codAntigoBanca: null,
          distribuidora: null
        },
        agente: {
          situacao: null,
          origem: null
        }
      },
      clienteDefault: {
        idSite: null,
        identificacao: "",
        id: null,
        inscricaoEstadual: null,
        inscricaoMunicipal: null,
        nome: "",
        nomeFantasia: "",
        idRamo: null,
        email: "",
        dtCriacao: null,
        dtNascimento: null,
        sexo: null,
        statusCredito: null,
        diaVencimento: null,
        codAntigoComercial: null,
        codAntigoCirculacao: null,
        codFinanceiro: null,
        renovacaoAutomatica: null,
        situacao: null,
        emailCliente: null,
        assinaturaCliente: null,
        anuncioCliente: null,
        funcionario: null,
        lgpd: null,
        tipoProtheus: null,
        enderecos: {
          cobranca: null,
          entrega: null,
          idUF: null,
          idMunicipio: null,
          cep: null,
          idCEP: null,
          logradouro: "",
          numero: null,
          complemento: "",
          idModoEntrega: null,
          complementoTipo: "",
          complementoNumero: "",
          complementoBloco: "",
          complementoEdificio: "",
          situacao: null,
          dtCriacaoInicio: null,
          dtCriacaoFim: null,
          online: null
        },
        telefones: {
          comercial: null,
          residencial: null,
          celular: null,
          whatsapp: null,
          ddd: "",
          numero: "",
          naoIncomodar: null,
          situacao: null,
          dtCriacaoInicio: null,
          dtCriacaoFim: null
        },
        emails: {
          email: "",
          fatura: null,
          situacao: null,
          dtCriacaoInicio: null,
          dtCriacaoFim: null
        },
        cartoes: {
          identificacao: "",
          nome: "",
          email: "",
          numero: "",
          cvv: "",
          validade: "",
          situacao: null,
          dtCriacaoInicio: null,
          dtCriacaoFim: null
        },
        contas: {
          identificacao: "",
          banco: "",
          agencia: "",
          numero: "",
          codFinanceiro: null,
          situacao: null,
          dtCriacaoInicio: null,
          dtCriacaoFim: null
        },
        celescs: {
          identificacao: "",
          unidadeConsumidora: "",
          situacao: null,
          dtCriacaoInicio: null,
          dtCriacaoFim: null
        },
        dependentes: {
          identificacao: "",
          nome: "",
          dtNascimento: null,
          clubeCortesia: null,
          associacao: "",
          email: "",
          foneDDD: "",
          foneNumero: "",
          situacao: null,
          codAntigoCirculacao: null,
          dtCriacaoInicio: null,
          dtCriacaoFim: null
        },
        departamentos: {
          departamento: "",
          situacao: null,
          dtCriacaoInicio: null,
          dtCriacaoFim: null
        },
        observacoes: {
          observacao: "",
          situacao: null,
          dtCriacaoInicio: null,
          dtCriacaoFim: null
        },
        contatos: {
          status: null,
          dtRegistroInicio: null,
          dtRegistroFim: null,
          idUsuario: null,
          idAssunto: null,
          idMotivo: null,
          idProcedimento: null,
          contato: "",
          idUsuarioExecucao: null,
          dtExecucaoInicio: null,
          dtExecucaoFim: null,
          execucao: "",
          idUsuarioSolucao: null,
          dtSolucaoInicio: null,
          dtSolucaoFim: null,
          solucao: ""
        },
        promocoes: {
          idPromocao: null
        },
        cortesias: {
          idCliente: null,
          dtCriacaoInicio: null,
          dtCriacaoFim: null
        },
        banca: {
          idTipoBanca: null,
          situacao: null,
          codAntigoBanca: null,
          distribuidora: null
        },
        agente: {
          situacao: null,
          origem: null
        }
      },

      filtroSelDtCriacaoUS: null,
      mnfiltroSelDtCriacaoUS: false,

      filtroSelDtNascimentoUS: null,
      mnfiltroSelDtNascimentoUS: false,

      filtroSelDtNascDependenteUS: null,
      mnfiltroSelDtNascDependenteUS: false,

      filtroSelEnderecosInicioUS: null,
      mnfiltroSelEnderecosInicioUS: false,
      filtroSelEnderecosFimUS: null,
      mnfiltroSelEnderecosFimUS: false,

      filtroSelTelefonesInicioUS: null,
      mnfiltroSelTelefonesInicioUS: false,
      filtroSelTelefonesFimUS: null,
      mnfiltroSelTelefonesFimUS: false,

      filtroSelEmailsInicioUS: null,
      mnfiltroSelEmailsInicioUS: false,
      filtroSelEmailsFimUS: null,
      mnfiltroSelEmailsFimUS: false,

      filtroSelCartoesInicioUS: null,
      mnfiltroSelCartoesInicioUS: false,
      filtroSelCartoesFimUS: null,
      mnfiltroSelCartoesFimUS: false,

      filtroSelContasInicioUS: null,
      mnfiltroSelContasInicioUS: false,
      filtroSelContasFimUS: null,
      mnfiltroSelContasFimUS: false,

      filtroSelCelescsInicioUS: null,
      mnfiltroSelCelescsInicioUS: false,
      filtroSelCelescsFimUS: null,
      mnfiltroSelCelescsFimUS: false,

      filtroSelDependentesInicioUS: null,
      mnfiltroSelDependentesInicioUS: false,
      filtroSelDependentesFimUS: null,
      mnfiltroSelDependentesFimUS: false,

      filtroSelDepartamentosInicioUS: null,
      mnfiltroSelDepartamentosInicioUS: false,
      filtroSelDepartamentosFimUS: null,
      mnfiltroSelDepartamentosFimUS: false,

      filtroSelObservacoesInicioUS: null,
      mnfiltroSelObservacoesInicioUS: false,
      filtroSelObservacoesFimUS: null,
      mnfiltroSelObservacoesFimUS: false,

      filtroSelContatosInicioUS: null,
      mnfiltroSelContatosInicioUS: false,
      filtroSelContatosFimUS: null,
      mnfiltroSelContatosFimUS: false,

      filtroSelContatosDtExecucaoInicioUS: null,
      mnfiltroSelContatosDtExecucaoInicioUS: false,
      filtroSelContatosDtExecucaoFimUS: null,
      mnfiltroSelContatosDtExecucaoFimUS: false,

      filtroSelContatosDtSolucaoInicioUS: null,
      mnfiltroSelContatosDtSolucaoInicioUS: false,
      filtroSelContatosDtSolucaoFimUS: null,
      mnfiltroSelContatosDtSolucaoFimUS: false,

      filtroSelCortesiasInicioUS: null,
      mnfiltroSelCortesiasInicioUS: false,
      filtroSelCortesiasFimUS: null,
      mnfiltroSelCortesiasFimUS: false,

      idSitePrincipal: null,
      siteGrupo: [],
      ramos: [],
      promocoes: [],
      cortesias: [],
      tiposBanca: [],
      formasPgto: [],
      sexos: [
        { id: "M", nome: "Masculino" },
        { id: "F", nome: "Feminino" },
        { id: "O", nome: "Outros" },
        { id: "N", nome: "Não Informado" }
      ],
      statusCreditos: [
        { id: 1, nome: "LIBERADO" },
        { id: 0, nome: "BLOQUEADO" },
        { id: 2, nome: "EM ANÁLISE" }
      ],
      itensEmailFatura: [
        {
          id: 1,
          nome: "Fatura (para envio de notas fiscais, boletos e cobrança)"
        },
        { id: 0, nome: "Não é para envio de fatura" }
      ],
      itensDependentesClube: [
        {
          id: 1,
          nome: "Faz parte do Clube do Assinante"
        },
        { id: 0, nome: "Não faz parte do Clube" }
      ],
      itensClubeForma: [
        {
          id: 1,
          nome: "Cortesia"
        },
        { id: 0, nome: "Pago" }
      ],
      itensNaoIncomodar: [
        { id: 0, nome: "Desbloqueado" },
        { id: 1, nome: "Não Incomodar" }
      ],
      itensSituacao: [
        { id: 1, nome: "Ativo" },
        { id: 0, nome: "Inativo" }
      ],
      itensSimNao: [
        { id: 1, nome: "SIM" },
        { id: 0, nome: "NÃO" }
      ],
      itensEmailCliente: [
        { id: 1, nome: "Cliente possui e-mail" },
        { id: 0, nome: "Cliente não possui e-mail" }
      ],
      itensAssinaturaCliente: [
        { id: 1, nome: "Cliente possui assinatura" },
        { id: 0, nome: "Cliente não possui assinatura" }
      ],
      itensAnuncioCliente: [
        { id: 1, nome: "Cliente possui anúncio" },
        { id: 0, nome: "Cliente não possui anúncio" }
      ],
      itensLGPD: [
        { id: 1, nome: "Ocultar Dados" },
        { id: 0, nome: "Uso Autorizado" }
      ],
      itensProtheus: [
        { id: 1, nome: "Faturamento por Parcela" },
        { id: 0, nome: "Faturamento por Contrato" }
      ],
      statusContato: ["ABERTO", "EXECUÇÃO", "FECHADO"],
      assuntos: [],
      motivos: [],
      procedimentos: [],
      usuarios: [],
      bancosDebitoConta: [],
      modosEntrega: [],
      ufs: [],
      municipios: [],
      ceps: [],

      direitoAssinaturas: false,
      direitoCEPs: false,
      direitoRamos: false,
      id: "",
      painel: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],

      autoUpdate: true
    };
  },

  methods: {
    carregarRamos() {
      api.get(process.env.VUE_APP_URL_WEB_API + "adm/ramo-atividade/lista?idSite=" + this.idSitePrincipal).then(response => {
        this.ramos = response.data;
      });
    },
    carregarPromocoes() {
      api.get(process.env.VUE_APP_URL_WEB_API + "ass/promocao/all?idSite=" + this.idSitePrincipal).then(response => {
        this.promocoes = response.data;
      });
    },
    carregarCortesias() {
      api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-cortesia/clientes?idSite=" + this.idSitePrincipal).then(response => {
        this.cortesias = response.data;
      });
    },
    carregarTiposBanca() {
      api.get(process.env.VUE_APP_URL_WEB_API + "ass/tipo-banca/all?idSite=" + this.idSitePrincipal).then(response => {
        this.tiposBanca = response.data;
      });
    },
    carregarFormasPgto() {
      api.get(process.env.VUE_APP_URL_WEB_API + "adm/forma-pagamento/all?idSite=" + this.idSitePrincipal).then(response => {
        this.formasPgto = response.data;
      });
    },
    carregarMotivosContato() {
      api.get(process.env.VUE_APP_URL_WEB_API + "adm/motivo-contato/lista?idSite=" + this.idSitePrincipal).then(response => {
        response.data.forEach(element => {
          if (element.tipo == 2) {
            this.motivos.push(element);
          } else if (element.tipo == 3) {
            this.procedimentos.push(element);
          } else {
            this.assuntos.push(element);
          }
        });
      });
    },
    carregarUsuarios() {
      api.get(process.env.VUE_APP_URL_WEB_API + "adm/site-usuario/usuariosSite/" + this.idSitePrincipal).then(response => {
        this.usuarios = response.data;
      });
    },

    filtrarMunicipios() {
      if (this.filtrosSelecionados.enderecos.idUF == null) {
        this.municipios = [];
        this.filtrosSelecionados.enderecos.idMunicipio = null;
      } else {
        this.carregarMunicipios();
      }
    },
    carregarMunicipios() {
      //* Carrega os municípios da UF
      api.get(process.env.VUE_APP_URL_WEB_API + "adm/municipio/lista?idUF=" + this.filtrosSelecionados.enderecos.idUF).then(response => {
        this.municipios = response.data;
      });
    },
    filtrarCEPs() {
      if (this.filtrosSelecionados.enderecos.idMunicipio == null) {
        this.ceps = [];
        this.filtrosSelecionados.enderecos.idCEP = null;
      } else {
        //* Carrega os logradouros do município
        api.get(process.env.VUE_APP_URL_WEB_API + "adm/cep/municipio/" + this.filtrosSelecionados.enderecos.idMunicipio).then(response => {
          this.ceps = response.data;
          this.ceps.forEach(element => {
            element.nome = element.cep + ": " + element.bairro + " - " + element.tipo + " " + element.logradouro + (element.faixa ? " - " : "") + element.faixa;
          });
        });
      }
    },
    buscarCep() {
      this.filtrosSelecionados.enderecos.idUF = null;
      this.filtrosSelecionados.enderecos.idMunicipio = null;
      this.filtrosSelecionados.enderecos.idCEP = null;

      if (this.filtrosSelecionados.enderecos.cep && this.filtrosSelecionados.enderecos.cep.length == 9) {
        //* Carrega os logradouros com o CEP
        var cep = util.somenteNumeros(this.filtrosSelecionados.enderecos.cep);
        api.get(process.env.VUE_APP_URL_WEB_API + "adm/cep/cep/" + cep).then(response => {
          this.ceps = response.data;
          this.ceps.forEach(element => {
            element.nome = element.cep + ": " + element.bairro + " - " + element.tipo + " " + element.logradouro + (element.faixa ? " - " : "") + element.faixa;
          });
          var cep0 = this.ceps.length ? this.ceps[0] : null;
          if (cep0) {
            this.filtrosSelecionados.enderecos.idUF = cep0.idUF;
            this.carregarMunicipios();
            this.filtrosSelecionados.enderecos.idMunicipio = cep0.idMunicipio;
            if (this.ceps.length == 1) {
              this.filtrosSelecionados.enderecos.idCEP = cep0.id;
            }
          }
        });
      }
    },

    // * Métodos para controle dos itens
    clickEndereco(tipo) {
      if (tipo == "cobranca") {
        this.filtrosSelecionados.enderecos.cobranca = this.filtrosSelecionados.enderecos.cobranca == 1 ? null : 1;
      } else {
        this.filtrosSelecionados.enderecos.entrega = this.filtrosSelecionados.enderecos.entrega == 1 ? null : 1;
      }
    },

    clickTelefone(tipo) {
      if (tipo == "comercial") {
        this.filtrosSelecionados.telefones.comercial = this.filtrosSelecionados.telefones.comercial ? null : 1;
      } else if (tipo == "residencial") {
        this.filtrosSelecionados.telefones.residencial = this.filtrosSelecionados.telefones.residencial ? null : 1;
      } else if (tipo == "celular") {
        this.filtrosSelecionados.telefones.celular = this.filtrosSelecionados.telefones.celular ? null : 1;
      } else if (tipo == "whatsapp") {
        this.filtrosSelecionados.telefones.whatsapp = this.filtrosSelecionados.telefones.whatsapp ? null : 1;
      }
    },
    limpar() {
      this.filtrosSelecionados = JSON.parse(JSON.stringify(this.clienteDefault));
      this.painel = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      this.filtroSelDtCriacaoUS = null;
      this.filtroSelDtNascimentoUS = null;
      this.filtroSelDtNascDependenteUS = null;
      this.filtroSelEnderecosInicioUS = null;
      this.filtroSelEnderecosFimUS = null;
      this.filtroSelTelefonesInicioUS = null;
      this.filtroSelTelefonesFimUS = null;
      this.filtroSelEmailsInicioUS = null;
      this.filtroSelEmailsFimUS = null;
      this.filtroSelCartoesInicioUS = null;
      this.filtroSelCartoesFimUS = null;
      this.filtroSelContasInicioUS = null;
      this.filtroSelContasFimUS = null;
      this.filtroSelCelescsInicioUS = null;
      this.filtroSelCelescsFimUS = null;
      this.filtroSelDependentesInicioUS = null;
      this.filtroSelDependentesFimUS = null;
      this.filtroSelDepartamentosInicioUS = null;
      this.filtroSelDepartamentosFimUS = null;
      this.filtroSelObservacoesInicioUS = null;
      this.filtroSelObservacoesFimUS = null;
      this.filtroSelContatosInicioUS = null;
      this.filtroSelContatosFimUS = null;
      this.filtroSelContatosDtExecucaoInicioUS = null;
      this.filtroSelContatosDtExecucaoFimUS = null;
      this.filtroSelContatosDtSolucaoInicioUS = null;
      this.filtroSelContatosDtSolucaoFimUS = null;
      this.filtroSelCortesiasInicioUS = null;
      this.filtroSelCortesiasFimUS = null;
    },
    filtroUrlParametros() {
      var filtro = "";

      //* Verifica os campos que foram preenchidos
      if (this.prefixo == "") {
        if (this.filtrosSelecionados.idSite) {
          filtro = filtro + "&" + this.prefixo + "idSite=" + this.filtrosSelecionados.idSite;
        }
      }

      if (this.filtrosSelecionados.identificacao) {
        filtro = filtro + "&" + this.prefixo + "identificacao=" + this.filtrosSelecionados.identificacao;
      }

      if (this.filtrosSelecionados.id) {
        filtro = filtro + "&" + this.prefixo + "id=" + this.filtrosSelecionados.id;
      }

      if (this.filtrosSelecionados.inscricaoEstadual) {
        filtro = filtro + "&" + this.prefixo + "inscricaoEstadual=" + this.filtrosSelecionados.inscricaoEstadual;
      }

      if (this.filtrosSelecionados.inscricaoMunicipal) {
        filtro = filtro + "&" + this.prefixo + "inscricaoMunicipal=" + this.filtrosSelecionados.inscricaoMunicipal;
      }

      if (this.filtrosSelecionados.nome) {
        filtro = filtro + "&" + this.prefixo + "nome=" + this.filtrosSelecionados.nome;
      }

      if (this.filtrosSelecionados.nomeFantasia) {
        filtro = filtro + "&" + this.prefixo + "nomeFantasia=" + this.filtrosSelecionados.nomeFantasia;
      }

      if (this.filtrosSelecionados.idRamo && this.filtrosSelecionados.idRamo.length) {
        filtro = filtro + "&" + this.prefixo + "idRamo=" + this.filtrosSelecionados.idRamo.join(",");
      }

      if (this.filtrosSelecionados.email) {
        filtro = filtro + "&" + this.prefixo + "email=" + this.filtrosSelecionados.email;
      }

      if (this.filtrosSelecionados.dtCriacao) {
        filtro = filtro + "&" + this.prefixo + "dtCriacao=" + this.filtrosSelecionados.dtCriacao;
      }

      if (this.filtrosSelecionados.dtNascimento) {
        filtro = filtro + "&" + this.prefixo + "dtNascimento=" + this.filtrosSelecionados.dtNascimento;
      }

      if (this.filtrosSelecionados.sexo && this.filtrosSelecionados.sexo.length) {
        filtro = filtro + "&" + this.prefixo + "sexo=" + this.filtrosSelecionados.sexo.join(",");
      }

      if (this.filtrosSelecionados.statusCredito && this.filtrosSelecionados.statusCredito.length) {
        filtro = filtro + "&" + this.prefixo + "statusCredito=" + this.filtrosSelecionados.statusCredito.join(",");
      }

      if (this.filtrosSelecionados.diaVencimento) {
        filtro = filtro + "&" + this.prefixo + "diaVencimento=" + this.filtrosSelecionados.diaVencimento;
      }

      if (this.filtrosSelecionados.codAntigoComercial) {
        filtro = filtro + "&" + this.prefixo + "codAntigoComercial=" + this.filtrosSelecionados.codAntigoComercial;
      }

      if (this.filtrosSelecionados.codAntigoCirculacao) {
        filtro = filtro + "&" + this.prefixo + "codAntigoCirculacao=" + this.filtrosSelecionados.codAntigoCirculacao;
      }

      if (this.filtrosSelecionados.codFinanceiro) {
        filtro = filtro + "&" + this.prefixo + "codFinanceiro=" + this.filtrosSelecionados.codFinanceiro;
      }

      if (this.filtrosSelecionados.situacao != null) {
        filtro = filtro + "&" + this.prefixo + "situacao=" + this.filtrosSelecionados.situacao;
      }
      if (this.filtrosSelecionados.online != null) {
        filtro = filtro + "&" + this.prefixo + "online=" + this.filtrosSelecionados.online;
      }
      if (this.filtrosSelecionados.emailCliente != null) {
        filtro = filtro + "&" + this.prefixo + "emailCliente=" + this.filtrosSelecionados.emailCliente;
      }
      if (this.filtrosSelecionados.assinaturaCliente != null) {
        filtro = filtro + "&a.assinaturaCliente=" + this.filtrosSelecionados.assinaturaCliente;
      }
      if (this.filtrosSelecionados.anuncioCliente != null) {
        filtro = filtro + "&u.anuncioCliente=" + this.filtrosSelecionados.anuncioCliente;
      }
      if (this.filtrosSelecionados.renovacaoAutomatica != null) {
        filtro = filtro + "&" + this.prefixo + "renovacaoAutomatica=" + this.filtrosSelecionados.renovacaoAutomatica;
      }
      if (this.filtrosSelecionados.funcionario != null) {
        filtro = filtro + "&" + this.prefixo + "funcionario=" + this.filtrosSelecionados.funcionario;
      }
      if (this.filtrosSelecionados.lgpd != null) {
        filtro = filtro + "&" + this.prefixo + "lgpd=" + this.filtrosSelecionados.lgpd;
      }
      if (this.filtrosSelecionados.tipoProtheus != null) {
        filtro = filtro + "&" + this.prefixo + "tipoProtheus=" + this.filtrosSelecionados.tipoProtheus;
      }

      if (this.filtrosSelecionados.enderecos) {
        if (this.filtrosSelecionados.enderecos.cobranca) {
          filtro = filtro + "&" + this.prefixo + "enderecosCobranca=" + this.filtrosSelecionados.enderecos.cobranca;
        }

        if (this.filtrosSelecionados.enderecos.entrega) {
          filtro = filtro + "&" + this.prefixo + "enderecosEntrega=" + this.filtrosSelecionados.enderecos.entrega;
        }

        if (this.filtrosSelecionados.enderecos.idUF) {
          filtro = filtro + "&" + this.prefixo + "enderecosIdUF=" + this.filtrosSelecionados.enderecos.idUF;
        }

        if (this.filtrosSelecionados.enderecos.idMunicipio) {
          filtro = filtro + "&" + this.prefixo + "enderecosIdMunicipio=" + this.filtrosSelecionados.enderecos.idMunicipio;
        }

        if (this.filtrosSelecionados.enderecos.idCEP) {
          filtro = filtro + "&" + this.prefixo + "enderecosIdCEP=" + this.filtrosSelecionados.enderecos.idCEP;
        }
        if (this.filtrosSelecionados.enderecos.cep) {
          var cep = util.somenteNumeros(this.filtrosSelecionados.enderecos.cep);
          filtro = filtro + "&" + this.prefixo + "enderecosCep=" + cep;
        }

        if (this.filtrosSelecionados.enderecos.logradouro) {
          filtro = filtro + "&" + this.prefixo + "enderecosLogradouro=" + this.filtrosSelecionados.enderecos.logradouro;
        }

        if (this.filtrosSelecionados.enderecos.numero) {
          filtro = filtro + "&" + this.prefixo + "enderecosNumero=" + this.filtrosSelecionados.enderecos.numero;
        }

        if (this.filtrosSelecionados.enderecos.complemento) {
          filtro = filtro + "&" + this.prefixo + "enderecosComplemento=" + this.filtrosSelecionados.enderecos.complemento;
        }

        if (this.filtrosSelecionados.enderecos.idModoEntrega && this.filtrosSelecionados.enderecos.idModoEntrega.length) {
          filtro = filtro + "&" + this.prefixo + "enderecosIdModoEntrega=" + this.filtrosSelecionados.enderecos.idModoEntrega.join(",");
        }

        if (this.filtrosSelecionados.enderecos.complementoTipo) {
          filtro = filtro + "&" + this.prefixo + "enderecosComplementoTipo=" + this.filtrosSelecionados.enderecos.complementoTipo;
        }

        if (this.filtrosSelecionados.enderecos.complementoNumero) {
          filtro = filtro + "&" + this.prefixo + "enderecosComplementoNumero=" + this.filtrosSelecionados.enderecos.complementoNumero;
        }

        if (this.filtrosSelecionados.enderecos.complementoBloco) {
          filtro = filtro + "&" + this.prefixo + "enderecosComplementoBloco=" + this.filtrosSelecionados.enderecos.complementoBloco;
        }

        if (this.filtrosSelecionados.enderecos.complementoEdificio) {
          filtro = filtro + "&" + this.prefixo + "enderecosComplementoEdificio=" + this.filtrosSelecionados.enderecos.complementoEdificio;
        }

        if (this.filtrosSelecionados.enderecos.situacao != null) {
          filtro = filtro + "&" + this.prefixo + "enderecosSituacao=" + this.filtrosSelecionados.enderecos.situacao;
        }

        if (this.filtrosSelecionados.enderecos.dtCriacaoInicio) {
          filtro = filtro + "&" + this.prefixo + "enderecosDtCriacaoInicio=" + this.filtrosSelecionados.enderecos.dtCriacaoInicio;
        }
        if (this.filtrosSelecionados.enderecos.dtCriacaoFim) {
          filtro = filtro + "&" + this.prefixo + "enderecosDtCriacaoFim=" + this.filtrosSelecionados.enderecos.dtCriacaoFim;
        }
        if (this.filtrosSelecionados.enderecos.online != null) {
          filtro = filtro + "&" + this.prefixo + "enderecosOnline=" + this.filtrosSelecionados.enderecos.online;
        }
      }

      if (this.filtrosSelecionados.telefones) {
        if (this.filtrosSelecionados.telefones.comercial) {
          filtro = filtro + "&" + this.prefixo + "telefonesComercial=" + this.filtrosSelecionados.telefones.comercial;
        }

        if (this.filtrosSelecionados.telefones.residencial) {
          filtro = filtro + "&" + this.prefixo + "telefonesResidencial=" + this.filtrosSelecionados.telefones.residencial;
        }

        if (this.filtrosSelecionados.telefones.celular) {
          filtro = filtro + "&" + this.prefixo + "telefonesCelular=" + this.filtrosSelecionados.telefones.celular;
        }

        if (this.filtrosSelecionados.telefones.whatsapp) {
          filtro = filtro + "&" + this.prefixo + "telefonesWhatsapp=" + this.filtrosSelecionados.telefones.whatsapp;
        }

        if (this.filtrosSelecionados.telefones.ddd) {
          filtro = filtro + "&" + this.prefixo + "telefonesDDD=" + this.filtrosSelecionados.telefones.ddd;
        }

        if (this.filtrosSelecionados.telefones.numero) {
          filtro = filtro + "&" + this.prefixo + "telefonesNumero=" + this.filtrosSelecionados.telefones.numero;
        }

        if (this.filtrosSelecionados.telefones.naoIncomodar != null) {
          filtro = filtro + "&" + this.prefixo + "telefonesNaoIncomodar=" + this.filtrosSelecionados.telefones.naoIncomodar;
        }

        if (this.filtrosSelecionados.telefones.situacao != null) {
          filtro = filtro + "&" + this.prefixo + "telefonesSituacao=" + this.filtrosSelecionados.telefones.situacao;
        }

        if (this.filtrosSelecionados.telefones.dtCriacaoInicio) {
          filtro = filtro + "&" + this.prefixo + "telefonesDtCriacaoInicio=" + this.filtrosSelecionados.telefones.dtCriacaoInicio;
        }
        if (this.filtrosSelecionados.telefones.dtCriacaoFim) {
          filtro = filtro + "&" + this.prefixo + "telefonesDtCriacaoFim=" + this.filtrosSelecionados.telefones.dtCriacaoFim;
        }
      }

      if (this.filtrosSelecionados.emails) {
        if (this.filtrosSelecionados.emails.email) {
          filtro = filtro + "&" + this.prefixo + "emailsEmail=" + this.filtrosSelecionados.emails.email;
        }

        if (this.filtrosSelecionados.emails.fatura != null) {
          filtro = filtro + "&" + this.prefixo + "emailsFatura=" + this.filtrosSelecionados.emails.fatura;
        }

        if (this.filtrosSelecionados.emails.situacao != null) {
          filtro = filtro + "&" + this.prefixo + "emailsSituacao=" + this.filtrosSelecionados.emails.situacao;
        }

        if (this.filtrosSelecionados.emails.dtCriacaoInicio) {
          filtro = filtro + "&" + this.prefixo + "emailsDtCriacaoInicio=" + this.filtrosSelecionados.emails.dtCriacaoInicio;
        }
        if (this.filtrosSelecionados.emails.dtCriacaoFim) {
          filtro = filtro + "&" + this.prefixo + "emailsDtCriacaoFim=" + this.filtrosSelecionados.emails.dtCriacaoFim;
        }
      }

      if (this.filtrosSelecionados.cartoes) {
        if (this.filtrosSelecionados.cartoes.identificacao) {
          filtro = filtro + "&" + this.prefixo + "cartoesIdentificacao=" + this.filtrosSelecionados.cartoes.identificacao;
        }

        if (this.filtrosSelecionados.cartoes.nome) {
          filtro = filtro + "&" + this.prefixo + "cartoesNome=" + this.filtrosSelecionados.cartoes.nome;
        }

        if (this.filtrosSelecionados.cartoes.email) {
          filtro = filtro + "&" + this.prefixo + "cartoesEmail=" + this.filtrosSelecionados.cartoes.email;
        }

        if (this.filtrosSelecionados.cartoes.numero) {
          filtro = filtro + "&" + this.prefixo + "cartoesNumero=" + this.filtrosSelecionados.cartoes.numero;
        }

        if (this.filtrosSelecionados.cartoes.cvv) {
          filtro = filtro + "&" + this.prefixo + "cartoesCVV=" + this.filtrosSelecionados.cartoes.cvv;
        }

        if (this.filtrosSelecionados.cartoes.validade) {
          filtro = filtro + "&" + this.prefixo + "cartoesValidade=" + this.filtrosSelecionados.cartoes.validade;
        }

        if (this.filtrosSelecionados.cartoes.situacao != null) {
          filtro = filtro + "&" + this.prefixo + "cartoesSituacao=" + this.filtrosSelecionados.cartoes.situacao;
        }

        if (this.filtrosSelecionados.cartoes.dtCriacaoInicio) {
          filtro = filtro + "&" + this.prefixo + "cartoesDtCriacaoInicio=" + this.filtrosSelecionados.cartoes.dtCriacaoInicio;
        }
        if (this.filtrosSelecionados.cartoes.dtCriacaoFim) {
          filtro = filtro + "&" + this.prefixo + "cartoesDtCriacaoFim=" + this.filtrosSelecionados.cartoes.dtCriacaoFim;
        }
      }

      if (this.filtrosSelecionados.contas) {
        if (this.filtrosSelecionados.contas.identificacao) {
          filtro = filtro + "&" + this.prefixo + "contasIdentificacao=" + this.filtrosSelecionados.contas.identificacao;
        }

        if (this.filtrosSelecionados.contas.idBanco && this.filtrosSelecionados.contas.idBanco.length) {
          filtro = filtro + "&" + this.prefixo + "contasIdBanco=" + this.filtrosSelecionados.contas.idBanco.join(",");
        }

        if (this.filtrosSelecionados.contas.agencia) {
          filtro = filtro + "&" + this.prefixo + "contasAgencia=" + this.filtrosSelecionados.contas.agencia;
        }

        if (this.filtrosSelecionados.contas.numero) {
          filtro = filtro + "&" + this.prefixo + "contasNumero=" + this.filtrosSelecionados.contas.numero;
        }

        if (this.filtrosSelecionados.contas.codFinanceiro) {
          filtro = filtro + "&" + this.prefixo + "contasCodFinanceiro=" + this.filtrosSelecionados.contas.codFinanceiro;
        }

        if (this.filtrosSelecionados.contas.situacao != null) {
          filtro = filtro + "&" + this.prefixo + "contasSituacao=" + this.filtrosSelecionados.contas.situacao;
        }

        if (this.filtrosSelecionados.contas.dtCriacaoInicio) {
          filtro = filtro + "&" + this.prefixo + "contasDtCriacaoInicio=" + this.filtrosSelecionados.contas.dtCriacaoInicio;
        }
        if (this.filtrosSelecionados.contas.dtCriacaoFim) {
          filtro = filtro + "&" + this.prefixo + "contasDtCriacaoFim=" + this.filtrosSelecionados.contas.dtCriacaoFim;
        }
      }

      if (this.filtrosSelecionados.celescs) {
        if (this.filtrosSelecionados.celescs.identificacao) {
          filtro = filtro + "&" + this.prefixo + "celescsIdentificacao=" + this.filtrosSelecionados.celescs.identificacao;
        }

        if (this.filtrosSelecionados.celescs.unidadeConsumidora) {
          filtro = filtro + "&" + this.prefixo + "celescsUnidadeConsumidora=" + this.filtrosSelecionados.celescs.unidadeConsumidora;
        }

        if (this.filtrosSelecionados.celescs.situacao != null) {
          filtro = filtro + "&" + this.prefixo + "celescsSituacao=" + this.filtrosSelecionados.celescs.situacao;
        }

        if (this.filtrosSelecionados.celescs.dtCriacaoInicio) {
          filtro = filtro + "&" + this.prefixo + "celescsDtCriacaoInicio=" + this.filtrosSelecionados.celescs.dtCriacaoInicio;
        }
        if (this.filtrosSelecionados.celescs.dtCriacaoFim) {
          filtro = filtro + "&" + this.prefixo + "celescsDtCriacaoFim=" + this.filtrosSelecionados.celescs.dtCriacaoFim;
        }
      }

      if (this.filtrosSelecionados.dependentes) {
        if (this.filtrosSelecionados.dependentes.identificacao) {
          filtro = filtro + "&" + this.prefixo + "dependentesIdentificacao=" + this.filtrosSelecionados.dependentes.identificacao;
        }

        if (this.filtrosSelecionados.dependentes.nome) {
          filtro = filtro + "&" + this.prefixo + "dependentesNome=" + this.filtrosSelecionados.dependentes.nome;
        }

        if (this.filtrosSelecionados.dependentes.dtNascimento) {
          filtro = filtro + "&" + this.prefixo + "dependentesDtNascimento=" + this.filtrosSelecionados.dependentes.dtNascimento;
        }

        if (this.filtrosSelecionados.dependentes.clubeCortesia != null) {
          filtro = filtro + "&" + this.prefixo + "dependentesClubeCortesia=" + this.filtrosSelecionados.dependentes.clubeCortesia;
        }

        if (this.filtrosSelecionados.dependentes.associacao) {
          filtro = filtro + "&" + this.prefixo + "dependentesAssociacao=" + this.filtrosSelecionados.dependentes.associacao;
        }

        if (this.filtrosSelecionados.dependentes.email) {
          filtro = filtro + "&" + this.prefixo + "dependentesEmail=" + this.filtrosSelecionados.dependentes.email;
        }

        if (this.filtrosSelecionados.dependentes.foneDDD) {
          filtro = filtro + "&" + this.prefixo + "dependentesFoneDDD=" + this.filtrosSelecionados.dependentes.foneDDD;
        }

        if (this.filtrosSelecionados.dependentes.foneNumero) {
          filtro = filtro + "&" + this.prefixo + "dependentesFoneNumero=" + this.filtrosSelecionados.dependentes.foneNumero;
        }

        if (this.filtrosSelecionados.dependentes.situacao != null) {
          filtro = filtro + "&" + this.prefixo + "dependentesSituacao=" + this.filtrosSelecionados.dependentes.situacao;
        }
        if (this.filtrosSelecionados.dependentes.codAntigoCirculacao) {
          filtro = filtro + "&" + this.prefixo + "dependentesCodAntigoCirculacao=" + this.filtrosSelecionados.dependentes.codAntigoCirculacao;
        }

        if (this.filtrosSelecionados.dependentes.dtCriacaoInicio) {
          filtro = filtro + "&" + this.prefixo + "dependentesDtCriacaoInicio=" + this.filtrosSelecionados.dependentes.dtCriacaoInicio;
        }
        if (this.filtrosSelecionados.dependentes.dtCriacaoFim) {
          filtro = filtro + "&" + this.prefixo + "dependentesDtCriacaoFim=" + this.filtrosSelecionados.dependentes.dtCriacaoFim;
        }
      }

      if (this.filtrosSelecionados.departamentos) {
        if (this.filtrosSelecionados.departamentos.departamento) {
          filtro = filtro + "&" + this.prefixo + "departamentosDepartamento=" + this.filtrosSelecionados.departamentos.departamento;
        }

        if (this.filtrosSelecionados.departamentos.situacao != null) {
          filtro = filtro + "&" + this.prefixo + "departamentosSituacao=" + this.filtrosSelecionados.departamentos.situacao;
        }

        if (this.filtrosSelecionados.departamentos.dtCriacaoInicio) {
          filtro = filtro + "&" + this.prefixo + "departamentosDtCriacaoInicio=" + this.filtrosSelecionados.departamentos.dtCriacaoInicio;
        }
        if (this.filtrosSelecionados.departamentos.dtCriacaoFim) {
          filtro = filtro + "&" + this.prefixo + "departamentosDtCriacaoFim=" + this.filtrosSelecionados.departamentos.dtCriacaoFim;
        }
      }

      if (this.filtrosSelecionados.observacoes) {
        if (this.filtrosSelecionados.observacoes.observacao) {
          filtro = filtro + "&" + this.prefixo + "observacoesObservacao=" + this.filtrosSelecionados.observacoes.observacao;
        }

        if (this.filtrosSelecionados.observacoes.situacao != null) {
          filtro = filtro + "&" + this.prefixo + "observacoesSituacao=" + this.filtrosSelecionados.observacoes.situacao;
        }

        if (this.filtrosSelecionados.observacoes.dtCriacaoInicio) {
          filtro = filtro + "&" + this.prefixo + "observacoesDtCriacaoInicio=" + this.filtrosSelecionados.observacoes.dtCriacaoInicio;
        }
        if (this.filtrosSelecionados.observacoes.dtCriacaoFim) {
          filtro = filtro + "&" + this.prefixo + "observacoesDtCriacaoFim=" + this.filtrosSelecionados.observacoes.dtCriacaoFim;
        }
      }

      if (this.filtrosSelecionados.contatos) {
        if (this.filtrosSelecionados.contatos.status) {
          filtro = filtro + "&" + this.prefixo + "contatosStatus=" + this.filtrosSelecionados.contatos.status;
        }

        if (this.filtrosSelecionados.contatos.dtRegistroInicio) {
          filtro = filtro + "&" + this.prefixo + "contatosDtRegistroInicio=" + this.filtrosSelecionados.contatos.dtRegistroInicio;
        }

        if (this.filtrosSelecionados.contatos.dtRegistroFim) {
          filtro = filtro + "&" + this.prefixo + "contatosDtRegistroFim=" + this.filtrosSelecionados.contatos.dtRegistroFim;
        }

        if (this.filtrosSelecionados.contatos.idUsuario && this.filtrosSelecionados.contatos.idUsuario.length) {
          filtro = filtro + "&" + this.prefixo + "contatosIdUsuario=" + this.filtrosSelecionados.contatos.idUsuario.join(",");
        }

        if (this.filtrosSelecionados.contatos.idAssunto && this.filtrosSelecionados.contatos.idAssunto.length) {
          filtro = filtro + "&" + this.prefixo + "contatosIdAssunto=" + this.filtrosSelecionados.contatos.idAssunto.join(",");
        }
        if (this.filtrosSelecionados.contatos.idMotivo && this.filtrosSelecionados.contatos.idMotivo.length) {
          filtro = filtro + "&" + this.prefixo + "contatosIdMotivo=" + this.filtrosSelecionados.contatos.idMotivo.join(",");
        }
        if (this.filtrosSelecionados.contatos.idProcedimento && this.filtrosSelecionados.contatos.idProcedimento.length) {
          filtro = filtro + "&" + this.prefixo + "contatosIdProcedimento=" + this.filtrosSelecionados.contatos.idProcedimento.join(",");
        }

        if (this.filtrosSelecionados.contatos.contato) {
          filtro = filtro + "&" + this.prefixo + "contatosContato=" + this.filtrosSelecionados.contatos.contato;
        }

        if (this.filtrosSelecionados.contatos.idUsuarioExecucao && this.filtrosSelecionados.contatos.idUsuarioExecucao.length) {
          filtro = filtro + "&" + this.prefixo + "contatosIdUsuarioExecucao=" + this.filtrosSelecionados.contatos.idUsuarioExecucao.join(",");
        }

        if (this.filtrosSelecionados.contatos.dtExecucaoInicio) {
          filtro = filtro + "&" + this.prefixo + "contatosDtExecucaoInicio=" + this.filtrosSelecionados.contatos.dtExecucaoInicio;
        }

        if (this.filtrosSelecionados.contatos.dtExecucaoFim) {
          filtro = filtro + "&" + this.prefixo + "contatosDtExecucaoFim=" + this.filtrosSelecionados.contatos.dtExecucaoFim;
        }

        if (this.filtrosSelecionados.contatos.execucao) {
          filtro = filtro + "&" + this.prefixo + "contatosExecucao=" + this.filtrosSelecionados.contatos.execucao;
        }

        if (this.filtrosSelecionados.contatos.idUsuarioSolucao && this.filtrosSelecionados.contatos.idUsuarioSolucao.length) {
          filtro = filtro + "&" + this.prefixo + "contatosIdUsuarioSolucao=" + this.filtrosSelecionados.contatos.idUsuarioSolucao.join(",");
        }

        if (this.filtrosSelecionados.contatos.dtSolucaoInicio) {
          filtro = filtro + "&" + this.prefixo + "contatosDtSolucaoInicio=" + this.filtrosSelecionados.contatos.dtSolucaoInicio;
        }

        if (this.filtrosSelecionados.contatos.dtSolucaoFim) {
          filtro = filtro + "&" + this.prefixo + "contatosDtSolucaoFim=" + this.filtrosSelecionados.contatos.dtSolucaoFim;
        }

        if (this.filtrosSelecionados.contatos.solucao) {
          filtro = filtro + "&" + this.prefixo + "contatosSolucao=" + this.filtrosSelecionados.contatos.solucao;
        }
      }

      if (this.filtrosSelecionados.promocoes) {
        if (this.filtrosSelecionados.promocoes.idPromocao && this.filtrosSelecionados.promocoes.idPromocao.length) {
          filtro = filtro + "&" + this.prefixo + "promocoesIdPromocao=" + this.filtrosSelecionados.promocoes.idPromocao.join(",");
        }
      }

      if (this.filtrosSelecionados.cortesias) {
        if (this.filtrosSelecionados.cortesias.idCliente && this.filtrosSelecionados.cortesias.idCliente.length) {
          filtro = filtro + "&" + this.prefixo + "cortesiasIdCliente=" + this.filtrosSelecionados.cortesias.idCliente.join(",");
        }

        if (this.filtrosSelecionados.cortesias.dtCriacaoInicio) {
          filtro = filtro + "&" + this.prefixo + "cortesiasDtCriacaoInicio=" + this.filtrosSelecionados.cortesias.dtCriacaoInicio;
        }
        if (this.filtrosSelecionados.cortesias.dtCriacaoFim) {
          filtro = filtro + "&" + this.prefixo + "cortesiasDtCriacaoFim=" + this.filtrosSelecionados.cortesias.dtCriacaoFim;
        }
      }

      if (this.filtrosSelecionados.banca) {
        if (this.filtrosSelecionados.banca.idTipoBanca && this.filtrosSelecionados.banca.idTipoBanca.length) {
          filtro = filtro + "&" + this.prefixo + "bancaIdTipoBanca=" + this.filtrosSelecionados.banca.idTipoBanca.join(",");
        }
        if (this.filtrosSelecionados.banca.idFormaPgto && this.filtrosSelecionados.banca.idFormaPgto.length) {
          filtro = filtro + "&" + this.prefixo + "bancaIdFormaPgto=" + this.filtrosSelecionados.banca.idFormaPgto.join(",");
        }
        if (this.filtrosSelecionados.banca.situacao != null) {
          filtro = filtro + "&" + this.prefixo + "bancaSituacao=" + this.filtrosSelecionados.banca.situacao;
        }
        if (this.filtrosSelecionados.banca.codAntigoBanca) {
          filtro = filtro + "&" + this.prefixo + "bancaCodAntigoBanca=" + this.filtrosSelecionados.banca.codAntigoBanca;
        }
        if (this.filtrosSelecionados.banca.distribuidora != null) {
          filtro = filtro + "&" + this.prefixo + "bancaDistribuidora=" + this.filtrosSelecionados.banca.distribuidora;
        }
      }

      if (this.filtrosSelecionados.agente) {
        if (this.filtrosSelecionados.agente.situacao != null) {
          filtro = filtro + "&" + this.prefixo + "agenteSituacao=" + this.filtrosSelecionados.agente.situacao;
        }
        if (this.filtrosSelecionados.agente.origem) {
          filtro = filtro + "&" + this.prefixo + "agenteOrigem=" + this.filtrosSelecionados.agente.origem;
        }
      }

      return filtro;
    },
    //* Chamadas a métodos públicos
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    filtrarItens(item, queryText, itemText) {
      return util.filtrarItens(item, queryText, itemText);
    },
    filtrarUFs(item, queryText, itemText) {
      return util.filtrarUFs(item, queryText, itemText);
    },
    pLetra(text) {
      return util.pLetra(text);
    },
    formatDateUS(date) {
      if (date && date.length == 10) {
        return util.formatDateUS(date);
      }
    },
    valida_data(date) {
      if (date) {
        return util.valida_data_BR(date);
      } else {
        return true;
      }
    }
  },

  watch: {
    filtroSelDtCriacaoUS() {
      this.filtrosSelecionados.dtCriacao = util.formatDate(this.filtroSelDtCriacaoUS);
      this.mnfiltroSelDtCriacaoUS = false;
    },
    filtroSelDtNascimentoUS() {
      this.filtrosSelecionados.dtNascimento = util.formatDate(this.filtroSelDtNascimentoUS);
      this.mnfiltroSelDtNascimentoUS = false;
    },
    filtroSelDtNascDependenteUS() {
      this.filtrosSelecionados.dependentes.dtNascimento = util.formatDate(this.filtroSelDtNascDependenteUS);
      this.mnfiltroSelDtNascDependenteUS = false;
    },

    filtroSelEnderecosInicioUS() {
      this.filtrosSelecionados.enderecos.dtCriacaoInicio = util.formatDate(this.filtroSelEnderecosInicioUS);
      this.mnfiltroSelEnderecosInicioUS = false;
    },
    filtroSelEnderecosFimUS() {
      this.filtrosSelecionados.enderecos.dtCriacaoFim = util.formatDate(this.filtroSelEnderecosFimUS);
      this.mnfiltroSelEnderecosFimUS = false;
    },

    filtroSelTelefonesInicioUS() {
      this.filtrosSelecionados.telefones.dtCriacaoInicio = util.formatDate(this.filtroSelTelefonesInicioUS);
      this.mnfiltroSelTelefonesInicioUS = false;
    },
    filtroSelTelefonesFimUS() {
      this.filtrosSelecionados.telefones.dtCriacaoFim = util.formatDate(this.filtroSelTelefonesFimUS);
      this.mnfiltroSelTelefonesFimUS = false;
    },

    filtroSelEmailsInicioUS() {
      this.filtrosSelecionados.emails.dtCriacaoInicio = util.formatDate(this.filtroSelEmailsInicioUS);
      this.mnfiltroSelEmailsInicioUS = false;
    },
    filtroSelEmailsFimUS() {
      this.filtrosSelecionados.emails.dtCriacaoFim = util.formatDate(this.filtroSelEmailsFimUS);
      this.mnfiltroSelEmailsFimUS = false;
    },

    filtroSelCartoesInicioUS() {
      this.filtrosSelecionados.cartoes.dtCriacaoInicio = util.formatDate(this.filtroSelCartoesInicioUS);
      this.mnfiltroSelCartoesInicioUS = false;
    },
    filtroSelCartoesFimUS() {
      this.filtrosSelecionados.cartoes.dtCriacaoFim = util.formatDate(this.filtroSelCartoesFimUS);
      this.mnfiltroSelCartoesFimUS = false;
    },

    filtroSelContasInicioUS() {
      this.filtrosSelecionados.contas.dtCriacaoInicio = util.formatDate(this.filtroSelContasInicioUS);
      this.mnfiltroSelContasInicioUS = false;
    },
    filtroSelContasFimUS() {
      this.filtrosSelecionados.contas.dtCriacaoFim = util.formatDate(this.filtroSelContasFimUS);
      this.mnfiltroSelContasFimUS = false;
    },

    filtroSelCelescsInicioUS() {
      this.filtrosSelecionados.celescs.dtCriacaoInicio = util.formatDate(this.filtroSelCelescsInicioUS);
      this.mnfiltroSelCelescsInicioUS = false;
    },
    filtroSelCelescsFimUS() {
      this.filtrosSelecionados.celescs.dtCriacaoFim = util.formatDate(this.filtroSelCelescsFimUS);
      this.mnfiltroSelCelescsFimUS = false;
    },

    filtroSelDependentesInicioUS() {
      this.filtrosSelecionados.dependentes.dtCriacaoInicio = util.formatDate(this.filtroSelDependentesInicioUS);
      this.mnfiltroSelDependentesInicioUS = false;
    },
    filtroSelDependentesFimUS() {
      this.filtrosSelecionados.dependentes.dtCriacaoFim = util.formatDate(this.filtroSelDependentesFimUS);
      this.mnfiltroSelDependentesFimUS = false;
    },

    filtroSelDepartamentosInicioUS() {
      this.filtrosSelecionados.departamentos.dtCriacaoInicio = util.formatDate(this.filtroSelDepartamentosInicioUS);
      this.mnfiltroSelDepartamentosInicioUS = false;
    },
    filtroSelDepartamentosFimUS() {
      this.filtrosSelecionados.departamentos.dtCriacaoFim = util.formatDate(this.filtroSelDepartamentosFimUS);
      this.mnfiltroSelDepartamentosFimUS = false;
    },

    filtroSelObservacoesInicioUS() {
      this.filtrosSelecionados.observacoes.dtCriacaoInicio = util.formatDate(this.filtroSelObservacoesInicioUS);
      this.mnfiltroSelObservacoesInicioUS = false;
    },
    filtroSelObservacoesFimUS() {
      this.filtrosSelecionados.observacoes.dtCriacaoFim = util.formatDate(this.filtroSelObservacoesFimUS);
      this.mnfiltroSelObservacoesFimUS = false;
    },

    filtroSelContatosInicioUS() {
      this.filtrosSelecionados.contatos.dtRegistroInicio = util.formatDate(this.filtroSelContatosInicioUS);
      this.mnfiltroSelContatosInicioUS = false;
    },
    filtroSelContatosFimUS() {
      this.filtrosSelecionados.contatos.dtRegistroFim = util.formatDate(this.filtroSelContatosFimUS);
      this.mnfiltroSelContatosFimUS = false;
    },

    filtroSelContatosDtExecucaoInicioUS() {
      this.filtrosSelecionados.contatos.dtExecucaoInicio = util.formatDate(this.filtroSelContatosDtExecucaoInicioUS);
      this.mnfiltroSelContatosDtExecucaoInicioUS = false;
    },
    filtroSelContatosDtExecucaoFimUS() {
      this.filtrosSelecionados.contatos.dtExecucaoFim = util.formatDate(this.filtroSelContatosDtExecucaoFimUS);
      this.mnfiltroSelContatosDtExecucaoFimUS = false;
    },

    filtroSelContatosDtSolucaoInicioUS() {
      this.filtrosSelecionados.contatos.dtSolucaoInicio = util.formatDate(this.filtroSelContatosDtSolucaoInicioUS);
      this.mnfiltroSelContatosDtSolucaoInicioUS = false;
    },
    filtroSelContatosDtSolucaoFimUS() {
      this.filtrosSelecionados.contatos.dtSolucaoFim = util.formatDate(this.filtroSelContatosDtSolucaoFimUS);
      this.mnfiltroSelContatosDtSolucaoFimUS = false;
    },

    filtroSelCortesiasInicioUS() {
      this.filtrosSelecionados.cortesias.dtCriacaoInicio = util.formatDate(this.filtroSelCortesiasInicioUS);
      this.mnfiltroSelCortesiasInicioUS = false;
    },
    filtroSelCortesiasFimUS() {
      this.filtrosSelecionados.cortesias.dtCriacaoFim = util.formatDate(this.filtroSelCortesiasFimUS);
      this.mnfiltroSelCortesiasFimUS = false;
    }
  },

  async created() {
    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_ASSINATURAS_EDITAR") {
        this.direitoAssinaturas = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ADMINISTRATIVO_RAMOS_ATIVIDADE_CRIAR") {
        this.direitoRamos = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ADMINISTRATIVO_CEPS_CRIAR") {
        this.direitoCEPs = true;
      }
    });

    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
    //* Carrega os itens dos selects
    this.carregarRamos();
    this.carregarPromocoes();
    this.carregarCortesias();
    this.carregarTiposBanca();
    this.carregarFormasPgto();
    this.carregarMotivosContato();
    this.carregarUsuarios();

    //* Carrega os estados
    api.get(process.env.VUE_APP_URL_WEB_API + "adm/uf/lista").then(response => {
      this.ufs = response.data;
      if (this.$store.state.usuario.site_pais != "BR") {
        this.ufs.forEach(element => {
          element.nome += "/" + element.pais;
        });
      }
    });

    //* Carrega os bancos de débito em conta
    api.get(process.env.VUE_APP_URL_WEB_API + "fin/cedente/debito?idSite=" + this.idSitePrincipal).then(response => {
      this.bancosDebitoConta = response.data;
      this.bancosDebitoConta.forEach(element => {
        element.id = element.idBanco;
        element.nome = element.bancoNumero + " - " + element.bancoNome;
      });
    });

    //* Carrega os modos de entrega
    api.get(process.env.VUE_APP_URL_WEB_API + "ass/modo-entrega/lista").then(response => {
      this.modosEntrega = response.data;
    });

    //* Verifica se o site do usuário está em GRUPO - OBS: ou ele compartilha a informação ou deixa exclusiva para o seu site
    var idGrupoSite = this.$store.state.usuario.site_idGrupo;
    if (idGrupoSite != null) {
      this.siteGrupo = [
        {
          id: this.idSitePrincipal,
          nome: this.$store.state.usuario.site_nome
        }
      ];
    }
  }
};
</script>
