<template>
  <div id="app">
    <v-app id="inspire">
      <v-card>
        <v-toolbar color="#333333">
          <v-icon color="white" class="mr-2">multiline_chart</v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light"
            >Fechamento SPED (EFD-ICMS/IPI)</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            fab
            small
            @click="
              dialogFechamento = true;
              carregarCompetencias();
            "
          >
            <v-icon color="white" title="Fechamento SPED">add</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="px-6 mt-4">
          <v-layout wrap>
            <v-spacer></v-spacer>
            <v-icon title="Atualizar" @click="pesquisaDados">search</v-icon>
          </v-layout>
          <v-dialog persistente v-model="dialogFechamento" max-width="600px">
            <v-card>
              <v-toolbar>
                <span class="headline">Fechamento SPED (EFD-ICMS/IPI)</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="dialogFechamento = false">
                  <v-icon color="darken-1">cancel</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <v-container fluid grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12>
                      <v-autocomplete
                        v-model="competencia"
                        :items="competencias"
                        :filter="filtrarObjetos"
                        label="Selecione a Competência"
                        item-text="nome"
                        item-value="anoMes"
                        no-data-text
                        autocomplete="disabled"
                        :name="Math.random()"
                        return-object
                        @change="mensagem = ''"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 v-if="mensagem">
                      <v-sheet :value="true" class="red--text">{{
                        mensagem
                      }}</v-sheet>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="red darken-1"
                  text
                  @click="
                    dialogFechamento = false;
                    fechando = false;
                  "
                  >Retornar</v-btn
                >
                <v-btn
                  color="green darken-1"
                  text
                  @click="gerarFechamento"
                  :disabled="fechando"
                  >Gerar Fechamento</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-data-table
            mobile-breakpoint="0"
            :headers="headers"
            :items="registros"
            :options.sync="options"
            :no-data-text="mensagemPesquisa"
            :footer-props="rodape"
            :disable-sort="carregando"
            :disable-pagination="carregando"
          >
            <template v-slot:[getItemActions()]="{ item }">
              <v-icon
                v-if="item.sped"
                color="blue"
                title="Download Arquivos Fechamento"
                @click="downloadArquivo(item.anoMes)"
                >cloud_download</v-icon
              >
            </template>

            <template v-slot:[getItemAnoMes()]="{ item }">{{
              item.anoMes.substring(2) + "/20" + item.anoMes.substring(0, 2)
            }}</template>

            <template v-slot:[getItemDtEmissao()]="{ item }">{{
              formatDateTime(item.dtEmissao)
            }}</template>
            <template v-slot:[getItemProcessado()]="{ item }">
              <span
                :class="
                  item.processado == 1
                    ? 'blue--text'
                    : item.processado == 2
                    ? 'green--text'
                    : ''
                "
                >{{
                  item.processado == 1
                    ? "Processando"
                    : item.processado == 2
                    ? "Processado"
                    : "Solicitado"
                }}</span
              >
            </template>
          </v-data-table>
          <div class="text-xs-center pt-2 marg-bt">
            <v-pagination
              v-model="paginaAtual"
              :length="totalPaginas"
              :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 7"
              circle
            ></v-pagination>
            <span>Faça o download do validador atualizado anualmente em: </span>
            <a
              href="https://www.gov.br/receitafederal/pt-br/assuntos/orientacao-tributaria/declaracoes-e-demonstrativos/sped-sistema-publico-de-escrituracao-digital/escrituracao-fiscal-digital-efd/escrituracao-fiscal-digital-efd"
              target="_blank"
              >Portal Sefaz</a
            >
          </div>
        </div>
        <v-snackbar
          v-model="snackbar"
          color="info"
          :timeout="5000"
          relative
          bottom
          left
          >{{ mensagem }}</v-snackbar
        >
        <v-snackbar
          v-model="snackErro"
          color="error"
          :timeout="5000"
          relative
          bottom
          left
          >{{ mensagem }}</v-snackbar
        >
      </v-card>
    </v-app>
  </div>
</template>

<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
import download from "downloadjs";
export default {
  data() {
    return {
      headers: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Competência", value: "anoMes" },
        { text: "Data", value: "dtEmissao" },
        { text: "Status", value: "processado" },
        { text: "Arquivo SPED", value: "sped" },
        { text: "Usuário", value: "usuario" }
      ],
      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },

      competencias: [],
      competencia: null,
      dialogFechamento: false,
      fechando: false,

      mensagem: "",
      snackbar: false,
      snackErro: false,

      idSitePrincipal: null,
      mensagemPesquisa: "",
      filtroPesquisa: "",
      registros: [],
      registrosPagina: 10,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      carregando: true,
      ordenacao: "",
      ordem: "",
      options: {}
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    getItemActions() {
      return "item.actions";
    },
    getItemAnoMes() {
      return "item.anoMes";
    },
    getItemDtEmissao() {
      return "item.dtEmissao";
    },
    getItemProcessado() {
      return "item.processado";
    },
    async gerarFechamento() {
      this.mensagem = "";
      if (this.competencia) {
        this.fechando = true;
        try {
          //* chama api para gerar os arquivos do convênio

          await api.post(
            process.env.VUE_APP_URL_WEB_API +
              "fin/nota-fiscal/fechamentoSPED?idSite=" +
              this.idSitePrincipal +
              "&competencia=" +
              this.competencia.anoMes +
              "&idUsuario=" +
              this.$store.state.usuario.id
          );

          this.pesquisaDados();

          this.mensagem = "Arquivos solicitados";
          this.snackbar = true;
          this.dialogFechamento = false;
        } catch (e) {
          this.mensagem =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
          this.snackErro = true;
        }
      } else {
        this.mensagem = "Selecione a competência";
      }
    },

    limpaFiltro() {
      this.paginaAtual = 1;
      this.pesquisaDados();
    },
    async pesquisaDados() {
      this.registros = [];
      //sinaliza que está carregando
      this.carregando = true;
      this.mensagemPesquisa = "Carregando...";

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "fin/fechamento-sped?idSite=" +
            this.$store.state.usuario.idSitePrincipal +
            "&pagina=" +
            this.paginaAtual +
            "&tamanho=" +
            this.registrosPagina +
            // "&filtro=" +
            // this.filtroPesquisa +
            "&ordenacao=" +
            this.ordenacao +
            "&ordem=" +
            this.ordem
        );
        //seta as variaveis com o retorno
        this.registros = response.data.data;
        this.totalRegistros = response.data.total;
        this.totalPaginas = Math.ceil(
          this.totalRegistros / this.registrosPagina
        );
        this.rodape.pageText = this.totalRegistros + " itens";
        //fecha o loading
        this.carregando = false;
        //se não achar registro seta a mensagem senão deixa em branco
        this.mensagemPesquisa =
          this.registros.length > 1 ? "" : "Nenhum registro encontrado";
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else {
          this.mensagemPesquisa = "Erro ao buscar dados: " + e;
        }
      }
    },
    async carregarCompetencias() {
      if (this.competencias.length == 0) {
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "fin/nota-fiscal/competencias?idSite=" +
            this.idSitePrincipal
        );
        if (response.data) {
          this.competencias = response.data;
        }
      }
    },
    downloadArquivo(anoMes) {
      const ano_mes = "20" + anoMes.substring(0, 2) + "_" + anoMes.substring(2);

      var caminhoArquivo = `arquivos/${this.idSitePrincipal}/notas-fiscais/sped/SPED_${ano_mes}.txt`;

      download(process.env.VUE_APP_URL_WEB_API + caminhoArquivo);
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    formatDateTime(date) {
      return util.formatDateTime(date);
    }
  },
  beforeMount() {
    this.POSSUI_DIREITO([
      "WEBSUITA_ADMIN_SUITA",
      "FINANCEIRO_FECHAMENTO_SPED_ACESSO"
    ]);
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
  },
  watch: {
    paginaAtual() {
      this.pesquisaDados();
    },
    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }
        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }
        this.pesquisaDados();
      }
    }
  }
};
</script>
