<template>
  <div id="app">
    <v-app id="inspire">
      <v-card>
        <v-toolbar color="#333333">
          <v-icon color="white" class="mr-2">dns</v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light">
            Carteira
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container fluid grid-list-md>
          <v-layout wrap>
            <v-flex xs12 md3>
              <v-menu v-model="mnuDias" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px" :disabled="loading">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dias"
                    label="Carteira Diária"
                    prepend-icon="event"
                    v-on="on"
                    color="blue-grey lighten-2"
                    @click:clear="diasUS = ['', '']"
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker v-model="diasUS" locale="pt-br" no-title range
                  ><v-spacer></v-spacer>
                  <v-btn text color="primary" @click="mnuDias = false">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-flex>

            <v-flex xs12 md3>
              <v-menu v-model="mnuMeses" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px" :disabled="loading">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="meses"
                    label="Carteira Mensal"
                    prepend-icon="event"
                    v-on="on"
                    color="blue-grey lighten-2"
                    @click:clear="mesesUS = ['', '']"
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker v-model="mesesUS" locale="pt-br" type="month" no-title range
                  ><v-spacer></v-spacer>
                  <v-btn text color="primary" @click="mnuMeses = false">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-flex>

            <v-flex xs12 md3>
              <v-autocomplete
                v-model="anosUS"
                :items="anos"
                label="Carteira Anual"
                prepend-icon="event"
                autocomplete="disabled"
                :name="Math.random()"
                no-data-text
                multiple
                :menu-props="{ closeOnContentClick: anosUS.length == 2 }"
                :disabled="loading"
              ></v-autocomplete>
            </v-flex>

            <v-flex md1 xs2 class="text-right">
              <v-btn color="blue" :loading="loading" :disabled="loading" fab small @click="carteira('rel')">
                <v-icon color="white" title="Relatório Carteira">dns</v-icon>
              </v-btn>
            </v-flex>
            <v-flex md1 xs2>
              <v-btn color="blue" :loading="loading" :disabled="loading" fab small @click="carteira('mov')">
                <v-icon color="white" title="Carteira Movimento">view_column</v-icon>
              </v-btn>
            </v-flex>
            <v-flex md1 xs2>
              <v-icon color="orange" title="Legenda" @click="dialogLegenda = true">help</v-icon>
            </v-flex>

            <v-flex xs12>
              <v-progress-linear v-model="value" :active="loading" class="ma-0" color="cyan accent-3" height="16" :indeterminate="value > 100"
                >Montando relatório. Aguarde...</v-progress-linear
              >
            </v-flex>
          </v-layout>
        </v-container>
        <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
        <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
        <v-dialog v-model="dialogLegenda" max-width="1200px">
          <v-card>
            <v-toolbar>
              <span class="headline">CARTEIRA MOVIMENTAÇÃO</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="dialogLegenda = false">
                <v-icon color="darken-1">cancel</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <table>
                <tr>
                  <td>VENDAS</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr class="fundo">
                  <td></td>
                  <td>IMPRESSO</td>
                  <td></td>
                  <td>captação de contrato que possui o produto impresso no período</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td>->CORRENTE</td>
                  <td>captação no período, permanecendo corrente</td>
                  <td>+</td>
                </tr>
                <tr class="fundo">
                  <td></td>
                  <td></td>
                  <td>->SUSPENSA</td>
                  <td>captação e suspensão neste mesmo período</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td>->EXPIRADA</td>
                  <td>captação e expiração neste mesmo período</td>
                </tr>
                <tr class="fundo">
                  <td></td>
                  <td></td>
                  <td>->RECUPERAÇÃO</td>
                  <td>captação e agendamento de cancelamento neste mesmo período</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td>->CANCELADA</td>
                  <td>captação e cancelamento neste mesmo período</td>
                </tr>
                <tr class="fundo">
                  <td></td>
                  <td></td>
                  <td>->EXCLUÍDA</td>
                  <td>captação e exclusão neste mesmo período</td>
                </tr>
                <tr>
                  <td></td>
                  <td>DIGITAL</td>
                  <td></td>
                  <td>captação de contrato que não possui o produto impresso no período</td>
                </tr>
                <tr class="fundo">
                  <td></td>
                  <td></td>
                  <td>->CORRENTE</td>
                  <td>captação no período, permanecendo corrente</td>
                  <td>+</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td>->SUSPENSA</td>
                  <td>captação e suspensão neste mesmo período</td>
                </tr>
                <tr class="fundo">
                  <td></td>
                  <td></td>
                  <td>->EXPIRADA</td>
                  <td>captação e expiração neste mesmo período</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td>->RECUPERAÇÃO</td>
                  <td>captação e agendamento de cancelamento(=recuperação) neste mesmo período</td>
                </tr>
                <tr class="fundo">
                  <td></td>
                  <td></td>
                  <td>->CANCELADA</td>
                  <td>captação e cancelamento neste mesmo período</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td>->EXCLUÍDA</td>
                  <td>captação e exclusão neste mesmo período</td>
                </tr>
                <tr class="fundo">
                  <td>IMPRESSO</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td>->DIGITAL</td>
                  <td></td>
                  <td>impresso no período anterior e digital no atual (independente do status)</td>
                </tr>
                <tr class="fundo">
                  <td>DIGITAL</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td>->IMPRESSO</td>
                  <td></td>
                  <td>digital no período anterior e impresso no atual (independente do status)</td>
                </tr>
                <tr class="fundo">
                  <td>CORRENTE</td>
                  <td></td>
                  <td></td>
                  <td>status corrente na data</td>
                  <td>=</td>
                </tr>
                <tr>
                  <td></td>
                  <td>->CORRENTE</td>
                  <td></td>
                  <td>diferente de corrente no período anterior e corrente no período atual</td>
                  <td>+</td>
                </tr>
                <tr class="fundo">
                  <td></td>
                  <td>->EXPIRADA</td>
                  <td></td>
                  <td>corrente no período anterior e expirada no período atual</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td></td>
                  <td>->SUSPENSA</td>
                  <td></td>
                  <td>corrente no período anterior e suspensa no período atual</td>
                  <td>-</td>
                </tr>
                <tr class="fundo">
                  <td></td>
                  <td>->RECUPERAÇÃO</td>
                  <td></td>
                  <td>corrente no período anterior e agendada para cancelamento(=recuperação) no período atual</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td></td>
                  <td>->CANCELADA</td>
                  <td></td>
                  <td>corrente no período anterior e cancelada no período atual</td>
                  <td>-</td>
                </tr>
                <tr class="fundo">
                  <td></td>
                  <td>->EXCLUÍDA</td>
                  <td></td>
                  <td>corrente no período anterior e excluída no período atual</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>EXPIRADA</td>
                  <td></td>
                  <td></td>
                  <td>tudo o que estava expirado no período anterior (coluna inicial do relatório)</td>
                  <td>-</td>
                </tr>
                <tr class="fundo">
                  <td></td>
                  <td>->EXPIRADA</td>
                  <td></td>
                  <td>expirada no período atual</td>
                </tr>
                <tr>
                  <td></td>
                  <td>->CORRENTE</td>
                  <td></td>
                  <td>expirada no período anterior e corrente no atual</td>
                </tr>
                <tr class="fundo">
                  <td></td>
                  <td>->OUTROS</td>
                  <td></td>
                  <td>expirada no período anterior e diferente de expirada e corrente no atual</td>
                </tr>
                <tr>
                  <td>SUSPENSA</td>
                  <td></td>
                  <td></td>
                  <td>tudo o que estava suspenso no período anterior (coluna inicial do relatório)</td>
                </tr>
                <tr class="fundo">
                  <td></td>
                  <td>->SUSPENSA</td>
                  <td></td>
                  <td>suspensa no período atual</td>
                </tr>
                <tr>
                  <td></td>
                  <td>->CORRENTE</td>
                  <td></td>
                  <td>suspensa no período anterior e corrente no atual</td>
                </tr>
                <tr class="fundo">
                  <td></td>
                  <td>->OUTROS</td>
                  <td></td>
                  <td>suspensa no período anterior e diferente de suspensa e corrente no atual</td>
                </tr>
                <tr>
                  <td>RECUPERAÇÃO</td>
                  <td></td>
                  <td></td>
                  <td>tudo o que estava em recuperação no período anterior (coluna inicial do relatório)</td>
                </tr>
                <tr class="fundo">
                  <td></td>
                  <td>->RECUPERAÇÃO</td>
                  <td></td>
                  <td>agendada para cancelamento(=recuperação) no período atual</td>
                </tr>
                <tr>
                  <td></td>
                  <td>->CORRENTE</td>
                  <td></td>
                  <td>recuperada, voltando a corrente no período atual</td>
                </tr>
                <tr class="fundo">
                  <td></td>
                  <td>->OUTROS</td>
                  <td></td>
                  <td>recuperada, não voltando a corrente (nem permanecendo em recuperação, nem cancelada) no período atual</td>
                </tr>
                <tr>
                  <td>CANCELADA</td>
                  <td></td>
                  <td></td>
                  <td>tudo o que estava expirado no período anterior (coluna inicial do relatório)</td>
                </tr>
                <tr class="fundo">
                  <td></td>
                  <td>->CANCELADA</td>
                  <td></td>
                  <td>cancelada no período atual</td>
                </tr>
                <tr>
                  <td></td>
                  <td>->CORRENTE</td>
                  <td></td>
                  <td>revertida, voltando a corrente no período atual</td>
                </tr>
                <tr class="fundo">
                  <td></td>
                  <td>->OUTROS</td>
                  <td></td>
                  <td>revertida, porém não voltando a corrente (nem permanecendo cancelada) no período atual</td>
                </tr>
                <tr>
                  <td>EXCLUÍDA</td>
                  <td></td>
                  <td></td>
                  <td>tudo o que estava excluído no período anterior (coluna inicial do relatório)</td>
                </tr>
                <tr class="fundo">
                  <td></td>
                  <td>->EXCLUÍDA</td>
                  <td></td>
                  <td>excluída no período atual</td>
                </tr>
                <tr>
                  <td></td>
                  <td>->CORRENTE</td>
                  <td></td>
                  <td>excluída no período anterior, voltando a corrente no período atual</td>
                </tr>
                <tr class="fundo">
                  <td></td>
                  <td>->OUTROS</td>
                  <td></td>
                  <td>excluída no período anterior, porém não voltando a corrente(nem permanecendo excluída) no período atual</td>
                </tr>
                <tr>
                  <td colspan="4" style="border-top:1px solid">
                    corrente do período atual:<br />= corrente do período anterior<br />+ novas vendas (digital e impresso corrente)<br />+ diferente de corrente no período
                    anterior e corrente no período atual<br />- corrente no período anterior e diferente de corrente no período atual.
                  </td>
                </tr>
              </table>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-card>
    </v-app>
  </div>
</template>
<style>
.fundo {
  background-color: #eee;
}
</style>
<script>
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
import util from "../../../util.js";
export default {
  data() {
    return {
      diasUS: ["", ""],
      mnuDias: false,

      mesesUS: ["", ""],
      mnuMeses: false,

      anosUS: [],

      mensagem: "",
      snackbar: false,
      snackErro: false,

      dialogLegenda: false,
      loading: false,
      value: 0
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    async carteira(tipo) {
      const diario = await this.csvDiario(tipo);
      if (!diario) {
        const mensal = await this.csvMensal(tipo);
        if (!mensal) {
          await this.csvAnual(tipo);
        }
      }
    },

    async csvDiario(tipo) {
      if (!this.diasUS || this.diasUS.length != 2 || this.diasUS[0] == "" || this.diasUS[1] == "") {
        return false;
      }

      var dtInic = "";
      var dtFim = "";
      if (!util.parseDateUS(this.diasUS[0]) || !util.parseDateUS(this.diasUS[1]) || util.parseDateUS(this.diasUS[0]) > util.parseDateUS(this.diasUS[1])) {
        this.mensagem = "Verifique o período";
        this.snackErro = true;
        return false;
      } else {
        dtInic = util.formatDate(this.diasUS[0]);
        dtFim = util.formatDate(this.diasUS[1]);
      }

      this.mesesUS = ["", ""];
      this.anosUS = [];

      this.mnuDias = false;

      this.value = 0;
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        this.value += 1;
      }, 3000);
      this.loading = true;

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            `ass/assinatura/${tipo == "rel" ? "relatorioCarteira" : "carteiraMovimento"}?idSite=${this.$store.state.usuario.idSitePrincipal}&inicio=${dtInic}&fim=${dtFim}`
        );
        if (response.data) {
          this.mensagem = "Relatório exportado, verifique suas mensagens";
          this.snackbar = true;
        }
        this.loading = false;
        return true;
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
        this.loading = false;
        return false;
      }
    },

    async csvMensal(tipo) {
      if (!this.mesesUS || this.mesesUS.length != 2 || this.mesesUS[0] == "" || this.mesesUS[1] == "") {
        return false;
      }

      var mesInic = "";
      var mesFim = "";
      if (
        !util.parseDateUS(this.mesesUS[0] + "-01") ||
        !util.parseDateUS(this.mesesUS[1] + "-01") ||
        util.parseDateUS(this.mesesUS[0] + "-01") > util.parseDateUS(this.mesesUS[1] + "-01")
      ) {
        this.mensagem = "Verifique o período";
        this.snackErro = true;
        return false;
      } else {
        mesInic = util.formatMMAAAA(this.mesesUS[0]);
        mesFim = util.formatMMAAAA(this.mesesUS[1]);
      }

      this.diasUS = ["", ""];
      this.anosUS = [];

      this.mnuMeses = false;

      this.value = 0;
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        this.value += 1;
      }, 3000);
      this.loading = true;

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            `ass/assinatura/${tipo == "rel" ? "relatorioCarteira" : "carteiraMovimento"}?idSite=${this.$store.state.usuario.idSitePrincipal}&inicio=${mesInic}&fim=${mesFim}`
        );
        if (response.data) {
          this.mensagem = "Relatório exportado, verifique suas mensagens";
          this.snackbar = true;
        }
        this.loading = false;
        return true;
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
        this.loading = false;
      }
    },

    async csvAnual(tipo) {
      if (this.anosUS.length != 2) {
        return false;
      }

      if (!this.anosUS || this.anosUS.length != 2) {
        return false;
      }
      var anoInic = "";
      var anoFim = "";
      if (
        !util.parseDateUS(this.anosUS[0] + "-01-01") ||
        !util.parseDateUS(this.anosUS[1] + "-01-01") ||
        util.parseDateUS(this.anosUS[0] + "-01-01") > util.parseDateUS(this.anosUS[1] + "-01-01")
      ) {
        this.mensagem = "Verifique o período";
        this.snackErro = true;
        return false;
      } else {
        anoInic = this.anosUS[0];
        anoFim = this.anosUS[1];
      }

      this.diasUS = ["", ""];
      this.mesesUS = ["", ""];

      this.value = 0;
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        this.value += 1;
      }, 3000);
      this.loading = true;

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            `ass/assinatura/${tipo == "rel" ? "relatorioCarteira" : "carteiraMovimento"}?idSite=${this.$store.state.usuario.idSitePrincipal}&inicio=${anoInic}&fim=${anoFim}`
        );
        if (response.data) {
          this.mensagem = "Relatório exportado, verifique suas mensagens";
          this.snackbar = true;
        }
        this.loading = false;
        return true;
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
        this.loading = false;
        return false;
      }
    },

    formatDate(date) {
      return util.formatDate(date);
    }
  },

  beforeMount() {
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_RELATÓRIOS_CARTEIRA"]);
  },
  computed: {
    dias() {
      var retorno = "";
      if (this.diasUS && this.diasUS.length > 1) {
        this.diasUS.forEach(element => {
          if (element) {
            retorno += (retorno ? " - " : "") + util.formatDate(element);
          }
        });
      }
      return retorno;
    },
    meses() {
      var retorno = "";
      if (this.mesesUS && this.mesesUS.length > 1) {
        this.mesesUS.forEach(element => {
          if (element) {
            retorno += (retorno ? " - " : "") + util.formatMMAAAA(element);
          }
        });
      }
      return retorno;
    },
    anos() {
      var retorno = [];
      var inicio = new Date().getFullYear();
      inicio -= 4;
      for (let index = 0; index < 6; index++) {
        retorno.push(inicio);
        inicio++;
      }
      return retorno;
    }
  }
};
</script>
