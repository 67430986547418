<template>
  <div id="app">
    <v-app id="inspire">
      <v-card class="hide-overflow fill-height">
        <v-toolbar height="50" color="#333333">
          <v-icon color="white" class="mr-2">mdi-account</v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light">
            Operações - Contas a
            {{ receber ? "Receber" : "Pagar" }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <router-link :to="receber ? '/financeiro/operacao/receber' : '/financeiro/operacao/pagar'" style="text-decoration: none" v-if="direitoCriar">
            <v-btn color="success" fab small>
              <v-icon color="white" title="Incluir">add</v-icon>
            </v-btn>
          </router-link>
        </v-toolbar>
        <div class="px-6 mt-4">
          <v-layout wrap>
            <v-flex md1 xs3>
              <v-dialog v-model="dialogFiltroAvancado" max-width="800px">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" color="blue" fab small :disabled="loading || loadingExp">
                    <v-icon color="white" title="Filtro Avançado">search</v-icon>
                  </v-btn>
                  <v-btn
                    color="red lighten-2"
                    style="color:white"
                    class="btn-rsn ml-n2 mb-6"
                    id="btn-limpa-filtro"
                    fab
                    @click="filtroAvancadoLimpar"
                    :disabled="loading || loadingExp"
                  >
                    <v-icon class="icon-rsn" small center title="Limpar Filtro">clear</v-icon>
                  </v-btn>
                </template>
                <template>
                  <v-card>
                    <v-tabs v-model="tab" background-color="blue accent-4" center-active dark>
                      <v-tab>Filtro</v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab">
                      <v-tab-item>
                        <FiltroOperacao ref="filtroOperacao" :receber="receber" />
                      </v-tab-item>
                    </v-tabs-items>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="orange lighten-2 white" style="color:white" class="btn-rsn" @click="filtroAvancadoRetornar">
                        <v-icon class="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar
                      </v-btn>
                      <v-btn outlined color="red white" style="color:white" class="btn-rsn" @click="filtroAvancadoLimpar">
                        <v-icon class="icon-rsn" left>clear</v-icon>Limpar
                      </v-btn>
                      <v-btn color="blue white" style="color:white" class="btn-rsn" depressed @click="limpaFiltro"> <v-icon class="icon-rsn" left>tune</v-icon>Filtrar </v-btn>
                      <v-btn v-if="direitoExportar" outlined color="blue white" style="color:white" class="btn-rsn" depressed @click="filtroAvancadoFiltrar(true)">
                        <v-icon class="icon-rsn" left>description</v-icon>Exportar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex md1 xs2>
              <v-btn v-if="direitoExportar" color="blue" :loading="loading" :disabled="loading || loadingExp" fab small @click="filtroAvancadoFiltrar(true)">
                <v-icon color="white" title="Exportar">description</v-icon>
              </v-btn>
            </v-flex>
            <v-flex xs6 md2>
              <v-text-field
                background-color="white"
                class="mb-2 elevation-0"
                v-model="identificacaoPesquisa"
                v-mask="'##############'"
                append-icon="search"
                label="Identificação"
                @keyup.enter="limpaFiltro"
                @click:append="limpaFiltro"
                autocomplete="disabled"
                :name="Math.random()"
                :disabled="loading"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md2>
              <v-text-field
                background-color="white"
                class="mb-2 elevation-0"
                v-model="nomePesquisa"
                append-icon="search"
                label="Nome/Razão Social"
                @keyup.enter="limpaFiltro"
                @click:append="limpaFiltro"
                autocomplete="disabled"
                :name="Math.random()"
                :disabled="loading"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-data-table
            mobile-breakpoint="0"
            :headers="receber ? headerReceber : headerPagar"
            :items="registros"
            :options.sync="options"
            :no-data-text="mensagemPesquisa"
            :footer-props="rodape"
            :disable-sort="carregando"
            :disable-pagination="carregando"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon v-if="direitoAssinatura && item.origem == 'ASS'" color="red" title="Assinatura" @click="visualizarRenovacao(item)">assignment</v-icon>
              <v-icon v-if="direitoAnuncio && item.origem == 'ANU'" color="yellow darken-2" title="Anúncio" @click="visualizarAnuncio(item)">{{
                item.idAnuncio ? "chrome_reader_mode" : "edit"
              }}</v-icon>
              <v-icon v-if="direitoAnuncio && item.origem == 'PAC'" color="yellow darken-3" title="Pacote" @click="visualizarPacote(item)">library_books</v-icon>
              <v-icon
                v-if="item.id && direitoEditar && (item.origem != 'ANU' || (item.origem == 'ANU' && item.idAnuncio))"
                color="blue"
                :title="item.origem == 'FIN' || item.origem == 'AVU' ? 'Editar' : 'Visualizar Operação'"
                @click="editarOperacao(item)"
                >edit</v-icon
              >
              <v-icon v-if="direitoEditar && item.id" color="blue" title="Lançamentos" @click="visualizarLancamentos(item)">view_headline</v-icon>
              <v-icon v-if="direitoParcelas" color="blue lighten-2" title="Parcelas" @click="visualizarParcelas(item)">add_circle_outline</v-icon>
              <v-icon v-if="direitoEditar && item.origem == 'FIN'" color="blue" title="Visualizar Log" @click="visualizarLog(item)">info</v-icon>
            </template>

            <template v-slot:item.id="{ item }">
              {{ item.idAssinatura ? item.idAssinatura + "/" + item.renovacao : item.idAnuncio ? item.idAnuncio : item.idPacote ? item.idPacote : item.id }}
            </template>

            <template v-slot:header.conta="{ header }">
              <span title="Somente se filtro de conta selecionado">{{ header.text }}</span>
            </template>

            <template v-slot:item.identificacao="{ item }">
              {{ receber ? item.identificacao : $store.state.usuario.site_identificacao == item.identificacao ? "" : item.identificacao }}
            </template>

            <template v-slot:item.situacao="{ item }">
              <span v-html="item.situacao == null ? '' : item.situacao == 1 ? 'Ativa' : '<strong>Cancelada</strong>'"></span>
            </template>

            <template v-slot:item.mensal="{ item }">
              {{ item.mensal ? "Sim" : "" }}
            </template>
            <template v-slot:item.confirmada="{ item }">
              {{ item.confirmada ? "" : "Sim" }}
            </template>

            <template v-slot:item.dtCriacao="{ item }">{{ formatDateTime(item.dtCriacao) }}</template>
            <template v-slot:item.dtOperacao="{ item }">{{ formatDate(item.dtOperacao) }}</template>
            <template v-slot:item.vlrBruto="{ item }">{{ formatCurrency(item.vlrBruto) }}</template>
            <template v-slot:item.vlrLiquido="{ item }">{{ formatCurrency(item.vlrLiquido) }}</template>
          </v-data-table>

          <div class="text-xs-center pt-2 marg-bt">
            <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 7" circle></v-pagination>
          </div>
        </div>

        <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagemPesquisa }}</v-snackbar>

        <v-dialog v-model="dialogLancamentos" max-width="900">
          <v-card>
            <v-toolbar>
              <span class="headline">Lançamentos {{ operacao }}</span>
            </v-toolbar>
            <v-card-text>
              <v-data-table mobile-breakpoint="0" :headers="headerLancamentos" :items="lancamentos" :footer-props="rodapeLancamentos" no-data-text>
                <template v-slot:item.dtLancamento="{ item }">{{ formatDate(item.dtLancamento) }}</template>
                <template v-slot:item.valor="{ item }">{{ formatCurrency(item.valor) }}</template>
              </v-data-table>
            </v-card-text>
            <v-card-actions>
              <v-btn color="blue lighten-2" text @click="dialogLancamentos = false">Fechar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogLogAlteracao" max-width="1200px">
          <v-card>
            <LogAlteracao :idOperacao="idOperacao" :dialogLogAlteracao.sync="dialogLogAlteracao" />
          </v-card>
        </v-dialog>
      </v-card>
    </v-app>
  </div>
</template>

<script>
import LogAlteracao from "@/views/components/LogAlteracao.vue";
import FiltroOperacao from "@/views/components/FiltroOperacao.vue";
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  components: {
    LogAlteracao,
    FiltroOperacao
  },
  data() {
    return {
      headerReceber: [
        { text: "Ações", value: "actions", width: "130" },
        { text: "Código", value: "id" },
        { text: "Registro", value: "dtCriacao" },
        { text: "Operação", value: "dtOperacao" },
        { text: "Cliente", value: "nome" },
        { text: "Identificação", value: "identificacao" },
        { text: "Bruto", value: "vlrBruto", align: "end" },
        { text: "Líquido", value: "vlrLiquido", align: "end" },
        { text: "Parcelas", value: "nroParcelas" },
        { text: "Situação", value: "situacao" },
        { text: "Descricao", value: "descricao" }
      ],
      headerPagar: [
        { text: "Ações", value: "actions", width: "130" },
        { text: "Código", value: "id" },
        { text: "Registro", value: "dtCriacao" },
        { text: "Operação", value: "dtOperacao" },
        { text: "Destino*", value: "conta" },
        { text: "Fornecedor", value: "nome" },
        { text: "Identificação", value: "identificacao" },
        { text: "Bruto", value: "vlrBruto", align: "end" },
        { text: "Líquido", value: "vlrLiquido", align: "end" },
        { text: "Parcelas", value: "nroParcelas" },
        { text: "Situação", value: "situacao" },
        { text: "Mensal", value: "mensal" },
        { text: "Orçamento", value: "confirmada" },
        { text: "Descrição", value: "descricao" }
      ],
      headerLancamentos: [
        { text: "ID", value: "id" },
        { text: "Data", value: "dtLancamento" },
        { text: "Débito", value: "nomeCompletoD" },
        { text: "Crédito", value: "nomeCompletoC" },
        { text: "Valor", value: "valor", align: "end" },
        { text: "Parcela", value: "idParcela" }
      ],
      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },
      rodapeLancamentos: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        pageText: ""
      },
      origens: [
        { id: "FIN", nome: "Financeiro" },
        { id: "ANU", nome: "Anúncio" },
        { id: "ASS", nome: "Assinatura" },
        { id: "PAC", nome: "Pacote" },
        { id: "AVU", nome: "Venda Avulsa" }
      ],

      tab: null,
      inicial: true,
      idSitePrincipal: null,
      dialogLancamentos: false,
      lancamentos: [],
      operacao: null,
      snackErro: false,
      mensagem: "",

      dialogLogAlteracao: false,
      idOperacao: "",

      direitoExportar: false,
      direitoCriar: false,
      direitoEditar: false,
      direitoAssinatura: false,
      direitoAnuncio: false,
      direitoParcelas: false,

      mensagemPesquisa: "",
      identificacaoPesquisa: null,
      nomePesquisa: null,

      dialogFiltroAvancado: false,
      registros: [],
      registrosPagina: 10,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      carregando: true,
      ordenacao: "",
      ordem: "",
      options: {},
      loader: null,
      loading: false,
      loadingExp: false
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    filtroAvancadoRetornar() {
      //* Esconde a dialog do filtro avançado
      this.dialogFiltroAvancado = false;
    },
    filtroAvancadoLimpar() {
      //* Limpa os registros da tabela
      this.registros = [];
      this.totalRegistros = 0;
      this.totalPaginas = 0;
      this.mensagemPesquisa = "MONTE SEU FILTRO PARA VISUALIZAR AS OPERAÇÕES...";

      //* Limpa os campos dos filtros
      if (this.$refs.filtroOperacao) {
        this.$refs.filtroOperacao.limpar();
      }
    },
    limpaFiltro() {
      var pgAnterior = this.paginaAtual;
      this.paginaAtual = 1;
      if (pgAnterior == 1) {
        this.filtroAvancadoFiltrar(false);
      }
    },
    async filtroAvancadoFiltrar(exportar) {
      if (this.identificacaoPesquisa && !util.valida_cpf(this.identificacaoPesquisa) && !util.valida_cnpj(this.identificacaoPesquisa)) {
        this.mensagemPesquisa = "Identificação inválida";
        this.snackErro = true;
        return;
      }

      var l = null;
      if (exportar) {
        this.loader = "loading";
        l = this.loader;
        this[l] = !this[l];
        setTimeout(() => (this[l] = false), 5000);
        this.loader = null;
      }

      if (!exportar) {
        //* Sinaliza que está carregando
        this.carregando = true;
        this.mensagemPesquisa = "Carregando...";
        this.loading = true;
      } else {
        this.mensagemPesquisa = "MONTE SEU FILTRO PARA VISUALIZAR AS OPERAÇÕES...";
      }

      //* Limpa os registros da tabela
      this.registros = [];
      this.totalRegistros = 0;
      this.totalPaginas = 0;

      //* Esconde a dialog do filtro avançado
      this.dialogFiltroAvancado = false;

      var filtroOperacaoUrl = this.montaFiltro();
      if (!filtroOperacaoUrl.includes("idSite")) {
        filtroOperacaoUrl += "&idSite=" + this.idSitePrincipal;
      }
      if (!filtroOperacaoUrl.includes("origem")) {
        filtroOperacaoUrl += "&origem=FIN";
      }
      if (this.identificacaoPesquisa) {
        if (filtroOperacaoUrl.includes("identificacao")) {
          this.identificacaoPesquisa = "";
        } else {
          filtroOperacaoUrl += "&identificacao=" + this.identificacaoPesquisa;
        }
      }
      if (this.nomePesquisa) {
        if (filtroOperacaoUrl.includes("&nome=")) {
          this.nomePesquisa = "";
        } else {
          filtroOperacaoUrl += "&nome=" + this.nomePesquisa;
          filtroOperacaoUrl += "&nomeFantasia=" + this.nomePesquisa;
        }
      }

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "fin/operacao/tipo/" +
            (this.receber ? "1" : "0") +
            "?" +
            filtroOperacaoUrl +
            "&pagina=" +
            this.paginaAtual +
            "&tamanho=" +
            this.registrosPagina +
            "&ordenacao=" +
            this.ordenacao +
            "&ordem=" +
            this.ordem +
            (exportar ? "&exportar=1" : "")
        );

        if (!exportar) {
          //seta as variaveis com o retorno
          this.registros = response.data.data;
          this.totalRegistros = response.data.total;
          this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
          this.rodape.pageText = this.totalRegistros + " itens";
          //fecha o loading
          this.carregando = false;
          //se não achar registro seta a mensagem senão deixa em branco
          this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
        }
      } catch (e) {
        this.carregando = false;
        this.registros = [];
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else {
          this.mensagemPesquisa =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
          this.snackErro = true;
        }
      } finally {
        this.loading = false;
      }
    },
    montaFiltro() {
      var filtroOperacaoUrl = "";
      if (this.$refs.filtroOperacao) {
        //* Carrega os filtros da nota fiscal
        filtroOperacaoUrl = filtroOperacaoUrl + this.$refs.filtroOperacao.filtroUrlParametros();
      }
      return filtroOperacaoUrl;
    },
    recarregaParametros() {
      if (this.receber) {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_CONTAS_RECEBER_ACESSO"]);
      } else {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_CONTAS_PAGAR_ACESSO"]);
      }

      this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

      //* Verifica se o usuário logado possui direitos
      this.$store.state.usuario.direitos.forEach(element => {
        var direito = element.modulo + "_" + element.grupo + "_" + element.direito;

        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_ASSINATURAS_EDITAR") {
          this.direitoAssinatura = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_ANÚNCIOS_EDITAR") {
          this.direitoAnuncio = true;
        }

        if (this.receber) {
          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_CONTAS_RECEBER_CRIAR") {
            this.direitoCriar = true;
          }
          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_CONTAS_RECEBER_EDITAR") {
            this.direitoEditar = true;
          }
          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_CONTAS_RECEBER_EXPORTAR") {
            this.direitoExportar = true;
          }
          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_RECEBER_ACESSO") {
            this.direitoParcelas = true;
          }
        } else {
          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_CONTAS_PAGAR_CRIAR") {
            this.direitoCriar = true;
          }
          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_CONTAS_PAGAR_EDITAR") {
            this.direitoEditar = true;
          }
          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_CONTAS_PAGAR_EXPORTAR") {
            this.direitoExportar = true;
          }
          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_PAGAR_ACESSO") {
            this.direitoParcelas = true;
          }
        }
      });

      if (this.receber && this.$store.state.usuario.site_protheus) {
        this.headerReceber.push({ text: "Protheus", value: "idProtheus" });
      }
    },

    editarOperacao(item) {
      window.open((this.receber ? "/financeiro/operacao/receber/" : "/financeiro/operacao/pagar/") + item.id, "_blank");
    },
    async visualizarLancamentos(item) {
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/lancamento/operacao/" + item.id + "?completa=1");
      this.lancamentos = response.data;
      this.operacao = item.idAssinatura
        ? "Assinatura " + item.idAssinatura + " / Renovação " + item.renovacao
        : item.idAnuncio
        ? "Anúncio " + item.idAnuncio
        : item.idPacote
        ? "Pacote Anúncio " + item.idPacote
        : item.origem == "AVU"
        ? "Venda Avulsa " + item.id
        : "Operação " + item.id;
      this.dialogLancamentos = true;
    },
    visualizarRenovacao(item) {
      window.open("/assinaturas/assinatura/" + item.idAssinatura + "/" + item.idRenovacao, "_blank");
    },
    visualizarAnuncio(item) {
      if (item.idAnuncio) {
        window.open("/comercial/anuncio/" + item.idAnuncio, "_blank");
      } else {
        window.open("/financeiro/operacao/receber/" + item.id, "_blank");
      }
    },
    visualizarPacote(item) {
      if (item.idPacote) {
        window.open("/comercial/pacote/" + item.idPacote, "_blank");
      }
    },
    visualizarParcelas(item) {
      window.open("/financeiro/parcelas/" + (this.receber ? "receber" : "pagar") + "?idOperacao=" + item.id, "_blank");
    },
    visualizarLog(item) {
      if (item.origem == "FIN") {
        this.idOperacao = item.id + "";
        this.dialogLogAlteracao = true;
      }
    },
    closeLogAlteracao() {
      this.dialogLogAlteracao = false;
      this.idOperacao = "";
    },

    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },

    formatDate(date) {
      return util.formatDate(date);
    },
    formatDateTime(datetime) {
      return util.formatDateTime(datetime);
    },
    formatCurrency(valor) {
      return util.formatCurrency(valor);
    }
  },
  beforeMount() {
    this.recarregaParametros();
  },

  computed: {
    receber() {
      return this.$route.path.indexOf("/receber") > -1;
    }
  },

  watch: {
    receber() {
      this.inicial = true;
    },
    $route() {
      this.inicial = true;
      this.mensagemPesquisa = "MONTE SEU FILTRO PARA VISUALIZAR AS OPERAÇÕES...";
      this.registros = [];
      this.totalRegistros = 0;
      this.totalPaginas = 0;
      this.recarregaParametros();
    },
    paginaAtual() {
      this.filtroAvancadoFiltrar(false);
    },
    dialogLogAlteracao(val) {
      val || this.closeLogAlteracao();
    },
    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }
        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }
        if (!this.inicial) {
          this.filtroAvancadoFiltrar(false);
        } else {
          this.mensagemPesquisa = "MONTE SEU FILTRO PARA VISUALIZAR AS OPERAÇÕES...";
          this.inicial = false;
        }
      }
    }
  }
};
</script>
