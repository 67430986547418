<template>
  <div id="app">
    <v-app id="inspire">
      <v-card>
        <v-toolbar color="#333333">
          <v-icon color="white" class="mr-2">sort</v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light">
            Balancete
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container fluid grid-list-md>
          <v-layout wrap>
            <v-flex xs6 md3>
              <v-menu
                v-model="mnuDtInicio"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dtInicio"
                    autofocus
                    label="Período"
                    prepend-icon="event"
                    v-on="on"
                    color="blue-grey lighten-2"
                    @click:clear="dtInicioUS = ['', '']"
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dtInicioUS"
                  locale="pt-br"
                  no-title
                  range
                  ><v-spacer></v-spacer>
                  <v-btn text color="primary" @click="mnuDtInicio = false">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs1>
              <v-btn
                color="accent"
                :loading="loading"
                :disabled="loading || loadingExp"
                fab
                small
                @click="pesquisaDados(false)"
              >
                <v-icon color="white" title="Filtrar">search</v-icon>
              </v-btn>
            </v-flex>
            <v-flex xs1>
              <v-btn
                v-if="direitoExportar"
                color="blue"
                :loading="loadingExp"
                :disabled="loading || loadingExp"
                fab
                small
                @click="pesquisaDados(true)"
              >
                <v-icon color="white" title="Exportar">description</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
        <div class="px-4 mt-3">
          <v-data-table
            mobile-breakpoint="0"
            :headers="headers"
            :items="registros"
            :no-data-text="mensagemPesquisa"
            hide-default-footer
            disable-sort
            disable-pagination
          >
            <template v-slot:[getItemNumero()]="{ item }">
              <span
                v-html="
                  '&emsp;'.repeat(item.numero.split('.').length - 2) +
                    item.numero +
                    ' - ' +
                    (item.nome != 'Disponível' ||
                    (item.nome == 'Disponível' &&
                      ($store.state.usuario.site_planoContas || direitoAdmin))
                      ? item.nome
                      : 'Caixa & Bancos')
                "
              ></span>
            </template>
            <template v-slot:[getItemMovimentoD()]="{ item }">
              {{ formatCurrency(item.movimentoD) }}
            </template>
            <template v-slot:[getItemMovimentoC()]="{ item }">
              {{ formatCurrency(item.movimentoC) }}
            </template>
            <template v-slot:[getItemSaldoD()]="{ item }">
              {{ formatCurrency(item.saldoD) }}
            </template>
            <template v-slot:[getItemSaldoC()]="{ item }">
              {{ formatCurrency(item.saldoC) }}
            </template>
          </v-data-table>
          <v-footer v-if="dtInicio"
            ><v-col class="text-right" cols="12"
              >Movimento: {{ dtInicio }}.<br />
              Saldo: tudo até
              {{ dtInicio.substr(13) }}.</v-col
            ></v-footer
          >

          <v-snackbar
            v-model="snackbar"
            color="info"
            :timeout="5000"
            relative
            bottom
            left
            >{{ mensagem }}</v-snackbar
          >
          <v-snackbar
            v-model="snackErro"
            color="error"
            :timeout="5000"
            relative
            bottom
            left
            >{{ mensagem }}</v-snackbar
          >
        </div>
      </v-card>
    </v-app>
  </div>
</template>

<script>
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
import util from "../../../util.js";
export default {
  data() {
    return {
      headers: [
        { text: "Conta", value: "numero", divider: "true" },
        {
          text: "Movimento (D)",
          value: "movimentoD",
          align: "end",
          width: "200"
        },
        {
          text: "Movimento (C)",
          value: "movimentoC",
          align: "end",
          width: "200",
          divider: "true"
        },
        {
          text: "Saldo (D)",
          value: "saldoD",
          align: "end",
          width: "200"
        },
        {
          text: "Saldo (C)",
          value: "saldoC",
          align: "end",
          width: "200"
        }
      ],
      registros: [],
      dtInicioUS: ["", ""],
      mnuDtInicio: false,
      direitoAdmin: false,
      direitoExportar: false,
      mensagem: "",
      snackbar: false,
      snackErro: false,
      mensagemPesquisa: "",
      carregando: true,
      options: {},
      loader: null,
      loading: false,
      loadingExp: false
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    getItemNumero() {
      return "item.numero";
    },
    getItemMovimentoD() {
      return "item.movimentoD";
    },
    getItemMovimentoC() {
      return "item.movimentoC";
    },
    getItemSaldoD() {
      return "item.saldoD";
    },
    getItemSaldoC() {
      return "item.saldoC";
    },

    async pesquisaDados(exportar) {
      this.registros = [];
      var dtInic = "";
      var dtFim = "";
      if (this.dtInicioUS && this.dtInicioUS.length > 1) {
        dtInic = this.dtInicioUS[0];
        dtFim = this.dtInicioUS[1];
      }
      if (!dtInic || !dtFim) {
        this.mensagem = "Verifique o período";
        this.snackErro = true;
        return;
      }

      if (exportar) {
        this.loader = "loadingExp";
      } else {
        this.loader = "loading";
      }
      const l = this.loader;
      this[l] = !this[l];

      //sinaliza que está carregando
      this.carregando = true;
      this.mensagemPesquisa = "Carregando...";

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "fin/lancamento/balancete?idSite=" +
            this.$store.state.usuario.idSitePrincipal +
            "&dtInicio=" +
            dtInic +
            "&dtFim=" +
            dtFim +
            (exportar ? "&exportar=1" : "")
        );
        this.registros = response.data;

        //fecha o loading
        this.carregando = false;

        //se não achar registro seta a mensagem senão deixa em branco
        if (this.registros.length) {
          this.mensagemPesquisa = "";

          if (exportar) {
            this.mensagem = "Balancete exportado, verifique suas mensagens";
            this.snackbar = true;
          }
        } else {
          this.mensagemPesquisa = "Nenhum registro encontrado";
        }
      } catch (e) {
        this.carregando = false;
        this.mensagemPesquisa = "Erro ao buscar dados: " + e;
      } finally {
        this[l] = false;
        this.loader = null;
      }
    },
    formatCurrency(valor) {
      return valor ? util.formatCurrency(valor) : "";
    }
  },

  beforeMount() {
    this.POSSUI_DIREITO([
      "WEBSUITA_ADMIN_SUITA",
      "FINANCEIRO_RELATÓRIOS_BALANCETE_ACESSO"
    ]);
    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito =
        element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA") {
        this.direitoAdmin = true;
      }
      if (
        direito == "WEBSUITA_ADMIN_SUITA" ||
        direito == "FINANCEIRO_RELATÓRIOS_BALANCETE_EXPORTAR"
      ) {
        this.direitoExportar = true;
      }
    });
  },
  computed: {
    dtInicio() {
      var retorno = "";
      if (this.dtInicioUS && this.dtInicioUS.length > 1) {
        this.dtInicioUS.forEach(element => {
          if (element) {
            retorno += (retorno ? " - " : "") + util.formatDate(element);
          }
        });
      }
      return retorno;
    }
  }
};
</script>
