<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-form ref="formElemento" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs6 md3>
            <v-autocomplete
              v-if="nfe && nfsc && nfse"
              v-model="filtrosSelecionados.emissaoNota"
              :items="itensEmissaoNota"
              :filter="filtrarObjetos"
              label="Emissão Nota Fiscal"
              item-text="nome"
              item-value="id"
              no-data-text
              autocomplete="disabled"
              :name="Math.random()"
            ></v-autocomplete>
          </v-flex>
          <v-expansion-panels v-model="painel" focusable>
            <v-expansion-panel dark class="elevation-0" v-if="nfe">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>NFe</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider></v-divider>
                <v-container fluid grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 md6>
                      <v-autocomplete
                        v-model="filtrosSelecionados.nfe.status"
                        :items="itensNFeStatus"
                        :filter="filtrarItens"
                        label="Status"
                        no-data-text
                        clearable
                        multiple
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-text-field v-model="filtrosSelecionados.nfe.serie" label="Série" maxlength="7" color="blue-grey lighten-2" clearable></v-text-field>
                    </v-flex>

                    <v-flex xs12>
                      <v-text-field v-model="filtrosSelecionados.nfe.chave" label="Chave" maxlength="60" color="blue-grey lighten-2" clearable></v-text-field>
                    </v-flex>

                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">
                        Número
                      </h4>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-container fluid grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12 md6>
                          <v-text-field v-model="filtrosSelecionados.nfe.numeroInicio" label="Início" v-mask="'########'" color="blue-grey lighten-2" clearable></v-text-field>
                        </v-flex>
                        <v-flex xs12 md6>
                          <v-text-field v-model="filtrosSelecionados.nfe.numeroFim" label="Fim" v-mask="'########'" color="blue-grey lighten-2" clearable></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-container>

                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">
                        Emissão
                      </h4>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-container fluid grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12 md6>
                          <v-menu
                            ref="mnfiltroSelNfeDtEmissaoInicioUS"
                            v-model="mnfiltroSelNfeDtEmissaoInicioUS"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="filtrosSelecionados.nfe.dtEmissaoInicio"
                                label="Início"
                                v-mask="'##/##/####'"
                                :rules="[value => valida_data(value) || 'Data inválida']"
                                color="blue-grey lighten-2"
                                clearable
                                prepend-icon="event"
                                v-on="on"
                                autocomplete="disabled"
                                :name="Math.random()"
                                @click:clear="filtroSelNfeDtEmissaoInicioUS = null"
                                @keyup="
                                  filtroSelNfeDtEmissaoInicioUS = valida_data(filtrosSelecionados.nfe.dtEmissaoInicio)
                                    ? formatDateUS(filtrosSelecionados.nfe.dtEmissaoInicio)
                                    : null
                                "
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="filtroSelNfeDtEmissaoInicioUS" locale="pt-br" no-title @input="mnfiltroSelNfeDtEmissaoInicioUS = false"></v-date-picker>
                          </v-menu>
                        </v-flex>
                        <v-flex xs12 md6>
                          <v-menu
                            ref="mnfiltroSelNfeDtEmissaoFimUS"
                            v-model="mnfiltroSelNfeDtEmissaoFimUS"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="filtrosSelecionados.nfe.dtEmissaoFim"
                                label="Fim"
                                v-mask="'##/##/####'"
                                :rules="[value => valida_data(value) || 'Data inválida']"
                                color="blue-grey lighten-2"
                                clearable
                                prepend-icon="event"
                                v-on="on"
                                autocomplete="disabled"
                                :name="Math.random()"
                                @click:clear="filtroSelNfeDtEmissaoFimUS = null"
                                @keyup="filtroSelNfeDtEmissaoFimUS = valida_data(filtrosSelecionados.nfe.dtEmissaoFim) ? formatDateUS(filtrosSelecionados.nfe.dtEmissaoFim) : null"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="filtroSelNfeDtEmissaoFimUS" locale="pt-br" no-title @input="mnfiltroSelNfeDtEmissaoFimUS = false"></v-date-picker>
                          </v-menu>
                        </v-flex>
                      </v-layout>
                    </v-container>

                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">
                        Cancelamento
                      </h4>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-container fluid grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12 md6>
                          <v-menu
                            ref="mnfiltroSelNfeDtCancelInicioUS"
                            v-model="mnfiltroSelNfeDtCancelInicioUS"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="filtrosSelecionados.nfe.dtCancelamentoInicio"
                                label="Início"
                                v-mask="'##/##/####'"
                                :rules="[value => valida_data(value) || 'Data inválida']"
                                color="blue-grey lighten-2"
                                clearable
                                prepend-icon="event"
                                v-on="on"
                                autocomplete="disabled"
                                :name="Math.random()"
                                @click:clear="filtroSelNfeDtCancelInicioUS = null"
                                @keyup="
                                  filtroSelNfeDtCancelInicioUS = valida_data(filtrosSelecionados.nfe.dtCancelamentoInicio)
                                    ? formatDateUS(filtrosSelecionados.nfe.dtCancelamentoInicio)
                                    : null
                                "
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="filtroSelNfeDtCancelInicioUS" locale="pt-br" no-title @input="mnfiltroSelNfeDtCancelInicioUS = false"></v-date-picker>
                          </v-menu>
                        </v-flex>
                        <v-flex xs12 md6>
                          <v-menu
                            ref="mnfiltroSelNfeDtCancelFimUS"
                            v-model="mnfiltroSelNfeDtCancelFimUS"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="filtrosSelecionados.nfe.dtCancelamentoFim"
                                label="Fim"
                                v-mask="'##/##/####'"
                                :rules="[value => valida_data(value) || 'Data inválida']"
                                color="blue-grey lighten-2"
                                clearable
                                prepend-icon="event"
                                v-on="on"
                                autocomplete="disabled"
                                :name="Math.random()"
                                @click:clear="filtroSelNfeDtCancelFimUS = null"
                                @keyup="
                                  filtroSelNfeDtCancelFimUS = valida_data(filtrosSelecionados.nfe.dtCancelamentoFim)
                                    ? formatDateUS(filtrosSelecionados.nfe.dtCancelamentoFim)
                                    : null
                                "
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="filtroSelNfeDtCancelFimUS" locale="pt-br" no-title @input="mnfiltroSelNfeDtCancelFimUS = false"></v-date-picker>
                          </v-menu>
                        </v-flex>
                      </v-layout>
                    </v-container>

                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">
                        Referenciada
                      </h4>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-container fluid grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12 md6>
                          <v-text-field v-model="filtrosSelecionados.nfe.serieRef" label="Série" maxlength="7" color="blue-grey lighten-2" clearable></v-text-field>
                        </v-flex>
                        <v-flex xs12 md6>
                          <v-text-field v-model="filtrosSelecionados.nfe.chaveRef" label="Chave" maxlength="60" color="blue-grey lighten-2" clearable></v-text-field>
                        </v-flex>

                        <v-flex xs12>
                          <h4 class="overline font-weight-black font-italic">
                            Número
                          </h4>
                          <v-divider></v-divider>
                        </v-flex>

                        <v-flex xs12 md6>
                          <v-text-field v-model="filtrosSelecionados.nfe.numeroRefInicio" label="Início" v-mask="'#######'" color="blue-grey lighten-2" clearable></v-text-field>
                        </v-flex>
                        <v-flex xs12 md6>
                          <v-text-field v-model="filtrosSelecionados.nfe.numeroRefFim" label="Fim" v-mask="'#######'" color="blue-grey lighten-2" clearable></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-layout>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" v-if="nfsc">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>NFSC</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider></v-divider>
                <v-container fluid grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 md6>
                      <v-autocomplete
                        v-model="filtrosSelecionados.nfsc.situacao"
                        :items="itensSituacaoNFSC"
                        :filter="filtrarObjetos"
                        label="Situação"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        clearable
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs6 md3>
                      <v-text-field v-model="filtrosSelecionados.nfsc.serie" label="Série" maxlength="7" color="blue-grey lighten-2" clearable></v-text-field>
                    </v-flex>
                    <v-flex xs6 md3>
                      <v-text-field
                        v-model="filtrosSelecionados.nfsc.anoMes"
                        label="Competência"
                        v-mask="'##/####'"
                        placeholder="mm/aaaa"
                        color="blue-grey lighten-2"
                        clearable
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">
                        Número
                      </h4>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-container fluid grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12 md6>
                          <v-text-field v-model="filtrosSelecionados.nfsc.numeroInicio" label="Início" v-mask="'#######'" color="blue-grey lighten-2" clearable></v-text-field>
                        </v-flex>
                        <v-flex xs12 md6>
                          <v-text-field v-model="filtrosSelecionados.nfsc.numeroFim" label="Fim" v-mask="'#######'" color="blue-grey lighten-2" clearable></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-container>

                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">
                        Emissão
                      </h4>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-container fluid grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12 md6>
                          <v-menu
                            ref="mnfiltroSelNfscDtEmissaoInicioUS"
                            v-model="mnfiltroSelNfscDtEmissaoInicioUS"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="filtrosSelecionados.nfsc.dtEmissaoInicio"
                                label="Início"
                                v-mask="'##/##/####'"
                                :rules="[value => valida_data(value) || 'Data inválida']"
                                color="blue-grey lighten-2"
                                clearable
                                prepend-icon="event"
                                v-on="on"
                                autocomplete="disabled"
                                :name="Math.random()"
                                @click:clear="filtroSelNfscDtEmissaoInicioUS = null"
                                @keyup="
                                  filtroSelNfscDtEmissaoInicioUS = valida_data(filtrosSelecionados.nfsc.dtEmissaoInicio)
                                    ? formatDateUS(filtrosSelecionados.nfsc.dtEmissaoInicio)
                                    : null
                                "
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="filtroSelNfscDtEmissaoInicioUS" locale="pt-br" no-title @input="mnfiltroSelNfscDtEmissaoInicioUS = false"></v-date-picker>
                          </v-menu>
                        </v-flex>
                        <v-flex xs12 md6>
                          <v-menu
                            ref="mnfiltroSelNfscDtEmissaoFimUS"
                            v-model="mnfiltroSelNfscDtEmissaoFimUS"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="filtrosSelecionados.nfsc.dtEmissaoFim"
                                label="Fim"
                                v-mask="'##/##/####'"
                                :rules="[value => valida_data(value) || 'Data inválida']"
                                color="blue-grey lighten-2"
                                clearable
                                prepend-icon="event"
                                v-on="on"
                                autocomplete="disabled"
                                :name="Math.random()"
                                @click:clear="filtroSelNfscDtEmissaoFimUS = null"
                                @keyup="
                                  filtroSelNfscDtEmissaoFimUS = valida_data(filtrosSelecionados.nfsc.dtEmissaoFim) ? formatDateUS(filtrosSelecionados.nfsc.dtEmissaoFim) : null
                                "
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="filtroSelNfscDtEmissaoFimUS" locale="pt-br" no-title @input="mnfiltroSelNfscDtEmissaoFimUS = false"></v-date-picker>
                          </v-menu>
                        </v-flex>
                      </v-layout>
                    </v-container>
                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">
                        Cancelamento
                      </h4>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-container fluid grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12 md6>
                          <v-menu
                            ref="mnfiltroSelNfscCancelDtInicialUS"
                            v-model="mnfiltroSelNfscCancelDtInicialUS"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="filtrosSelecionados.nfsc.dtCancelamentoInicio"
                                label="Início"
                                v-mask="'##/##/####'"
                                :rules="[value => valida_data(value) || 'Data inválida']"
                                color="blue-grey lighten-2"
                                clearable
                                prepend-icon="event"
                                v-on="on"
                                autocomplete="disabled"
                                :name="Math.random()"
                                @click:clear="filtroSelNfscCancelDtInicialUS = null"
                                @keyup="
                                  filtroSelNfscCancelDtInicialUS = valida_data(filtrosSelecionados.nfsc.dtCancelamentoInicio)
                                    ? formatDateUS(filtrosSelecionados.nfsc.dtCancelamentoInicio)
                                    : null
                                "
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="filtroSelNfscCancelDtInicialUS" locale="pt-br" no-title @input="mnfiltroSelNfscCancelDtInicialUS = false"></v-date-picker>
                          </v-menu>
                        </v-flex>
                        <v-flex xs12 md6>
                          <v-menu
                            ref="mnfiltroSelNfscCancelDtFimUS"
                            v-model="mnfiltroSelNfscCancelDtFimUS"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="filtrosSelecionados.nfsc.dtCancelamentoFim"
                                label="Fim"
                                v-mask="'##/##/####'"
                                :rules="[value => valida_data(value) || 'Data inválida']"
                                color="blue-grey lighten-2"
                                clearable
                                prepend-icon="event"
                                v-on="on"
                                autocomplete="disabled"
                                :name="Math.random()"
                                @click:clear="filtroSelNfscCancelDtFimUS = null"
                                @keyup="
                                  filtroSelNfscCancelDtFimUS = valida_data(filtrosSelecionados.nfsc.dtCancelamentoFim)
                                    ? formatDateUS(filtrosSelecionados.nfsc.dtCancelamentoFim)
                                    : null
                                "
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="filtroSelNfscCancelDtFimUS" locale="pt-br" no-title @input="mnfiltroSelNfscCancelDtFimUS = false"></v-date-picker>
                          </v-menu>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-layout>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" v-if="nfse">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>NFSe</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider></v-divider>
                <v-container fluid grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 md6>
                      <v-autocomplete
                        v-model="filtrosSelecionados.nfse.status"
                        :items="itensNFSeStatus"
                        :filter="filtrarItens"
                        label="Status"
                        no-data-text
                        clearable
                        multiple
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-text-field v-model="filtrosSelecionados.nfse.serie" label="Série" maxlength="7" color="blue-grey lighten-2" clearable></v-text-field>
                    </v-flex>

                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">
                        RPS
                      </h4>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-container fluid grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12 md6>
                          <v-text-field v-model="filtrosSelecionados.nfse.rpsInicio" label="Início" v-mask="'#######'" color="blue-grey lighten-2" clearable></v-text-field>
                        </v-flex>
                        <v-flex xs12 md6>
                          <v-text-field v-model="filtrosSelecionados.nfse.rpsFim" label="Fim" v-mask="'#######'" color="blue-grey lighten-2" clearable></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-container>
                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">
                        Número
                      </h4>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-container fluid grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12 md6>
                          <v-text-field v-model="filtrosSelecionados.nfse.numeroInicio" label="Início" v-mask="'#######'" color="blue-grey lighten-2" clearable></v-text-field>
                        </v-flex>
                        <v-flex xs12 md6>
                          <v-text-field v-model="filtrosSelecionados.nfse.numeroFim" label="Fim" v-mask="'#######'" color="blue-grey lighten-2" clearable></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-container>
                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">
                        Emissão
                      </h4>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-container fluid grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12 md6>
                          <v-menu
                            ref="mnfiltroSelNfseDtEmissaoInicioUS"
                            v-model="mnfiltroSelNfseDtEmissaoInicioUS"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="filtrosSelecionados.nfse.dtEmissaoInicio"
                                label="Início"
                                v-mask="'##/##/####'"
                                :rules="[value => valida_data(value) || 'Data inválida']"
                                color="blue-grey lighten-2"
                                clearable
                                prepend-icon="event"
                                v-on="on"
                                autocomplete="disabled"
                                :name="Math.random()"
                                @click:clear="filtroSelNfseDtEmissaoInicioUS = null"
                                @keyup="
                                  filtroSelNfseDtEmissaoInicioUS = valida_data(filtrosSelecionados.nfse.dtEmissaoInicio)
                                    ? formatDateUS(filtrosSelecionados.nfse.dtEmissaoInicio)
                                    : null
                                "
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="filtroSelNfseDtEmissaoInicioUS" locale="pt-br" no-title @input="mnfiltroSelNfseDtEmissaoInicioUS = false"></v-date-picker>
                          </v-menu>
                        </v-flex>
                        <v-flex xs12 md6>
                          <v-menu
                            ref="mnfiltroSelNfseDtEmissaoFimUS"
                            v-model="mnfiltroSelNfseDtEmissaoFimUS"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="filtrosSelecionados.nfse.dtEmissaoFim"
                                label="Fim"
                                v-mask="'##/##/####'"
                                :rules="[value => valida_data(value) || 'Data inválida']"
                                color="blue-grey lighten-2"
                                clearable
                                prepend-icon="event"
                                v-on="on"
                                autocomplete="disabled"
                                :name="Math.random()"
                                @click:clear="filtroSelNfseDtEmissaoFimUS = null"
                                @keyup="
                                  filtroSelNfseDtEmissaoFimUS = valida_data(filtrosSelecionados.nfse.dtEmissaoFim) ? formatDateUS(filtrosSelecionados.nfse.dtEmissaoFim) : null
                                "
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="filtroSelNfseDtEmissaoFimUS" locale="pt-br" no-title @input="mnfiltroSelNfseDtEmissaoFimUS = false"></v-date-picker>
                          </v-menu>
                        </v-flex>
                      </v-layout>
                    </v-container>
                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">
                        Cancelamento
                      </h4>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-container fluid grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12 md6>
                          <v-menu
                            ref="mnfiltroSelNfseCancelDtInicioUS"
                            v-model="mnfiltroSelNfseCancelDtInicioUS"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="filtrosSelecionados.nfse.dtCancelamentoInicio"
                                label="Início"
                                v-mask="'##/##/####'"
                                :rules="[value => valida_data(value) || 'Data inválida']"
                                color="blue-grey lighten-2"
                                clearable
                                prepend-icon="event"
                                v-on="on"
                                autocomplete="disabled"
                                :name="Math.random()"
                                @click:clear="filtroSelNfseCancelDtInicioUS = null"
                                @keyup="
                                  filtroSelNfseCancelDtInicioUS = valida_data(filtrosSelecionados.nfse.dtCancelamentoInicio)
                                    ? formatDateUS(filtrosSelecionados.nfse.dtCancelamentoInicio)
                                    : null
                                "
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="filtroSelNfseCancelDtInicioUS" locale="pt-br" no-title @input="mnfiltroSelNfseCancelDtInicioUS = false"></v-date-picker>
                          </v-menu>
                        </v-flex>
                        <v-flex xs12 md6>
                          <v-menu
                            ref="mnfiltroSelNfseCancelDtFimUS"
                            v-model="mnfiltroSelNfseCancelDtFimUS"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="filtrosSelecionados.nfse.dtCancelamentoFim"
                                label="Fim"
                                v-mask="'##/##/####'"
                                :rules="[value => valida_data(value) || 'Data inválida']"
                                color="blue-grey lighten-2"
                                clearable
                                prepend-icon="event"
                                v-on="on"
                                autocomplete="disabled"
                                :name="Math.random()"
                                @click:clear="filtroSelNfseCancelDtFimUS = null"
                                @keyup="
                                  filtroSelNfseCancelDtFimUS = valida_data(filtrosSelecionados.nfse.dtCancelamentoFim)
                                    ? formatDateUS(filtrosSelecionados.nfse.dtCancelamentoFim)
                                    : null
                                "
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="filtroSelNfseCancelDtFimUS" locale="pt-br" no-title @input="mnfiltroSelNfseCancelDtFimUS = false"></v-date-picker>
                          </v-menu>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-layout>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-layout>
      </v-container>
    </v-form>
  </v-card>
</template>
<script>
import util from "../../util.js";
export default {
  props: {
    prefixo: { type: String, default: "" },
    nfe: { type: Boolean, default: false },
    nfsc: { type: Boolean, default: false },
    nfse: { type: Boolean, default: false }
  },
  data() {
    this.$vuetify.theme.primary = "blue lighten-2";
    return {
      filtrosSelecionados: {
        emissaoNota: null,

        nfe: {
          chave: null,
          dtEmissaoInicio: null,
          dtEmissaoFim: null,
          dtCancelamentoInicio: null,
          dtCancelamentoFim: null,
          serie: null,
          numeroInicio: null,
          numeroFim: null,
          status: null,
          chaveRef: null,
          serieRef: null,
          numeroRefInicio: null,
          numeroRefFim: null
        },

        nfsc: {
          anoMes: null,
          dtEmissaoInicio: null,
          dtEmissaoFim: null,
          dtCancelamentoInicio: null,
          dtCancelamentoFim: null,
          serie: null,
          numeroInicio: null,
          numeroFim: null,
          situacao: null
        },

        nfse: {
          dtEmissaoInicio: null,
          dtEmissaoFim: null,
          dtCancelamentoInicio: null,
          dtCancelamentoFim: null,
          serie: null,
          rpsInicio: null,
          rpsFim: null,
          numeroInicio: null,
          numeroFim: null,
          status: null
        }
      },

      filtroSelNfseCancelDtInicioUS: null,
      mnfiltroSelNfseCancelDtInicioUS: false,

      filtroSelNfseCancelDtFimUS: null,
      mnfiltroSelNfseCancelDtFimUS: false,

      filtroSelNfseDtEmissaoInicioUS: null,
      mnfiltroSelNfseDtEmissaoInicioUS: false,

      filtroSelNfseDtEmissaoFimUS: null,
      mnfiltroSelNfseDtEmissaoFimUS: false,

      filtroSelNfscCancelDtInicialUS: null,
      mnfiltroSelNfscCancelDtInicialUS: false,

      filtroSelNfscCancelDtFimUS: null,
      mnfiltroSelNfscCancelDtFimUS: false,

      filtroSelNfscDtEmissaoInicioUS: null,
      mnfiltroSelNfscDtEmissaoInicioUS: false,

      filtroSelNfscDtEmissaoFimUS: null,
      mnfiltroSelNfscDtEmissaoFimUS: false,

      filtroSelNfeDtCancelInicioUS: null,
      mnfiltroSelNfeDtCancelInicioUS: false,

      filtroSelNfeDtCancelFimUS: null,
      mnfiltroSelNfeDtCancelFimUS: false,

      filtroSelNfeDtEmissaoInicioUS: null,
      mnfiltroSelNfeDtEmissaoInicioUS: false,

      filtroSelNfeDtEmissaoFimUS: null,
      mnfiltroSelNfeDtEmissaoFimUS: false,

      nfeDefault: {
        chave: null,
        dtEmissaoInicio: null,
        dtEmissaoFim: null,
        dtCancelamentoInicio: null,
        dtCancelamentoFim: null,
        serie: null,
        numeroInicio: null,
        numeroFim: null,
        status: null,
        chaveRef: null,
        serieRef: null,
        numeroRefInicio: null,
        numeroRefFim: null
      },

      nfscDefault: {
        anoMes: null,
        dtEmissaoInicio: null,
        dtEmissaoFim: null,
        dtCancelamentoInicio: null,
        dtCancelamentoFim: null,
        serie: null,
        numeroInicio: null,
        numeroFim: null,
        situacao: null
      },

      nfseDefault: {
        dtEmissaoInicio: null,
        dtEmissaoFim: null,
        dtCancelamentoInicio: null,
        dtCancelamentoFim: null,
        serie: null,
        rpsInicio: null,
        rpsFim: null,
        numeroInicio: null,
        numeroFim: null,
        status: null
      },

      itensEmissaoNota: [
        { id: 1, nome: "Emitidas" },
        { id: 0, nome: "Não Emitidas" },
        { id: null, nome: "Ambas" }
      ],

      itensNFeStatus: [
        "Emitir",
        "Cancelar",
        "Devolver",
        "Estornar",
        "Processada",
        "Cancelada",
        "Devolução",
        "Estorno",
        "Devolvida",
        "Estornada",
        "Erro",
        "ErroDevolver",
        "ErroEstornar",
        "Denegada"
        //"Inutilizada"
      ],

      itensSituacaoNFSC: [
        { id: "N", nome: "Normal" },
        { id: "S", nome: "Cancelada" }
      ],

      itensNFSeStatus: ["Emitir", "Enviada", "Erro", "Processada", "Cancelada", "Alterada"],
      id: "",
      painel: [0, 0, 0],

      autoUpdate: true
    };
  },

  methods: {
    filtroUrlParametros() {
      var idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
      var emissaoNota = "";
      var filtroNFe = "";
      var filtroNFSC = "";
      var filtroNFSe = "";

      //* Verifica os campos que foram preenchidos
      if (this.filtrosSelecionados.emissaoNota != null) {
        emissaoNota = emissaoNota + "&" + this.prefixo + "emissaoNota=" + this.filtrosSelecionados.emissaoNota;
      }

      //* Monta o filtro para NFe
      if (this.filtrosSelecionados.nfe) {
        if (this.filtrosSelecionados.nfe.chave) {
          filtroNFe = filtroNFe + "&" + this.prefixo + "nfeChave=" + this.filtrosSelecionados.nfe.chave;
        }

        if (this.filtrosSelecionados.nfe.dtEmissaoInicio) {
          filtroNFe = filtroNFe + "&" + this.prefixo + "nfeDtEmissaoInicio=" + this.filtrosSelecionados.nfe.dtEmissaoInicio;
        }

        if (this.filtrosSelecionados.nfe.dtEmissaoFim) {
          filtroNFe = filtroNFe + "&" + this.prefixo + "nfeDtEmissaoFim=" + this.filtrosSelecionados.nfe.dtEmissaoFim;
        }

        if (this.filtrosSelecionados.nfe.dtCancelamentoInicio) {
          filtroNFe = filtroNFe + "&" + this.prefixo + "nfeDtCancelamentoInicio=" + this.filtrosSelecionados.nfe.dtCancelamentoInicio;
        }

        if (this.filtrosSelecionados.nfe.dtCancelamentoFim) {
          filtroNFe = filtroNFe + "&" + this.prefixo + "nfeDtCancelamentoFim=" + this.filtrosSelecionados.nfe.dtCancelamentoFim;
        }

        if (this.filtrosSelecionados.nfe.serie) {
          filtroNFe = filtroNFe + "&" + this.prefixo + "nfeSerie=" + this.filtrosSelecionados.nfe.serie;
        }

        if (this.filtrosSelecionados.nfe.numeroInicio) {
          filtroNFe = filtroNFe + "&" + this.prefixo + "nfeNumeroInicio=" + this.filtrosSelecionados.nfe.numeroInicio;
        }

        if (this.filtrosSelecionados.nfe.numeroFim) {
          filtroNFe = filtroNFe + "&" + this.prefixo + "nfeNumeroFim=" + this.filtrosSelecionados.nfe.numeroFim;
        }

        if (this.filtrosSelecionados.nfe.status && this.filtrosSelecionados.nfe.status.length) {
          filtroNFe = filtroNFe + "&" + this.prefixo + "nfeStatus=" + this.filtrosSelecionados.nfe.status.join(",");
        }

        if (this.filtrosSelecionados.nfe.chaveRef) {
          filtroNFe = filtroNFe + "&" + this.prefixo + "nfeChaveRef=" + this.filtrosSelecionados.nfe.chaveRef;
        }

        if (this.filtrosSelecionados.nfe.serieRef) {
          filtroNFe = filtroNFe + "&" + this.prefixo + "nfeSerieRef=" + this.filtrosSelecionados.nfe.serieRef;
        }

        if (this.filtrosSelecionados.nfe.numeroRefInicio) {
          filtroNFe = filtroNFe + "&" + this.prefixo + "nfeNumeroRefInicio=" + this.filtrosSelecionados.nfe.numeroRefInicio;
        }

        if (this.filtrosSelecionados.nfe.numeroRefFim) {
          filtroNFe = filtroNFe + "&" + this.prefixo + "nfeNumeroRefFim=" + this.filtrosSelecionados.nfe.numeroRefFim;
        }

        if (filtroNFe) {
          filtroNFe = filtroNFe + "&" + this.prefixo + "nfeIdSite=" + idSitePrincipal;
        }
      }

      //* Monta o filtro para NFSC
      if (this.filtrosSelecionados.nfsc) {
        if (this.filtrosSelecionados.nfsc.anoMes) {
          var anoMes = "";
          try {
            anoMes = this.filtrosSelecionados.nfsc.anoMes.substring(5) + this.filtrosSelecionados.nfsc.anoMes.substring(0, 2);
          } catch (e) {
            anoMes = "";
          }
          if (anoMes) {
            filtroNFSC = filtroNFSC + "&" + this.prefixo + "nfscAnoMes=" + anoMes;
          }
        }

        if (this.filtrosSelecionados.nfsc.dtEmissaoInicio) {
          filtroNFSC = filtroNFSC + "&" + this.prefixo + "nfscDtEmissaoInicio=" + this.filtrosSelecionados.nfsc.dtEmissaoInicio;
        }

        if (this.filtrosSelecionados.nfsc.dtEmissaoFim) {
          filtroNFSC = filtroNFSC + "&" + this.prefixo + "nfscDtEmissaoFim=" + this.filtrosSelecionados.nfsc.dtEmissaoFim;
        }

        if (this.filtrosSelecionados.nfsc.dtCancelamentoInicio) {
          filtroNFSC = filtroNFSC + "&" + this.prefixo + "nfscDtCancelamentoInicio=" + this.filtrosSelecionados.nfsc.dtCancelamentoInicio;
        }

        if (this.filtrosSelecionados.nfsc.dtCancelamentoFim) {
          filtroNFSC = filtroNFSC + "&" + this.prefixo + "nfscDtCancelamentoFim=" + this.filtrosSelecionados.nfsc.dtCancelamentoFim;
        }

        if (this.filtrosSelecionados.nfsc.serie) {
          filtroNFSC = filtroNFSC + "&" + this.prefixo + "nfscSerie=" + this.filtrosSelecionados.nfsc.serie;
        }

        if (this.filtrosSelecionados.nfsc.numeroInicio) {
          filtroNFSC = filtroNFSC + "&" + this.prefixo + "nfscNumeroInicio=" + this.filtrosSelecionados.nfsc.numeroInicio;
        }

        if (this.filtrosSelecionados.nfsc.numeroFim) {
          filtroNFSC = filtroNFSC + "&" + this.prefixo + "nfscNumeroFim=" + this.filtrosSelecionados.nfsc.numeroFim;
        }

        if (this.filtrosSelecionados.nfsc.situacao) {
          filtroNFSC = filtroNFSC + "&" + this.prefixo + "nfscSituacao=" + this.filtrosSelecionados.nfsc.situacao;
        }

        if (filtroNFSC) {
          filtroNFSC = filtroNFSC + "&" + this.prefixo + "nfscIdSite=" + idSitePrincipal;
        }
      }

      //* Monta o filtro para NFSe
      if (this.filtrosSelecionados.nfse) {
        if (this.filtrosSelecionados.nfse.dtEmissaoInicio) {
          filtroNFSe = filtroNFSe + "&" + this.prefixo + "nfseDtEmissaoInicio=" + this.filtrosSelecionados.nfse.dtEmissaoInicio;
        }

        if (this.filtrosSelecionados.nfse.dtEmissaoFim) {
          filtroNFSe = filtroNFSe + "&" + this.prefixo + "nfseDtEmissaoFim=" + this.filtrosSelecionados.nfse.dtEmissaoFim;
        }

        if (this.filtrosSelecionados.nfse.dtCancelamentoInicio) {
          filtroNFSe = filtroNFSe + "&" + this.prefixo + "nfseDtCancelamentoInicio=" + this.filtrosSelecionados.nfse.dtCancelamentoInicio;
        }

        if (this.filtrosSelecionados.nfse.dtCancelamentoFim) {
          filtroNFSe = filtroNFSe + "&" + this.prefixo + "nfseDtCancelamentoFim=" + this.filtrosSelecionados.nfse.dtCancelamentoFim;
        }

        if (this.filtrosSelecionados.nfse.serie) {
          filtroNFSe = filtroNFSe + "&" + this.prefixo + "nfseSerie=" + this.filtrosSelecionados.nfse.serie;
        }

        if (this.filtrosSelecionados.nfse.rpsInicio) {
          filtroNFSe = filtroNFSe + "&" + this.prefixo + "nfseRpsInicio=" + this.filtrosSelecionados.nfse.rpsInicio;
        }

        if (this.filtrosSelecionados.nfse.rpsFim) {
          filtroNFSe = filtroNFSe + "&" + this.prefixo + "nfseRpsFim=" + this.filtrosSelecionados.nfse.rpsFim;
        }

        if (this.filtrosSelecionados.nfse.numeroInicio) {
          filtroNFSe = filtroNFSe + "&" + this.prefixo + "nfseNumeroInicio=" + this.filtrosSelecionados.nfse.numeroInicio;
        }

        if (this.filtrosSelecionados.nfse.numeroFim) {
          filtroNFSe = filtroNFSe + "&" + this.prefixo + "nfseNumeroFim=" + this.filtrosSelecionados.nfse.numeroFim;
        }

        if (this.filtrosSelecionados.nfse.status && this.filtrosSelecionados.nfse.status.length) {
          filtroNFSe = filtroNFSe + "&" + this.prefixo + "nfseStatus=" + this.filtrosSelecionados.nfse.status.join(",");
        }

        if (filtroNFSe) {
          filtroNFSe = filtroNFSe + "&" + this.prefixo + "nfseIdSite=" + idSitePrincipal;
        }
      }

      return emissaoNota + filtroNFe + filtroNFSC + filtroNFSe;
    },

    formatDateUS(date) {
      if (date && date.length == 10) {
        return util.formatDateUS(date);
      }
    },
    valida_data(date) {
      if (date) {
        return util.valida_data_BR(date);
      } else {
        return true;
      }
    },

    limpar() {
      this.filtrosSelecionados.emissaoNota = null;
      this.filtrosSelecionados.nfe = JSON.parse(JSON.stringify(this.nfeDefault));
      this.filtrosSelecionados.nfsc = JSON.parse(JSON.stringify(this.nfscDefault));
      this.filtrosSelecionados.nfse = JSON.parse(JSON.stringify(this.nfseDefault));
      this.painel = [0, 0, 0];
      this.filtroSelNfseCancelDtInicioUS = null;
      this.filtroSelNfseCancelDtFimUS = null;
      this.filtroSelNfseDtEmissaoInicioUS = null;
      this.filtroSelNfseDtEmissaoFimUS = null;
      this.filtroSelNfscCancelDtInicialUS = null;
      this.filtroSelNfscCancelDtFimUS = null;
      this.filtroSelNfscDtEmissaoInicioUS = null;
      this.filtroSelNfscDtEmissaoFimUS = null;
      this.filtroSelNfeDtCancelInicioUS = null;
      this.filtroSelNfeDtCancelFimUS = null;
      this.filtroSelNfeDtEmissaoInicioUS = null;
      this.filtroSelNfeDtEmissaoFimUS = null;
    },
    //* Chamadas a métodos públicos
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    filtrarItens(item, queryText, itemText) {
      return util.filtrarItens(item, queryText, itemText);
    }
  },

  watch: {
    filtroSelNfseCancelDtInicioUS() {
      this.filtrosSelecionados.nfse.dtCancelamentoInicio = util.formatDate(this.filtroSelNfseCancelDtInicioUS);
    },
    filtroSelNfseCancelDtFimUS() {
      this.filtrosSelecionados.nfse.dtCancelamentoFim = util.formatDate(this.filtroSelNfseCancelDtFimUS);
    },
    filtroSelNfseDtEmissaoInicioUS() {
      this.filtrosSelecionados.nfse.dtEmissaoInicio = util.formatDate(this.filtroSelNfseDtEmissaoInicioUS);
      this.mnfiltroSelNfseDtEmissaoInicioUS = false;
    },
    filtroSelNfseDtEmissaoFimUS() {
      this.filtrosSelecionados.nfse.dtEmissaoFim = util.formatDate(this.filtroSelNfseDtEmissaoFimUS);
      this.mnfiltroSelNfseDtEmissaoFimUS = false;
    },
    filtroSelNfscCancelDtInicialUS() {
      this.filtrosSelecionados.nfsc.dtCancelamentoInicio = util.formatDate(this.filtroSelNfscCancelDtInicialUS);
      this.mnfiltroSelNfscCancelDtInicialUS = false;
    },
    filtroSelNfscCancelDtFimUS() {
      this.filtrosSelecionados.nfsc.dtCancelamentoFim = util.formatDate(this.filtroSelNfscCancelDtFimUS);
      this.mnfiltroSelNfscCancelDtFimUS = false;
    },
    filtroSelNfscDtEmissaoInicioUS() {
      this.filtrosSelecionados.nfsc.dtEmissaoInicio = util.formatDate(this.filtroSelNfscDtEmissaoInicioUS);
      this.mnfiltroSelNfscDtEmissaoInicioUS = false;
    },
    filtroSelNfscDtEmissaoFimUS() {
      this.filtrosSelecionados.nfsc.dtEmissaoFim = util.formatDate(this.filtroSelNfscDtEmissaoFimUS);
      this.mnfiltroSelNfscDtEmissaoFimUS = false;
    },
    filtroSelNfeDtCancelInicioUS() {
      this.filtrosSelecionados.nfe.dtCancelamentoInicio = util.formatDate(this.filtroSelNfeDtCancelInicioUS);
      this.mnfiltroSelNfeDtCancelInicioUS = false;
    },
    filtroSelNfeDtCancelFimUS() {
      this.filtrosSelecionados.nfe.dtCancelamentoFim = util.formatDate(this.filtroSelNfeDtCancelFimUS);
      this.mnfiltroSelNfeDtCancelFimUS = false;
    },
    filtroSelNfeDtEmissaoInicioUS() {
      this.filtrosSelecionados.nfe.dtEmissaoInicio = util.formatDate(this.filtroSelNfeDtEmissaoInicioUS);
      this.mnfiltroSelNfeDtEmissaoInicioUS = false;
    },
    filtroSelNfeDtEmissaoFimUS() {
      this.filtrosSelecionados.nfe.dtEmissaoFim = util.formatDate(this.filtroSelNfeDtEmissaoFimUS);
      this.mnfiltroSelNfeDtEmissaoFimUS = false;
    }
  }
};
</script>
