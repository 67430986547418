<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">format_list_numbered</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">
        Seção
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear
              :active="isUpdating"
              class="ma-0"
              color="cyan accent-3"
              height="3"
              indeterminate
            ></v-progress-linear>
          </v-flex>

          <v-flex xs12 md12 v-if="siteGrupo.length > 0">
            <v-autocomplete
              v-model="secao.idSite"
              :disabled="id || isUpdating || this.secao.idSuperior != null"
              :items="siteGrupo"
              :filter="filtrarObjetos"
              label="Restrita ao Site"
              item-text="nome"
              item-value="id"
              :rules="[validacao.idSite == false || validacao.idSite]"
              autocomplete="disabled"
              :name="Math.random()"
              no-data-text
              clearable
              @change="validacao.idSite = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12>
            <v-text-field
              v-model="hierarquia"
              label="Hierarquia"
              disabled
              color="blue-grey lighten-2"
            ></v-text-field>
            <v-subheader class="red--text" v-if="maesInativas">{{
              maesInativas
            }}</v-subheader>
          </v-flex>

          <v-flex xs12 md10>
            <v-text-field
              autofocus
              v-model="secao.nome"
              label="Nome"
              maxlength="60"
              hint="Obrigatório"
              :rules="[
                validacao.nome == false || validacao.nome,
                value => !!value || 'Obrigatório'
              ]"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="
                secao.nome = pLetra(secao.nome);
                validacao.nome = false;
              "
            ></v-text-field>
          </v-flex>

          <v-flex xs6 md2>
            <v-text-field
              v-model="secao.ordem"
              label="Ordem"
              v-mask="'#######'"
              hint="Obrigatório - ordem desta secao dentro da hierarquia acima: 1,2..."
              :rules="[
                validacao.ordem == false || validacao.ordem,
                value =>
                  !!value ||
                  'Obrigatório - ordem desta secao dentro da hierarquia acima: 1,2...'
              ]"
              color="blue-grey lighten-2"
              @change="validacao.ordem = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md12>
            <v-textarea
              v-model="secao.comandosEstilos"
              label="Estilos"
              maxlength="4000"
              counter="4000"
              rows="6"
              :rules="[
                validacao.comandosEstilos == false || validacao.comandosEstilos,
                value =>
                  !value ||
                  (value.length > 10 && value.length < 4000) ||
                  'Descrição dos estilos deve ter entre 10 e 4000 caracteres'
              ]"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.comandosEstilos = false"
            ></v-textarea>
          </v-flex>

          <v-flex xs12 md12>
            <v-text-field
              v-model="secao.caminhoSelo"
              label="Caminho do Selo"
              maxlength="1024"
              :rules="[validacao.caminhoSelo == false || validacao.caminhoSelo]"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.caminhoSelo = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="secao.altura"
              label="Altura"
              :allowNegative="false"
              :decimalLength="3"
              :max="60"
              :rules="[
                validacao.altura == false || validacao.altura,
                value =>
                  (value &&
                    parseFloat(value.replace('.', '').replace(',', '.')) <=
                      60) ||
                  'Verifique a altura'
              ]"
              :disabled="isUpdating"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.altura = false"
            ></v-currency-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="secao.largura"
              label="Largura"
              hint="Obrigatório"
              :allowNegative="false"
              :max="30"
              :rules="[
                validacao.largura == false || validacao.largura,
                value =>
                  (value &&
                    parseFloat(value.replace('.', '').replace(',', '.')) <=
                      30) ||
                  'Verifique a largura'
              ]"
              :disabled="isUpdating"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.largura = false"
            ></v-currency-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-switch
              v-model="secao.titulo"
              :label="`${secao.titulo == 1 ? 'Título' : 'Final'}`"
              :disabled="(secao.id != '' && secao.filhas > 0) || final"
            ></v-switch>
          </v-flex>

          <v-flex xs6 md3>
            <v-switch
              v-model="secao.situacao"
              :label="`${secao.situacao == 1 ? 'Ativa' : 'Inativa'}`"
            ></v-switch>
          </v-flex>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">{{
              validacao.mensagemErro
            }}</v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn
        to="/comercial/secoes"
        color="orange lighten-2 white"
        id="btn-rsn"
        style="color:white"
      >
        <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar
      </v-btn>
      <v-btn
        :loading="isUpdating"
        color="blue white"
        id="btn-rsn"
        style="color:white"
        depressed
        @click="salvar('false')"
      >
        <v-icon id="icon-rsn" left>update</v-icon>Salvar
      </v-btn>
      <v-btn
        :loading="isUpdating"
        color="orange white"
        style="color:white"
        id="btn-rsn"
        outlined
        @click="salvar('retornar')"
      >
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>
      <v-btn
        :loading="isUpdating"
        color="blue white"
        id="btn-rsn"
        style="color:white"
        outlined
        @click="salvar('true')"
        v-if="direitoCriar"
      >
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Nova
      </v-btn>
    </v-card-actions>
    <v-snackbar
      v-model="snackbar"
      color="info"
      :timeout="5000"
      relative
      bottom
      left
      >{{ mensagem }}</v-snackbar
    >
    <v-snackbar
      v-model="snackErro"
      color="error"
      :timeout="5000"
      relative
      bottom
      left
      >{{ mensagem }}</v-snackbar
    >
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      secao: {
        id: "",
        idSite: null,
        idSuperior: null,
        ordem: 0,
        nome: "",
        comandosEstilos: "",
        altura: 0,
        largura: 0,
        titulo: 0,
        caminhoSelo: "",
        situacao: 1
      },
      validacao: {
        id: false,
        idSite: false,
        idSuperior: false,
        ordem: false,
        nome: false,
        comandosEstilos: false,
        altura: false,
        largura: false,
        titulo: false,
        caminhoSelo: false,
        situacao: false,
        mensagemErro: false
      },

      hierarquia: "",
      siteGrupo: [],

      estilosPadrao: [
        `<TableStyle:TAB_TITULO_1>
<TableStart:1,1:0:0<tCellDefaultCellType:Text>>
<ColStart:<tColAttrWidth:88>>
<RowStart:<tRowAttrHeight:62>>
<CellStyle:CEL_TITULO_1>
<StylePriority:0>
<CellStart:1,1>
<ParaStyle:PAR_TITULO_1>
<$TEXTO>
<CellEnd:>
<RowEnd:>
<TableEnd:>`,
        `<TableStyle:TAB_TITULO_2>
<TableStart:1,1:0:0<tCellDefaultCellType:Text>>
<ColStart:<tColAttrWidth:88>>
<RowStart:<tRowAttrHeight:62>>
<CellStyle:CEL_TITULO_2>
<StylePriority:0>
<CellStart:1,1>
<ParaStyle:PAR_TITULO_2>
<$TEXTO>
<CellEnd:>
<RowEnd:>
<TableEnd:>`,
        `<TableStyle:TAB_TITULO_3>
<TableStart:1,1:0:0<tCellDefaultCellType:Text>>
<ColStart:<tColAttrWidth:88>>
<RowStart:<tRowAttrHeight:62>>
<CellStyle:CEL_TITULO_3>
<StylePriority:0>
<CellStart:1,1>
<ParaStyle:PAR_TITULO_3>
<$TEXTO>
<CellEnd:>
<RowEnd:>
<TableEnd:>`
      ],

      final: false,
      idSitePrincipal: null,
      maesInativas: "",
      direitoCriar: false,
      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,
      isUpdating: false
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem =
          "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      this.isUpdating = true;
      try {
        var retorno = "";
        //se tiver id é um update
        if (this.id) {
          retorno = await api.put(
            process.env.VUE_APP_URL_WEB_API + "com/secao/" + this.id,
            this.secao
          );
        } else {
          retorno = await api.post(
            process.env.VUE_APP_URL_WEB_API +
              "com/secao?idSite=" +
              this.idSitePrincipal,
            this.secao
          );
          this.id = retorno.data.id;
          this.secao.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "true") {
          this.$router.push(
            "/comercial/secao" + (this.mae ? "/" + this.mae : "")
          ); //para alterar a url
          this.$router.go(
            "/comercial/secao" + (this.mae ? "/" + this.mae : "")
          ); //para recarregar a página
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push("/comercial/secoes"); //para alterar a url
          }, 2000);
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem =
            "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem =
            "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },
    async carregarHierarquia() {
      //* Carrega a hierarquia da secao - nomes das seções mãe
      var idMae = this.secao.idSuperior;
      var itens = [];
      this.maesInativas = "";
      if (idMae) {
        while (idMae) {
          const responseMae = await api.get(
            process.env.VUE_APP_URL_WEB_API +
              "com/secao/" +
              idMae +
              "?idSite=" +
              this.idSitePrincipal
          );
          if (responseMae.data) {
            if (!responseMae.data.situacao) {
              this.maesInativas =
                responseMae.data.nome +
                (this.maesInativas ? ", " : "") +
                this.maesInativas;
            }
            idMae = responseMae.data.idSuperior;
            this.secao.idSite = responseMae.data.idSite; //* Todas as seções filhas estarão no mesmo site da secao de primeiro nível
            itens.push(responseMae.data.nome);
            if (!this.secao.ordem) {
              this.secao.ordem = parseInt(responseMae.data.filhas) + 1;
            }
          } else {
            idMae = null;
          }
        }
        if (this.maesInativas) {
          if (this.maesInativas.split(",").length == 1) {
            this.maesInativas = "Seção " + this.maesInativas + " está INATIVA";
          } else {
            this.maesInativas =
              "Seções " + this.maesInativas + " estão INATIVAS";
          }
        }
      } else {
        const responseMaes = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "com/secao/maes?idSite=" +
            this.idSitePrincipal
        );
        if (responseMaes.data) {
          if (!this.secao.ordem) {
            this.secao.ordem = parseInt(responseMaes.data.filhas) + 1;
          }
        }
      }
      itens.forEach(element => {
        this.hierarquia = element + "\\" + this.hierarquia;
      });

      if (this.hierarquia.split("\\").length > 2) {
        //* Só permite 3 níveis, então a terceira tem que ser final
        this.secao.titulo = 0;
        this.final = true;
      }
      if (!this.id) {
        if (
          this.estilosPadrao.length >=
          this.hierarquia.split("\\").length - 1
        ) {
          this.secao.comandosEstilos = this.estilosPadrao[
            this.hierarquia.split("\\").length - 1
          ];
        }
      }
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    pLetra(text) {
      return util.pLetra(text);
    }
  },

  computed: {
    mae() {
      return this.$route.params.mae;
    }
  },

  async created() {
    this.id = this.$route.params.id ? this.$route.params.id : "";
    if (this.id == "null") {
      this.id = "";
    }

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito =
        element.modulo + "_" + element.grupo + "_" + element.direito;
      if (
        direito == "WEBSUITA_ADMIN_SUITA" ||
        direito == "COMERCIAL_SEÇÕES_CRIAR"
      ) {
        this.direitoCriar = true;
      }
    });
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

    //* Verifica se o site do usuário está em GRUPO - OBS: ou ele compartilha a informação ou deixa exclusiva para o seu site
    var idGrupoSite = this.$store.state.usuario.site_idGrupo;
    if (idGrupoSite != null) {
      this.siteGrupo = [
        {
          id: this.idSitePrincipal,
          nome: this.$store.state.usuario.site_nome
        }
      ];
    }

    try {
      //* Se é edição, carrega o elemento
      if (this.id == "") {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "COMERCIAL_SEÇÕES_CRIAR"]);
        this.secao.idSuperior = this.mae
          ? this.mae == "null"
            ? null
            : this.mae
          : null;
        this.secao.titulo = this.mae && this.mae != "null" ? 0 : 1;
        this.carregarHierarquia();
      } else {
        this.POSSUI_DIREITO([
          "WEBSUITA_ADMIN_SUITA",
          "COMERCIAL_SEÇÕES_EDITAR"
        ]);
        //* Carrega o elemento - somente se for público (idSite=null) ou for do site do usuário logado
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "com/secao/" +
            this.id +
            "?idSite=" +
            this.idSitePrincipal
        );
        if (!response.data) {
          this.$router.push("/comercial/secoes");
        } else {
          this.secao = response.data;
          //* OBS:
          //Se não é seção título, pode se tornar, somente se não houverem anúncios vinculados
          //Se é seção título, só pode se tornar final se não existirem filhas vinculadas

          this.carregarHierarquia();
        }
      }
    } catch {
      this.mensagem = "Ocorreu um erro ao carregar os dados";
      this.snackErro = true;
    }
  }
};
</script>
