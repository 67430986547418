<template>
  <div id="app">
    <v-app id="inspire">
      <v-card>
        <v-toolbar color="#333333">
          <v-icon color="white" class="mr-2">format_list_numbered</v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light">
            Seções
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <router-link
            v-if="direitoCriar"
            to="/comercial/secao/null/null"
            style="text-decoration: none"
          >
            <v-btn v-if="direitoCriar" color="success" fab small>
              <v-icon color="white" title="Incluir">add</v-icon>
            </v-btn>
          </router-link>
        </v-toolbar>
        <div class="px-4 mt-3">
          <v-layout wrap>
            <v-spacer></v-spacer>
            <v-flex xs12 md5>
              <v-text-field
                background-color="white"
                class="mt-2 mb-2 elevation-0"
                v-model="filtroPesquisa"
                append-icon="search"
                label="Digite para pesquisar..."
                placeholder="Pesquisa pelo nome"
                hide-details
                @keyup.enter="limpaFiltro"
                @click:append="limpaFiltro"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-data-table
            mobile-breakpoint="0"
            :headers="headers"
            :items="registros"
            :options.sync="options"
            :no-data-text="mensagemPesquisa"
            :footer-props="rodape"
            :disable-sort="carregando"
            :disable-pagination="carregando"
          >
            <template v-slot:[getItemActions()]="{ item }">
              <router-link
                :to="'/comercial/secao/' + item.idSuperior + '/' + item.id"
                style="text-decoration: none"
              >
                <v-icon color="blue" title="Editar">edit</v-icon>
              </router-link>
              <v-icon
                v-if="direitoExcluir && !item.filhas"
                color="error"
                title="Remover"
                @click="exclui(item)"
                >delete</v-icon
              >
            </template>

            <template v-slot:[getItemNumero()]="{ item }">
              <span v-html="item.secao"></span>
            </template>

            <template v-slot:[getItemActions2()]="{ item }">
              <router-link
                v-if="item.titulo == 1 && direitoCriar"
                :to="'/comercial/secao/' + item.id"
                style="text-decoration: none"
              >
                <v-icon color="success" title="Incluir">add_circle</v-icon>
              </router-link>
            </template>

            <template v-slot:[getItemTitulo()]="{ item }">
              <span
                v-html="item.titulo == 1 ? 'TÍTULO' : '<strong>Final</strong>'"
              ></span>
            </template>

            <template v-slot:[getItemSituacao()]="{ item }">
              <span
                v-html="
                  item.situacao == 1
                    ? 'Ativo'
                    : item.situacao == 0
                    ? '<strong>Inativo</strong>'
                    : ''
                "
              ></span>
            </template>
          </v-data-table>
          <div class="text-xs-center pt-2 marg-bt">
            <v-pagination
              v-model="paginaAtual"
              :length="totalPaginas"
              :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 11"
              circle
            ></v-pagination>
          </div>
        </div>
        <v-snackbar
          v-model="snackbar"
          color="info"
          :timeout="5000"
          relative
          bottom
          left
          >{{ mensagem }}</v-snackbar
        >
        <v-snackbar
          v-model="snackErro"
          color="error"
          :timeout="5000"
          relative
          bottom
          left
          >{{ mensagem }}</v-snackbar
        >
      </v-card>
      <v-dialog persistent v-model="dialogConfirm" max-width="350">
        <v-card>
          <v-toolbar>
            <span class="headline">Confirmação</span>
          </v-toolbar>
          <v-card-text v-html="msgConfirm"></v-card-text>
          <v-card-actions>
            <v-btn color="red darken-1" text @click="dialogConfirm = false"
              >Não</v-btn
            >
            <v-btn color="green darken-1" text @click="excluir">Sim</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      headers: [
        { text: "Ações", value: "actions" },
        { text: "Seção", value: "numero" },
        { text: "", value: "actions2", divider: "true" },
        { text: "ID", value: "id" },
        { text: "Classe", value: "titulo" },
        { text: "Situação", value: "situacao" }
      ],
      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },
      direitoCriar: false,
      direitoEditar: false,
      direitoExcluir: false,
      mensagemPesquisa: "",
      filtroPesquisa: "",
      registros: [],
      registrosPagina: 50,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,

      dialogConfirm: false,
      msgConfirm: "",
      itemExcluir: null,

      carregando: true,
      ordenacao: "",
      ordem: "",
      snackbar: false,
      snackErro: false,
      mensagem: "",
      options: {}
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    getItemActions() {
      return "item.actions";
    },
    getItemNumero() {
      return "item.numero";
    },
    getItemActions2() {
      return "item.actions2";
    },
    getItemTitulo() {
      return "item.titulo";
    },
    getItemSituacao() {
      return "item.situacao";
    },
    limpaFiltro() {
      this.paginaAtual = 1;
      this.pesquisaDados();
    },
    async pesquisaDados() {
      this.registros = [];
      //sinaliza que está carregando
      this.carregando = true;
      this.mensagemPesquisa = "Carregando...";

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "com/secao?idSite=" +
            this.$store.state.usuario.idSitePrincipal +
            "&pagina=" +
            this.paginaAtual +
            "&tamanho=" +
            this.registrosPagina +
            "&filtro=" +
            this.filtroPesquisa +
            "&ordenacao=" +
            this.ordenacao +
            "&ordem=" +
            this.ordem
        );

        //seta as variaveis com o retorno
        this.registros = response.data.data;

        this.registros.forEach(element => {
          element.secao = "";
          if (element.numero.includes(".")) {
            element.secao = "&emsp;".repeat(
              element.numero.split(".").length - 1
            );
          }
          element.secao += element.numero + " - " + element.nome;
        });
        this.totalRegistros = response.data.total;
        this.totalPaginas = Math.ceil(
          this.totalRegistros / this.registrosPagina
        );
        this.rodape.pageText = this.totalRegistros + " itens";
        //fecha o loading
        this.carregando = false;
        //se não achar registro seta a mensagem senão deixa em branco
        this.mensagemPesquisa =
          this.registros.length > 1 ? "" : "Nenhum registro encontrado";
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else {
          this.mensagemPesquisa = "Erro ao buscar dados: " + e;
        }
      }
    },

    exclui(item) {
      this.itemExcluir = item;
      this.msgConfirm =
        "<br />Tem certeza que deseja EXCLUIR?<br /><span style='color:red'>(esta operação não pode ser desfeita)</span>";
      this.dialogConfirm = true;
    },
    async excluir() {
      this.dialogConfirm = false;

      try {
        await api.delete(
          process.env.VUE_APP_URL_WEB_API + "com/secao/" + this.itemExcluir.id
        );
        this.mensagem = "Item foi excluído";
        this.snackbar = true;
        this.pesquisaDados();
      } catch (e) {
        this.mensagem = e.response.data[0].mensagem;
        this.snackErro = true;
      }
    }
  },

  beforeMount() {
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "COMERCIAL_SEÇÕES_ACESSO"]);
    //* Verifica se o usuário logado possui direito para incluir
    this.$store.state.usuario.direitos.forEach(element => {
      var direito =
        element.modulo + "_" + element.grupo + "_" + element.direito;
      if (
        direito == "WEBSUITA_ADMIN_SUITA" ||
        direito == "COMERCIAL_SEÇÕES_CRIAR"
      ) {
        this.direitoCriar = true;
      }
      if (
        direito == "WEBSUITA_ADMIN_SUITA" ||
        direito == "COMERCIAL_SEÇÕES_EDITAR"
      ) {
        this.direitoEditar = true;
      }
      if (
        direito == "WEBSUITA_ADMIN_SUITA" ||
        direito == "COMERCIAL_SEÇÕES_EXCLUIR"
      ) {
        this.direitoExcluir = true;
      }
    });
  },

  watch: {
    $route() {
      this.paginaAtual = 1;
      this.pesquisaDados();
    },
    paginaAtual() {
      this.pesquisaDados();
    },
    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }
        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }
        this.pesquisaDados();
      }
    }
  }
};
</script>
