import { isDate } from "util";

var util = {
  calc_digitos_posicoes(digitos, posicoes = 10, soma_digitos = 0) {
    digitos = digitos.toString();
    for (var i = 0; i < digitos.length; i++) {
      soma_digitos = soma_digitos + digitos[i] * posicoes;
      posicoes--;
      if (posicoes < 2) {
        posicoes = 9;
      }
    }
    soma_digitos = soma_digitos % 11;
    if (soma_digitos < 2) {
      soma_digitos = 0;
    } else {
      soma_digitos = 11 - soma_digitos;
    }

    var cpf = digitos + soma_digitos;

    return cpf;
  },
  valida_cpf(valor) {
    valor = valor.toString();
    valor = valor.replace(/[^0-9]/g, "");
    var digitos = valor.substr(0, 9);
    var novo_cpf0 = this.calc_digitos_posicoes(digitos);
    var novo_cpf = this.calc_digitos_posicoes(novo_cpf0, 11);
    if (
      novo_cpf === valor &&
      novo_cpf !== "00000000000" &&
      novo_cpf !== "11111111111" &&
      novo_cpf !== "22222222222" &&
      novo_cpf !== "33333333333" &&
      novo_cpf !== "44444444444" &&
      novo_cpf !== "55555555555" &&
      novo_cpf !== "66666666666" &&
      novo_cpf !== "77777777777" &&
      novo_cpf !== "88888888888" &&
      novo_cpf !== "99999999999"
    ) {
      return true;
    } else {
      return false;
    }
  },
  valida_cnpj(valor) {
    valor = valor.toString();
    valor = valor.replace(/[^0-9]/g, "");
    if (parseInt(valor) == 0) {
      return false;
    }
    var cnpj_original = valor;
    var primeiros_numeros_cnpj = valor.substr(0, 12);
    var primeiro_calculo = this.calc_digitos_posicoes(primeiros_numeros_cnpj, 5);
    var segundo_calculo = this.calc_digitos_posicoes(primeiro_calculo, 6);
    var cnpj = segundo_calculo;
    if (cnpj === cnpj_original) {
      return true;
    }
    return false;
  },
  valida_nif(nif) {
    if (!["1", "2", "3", "5", "6", "8"].includes(nif.substr(0, 1)) && !["45", "70", "71", "72", "77", "79", "90", "91", "98", "99"].includes(nif.substr(0, 2))) return false;

    let total = nif[0] * 9 + nif[1] * 8 + nif[2] * 7 + nif[3] * 6 + nif[4] * 5 + nif[5] * 4 + nif[6] * 3 + nif[7] * 2;

    let modulo11 = total - parseInt(total / 11) * 11;
    let comparador = modulo11 == 1 || modulo11 == 0 ? 0 : 11 - modulo11;

    return nif[8] == comparador;
  },
  valida_email(email) {
    const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    // obsoleto: /^[a-zA-Z0-9][a-zA-Z0-9._-]+@([a-zA-Z0-9._-]+\.)[a-zA-Z-0-9]{2,3}$/
    return emailRegexp.test(email);
  },
  valida_url(url) {
    const urlRegexp = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    return url == "http://localhost" || urlRegexp.test(url);
  },
  valida_competencia_futura(compet) {
    var retorno = true;
    if (compet != null && compet != "") {
      var dataAtualAux = new Date();
      var dataAtual = new Date(dataAtualAux.getFullYear(), dataAtualAux.getMonth(), 1);
      var dataLimite = new Date(new Date(dataAtualAux.getFullYear() + 20, dataAtualAux.getMonth(), 1)); //limite para datas: 20 anos
      var x = compet.split("/");
      var confere = new Date(x[1], x[0] - 1, "01");
      var confere2 =
        (confere.getDate() < 10 ? "0" : "") + confere.getDate() + "/" + (confere.getMonth() + 1 < 10 ? "0" : "") + (confere.getMonth() + 1) + "/" + confere.getFullYear();
      compet = "01/" + compet;
      if (!confere || compet != confere2 || confere < dataAtual || confere > dataLimite) {
        retorno = false;
      }
    }
    return retorno;
  },

  limparObjeto(objeto, valor) {
    Object.keys(objeto).map(campo => {
      objeto[campo] = valor;
    });
  },

  validaErros(objeto, erros) {
    //faz um map nas mensagens de retorno
    erros.map(erro => {
      //faz um map no objeto de validação
      Object.keys(objeto).map(campo => {
        //se encontrar o campo na mensagem ele seta a mensagem de erro
        if (erro.campo == campo) {
          if (Array.isArray(erro.mensagem)) {
            objeto[campo] = "";
            erro.mensagem.map(element => {
              //se encontrar o campo na mensagem ele seta a mensagem de erro
              objeto[campo] += (objeto[campo] == "" ? "" : " | ") + element.mensagem;
            });
          }
          if (!objeto[campo]) {
            objeto[campo] = erro.mensagem;
          }
        }
      });
    });
  },

  isDDDvalido(ddd) {
    var retorno = ddd.match("((([1,4,6,8,9][1-9])|(2[1,2,4,7,8])|(3[1-5])|(3[7-8])|(5[1,3,4,5])|(7[1,3,4,5,7,9])))");
    return retorno != null;
  },

  valida_data(date) {
    var retorno = true;
    if (date != null && date != "") {
      date = date.substr(0, 10); //* remove a parte do horário
      var dataAtualAux = new Date();
      var dataLimiteInicio = new Date(new Date(2010, 0, 1));
      var dataLimiteFim = new Date(new Date(dataAtualAux.getFullYear() + 20, dataAtualAux.getMonth(), 1));

      var x = date.split("-");
      var confere = new Date(x[0], x[1] - 1, x[2]);
      var confere2 =
        confere.getFullYear() + "-" + (confere.getMonth() + 1 < 10 ? "0" : "") + (confere.getMonth() + 1) + "-" + (confere.getDate() < 10 ? "0" : "") + confere.getDate();
      if (!confere || date != confere2 || confere < dataLimiteInicio || confere > dataLimiteFim) {
        retorno = false;
      }
    } else {
      retorno = false;
    }
    return retorno;
  },

  valida_data_BR(date) {
    var retorno = true;
    if (date) {
      date = date.substr(0, 10); //* remove a parte do horário
      //limite para datas: 01/01/2010 a hoje+20 anos
      var dataAtualAux = new Date();
      var dataLimiteInicio = new Date(new Date(2010, 0, 1));
      var dataLimiteFim = new Date(new Date(dataAtualAux.getFullYear() + 20, dataAtualAux.getMonth(), 1));

      var x = date.split("/");
      var confere = new Date(x[2], x[1] - 1, x[0]);
      var confere2 =
        (confere.getDate() < 10 ? "0" : "") + confere.getDate() + "/" + (confere.getMonth() + 1 < 10 ? "0" : "") + (confere.getMonth() + 1) + "/" + confere.getFullYear();
      if (!confere || date != confere2 || confere < dataLimiteInicio || confere > dataLimiteFim) {
        retorno = false;
      }
    } else {
      retorno = false;
    }
    return retorno;
  },

  valida_data_nasc_BR(date) {
    var retorno = true;
    if (date) {
      date = date.substr(0, 10); //* remove a parte do horário
      var dataLimite = new Date(new Date(1900, 0, 1)); //limite para datas de nascimento: 01/01/1900

      var x = date.split("/");
      var confere = new Date(x[2], x[1] - 1, x[0]);
      var confere2 =
        (confere.getDate() < 10 ? "0" : "") + confere.getDate() + "/" + (confere.getMonth() + 1 < 10 ? "0" : "") + (confere.getMonth() + 1) + "/" + confere.getFullYear();
      if (!confere || date != confere2 || confere < dataLimite || confere > new Date()) {
        retorno = false;
      }
    }
    return retorno;
  },

  valida_data_futura(date, hoje) {
    var retorno = true;
    if (date != null && date != "") {
      date = date.substr(0, 10); //* remove a parte do horário
      var dataAtual = new Date();
      if (hoje) {
        //* Considera o dia atual
        dataAtual.setDate(dataAtual.getDate() - 1);
      }
      var dataLimite = new Date(new Date(2010, 0, 1)); //limite para datas: 01/01/2010

      var x = date.split("-");
      var confere = new Date(x[0], x[1] - 1, x[2]);
      var confere2 =
        confere.getFullYear() + "-" + (confere.getMonth() + 1 < 10 ? "0" : "") + (confere.getMonth() + 1) + "-" + (confere.getDate() < 10 ? "0" : "") + confere.getDate();
      if (!confere || date != confere2 || confere < dataLimite || confere < dataAtual) {
        retorno = false;
      }
    }
    return retorno;
  },

  valida_data_futura_BR(date, hoje) {
    var retorno = true;
    if (date != null && date != "") {
      date = date.substr(0, 10); //* remove a parte do horário
      var dataAtual = new Date();
      if (hoje) {
        //* Considera o dia atual
        dataAtual.setDate(dataAtual.getDate() - 1);
      }
      var dataLimite = new Date(new Date(2010, 0, 1)); //limite para datas: 01/01/2010

      var x = date.split("/");
      var confere = new Date(x[2], x[1] - 1, x[0]);
      var confere2 =
        (confere.getDate() < 10 ? "0" : "") + confere.getDate() + "/" + (confere.getMonth() + 1 < 10 ? "0" : "") + (confere.getMonth() + 1) + "/" + confere.getFullYear();
      if (!confere || date != confere2 || confere < dataLimite || confere < dataAtual) {
        retorno = false;
      }
    }
    return retorno;
  },

  valida_data_passada_BR(date, hoje, limitar) {
    var retorno = true;
    if (date != null && date != "") {
      date = date.substr(0, 10); //* remove a parte do horário
      var dataAtual = new Date();
      if (!hoje) {
        //* Não considera o dia atual
        dataAtual.setDate(dataAtual.getDate() - 1);
      }
      var dataLimite = null;
      if (limitar) {
        dataLimite = new Date(new Date(2010, 0, 1)); //limite para datas de quitação: 01/01/2010
      } else {
        dataLimite = new Date(new Date(1900, 0, 1)); //limite para datas de criação: 01/01/1900 (pois DP tem base antiga)
      }

      var x = date.split("/");
      var confere = new Date(x[2], x[1] - 1, x[0]);
      var confere2 =
        (confere.getDate() < 10 ? "0" : "") + confere.getDate() + "/" + (confere.getMonth() + 1 < 10 ? "0" : "") + (confere.getMonth() + 1) + "/" + confere.getFullYear();
      if (!confere || date != confere2 || confere < dataLimite || confere > dataAtual) {
        retorno = false;
      }
    }
    return retorno;
  },

  valida_hora(hora) {
    if (hora && hora.length == 5 && parseInt(hora.substring(0, 2)) <= 23 && parseInt(hora.substring(3, 5)) <= 59) {
      return true;
    } else {
      return false;
    }
  },

  valida_nome(text) {
    if (text == "") {
      //* Não obriga o preenchimento, apenas verifica se não possui caracteres estranhos
      return true;
    }
    const textRegexp = /^[A-Za-záàâãéèêíïóôõöúüçñÁÀÂÃÉÈÍÏÓÔÕÖÚÜÇÑ'ºª&/.\- \d]+$/;
    return textRegexp.test(text);
  },

  pLetra(text) {
    if (!text || text === "" || text.trim() === "") {
      return "";
    }

    //* Remove excesso de espaços em branco
    text = text.trim();
    text = text.replace(/[–]/g, "-");
    text = text.replace(/\s\s+/g, " ");
    text = text.replace(/[ ]/g, " ");
    text = text.replace(/[Ɵ]/g, "");

    if (text !== text.toLowerCase() && text !== text.toUpperCase()) {
      //* Só irá converter se todos os caracteres estiverem em minúsculos ou maiúsculos (para permitir manter uma correção, ex: APM - Associação de Pais e Mestres)
      return text;
    }
    if (text === text.toUpperCase()) {
      //* Para padronização do sistema, não permite nomes totalmente em maiúsculas
      text = text.toLowerCase();
    }
    var words = text.split(" ");
    for (var a = 0; a < words.length; a++) {
      //* Converte primeira letra de cada palavra em maiúsculo, ignorando os artigos e conjunções abaixo
      var w = words[a];
      words[a] = w[0].toUpperCase() + w.slice(1).toLowerCase();
      if (w === "da" || w === "de" || w === "do" || w === "das" || w === "dos" || w === "a" || w === "e" || w === "o" || w === "com" || w === "na" || w === "em" || w === "no") {
        words[a] = w;
      }
      if (w === w.toUpperCase()) {
        //* Mantém siglas em maiúsculas
        words[a] = w;
      }
    }
    return words.join(" ");
  },
  formatDDMM(date) {
    try {
      if (date && isDate(date)) {
        date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      }
      if (!date) return null;
      if (date.indexOf("T")) {
        date = date.split("T")[0];
      }
      if (date.indexOf(" ")) {
        date = date.split(" ")[0];
      }
      const partes = date.split("-");
      return `${partes[2].padStart(2, "0")}/${partes[1].padStart(2, "0")}`;
    } catch {
      return null;
    }
  },
  formatMMAAAA(anomes) {
    try {
      if (!anomes) return null;
      const partes = anomes.split("-");
      return `${partes[1].padStart(2, "0")}/${partes[0].padStart(4, "0")}`;
    } catch {
      return null;
    }
  },
  formatDate(date) {
    try {
      if (date && isDate(date)) {
        date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      }
      if (!date) return null;
      if (date.indexOf("T")) {
        date = date.split("T")[0];
      }
      if (date.indexOf(" ")) {
        date = date.split(" ")[0];
      }
      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    } catch {
      return null;
    }
  },
  formatDateUS(date) {
    if (!date) return null;
    const [day, month, year] = date.split("/");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  },
  parseDate(date) {
    if (!date || date.length < 10) return null;
    date = date.substr(0, 10); //* remove a parte do horário
    const [day, month, year] = date.split("/");
    return new Date(year, month - 1, day);
  },
  parseDateTime(datetime) {
    if (!datetime) return null;
    var date = datetime.split(" ")[0];
    var time = datetime.split(" ")[1];
    const [day, month, year] = date.split("/");
    const [hour, minute, second] = time.split(":");
    return new Date(year, month - 1, day, hour, minute, second);
  },

  parseDateUS(date) {
    if (!date) return null;
    if (isDate(date)) {
      date = date.getFullYear() + "-" + (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1) + "-" + (date.getDate() < 10 ? "0" : "") + date.getDate();
    }
    date = date.substr(0, 10); //* remove a parte do horário
    const [year, month, day] = date.split("-");
    return new Date(year, month - 1, day, 0, 0, 0, 0);
  },

  formatDateTime(datetime) {
    if (!datetime) return null;
    if (datetime && datetime != null && datetime != "") {
      var options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
      };
      datetime = new Date(datetime);
      var portugueseTime = new Intl.DateTimeFormat(["pt-BR", "pt-PT"], options);
      return portugueseTime.format(datetime);
    } else {
      return null;
    }
  },
  formatDateUTC(datetime) {
    if (!datetime) return null;
    if (datetime && datetime != null && datetime != "") {
      var options = {
        year: "numeric",
        month: "numeric",
        day: "numeric"
      };
      datetime = new Date(datetime);
      var portugueseTime = new Intl.DateTimeFormat(["pt-BR", "pt-PT"], options);
      return portugueseTime.format(datetime);
    } else {
      return null;
    }
  },
  formatDateTimeUTC(datetime) {
    if (!datetime) return null;
    if (datetime && datetime != null && datetime != "") {
      var options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
      };
      datetime = new Date(datetime);
      var portugueseTime = new Intl.DateTimeFormat(["pt-BR", "pt-PT"], options);
      return portugueseTime.format(datetime);
    } else {
      return null;
    }
  },
  formatDateUSTime(datetime) {
    if (!datetime) return null;
    var date = datetime.split(" ")[0];
    var time = datetime.split(" ")[1];
    const [day, month, year] = date.split("/");
    var [hour, minute, second] = time.split(":");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")} ${hour}:${minute}:${second}`;
  },
  dateToStringUS(date) {
    if (isDate(date)) {
      date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    }
    if (!date) return null;
    if (date.indexOf("T")) {
      date = date.split("T")[0];
    }
    if (date.indexOf(" ")) {
      date = date.split(" ")[0];
    }
    const [year, month, day] = date.split("-");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  },

  formatNumber(valor, pais) {
    if (!valor) {
      valor = 0;
    }
    const formatter = new Intl.NumberFormat("pt-BR", {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });
    var retorno = formatter.format(valor);
    if (pais && pais == "PT") {
      retorno = retorno.replace(/[.]/g, " ");
    }
    return retorno;
  },
  formatCurrency(valor, pais, mostrarSimbolo) {
    if (!valor) {
      valor = 0;
    }
    const formatter = new Intl.NumberFormat("pt-BR", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    var retorno = formatter.format(valor);
    if (pais && pais == "PT") {
      retorno = retorno.replace(/[.]/g, " ");
    }
    if (mostrarSimbolo) {
      if (pais == "PT") {
        retorno = retorno + " €";
      } else {
        retorno = "R$ " + retorno;
      }
    }
    return retorno;
  },
  formatCurrency4(valor) {
    if (!valor) return "0,0000";
    const formatter = new Intl.NumberFormat("pt-BR", {
      style: "decimal",
      minimumFractionDigits: 4,
      maximumFractionDigits: 4
    });
    return formatter.format(valor);
  },
  escondeCartao(numero) {
    var retorno = numero;
    if (numero && numero.length > 4) {
      retorno = "***" + numero.substring(numero.length - 4);
    }
    return retorno;
  },
  isFormaPgto1Parcela(tipoFormaPgto) {
    //* Só permite vender em 1x
    var retorno = false;
    var formasPgto = [
      "A_VISTA",
      "APPLE",
      "BONIFICACAO",
      "COMPENSACAO",
      "CORTESIA",
      // "CREDITO",descontinuado
      "PACOTE"
    ];
    //permitir lançar uma permuta a receber ou a pagar parcelada (agenda para permutar $x,xx por mês)
    // "PERMUTA_PAGAR",
    // "PERMUTA_RECEBER"
    if (formasPgto.includes(tipoFormaPgto)) {
      retorno = true;
    }
    return retorno;
  },
  isFormaPgtoEspeciais(tipoFormaPgto) {
    //* Não permite alterar de/para outra forma
    var retorno = false;
    var formasPgto = [
      "APPLE",
      "BONIFICACAO",
      "COMPENSACAO",
      "CORTESIA",
      // "CREDITO", descontinuado
      "PACOTE",
      "PERMUTA_PAGAR",
      "PERMUTA_RECEBER"
    ];
    if (formasPgto.includes(tipoFormaPgto)) {
      retorno = true;
    }
    return retorno;
  },
  isFormaPgtoValorZero(tipoFormaPgto) {
    var retorno = false;
    var formasPgto = ["BONIFICACAO", "COMPENSACAO", "CORTESIA"];
    if (formasPgto.includes(tipoFormaPgto)) {
      retorno = true;
    }
    return retorno;
  },
  imprimir(corpo) {
    const WinPrint = window.open("", "", "left=0,top=0,width=1200,height=900,toolbar=0,scrollbars=0,status=0");

    WinPrint.document.write(corpo);
    WinPrint.document.close();
    WinPrint.onload = function() {
      //* Dá uma pausa para esperar carregar a imagem e a fonte (se houver)
      WinPrint.focus();
    };
  },
  nullToString(valor) {
    return valor == null || valor == "undefined" ? "" : valor.toString();
  },
  removeAcentos(texto) {
    if (texto) {
      texto = texto.replace(/\s\s+/g, " ");
      texto = texto.replace(/[°]/g, "o");
      texto = texto.replace(/[º]/g, "o");
      texto = texto.replace(/[ª]/g, "a");
      texto = texto.replace(/[ ]/g, " ");
      texto = texto.replace(/[Ɵ]/g, "");
      texto = texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      texto = texto.trim();
    }
    return texto;
  },
  removeEspeciais(texto) {
    if (texto) {
      texto = texto.replace(/[&]/g, "E");
      texto = texto.replace(/[']/g, " ");
      texto = texto.replace(/\s\s+/g, " ");
      texto = texto.replace(/[°]/g, "o");
      texto = texto.replace(/[º]/g, "o");
      texto = texto.replace(/[ª]/g, "a");
      texto = texto.replace(/[ ]/g, " ");
      texto = texto.replace(/[Ɵ]/g, "");
      texto = texto.normalize("NFD").replace(/([\u0300-\u036f]|[^0-9a-zA-Z/,.-\s])/g, ""); //A-Z/,.- aceita barra, vírgula, ponto, traço
      texto = texto.replace(/[\r\n]/g, " ");
      texto = texto.replace(/[\n]/g, " ");
      texto = texto.trim();
    }
    return texto;
  },
  removeEspeciaisMais(texto) {
    if (texto) {
      texto = texto.replace(/[&]/g, "E");
      texto = texto.replace(/[']/g, " ");
      texto = texto.replace(/\s\s+/g, " ");
      texto = texto.replace(/[°]/g, "o");
      texto = texto.replace(/[º]/g, "o");
      texto = texto.replace(/[ª]/g, "a");
      texto = texto.replace(/[ ]/g, " ");
      texto = texto.replace(/[Ɵ]/g, "");
      texto = texto.normalize("NFD").replace(/([\u0300-\u036f]|[^0-9a-zA-Z/,.+-\s])/g, ""); //A-Z/,.- aceita barra, vírgula, ponto, traço, +
      texto = texto.replace(/[\r\n]/g, " ");
      texto = texto.replace(/[\n]/g, " ");
      texto = texto.trim();
    }
    return texto;
  },
  somenteNumeros(valor) {
    return valor.replace(/[^0-9]/g, "");
  },
  filtrarObjetos(item, queryText, itemText) {
    if (queryText === null || queryText.length === 0) {
      return true;
    }
    var text = util.removeAcentos(item["nome"]).toLowerCase();
    queryText = util.removeAcentos(queryText).toLowerCase();
    return itemText && itemText.length > 0 && text.indexOf(queryText) > -1;
  },
  filtrarItens(item, queryText, itemText) {
    if (queryText === null || queryText.length === 0) {
      return true;
    }
    var text = util.removeAcentos(item).toLowerCase();
    queryText = util.removeAcentos(queryText).toLowerCase();
    return itemText && itemText.length > 0 && text.indexOf(queryText) > -1;
  },
  filtrarUFs(item, queryText, itemText) {
    if (queryText === null || queryText.length === 0) {
      return true;
    }
    var text = (queryText.length > 2 ? util.removeAcentos(item["nome"]).toLowerCase() : "") + "-" + item["sigla"].toLowerCase() + "-" + item["pais"].toLowerCase();
    queryText = util.removeAcentos(queryText).toLowerCase();
    return itemText && itemText.length > 0 && text.indexOf(queryText) > -1;
  },
  filtrarObjetosIdentificacao(item, queryText, itemText) {
    if (queryText === null || queryText.length === 0) {
      return true;
    }
    var text = "";
    if (queryText == util.somenteNumeros(queryText)) {
      text = item["identificacao"];
    } else {
      text = util.removeAcentos(item["nome"]).toLowerCase();
    }
    queryText = util.removeAcentos(queryText).toLowerCase();
    return itemText && itemText.length > 0 && text.indexOf(queryText) > -1;
  },
  formatIdentificacao(identificacao) {
    var retorno = "";
    if (!identificacao) {
      return "";
    }
    if (identificacao.length != 14 && identificacao.length != 11) {
      if (identificacao.length > 14) {
        return identificacao.substring(0, 14);
      } else {
        return identificacao;
      }
    }
    if (identificacao.length == 14) {
      //* CNPJ
      retorno =
        identificacao.substring(0, 2) +
        "." +
        identificacao.substring(2, 5) +
        "." +
        identificacao.substring(5, 8) +
        "/" +
        identificacao.substring(8, 12) +
        "-" +
        identificacao.substring(12);
    } else {
      //* CPF
      retorno = identificacao.substring(0, 3) + "." + identificacao.substring(3, 6) + "." + identificacao.substring(6, 9) + "-" + identificacao.substring(9);
    }
    return retorno;
  },

  abreviaNome(nome) {
    if (!nome || !nome.trim() || !nome.includes(" ")) {
      return nome;
    }
    var retorno = nome.trim;
    retorno = nome.split(" ")[0];
    var itens = nome.split(" ");
    for (let index = 0; index < itens.length; index++) {
      if (index) {
        const item = itens[index];
        if (item.startsWith("(") && item.endsWith(")")) {
          retorno += item;
        } else {
          retorno += " " + item.substring(0, 1);
        }
      }
    }
    return retorno;
  },

  abreviaEmail(email) {
    var retorno = "***";
    if (email.includes("@")) {
      const partes = email.split("@");
      const pontos = partes[1].split(".");
      retorno = partes[0].substring(0, 2) + "***@" + partes[1].substring(0, 2) + "***." + pontos[pontos.length - 1];
    }
    return retorno;
  }
};

export default util;
