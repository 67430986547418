<template>
  <div id="app">
    <v-app id="inspire">
      <v-card>
        <v-toolbar color="#333333">
          <v-icon color="white" class="mr-2">monetization_on</v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light">Cobrança</v-toolbar-title>
        </v-toolbar>

        <v-container fluid grid-list-md>
          <v-layout wrap>
            <v-flex xs12 md3>
              <v-autocomplete
                autofocus
                v-model="regua"
                :items="reguas"
                :filter="filtrarObjetos"
                label="Selecione a Régua"
                item-text="nome"
                item-value="id"
                no-data-text
                @change="carregarEtapas"
                return-object
              >
                <template v-slot:selection="data">
                  <v-chip color="blue lighten-3"> </v-chip>
                  <span class="ml-2">{{ data.item.nome }}</span>
                </template>

                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title class="ml-2">{{ data.item.nome }}</v-list-item-title>
                    <v-list-item-subtitle class="ml-2">{{ data.item.descricao }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-flex>

            <v-flex xs12 md3>
              <v-autocomplete
                v-model="etapa"
                :items="etapas"
                :filter="filtrarObjetos"
                label="Selecione a Etapa"
                item-text="nome"
                item-value="id"
                no-data-text
                @change="defineEtapa"
              >
                <template v-slot:selection="data">
                  <v-chip color="blue lighten-3">
                    <v-icon small v-if="data.item.dialer">phone</v-icon>
                    <v-icon small v-if="data.item.email">local_post_office</v-icon>
                    <v-icon small v-if="data.item.corte">location_off</v-icon>
                    <v-icon small v-if="data.item.cancelamento">highlight_off</v-icon>
                  </v-chip>
                  <span class="ml-2">{{ data.item.nome + " - " + data.item.descricao }}</span>
                </template>

                <template v-slot:item="data">
                  <v-chip color="blue lighten-3">
                    <v-icon small v-if="data.item.dialer">phone</v-icon>
                    <v-icon small v-if="data.item.email">local_post_office</v-icon>
                    <v-icon small v-if="data.item.corte">location_off</v-icon>
                    <v-icon small v-if="data.item.cancelamento">highlight_off</v-icon>
                  </v-chip>
                  <v-list-item-content>
                    <v-list-item-title class="ml-2">{{ data.item.nome }}</v-list-item-title>
                    <v-list-item-subtitle class="ml-2">{{ data.item.descricao }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-flex>

            <v-flex md6></v-flex>

            <v-flex xs12 md3>
              <v-autocomplete
                v-model="banco"
                :items="bancos"
                :filter="filtrarObjetos"
                label="Banco"
                item-text="nome"
                item-value="id"
                no-data-text
                return-object
                clearable
              ></v-autocomplete>
            </v-flex>

            <v-flex xs11 offset-md3 md5>
              <v-text-field
                v-model="filtroPesquisa"
                append-icon="search"
                label="Pesquisa pelo atendente, cliente, contrato ou forma de pagamento"
                single-line
                hide-details
                @keyup.enter="limpaFiltro(false)"
                @click:append="limpaFiltro(false)"
              ></v-text-field>
            </v-flex>

            <v-flex xs1 md1 class="text-right">
              <v-btn v-if="direitoExportar" color="blue" :loading="loading" :disabled="loading" fab small>
                <v-icon color="white" title="Exportar" @click="limpaFiltro(true)">description</v-icon>
              </v-btn>
            </v-flex>

            <v-flex xs12>
              <h4 class="overline font-weight-black font-italic">
                Negociações Realizadas
              </h4>
              <v-divider></v-divider>
            </v-flex>

            <v-flex xs12 md3>
              <v-autocomplete
                v-model="idAtendente"
                :items="atendentes"
                :filter="filtrarObjetos"
                label="Negociado por"
                item-text="nome"
                item-value="id"
                no-data-text
                clearable
              ></v-autocomplete>
            </v-flex>

            <v-flex xs11 md3>
              <v-menu v-model="mnuRegistro" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="intervaloRegistro"
                    label="Período da Negociação"
                    prepend-icon="event"
                    v-on="on"
                    color="blue-grey lighten-2"
                    clearable
                    @click:clear="intervaloRegistroUS = ['', '']"
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker v-model="intervaloRegistroUS" locale="pt-br" no-title range
                  ><v-spacer></v-spacer>
                  <v-btn text color="primary" @click="mnuRegistro = false">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-flex>

            <v-flex xs1 md6 class="text-right">
              <v-spacer></v-spacer>
              <v-btn v-if="direitoImprimir" color="blue" fab small :loading="loading">
                <v-icon color="white" title="Imprimir" @click="relatorio()">print</v-icon>
              </v-btn>
            </v-flex>

            <v-flex xs12>
              <v-divider></v-divider>
            </v-flex>
          </v-layout>
        </v-container>

        <v-data-table
          mobile-breakpoint="0"
          :headers="headers"
          :items="registros"
          :options.sync="options"
          :no-data-text="mensagemPesquisa"
          :footer-props="rodape"
          :disable-sort="carregando"
          :disable-pagination="carregando"
        >
          <template v-slot:top>
            <v-subheader>
              <span style="color:#000000">{{
                idAtendente || intervaloRegistro ? "*Listagem de parcelas a cobrar negociadas anteriormente." : "*Listagem de parcelas a cobrar."
              }}</span>
            </v-subheader>
          </template>

          <template v-slot:[getItemActions()]="{ item }">
            <v-icon
              v-if="direitoAssinante && (item.atendente == null || item.atendente == $store.state.usuario.nome)"
              color="orange"
              :title="$route.path.includes('assinaturas') ? 'Assinante' : $route.path.includes('comercial') ? 'Anunciante' : 'Cliente'"
              @click="visualizarCliente(item)"
              >account_circle</v-icon
            >
            <v-icon
              v-if="direitoAssinatura && (item.atendente == null || item.atendente == $store.state.usuario.nome)"
              :color="$route.path.includes('assinaturas') ? 'red' : $route.path.includes('comercial') ? 'yellow darken-2' : 'blue'"
              :title="$route.path.includes('assinaturas') ? 'Assinatura' : $route.path.includes('comercial') ? 'Anúncio' : 'Operação'"
              @click="visualizarContrato(item)"
              >{{ $route.path.includes("assinaturas") ? "assignment" : $route.path.includes("comercial") ? "chrome_reader_mode" : "add_circle" }}</v-icon
            >
            <v-icon v-if="direitoParcela && (item.atendente == null || item.atendente == $store.state.usuario.nome)" color="blue" title="Parcela" @click="editarParcela(item)"
              >edit</v-icon
            >
            <v-icon v-if="item.atendente == null || item.atendente == $store.state.usuario.nome" color="blue" title="Contato" @click="editarContato(item)">call</v-icon>
          </template>

          <template v-slot:[getItemAtendente()]="{ item }">
            {{ item.atendente }}
            <v-icon color="blue-grey" title="Liberar Cliente" v-if="item.atendente != null" @click="bloquearLiberarCliente(item, false)">call_end</v-icon>
          </template>

          <template v-slot:[getItemValor()]="{ item }">
            <span>
              {{ formatCurrency(item.valor) }}
            </span>
            <!-- já está com descAcr -->
          </template>
          <template v-slot:[getItemAtraso()]="{ item }">
            <span>
              {{ item.atraso + " dias" }}
            </span>
          </template>
          <template v-slot:[getItemDtVecto()]="{ item }">
            <span>
              {{ formatDateUTC(item.dtVecto) }}
            </span>
          </template>
          <template v-slot:[getItemDtPromessa()]="{ item }">
            <span>
              {{ formatDateUTC(item.dtPromessa) }}
            </span>
          </template>
        </v-data-table>
        <div class="text-xs-center pt-2 marg-bt">
          <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 15" circle></v-pagination>
        </div>

        <v-dialog persistent v-model="dialogParcela" max-width="700px">
          <v-card>
            <Parcela :id="idParcela" receber="true" :dialogParcela.sync="dialogParcela" />
          </v-card>
        </v-dialog>

        <v-dialog persistent v-model="dialogContato" max-width="1200px">
          <v-card>
            <Contato
              :idEtapa="idEtapa"
              :idParcela="idParcela"
              :idAssinatura="idAssinatura"
              :idRenovacao="idRenovacao"
              :idAnuncio="idAnuncio"
              :idOperacao="idOperacao"
              :idCliente="idCliente"
              :cliente="cliente"
              :tipo="tipo"
              :origem="origem"
              :dialogContato.sync="dialogContato"
            />
          </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
        <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
      </v-card>
    </v-app>
  </div>
</template>

<script>
import Parcela from "@/views/fin/Parcela.vue";
import Contato from "@/views/ass/Contato.vue";
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  components: {
    Parcela,
    Contato
  },
  data() {
    return {
      headers: [
        { text: "Ações", value: "actions", width: "140" },
        { text: "Atendente", value: "atendente" },
        { text: "Parcela", value: "idParcela" },
        {
          text: this.$route.path.includes("assinaturas") ? "Assinatura" : this.$route.path.includes("comercial") ? "Anúncio" : "Operação",
          value: this.$route.path.includes("assinaturas") ? "idAssinatura" : this.$route.path.includes("comercial") ? "idAnuncio" : "idOperacao"
        },
        { text: "Cliente", value: "cliente" },
        { text: "Valor", value: "valor" },
        { text: "Atraso", value: "atraso" },
        { text: "Vencimento", value: "dtVecto" },
        { text: "Promessa", value: "dtPromessa" },
        { text: "Forma Pagamento", value: "formaPagamento" }
      ],
      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },

      idSitePrincipal: null,
      regua: {},
      reguas: [],
      etapa: null,
      etapas: [],

      ordenacao: "",
      ordem: "",

      //* Dados para DialogParcela e DialogContato
      idEtapa: "",
      idParcela: "",
      idAssinatura: "",
      idRenovacao: "",
      idAnuncio: "",
      idOperacao: "",
      idCliente: "",
      cliente: "",
      tipo: "",
      origem: "",

      direitoAssinante: false,
      direitoAssinatura: false,
      direitoParcela: false,
      direitoExportar: false,
      direitoImprimir: false,

      idAtendente: null,
      atendentes: [],
      banco: {},
      bancos: [],
      mnuRegistro: false,
      intervaloRegistroUS: ["", ""],

      mensagemPesquisa: "",
      filtroPesquisa: "",
      registros: [],
      registrosPagina: 10,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      carregando: true,
      options: {},
      snackbar: false,
      snackErro: false,
      mensagem: "",
      loader: null,
      loading: false,

      dialogParcela: false,
      dialogContato: false
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    getItemActions() {
      return "item.actions";
    },
    getItemAtendente() {
      return "item.atendente";
    },
    getItemValor() {
      return "item.valor";
    },
    getItemAtraso() {
      return "item.atraso";
    },
    getItemDtVecto() {
      return "item.dtVecto";
    },
    getItemDtPromessa() {
      return "item.dtPromessa";
    },

    defineEtapa() {
      this.etapa;
      this.pesquisaDados(false);
    },
    limpaFiltro(exportar) {
      this.paginaAtual = 1;
      this.pesquisaDados(exportar);
    },
    async pesquisaDados(exportar) {
      if (this.etapa == null) {
        this.paginaAtual = 1;
        this.mensagem = "Selecione a régua e etapa de cobrança";
        this.snackbar = true;
        return;
      }
      var dtInicio = "";
      var dtFim = "";
      if (this.intervaloRegistroUS && this.intervaloRegistroUS.length > 1) {
        dtInicio = this.intervaloRegistroUS[0];
        dtFim = this.intervaloRegistroUS[1];
      }

      //sinaliza que está carregando
      this.registros = [];
      this.totalRegistros = 0;
      this.totalPaginas = 0;

      this.carregando = true;
      this.mensagemPesquisa = "Carregando...";

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "fin/parcela/cobranca/" +
            this.etapa +
            "?pagina=" +
            this.paginaAtual +
            (this.idAtendente ? "&idUsuario=" + this.idAtendente : "") +
            (this.banco && this.banco.id ? "&bancoNumero=" + this.banco.bancoNumero : "") +
            (dtInicio && dtFim ? "&dtInicio=" + dtInicio + "&dtFim=" + dtFim : "") +
            "&tamanho=" +
            this.registrosPagina +
            "&filtro=" +
            this.filtroPesquisa +
            "&ordenacao=" +
            this.ordenacao +
            "&ordem=" +
            this.ordem +
            (exportar ? "&exportar=1" : "")
        );

        //seta as variaveis com o retorno
        this.registros = response.data.data;
        this.totalRegistros = response.data.total;
        this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
        this.rodape.pageText = this.totalRegistros + " itens";
        //fecha o loading
        this.carregando = false;
        //se não achar registro seta a mensagem senão deixa em branco
        this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else {
          this.mensagemPesquisa =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
        }
      }
    },
    carregarReguas() {
      //* Carrega as réguas de cobrança
      api
        .get(
          process.env.VUE_APP_URL_WEB_API +
            "ass/etapa-cobranca-grupo/lista" +
            (this.$route.path.includes("assinaturas") ? "?assinaturas=1" : this.$route.path.includes("comercial") ? "?comercial=1" : "")
        )
        .then(response => {
          this.reguas = response.data;
          this.reguas.forEach(element => {
            if (element.dtInicio) {
              element.descricao = "após " + util.formatDate(element.dtInicio);
            }
            if (element.dtFim) {
              element.descricao = "até " + util.formatDate(element.dtFim);
            }
          });
          this.etapa = null;
        })
        .catch(() => {
          this.mensagem = "Ocorreu um erro ao carregar as réguas de cobrança";
          this.snackErro = true;
        });
    },
    carregarEtapas() {
      this.registros = [];
      this.totalRegistros = 0;
      this.totalPaginas = 0;
      this.paginaAtual = 1;
      this.etapa = null;

      //* Carrega as etapas de cobrança da régua selecionada
      api
        .get(process.env.VUE_APP_URL_WEB_API + "ass/etapa-cobranca/grupo/" + this.regua.id)
        .then(response => {
          this.etapas = response.data;
          this.etapas.forEach(element => {
            element.descricao = element.inicio + " a " + element.fim + " dias";
          });
        })
        .catch(() => {
          this.mensagem = "Ocorreu um erro ao carregar as etapas de cobrança";
          this.snackErro = true;
        });
    },
    carregarAtendentes() {
      api
        .get(process.env.VUE_APP_URL_WEB_API + "fin/contato/atendentes")
        .then(response => {
          this.atendentes = response.data;
        })
        .catch(() => {
          this.mensagem = "Ocorreu um erro ao carregar os atendentes";
          this.snackErro = true;
        });
    },
    carregarBancos() {
      api
        .get(process.env.VUE_APP_URL_WEB_API + "fin/cedente/debito?idSite=" + this.idSitePrincipal)
        .then(response => {
          this.bancos = response.data;
          this.bancos.forEach(element => {
            element.nome = element.bancoNumero + " - " + element.bancoNome;
          });
        })
        .catch(() => {
          this.mensagem = "Ocorreu um erro ao carregar os bancos";
          this.snackErro = true;
        });
    },

    async clienteLiberado(idCliente) {
      const retorno = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente/atendimento/" + idCliente + "?idUsuario=" + this.$store.state.usuario.id);
      return retorno.data.length == 1;
    },

    async bloquearLiberarCliente(item, bloquear) {
      var cobrancaItem = Object.assign({}, item);
      cobrancaItem.atendente = null;

      var clienteItem = { idUsuarioCall: null };
      if (bloquear) {
        clienteItem = { idUsuarioCall: this.$store.state.usuario.id };
        cobrancaItem.atendente = this.$store.state.usuario.nome;
      }
      try {
        await api.put(process.env.VUE_APP_URL_WEB_API + "adm/cliente/call/" + cobrancaItem.idCliente, clienteItem);

        var itemIndex = this.registros.indexOf(item);
        Object.assign(this.registros[itemIndex], cobrancaItem);
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },

    async visualizarCliente(item) {
      const liberado = await this.clienteLiberado(item.idCliente);
      if (!liberado) {
        this.mensagem = "Este cliente está sendo atendido por outro usuário.";
        this.snackErro = true;
        return;
      }
      await this.bloquearLiberarCliente(item, true);
      if (this.$route.path.includes("assinaturas")) {
        window.open("/assinaturas/assinante/" + item.idCliente, "_blank");
      } else if (this.$route.path.includes("comercial")) {
        window.open("/comercial/anunciante/" + item.idCliente, "_blank");
      } else {
        window.open("/financeiro/cliente/" + item.idCliente, "_blank");
      }
    },
    async visualizarContrato(item) {
      const liberado = await this.clienteLiberado(item.idCliente);
      if (!liberado) {
        this.mensagem = "Este cliente está sendo atendido por outro usuário.";
        this.snackErro = true;
        return;
      }
      await this.bloquearLiberarCliente(item, true);
      if (this.$route.path.includes("assinaturas")) {
        window.open("/assinaturas/assinatura/" + item.idAssinatura, "_blank");
      } else if (this.$route.path.includes("comercial")) {
        window.open("/comercial/anuncio/" + item.idAnuncio, "_blank");
      } else {
        window.open("/financeiro/operacao/receber/" + item.idOperacao, "_blank");
      }
    },
    async editarParcela(item) {
      const liberado = await this.clienteLiberado(item.idCliente);
      if (!liberado) {
        this.mensagem = "Este cliente está sendo atendido por outro usuário.";
        this.snackErro = true;
        return;
      }
      await this.bloquearLiberarCliente(item, true);
      this.idParcela = item.idParcela + "";
      this.dialogParcela = true;
    },
    closeParcela() {
      this.dialogParcela = false;
      this.idParcela = "";
    },

    async editarContato(item) {
      const liberado = await this.clienteLiberado(item.idCliente);
      if (!liberado) {
        this.mensagem = "Este cliente está sendo atendido por outro usuário.";
        this.snackErro = true;
        return;
      }
      await this.bloquearLiberarCliente(item, true);
      this.idEtapa = this.regua.id + "";
      this.idParcela = item.idParcela + "";
      this.idAssinatura = item.idAssinatura ? item.idAssinatura + "" : "";
      this.idRenovacao = item.idRenovacao ? item.idRenovacao + "" : "";
      this.idAnuncio = item.idAnuncio ? item.idAnuncio + "" : "";
      this.idOperacao = !item.idAssinatura && !item.idAnuncio && item.idOperacao ? item.idOperacao + "" : "";
      this.origem = "";
      if (!item.idAssinatura && !item.idAnuncio && !item.idPacote) {
        this.origem = "FIN";
      }
      this.idCliente = item.idCliente + "";
      this.cliente = item.cliente;
      this.tipo = "2"; //cobrança
      this.dialogContato = true;
    },
    closeContato() {
      this.dialogContato = false;
      this.idParcela = "";
      this.idAssinatura = "";
      this.idRenovacao = "";
      this.idAnuncio = "";
      this.idOperacao = "";
      this.origem = "";
    },

    async relatorio() {
      var dtInicio = "";
      var dtFim = "";
      if (this.intervaloRegistroUS && this.intervaloRegistroUS.length > 1) {
        dtInicio = this.intervaloRegistroUS[0];
        dtFim = this.intervaloRegistroUS[1];
      }
      if (!dtInicio || !dtFim) {
        this.mensagem = "Verifique o período da negociação";
        this.snackErro = true;
        return;
      }

      this.loader = "loading";
      const l = this.loader;
      this[l] = !this[l];

      try {
        var parametros =
          "?dtInicio=" + dtInicio + "&dtFim=" + dtFim + "&origem=" + (this.$route.path.includes("assinaturas") ? "ASS" : this.$route.path.includes("comercial") ? "COM" : "FIN");
        if (this.idAtendente) {
          parametros += "&idUsuario=" + this.idAtendente;
        }
        if (this.banco && this.banco.id) {
          parametros += "&bancoNumero=" + this.banco.bancoNumero;
        }
        if (this.regua && this.regua.id) {
          parametros += "&idEtapa=" + this.regua.id;
          parametros += "&frente=" + this.regua.nome;
        }
        if (this.filtroPesquisa) {
          parametros += "&filtro=" + this.filtroPesquisa;
        }

        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/parcela/cobranca" + parametros);

        util.imprimir(response.data);
      } catch (e) {
        var erro = e.response.data;
        if (erro.length > 0) {
          erro = erro[0].mensagem;
        }
        this.mensagem = erro;
        this.snackErro = true;
      } finally {
        this[l] = false;
        this.loader = null;
      }
    },

    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    formatDate(date) {
      return util.formatDate(date);
    },
    formatDateUTC(date) {
      return util.formatDateUTC(date);
    },
    formatDateTime(datetime) {
      return util.formatDateTime(datetime);
    },
    formatCurrency(valor) {
      return util.formatCurrency(valor);
    }
  },

  beforeMount() {
    if (this.$route.path.includes("assinaturas")) {
      this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_COBRANÇA_ACESSO"]);
    } else if (this.$route.path.includes("comercial")) {
      this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "COMERCIAL_COBRANÇA_ACESSO"]);
    } else {
      this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_COBRANÇA_ACESSO"]);
    }
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_ASSINANTES_EDITAR") {
        this.direitoAssinante = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_ASSINATURAS_EDITAR") {
        this.direitoAssinatura = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_PARCELAS_EDITAR") {
        this.direitoParcela = true;
      }
      if (this.$route.path.includes("assinaturas")) {
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_COBRANÇA_EXPORTAR") {
          this.direitoExportar = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_COBRANÇA_IMPRIMIR") {
          this.direitoImprimir = true;
        }
      } else if (this.$route.path.includes("comercial")) {
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_COBRANÇA_EXPORTAR") {
          this.direitoExportar = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_COBRANÇA_IMPRIMIR") {
          this.direitoImprimir = true;
        }
      } else {
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_COBRANÇA_EXPORTAR") {
          this.direitoExportar = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_COBRANÇA_IMPRIMIR") {
          this.direitoImprimir = true;
        }
      }
    });
  },

  computed: {
    intervaloRegistro() {
      var retorno = "";
      if (this.intervaloRegistroUS && this.intervaloRegistroUS.length > 1) {
        this.intervaloRegistroUS.forEach(element => {
          if (element) {
            retorno += (retorno ? " - " : "") + util.formatDate(element);
          }
        });
      }
      return retorno;
    }
  },
  watch: {
    paginaAtual() {
      this.pesquisaDados(false);
    },
    dialogParcela(val) {
      if (val == null) {
        this.pesquisaDados(false);
      }
      val || this.closeParcela();
    },
    dialogContato(val) {
      if (val == null) {
        this.pesquisaDados(false);
      }
      val || this.closeContato();
    },
    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }

        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }

        this.pesquisaDados(false);
      }
    }
  },
  mounted() {
    this.carregarReguas();
    this.carregarAtendentes();
    this.carregarBancos();
  }
};
</script>
