<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">mdi-account</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Configuração - Nota Fiscal Eletrônica de Serviço</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12 md3>
            <v-switch
              v-model="configNFSe.financeiro"
              :label="`${configNFSe.financeiro == 1 ? 'Emite Notas com Parcelas do Financeiro' : 'Não emite notas com parcelas do Financeiro'}`"
            ></v-switch>
          </v-flex>

          <v-flex xs12 md3>
            <v-switch
              v-model="configNFSe.assinatura"
              :label="`${configNFSe.assinatura == 1 ? 'Emite Notas com Parcelas de Assinatura' : 'Não emite notas com parcelas de Assinatura'}`"
            ></v-switch>
          </v-flex>

          <v-flex xs12 md3>
            <v-switch
              v-model="configNFSe.vendaAvulsa"
              :label="`${configNFSe.vendaAvulsa == 1 ? 'Emite Notas com Parcelas de Venda Avulsa' : 'Não emite notas com parcelas de Venda Avulsa'}`"
            ></v-switch>
          </v-flex>

          <v-flex xs12 md3>
            <v-switch
              v-model="configNFSe.anuncio"
              :label="`${configNFSe.anuncio == 1 ? 'Emite Notas com Parcelas de Anúncio' : 'Não emite notas com parcelas de Anúncio'}`"
            ></v-switch>
          </v-flex>

          <v-flex xs6 md3>
            <v-text-field
              v-model="configNFSe.serie"
              autofocus
              label="Série"
              maxlength="5"
              hint="Obrigatório"
              :rules="[validacao.serie == false || validacao.serie, value => !!value || 'Obrigatório.']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="
                configNFSe.serie = configNFSe.serie.toUpperCase();
                validacao.serie = false;
              "
            ></v-text-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-text-field
              v-model="configNFSe.ultimoRPS"
              label="Última RPS"
              v-mask="'#########'"
              color="blue-grey lighten-2"
              hint="Obrigatório"
              :rules="[validacao.ultimoRPS == false || validacao.ultimoRPS, value => !!value || 'Obrigatório.']"
              @change="validacao.ultimoRPS = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-text-field
              v-model="configNFSe.ultimoLote"
              label="Último Lote"
              v-mask="'#########'"
              color="blue-grey lighten-2"
              hint="Obrigatório"
              :rules="[validacao.ultimoLote == false || validacao.ultimoLote, value => !!value || 'Obrigatório.']"
              @change="validacao.ultimoLote = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-text-field
              v-model="configNFSe.hsCanc"
              label="Prazo Cancelamento"
              suffix="hs"
              v-mask="'###'"
              hint="Obrigatório"
              :rules="[validacao.hsCanc == false || validacao.hsCanc, value => !!value || 'Preencha o prazo de cancelamento (em horas)']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.hsCanc = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-switch v-model="configNFSe.simples" :label="`${configNFSe.simples == 1 ? 'SIMPLES' : 'Não é SIMPLES'}`"></v-switch>
          </v-flex>

          <v-flex xs6 md3>
            <v-switch v-model="configNFSe.incCult" :label="`${configNFSe.incCult == 1 ? 'Incentivo Cultural' : 'Não é Incentivo Cultural'}`"></v-switch>
          </v-flex>

          <v-flex xs6 md3>
            <v-switch v-model="configNFSe.incFisc" :label="`${configNFSe.incFisc == 1 ? 'Incentivo Fiscal' : 'Não é Incentivo Fiscal'}`"></v-switch>
          </v-flex>

          <v-flex xs6 md3>
            <v-switch
              v-model="configNFSe.publicacoes"
              :label="`${configNFSe.publicacoes == 1 ? 'Apresenta Publicações na Descrição' : 'Não apresenta publicações na descrição'}`"
            ></v-switch>
          </v-flex>

          <v-flex xs6 md3>
            <v-text-field
              v-model="configNFSe.natOp"
              label="Natureza da Operação"
              v-mask="'#'"
              hint="Obrigatório"
              :rules="[validacao.natOp == false || validacao.natOp, value => value != '' || 'Obrigatório.']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.natOp = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-text-field
              v-model="configNFSe.codTribMun"
              label="Código Tributário Municipal"
              maxlength="20"
              hint="Obrigatório"
              :rules="[validacao.codTribMun == false || validacao.codTribMun, value => !!value || 'Obrigatório.']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.codTribMun = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-text-field
              v-model="configNFSe.cnae"
              label="CNAE"
              maxlength="7"
              hint="Obrigatório"
              :rules="[validacao.cnae == false || validacao.cnae, value => !!value || 'Obrigatório.']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.cnae = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-text-field
              v-model="configNFSe.codListaServ"
              label="Código Lista de Serviço"
              maxlength="4"
              hint="Obrigatório"
              :rules="[
                validacao.codListaServ == false || validacao.codListaServ,
                value => !!value || 'Obrigatório.',
                value => value.length == 4 || 'Preencha o Código Lista de Serviço com 4 dígitos numéricos'
              ]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.codListaServ = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="configNFSe.iss"
              label="Alíquota ISS"
              :allowNegative="false"
              :max="99"
              :rules="[validacao.iss == false || validacao.iss]"
              color="blue-grey lighten-2"
              @change="validacao.iss = false"
            ></v-currency-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-text-field
              v-model="configNFSe.senha"
              label="Senha Certificado"
              maxlength="30"
              hint="Obrigatório"
              :rules="[validacao.senha == false || validacao.senha, value => !!value || 'Obrigatório.']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.senha = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-switch
              color="success"
              v-model="configNFSe.publicacoes"
              :label="`${configNFSe.publicacoes == 1 ? 'Inclui publicações na descrição' : 'Não inclui publicações na descrição'}`"
            ></v-switch>
          </v-flex>

          <v-flex xs12 md12>
            <v-text-field
              v-model="configNFSe.token"
              label="Token Conexão Prefeitura"
              maxlength="32"
              :rules="[validacao.token == false || validacao.token]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.token = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-switch v-model="configNFSe.textoEstendido" :label="`${configNFSe.textoEstendido == 1 ? 'Utilizar Texto Estendido' : 'Sem texto estendido'}`"></v-switch>
          </v-flex>

          <v-flex xs6 md3>
            <v-switch color="success" v-model="configNFSe.producao" :label="`${configNFSe.producao == 1 ? 'Ambiente de Produção' : 'Ambiente de Homologação'}`"></v-switch>
          </v-flex>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">{{ validacao.mensagemErro }}</v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn to="/financeiro/desktop" color="orange lighten-2 white" style="color:white"> <v-icon left>keyboard_arrow_left</v-icon>Retornar </v-btn>
      <v-btn v-if="direitoEditar" :loading="isUpdating" color="blue white" style="color:white" depressed @click="salvar"> <v-icon left>update</v-icon>Salvar </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      configNFSe: {
        id: "",
        idSite: null,
        financeiro: 0,
        assinatura: 0,
        vendaAvulsa: 0,
        anuncio: 0,
        ultimoRPS: null,
        ultimoLote: null,
        hsCanc: "",
        simples: 0,
        serie: "",
        altComp: 0,
        incCult: 0,
        incFisc: 0,
        natOp: 0,
        codTribMun: "",
        cnae: "",
        codListaServ: "",
        iss: 0,
        senha: "",
        token: "",
        producao: 0,
        textoEstendido: 0,
        publicacoes: 0
      },

      validacao: {
        id: false,
        idSite: false,
        financeiro: false,
        assinatura: false,
        vendaAvulsa: false,
        anuncio: false,
        ultimoRPS: false,
        ultimoLote: false,
        hsCanc: false,
        simples: false,
        serie: false,
        altComp: false,
        incCult: false,
        incFisc: false,
        natOp: false,
        codTribMun: false,
        cnae: false,
        codListaServ: false,
        iss: false,
        senha: false,
        token: false,
        producao: false,
        textoEstendido: false,
        publicacoes: false,
        mensagemErro: false
      },

      idSitePrincipal: null,

      direitoEditar: false,
      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    async salvar() {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      this.isUpdating = true;
      try {
        var retorno = "";
        //se tiver id é um update
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "fin/config-nfse/" + this.id, this.configNFSe);
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "fin/config-nfse?idSite=" + this.idSitePrincipal, this.configNFSe);
          this.id = retorno.data.id;
          this.configNFSe.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },
    pLetra(text) {
      return util.pLetra(text);
    }
  },

  async created() {
    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA") {
        this.direitoEditar = true;
      }
    });

    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
    try {
      this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_CONFIG_NFSE_ACESSO"]);
      //* Carrega a configuração para o site, se existir
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/config-nfse/site/" + this.idSitePrincipal);
      if (!response.data) {
        this.id = "";
        this.configNFSe.idSite = this.idSitePrincipal;
      } else {
        this.id = response.data.id;
        this.configNFSe = response.data;
      }
    } catch {
      this.mensagem = "Ocorreu um erro ao carregar os dados";
      this.snackErro = true;
    }
  }
};
</script>
