<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar height="50" color="#333333">
      <v-icon color="white" class="mr-2">share</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Rede de Sites</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12>
            <h4 class="overline font-weight-black font-italic">
              Dados Básicos
            </h4>
            <v-divider></v-divider>
          </v-flex>

          <v-flex xs12>
            <v-text-field
              background-color="white"
              v-model="rede.nome"
              autofocus
              label="Nome da Rede"
              maxlength="100"
              hint="Obrigatório"
              :rules="[
                validacao.nome == false || validacao.nome,
                value => !!value || 'Obrigatório.',
                value => valida_nome(value) || 'Nome inválido',
                value => value.length > 2 || 'Preencha o nome da rede'
              ]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="
                rede.nome = pLetra(rede.nome);
                validacao.nome = false;
              "
              autocomplete="disabled"
              :name="Math.random()"
            ></v-text-field>
          </v-flex>

          <v-flex xs12>
            <v-switch v-model="rede.situacao" :label="`${rede.situacao == 1 ? 'Ativa' : 'Inativa'}`"></v-switch>
          </v-flex>

          <v-flex xs12>
            <h4 class="overline font-weight-black font-italic">
              Sites da Rede
            </h4>
            <v-divider></v-divider>
          </v-flex>

          <v-flex offset-xs8 xs4>
            <v-text-field
              background-color="white"
              class="mb-2 elevation-0"
              v-model="searchSitesRede"
              append-icon="search"
              label="Digite para pesquisar..."
              hide-details
              single-line
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-data-table
          mobile-breakpoint="0"
          :headers="headersSitesRede"
          :items="sitesRede"
          :search="searchSitesRede"
          no-data-text
          no-results-text
          :footer-props="{
            itemsPerPageText: 'Registros por página',
            itemsPerPageOptions: [10, 25, 50],
            pageText: ''
          }"
        >
          <template v-slot:[getItemSituacao()]="{ item }">
            <span v-html="item.situacao == 1 ? 'Ativo' : item.situacao == 0 ? '<strong>Inativo</strong>' : ''"></span>
          </template>
        </v-data-table>

        <v-flex xs12 v-if="validacao.mensagemErro">
          <v-sheet :value="true" class="red--text">
            {{ validacao.mensagemErro }}
          </v-sheet>
        </v-flex>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn to="/administrativo/redes" color="orange lighten-2 white" style="color:white" depressed id="btn-rsn">
        <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar
      </v-btn>
      <v-btn :loading="isUpdating" color="blue white" style="color:white" id="btn-rsn" depressed @click="salvar('false')"> <v-icon id="icon-rsn" left>update</v-icon>Salvar </v-btn>

      <v-btn :loading="isUpdating" color="orange white" style="color:white" id="btn-rsn" outlined @click="salvar('retornar')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>
      <v-btn :loading="isUpdating" color="blue white" style="color:white" id="btn-rsn" outlined @click="salvar('true')">
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Nova
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>

<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    getItemSituacao() {
      return "item.situacao";
    },

    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }
      this.isUpdating = true;
      try {
        var retorno = "";
        //se tiver id é um update
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "adm/rede/" + this.id, this.rede);
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "adm/rede?idSite=" + this.idSitePrincipal, this.rede);
          this.id = retorno.data.id;
          this.rede.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "true") {
          this.$router.push("/administrativo/rede");
          this.$router.go("/administrativo/rede");
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push("/administrativo/redes");
          }, 2000);
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },
    pLetra(text) {
      return util.pLetra(text);
    },
    valida_nome(text) {
      return util.valida_nome(text);
    }
  },
  data() {
    return {
      rede: {
        id: "",
        nome: "",
        situacao: 1
      },
      validacao: {
        id: false,
        nome: false,
        situacao: false,
        mensagemErro: false
      },

      sitesRede: [],

      headersSitesRede: [
        { text: "ID", value: "id" },
        { text: "Nome", value: "nome" },
        {
          text: this.$store.state.usuario.site_pais == "BR" ? "Município" : "Concelho",
          value: "municipio"
        },
        { text: "Situação", value: "situacao" }
      ],

      idSitePrincipal: null,
      elementoValid: true,
      id: "",
      searchSitesRede: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false
    };
  },
  async created() {
    this.id = this.$route.params.id ? this.$route.params.id : "";
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA"]);

    //* Se é edição, carrega a rede
    if (this.id != "") {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/rede/" + this.id);
        if (!response.data) {
          this.$router.push("/administrativo/redes");
          return;
        }
        this.rede = response.data;

        //* Carrega os sites da rede
        const responseSites = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/site/rede/" + this.rede.id);
        this.sitesRede = responseSites.data;
        this.sitesRede.forEach(element => {
          element.municipio += "/" + element.uf;
        });
      } catch {
        this.mensagem = "Ocorreu um erro ao carregar os dados";
        this.snackErro = true;
      }
    }
  }
};
</script>
