<template>
  <div id="app">
    <v-app id="inspire">
      <v-card>
        <v-toolbar color="#333333">
          <v-icon color="white" class="mr-2">bolt</v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light">Títulos Celesc</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <div class="px-6 mt-4">
          <v-layout wrap>
            <v-spacer></v-spacer>
            <v-flex xs12 md5>
              <v-text-field
                background-color="white"
                class="mt-2 mb-2 elevation-0"
                v-model="filtroPesquisa"
                append-icon="search"
                label="Digite para pesquisar..."
                placeholder="Pesquisa pelo codigo, status, identificacao, cliente, unidade consumidora ou sigla do site"
                hide-details
                @keyup.enter="limpaFiltro"
                @click:append="limpaFiltro"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-data-table mobile-breakpoint="0" :headers="headers" :items="registros" :options.sync="options" :no-data-text="mensagemPesquisa" :footer-props="rodape">
            <template v-slot:item.sigla="{ item }">
              <span>{{ item.sigla.toUpperCase() }}</span>
            </template>
            <template v-slot:item.anoMes="{ item }">
              <span>{{ item.anoMes.substring(2) + "/20" + item.anoMes.substring(0, 2) }}</span>
            </template>
            <template v-slot:item.valor="{ item }">
              <span>{{ formatCurrency(item.valor) }}</span>
            </template>
            <template v-slot:item.dtRetorno="{ item }">
              <span>{{ formatDate(item.dtRetorno) }}</span>
            </template>
          </v-data-table>
          <div class="text-xs-center pt-2 marg-bt">
            <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 11" circle></v-pagination>
          </div>
        </div>
      </v-card>
    </v-app>
  </div>
</template>

<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      headers: [
        { text: "Sigla", value: "sigla" },
        { text: "Site", value: "site", width: 200 },
        { text: "Lote Recepção", value: "idLoteRecepcao" },
        { text: "Lote Remessa", value: "idLoteRemessa", divider: true },
        { text: "Competência", value: "anoMes" },
        { text: "Código", value: "codigo" },
        { text: "Valor", value: "valor" },
        { text: "Status", value: "status", divider: true },
        { text: "Identificação", value: "identificacao" },
        { text: "Cliente", value: "cliente" },
        {
          text: "Unidade Consumidora",
          value: "unidadeConsumidora",
          divider: true
        },
        { text: "Lote Retorno", value: "idLoteRetorno" },
        { text: "Data", value: "dtRetorno" },
        { text: "Código", value: "codRetorno" },
        { text: "Descrição", value: "descricaoRetorno" }
      ],
      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50, 100],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },
      mensagemPesquisa: "",
      filtroPesquisa: "",
      registros: [],
      registrosPagina: 10,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      carregando: true,
      ordenacao: "",
      ordem: "",
      options: {}
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    limpaFiltro() {
      this.paginaAtual = 1;
      this.pesquisaDados();
    },
    async pesquisaDados() {
      this.registros = [];
      //sinaliza que está carregando
      this.carregando = true;
      this.mensagemPesquisa = "Carregando...";

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "fin/celesc-titulo?pagina=" +
            this.paginaAtual +
            "&tamanho=" +
            this.registrosPagina +
            "&filtro=" +
            this.filtroPesquisa +
            (this.$route.query["idLoteRecepcao"] ? "&idLoteRecepcao=" + this.$route.query["idLoteRecepcao"] : "") +
            (this.$route.query["idLoteRemessa"] ? "&idLoteRemessa=" + this.$route.query["idLoteRemessa"] : "") +
            (this.$route.query["idLoteRetorno"] ? "&idLoteRetorno=" + this.$route.query["idLoteRetorno"] : "") +
            "&ordenacao=" +
            this.ordenacao +
            "&ordem=" +
            this.ordem
        );

        //seta as variaveis com o retorno
        this.registros = response.data.data;
        this.totalRegistros = response.data.total;
        this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
        this.rodape.pageText = this.totalRegistros + " itens = R$ " + util.formatCurrency(response.data.soma);
        //fecha o loading
        this.carregando = false;
        //se não achar registro seta a mensagem senão deixa em branco
        this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else {
          this.mensagemPesquisa = "Erro ao buscar dados: " + e;
        }
      }
    },
    formatCurrency(valor) {
      return util.formatCurrency(valor);
    },
    formatDate(date) {
      return util.formatDate(date);
    }
  },
  beforeMount() {
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_CELESC_TITULOS_ACESSO"]);
    if (this.$store.state.usuario.idSitePrincipal != 46) {
      setTimeout(() => {
        this.mensagemPesquisa = "Somente Adjori pode acessar esta tela!";
      }, 1000);
      setTimeout(() => {
        this.$router.push("/acesso-negado");
        return;
      }, 4000);
    }
  },
  watch: {
    paginaAtual() {
      this.pesquisaDados();
    },
    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }
        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }
        this.pesquisaDados();
      }
    }
  }
};
</script>
