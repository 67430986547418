<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">credit_card</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Forma de Pagamento</v-toolbar-title>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12 md12 v-if="siteGrupo.length > 0">
            <v-autocomplete
              v-model="formaPagamento.idSite"
              :items="siteGrupo"
              :filter="filtrarObjetos"
              label="Restrito ao Site"
              :disabled="isUpdating"
              :rules="[validacao.idSite == false || validacao.idSite]"
              item-text="nome"
              item-value="id"
              no-data-text
              clearable
              @change="validacao.idSite = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md12>
            <v-text-field
              autofocus
              v-model="formaPagamento.nome"
              label="Nome da Forma de Pagamento"
              maxlength="30"
              hint="Obrigatório"
              :rules="[
                validacao.nome == false || validacao.nome,
                value => !!value || 'Obrigatório.',
                value => valida_nome(value) || 'Nome inválido',
                value => value.length > 2 || 'Preencha o nome da forma de pagamento'
              ]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="
                formaPagamento.nome = pLetra(formaPagamento.nome);
                validacao.nome = false;
              "
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md6>
            <v-autocomplete
              v-model="formaPagamento.tipo"
              :items="tipos"
              :filter="filtrarItens"
              label="Selecione o Tipo"
              :disabled="isUpdating || formaPagamento.id != ''"
              hint="Obrigatório"
              :rules="[validacao.tipo == false || validacao.tipo, value => !!value || 'Obrigatório.']"
              no-data-text
              @change="validacao.tipo = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md6>
            <v-autocomplete
              v-model="formaPagamento.idConta"
              :filter="filtrarObjetos"
              :disabled="isUpdating"
              :items="contasMovimento"
              :rules="[validacao.idConta == false || validacao.idConta]"
              label="Conta para Quitação"
              item-text="nome"
              item-value="id"
              clearable
              no-data-text
              @change="validacao.idConta = false"
            >
            </v-autocomplete>
          </v-flex>

          <v-flex xs12 md4>
            <v-switch v-model="formaPagamento.online" :label="`${formaPagamento.online == 1 ? 'Disponível para Vendas Online' : 'Indisponível para Vendas Online'}`"></v-switch>
          </v-flex>

          <v-flex xs12 md4>
            <v-switch
              v-model="formaPagamento.receber"
              :label="`${formaPagamento.receber == 1 ? 'Disponível para Contas a Receber' : 'Indisponível para Contas a Receber'}`"
            ></v-switch>
          </v-flex>

          <v-flex xs12 md4>
            <v-switch v-model="formaPagamento.pagar" :label="`${formaPagamento.pagar == 1 ? 'Disponível para Contas a Pagar' : 'Indisponível para Contas a Pagar'}`"></v-switch>
          </v-flex>

          <v-flex xs12 md4 v-if="$store.state.usuario.site_comercial">
            <v-switch
              v-model="formaPagamento.bloqueioPublicacao"
              :label="`${formaPagamento.bloqueioPublicacao == 1 ? 'Bloquear Publicação*' : 'Não bloquear publicação'}`"
            ></v-switch>
          </v-flex>

          <v-flex xs12 md4>
            <v-switch v-model="formaPagamento.situacao" :label="`${formaPagamento.situacao == 1 ? 'Ativo' : 'Inativo'}`"></v-switch>
          </v-flex>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">{{ validacao.mensagemErro }}</v-sheet>
          </v-flex>

          <v-flex xs12 v-if="$store.state.usuario.site_comercial && formaPagamento.bloqueioPublicacao">
            <v-subheader>
              <span style="color:#0000FF">
                *Bloqueia publicação até uma parcela do anúncio ser quitada.
              </span>
            </v-subheader>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn to="/administrativo/formas-pagamento" id="btn-rsn" color="orange lighten-2 white" style="color:white">
        <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar
      </v-btn>
      <v-btn :loading="isUpdating" color="blue white" style="color:white" id="btn-rsn" depressed @click="salvar('false')"> <v-icon id="icon-rsn" left>update</v-icon>Salvar </v-btn>
      <v-btn :loading="isUpdating" color="orange white" style="color:white" id="btn-rsn" outlined @click="salvar('retornar')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>
      <v-btn :loading="isUpdating" color="blue white" style="color:white" id="btn-rsn" outlined @click="salvar('true')" v-if="direitoCriar">
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Nova
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      //* Apesar de ser setada somente de forma automática, na captação, PERMUTA_PAGAR também é uma forma de pagamento para conta a receber

      //disponível via Asaas
      // if (this.formaPagamento.receber && this.formaPagamento.tipo == "PIX") {
      //   this.mensagem = "Forma de pagamento ainda não disponível para conta a receber";
      //   this.snackErro = true;
      //   return;
      // }

      if (
        this.formaPagamento.pagar &&
        [
          "BONIFICACAO",
          "CARTAO_CREDITO",
          "CELESC",
          "COMPENSACAO",
          //"CORTESIA",
          // "CREDITO", descontinuado
          "PACOTE",
          "SAMAE"
        ].includes(this.formaPagamento.tipo)
      ) {
        this.mensagem = "Forma de pagamento não disponível para conta a pagar";
        this.snackErro = true;
        return;
        //* Não ficarão disponíveis para contas a pagar
        //CORTESIA - porque alguém lançaria uma conta a pagar com valor zero?
        //CARTAO_CREDITO - é o automático, então para contas a pagar, usar CARTAO_MANUAL
        // this.formaPagamento.pagar = 0;
      }

      if (this.formaPagamento.online && !["APPLE", "BOLETO", "CARTAO_CREDITO", "CELESC", "DEBITO_CONTA", "BONIFICACAO", "CORTESIA", "PIX"].includes(this.formaPagamento.tipo)) {
        //* Somente estas formas ficarão disponíveis para o paywall
        //BONIFICACAO/CORTESIA - serão lançados automaticamente pelos mecanismo de cupom
        this.mensagem = "Forma de pagamento não disponível para vendas online";
        this.snackErro = true;
        return;
        // this.formaPagamento.online = 0;
      }

      this.isUpdating = true;
      try {
        var retorno = "";
        //se tiver id é um update
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "adm/forma-pagamento/" + this.id, this.formaPagamento);
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "adm/forma-pagamento?idSite=" + this.idSitePrincipal, this.formaPagamento);
          this.id = retorno.data.id;
          this.formaPagamento.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "true") {
          this.$router.push("/administrativo/forma-pagamento");
          this.$router.go("/administrativo/forma-pagamento");
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push("/administrativo/formas-pagamento");
          }, 2000);
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },

    async carregarContas() {
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/conta/movimento");
      if (response.data) {
        this.contasMovimento = response.data;
      }
    },
    filtrarItens(item, queryText, itemText) {
      return util.filtrarItens(item, queryText, itemText);
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    pLetra(text) {
      return util.pLetra(text);
    },
    valida_nome(text) {
      return util.valida_nome(text);
    }
  },
  data() {
    return {
      formaPagamento: {
        id: "",
        idSite: null,
        idConta: null,
        nome: "",
        tipo: "",
        online: 0,
        receber: 0,
        pagar: 0,
        bloqueioPublicacao: 0,
        situacao: 1
      },
      validacao: {
        id: false,
        idSite: false,
        idConta: false,
        nome: false,
        tipo: false,
        online: false,
        receber: false,
        pagar: false,
        situacao: false,
        bloqueioPublicacao: false,
        mensagemErro: false
      },

      siteGrupo: [],

      contasMovimento: [],

      tipos: [
        "A_VISTA",
        "APPLE",
        "BOLETO",
        "BONIFICACAO",
        "CARTAO_CREDITO",
        "CARTAO_MANUAL",
        "CARTEIRA",
        "CASA",
        "CELESC",
        "CHEQUE",
        "COMPENSACAO",
        "CORTESIA",
        // "CREDITO", descontinuado
        "DEBITO_CONTA",
        "DEPOSITO_BANCARIO",
        "PACOTE",
        "PERMUTA_PAGAR",
        "PERMUTA_RECEBER",
        "PIX",
        "SAMAE"
      ],

      idSitePrincipal: null,
      direitoCriar: false,
      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,

      //mode: "",
      //timeout: 6000,
      //autoUpdate: true,
      isUpdating: false
    };
  },

  async created() {
    this.id = this.$route.params.id ? this.$route.params.id : "";

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ADMINISTRATIVO_FORMAS_PAGAMENTO_CRIAR") {
        this.direitoCriar = true;
      }
    });

    //* Verifica se o site do usuário está em GRUPO - OBS: ou ele compartilha a informação ou deixa exclusiva para o seu site
    var idGrupoSite = this.$store.state.usuario.site_idGrupo;
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
    if (idGrupoSite != null) {
      this.siteGrupo = [
        {
          id: this.idSitePrincipal,
          nome: this.$store.state.usuario.site_nome
        }
      ];
    }

    this.carregarContas();

    try {
      //* Se é edição, carrega o item
      if (this.id == "") {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ADMINISTRATIVO_FORMAS_PAGAMENTO_CRIAR"]);
      } else {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ADMINISTRATIVO_FORMAS_PAGAMENTO_EDITAR"]);
        //* Carrega o item - somente se for público (idSite=null) ou for do site do usuário logado
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/forma-pagamento/" + this.id + "?idSite=" + this.idSitePrincipal);

        if (!response.data) {
          this.$router.push("/administrativo/formas-pagamento");
        } else {
          this.formaPagamento = response.data;
        }
      }
    } catch {
      this.mensagem = "Ocorreu um erro ao carregar os dados";
      this.snackErro = true;
    }
  }
};
</script>
