<template>
  <div id="app">
    <v-app id="inspire">
      <v-card class="hide-overflow fill-height">
        <v-toolbar height="50" color="#333333">
          <v-icon color="white" class="mr-2">{{ remessa ? "trending_up" : "trending_down" }}</v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light"> {{ remessa ? "Remessas" : "Retornos" }} Celesc </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="success" fab small v-if="remessa" @click="gerarRemessa" class="ma-1">
            <v-icon color="white" title="Criação de Remessa Celesc">offline_bolt</v-icon>
          </v-btn>
          <v-btn v-if="!remessa" color="success" fab small @click="dialogUpload = true">
            <v-icon color="white" title="Envio de Arquivo Retorno">cloud_upload</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="px-6 mt-4">
          <v-layout wrap>
            <v-spacer></v-spacer>
            <v-flex md1 xs2>
              <v-btn v-if="!remessa" color="blue" :loading="loading" :disabled="loading" fab small @click="pesquisaDados(true)">
                <v-icon color="white" title="Exportar">description</v-icon>
              </v-btn>
            </v-flex>
            <v-flex xs12 md5>
              <v-text-field
                background-color="white"
                class="mt-2 mb-2 elevation-0"
                v-model="filtroPesquisa"
                append-icon="search"
                label="Digite para pesquisar..."
                placeholder="Pesquisa pela competência(MM/AAAA)"
                hide-details
                @keyup.enter="limpaFiltro"
                @click:append="limpaFiltro"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-dialog persistente v-model="dialogUpload" max-width="400px">
            <v-card>
              <v-toolbar>
                <span class="headline">Envio de Arquivo</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="dialogUpload = false">
                  <v-icon color="darken-1">cancel</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <FileUpload :retorno="true" :receber="false" :celesc="true" />
              </v-card-text>
            </v-card>
          </v-dialog>

          <v-data-table
            mobile-breakpoint="0"
            :headers="remessa ? headers : headersRetorno"
            :items="registros"
            :options.sync="options"
            :no-data-text="mensagemPesquisa"
            :footer-props="rodape"
            :disable-sort="carregando"
            :disable-pagination="carregando"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon v-if="direitoTitulos && item.status != 'EXCLUÍDO'" color="blue" title="Visualizar Parcelas" @click="visualizarTitulos(item)">add_circle_outline</v-icon>
              <v-icon
                v-if="item.status == 'CORRENTE' && (item.remessa == 'CRIADA' || item.remessa == 'ENVIADA') && item.arquivo && remessa"
                :color="item.remessa == 'ENVIADA' ? 'red' : 'blue'"
                title="Download Arquivo Remessa"
                @click="downloadRemessa(item)"
                >cloud_download</v-icon
              >
              <v-btn icon v-if="!remessa && item.idSite == 47" :loading="isUpdating" @click="downloadXMLRetorno(item)">
                <v-icon color="blue" title="Download Retorno XML">cloud_download</v-icon></v-btn
              >
            </template>

            <template v-slot:item.dtCriacao="{ item }">{{ formatDateTime(item.dtCriacao) }}</template>

            <template v-slot:item.remessa="{ item }">
              <span v-if="remessa">{{ item.remessa }}</span>
            </template>

            <template v-slot:item.dtRemessa="{ item }">
              <span v-if="remessa">{{ formatDateTime(item.dtRemessa) }}</span>
            </template>

            <template v-slot:item.arquivo="{ item }">
              <span v-if="!remessa">{{ item.arquivo }}</span>
            </template>

            <template v-slot:item.valor="{ item }">{{ formatCurrency(item.valor) }}</template>

            <template v-slot:item.qtde="{ item }">{{ formatNumber(item.qtde) }}</template>

            <template v-slot:item.titulos="{ item }">{{ formatNumber(item.titulos) }}</template>
          </v-data-table>
          <div class="text-xs-center pt-2 marg-bt">
            <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 7" circle></v-pagination>
          </div>
        </div>
        <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
        <v-snackbar v-model="snackErro" color="error" :timeout="10000" relative bottom left>{{ mensagem }}</v-snackbar>
      </v-card>
    </v-app>
  </div>
</template>

<script>
import FileUpload from "@/views/components/FileUpload.vue";
import util from "../../util.js";
import { api } from "@/services/api.js";
import download from "downloadjs";
import { mapMutations } from "vuex";
export default {
  components: {
    FileUpload
  },
  data() {
    return {
      headers: [
        { text: "Ações", value: "actions", width: "160" },
        { text: "ID", value: "id" },
        { text: "Número", value: "numero" },
        { text: "Emissão", value: "dtCriacao" },
        { text: "Status Remessa", value: "remessa" },
        { text: "Remessa", value: "dtRemessa" },
        { text: "Valor", value: "valor", align: "end" },
        { text: "Itens", value: "qtde", align: "end" }
      ],
      headersRetorno: [
        { text: "Ações", value: "actions", width: "110" },
        { text: "ID", value: "id" },
        { text: "Sigla", value: "sigla" },
        { text: "Site", value: "site", width: 200 },
        { text: "Número", value: "numero" },
        { text: "Emissão", value: "dtCriacao" },
        { text: "Arquivo", value: "arquivo" },
        { text: "Arrecadado", value: "valor", align: "end" },
        { text: "Itens", value: "titulos", align: "end" }
      ],

      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },

      dialogUpload: false,

      isUpdating: false,

      direitoTitulos: false,

      mensagem: "",
      snackbar: false,
      snackErro: false,

      idSitePrincipal: null,
      mensagemPesquisa: "",
      filtroPesquisa: "",
      registros: [],
      registrosPagina: 10,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      carregando: true,
      ordenacao: "",
      ordem: "",
      options: {},
      loader: null,
      loading: false
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    limpaFiltro() {
      this.paginaAtual = 1;
      this.pesquisaDados();
    },
    async pesquisaDados(exportar) {
      var l = null;
      if (exportar) {
        this.loader = "loading";
        l = this.loader;
        this[l] = !this[l];
        setTimeout(() => (this[l] = false), 5000);
        this.loader = null;
      }

      //* Sinaliza que está carregando
      this.carregando = true;
      this.mensagemPesquisa = "Carregando...";
      this.loading = true;

      this.registros = [];

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "fin/lote-faturamento/celesc" +
            (this.remessa ? "/0" : "/1") +
            "?idSite=" +
            this.idSitePrincipal +
            "&pagina=" +
            this.paginaAtual +
            "&tamanho=" +
            this.registrosPagina +
            "&filtro=" +
            this.filtroPesquisa +
            "&ordenacao=" +
            this.ordenacao +
            "&ordem=" +
            this.ordem +
            (exportar ? "&exportar=1" : "")
        );

        //seta as variaveis com o retorno
        this.registros = response.data.data;
        this.totalRegistros = response.data.total;
        this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
        this.rodape.pageText = this.totalRegistros + " lotes - " + util.formatNumber(response.data.itens) + " itens = R$ " + util.formatCurrency(response.data.soma);
        //fecha o loading
        this.carregando = false;
        //se não achar registro seta a mensagem senão deixa em branco
        this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else {
          this.mensagem =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
          this.snackErro = true;
        }
      } finally {
        this.loading = false;
      }
    },

    async loteCelesc() {
      const loteCommit = {
        idSite: this.idSitePrincipal,
        tipo: 1,
        origem: 0,
        parcelas: [] //* Parcelas Celesc serão buscadas por script
      };

      try {
        await api.post(process.env.VUE_APP_URL_WEB_API + "fin/lote-faturamento?idSite=" + this.idSitePrincipal + "&forma=CELESC", loteCommit);

        this.mensagem = "Lote(s) criado(s) com sucesso";
        this.snackbar = true;
        this.pesquisaDados();
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },

    async gerarRemessa() {
      //* Cria lote remessa e gera arquivo remessa a ser enviado a Celesc
      try {
        this.isUpdating = true;
        await api.post(process.env.VUE_APP_URL_WEB_API + "fin/celesc/remessaAdjori");

        this.pesquisaDados();
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      } finally {
        this.isUpdating = false;
      }
    },

    visualizarTitulos(item) {
      window.open("/financeiro/celesc-titulos?" + (this.remessa ? "idLoteRemessa=" : "idLoteRetorno=") + item.id, "_blank");
    },

    async downloadRemessa(item) {
      this.dadoIndex = this.registros.indexOf(item);
      this.dadoItem = Object.assign({}, item);

      var caminhoRemessa = "";
      var msg = "";
      if (item.arquivo) {
        caminhoRemessa = `arquivos/${this.idSitePrincipal}/celescs/remessas/${item.arquivo}`;
      } else {
        msg = "Arquivo remessa não emitido";
      }
      if (caminhoRemessa) {
        download(process.env.VUE_APP_URL_WEB_API + caminhoRemessa);
        if (item.remessa != "ENVIADA") {
          try {
            await api.put(process.env.VUE_APP_URL_WEB_API + "fin/lote-faturamento/enviada/" + item.id + "?idSite=" + this.idSitePrincipal + "&tipo=" + item.tipo);
            this.dadoItem.remessa = "ENVIADA";
            this.dadoItem.dtRemessa = new Date();
            Object.assign(this.registros[this.dadoIndex], this.dadoItem);
          } catch (e) {
            this.mensagem =
              "ERRO: " +
              (e.toString().includes("status code 400")
                ? "SESSÃO EXPIRADA"
                : e.response && e.response.data && e.response.data.length > 0
                ? e.response.data[0].mensagem
                : "Indeterminado, verifique sua conexão");
            this.snackErro = true;
          }
        }
      } else {
        this.mensagem = msg;
        this.snackErro = true;
      }
    },
    async downloadXMLRetorno(item) {
      try {
        this.isUpdating = true;
        await api.post(process.env.VUE_APP_URL_WEB_API + "fin/celesc/retorno", {
          idSite: item.idSite,
          lote: item.id
        });
        download(process.env.VUE_APP_URL_WEB_API + "arquivos/" + item.idSite + "/celescs/retornos/" + item.id + ".xml");
        setTimeout(() => {
          this.isUpdating = false;
        }, 9000);
      } catch (e) {
        this.isUpdating = false;
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },

    formatDate(date) {
      return util.formatDate(date);
    },
    formatDateTime(datetime) {
      return util.formatDateTime(datetime);
    },
    formatNumber(valor) {
      return util.formatNumber(valor);
    },
    formatCurrency(valor) {
      return util.formatCurrency(valor);
    }
  },

  beforeMount() {
    if (this.remessa) {
      this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_CELESC_REMESSAS_ACESSO"]);
    } else {
      this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_CELESC_RETORNOS_ACESSO"]);
    }

    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
    if (this.idSitePrincipal != 46) {
      this.mensagem = "Somente Adjori pode acessar esta tela!";
      this.snackErro = true;
      setTimeout(() => {
        this.$router.push("/acesso-negado");
        return;
      }, 3000);
    }

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;

      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_CELESC_TITULOS_ACESSO") {
        this.direitoTitulos = true;
      }
    });

    if (!this.remessa) {
      this.filtroPesquisa = util.formatDate(new Date()).substring(3);
    }
  },

  computed: {
    remessa() {
      return this.$route.path.indexOf("-remessas") > -1;
    }
  },

  watch: {
    $route() {
      this.pesquisaDados();
    },
    paginaAtual() {
      this.pesquisaDados();
    },
    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }

        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }

        this.pesquisaDados();
      }
    }
  }
};
</script>
