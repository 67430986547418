<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">settings_input_antenna</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Configuração IVC</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs6 md3>
            <v-currency-field
              locale="pt-BR"
              v-model="vlrTabelaIVC"
              label="Valor de Tabela do IVC"
              :allowNegative="false"
              :max="99"
              :rules="[validacao.vlrTabelaIVC == false || validacao.vlrTabelaIVC, value => !!value || 'Obrigatório.', value => (value && value.length < 5) || 'Verifique o valor']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.vlrTabelaIVC = false"
            ></v-currency-field>
          </v-flex>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">
              {{ validacao.mensagemErro }}
            </v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn to="/assinaturas/desktop" id="btn-rsn" color="orange lighten-2 white" style="color:white"> <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar </v-btn>
      <v-btn v-if="direitoEditar" :loading="isUpdating" color="blue white" style="color:white" id="btn-rsn" depressed @click="salvar('false')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      vlrTabelaIVC: 0,
      validacao: {
        vlrTabelaIVC: false,
        mensagemErro: false
      },

      idSitePrincipal: null,
      direitoEditar: false,
      elementoValid: true,
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    async salvar() {
      await util.limparObjeto(this.validacao, false);

      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      this.isUpdating = true;
      try {
        await api.put(process.env.VUE_APP_URL_WEB_API + "adm/site/ivc/" + this.idSitePrincipal, { vlrTabelaIVC: this.vlrTabelaIVC });
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    }
  },

  async created() {
    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_CONFIG_IVC_EDITAR") {
        this.direitoEditar = true;
      }
    });

    //* Verifica se o site do usuário está em GRUPO - OBS: ou ele compartilha a informação ou deixa exclusiva para o seu site
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

    try {
      this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_CONFIG_IVC_ACESSO"]);
      //* Carrega o elemento - somente se for público (idSite=null) ou for do site do usuário logado
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/site/ivc/" + this.idSitePrincipal);
      if (response.data) {
        this.vlrTabelaIVC = response.data.vlrTabelaIVC;
      }
    } catch {
      this.mensagem = "Ocorreu um erro ao carregar os dados";
      this.snackErro = true;
    }
  }
};
</script>
