<template>
  <div id="app">
    <v-app id="inspire">
      <v-card>
        <v-toolbar color="#333333">
          <v-icon color="white" class="mr-2">account_balance_wallet</v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light">
            Comissionamento
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container fluid grid-list-md>
          <v-layout wrap>
            <v-flex xs12 md2>
              <v-menu v-model="mnuDias" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px" :disabled="loading">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dias"
                    label="Período"
                    hint="Vencimento Original"
                    persistent-hint
                    prepend-icon="event"
                    v-on="on"
                    color="blue-grey lighten-2"
                    @click:clear="diasUS = ['', '']"
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker v-model="diasUS" locale="pt-br" no-title range
                  ><v-spacer></v-spacer>
                  <v-btn text color="primary" @click="mnuDias = false">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 md4>
              <v-autocomplete
                v-model="agenteSelecionado"
                :items="agentes"
                :filter="filtrarObjetos"
                label="Agente/Agência"
                item-text="nome"
                item-value="id"
                no-data-text
                return-object
              ></v-autocomplete>
            </v-flex>

            <v-flex md1 xs2 class="text-right">
              <v-btn color="blue" :loading="loading" :disabled="loading" fab small @click="comissionamento('agentes')" v-if="direitoAgentes">
                <v-icon color="white" title="Relatório Comissionamento Agentes">account_balance_wallet</v-icon>
              </v-btn>
            </v-flex>
            <v-flex md1 xs2>
              <v-btn color="blue" :loading="loading" :disabled="loading" fab small @click="comissionamento('usuarios')" v-if="direitoUsuarios">
                <v-icon color="white" title="Relatório Comissionamento Usuários">switch_account</v-icon>
              </v-btn>
            </v-flex>

            <v-flex xs12>
              <v-progress-linear v-model="value" :active="loading" class="ma-0" color="cyan accent-3" height="16" :indeterminate="value > 100"
                >Montando relatório. Aguarde...</v-progress-linear
              >
            </v-flex>
          </v-layout>
        </v-container>
        <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
        <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
      </v-card>
    </v-app>
  </div>
</template>
<script>
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
import util from "../../../util.js";
export default {
  data() {
    return {
      diasUS: ["", ""],
      mnuDias: false,
      agenteSelecionado: null,
      agentes: [],

      direitoAgentes: false,
      direitoUsuarios: false,

      mensagem: "",
      snackbar: false,
      snackErro: false,

      loading: false,
      value: 0
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    async comissionamento(tipo) {
      if (!this.agenteSelecionado || !this.agenteSelecionado.id) {
        this.mensagem = "Selecione o agente/agência";
        this.snackErro = true;
        return false;
      }
      if (!this.diasUS || this.diasUS.length != 2 || this.diasUS[0] == "" || this.diasUS[1] == "") {
        this.mensagem = "Verifique o período";
        this.snackErro = true;
        return false;
      }

      var dtInic = "";
      var dtFim = "";
      if (!util.parseDateUS(this.diasUS[0]) || !util.parseDateUS(this.diasUS[1]) || util.parseDateUS(this.diasUS[0]) > util.parseDateUS(this.diasUS[1])) {
        this.mensagem = "Verifique o período";
        this.snackErro = true;
        return false;
      } else {
        dtInic = util.formatDate(this.diasUS[0]);
        dtFim = util.formatDate(this.diasUS[1]);
      }

      this.mnuDias = false;

      this.value = 0;
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        this.value += 1;
      }, 3000);
      this.loading = true;

      try {
        //faz a pesquisa no banco de dados
        if (tipo == "agentes") {
          const response = await api.get(
            process.env.VUE_APP_URL_WEB_API +
              `ass/regra-comissao-agente/relatorio?idSite=${this.$store.state.usuario.idSitePrincipal}&idAgente=${this.agenteSelecionado.id}&agente=${this.agenteSelecionado.nome}&inicio=${dtInic}&fim=${dtFim}`
          );
          if (response.data) {
            this.mensagem = "Relatório exportado, verifique suas mensagens";
            this.snackbar = true;
          }
        }
        this.loading = false;
        return true;
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
        this.loading = false;
        return false;
      }
    },

    async carregarAgentes() {
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/regra-comissao-agente/agentes");
      if (response.data) {
        this.agentes = response.data;
      }
    },

    formatDate(date) {
      return util.formatDate(date);
    }
  },

  beforeMount() {
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_RELATÓRIOS_COMISSIONAMENTO_AGENTES", "ASSINATURAS_RELATÓRIOS_COMISSIONAMENTO_USUÁRIOS"]);

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_RELATÓRIOS_COMISSIONAMENTO_AGENTES") {
        this.direitoAgentes = true;
      }
      // if (direito == "WEBSUITA_ADMIN_SUITA") {
      //   // || direito == "ASSINATURAS_RELATÓRIOS_COMISSIONAMENTO_USUÁRIOS"
      //   this.direitoUsuarios = true;
      // }
    });

    this.carregarAgentes();
  },
  computed: {
    dias() {
      var retorno = "";
      if (this.diasUS && this.diasUS.length > 1) {
        this.diasUS.forEach(element => {
          if (element) {
            retorno += (retorno ? " - " : "") + util.formatDate(element);
          }
        });
      }
      return retorno;
    }
  }
};
</script>
