<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">dashboard</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Plano de Assinatura</v-toolbar-title>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <h4 class="overline font-weight-black font-italic">
              Dados Básicos
            </h4>
            <v-divider></v-divider>
          </v-flex>

          <v-flex xs12 md12 v-if="siteGrupo.length > 0">
            <v-autocomplete
              v-model="plano.idSite"
              :disabled="isUpdating"
              :items="siteGrupo"
              :filter="filtrarObjetos"
              label="Restrito ao Site"
              item-text="nome"
              item-value="id"
              :rules="[validacao.idSite == false || validacao.idSite]"
              clearable
              no-data-text
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md10>
            <v-text-field
              autofocus
              v-model="plano.nome"
              label="Nome do Plano"
              maxlength="80"
              hint="Obrigatório"
              :rules="[
                validacao.nome == false || validacao.nome,
                value => !!value || 'Obrigatório.',
                value => valida_nome(value) || 'Nome inválido',
                value => value.length > 2 || 'Preencha o nome do plano de assinatura'
              ]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="
                plano.nome = pLetra(plano.nome);
                validacao.nome = false;
              "
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md2>
            <v-text-field
              v-model="plano.sigla"
              label="Sigla"
              maxlength="4"
              :rules="[validacao.sigla == false || validacao.sigla]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="
                plano.sigla = plano.sigla.toUpperCase();
                validacao.sigla = false;
              "
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md5>
            <v-autocomplete
              v-model="modalidadeSelecionada"
              :disabled="isUpdating || (id != '' && !direitoAvancado)"
              :items="modalidades"
              :filter="filtrarObjetos"
              hint="Obrigatório"
              :rules="[validacao.idModalidade == false || validacao.idModalidade, value => (!!value && !!value.id) || 'Obrigatório.']"
              label="Selecione a Modalidade"
              item-text="nome"
              item-value="id"
              no-data-text
              return-object
              @change="validacao.idModalidade = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md5>
            <v-autocomplete
              v-model="planoMigrarSelecionado"
              disabled
              :items="planosMigrar"
              :filter="filtrarObjetos"
              hint="Se indicado, será migrado para este plano na próxima renovação"
              :rules="[validacao.idPlanoMigrar == false || validacao.idPlanoMigrar]"
              label="Migrar Para"
              item-text="nome"
              item-value="id"
              no-data-text
              return-object
              @change="validacao.idPlanoMigrar = false"
              clearable
            ></v-autocomplete>
          </v-flex>

          <v-flex xs3 md2>
            <v-text-field
              v-model="plano.enderecosAdic"
              label="Endereços Adicionais"
              v-mask="'#'"
              :rules="[
                validacao.enderecosAdic == false || validacao.enderecosAdic,
                value => value != null || 'Obrigatório.',
                value => value < 10 || 'Máximo 9 endereços adicionais'
              ]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.enderecosAdic = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md3>
            <v-switch
              v-model="plano.renovacaoTabela"
              :label="`${plano.renovacaoTabela == 1 ? 'Renovação pelo Preço de Tabela' : 'Renovação pelo Valor da Última Renovação'}`"
              :disabled="isUpdating || (id != '' && !direitoAvancado)"
            ></v-switch>
          </v-flex>

          <v-flex xs12 md3>
            <v-switch v-model="plano.renovacaoAutomatica" :label="`${plano.renovacaoAutomatica == 1 ? 'Renovação Automática' : 'Renovação Manual'}`"></v-switch>
          </v-flex>
          <v-flex xs12 md3>
            <v-switch
              v-model="plano.cartaoIntegral"
              :label="`${plano.cartaoIntegral == 1 ? 'Cobrar TODAS as parcelas no cartão' : 'Cobrar apenas a próxima parcela no cartão'}`"
            ></v-switch>
          </v-flex>

          <v-flex xs12 md3>
            <v-switch
              v-model="plano.validarEndereco"
              :label="`${plano.validarEndereco == 1 ? 'Endereço será validado na captação' : 'Endereço não será validado na captação'}`"
            ></v-switch>
          </v-flex>

          <v-flex xs12 md3>
            <v-switch
              v-model="plano.situacao"
              :label="`${plano.situacao == 1 ? 'Ativo' : 'Inativo'}`"
              :disabled="isUpdating || (id != '' && plano.situacao == 1 && !direitoAvancado)"
            ></v-switch>
          </v-flex>

          <v-flex xs12 v-if="plano.idPlanoMigrar != null">
            <v-alert :value="true" color="red" icon="forward" outlined>Este plano está em processo de migração e não pode mais ser editado.</v-alert>
          </v-flex>
        </v-layout>

        <v-layout wrap>
          <v-expansion-panels v-model="painel" focusable>
            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.produtos ? 'red--text' : ''">
                <strong>Produtos</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-toolbar>
                      <v-spacer></v-spacer>
                      <v-dialog persistent v-model="dialogProduto" max-width="600px">
                        <template v-slot:activator="{ on }">
                          <v-btn color="success" fab small v-on="on">
                            <v-icon color="white" title="Incluir">add</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form ref="formProduto" v-model="produtoValid" lazy-validation>
                            <v-toolbar>
                              <span class="headline">Produto</span>
                              <v-spacer></v-spacer>
                              <v-btn icon @click="closeProduto">
                                <v-icon color="darken-1">cancel</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-container grid-list-md>
                                <v-layout wrap>
                                  <v-flex xs12 md6>
                                    <v-autocomplete
                                      autofocus
                                      v-model="produtoSelecionado"
                                      :items="produtos"
                                      :rules="[value => (!!value && !!value.id) || 'Obrigatório.']"
                                      :filter="filtrarObjetos"
                                      label="Produto"
                                      item-text="nome"
                                      item-value="id"
                                      no-data-text
                                      :disabled="produtoItem.id != null"
                                      return-object
                                    ></v-autocomplete>
                                  </v-flex>

                                  <v-flex xs12 md6>
                                    <v-currency-field
                                      :locale="`pt-${$store.state.usuario.site_pais}`"
                                      v-model="produtoItem.valor"
                                      label="Valor de Tabela"
                                      :allowNegative="false"
                                      :max="99999"
                                      :rules="[
                                        value => !!value || 'Obrigatório.',
                                        value => (value && parseFloat(value.replace(',', '.')) > 0 && value.length < 10) || 'Valor de tabela do produto inválido'
                                      ]"
                                      color="blue-grey lighten-2"
                                      :readonly="produtoItem.id != null"
                                      @change="
                                        produtoItem.valorMinimo == 0 ? (produtoItem.valorMinimo = produtoItem.valor) : 0,
                                          produtoItem.valorMaximo == 0 ? (produtoItem.valorMaximo = produtoItem.valor) : 0
                                      "
                                    ></v-currency-field>
                                  </v-flex>

                                  <v-flex xs12 md6>
                                    <v-currency-field
                                      :locale="`pt-${$store.state.usuario.site_pais}`"
                                      v-model="produtoItem.valorMinimo"
                                      label="Valor Mínimo"
                                      :allowNegative="false"
                                      :max="99999"
                                      :rules="[
                                        value => !!value || 'Obrigatório.',
                                        value =>
                                          (value && value.length < 10 && parseFloat(value.replace('.', '').replace(',', '.')) <= produtoItem.valor) ||
                                          'Valor mínimo do produto inválido'
                                      ]"
                                      color="blue-grey lighten-2"
                                      :readonly="produtoItem.id != null"
                                    ></v-currency-field>
                                  </v-flex>

                                  <v-flex xs12 md6>
                                    <v-currency-field
                                      :locale="`pt-${$store.state.usuario.site_pais}`"
                                      v-model="produtoItem.valorMaximo"
                                      label="Valor Máximo"
                                      :allowNegative="false"
                                      :max="99999"
                                      :rules="[
                                        value => !!value || 'Obrigatório.',
                                        value =>
                                          (value && value.length < 10 && parseFloat(value.replace('.', '').replace(',', '.')) >= produtoItem.valor) ||
                                          'Valor máximo do produto inválido'
                                      ]"
                                      color="blue-grey lighten-2"
                                      :readonly="produtoItem.id != null"
                                    ></v-currency-field>
                                  </v-flex>

                                  <v-flex xs12 md6>
                                    <v-switch
                                      v-model="produtoItem.obrigatorio"
                                      :label="`${produtoItem.obrigatorio == 1 ? 'Obrigatório' : 'Opcional'}`"
                                      :readonly="produtoItem.id != null"
                                    ></v-switch>
                                  </v-flex>
                                  <v-flex xs12 v-if="produtoItem.produtoTipo == 'IMPRESSO'">
                                    <v-autocomplete
                                      v-model="produtoItem.period"
                                      :items="dias"
                                      label="Dias de Entrega"
                                      multiple
                                      :filter="filtrarItens"
                                      :rules="[
                                        value =>
                                          (value.length == 0 && produtoItem.produtoTipo != 'IMPRESSO') ||
                                          (value.length != 0 && produtoItem.produtoTipo == 'IMPRESSO') ||
                                          'Selecione pelo menos 1 dia da semana'
                                      ]"
                                      :disabled="produtoItem.id != null"
                                      no-data-text
                                    ></v-autocomplete>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" @click="closeProduto">Retornar</v-btn>
                              <v-btn v-if="produtoItem.id == null" color="blue darken-1" @click="saveProduto">Atualizar</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="headerProdutos"
                      :items="produtosPlano"
                      no-data-text
                      no-results-text
                      :footer-props="{
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageOptions: [10, 25, 50],
                        pageText: ''
                      }"
                    >
                      <template v-slot:item.actions="{ item }">
                        <v-icon class="mr-2" color="blue lighten-2" :title="item.id == null ? 'Editar' : 'Visualizar'" @click="editarProduto(item)">{{
                          item.id == null ? "edit" : "loupe"
                        }}</v-icon>
                        <v-icon
                          :color="item.situacao == 1 ? 'blue lighten-1' : 'blue-grey lighten-3'"
                          :title="item.situacao == 1 ? 'Desativar' : 'Desativado'"
                          @click="desativarProduto(item)"
                          :disabled="!item.situacao || (id != '' && !direitoAvancado)"
                        >
                          {{ item.situacao == 1 ? "radio_button_checked" : "radio_button_unchecked" }}
                        </v-icon>
                      </template>

                      <template v-slot:item.obrigatorio="{ item }">
                        <span v-html="item.obrigatorio ? 'Sim' : ''"></span>
                      </template>

                      <template v-slot:item.produtoSituacao="{ item }">
                        <span v-html="item.produtoSituacao == 1 ? 'Ativo' : '<strong>Inativo</strong>'"></span>
                      </template>
                    </v-data-table>
                    <v-alert v-if="validacao.produtos" :value="true" color="error" icon="priority_high" outlined>{{ validacao.produtos }}</v-alert>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.agentes ? 'red--text' : ''">
                <strong>Agentes/Agências</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-toolbar>
                      <v-spacer></v-spacer>
                      <v-dialog persistent v-model="dialogAgente" max-width="600px">
                        <template v-slot:activator="{ on }">
                          <v-btn color="success" fab small v-on="on">
                            <v-icon color="white" title="Incluir">add</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form ref="formAgente" v-model="agenteValid" lazy-validation>
                            <v-toolbar>
                              <span class="headline">Agente/Agência</span>
                              <v-spacer></v-spacer>
                              <v-btn icon @click="closeAgente">
                                <v-icon color="darken-1">cancel</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-container grid-list-md>
                                <v-layout wrap>
                                  <v-flex xs12 md12>
                                    <v-autocomplete
                                      autofocus
                                      v-model="agenteSelecionado"
                                      :items="agentes"
                                      :filter="filtrarObjetos"
                                      hint="Obrigatório"
                                      :rules="[value => (!!value && !!value.id) || 'Obrigatório.']"
                                      label="Agente/Agência"
                                      item-text="nome"
                                      item-value="id"
                                      no-data-text
                                      :disabled="agenteItem.id != null"
                                      return-object
                                    ></v-autocomplete>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" @click="closeAgente">Retornar</v-btn>
                              <v-btn color="blue darken-1" @click="saveAgente" v-if="agenteItem.id == null">Atualizar</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="headerAgentes"
                      :items="agentesPlano"
                      no-data-text="Deixe em branco caso deseje que TODOS os agentes ATIVOS estejam disponíveis para este plano."
                      no-results-text
                      :footer-props="{
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageOptions: [10, 25, 50],
                        pageText: ''
                      }"
                    >
                      <template v-slot:item.actions="{ item }">
                        <v-icon class="mr-2" color="blue lighten-2" :title="item.id == null ? 'Editar' : 'Visualizar'" @click="editarAgente(item)">{{
                          item.id == null ? "edit" : "loupe"
                        }}</v-icon>
                        <v-icon
                          :color="item.situacao == 1 ? 'blue lighten-1' : 'blue-grey lighten-3'"
                          :title="item.situacao == 1 ? 'Desativar' : 'Desativado'"
                          @click="desativarAgente(item)"
                        >
                          {{ item.situacao == 1 ? "radio_button_checked" : "radio_button_unchecked" }}
                        </v-icon>
                      </template>

                      <template v-slot:item.agenteSituacao="{ item }">
                        <span v-html="item.agenteSituacao == 1 ? 'Ativo' : '<strong>Inativo</strong>'"></span>
                      </template>
                    </v-data-table>
                    <v-alert v-if="validacao.agentes" :value="true" color="error" icon="priority_high" outlined>{{ validacao.agentes }}</v-alert>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.formasPagamento ? 'red--text' : ''">
                <strong>Formas de Pagamento</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-toolbar>
                      <v-spacer></v-spacer>
                      <v-btn color="success" fab small @click="incluirFormaPagamento">
                        <v-icon color="white" title="Incluir">add</v-icon>
                      </v-btn>
                      <v-dialog persistent v-model="dialogFormaPagamento" max-width="600px">
                        <v-card>
                          <v-form ref="formFormaPagamento" v-model="formaPagamentoValid" lazy-validation>
                            <v-toolbar>
                              <span class="headline">Forma de Pagamento</span>
                              <v-spacer></v-spacer>
                              <v-btn icon @click="closeFormaPagamento">
                                <v-icon color="darken-1">cancel</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-container grid-list-md>
                                <v-layout wrap>
                                  <v-flex xs12 md12>
                                    <v-autocomplete
                                      autofocus
                                      v-model="formaPagamentoSelecionada"
                                      :items="formasPagamento"
                                      :filter="filtrarObjetos"
                                      :rules="[value => (!!value && !!value.id) || 'Obrigatório.']"
                                      hint="Obrigatório"
                                      label="Forma de Pagamento"
                                      item-text="nome"
                                      item-value="id"
                                      no-data-text
                                      :disabled="formaPagamentoItem.id != null"
                                      return-object
                                    ></v-autocomplete>
                                  </v-flex>
                                  <v-flex xs12 md6>
                                    <v-text-field
                                      v-model="formaPagamentoItem.maxParcelas"
                                      label="Limite de Parcelas"
                                      v-mask="'##'"
                                      hint="Obrigatório"
                                      :rules="[
                                        value => !!value || 'Obrigatório.',
                                        value => (value > 0 && value < 31) || 'Limite deve ser de 1 a 30',
                                        value => verificaNroParcelas(value)
                                      ]"
                                      :disabled="isUpdating"
                                      color="blue-grey lighten-2"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      :readonly="isFormaPgto1Parcela(formaPagamentoItem.formaPagamentoTipo)"
                                    ></v-text-field>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" @click="closeFormaPagamento">Retornar</v-btn>
                              <v-btn color="blue darken-1" @click="saveFormaPagamento">Atualizar</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="headerFormasPagamento"
                      :items="formasPagamentoPlano"
                      no-data-text
                      no-results-text
                      :footer-props="{
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageOptions: [10, 25, 50],
                        pageText: ''
                      }"
                    >
                      <template v-slot:item.actions="{ item }">
                        <v-icon class="mr-2" color="blue lighten-2" :title="item.id == null ? 'Editar' : 'Visualizar'" @click="editarFormaPagamento(item)">{{
                          item.id == null ? "edit" : "loupe"
                        }}</v-icon>
                        <v-icon
                          :color="item.situacao == 1 ? 'blue lighten-1' : 'blue-grey lighten-3'"
                          :title="item.situacao == 1 ? 'Desativar' : 'Desativado'"
                          @click="desativarFormaPagamento(item)"
                          :disabled="!item.situacao || (id != '' && !direitoAvancado)"
                        >
                          {{ item.situacao == 1 ? "radio_button_checked" : "radio_button_unchecked" }}
                        </v-icon>
                      </template>

                      <template v-slot:item.formaPagamentoSituacao="{ item }">
                        <span v-html="item.formaPagamentoSituacao == 1 ? 'Ativa' : '<strong>Inativa</strong>'"></span>
                      </template>
                    </v-data-table>
                    <v-alert v-if="validacao.formasPagamento" :value="true" color="error" icon="priority_high" outlined>{{ validacao.formasPagamento }}</v-alert>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.regrasAjuste ? 'red--text' : ''">
                <strong>Regras de Ajuste de Preços</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-toolbar>
                      <v-spacer></v-spacer>
                      <v-dialog persistent v-model="dialogRegraAjuste" max-width="600px">
                        <template v-slot:activator="{ on }">
                          <v-btn color="success" fab small v-on="on">
                            <v-icon color="white" title="Incluir">add</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form ref="formRegraAjuste" v-model="regraAjusteValid" lazy-validation>
                            <v-toolbar>
                              <span class="headline">Regra de Ajuste</span>
                              <v-spacer></v-spacer>
                              <v-btn icon @click="closeRegraAjuste">
                                <v-icon color="darken-1">cancel</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-container grid-list-md>
                                <v-layout wrap>
                                  <v-flex xs12 md12>
                                    <v-autocomplete
                                      autofocus
                                      v-model="regraAjusteSelecionada"
                                      :items="regrasAjuste"
                                      :filter="filtrarObjetos"
                                      :rules="[value => (!!value && !!value.id) || 'Obrigatório.']"
                                      label="Regra de Ajuste"
                                      item-text="nome"
                                      item-value="id"
                                      no-data-text
                                      :disabled="regraAjusteItem.id != null"
                                      return-object
                                    ></v-autocomplete>
                                  </v-flex>
                                  <v-flex xs12 md12>
                                    <v-autocomplete
                                      v-model="regraAjusteItem.automatica"
                                      :items="automaticos"
                                      :filter="filtrarObjetos"
                                      :rules="[value => value != null || 'Obrigatório.']"
                                      label="Aplicação"
                                      item-text="nome"
                                      item-value="id"
                                      no-data-text
                                      :disabled="regraAjusteItem.id != null"
                                    ></v-autocomplete>
                                  </v-flex>
                                  <v-flex xs12>
                                    <v-switch
                                      v-model="regraAjusteItem.online"
                                      :label="`${regraAjusteItem.online == 1 ? 'Regra Padrão para Captação Online' : 'Regra não é padrão para captação online'}`"
                                      :disabled="regraAjusteItem.id != null || regraAjusteItem.regraAjusteDisponivel == 2"
                                    ></v-switch>
                                  </v-flex>
                                  <v-flex xs12 v-if="regraAjusteItem.online && regraAjusteItem.automatica != 3">
                                    <v-alert :value="true" color="error" icon="priority_high" outlined>{{
                                      regraAjusteItem.automatica == 0
                                        ? "Para regra padrão ONLINE, aplicação não pode ser Manual."
                                        : regraAjusteItem.automatica == 1
                                        ? "Se você utilizar esta aplicação para regra padrão ONLINE, deverá ter outra regra para Renovação."
                                        : regraAjusteItem.automatica == 2
                                        ? "Se você utilizar esta aplicação para regra padrão ONLINE, deverá ter outra regra para Captação."
                                        : ""
                                    }}</v-alert>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" @click="closeRegraAjuste">Retornar</v-btn>
                              <v-btn v-if="regraAjusteItem.id == null" color="blue darken-1" @click="saveRegraAjuste">Atualizar</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="headerRegrasAjuste"
                      :items="regrasAjustePlano"
                      no-data-text="Deixe em branco caso deseje utilizar a regra de ajuste PADRÃO para Assinaturas."
                      no-results-text
                      :footer-props="{
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageOptions: [10, 25, 50],
                        pageText: ''
                      }"
                    >
                      <template v-slot:item.actions="{ item }">
                        <v-icon class="mr-2" color="blue lighten-2" :title="item.id == null ? 'Editar' : 'Visualizar'" @click="editarRegraAjuste(item)">{{
                          item.id == null ? "edit" : "loupe"
                        }}</v-icon>
                        <v-icon
                          v-if="item.regraAjusteDisponivel != 2 && item.situacao == 1"
                          :color="item.online == 1 ? 'blue lighten-1' : 'blue-grey lighten-3'"
                          :title="item.online == 1 ? 'Desmarcar ONLINE' : 'Marcar como ONLINE'"
                          @click="marcarDesmarcarOnline(item)"
                          :disabled="id != '' && !direitoAvancado"
                        >
                          {{ item.online == 1 ? "wb_cloudy" : "cloud_off" }}
                        </v-icon>
                        <v-icon
                          :color="item.situacao == 1 ? 'blue lighten-1' : 'blue-grey lighten-3'"
                          :title="item.situacao == 1 ? 'Desativar' : 'Desativado'"
                          @click="desativarRegraAjuste(item)"
                          :disabled="!item.situacao || (id != '' && !direitoAvancado)"
                        >
                          {{ item.situacao == 1 ? "radio_button_checked" : "radio_button_unchecked" }}
                        </v-icon>
                      </template>
                      <template v-slot:item.regraAjusteDisponivel="{ item }">
                        {{
                          item.regraAjusteDisponivel == 1
                            ? "Captação"
                            : item.regraAjusteDisponivel == 2
                            ? "Recuperação"
                            : item.regraAjusteDisponivel == 3
                            ? "Captação e Recuperação"
                            : "PADRÃO"
                        }}
                      </template>

                      <template v-slot:item.automatica="{ item }">
                        {{
                          item.automatica == 1
                            ? "Captação (Automático)"
                            : item.automatica == 2
                            ? "Renovação (Automático)"
                            : item.automatica == 3
                            ? "Captação e Renovação (Automático)"
                            : "Usuário (Manual)"
                        }}
                      </template>

                      <template v-slot:item.regraAjusteSituacao="{ item }">
                        <span v-html="item.regraAjusteSituacao == 1 ? 'Ativa' : '<strong>Inativa</strong>'"></span>
                      </template>
                    </v-data-table>
                    <v-alert v-if="validacao.regrasAjuste" :value="true" color="error" icon="priority_high" outlined>{{ validacao.regrasAjuste }}</v-alert>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.promocoes ? 'red--text' : ''">
                <strong>Promoções</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-toolbar>
                      <v-spacer></v-spacer>
                      <v-dialog persistent v-model="dialogPromocao" max-width="600px">
                        <template v-slot:activator="{ on }">
                          <v-btn color="success" fab small v-on="on">
                            <v-icon color="white" title="Incluir">add</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form ref="formPromocao" v-model="promocaoValid" lazy-validation>
                            <v-toolbar>
                              <span class="headline">Promoção</span>
                              <v-spacer></v-spacer>
                              <v-btn icon @click="closePromocao">
                                <v-icon color="darken-1">cancel</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-container grid-list-md>
                                <v-layout wrap>
                                  <v-flex xs12 md12>
                                    <v-autocomplete
                                      autofocus
                                      v-model="promocaoSelecionada"
                                      :items="promocoes"
                                      :rules="[value => (!!value && !!value.id) || 'Obrigatório.']"
                                      :filter="filtrarObjetos"
                                      label="Promoção"
                                      item-text="nome"
                                      item-value="id"
                                      no-data-text
                                      :disabled="promocaoItem.id != null"
                                      return-object
                                    ></v-autocomplete>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" @click="closePromocao">Retornar</v-btn>
                              <v-btn v-if="promocaoItem.id == null" color="blue darken-1" @click="savePromocao">Atualizar</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="headerPromocoes"
                      :items="promocoesPlano"
                      no-data-text
                      no-results-text
                      :footer-props="{
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageOptions: [10, 25, 50],
                        pageText: ''
                      }"
                    >
                      <template v-slot:item.actions="{ item }">
                        <v-icon class="mr-2" color="blue lighten-2" :title="item.id == null ? 'Editar' : 'Visualizar'" @click="editarPromocao(item)">{{
                          item.id == null ? "edit" : "loupe"
                        }}</v-icon>
                        <v-icon
                          :color="item.situacao == 1 ? 'blue lighten-1' : 'blue-grey lighten-3'"
                          :title="item.situacao == 1 ? 'Desativar' : 'Desativado'"
                          @click="desativarPromocao(item)"
                        >
                          {{ item.situacao == 1 ? "radio_button_checked" : "radio_button_unchecked" }}
                        </v-icon>
                      </template>

                      <template v-slot:item.promocaoSituacao="{ item }">
                        <span v-html="item.promocaoSituacao == 1 ? 'Ativa' : '<strong>Inativa</strong>'"></span>
                      </template>
                    </v-data-table>
                    <v-alert v-if="validacao.promocoes" :value="true" color="error" icon="priority_high" outlined>{{ validacao.promocoes }}</v-alert>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">{{ validacao.mensagemErro }}</v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn to="/assinaturas/planos" id="btn-rsn" color="orange lighten-2 white" style="color:white"> <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar </v-btn>
      <v-btn
        v-if="plano.idPlanoMigrar == null"
        :loading="isUpdating"
        :disabled="bloqueiaBotoes"
        color="blue white"
        style="color:white"
        id="btn-rsn"
        depressed
        @click="salvar('false')"
      >
        <v-icon id="icon-rsn" left>update</v-icon>Salvar
      </v-btn>
      <v-btn
        v-if="plano.idPlanoMigrar == null"
        :loading="isUpdating"
        :disabled="bloqueiaBotoes"
        color="orange white"
        style="color:white"
        id="btn-rsn"
        outlined
        @click="salvar('retornar')"
      >
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>
      <v-btn
        :loading="isUpdating"
        :disabled="bloqueiaBotoes"
        color="blue white"
        style="color:white"
        id="btn-rsn"
        outlined
        @click="salvar('true')"
        v-if="direitoCriar && plano.idPlanoMigrar == null"
      >
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Novo
      </v-btn>
    </v-card-actions>
    <v-dialog v-model="dialogConfirm" max-width="290">
      <v-card>
        <v-toolbar>
          <span class="headline">Confirmação</span>
        </v-toolbar>
        <v-card-text class="mt-2" v-html="msgConfirm"></v-card-text>
        <v-card-actions>
          <v-btn color="red darken-1" text @click="naoConfirmado">Não</v-btn>
          <v-btn color="green darken-1" text @click="confirmado">Sim</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      plano: {
        id: "",
        idSite: null,
        idModalidade: null,
        idPlanoMigrar: null,
        nome: "",
        sigla: null,
        renovacaoTabela: 1,
        enderecosAdic: 0,
        validarEndereco: 0,
        renovacaoAutomatica: 1,
        cartaoIntegral: 0,
        rede: 0,
        situacao: 1
      },
      validacao: {
        id: false,
        idSite: false,
        idModalidade: false,
        idPlanoMigrar: false,
        nome: false,
        sigla: false,
        renovacaoTabela: false,
        enderecosAdic: false,
        validarEndereco: false,
        renovacaoAutomatica: false,
        cartaoIntegral: false,
        rede: false,
        situacao: false,
        produtos: false,
        formasPagamento: false,
        agentes: false,
        regrasAjuste: false,
        promocoes: false,
        mensagemErro: false
      },
      produtosPlano: [],
      formasPagamentoPlano: [],
      agentesPlano: [],
      regrasAjustePlano: [],
      promocoesPlano: [],

      siteGrupo: [],

      modalidades: [],
      modalidadeSelecionada: {},
      produtos: [],
      planosMigrar: [],
      planoMigrarSelecionado: {},
      tipos: [
        { id: 0, nome: "HTML" },
        { id: 1, nome: "Imagem" }
      ],
      formasPagamento: [],
      agentes: [],
      regrasAjuste: [],
      automaticos: [
        { id: 0, nome: "Usuário (Manual)" },
        { id: 1, nome: "Captação (Automático)" },
        { id: 2, nome: "Renovação (Automático)" },
        { id: 3, nome: "Captação e Renovação (Automático)" }
      ],
      promocoes: [],

      headerProdutos: [
        { text: "Ações", value: "actions", width: "90" },
        { text: "ID", value: "id" },
        { text: "Produto", value: "produto" },
        { text: "Valor", value: "vlr" },
        { text: "Mínimo", value: "vlrMinimo" },
        { text: "Máximo", value: "vlrMaximo" },
        { text: "Dias Entrega", value: "periodo" },
        { text: "Obrigatório", value: "obrigatorio" },
        { text: "Situação", value: "produtoSituacao" },
        { text: "Registro", value: "dtRegistro" },
        { text: "Desativado em", value: "dtExclusao" }
      ],
      headerFormasPagamento: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Forma de Pagamento", value: "formaPagamento" },
        { text: "Parcelas", value: "maxParcelas" },
        { text: "Situação", value: "formaPagamentoSituacao" },
        { text: "Registro", value: "dtRegistro" },
        { text: "Desativado em", value: "dtExclusao" }
      ],
      headerAgentes: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Agente", value: "agente" },
        { text: "Situação", value: "agenteSituacao" },
        { text: "Registro", value: "dtRegistro" },
        { text: "Desativado em", value: "dtExclusao" }
      ],
      headerRegrasAjuste: [
        { text: "Ações", value: "actions", width: "120" },
        { text: "ID", value: "id" },
        { text: "Regra", value: "regraAjuste" },
        { text: "Disponibilidade", value: "regraAjusteDisponivel" },
        { text: "Aplicação", value: "automatica" },
        { text: "Situação", value: "regraAjusteSituacao" },
        { text: "Registro", value: "dtRegistro" },
        { text: "Desativado em", value: "dtExclusao" }
      ],
      headerPromocoes: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Promoção", value: "promocao" },
        { text: "Situação", value: "promocaoSituacao" },
        { text: "Registro", value: "dtRegistro" },
        { text: "Desativado em", value: "dtExclusao" }
      ],

      //* Parâmetros dos modais
      produtoSelecionado: {},
      dialogProduto: false,
      produtoValid: true,
      produtoIndex: -1,
      produtoItem: {
        id: null,
        idProduto: null,
        produto: "",
        produtoSituacao: 1,
        produtoTipo: null,
        valor: 0,
        vlr: 0,
        valorMinimo: 0,
        vlrMinimo: 0,
        valorMaximo: 0,
        vlrMaximo: 0,
        period: [],
        periodo: "",
        obrigatorio: 1,
        situacao: 1
      },
      defaultProdutoItem: {
        id: null,
        idProduto: null,
        produto: "",
        produtoSituacao: 1,
        produtoTipo: null,
        valor: 0,
        vlr: 0,
        valorMinimo: 0,
        vlrMinimo: 0,
        valorMaximo: 0,
        vlrMaximo: 0,
        period: [],
        periodo: "",
        obrigatorio: 1,
        situacao: 1
      },
      dias: ["DOM", "SEG", "TER", "QUA", "QUI", "SEX", "SAB"],

      formaPagamentoSelecionada: {},
      dialogFormaPagamento: false,
      formaPagamentoValid: true,
      formaPagamentoIndex: -1,
      formaPagamentoItem: {
        id: null,
        idFormaPgto: null,
        formaPagamento: "",
        formaPagamentoSituacao: 1,
        formaPagamentoTipo: "",
        maxParcelas: 1,
        situacao: 1
      },
      formaPagamentoItemAnterior: {
        id: null,
        idFormaPgto: null,
        formaPagamento: "",
        formaPagamentoSituacao: 1,
        formaPagamentoTipo: "",
        maxParcelas: 1,
        situacao: 1
      },
      defaultFormaPagamentoItem: {
        id: null,
        idFormaPgto: null,
        formaPagamento: "",
        formaPagamentoSituacao: 1,
        formaPagamentoTipo: "",
        maxParcelas: 1,
        situacao: 1
      },

      agenteSelecionado: {},
      dialogAgente: false,
      agenteValid: true,
      agenteIndex: -1,
      agenteItem: {
        id: null,
        idAgente: null,
        agente: "",
        agenteSituacao: 1,
        situacao: 1
      },
      defaultAgenteItem: {
        id: null,
        idAgente: null,
        agente: "",
        agenteSituacao: 1,
        situacao: 1
      },

      regraAjusteSelecionada: {},
      dialogRegraAjuste: false,
      regraAjusteValid: true,
      regraAjusteIndex: -1,
      regraAjusteItem: {
        id: null,
        idRegraAjuste: null,
        regraAjuste: "",
        regraAjusteSituacao: 1,
        regraAjusteDisponivel: 0,
        automatica: 0,
        online: 0,
        situacao: 1
      },
      defaultRegraAjusteItem: {
        id: null,
        idRegraAjuste: null,
        regraAjuste: "",
        regraAjusteSituacao: 1,
        regraAjusteDisponivel: 0,
        automatica: 0,
        online: 0,
        situacao: 1
      },

      promocaoSelecionada: {},
      dialogPromocao: false,
      promocaoValid: true,
      promocaoIndex: -1,
      promocaoItem: {
        id: null,
        idPromocao: null,
        promocao: "",
        promocaoSituacao: 1,
        situacao: 1
      },
      defaultPromocaoItem: {
        id: null,
        idPromocao: null,
        promocao: "",
        promocaoSituacao: 1,
        situacao: 1
      },

      id: "",
      idSitePrincipal: null,
      direitoCriar: false,
      direitoRegraAjuste: false,
      direitoAvancado: false,

      elementoValid: true,
      painel: [0, 0, 0, 0, 0],
      dialogConfirm: false,
      tipoConfirm: "",
      msgConfirm: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false,
      bloqueiaBotoes: false
    };
  },

  watch: {
    modalidadeSelecionada(val) {
      this.plano.idModalidade = val.id;
    },
    produtoSelecionado(val) {
      this.produtoItem.idProduto = val.id;
      this.produtoItem.produto = val.nome;
      this.produtoItem.produtoTipo = val.tipo;
    },
    formaPagamentoSelecionada(val) {
      this.formaPagamentoItem.idFormaPgto = val.id;
      this.formaPagamentoItem.formaPagamento = val.nome;
      this.formaPagamentoItem.formaPagamentoTipo = val.tipo;
    },
    agenteSelecionado(val) {
      this.agenteItem.idAgente = val.id;
      this.agenteItem.agente = val.nome;
    },
    regraAjusteSelecionada(val) {
      this.regraAjusteItem.idRegraAjuste = val.id;
      this.regraAjusteItem.regraAjuste = val.nome;
      this.regraAjusteItem.regraAjusteDisponivel = val.disponivel;
      if (this.regraAjusteItem.online) {
        if (this.regraAjusteItem.regraAjusteDisponivel == 2) {
          this.regraAjusteItem.online = 0;
        }
      }
    },
    promocaoSelecionada(val) {
      this.promocaoItem.idPromocao = val.id;
      this.promocaoItem.promocao = val.nome;
    },
    dialogProduto(val) {
      val || this.closeProduto();
    },
    dialogFormaPagamento(val) {
      val || this.closeFormaPagamento();
    },
    dialogAgente(val) {
      val || this.closeAgente();
    },
    dialogRegraAjuste(val) {
      val || this.closeRegraAjuste();
    },
    dialogPromocao(val) {
      val || this.closePromocao();
    }
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }
      var erro = false;
      if (this.produtosPlano.length == 0) {
        erro = true;
        this.validacao.produtos = "Selecione pelo menos 1 produto";
      }
      if (this.formasPagamentoPlano.length == 0) {
        erro = true;
        this.validacao.formasPagamento = "Selecione pelo menos 1 forma de pagamento";
      }
      // if (this.agentesPlano.length == 0) {
      //   erro = true;
      //   this.validacao.agentes = "Selecione pelo menos 1 agente de vendas";
      // }
      if (erro) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      //* Prepara os produtos do plano para envio a base
      var produtosPlanoCommit = [];
      var produtosVal = [];
      var produtoDuplicado = "";
      this.produtosPlano.forEach(element => {
        if (produtosVal.includes(element.produto) && element.situacao) {
          produtoDuplicado = element.produto;
        }
        if (element.situacao) {
          produtosVal.push(element.produto);
        }
        if (element.period) {
          element.periodo = "";
          element.period.forEach(item => {
            element.periodo += item + ";";
          });
        }
        produtosPlanoCommit.push({
          id: element.id,
          idPlano: this.id,
          idProduto: element.idProduto,
          valor: element.valor,
          valorMinimo: element.valorMinimo,
          valorMaximo: element.valorMaximo,
          periodo: element.periodo,
          obrigatorio: element.obrigatorio,
          situacao: element.situacao
        });
      });
      if (produtoDuplicado) {
        this.mensagem = "Já há uma configuração ativa para o produto [" + produtoDuplicado + "]";
        this.snackErro = true;
        return;
      }

      //* Prepara as formas de pagamento do plano para envio a base
      var formasPagamentoPlanoCommit = [];
      var formasPagamentoVal = [];
      var formaPagamentoDuplicada = "";
      var parcelasFormaPagamentoInvalida = "";
      this.formasPagamentoPlano.forEach(element => {
        if (formasPagamentoVal.includes(element.formaPagamento) && element.situacao) {
          formaPagamentoDuplicada = element.formaPagamento;
        }
        if (element.situacao) {
          formasPagamentoVal.push(element.formaPagamento);
        }
        if (element.situacao) {
          if (this.modalidadeSelecionada.periodo == "D") {
            if (parseInt(element.maxParcelas) > 1) {
              parcelasFormaPagamentoInvalida = element.formaPagamento + "=" + "Modalidades diárias só permitem 1 parcela";
            }
          } else if (parseInt(element.maxParcelas) > parseInt(this.modalidadeSelecionada.tempo)) {
            parcelasFormaPagamentoInvalida = element.formaPagamento + "=" + "Não é possível configurar mais parcelas que o número de meses da modalidade";
          }
        }
        formasPagamentoPlanoCommit.push({
          id: element.id,
          idPlano: this.id,
          idFormaPgto: element.idFormaPgto,
          maxParcelas: element.maxParcelas,
          situacao: element.situacao
        });
      });
      if (formaPagamentoDuplicada) {
        this.mensagem = "Já há uma configuração ativa para a forma de pagamento [" + formaPagamentoDuplicada + "]";
        this.snackErro = true;
        return;
      }
      if (parcelasFormaPagamentoInvalida) {
        this.mensagem = parcelasFormaPagamentoInvalida;
        this.snackErro = true;
        return;
      }

      //* Prepara os agentes do plano para envio a base
      var agentesPlanoCommit = [];
      var agentesVal = [];
      var agenteDuplicado = "";
      this.agentesPlano.forEach(element => {
        if (agentesVal.includes(element.agente) && element.situacao) {
          agenteDuplicado = element.agente;
        }
        if (element.situacao) {
          agentesVal.push(element.agente);
        }
        agentesPlanoCommit.push({
          id: element.id,
          idPlano: this.id,
          idAgente: element.idAgente,
          situacao: element.situacao
        });
      });
      if (agenteDuplicado) {
        this.mensagem = "Já há uma configuração ativa para o agente [" + agenteDuplicado + "]";
        this.snackErro = true;
        return;
      }

      //* Prepara as regras de ajuste do plano para envio a base
      var regrasAjustePlanoCommit = [];
      var regrasAjusteVal = [];
      var regraAjusteDuplicada = "";

      var regrasAjusteOnlineVal = [];
      var regraAjusteOnlineInvalida = false;

      this.regrasAjustePlano.forEach(element => {
        if (regrasAjusteVal.includes(element.regraAjuste) && element.situacao) {
          regraAjusteDuplicada = element.regraAjuste;
        }
        if (element.situacao) {
          regrasAjusteVal.push(element.regraAjuste);
        }
        if (element.online && element.situacao && [1, 3].includes(element.automatica)) {
          regrasAjusteOnlineVal.push(element);
        }
        if (element.online && element.situacao && !element.automatica) {
          regraAjusteOnlineInvalida = true;
        }

        regrasAjustePlanoCommit.push({
          id: element.id,
          idPlano: this.id,
          idRegraAjuste: element.idRegraAjuste,
          automatica: element.automatica,
          online: element.online,
          situacao: element.situacao
        });
      });
      if (regraAjusteDuplicada) {
        this.mensagem = "Já há uma configuração ativa para a regra de ajuste [" + regraAjusteDuplicada + "]";
        this.snackErro = true;
        return;
      }
      if (regrasAjusteOnlineVal.length > 1) {
        this.mensagem = "Só é possível a definição de uma única regra de ajuste padrão para captação ONLINE";
        this.snackErro = true;
        return;
      }
      if (regraAjusteOnlineInvalida) {
        this.mensagem = "Não é possível a definição de regra de ajuste padrão para captação ONLINE de aplicação MANUAL";
        this.snackErro = true;
        return;
      }

      // sistema permite mais de uma regra auto e/ou manual para cada plano, assim poderá configurar uma para cada número de parcelas

      //* Prepara os promocoes da plano para envio a base
      var promocoesPlanoCommit = [];
      var promocoesVal = [];
      var promocaoDuplicada = "";
      this.promocoesPlano.forEach(element => {
        if (promocoesVal.includes(element.promocao) && element.situacao) {
          promocaoDuplicada = element.promocao;
        }
        if (element.situacao) {
          promocoesVal.push(element.promocao);
        }
        promocoesPlanoCommit.push({
          id: element.id,
          idPlano: this.id,
          idPromocao: element.idPromocao,
          situacao: element.situacao
        });
      });
      if (promocaoDuplicada) {
        this.mensagem = "Já há uma configuração ativa para a promoção [" + promocaoDuplicada + "]";
        this.snackErro = true;
        return;
      }

      this.plano.produtos = produtosPlanoCommit;
      this.plano.formasPagamento = formasPagamentoPlanoCommit;
      this.plano.agentes = agentesPlanoCommit;
      this.plano.regrasAjuste = regrasAjustePlanoCommit;
      this.plano.promocoes = promocoesPlanoCommit;

      this.isUpdating = true;
      try {
        var retorno = "";
        //se tiver id é um update
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "ass/plano/" + this.id, this.plano);
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "ass/plano?idSite=" + this.idSitePrincipal, this.plano);
          this.id = retorno.data.id;
          this.plano.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "true") {
          this.$router.push("/assinaturas/plano");
          this.$router.go("/assinaturas/plano");
          return;
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push("/assinaturas/planos");
          }, 2000);
        }
        this.carregarProdutosPlano();
        this.carregarFormasPagamentoPlano();
        this.carregarAgentesPlano();
        this.carregarRegrasAjustePlano();
        this.carregarPromocoesPlano();
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },

    async carregarProdutosPlano() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/plano-produto/plano/" + this.id);
        if (response.data) {
          this.produtosPlano = response.data;
          this.produtosPlano.forEach(element => {
            element.vlr = util.formatCurrency(element.valor);
            element.vlrMinimo = util.formatCurrency(element.valorMinimo);
            element.vlrMaximo = util.formatCurrency(element.valorMaximo);
            element.dtRegistro = util.formatDateTime(element.dtRegistro);
            element.dtExclusao = util.formatDateTime(element.dtExclusao);
            if (element.periodo) {
              var itens = element.periodo.split(";");
              element.period = [];
              itens.forEach(item => {
                if (item) {
                  element.period.push(item);
                }
              });
            }
          });
        }
      } catch (e) {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os produtos do plano de assinatura: " + e;
        this.snackErro = true;
      }
    },

    async carregarFormasPagamentoPlano() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/plano-forma-pagamento/plano/" + this.id);
        if (response.data) {
          this.formasPagamentoPlano = response.data;
          this.formasPagamentoPlano.forEach(element => {
            element.dtRegistro = util.formatDateTime(element.dtRegistro);
            element.dtExclusao = util.formatDateTime(element.dtExclusao);
          });
        }
      } catch (e) {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as formas de pagamento do plano de assinatura: " + e;
        this.snackErro = true;
      }
    },

    async carregarAgentesPlano() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/plano-agente/plano/" + this.id);
        if (response.data) {
          this.agentesPlano = response.data;
          this.agentesPlano.forEach(element => {
            element.dtRegistro = util.formatDateTime(element.dtRegistro);
            element.dtExclusao = util.formatDateTime(element.dtExclusao);
          });
        }
      } catch (e) {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os agentes de venda do plano de assinatura: " + e;
        this.snackErro = true;
      }
    },

    async carregarRegrasAjustePlano() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/plano-regra-ajuste/plano/" + this.id);
        if (response.data) {
          this.regrasAjustePlano = response.data;
          this.regraAjusteAtiva = false;
          this.regrasAjustePlano.forEach(element => {
            element.dtRegistro = util.formatDateTime(element.dtRegistro);
            element.dtExclusao = util.formatDateTime(element.dtExclusao);
            var dtFim = new Date(element.dtFim);
            dtFim.setHours(23);
            if (dtFim > new Date()) {
              this.regraAjusteAtiva = true;
            }
            element.excluir = new Date(element.dtFim) > new Date();
          });
        }
      } catch (e) {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as regras de ajuste do plano de assinatura: " + e;
        this.snackErro = true;
      }
    },

    async carregarPromocoesPlano() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/plano-promocao/plano/" + this.id);
        if (response.data) {
          this.promocoesPlano = response.data;
          this.promocoesPlano.forEach(element => {
            element.dtRegistro = util.formatDateTime(element.dtRegistro);
            element.dtExclusao = util.formatDateTime(element.dtExclusao);
            var ontem = new Date();
            var dtItem = new Date(element.dtFim);
            ontem.setDate(ontem.getDate() - 1);
            if (dtItem < ontem) {
              element.promocaoSituacao = 0;
            }
          });
        }
      } catch (e) {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as promoções do plano de assinatura: " + e;
        this.snackErro = true;
      }
    },

    async carregarModalidades() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/modalidade/lista?idSite=" + this.idSitePrincipal);
        if (response.data) {
          this.modalidades = response.data;
          this.modalidades.forEach(element => {
            element.nome = element.nome + " - " + element.tempo + (element.periodo == `D` ? (element.tempo == 1 ? ` dia` : ` dias`) : element.tempo == 1 ? ` mês` : ` meses`);
          });
        }
      } catch (e) {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as modalidades do plano de assinatura: " + e;
        this.snackErro = true;
      }
    },

    async carregarPlanosMigrar() {
      //* Não carrega planos já marcados para migração
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/plano/lista?idSite=" + this.idSitePrincipal + "&migrar=1");
        if (response.data) {
          this.planosMigrar = response.data;
        }
      } catch (e) {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os planos de assinatura para migração: " + e;
        this.snackErro = true;
      }
    },

    async carregarProdutos() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/produto/lista?idSite=" + this.idSitePrincipal);
        if (response.data) {
          this.produtos = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de produtos";
        this.snackErro = true;
      }
    },

    async carregarFormasPagamento() {
      try {
        //* Busca as formas de pagamento a receber (pois não há o parâmetro pagar=1)
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/forma-pagamento/lista?idSite=" + this.idSitePrincipal);
        if (response.data) {
          var formasPgto = response.data;
          this.formasPagamento = [];
          formasPgto.forEach(element => {
            if (!["PERMUTA_PAGAR", "PERMUTA_RECEBER", "PACOTE"].includes(element.tipo)) {
              this.formasPagamento.push(element);
            }
          });
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de formas de pagamento";
        this.snackErro = true;
      }
    },

    async carregarAgentes() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-agente/lista?idSite=" + this.idSitePrincipal);
        if (response.data) {
          this.agentes = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de agentes de venda";
        this.snackErro = true;
      }
    },

    async carregarRegrasAjuste() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/regra-ajuste/lista?origem=ASS");
        if (response.data) {
          this.regrasAjuste = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de regras de ajuste";
        this.snackErro = true;
      }
    },

    async carregarPromocoes() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/promocao/lista?idSite=" + this.idSitePrincipal);

        if (response.data) {
          this.promocoes = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de promoções";
        this.snackErro = true;
      }
    },

    // * Métodos para controle dos itens
    editarProduto(item) {
      this.produtoIndex = this.produtosPlano.indexOf(item);
      this.produtoItem = Object.assign({}, item);
      this.produtoSelecionado = { id: item.idProduto, nome: item.produto };
      this.dialogProduto = true;
    },
    desativarProduto(item) {
      this.produtoItem = Object.assign({}, item);
      this.produtoIndex = this.produtosPlano.indexOf(item);
      if (this.produtoItem.id == null) {
        this.produtosPlano.splice(this.produtoIndex, 1);
        this.produtoItem = Object.assign({}, this.defaultProdutoItem);
        this.produtoSelecionado = {};
        this.produtoIndex = -1;
      } else {
        if (this.produtoItem.situacao) {
          this.tipoConfirm = "DESATIVAR_PRODUTO";
          this.msgConfirm = "Tem certeza que deseja desativar este produto? (esta ação não pode ser desfeita)";
          this.dialogConfirm = true;
        } else {
          this.produtoItem = Object.assign({}, this.defaultProdutoItem);
          this.produtoSelecionado = {};
          this.produtoIndex = -1;
        }
      }
    },
    async desativaProduto() {
      this.produtoItem.situacao = this.produtoItem.situacao == 1 ? 0 : 1;
      this.produtoItem.dtExclusao = util.formatDateTime(new Date());
      var situacao = { situacao: this.produtoItem.situacao };
      try {
        await api.put(process.env.VUE_APP_URL_WEB_API + "ass/plano-produto/situacao/" + this.produtoItem.id, situacao);

        Object.assign(this.produtosPlano[this.produtoIndex], this.produtoItem);

        this.produtoItem = Object.assign({}, this.defaultProdutoItem);
        this.produtoIndex = -1;
        this.produtoSelecionado = {};

        this.mensagem = "Item Desativado";
        this.snackbar = true;
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },
    closeProduto() {
      this.dialogProduto = false;
      setTimeout(() => {
        this.produtoItem = Object.assign({}, this.defaultProdutoItem);
        this.produtoIndex = -1;
        this.produtoSelecionado = {};
        this.$refs.formProduto.resetValidation();
      }, 300);
    },
    saveProduto() {
      if (this.$refs.formProduto.validate()) {
        this.produtoItem.dtRegistro = util.formatDateTime(new Date());
        this.produtoItem.periodo = "";
        this.produtoItem.period.forEach(element => {
          this.produtoItem.periodo += element + ";";
        });
        this.produtoItem.vlr = util.formatCurrency(this.produtoItem.valor);
        this.produtoItem.vlrMinimo = util.formatCurrency(this.produtoItem.valorMinimo);
        this.produtoItem.vlrMaximo = util.formatCurrency(this.produtoItem.valorMaximo);
        if (this.produtoIndex > -1) {
          Object.assign(this.produtosPlano[this.produtoIndex], this.produtoItem);
        } else {
          this.produtosPlano.push(this.produtoItem);
        }
        this.closeProduto();
        this.produtoItem = Object.assign({}, this.defaultProdutoItem);
        this.produtoIndex = -1;
        this.produtoSelecionado = {};
        this.$refs.formProduto.resetValidation();
        this.validacao.produtos = false;
      }
    },

    incluirFormaPagamento() {
      if (!this.modalidadeSelecionada.id) {
        this.mensagem = "Favor selecionar a modalidade do plano";
        this.snackErro = true;
      } else {
        this.dialogFormaPagamento = true;
      }
    },
    editarFormaPagamento(item) {
      this.formaPagamentoIndex = this.formasPagamentoPlano.indexOf(item);
      this.formaPagamentoItem = Object.assign({}, item);
      this.formaPagamentoItemAnterior = Object.assign({}, item);
      this.formaPagamentoSelecionada = {
        id: item.idFormaPgto,
        nome: item.formaPagamento
      };
      this.dialogFormaPagamento = true;
    },
    desativarFormaPagamento(item) {
      this.formaPagamentoItem = Object.assign({}, item);
      this.formaPagamentoIndex = this.formasPagamentoPlano.indexOf(item);
      if (this.formaPagamentoItem.id == null) {
        this.formasPagamentoPlano.splice(this.formaPagamentoIndex, 1);
        this.formaPagamentoItem = Object.assign({}, this.defaultFormaPagamentoItem);
        this.formaPagamentoIndex = -1;
        this.formaPagamentoSelecionada = {};
      } else {
        if (this.formaPagamentoItem.situacao) {
          this.tipoConfirm = "DESATIVAR_FORMA_PAGAMENTO";
          this.msgConfirm = "Tem certeza que deseja desativar esta forma de pagamento? (esta ação não pode ser desfeita)";
          this.dialogConfirm = true;
        } else {
          this.formaPagamentoItem = Object.assign({}, this.defaultFormaPagamentoItem);
          this.formaPagamentoIndex = -1;
          this.formaPagamentoSelecionada = {};
        }
      }
    },
    async desativaFormaPagamento() {
      this.formaPagamentoItem.situacao = this.formaPagamentoItem.situacao == 1 ? 0 : 1;
      this.formaPagamentoItem.dtExclusao = util.formatDateTime(new Date());
      var situacao = { situacao: this.formaPagamentoItem.situacao };
      try {
        await api.put(process.env.VUE_APP_URL_WEB_API + "ass/plano-forma-pagamento/situacao/" + this.formaPagamentoItem.id, situacao);
        Object.assign(this.formasPagamentoPlano[this.formaPagamentoIndex], this.formaPagamentoItem);

        this.formaPagamentoItem = Object.assign({}, this.defaultFormaPagamentoItem);
        this.formaPagamentoIndex = -1;
        this.formaPagamentoSelecionada = {};

        this.mensagem = "Item Desativado";
        this.snackbar = true;
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },
    closeFormaPagamento() {
      this.dialogFormaPagamento = false;
      setTimeout(() => {
        this.formaPagamentoItem = Object.assign({}, this.defaultFormaPagamentoItem);
        this.formaPagamentoIndex = -1;
        this.formaPagamentoSelecionada = {};

        this.$refs.formFormaPagamento.resetValidation();
      }, 300);
    },
    async saveFormaPagamento() {
      if (this.$refs.formFormaPagamento.validate()) {
        if (this.formaPagamentoItem.id != null) {
          if (parseInt(this.formaPagamentoItem.maxParcelas) < parseInt(this.formaPagamentoItemAnterior.maxParcelas)) {
            this.mensagem = "Edição não é possível para exclusão de parcelas";
            this.snackErro = true;
            return;
          }

          try {
            var maxParcelas = { maxParcelas: this.formaPagamentoItem.maxParcelas };
            await api.put(process.env.VUE_APP_URL_WEB_API + "ass/plano-forma-pagamento/maxParcelas/" + this.formaPagamentoItem.id, maxParcelas);
            Object.assign(this.formasPagamentoPlano[this.formaPagamentoIndex], this.formaPagamentoItem);

            this.formaPagamentoItem = Object.assign({}, this.defaultFormaPagamentoItem);
            this.formaPagamentoIndex = -1;
            this.formaPagamentoSelecionada = {};

            this.mensagem = "Item atualizado";
            this.snackbar = true;

            this.closeFormaPagamento();
            this.$refs.formFormaPagamento.resetValidation();
            this.validacao.formasPagamento = false;
          } catch (e) {
            this.mensagem =
              "ERRO: " +
              (e.toString().includes("status code 400")
                ? "SESSÃO EXPIRADA"
                : e.response && e.response.data && e.response.data.length > 0
                ? e.response.data[0].mensagem
                : "Indeterminado, verifique sua conexão");
            this.snackErro = true;
          }
          return;
        }

        this.formaPagamentoItem.dtRegistro = util.formatDateTime(new Date());

        if (this.formaPagamentoIndex > -1) {
          Object.assign(this.formasPagamentoPlano[this.formaPagamentoIndex], this.formaPagamentoItem);
        } else {
          this.formasPagamentoPlano.push(this.formaPagamentoItem);
        }
        this.closeFormaPagamento();
        this.formaPagamentoItem = Object.assign({}, this.defaultFormaPagamentoItem);
        this.formaPagamentoIndex = -1;
        this.formaPagamentoSelecionada = {};

        this.$refs.formFormaPagamento.resetValidation();
        this.validacao.formasPagamento = false;
      }
    },

    editarAgente(item) {
      this.agenteIndex = this.agentesPlano.indexOf(item);
      this.agenteItem = Object.assign({}, item);
      this.agenteSelecionado = { id: item.idAgente, nome: item.agente };
      this.dialogAgente = true;
    },
    desativarAgente(item) {
      this.agenteItem = Object.assign({}, item);
      this.agenteIndex = this.agentesPlano.indexOf(item);
      if (this.agenteItem.id == null) {
        this.agentesPlano.splice(this.agenteIndex, 1);
        this.agenteItem = Object.assign({}, this.defaultAgenteItem);
        this.agenteIndex = -1;
        this.agenteSelecionado = {};
      } else {
        if (this.agenteItem.situacao) {
          this.tipoConfirm = "DESATIVAR_AGENTE";
          this.msgConfirm = "Tem certeza que deseja desativar este agente? (esta ação não pode ser desfeita)";
          this.dialogConfirm = true;
        } else {
          this.agenteItem = Object.assign({}, this.defaultAgenteItem);
          this.agenteIndex = -1;
          this.agenteSelecionado = {};
        }
      }
    },
    async desativaAgente() {
      this.agenteItem.situacao = this.agenteItem.situacao == 1 ? 0 : 1;
      this.agenteItem.dtExclusao = util.formatDateTime(new Date());
      var situacao = { situacao: this.agenteItem.situacao };
      try {
        await api.put(process.env.VUE_APP_URL_WEB_API + "ass/plano-agente/situacao/" + this.agenteItem.id, situacao);
        Object.assign(this.agentesPlano[this.agenteIndex], this.agenteItem);

        this.agenteItem = Object.assign({}, this.defaultAgenteItem);
        this.agenteIndex = -1;
        this.agenteSelecionado = {};

        this.mensagem = "Item Desativado";
        this.snackbar = true;
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },
    closeAgente() {
      this.dialogAgente = false;
      setTimeout(() => {
        this.agenteItem = Object.assign({}, this.defaultAgenteItem);
        this.agenteIndex = -1;
        this.agenteSelecionado = {};

        this.$refs.formAgente.resetValidation();
      }, 300);
    },
    saveAgente() {
      if (this.$refs.formAgente.validate()) {
        this.agenteItem.dtRegistro = util.formatDateTime(new Date());
        if (this.agenteIndex > -1) {
          Object.assign(this.agentesPlano[this.agenteIndex], this.agenteItem);
        } else {
          this.agentesPlano.push(this.agenteItem);
        }
        this.closeAgente();
        this.agenteItem = Object.assign({}, this.defaultAgenteItem);
        this.agenteIndex = -1;
        this.agenteSelecionado = {};

        this.$refs.formAgente.resetValidation();
        this.validacao.agentes = false;
      }
    },

    editarRegraAjuste(item) {
      if (item.id && this.direitoRegraAjuste) {
        window.open("/assinaturas/regra-ajuste/" + item.idRegraAjuste, "_blank");
      } else {
        this.regraAjusteIndex = this.regrasAjustePlano.indexOf(item);
        this.regraAjusteItem = Object.assign({}, item);
        this.regraAjusteSelecionada = {
          id: item.idRegraAjuste,
          nome: item.regraAjuste,
          disponivel: item.disponivel
        };
        this.dialogRegraAjuste = true;
      }
    },
    desativarRegraAjuste(item) {
      this.regraAjusteItem = Object.assign({}, item);
      this.regraAjusteIndex = this.regrasAjustePlano.indexOf(item);
      if (this.regraAjusteItem.id == null) {
        this.regrasAjustePlano.splice(this.regraAjusteIndex, 1);
        this.regraAjusteItem = Object.assign({}, this.defaultRegraAjusteItem);
        this.regraAjusteIndex = -1;
        this.regraAjusteSelecionada = {};
      } else {
        if (this.regraAjusteItem.situacao) {
          this.tipoConfirm = "DESATIVAR_REGRA_AJUSTE";
          this.msgConfirm = "Tem certeza que deseja desativar esta regra de ajuste? (esta ação não pode ser desfeita)";
          this.dialogConfirm = true;
        } else {
          this.regraAjusteItem = Object.assign({}, this.defaultRegraAjusteItem);
          this.regraAjusteIndex = -1;
          this.regraAjusteSelecionada = {};
        }
      }
    },
    async desativaRegraAjuste() {
      this.regraAjusteItem.situacao = this.regraAjusteItem.situacao == 1 ? 0 : 1;
      this.regraAjusteItem.dtExclusao = util.formatDateTime(new Date());
      var situacao = { situacao: this.regraAjusteItem.situacao };
      try {
        await api.put(process.env.VUE_APP_URL_WEB_API + "ass/plano-regra-ajuste/situacao/" + this.regraAjusteItem.id, situacao);

        Object.assign(this.regrasAjustePlano[this.regraAjusteIndex], this.regraAjusteItem);

        this.regraAjusteItem = Object.assign({}, this.defaultRegraAjusteItem);
        this.regraAjusteIndex = -1;
        this.regraAjusteSelecionada = {};

        this.mensagem = "Item Desativado";
        this.snackbar = true;
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },
    closeRegraAjuste() {
      this.dialogRegraAjuste = false;
      setTimeout(() => {
        this.regraAjusteItem = Object.assign({}, this.defaultRegraAjusteItem);
        this.regraAjusteIndex = -1;
        this.regraAjusteSelecionada = {};

        this.$refs.formRegraAjuste.resetValidation();
      }, 300);
    },
    async saveRegraAjuste() {
      if (this.$refs.formRegraAjuste.validate()) {
        this.regraAjusteItem.dtRegistro = util.formatDateTime(new Date());
        if (this.regraAjusteIndex > -1) {
          Object.assign(this.regrasAjustePlano[this.regraAjusteIndex], this.regraAjusteItem);
        } else {
          this.regrasAjustePlano.push(this.regraAjusteItem);
        }
        this.closeRegraAjuste();
        this.regraAjusteItem = Object.assign({}, this.defaultRegraAjusteItem);
        this.regraAjusteIndex = -1;
        this.regraAjusteSelecionada = {};

        this.$refs.formRegraAjuste.resetValidation();
        this.validacao.regrasAjuste = false;
      }
    },
    async marcarDesmarcarOnline(item) {
      this.regraAjusteItem = Object.assign({}, item);
      this.regraAjusteIndex = this.regrasAjustePlano.indexOf(item);

      var regrasAjusteOnlineVal = [];
      var regraAjusteOnlineInvalida = false;

      var regras = JSON.parse(JSON.stringify(this.regrasAjustePlano));
      for (let index = 0; index < regras.length; index++) {
        var element = regras[index];
        if (this.regraAjusteIndex == index) {
          element.online = element.online == 1 ? 0 : 1;
        }
        if (element.online && element.situacao && [1, 3].includes(element.automatica)) {
          regrasAjusteOnlineVal.push(element);
        }
        if (element.online && element.situacao && !element.automatica) {
          regraAjusteOnlineInvalida = true;
        }
      }
      if (regrasAjusteOnlineVal.length > 1) {
        this.mensagem = "Só é possível a definição de uma única regra de ajuste padrão para captação ONLINE";
        this.snackErro = true;
        return;
      }
      if (regraAjusteOnlineInvalida) {
        this.mensagem = "Não é possível a definição de regra de ajuste padrão para captação ONLINE de aplicação MANUAL";
        this.snackErro = true;
        return;
      }

      this.regraAjusteItem.online = this.regraAjusteItem.online == 1 ? 0 : 1;
      if (this.regraAjusteItem.id != null) {
        try {
          var online = { online: this.regraAjusteItem.online };
          await api.put(process.env.VUE_APP_URL_WEB_API + "ass/plano-regra-ajuste/online/" + this.regraAjusteItem.id, online);
        } catch (e) {
          this.mensagem =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
          this.snackErro = true;
          return;
        }
      }

      Object.assign(this.regrasAjustePlano[this.regraAjusteIndex], this.regraAjusteItem);

      this.regraAjusteItem = Object.assign({}, this.defaultRegraAjusteItem);
      this.regraAjusteIndex = -1;
      this.regraAjusteSelecionada = {};

      this.mensagem = "Item alterado";
      this.snackbar = true;
    },

    editarPromocao(item) {
      this.promocaoIndex = this.promocoesPlano.indexOf(item);
      this.promocaoItem = Object.assign({}, item);
      this.promocaoSelecionada = { id: item.idPromocao, nome: item.promocao };
      this.dialogPromocao = true;
    },
    desativarPromocao(item) {
      this.promocaoItem = Object.assign({}, item);
      this.promocaoIndex = this.promocoesPlano.indexOf(item);
      if (this.promocaoItem.id == null) {
        this.promocoesPlano.splice(this.promocaoIndex, 1);
        this.promocaoItem = Object.assign({}, this.defaultPromocaoItem);
        this.produtoIndex = -1;
        this.promocaoSelecionada = {};
      } else {
        if (this.promocaoItem.situacao) {
          this.tipoConfirm = "DESATIVAR_PROMOCAO";
          this.msgConfirm = "Tem certeza que deseja desativar esta promoção? (esta ação não pode ser desfeita)";
          this.dialogConfirm = true;
        } else {
          this.promocaoItem = Object.assign({}, this.defaultPromocaoItem);
          this.produtoIndex = -1;
          this.promocaoSelecionada = {};
        }
      }
    },
    async desativaPromocao() {
      this.promocaoItem.situacao = this.promocaoItem.situacao == 1 ? 0 : 1;
      this.promocaoItem.dtExclusao = util.formatDateTime(new Date());
      var situacao = { situacao: this.promocaoItem.situacao };
      try {
        await api.put(process.env.VUE_APP_URL_WEB_API + "ass/plano-promocao/situacao/" + this.promocaoItem.id, situacao);

        Object.assign(this.promocoesPlano[this.promocaoIndex], this.promocaoItem);

        this.promocaoItem = Object.assign({}, this.defaultPromocaoItem);
        this.promocaoIndex = -1;
        this.promocaoSelecionada = {};

        this.mensagem = "Item Desativado";
        this.snackbar = true;
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },
    closePromocao() {
      this.dialogPromocao = false;
      setTimeout(() => {
        this.promocaoItem = Object.assign({}, this.defaultPromocaoItem);
        this.promocaoIndex = -1;
        this.promocaoSelecionada = {};

        this.$refs.formPromocao.resetValidation();
      }, 300);
    },
    savePromocao() {
      if (this.$refs.formPromocao.validate()) {
        this.promocaoItem.dtRegistro = util.formatDateTime(new Date());

        if (this.promocaoIndex > -1) {
          Object.assign(this.promocoesPlano[this.promocaoIndex], this.promocaoItem);
        } else {
          this.promocoesPlano.push(this.promocaoItem);
        }
        this.closePromocao();
        this.promocaoItem = Object.assign({}, this.defaultPromocaoItem);
        this.promocaoIndex = -1;
        this.promocaoSelecionada = {};

        this.$refs.formPromocao.resetValidation();
        this.validacao.promocoes = false;
      }
    },

    confirmado() {
      this.dialogConfirm = false;
      if (this.tipoConfirm == "DESATIVAR_PRODUTO") {
        this.desativaProduto();
      } else if (this.tipoConfirm == "DESATIVAR_FORMA_PAGAMENTO") {
        this.desativaFormaPagamento();
      } else if (this.tipoConfirm == "DESATIVAR_AGENTE") {
        this.desativaAgente();
      } else if (this.tipoConfirm == "DESATIVAR_REGRA_AJUSTE") {
        this.desativaRegraAjuste();
      } else if (this.tipoConfirm == "DESATIVAR_PROMOCAO") {
        this.desativaPromocao();
      }
    },
    naoConfirmado() {
      this.dialogConfirm = false;
      if (this.tipoConfirm == "DESATIVAR_PRODUTO") {
        this.produtoItem = Object.assign({}, this.defaultProdutoItem);
        this.produtoIndex = -1;
        this.produtoSelecionado = {};
      } else if (this.tipoConfirm == "DESATIVAR_FORMA_PAGAMENTO") {
        this.formaPagamentoItem = Object.assign({}, this.defaultFormaPagamentoItem);
        this.formaPagamentoIndex = -1;
        this.formaPagamentoSelecionada = {};
      } else if (this.tipoConfirm == "DESATIVAR_AGENTE") {
        this.agenteItem = Object.assign({}, this.defaultAgenteItem);
        this.agenteIndex = -1;
        this.agenteSelecionado = {};
      } else if (this.tipoConfirm == "DESATIVAR_REGRA_AJUSTE") {
        this.regraAjusteItem = Object.assign({}, this.defaultRegraAjusteItem);
        this.regraAjusteIndex = -1;
        this.regraAjusteSelecionada = {};
      } else if (this.tipoConfirm == "DESATIVAR_PROMOCAO") {
        this.promocaoItem = Object.assign({}, this.defaultPromocaoItem);
        this.promocaoIndex = -1;
        this.promocaoSelecionada = {};
      }
    },

    isFormaPgto1Parcela(formaPagamento) {
      var retorno = util.isFormaPgto1Parcela(formaPagamento);
      if (retorno) {
        this.formaPagamentoItem.maxParcelas = 1;
      }
      return retorno;
    },
    verificaNroParcelas() {
      var retorno = true;
      if (!this.modalidadeSelecionada.id) {
        return "Favor selecionar a modalidade do plano";
      }
      if (this.modalidadeSelecionada.periodo == "D") {
        if (parseInt(this.formaPagamentoItem.maxParcelas) > 1) {
          return "Modalidades diárias só permitem 1 parcela";
        }
      } else if (parseInt(this.formaPagamentoItem.maxParcelas) > parseInt(this.modalidadeSelecionada.tempo)) {
        return "Não é possível configurar mais parcelas que o número de meses da modalidade";
      }
      return retorno;
    },

    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    filtrarItens(item, queryText, itemText) {
      return util.filtrarItens(item, queryText, itemText);
    },
    pLetra(text) {
      return util.pLetra(text);
    },
    formatDate(date) {
      return util.formatDate(date);
    },
    formatDateTime(datetime) {
      return util.formatDateTime(datetime);
    },
    valida_nome(text) {
      return util.valida_nome(text);
    }
  },

  async created() {
    this.isUpdating = false;
    this.bloqueiaBotoes = true;
    this.id = this.$route.params.id ? this.$route.params.id : "";

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_PLANOS_CRIAR") {
        this.direitoCriar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_REGRAS_AJUSTE_EDITAR") {
        this.direitoRegraAjuste = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_PLANOS_EDITAR_AVANÇADO") {
        this.direitoAvancado = true;
      }
    });
    var idGrupoSite = this.$store.state.usuario.site_idGrupo;
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
    if (idGrupoSite != null) {
      this.siteGrupo = [
        {
          id: this.idSitePrincipal,
          nome: this.$store.state.usuario.site_nome
        }
      ];
    }

    //* Carrega os itens dos selects
    await this.carregarModalidades();
    await this.carregarPlanosMigrar();
    await this.carregarProdutos();
    await this.carregarFormasPagamento();
    await this.carregarAgentes();
    await this.carregarRegrasAjuste();
    await this.carregarPromocoes();

    if (this.id == "") {
      this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_PLANOS_CRIAR"]);
    } else {
      try {
        //* Se é edição, carrega a plano
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_PLANOS_EDITAR"]);
        //* Carrega a plano - somente se for do site do usuário logado
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/plano/" + this.id + "?idSite=" + this.idSitePrincipal);

        if (!response.data) {
          this.$router.push("/assinaturas/planos");
          return;
        }
        this.plano = response.data;
        this.modalidades.forEach(element => {
          if (element.id == this.plano.idModalidade) {
            this.modalidadeSelecionada = element;
          }
        });
        this.planosMigrar.forEach(element => {
          if (element.id == this.plano.idPlanoMigrar) {
            this.planoMigrarSelecionado = element;
          }
        });

        await this.carregarProdutosPlano();
        await this.carregarFormasPagamentoPlano();
        await this.carregarAgentesPlano();
        await this.carregarRegrasAjustePlano();
        await this.carregarPromocoesPlano();
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os dados do plano de assinatura";
        this.snackErro = true;
      }
    }
    setTimeout(() => {
      this.bloqueiaBotoes = false;
    }, 1000);
  }
};
</script>
