<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">toc</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Régua de Cobrança</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12 md12 v-if="siteGrupo.length > 0">
            <v-autocomplete
              v-model="grupoEtapaCobranca.idSite"
              :disabled="isUpdating"
              :items="siteGrupo"
              :filter="filtrarObjetos"
              label="Restrita ao Site"
              item-text="nome"
              item-value="id"
              :rules="[validacao.idSite == false || validacao.idSite]"
              clearable
              no-data-text
              @change="validacao.idSite = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md12>
            <v-text-field
              autofocus
              v-model="grupoEtapaCobranca.nome"
              label="Nome da Régua"
              maxlength="20"
              hint="Obrigatório"
              :rules="[validacao.nome == false || validacao.nome, value => !!value || 'Obrigatório.', value => value.length > 2 || 'Preencha o nome do grupo da etapa de cobrança']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="
                grupoEtapaCobranca.nome = pLetra(grupoEtapaCobranca.nome);
                validacao.nome = false;
              "
            ></v-text-field>
          </v-flex>

          <v-flex xs6>
            <v-autocomplete
              v-model="grupoEtapaCobranca.novas"
              :disabled="isUpdating"
              :items="novas"
              hint="Obrigatório"
              :rules="[validacao.novas == false || validacao.novas, value => value !== '' || 'Obrigatório']"
              :filter="filtrarObjetos"
              label="Tipo de Régua"
              item-text="nome"
              item-value="id"
              no-data-text
              @change="validacao.novas = false"
            >
              <template v-slot:selection="data">
                <v-chip color="blue lighten-3">
                  {{ data.item.nome }}
                </v-chip>
                <span class="ml-2">{{ data.item.descricao }}</span>
              </template>

              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.nome"></v-list-item-title>
                  <v-list-item-subtitle v-html="data.item.descricao"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-flex>

          <v-flex xs12>
            <h4 class="overline font-weight-black font-italic">
              Faixa de
              {{ $route.path.includes("assinaturas") ? "Renovações*" : $route.path.includes("comercial") ? "Captações*" : "Operações*" }}
            </h4>
            <v-divider></v-divider>
          </v-flex>
          <v-flex xs12 md6>
            <v-menu ref="mnuDtInicio" v-model="mnuDtInicio" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dtInicio"
                  label="Data Inicial"
                  v-mask="'##/##/####'"
                  :rules="[validacao.dtInicio == false || validacao.dtInicio, value => valida_data(value) || 'Data inválida']"
                  :disabled="isUpdating"
                  prepend-icon="event"
                  v-on="on"
                  color="blue-grey lighten-2"
                  autocomplete="disabled"
                  :name="Math.random()"
                  @keyup="dtInicioUS = valida_data(dtInicio) ? formatDateUS(dtInicio) : null"
                  @change="validacao.dtInicio = false"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dtInicioUS" locale="pt-br" no-title @input="mnuDtInicio = false"></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs12 md6>
            <v-menu ref="mnuDtFim" v-model="mnuDtFim" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dtFim"
                  label="Data Final"
                  v-mask="'##/##/####'"
                  :rules="[validacao.dtFim == false || validacao.dtFim, value => valida_data(value) || 'Data inválida']"
                  :disabled="isUpdating"
                  prepend-icon="event"
                  v-on="on"
                  color="blue-grey lighten-2"
                  autocomplete="disabled"
                  :name="Math.random()"
                  @keyup="dtFimUS = valida_data(dtFim) ? formatDateUS(dtFim) : null"
                  @change="validacao.dtFim = false"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dtFimUS" locale="pt-br" no-title @input="mnuDtFim = false"></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs12 md12>
            <v-subheader
              >*Se indicada, régua abrangerá somente
              {{
                $route.path.includes("assinaturas")
                  ? "as assinaturas com períodos iniciados"
                  : $route.path.includes("comercial")
                  ? "as captações ocorridas"
                  : "as operações ocorridas"
              }}
              dentro desta faixa.</v-subheader
            >
            <v-divider></v-divider>
          </v-flex>

          <v-flex xs6>
            <v-switch v-model="grupoEtapaCobranca.situacao" :label="`${grupoEtapaCobranca.situacao == 1 ? 'Ativo' : 'Inativo'}`"></v-switch>
          </v-flex>
          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">
              {{ validacao.mensagemErro }}
            </v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn
        :to="($route.path.includes('assinaturas') ? '/assinaturas' : $route.path.includes('comercial') ? '/comercial' : '/financeiro') + '/etapas-cobranca'"
        id="btn-rsn"
        color="orange lighten-2 white"
        style="color:white"
      >
        <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar
      </v-btn>
      <v-btn :loading="isUpdating" color="blue white" style="color:white" id="btn-rsn" depressed @click="salvar('false')"> <v-icon id="icon-rsn" left>update</v-icon>Salvar </v-btn>
      <v-btn :loading="isUpdating" color="orange white" style="color:white" id="btn-rsn" outlined @click="salvar('retornar')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>

      <v-btn :loading="isUpdating" color="blue white" style="color:white" id="btn-rsn" outlined @click="salvar('true')" v-if="direitoCriar">
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Nova
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      grupoEtapaCobranca: {
        id: "",
        idSite: null,
        nome: "",
        novas: "",
        dtInicio: null,
        dtFim: null,
        situacao: 1
      },
      validacao: {
        id: false,
        idSite: false,
        nome: false,
        novas: false,
        dtInicio: false,
        dtFim: false,
        situacao: false,
        mensagemErro: false
      },

      dtInicio: null,
      dtInicioUS: null,
      mnuDtInicio: false,

      dtFim: null,
      dtFimUS: null,
      mnuDtFim: false,

      siteGrupo: [],
      novas: [
        {
          id: null,
          nome: "Assinaturas Manual",
          descricao: "Cobrança manual (somente ligação telefônica)"
        },
        {
          id: 1,
          nome: "Assinaturas Novas",
          descricao: "Cobrança automática (ligação, e-mail, corte, cancelamento)"
        },
        {
          id: 0,
          nome: "Assinaturas Renovadas",
          descricao: "Cobrança automática (ligação, e-mail, corte, cancelamento)"
        }
      ],

      idSitePrincipal: null,
      direitoCriar: false,
      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      if (this.dtInicio != null) {
        this.grupoEtapaCobranca.dtInicio = util.formatDateUS(this.dtInicio);
      } else {
        this.grupoEtapaCobranca.dtInicio = null;
      }
      if (this.dtFim != null) {
        this.grupoEtapaCobranca.dtFim = util.formatDateUS(this.dtFim);
      } else {
        this.grupoEtapaCobranca.dtFim = null;
      }
      if (this.grupoEtapaCobranca.dtInicio && this.grupoEtapaCobranca.dtFim) {
        if (new Date(this.grupoEtapaCobranca.dtInicio) > new Date(this.grupoEtapaCobranca.dtFim)) {
          this.mensagem = "Verifique as datas de início e fim da régua de cobrança";
          this.snackErro = true;
          return;
        }
      }

      this.isUpdating = true;
      try {
        var retorno = "";
        //se tiver id é um update
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "ass/etapa-cobranca-grupo/" + this.id, this.grupoEtapaCobranca);
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "ass/etapa-cobranca-grupo?idSite=" + this.idSitePrincipal, this.grupoEtapaCobranca);
          this.id = retorno.data.id;
          this.grupoEtapaCobranca.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "true") {
          this.$router.push(
            (this.$route.path.includes("assinaturas") ? "/assinaturas" : this.$route.path.includes("comercial") ? "/comercial" : "/financeiro") + "/etapa-cobranca-grupo"
          ); //para alterar a url
          this.$router.go(
            (this.$route.path.includes("assinaturas") ? "/assinaturas" : this.$route.path.includes("comercial") ? "/comercial" : "/financeiro") + "/etapa-cobranca-grupo"
          ); //para recarregar a página
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push(
              (this.$route.path.includes("assinaturas") ? "/assinaturas" : this.$route.path.includes("comercial") ? "/comercial" : "/financeiro") + "/etapas-cobranca"
            ); //para alterar a url
          }, 2000);
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },

    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    pLetra(text) {
      return util.pLetra(text);
    },
    valida_data(date) {
      if (date) {
        return util.valida_data_BR(date);
      } else {
        return true;
      }
    },
    formatDateUS(date) {
      return util.formatDateUS(date);
    }
  },

  watch: {
    dtInicioUS() {
      this.dtInicio = util.formatDate(this.dtInicioUS);
      this.mnuDtInicio = false;
    },
    dtFimUS() {
      this.dtFim = util.formatDate(this.dtFimUS);
      this.mnuDtFim = false;
    }
  },

  async created() {
    this.id = this.$route.params.id ? this.$route.params.id : "";

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (
        direito == "WEBSUITA_ADMIN_SUITA" ||
        (this.$route.path.includes("assinaturas") && direito == "ASSINATURAS_ETAPAS_COBRANÇA_CRIAR") ||
        (this.$route.path.includes("comercial") && direito == "COMERCIAL_ETAPAS_COBRANÇA_CRIAR") ||
        (this.$route.path.includes("financeiro") && direito == "FINANCEIRO_ETAPAS_COBRANÇA_CRIAR")
      ) {
        this.direitoCriar = true;
      }
    });

    //* Verifica se o site do usuário está em GRUPO - OBS: ou ele compartilha a informação ou deixa exclusiva para o seu site
    var idGrupoSite = this.$store.state.usuario.site_idGrupo;
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
    if (idGrupoSite != null) {
      this.siteGrupo = [
        {
          id: this.idSitePrincipal,
          nome: this.$store.state.usuario.site_nome
        }
      ];
    }

    if (this.$route.path.includes("comercial")) {
      this.novas = [
        {
          id: 2,
          nome: "Comercial",
          descricao: "Cobrança automática (ligação, e-mail, corte, cancelamento)"
        }
      ];
      this.grupoEtapaCobranca.novas = 2;
    }
    if (this.$route.path.includes("financeiro")) {
      this.novas = [
        {
          id: 3,
          nome: "Financeiro",
          descricao: "Cobrança automática (ligação, e-mail, corte, cancelamento)"
        }
      ];
      this.grupoEtapaCobranca.novas = 3;
    }

    try {
      //* Se é edição, carrega o elemento
      if (this.id == "") {
        if (this.$route.path.includes("assinaturas")) {
          this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_ETAPAS_COBRANÇA_CRIAR"]);
        } else if (this.$route.path.includes("comercial")) {
          this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "COMERCIAL_ETAPAS_COBRANÇA_CRIAR"]);
        } else {
          this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_ETAPAS_COBRANÇA_CRIAR"]);
        }
      } else {
        if (this.$route.path.includes("assinaturas")) {
          this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_ETAPAS_COBRANÇA_EDITAR"]);
        } else if (this.$route.path.includes("comercial")) {
          this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "COMERCIAL_ETAPAS_COBRANÇA_EDITAR"]);
        } else {
          this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_ETAPAS_COBRANÇA_EDITAR"]);
        }
        //* Carrega o elemento - somente se for público (idSite=null) ou for do site do usuário logado
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/etapa-cobranca-grupo/" + this.id + "?idSite=" + this.idSitePrincipal);
        if (!response.data) {
          this.$router.push(
            (this.$route.path.includes("assinaturas") ? "/assinaturas" : this.$route.path.includes("comercial") ? "/comercial" : "/financeiro") + "/etapas-cobranca"
          );
        } else {
          this.grupoEtapaCobranca = response.data;
          if (this.grupoEtapaCobranca.dtInicio != null) {
            this.dtInicioUS = util.dateToStringUS(this.grupoEtapaCobranca.dtInicio);
          }
          if (this.grupoEtapaCobranca.dtFim != null) {
            this.dtFimUS = util.dateToStringUS(this.grupoEtapaCobranca.dtFim);
          }
        }
      }
    } catch {
      this.mensagem = "Ocorreu um erro ao carregar os dados";
      this.snackErro = true;
    }
  }
};
</script>
