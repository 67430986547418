<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-form ref="formElemento">
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-expansion-panels v-model="painel" focusable>
            <v-expansion-panel dark class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Dados Básicos</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider></v-divider>
                <v-container fluid grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 md3>
                      <v-autocomplete
                        v-model="filtrosSelecionados.online"
                        :items="itensSimNao"
                        :filter="filtrarObjetos"
                        label="Online"
                        item-text="nome"
                        item-value="id"
                        autocomplete="disabled"
                        :name="Math.random()"
                        no-data-text
                        clearable
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12 md3>
                      <v-text-field
                        v-model="filtrosSelecionados.idAssinatura"
                        label="ID"
                        v-mask="'######'"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        clearable
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-autocomplete
                        v-model="filtrosSelecionados.status"
                        :items="itensStatus"
                        :filter="filtrarItens"
                        label="Status"
                        autocomplete="disabled"
                        :name="Math.random()"
                        no-data-text
                        multiple
                        clearable
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12>
                      <v-autocomplete
                        v-model="filtrosSelecionados.idOrigem"
                        :items="origensVenda"
                        :filter="filtrarObjetos"
                        label="Origem de Venda"
                        item-text="nome"
                        item-value="id"
                        autocomplete="disabled"
                        :name="Math.random()"
                        no-data-text
                        clearable
                        multiple
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12>
                      <v-autocomplete
                        v-model="filtrosSelecionados.idUsuario"
                        :items="usuarios"
                        :filter="filtrarObjetos"
                        label="Usuário Captação"
                        item-text="nome"
                        item-value="id"
                        autocomplete="disabled"
                        :name="Math.random()"
                        no-data-text
                        clearable
                        multiple
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">
                        Captada entre
                      </h4>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-container fluid grid-list-md>
                      <v-layout wrap>
                        <v-flex xs6 md6>
                          <v-menu
                            ref="mnRenovacoesInicio"
                            v-model="mnRenovacoesInicio"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="filtrosSelecionados.dtCriacaoInicio"
                                label="Início"
                                v-mask="'##/##/####'"
                                :rules="[value => valida_data(value) || 'Data inválida']"
                                color="blue-grey lighten-2"
                                prepend-icon="event"
                                v-on="on"
                                autocomplete="disabled"
                                :name="Math.random()"
                                clearable
                                @click:clear="renovacoesDtInicioInicialUS = null"
                                @keyup="renovacoesDtInicioInicialUS = valida_data(filtrosSelecionados.dtCriacaoInicio) ? formatDateUS(filtrosSelecionados.dtCriacaoInicio) : null"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="renovacoesDtInicioInicialUS" locale="pt-br" no-title @input="mnRenovacoesInicio = false"></v-date-picker>
                          </v-menu>
                        </v-flex>

                        <v-flex xs6 md6>
                          <v-menu
                            ref="mnRenovacoesFim"
                            v-model="mnRenovacoesFim"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="filtrosSelecionados.dtCriacaoFim"
                                label="Fim"
                                v-mask="'##/##/####'"
                                :rules="[value => valida_data(value) || 'Data inválida']"
                                color="blue-grey lighten-2"
                                clearable
                                prepend-icon="event"
                                v-on="on"
                                @click:clear="renovacoesDtInicioFinalUS = null"
                                autocomplete="disabled"
                                :name="Math.random()"
                                @keyup="renovacoesDtInicioFinalUS = valida_data(filtrosSelecionados.dtCriacaoFim) ? formatDateUS(filtrosSelecionados.dtCriacaoFim) : null"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="renovacoesDtInicioFinalUS" locale="pt-br" no-title @input="mnRenovacoesFim = false"></v-date-picker>
                          </v-menu>
                        </v-flex>
                      </v-layout>
                    </v-container>

                    <v-flex xs12>
                      <v-autocomplete
                        v-model="filtrosSelecionados.renovacoes.atual"
                        :items="itensAtual"
                        :filter="filtrarObjetos"
                        label="Renovação"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                      >
                        <template v-slot:selection="data">
                          <v-chip dense color="blue lighten-3">{{ data.item.id }} </v-chip>
                          <span class="ml-2">{{ data.item.nome }}</span>
                        </template>
                        <template v-slot:item="data">
                          <v-list-item-content style="width:100px;">
                            <v-list-item-title>
                              <v-chip dense color="blue lighten-3">{{ data.item.id }} </v-chip>
                              <span class="ml-2">{{ data.item.nome }}</span>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <span>{{ data.item.descricao }}</span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-flex>

                    <v-flex xs8>
                      <v-autocomplete
                        v-model="filtrosSelecionados.idFormaPgto"
                        :items="formasPgto"
                        :filter="filtrarObjetos"
                        label="Forma de Pagamento Padrão"
                        item-text="nome"
                        item-value="id"
                        autocomplete="disabled"
                        :name="Math.random()"
                        no-data-text
                        clearable
                        multiple
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs4>
                      <v-text-field v-model="filtrosSelecionados.nroParcelas" label="Número Parcelas Padrão" v-mask="'##'" color="blue-grey lighten-2" clearable></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-autocomplete
                        v-model="filtrosSelecionados.idRegraAjuste"
                        :items="regrasAjuste"
                        :filter="filtrarObjetos"
                        label="Regra de Ajuste Padrão"
                        item-text="nome"
                        item-value="id"
                        autocomplete="disabled"
                        :name="Math.random()"
                        no-data-text
                        clearable
                        multiple
                      ></v-autocomplete>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Renovação</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider></v-divider>
                <v-flex xs12 md12>
                  <v-container fluid grid-list-md>
                    <v-layout wrap>
                      <v-flex xs6 md3>
                        <v-text-field v-model="filtrosSelecionados.renovacoes.renovacao" label="Renovação" v-mask="'###'" color="blue-grey lighten-2" clearable></v-text-field>
                      </v-flex>
                      <v-flex xs6 md3>
                        <v-text-field v-model="filtrosSelecionados.renovacoes.edicoes" label="Edições" v-mask="'###'" color="blue-grey lighten-2" clearable></v-text-field>
                      </v-flex>
                      <v-flex xs6 md6>
                        <v-text-field v-model="filtrosSelecionados.renovacoes.autorizacao" label="Autorização" maxlength="100" color="blue-grey lighten-2" clearable></v-text-field>
                      </v-flex>

                      <v-flex xs12 md6>
                        <v-autocomplete
                          v-model="filtrosSelecionados.renovacoes.idPlano"
                          :items="planos"
                          :filter="filtrarObjetos"
                          label="Plano de Assinatura"
                          item-text="nome"
                          item-value="id"
                          autocomplete="disabled"
                          :name="Math.random()"
                          no-data-text
                          clearable
                          multiple
                        ></v-autocomplete>
                      </v-flex>

                      <v-flex xs12 md6>
                        <v-autocomplete
                          v-model="filtrosSelecionados.renovacoes.idProduto"
                          :items="produtos"
                          :filter="filtrarObjetos"
                          label="Produto"
                          item-text="nome"
                          item-value="id"
                          autocomplete="disabled"
                          :name="Math.random()"
                          no-data-text
                          clearable
                          multiple
                        ></v-autocomplete>
                      </v-flex>

                      <v-flex xs12 md6>
                        <v-autocomplete
                          v-model="filtrosSelecionados.renovacoes.tipoProduto"
                          :items="itensTipoProduto"
                          :filter="filtrarItens"
                          label="Tipo de Produto"
                          autocomplete="disabled"
                          :name="Math.random()"
                          no-data-text
                          clearable
                          multiple
                        ></v-autocomplete>
                      </v-flex>

                      <v-flex xs12 md6>
                        <v-autocomplete
                          v-model="filtrosSelecionados.renovacoes.tipoProdutoOmitir"
                          :items="itensTipoProduto"
                          :filter="filtrarItens"
                          label="Tipo de Produto a Omitir"
                          autocomplete="disabled"
                          :name="Math.random()"
                          no-data-text
                          clearable
                          multiple
                        ></v-autocomplete>
                      </v-flex>

                      <v-flex xs12>
                        <v-autocomplete
                          v-model="filtrosSelecionados.renovacoes.modalidade"
                          :items="modalidades"
                          :filter="filtrarObjetos"
                          label="Modalidade"
                          item-text="nome"
                          item-value="id"
                          autocomplete="disabled"
                          :name="Math.random()"
                          no-data-text
                          clearable
                          multiple
                        ></v-autocomplete>
                      </v-flex>

                      <v-flex xs12>
                        <h4 class="overline font-weight-black font-italic">
                          Valor Cobrado
                        </h4>
                        <v-divider></v-divider>
                        <v-layout wrap>
                          <v-flex xs6 md6>
                            <v-currency-field
                              :locale="`pt-${$store.state.usuario.site_pais}`"
                              v-model="filtrosSelecionados.renovacoes.valorInicio"
                              label="Mínimo"
                              :allowNegative="false"
                              :max="9999999"
                              color="blue-grey lighten-2"
                            ></v-currency-field>
                          </v-flex>
                          <v-flex xs6 md6>
                            <v-currency-field
                              :locale="`pt-${$store.state.usuario.site_pais}`"
                              v-model="filtrosSelecionados.renovacoes.valorFim"
                              label="Máximo"
                              :allowNegative="false"
                              :max="9999999"
                              color="blue-grey lighten-2"
                            ></v-currency-field>
                          </v-flex>
                        </v-layout>
                      </v-flex>

                      <v-flex xs12>
                        <v-autocomplete
                          v-model="filtrosSelecionados.renovacoes.idUsuario"
                          :items="usuarios"
                          :filter="filtrarObjetos"
                          label="Usuário Renovação"
                          item-text="nome"
                          item-value="id"
                          autocomplete="disabled"
                          :name="Math.random()"
                          no-data-text
                          clearable
                          multiple
                        ></v-autocomplete>
                      </v-flex>

                      <v-flex xs12>
                        <v-autocomplete
                          v-model="filtrosSelecionados.renovacoes.edicao"
                          :items="itensDias"
                          :filter="filtrarItens"
                          label="Dia de Entrega"
                          autocomplete="disabled"
                          :name="Math.random()"
                          no-data-text
                          clearable
                          multiple
                        ></v-autocomplete>
                      </v-flex>

                      <v-flex xs6 md2>
                        <v-autocomplete
                          v-model="filtrosSelecionados.renovacoes.automatica"
                          :items="itensSimNao"
                          :filter="filtrarObjetos"
                          label="Automática"
                          item-text="nome"
                          item-value="id"
                          autocomplete="disabled"
                          :name="Math.random()"
                          no-data-text
                          clearable
                        ></v-autocomplete>
                      </v-flex>
                      <v-flex xs6 md2>
                        <v-autocomplete
                          v-model="filtrosSelecionados.renovacoes.reinicio"
                          :items="itensSimNao"
                          :filter="filtrarObjetos"
                          label="Reinício"
                          item-text="nome"
                          item-value="id"
                          autocomplete="disabled"
                          :name="Math.random()"
                          no-data-text
                          clearable
                        ></v-autocomplete>
                      </v-flex>
                      <v-flex xs6 md2>
                        <v-autocomplete
                          v-model="filtrosSelecionados.renovacoes.online"
                          :items="itensSimNao"
                          :filter="filtrarObjetos"
                          label="Online"
                          item-text="nome"
                          item-value="id"
                          autocomplete="disabled"
                          :name="Math.random()"
                          no-data-text
                          clearable
                        ></v-autocomplete>
                      </v-flex>
                      <v-flex xs6 md2>
                        <v-autocomplete
                          v-model="filtrosSelecionados.renovacoes.vip"
                          :items="itensSimNao"
                          :filter="filtrarObjetos"
                          label="VIP"
                          item-text="nome"
                          item-value="id"
                          autocomplete="disabled"
                          :name="Math.random()"
                          no-data-text
                          clearable
                        ></v-autocomplete>
                      </v-flex>
                      <v-flex xs12 md4>
                        <v-switch
                          v-model="filtrosSelecionados.renovacoes.ativas"
                          :label="`${filtrosSelecionados.renovacoes.ativas == 1 ? 'Renovações Ativas' : 'Renovações Excluídas'}`"
                        ></v-switch>
                      </v-flex>
                      <v-flex xs6>
                        <v-text-field
                          v-model="filtrosSelecionados.renovacoes.codAntigo"
                          label="Código Antigo"
                          v-mask="'######'"
                          color="blue-grey lighten-2"
                          clearable
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs6 v-if="$store.state.usuario.site_protheus">
                        <v-text-field
                          v-model="filtrosSelecionados.renovacoes.idProtheus"
                          label="ID Protheus"
                          v-mask="'######'"
                          color="blue-grey lighten-2"
                          clearable
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs12>
                        <h4 class="overline font-weight-black font-italic">
                          Renovada entre
                        </h4>
                        <v-divider></v-divider>
                      </v-flex>
                      <v-container fluid grid-list-md>
                        <v-layout wrap>
                          <v-flex xs6 md6>
                            <v-menu
                              ref="mnRenovacaoInicio"
                              v-model="mnRenovacaoInicio"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="filtrosSelecionados.renovacoes.dtRenovacaoInicio"
                                  label="Início"
                                  v-mask="'##/##/####'"
                                  :rules="[value => valida_data(value) || 'Data inválida']"
                                  color="blue-grey lighten-2"
                                  prepend-icon="event"
                                  v-on="on"
                                  autocomplete="disabled"
                                  :name="Math.random()"
                                  clearable
                                  @click:clear="renovacoesDtRenocacaoInicialUS = null"
                                  @keyup="
                                    renovacoesDtRenovacaoInicialUS = valida_data(filtrosSelecionados.renovacoes.dtRenovacaoInicio)
                                      ? formatDateUS(filtrosSelecionados.renovacoes.dtRenovacaoInicio)
                                      : null
                                  "
                                ></v-text-field>
                              </template>
                              <v-date-picker v-model="renovacoesDtRenovacaoInicialUS" locale="pt-br" no-title @input="mnRenovacaoInicio = false"></v-date-picker>
                            </v-menu>
                          </v-flex>

                          <v-flex xs6 md6>
                            <v-menu
                              ref="mnRenovacaoFim"
                              v-model="mnRenovacaoFim"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="filtrosSelecionados.renovacoes.dtRenovacaoFim"
                                  label="Fim"
                                  v-mask="'##/##/####'"
                                  :rules="[value => valida_data(value) || 'Data inválida']"
                                  color="blue-grey lighten-2"
                                  clearable
                                  prepend-icon="event"
                                  v-on="on"
                                  @click:clear="renovacoesDtRenovacaoFinalUS = null"
                                  autocomplete="disabled"
                                  :name="Math.random()"
                                  @keyup="
                                    renovacoesDtRenovacaoFinalUS = valida_data(filtrosSelecionados.renovacoes.dtRenovacaoFim)
                                      ? formatDateUS(filtrosSelecionados.renovacoes.dtRenovacaoFim)
                                      : null
                                  "
                                ></v-text-field>
                              </template>
                              <v-date-picker v-model="renovacoesDtRenovacaoFinalUS" locale="pt-br" no-title @input="mnRenovacaoFim = false"></v-date-picker>
                            </v-menu>
                          </v-flex>
                        </v-layout>
                      </v-container>

                      <v-flex xs12>
                        <h4 class="overline font-weight-black font-italic">
                          Período Inicial
                        </h4>
                        <v-divider></v-divider>
                      </v-flex>
                      <v-container fluid grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12 md6>
                            <v-menu
                              ref="mnfiltroSelRenovacoesInicioInicialUS"
                              v-model="mnfiltroSelRenovacoesInicioInicialUS"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="filtrosSelecionados.renovacoes.dtInicio_inicial"
                                  label="Início"
                                  hint="Início a partir de"
                                  v-mask="'##/##/####'"
                                  :rules="[value => valida_data(value) || 'Data inválida']"
                                  color="blue-grey lighten-2"
                                  clearable
                                  prepend-icon="event"
                                  v-on="on"
                                  autocomplete="disabled"
                                  :name="Math.random()"
                                  @click:clear="filtroSelRenovacoesInicioInicialUS = null"
                                  @keyup="
                                    filtroSelRenovacoesInicioInicialUS = valida_data(filtrosSelecionados.renovacoes.dtInicio_inicial)
                                      ? formatDateUS(filtrosSelecionados.renovacoes.dtInicio_inicial)
                                      : null
                                  "
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="filtroSelRenovacoesInicioInicialUS"
                                locale="pt-br"
                                no-title
                                @input="mnfiltroSelRenovacoesInicioInicialUS = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-flex>
                          <!---->
                          <v-flex xs12 md6>
                            <v-menu
                              ref="mnfiltroSelRenovacoesInicioFinalUS"
                              v-model="mnfiltroSelRenovacoesInicioFinalUS"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="filtrosSelecionados.renovacoes.dtInicio_final"
                                  label="Fim"
                                  hint="Início até"
                                  v-mask="'##/##/####'"
                                  :rules="[value => valida_data(value) || 'Data inválida']"
                                  color="blue-grey lighten-2"
                                  clearable
                                  prepend-icon="event"
                                  v-on="on"
                                  autocomplete="disabled"
                                  :name="Math.random()"
                                  @click:clear="filtroSelRenovacoesInicioFinalUS = null"
                                  @keyup="
                                    filtroSelRenovacoesInicioFinalUS = valida_data(filtrosSelecionados.renovacoes.dtInicio_final)
                                      ? formatDateUS(filtrosSelecionados.renovacoes.dtInicio_final)
                                      : null
                                  "
                                ></v-text-field>
                              </template>
                              <v-date-picker v-model="filtroSelRenovacoesInicioFinalUS" locale="pt-br" no-title @input="mnfiltroSelRenovacoesInicioFinalUS = false"></v-date-picker>
                            </v-menu>
                          </v-flex>
                        </v-layout>
                      </v-container>

                      <v-flex xs12>
                        <h4 class="overline font-weight-black font-italic">
                          Período Final
                        </h4>
                        <v-divider></v-divider>
                      </v-flex>
                      <v-container fluid grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12 md6>
                            <v-menu
                              ref="mnfiltroSelRenovacoesFimInicialUS"
                              v-model="mnfiltroSelRenovacoesFimInicialUS"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="filtrosSelecionados.renovacoes.dtFim_inicial"
                                  label="Início"
                                  hint="Fim a partir de"
                                  v-mask="'##/##/####'"
                                  :rules="[value => valida_data(value) || 'Data inválida']"
                                  color="blue-grey lighten-2"
                                  clearable
                                  prepend-icon="event"
                                  v-on="on"
                                  autocomplete="disabled"
                                  :name="Math.random()"
                                  @click:clear="filtroSelRenovacoesFimInicialUS = null"
                                  @keyup="
                                    filtroSelRenovacoesFimInicialUS = valida_data(filtrosSelecionados.renovacoes.dtFim_inicial)
                                      ? formatDateUS(filtrosSelecionados.renovacoes.dtFim_inicial)
                                      : null
                                  "
                                ></v-text-field>
                              </template>
                              <v-date-picker v-model="filtroSelRenovacoesFimInicialUS" locale="pt-br" no-title @input="mnfiltroSelRenovacoesFimInicialUS = false"></v-date-picker>
                            </v-menu>
                          </v-flex>
                          <!---->
                          <v-flex xs12 md6>
                            <v-menu
                              ref="mnfiltroSelRenovacoesFimFinalUS"
                              v-model="mnfiltroSelRenovacoesFimFinalUS"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="filtrosSelecionados.renovacoes.dtFim_final"
                                  label="Fim"
                                  hint="Término até"
                                  v-mask="'##/##/####'"
                                  :rules="[value => valida_data(value) || 'Data inválida']"
                                  color="blue-grey lighten-2"
                                  clearable
                                  prepend-icon="event"
                                  v-on="on"
                                  autocomplete="disabled"
                                  :name="Math.random()"
                                  @click:clear="filtroSelRenovacoesFimFinalUS = null"
                                  @keyup="
                                    filtroSelRenovacoesFimFinalUS = valida_data(filtrosSelecionados.renovacoes.dtFim_final)
                                      ? formatDateUS(filtrosSelecionados.renovacoes.dtFim_final)
                                      : null
                                  "
                                ></v-text-field>
                              </template>
                              <v-date-picker v-model="filtroSelRenovacoesFimFinalUS" locale="pt-br" no-title @input="mnfiltroSelRenovacoesFimFinalUS = false"></v-date-picker>
                            </v-menu>
                          </v-flex>
                        </v-layout>
                      </v-container>

                      <v-flex xs12>
                        <h4 class="overline font-weight-black font-italic">
                          Exemplares
                        </h4>
                        <v-divider></v-divider>
                        <v-layout wrap>
                          <v-flex xs6 md6>
                            <v-text-field
                              class="ml-4"
                              v-model="filtrosSelecionados.renovacoes.exemplaresInicio"
                              label="Início"
                              v-mask="'####'"
                              color="blue-grey lighten-2"
                              clearable
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs6 md6>
                            <v-text-field
                              class="ml-4"
                              v-model="filtrosSelecionados.renovacoes.exemplaresFim"
                              label="Fim"
                              v-mask="'####'"
                              color="blue-grey lighten-2"
                              clearable
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                      </v-flex>

                      <v-flex xs12>
                        <h4 class="overline font-weight-black font-italic">
                          Ativos
                        </h4>
                        <v-divider></v-divider>
                        <v-layout wrap>
                          <v-flex xs6 md6>
                            <v-text-field
                              class="ml-4"
                              v-model="filtrosSelecionados.renovacoes.ativosInicio"
                              label="Início"
                              v-mask="'####'"
                              color="blue-grey lighten-2"
                              clearable
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs6 md6>
                            <v-text-field
                              class="ml-4"
                              v-model="filtrosSelecionados.renovacoes.ativosFim"
                              label="Fim"
                              v-mask="'####'"
                              color="blue-grey lighten-2"
                              clearable
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                      </v-flex>

                      <v-flex xs12>
                        <h4 class="overline font-weight-black font-italic">
                          Entregues
                        </h4>
                        <v-divider></v-divider>
                        <v-layout wrap>
                          <v-flex xs6 md6>
                            <v-text-field
                              class="ml-4"
                              v-model="filtrosSelecionados.renovacoes.entreguesInicio"
                              label="Início"
                              v-mask="'####'"
                              color="blue-grey lighten-2"
                              clearable
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs6 md6>
                            <v-text-field
                              class="ml-4"
                              v-model="filtrosSelecionados.renovacoes.entreguesFim"
                              label="Fim"
                              v-mask="'####'"
                              color="blue-grey lighten-2"
                              clearable
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark elevation-0>
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Agente/Agência</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider></v-divider>
                <v-container fluid grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 md12>
                      <v-autocomplete
                        v-model="filtrosSelecionados.agentes.idAgente"
                        :items="agentes"
                        :filter="filtrarObjetos"
                        label="Agente/Agência"
                        item-text="nome"
                        item-value="id"
                        autocomplete="disabled"
                        :name="Math.random()"
                        no-data-text
                        clearable
                        multiple
                      ></v-autocomplete>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark elevation-0>
              <v-expansion-panel-header>
                <strong>Observação</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider></v-divider>
                <v-flex xs12 md12>
                  <v-container fluid grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12>
                        <v-autocomplete
                          v-model="filtrosSelecionados.observacoes.idUsuario"
                          :items="usuarios"
                          :filter="filtrarObjetos"
                          label="Usuário"
                          item-text="nome"
                          item-value="id"
                          autocomplete="disabled"
                          :name="Math.random()"
                          no-data-text
                          clearable
                          multiple
                        ></v-autocomplete>
                      </v-flex>
                      <v-flex xs12>
                        <v-text-field v-model="filtrosSelecionados.observacoes.observacao" label="Observação" maxlength="100" color="blue-grey lighten-2" clearable></v-text-field>
                      </v-flex>

                      <v-flex xs12>
                        <h4 class="overline font-weight-black font-italic">
                          Registrada entre
                        </h4>
                        <v-divider></v-divider>
                      </v-flex>
                      <v-container fluid grid-list-md>
                        <v-layout wrap>
                          <v-flex xs6 md6>
                            <v-menu
                              ref="mnfiltroSelObservacoesInicioUS"
                              v-model="mnfiltroSelObservacoesInicioUS"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="filtrosSelecionados.observacoes.dtCriacaoInicio"
                                  label="Início"
                                  v-mask="'##/##/####'"
                                  :rules="[value => valida_data(value) || 'Data inválida']"
                                  color="blue-grey lighten-2"
                                  clearable
                                  prepend-icon="event"
                                  v-on="on"
                                  autocomplete="disabled"
                                  :name="Math.random()"
                                  @click:clear="filtroSelObservacoesInicioUS = null"
                                  @keyup="
                                    filtroSelObservacoesInicioUS = valida_data(filtrosSelecionados.observacoes.dtCriacaoInicio)
                                      ? formatDateUS(filtrosSelecionados.observacoes.dtCriacaoInicio)
                                      : null
                                  "
                                ></v-text-field>
                              </template>
                              <v-date-picker v-model="filtroSelObservacoesInicioUS" locale="pt-br" no-title @input="mnfiltroSelObservacoesInicioUS = false"></v-date-picker>
                            </v-menu>
                          </v-flex>
                          <v-flex xs6 md6>
                            <v-menu
                              ref="mnfiltroSelObservacoesFimUS"
                              v-model="mnfiltroSelObservacoesFimUS"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="filtrosSelecionados.observacoes.dtCriacaoFim"
                                  label="Fim"
                                  v-mask="'##/##/####'"
                                  :rules="[value => valida_data(value) || 'Data inválida']"
                                  color="blue-grey lighten-2"
                                  clearable
                                  prepend-icon="event"
                                  v-on="on"
                                  autocomplete="disabled"
                                  :name="Math.random()"
                                  @click:clear="filtroSelObservacoesFimUS = null"
                                  @keyup="
                                    filtroSelObservacoesFimUS = valida_data(filtrosSelecionados.observacoes.dtCriacaoFim)
                                      ? formatDateUS(filtrosSelecionados.observacoes.dtCriacaoFim)
                                      : null
                                  "
                                ></v-text-field>
                              </template>
                              <v-date-picker v-model="filtroSelObservacoesFimUS" locale="pt-br" no-title @input="mnfiltroSelObservacoesFimUS = false"></v-date-picker>
                            </v-menu>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-layout>
                  </v-container>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Reclamação</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider></v-divider>
                <v-flex xs12 md12>
                  <v-container fluid grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12>
                        <h4 class="overline font-weight-black font-italic">
                          Reclamação
                        </h4>
                        <v-divider></v-divider>
                      </v-flex>

                      <v-container fluid grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12 md6>
                            <v-autocomplete
                              v-model="filtrosSelecionados.reclamacoes.status"
                              :items="statusReclamacao"
                              :filter="filtrarItens"
                              label="Status"
                              autocomplete="disabled"
                              :name="Math.random()"
                              no-data-text
                              clearable
                            ></v-autocomplete>
                          </v-flex>
                          <v-flex xs12 md6>
                            <v-text-field
                              v-model="filtrosSelecionados.reclamacoes.reclamacao"
                              label="Reclamação"
                              maxlength="100"
                              color="blue-grey lighten-2"
                              clearable
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12>
                            <v-autocomplete
                              v-model="filtrosSelecionados.reclamacoes.idReclamacao"
                              :items="reclamacoesPadrao"
                              :filter="filtrarObjetos"
                              label="Motivo"
                              item-text="nome"
                              item-value="id"
                              autocomplete="disabled"
                              :name="Math.random()"
                              no-data-text
                              clearable
                              multiple
                            ></v-autocomplete>
                          </v-flex>
                          <v-flex xs12>
                            <v-autocomplete
                              v-model="filtrosSelecionados.reclamacoes.idUsuario"
                              :items="usuarios"
                              :filter="filtrarObjetos"
                              label="Usuário"
                              item-text="nome"
                              item-value="id"
                              autocomplete="disabled"
                              :name="Math.random()"
                              no-data-text
                              clearable
                              multiple
                            ></v-autocomplete>
                          </v-flex>

                          <v-flex xs12>
                            <h4 class="overline font-weight-black font-italic">
                              Registrada entre
                            </h4>
                            <v-divider></v-divider>
                          </v-flex>
                          <v-container fluid grid-list-md>
                            <v-layout wrap>
                              <v-flex xs12 md6>
                                <v-menu
                                  ref="mnfiltroSelReclamacoesInicioUS"
                                  v-model="mnfiltroSelReclamacoesInicioUS"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="filtrosSelecionados.reclamacoes.dtRegistroInicio"
                                      label="Início"
                                      v-mask="'##/##/####'"
                                      :rules="[value => valida_data(value) || 'Data inválida']"
                                      color="blue-grey lighten-2"
                                      clearable
                                      prepend-icon="event"
                                      v-on="on"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      @click:clear="filtroSelReclamacoesInicioUS = null"
                                      @keyup="
                                        filtroSelReclamacoesInicioUS = valida_data(filtrosSelecionados.reclamacoes.dtRegistroInicio)
                                          ? formatDateUS(filtrosSelecionados.reclamacoes.dtRegistroInicio)
                                          : null
                                      "
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker v-model="filtroSelReclamacoesInicioUS" locale="pt-br" no-title @input="mnfiltroSelReclamacoesInicioUS = false"></v-date-picker>
                                </v-menu>
                              </v-flex>
                              <v-flex xs12 md6>
                                <v-menu
                                  ref="mnfiltroSelReclamacoesFimUS"
                                  v-model="mnfiltroSelReclamacoesFimUS"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="filtrosSelecionados.reclamacoes.dtRegistroFim"
                                      label="Fim"
                                      v-mask="'##/##/####'"
                                      :rules="[value => valida_data(value) || 'Data inválida']"
                                      color="blue-grey lighten-2"
                                      clearable
                                      prepend-icon="event"
                                      v-on="on"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      @click:clear="filtroSelReclamacoesFimUS = null"
                                      @keyup="
                                        filtroSelReclamacoesFimUS = valida_data(filtrosSelecionados.reclamacoes.dtRegistroFim)
                                          ? formatDateUS(filtrosSelecionados.reclamacoes.dtRegistroFim)
                                          : null
                                      "
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker v-model="filtroSelReclamacoesFimUS" locale="pt-br" no-title @input="mnfiltroSelReclamacoesFimUS = false"></v-date-picker>
                                </v-menu>
                              </v-flex>
                            </v-layout>
                          </v-container>
                        </v-layout>
                      </v-container>

                      <v-flex xs12>
                        <h4 class="overline font-weight-black font-italic">
                          Execução
                        </h4>
                        <v-divider></v-divider>
                      </v-flex>
                      <v-container fluid grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12>
                            <v-text-field v-model="filtrosSelecionados.reclamacoes.execucao" label="Execução" maxlength="100" color="blue-grey lighten-2" clearable></v-text-field>
                          </v-flex>
                          <v-flex xs12>
                            <v-autocomplete
                              v-model="filtrosSelecionados.reclamacoes.idUsuarioExecucao"
                              :items="usuarios"
                              :filter="filtrarObjetos"
                              label="Usuário"
                              item-text="nome"
                              item-value="id"
                              autocomplete="disabled"
                              :name="Math.random()"
                              no-data-text
                              clearable
                              multiple
                            ></v-autocomplete>
                          </v-flex>
                        </v-layout>
                      </v-container>
                      <v-container fluid grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12>
                            <h4 class="overline font-weight-black font-italic">
                              Executada entre
                            </h4>
                            <v-divider></v-divider>
                          </v-flex>
                          <v-container fluid grid-list-md>
                            <v-layout wrap>
                              <v-flex xs12 md6>
                                <v-menu
                                  ref="mnfiltroSelReclamacoesDtExecucaoInicioUS"
                                  v-model="mnfiltroSelReclamacoesDtExecucaoInicioUS"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="filtrosSelecionados.reclamacoes.dtExecucaoInicio"
                                      label="Início"
                                      v-mask="'##/##/####'"
                                      :rules="[value => valida_data(value) || 'Data inválida']"
                                      color="blue-grey lighten-2"
                                      clearable
                                      prepend-icon="event"
                                      v-on="on"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      @click:clear="filtroSelReclamacoesDtExecucaoInicioUS = null"
                                      @keyup="
                                        filtroSelReclamacoesDtExecucaoInicioUS = valida_data(filtrosSelecionados.reclamacoes.dtExecucaoInicio)
                                          ? formatDateUS(filtrosSelecionados.reclamacoes.dtExecucaoInicio)
                                          : null
                                      "
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="filtroSelReclamacoesDtExecucaoInicioUS"
                                    locale="pt-br"
                                    no-title
                                    @input="mnfiltroSelReclamacoesDtExecucaoInicioUS = false"
                                  ></v-date-picker>
                                </v-menu>
                              </v-flex>
                              <v-flex xs12 md6>
                                <v-menu
                                  ref="mnfiltroSelReclamacoesDtExecucaoFimUS"
                                  v-model="mnfiltroSelReclamacoesDtExecucaoFimUS"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="filtrosSelecionados.reclamacoes.dtExecucaoFim"
                                      label="Fim"
                                      v-mask="'##/##/####'"
                                      :rules="[value => valida_data(value) || 'Data inválida']"
                                      color="blue-grey lighten-2"
                                      clearable
                                      prepend-icon="event"
                                      v-on="on"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      @click:clear="filtroSelReclamacoesDtExecucaoFimUS = null"
                                      @keyup="
                                        filtroSelReclamacoesDtExecucaoFimUS = valida_data(filtrosSelecionados.reclamacoes.dtExecucaoFim)
                                          ? formatDateUS(filtrosSelecionados.reclamacoes.dtExecucaoFim)
                                          : null
                                      "
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="filtroSelReclamacoesDtExecucaoFimUS"
                                    locale="pt-br"
                                    no-title
                                    @input="mnfiltroSelReclamacoesDtExecucaoFimUS = false"
                                  ></v-date-picker>
                                </v-menu>
                              </v-flex>
                            </v-layout>
                          </v-container>
                        </v-layout>
                      </v-container>

                      <v-flex xs12>
                        <h4 class="overline font-weight-black font-italic">
                          Solução
                        </h4>
                        <v-divider></v-divider>
                      </v-flex>
                      <v-container fluid grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12>
                            <v-text-field v-model="filtrosSelecionados.reclamacoes.solucao" label="Resolução" maxlength="100" color="blue-grey lighten-2" clearable></v-text-field>
                          </v-flex>
                          <v-flex xs12>
                            <v-autocomplete
                              v-model="filtrosSelecionados.reclamacoes.idUsuarioSolucao"
                              :items="usuarios"
                              :filter="filtrarObjetos"
                              label="Usuário"
                              item-text="nome"
                              item-value="id"
                              autocomplete="disabled"
                              :name="Math.random()"
                              no-data-text
                              clearable
                              multiple
                            ></v-autocomplete>
                          </v-flex>
                        </v-layout>
                      </v-container>
                      <v-container fluid grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12>
                            <h4 class="overline font-weight-black font-italic">
                              Solucionada entre
                            </h4>
                            <v-divider></v-divider>
                          </v-flex>
                          <v-container fluid grid-list-md>
                            <v-layout wrap>
                              <v-flex xs12 md6>
                                <v-menu
                                  ref="mnfiltroSelReclamacoesDtSolucaoInicioUS"
                                  v-model="mnfiltroSelReclamacoesDtSolucaoInicioUS"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="filtrosSelecionados.reclamacoes.dtSolucaoInicio"
                                      label="Início"
                                      v-mask="'##/##/####'"
                                      :rules="[value => valida_data(value) || 'Data inválida']"
                                      color="blue-grey lighten-2"
                                      clearable
                                      prepend-icon="event"
                                      v-on="on"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      @click:clear="filtroSelReclamacoesDtSolucaoInicioUS = null"
                                      @keyup="
                                        filtroSelReclamacoesDtSolucaoInicioUS = valida_data(filtrosSelecionados.reclamacoes.dtSolucaoInicio)
                                          ? formatDateUS(filtrosSelecionados.reclamacoes.dtSolucaoInicio)
                                          : null
                                      "
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="filtroSelReclamacoesDtSolucaoInicioUS"
                                    locale="pt-br"
                                    no-title
                                    @input="mnfiltroSelReclamacoesDtSolucaoInicioUS = false"
                                  ></v-date-picker>
                                </v-menu>
                              </v-flex>
                              <v-flex xs12 md6>
                                <v-menu
                                  ref="mnfiltroSelReclamacoesDtSolucaoFimUS"
                                  v-model="mnfiltroSelReclamacoesDtSolucaoFimUS"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="filtrosSelecionados.reclamacoes.dtSolucaoFim"
                                      label="Fim"
                                      v-mask="'##/##/####'"
                                      :rules="[value => valida_data(value) || 'Data inválida']"
                                      color="blue-grey lighten-2"
                                      clearable
                                      prepend-icon="event"
                                      v-on="on"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      @click:clear="filtroSelReclamacoesDtSolucaoFimUS = null"
                                      @keyup="
                                        filtroSelReclamacoesDtSolucaoFimUS = valida_data(filtrosSelecionados.reclamacoes.dtSolucaoFim)
                                          ? formatDateUS(filtrosSelecionados.reclamacoes.dtSolucaoFim)
                                          : null
                                      "
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="filtroSelReclamacoesDtSolucaoFimUS"
                                    locale="pt-br"
                                    no-title
                                    @input="mnfiltroSelReclamacoesDtSolucaoFimUS = false"
                                  ></v-date-picker>
                                </v-menu>
                              </v-flex>
                            </v-layout>
                          </v-container>
                        </v-layout>
                      </v-container>
                    </v-layout>
                  </v-container>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Suspensão</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider></v-divider>
                <v-flex xs12 md12>
                  <v-spacer></v-spacer>

                  <v-container fluid grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12 md12>
                        <v-autocomplete
                          v-model="filtrosSelecionados.suspensoes.idUsuario"
                          :items="usuarios"
                          :filter="filtrarObjetos"
                          label="Usuário"
                          item-text="nome"
                          item-value="id"
                          autocomplete="disabled"
                          :name="Math.random()"
                          no-data-text
                          clearable
                          multiple
                        ></v-autocomplete>
                      </v-flex>
                      <v-flex xs12>
                        <v-autocomplete
                          v-model="filtrosSelecionados.suspensoes.idMotivoSuspensao"
                          :items="motivosSuspensao"
                          :filter="filtrarObjetos"
                          label="Motivo"
                          item-text="nome"
                          item-value="id"
                          autocomplete="disabled"
                          :name="Math.random()"
                          no-data-text
                          clearable
                          multiple
                        ></v-autocomplete>
                      </v-flex>
                      <v-flex xs12 md12>
                        <v-text-field v-model="filtrosSelecionados.suspensoes.motivo" label="Descrição" maxlength="100" color="blue-grey lighten-2" clearable></v-text-field>
                      </v-flex>

                      <v-flex xs12>
                        <h4 class="overline font-weight-black font-italic">
                          Registrada entre
                        </h4>
                        <v-divider></v-divider>
                      </v-flex>
                      <v-container fluid grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12 md6>
                            <v-menu
                              ref="mnfiltroSelSuspensoesDtRegistroInicioUS"
                              v-model="mnfiltroSelSuspensoesDtRegistroInicioUS"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="filtrosSelecionados.suspensoes.dtRegistroInicio"
                                  label="Início"
                                  v-mask="'##/##/####'"
                                  :rules="[value => valida_data(value) || 'Data inválida']"
                                  color="blue-grey lighten-2"
                                  clearable
                                  prepend-icon="event"
                                  v-on="on"
                                  autocomplete="disabled"
                                  :name="Math.random()"
                                  @click:clear="filtroSelSuspensoesDtRegistroInicioUS = null"
                                  @keyup="
                                    filtroSelSuspensoesDtRegistroInicioUS = valida_data(filtrosSelecionados.suspensoes.dtRegistroInicio)
                                      ? formatDateUS(filtrosSelecionados.suspensoes.dtRegistroInicio)
                                      : null
                                  "
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="filtroSelSuspensoesDtRegistroInicioUS"
                                locale="pt-br"
                                no-title
                                @input="mnfiltroSelSuspensoesDtRegistroInicioUS = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-flex>
                          <v-flex xs12 md6>
                            <v-menu
                              ref="mnfiltroSelSuspensoesDtRegistroFimUS"
                              v-model="mnfiltroSelSuspensoesDtRegistroFimUS"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="filtrosSelecionados.suspensoes.dtRegistroFim"
                                  label="Fim"
                                  v-mask="'##/##/####'"
                                  :rules="[value => valida_data(value) || 'Data inválida']"
                                  color="blue-grey lighten-2"
                                  clearable
                                  prepend-icon="event"
                                  v-on="on"
                                  autocomplete="disabled"
                                  :name="Math.random()"
                                  @click:clear="filtroSelSuspensoesDtRegistroFimUS = null"
                                  @keyup="
                                    filtroSelSuspensoesDtRegistroFimUS = valida_data(filtrosSelecionados.suspensoes.dtRegistroFim)
                                      ? formatDateUS(filtrosSelecionados.suspensoes.dtRegistroFim)
                                      : null
                                  "
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="filtroSelSuspensoesDtRegistroFimUS"
                                locale="pt-br"
                                no-title
                                @input="mnfiltroSelSuspensoesDtRegistroFimUS = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-flex>
                        </v-layout>
                      </v-container>

                      <v-flex xs12>
                        <h4 class="overline font-weight-black font-italic">
                          Suspensa entre
                        </h4>
                        <v-divider></v-divider>
                      </v-flex>
                      <v-container fluid grid-list-md>
                        <v-layout wrap>
                          <v-flex xs6 md6>
                            <v-menu
                              ref="mnfiltroSelSuspensoesDtInicioUS"
                              v-model="mnfiltroSelSuspensoesDtInicioUS"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="filtrosSelecionados.suspensoes.dtInicio"
                                  label="Início"
                                  v-mask="'##/##/####'"
                                  :rules="[value => valida_data(value) || 'Data inválida']"
                                  color="blue-grey lighten-2"
                                  clearable
                                  prepend-icon="event"
                                  v-on="on"
                                  autocomplete="disabled"
                                  :name="Math.random()"
                                  @click:clear="filtroSelSuspensoesDtInicioUS = null"
                                  @keyup="
                                    filtroSelSuspensoesDtInicioUS = valida_data(filtrosSelecionados.suspensoes.dtInicio)
                                      ? formatDateUS(filtrosSelecionados.suspensoes.dtInicio)
                                      : null
                                  "
                                ></v-text-field>
                              </template>
                              <v-date-picker v-model="filtroSelSuspensoesDtInicioUS" locale="pt-br" no-title @input="mnfiltroSelSuspensoesDtInicioUS = false"></v-date-picker>
                            </v-menu>
                          </v-flex>
                          <v-flex xs6 md6>
                            <v-menu
                              ref="mnfiltroSelSuspensoesDtFimUS"
                              v-model="mnfiltroSelSuspensoesDtFimUS"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="filtrosSelecionados.suspensoes.dtFim"
                                  label="Fim"
                                  v-mask="'##/##/####'"
                                  :rules="[value => valida_data(value) || 'Data inválida']"
                                  color="blue-grey lighten-2"
                                  clearable
                                  prepend-icon="event"
                                  v-on="on"
                                  autocomplete="disabled"
                                  :name="Math.random()"
                                  @click:clear="filtroSelSuspensoesDtFimUS = null"
                                  @keyup="
                                    filtroSelSuspensoesDtFimUS = valida_data(filtrosSelecionados.suspensoes.dtFim) ? formatDateUS(filtrosSelecionados.suspensoes.dtFim) : null
                                  "
                                ></v-text-field>
                              </template>
                              <v-date-picker v-model="filtroSelSuspensoesDtFimUS" locale="pt-br" no-title @input="mnfiltroSelSuspensoesDtFimUS = false"></v-date-picker>
                            </v-menu>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-layout>
                  </v-container>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Regras de Ajuste de Preços</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider></v-divider>
                <v-flex xs12 md12>
                  <v-container fluid grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12 md6>
                        <v-autocomplete
                          v-model="filtrosSelecionados.regrasAjuste.idRegraAjuste"
                          :items="regrasAjuste"
                          :filter="filtrarObjetos"
                          label="Regra de Ajuste"
                          item-text="nome"
                          item-value="id"
                          autocomplete="disabled"
                          :name="Math.random()"
                          no-data-text
                          clearable
                          multiple
                        ></v-autocomplete>
                      </v-flex>

                      <v-flex xs12 md6>
                        <v-text-field v-model="filtrosSelecionados.regrasAjuste.motivo" label="Motivo" maxlength="100" color="blue-grey lighten-2" clearable></v-text-field>
                      </v-flex>

                      <v-flex xs12>
                        <v-autocomplete
                          v-model="filtrosSelecionados.regrasAjuste.idUsuario"
                          :items="usuarios"
                          :filter="filtrarObjetos"
                          label="Usuário"
                          item-text="nome"
                          item-value="id"
                          autocomplete="disabled"
                          :name="Math.random()"
                          no-data-text
                          clearable
                          multiple
                        ></v-autocomplete>
                      </v-flex>

                      <v-flex xs12>
                        <h4 class="overline font-weight-black font-italic">
                          Aplicada entre
                        </h4>
                        <v-divider></v-divider>
                      </v-flex>
                      <v-container fluid grid-list-md>
                        <v-layout wrap>
                          <v-flex xs6 md6>
                            <v-menu
                              ref="mnfiltroSelRegAjusDtRegistroInicioUS"
                              v-model="mnfiltroSelRegAjusDtRegistroInicioUS"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="filtrosSelecionados.regrasAjuste.dtRegistroInicio"
                                  label="Início"
                                  v-mask="'##/##/####'"
                                  :rules="[value => valida_data(value) || 'Data inválida']"
                                  color="blue-grey lighten-2"
                                  clearable
                                  prepend-icon="event"
                                  v-on="on"
                                  autocomplete="disabled"
                                  :name="Math.random()"
                                  @click:clear="filtroSelRegAjusDtRegistroInicioUS = null"
                                  @keyup="
                                    filtroSelRegAjusDtRegistroInicioUS = valida_data(filtrosSelecionados.regrasAjuste.dtRegistroInicio)
                                      ? formatDateUS(filtrosSelecionados.regrasAjuste.dtRegistroInicio)
                                      : null
                                  "
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="filtroSelRegAjusDtRegistroInicioUS"
                                locale="pt-br"
                                no-title
                                @input="mnfiltroSelRegAjusDtRegistroInicioUS = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-flex>
                          <v-flex xs6 md6>
                            <v-menu
                              ref="mnfiltroSelRegAjusDtRegistroFimUS"
                              v-model="mnfiltroSelRegAjusDtRegistroFimUS"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="filtrosSelecionados.regrasAjuste.dtRegistroFim"
                                  label="Fim"
                                  v-mask="'##/##/####'"
                                  :rules="[value => valida_data(value) || 'Data inválida']"
                                  color="blue-grey lighten-2"
                                  clearable
                                  prepend-icon="event"
                                  v-on="on"
                                  autocomplete="disabled"
                                  :name="Math.random()"
                                  @click:clear="filtroSelRegAjusDtRegistroFimUS = null"
                                  @keyup="
                                    filtroSelRegAjusDtRegistroFimUS = valida_data(filtrosSelecionados.regrasAjuste.dtRegistroFim)
                                      ? formatDateUS(filtrosSelecionados.regrasAjuste.dtRegistroFim)
                                      : null
                                  "
                                ></v-text-field>
                              </template>
                              <v-date-picker v-model="filtroSelRegAjusDtRegistroFimUS" locale="pt-br" no-title @input="mnfiltroSelRegAjusDtRegistroFimUS = false"></v-date-picker>
                            </v-menu>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-layout>
                  </v-container>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Promoção</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-container fluid grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12 md12>
                        <v-autocomplete
                          v-model="filtrosSelecionados.promocoes.idPromocao"
                          :items="promocoes"
                          :filter="filtrarObjetos"
                          label="Promoção"
                          item-text="nome"
                          item-value="id"
                          autocomplete="disabled"
                          :name="Math.random()"
                          no-data-text
                          clearable
                          multiple
                        ></v-autocomplete>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Cortesias</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-form ref="formCortesia" lazy-validation>
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12 md12>
                          <v-autocomplete
                            v-model="filtrosSelecionados.cortesias.idCliente"
                            :items="cortesias"
                            :filter="filtrarObjetos"
                            label="Empresa com Cortesia"
                            item-text="nome"
                            item-value="id"
                            no-data-text
                            clearable
                            multiple
                            autocomplete="disabled"
                            :name="Math.random()"
                          ></v-autocomplete>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-form>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Cancelamento</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider></v-divider>
                <v-flex xs12 md12>
                  <v-container fluid grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12>
                        <h4 class="overline font-weight-black font-italic">
                          Cancelamento
                        </h4>
                        <v-divider></v-divider>
                      </v-flex>
                      <v-container fluid grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12 md6>
                            <v-autocomplete
                              v-model="filtrosSelecionados.cancelamentos.idUsuarioCancelamento"
                              :items="usuarios"
                              :filter="filtrarObjetos"
                              label="Usuário"
                              item-text="nome"
                              item-value="id"
                              autocomplete="disabled"
                              :name="Math.random()"
                              no-data-text
                              clearable
                              multiple
                            ></v-autocomplete>
                          </v-flex>
                          <v-flex xs12 md6>
                            <v-autocomplete
                              v-model="filtrosSelecionados.cancelamentos.idMotivoCancelamento"
                              :items="motivosCancelamento"
                              :filter="filtrarObjetos"
                              label="Motivo"
                              item-text="nome"
                              item-value="id"
                              autocomplete="disabled"
                              :name="Math.random()"
                              no-data-text
                              clearable
                              multiple
                            ></v-autocomplete>
                          </v-flex>

                          <v-flex xs12>
                            <v-text-field
                              v-model="filtrosSelecionados.cancelamentos.motivoCancelamento"
                              label="Descrição"
                              maxlength="100"
                              color="blue-grey lighten-2"
                              clearable
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                      </v-container>
                      <v-container fluid grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12>
                            <h4 class="overline font-weight-black font-italic">
                              Registrada em
                            </h4>
                            <v-divider></v-divider>
                          </v-flex>
                          <v-container fluid grid-list-md>
                            <v-layout wrap>
                              <v-flex xs12 md6>
                                <v-menu
                                  ref="mnfiltroSelCancDtRegistroInicioUS"
                                  v-model="mnfiltroSelCancDtRegistroInicioUS"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="filtrosSelecionados.cancelamentos.dtRegistroInicio"
                                      label="Início"
                                      v-mask="'##/##/####'"
                                      :rules="[value => valida_data(value) || 'Data inválida']"
                                      color="blue-grey lighten-2"
                                      clearable
                                      prepend-icon="event"
                                      v-on="on"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      @click:clear="filtroSelCancDtRegistroInicioUS = null"
                                      @keyup="
                                        filtroSelCancDtRegistroInicioUS = valida_data(filtrosSelecionados.cancelamentos.dtRegistroInicio)
                                          ? formatDateUS(filtrosSelecionados.cancelamentos.dtRegistroInicio)
                                          : null
                                      "
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="filtroSelCancDtRegistroInicioUS"
                                    locale="pt-br"
                                    no-title
                                    @input="mnfiltroSelCancDtRegistroInicioUS = false"
                                  ></v-date-picker>
                                </v-menu>
                              </v-flex>
                              <v-flex xs12 md6>
                                <v-menu
                                  ref="mnfiltroSelCancDtRegistroFimUS"
                                  v-model="mnfiltroSelCancDtRegistroFimUS"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="filtrosSelecionados.cancelamentos.dtRegistroFim"
                                      label="Fim"
                                      v-mask="'##/##/####'"
                                      :rules="[value => valida_data(value) || 'Data inválida']"
                                      color="blue-grey lighten-2"
                                      clearable
                                      prepend-icon="event"
                                      v-on="on"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      @click:clear="filtroSelCancDtRegistroFimUS = null"
                                      @keyup="
                                        filtroSelCancDtRegistroFimUS = valida_data(filtrosSelecionados.cancelamentos.dtRegistroFim)
                                          ? formatDateUS(filtrosSelecionados.cancelamentos.dtRegistroFim)
                                          : null
                                      "
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker v-model="filtroSelCancDtRegistroFimUS" locale="pt-br" no-title @input="mnfiltroSelCancDtRegistroFimUS = false"></v-date-picker>
                                </v-menu>
                              </v-flex>
                            </v-layout>
                          </v-container>
                          <v-flex xs12>
                            <h4 class="overline font-weight-black font-italic">
                              Agendamento entre
                            </h4>
                            <v-divider></v-divider>
                          </v-flex>
                          <v-container fluid grid-list-md>
                            <v-layout wrap>
                              <v-flex xs12 md6>
                                <v-menu
                                  ref="mnfiltroSelCancDtAgendamentoInicioUS"
                                  v-model="mnfiltroSelCancDtAgendamentoInicioUS"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="filtrosSelecionados.cancelamentos.dtAgendamentoInicio"
                                      label="Início"
                                      v-mask="'##/##/####'"
                                      :rules="[value => valida_data(value) || 'Data inválida']"
                                      color="blue-grey lighten-2"
                                      clearable
                                      prepend-icon="event"
                                      v-on="on"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      @click:clear="filtroSelCancDtAgendamentoInicioUS = null"
                                      @keyup="
                                        filtroSelCancDtAgendamentoInicioUS = valida_data(filtrosSelecionados.cancelamentos.dtAgendamentoInicio)
                                          ? formatDateUS(filtrosSelecionados.cancelamentos.dtAgendamentoInicio)
                                          : null
                                      "
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="filtroSelCancDtAgendamentoInicioUS"
                                    locale="pt-br"
                                    no-title
                                    @input="mnfiltroSelCancDtAgendamentoInicioUS = false"
                                  ></v-date-picker>
                                </v-menu>
                              </v-flex>
                              <v-flex xs12 md6>
                                <v-menu
                                  ref="mnfiltroSelCancDtAgendamentoFimUS"
                                  v-model="mnfiltroSelCancDtAgendamentoFimUS"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="filtrosSelecionados.cancelamentos.dtAgendamentoFim"
                                      label="Fim"
                                      v-mask="'##/##/####'"
                                      :rules="[value => valida_data(value) || 'Data inválida']"
                                      color="blue-grey lighten-2"
                                      clearable
                                      prepend-icon="event"
                                      v-on="on"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      @click:clear="filtroSelCancDtAgendamentoFimUS = null"
                                      @keyup="
                                        filtroSelCancDtAgendamentoFimUS = valida_data(filtrosSelecionados.cancelamentos.dtAgendamentoFim)
                                          ? formatDateUS(filtrosSelecionados.cancelamentos.dtAgendamentoFim)
                                          : null
                                      "
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="filtroSelCancDtAgendamentoFimUS"
                                    locale="pt-br"
                                    no-title
                                    @input="mnfiltroSelCancDtAgendamentoFimUS = false"
                                  ></v-date-picker>
                                </v-menu>
                              </v-flex>
                            </v-layout>
                          </v-container>
                          <v-flex xs12>
                            <h4 class="overline font-weight-black font-italic">
                              Cancelamento entre
                            </h4>
                            <v-divider></v-divider>
                          </v-flex>
                          <v-container fluid grid-list-md>
                            <v-layout wrap>
                              <v-flex xs12 md6>
                                <v-menu
                                  ref="mnfiltroSelCancDtCancelamentoInicioUS"
                                  v-model="mnfiltroSelCancDtCancelamentoInicioUS"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="filtrosSelecionados.cancelamentos.dtCancelamentoInicio"
                                      label="Início"
                                      v-mask="'##/##/####'"
                                      :rules="[value => valida_data(value) || 'Data inválida']"
                                      color="blue-grey lighten-2"
                                      clearable
                                      prepend-icon="event"
                                      v-on="on"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      @click:clear="filtroSelCancDtCancelamentoInicioUS = null"
                                      @keyup="
                                        filtroSelCancDtCancelamentoInicioUS = valida_data(filtrosSelecionados.cancelamentos.dtCancelamentoInicio)
                                          ? formatDateUS(filtrosSelecionados.cancelamentos.dtCancelamentoInicio)
                                          : null
                                      "
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="filtroSelCancDtCancelamentoInicioUS"
                                    locale="pt-br"
                                    no-title
                                    @input="mnfiltroSelCancDtCancelamentoInicioUS = false"
                                  ></v-date-picker>
                                </v-menu>
                              </v-flex>
                              <v-flex xs12 md6>
                                <v-menu
                                  ref="mnfiltroSelCancDtCancelamentoFimUS"
                                  v-model="mnfiltroSelCancDtCancelamentoFimUS"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="filtrosSelecionados.cancelamentos.dtCancelamentoFim"
                                      label="Fim"
                                      v-mask="'##/##/####'"
                                      :rules="[value => valida_data(value) || 'Data inválida']"
                                      color="blue-grey lighten-2"
                                      clearable
                                      prepend-icon="event"
                                      v-on="on"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      @click:clear="filtroSelCancDtCancelamentoFimUS = null"
                                      @keyup="
                                        filtroSelCancDtCancelamentoFimUS = valida_data(filtrosSelecionados.cancelamentos.dtCancelamentoFim)
                                          ? formatDateUS(filtrosSelecionados.cancelamentos.dtCancelamentoFim)
                                          : null
                                      "
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="filtroSelCancDtCancelamentoFimUS"
                                    locale="pt-br"
                                    no-title
                                    @input="mnfiltroSelCancDtCancelamentoFimUS = false"
                                  ></v-date-picker>
                                </v-menu>
                              </v-flex>
                            </v-layout>
                          </v-container>
                        </v-layout>
                      </v-container>

                      <v-layout wrap>
                        <v-flex xs12>
                          <h4 class="overline font-weight-black font-italic">
                            Recuperação
                          </h4>
                          <v-divider></v-divider>
                        </v-flex>
                      </v-layout>
                      <v-container fluid grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12 md6>
                            <v-autocomplete
                              v-model="filtrosSelecionados.cancelamentos.idUsuarioRecuperacao"
                              :items="usuarios"
                              :filter="filtrarObjetos"
                              label="Usuário"
                              item-text="nome"
                              item-value="id"
                              autocomplete="disabled"
                              :name="Math.random()"
                              no-data-text
                              clearable
                              multiple
                            ></v-autocomplete>
                          </v-flex>
                          <v-flex xs12 md6>
                            <v-text-field
                              v-model="filtrosSelecionados.cancelamentos.motivoRecuperacao"
                              label="Motivo"
                              maxlength="100"
                              color="blue-grey lighten-2"
                              clearable
                            ></v-text-field>
                          </v-flex>

                          <v-flex xs12>
                            <h4 class="overline font-weight-black font-italic">
                              Recuperada entre
                            </h4>
                            <v-divider></v-divider>
                          </v-flex>
                          <v-container fluid grid-list-md>
                            <v-layout wrap>
                              <v-flex xs12 md6>
                                <v-menu
                                  ref="mnfiltroSelCanDtRecuperacaoInicioUS"
                                  v-model="mnfiltroSelCanDtRecuperacaoInicioUS"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="filtrosSelecionados.cancelamentos.dtRecuperacaoInicio"
                                      label="Início"
                                      v-mask="'##/##/####'"
                                      :rules="[value => valida_data(value) || 'Data inválida']"
                                      color="blue-grey lighten-2"
                                      clearable
                                      prepend-icon="event"
                                      v-on="on"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      @click:clear="filtroSelCanDtRecuperacaoInicioUS = null"
                                      @keyup="
                                        filtroSelCanDtRecuperacaoInicioUS = valida_data(filtrosSelecionados.cancelamentos.dtRecuperacaoInicio)
                                          ? formatDateUS(filtrosSelecionados.cancelamentos.dtRecuperacaoInicio)
                                          : null
                                      "
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="filtroSelCanDtRecuperacaoInicioUS"
                                    locale="pt-br"
                                    no-title
                                    @input="mnfiltroSelCanDtRecuperacaoInicioUS = false"
                                  ></v-date-picker>
                                </v-menu>
                              </v-flex>
                              <v-flex xs12 md6>
                                <v-menu
                                  ref="mnfiltroSelCanDtRecuperacaoFimUS"
                                  v-model="mnfiltroSelCanDtRecuperacaoFimUS"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="filtrosSelecionados.cancelamentos.dtRecuperacaoFim"
                                      label="Fim"
                                      v-mask="'##/##/####'"
                                      :rules="[value => valida_data(value) || 'Data inválida']"
                                      color="blue-grey lighten-2"
                                      clearable
                                      prepend-icon="event"
                                      v-on="on"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      @click:clear="filtroSelCanDtRecuperacaoFimUS = null"
                                      @keyup="
                                        filtroSelCanDtRecuperacaoFimUS = valida_data(filtrosSelecionados.cancelamentos.dtRecuperacaoFim)
                                          ? formatDateUS(filtrosSelecionados.cancelamentos.dtRecuperacaoFim)
                                          : null
                                      "
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker v-model="filtroSelCanDtRecuperacaoFimUS" locale="pt-br" no-title @input="mnfiltroSelCanDtRecuperacaoFimUS = false"></v-date-picker>
                                </v-menu>
                              </v-flex>
                            </v-layout>
                          </v-container>
                        </v-layout>
                      </v-container>
                      <v-layout wrap>
                        <v-flex xs12>
                          <h4 class="overline font-weight-black font-italic">
                            Reversão
                          </h4>
                          <v-divider></v-divider>
                        </v-flex>
                      </v-layout>
                      <v-container fluid grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12 md6>
                            <v-autocomplete
                              v-model="filtrosSelecionados.cancelamentos.idUsuarioReversao"
                              :items="usuarios"
                              :filter="filtrarObjetos"
                              label="Usuário"
                              item-text="nome"
                              item-value="id"
                              autocomplete="disabled"
                              :name="Math.random()"
                              no-data-text
                              clearable
                              multiple
                            ></v-autocomplete>
                          </v-flex>
                          <v-flex xs12 md6>
                            <v-text-field
                              v-model="filtrosSelecionados.cancelamentos.motivoReversao"
                              label="Motivo"
                              maxlength="100"
                              color="blue-grey lighten-2"
                              clearable
                            ></v-text-field>
                          </v-flex>

                          <v-flex xs12>
                            <h4 class="overline font-weight-black font-italic">
                              Revertida entre
                            </h4>
                            <v-divider></v-divider>
                          </v-flex>
                          <v-flex xs12 md6>
                            <v-menu
                              ref="mnfiltroSelCanDtReversaoInicioUS"
                              v-model="mnfiltroSelCanDtReversaoInicioUS"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="filtrosSelecionados.cancelamentos.dtReversaoInicio"
                                  label="Início"
                                  v-mask="'##/##/####'"
                                  :rules="[value => valida_data(value) || 'Data inválida']"
                                  color="blue-grey lighten-2"
                                  clearable
                                  prepend-icon="event"
                                  v-on="on"
                                  autocomplete="disabled"
                                  :name="Math.random()"
                                  @click:clear="filtroSelCanDtReversaoInicioUS = null"
                                  @keyup="
                                    filtroSelCanDtReversaoInicioUS = valida_data(filtrosSelecionados.cancelamentos.dtReversaoInicio)
                                      ? formatDateUS(filtrosSelecionados.cancelamentos.dtReversaoInicio)
                                      : null
                                  "
                                ></v-text-field>
                              </template>
                              <v-date-picker v-model="filtroSelCanDtReversaoInicioUS" locale="pt-br" no-title @input="mnfiltroSelCanDtReversaoInicioUS = false"></v-date-picker>
                            </v-menu>
                          </v-flex>
                          <v-flex xs12 md6>
                            <v-menu
                              ref="mnfiltroSelCanDtReversaoFimUS"
                              v-model="mnfiltroSelCanDtReversaoFimUS"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="filtrosSelecionados.cancelamentos.dtReversaoFim"
                                  label="Fim"
                                  v-mask="'##/##/####'"
                                  :rules="[value => valida_data(value) || 'Data inválida']"
                                  color="blue-grey lighten-2"
                                  clearable
                                  prepend-icon="event"
                                  v-on="on"
                                  autocomplete="disabled"
                                  :name="Math.random()"
                                  @click:clear="filtroSelCanDtReversaoFimUS = null"
                                  @keyup="
                                    filtroSelCanDtReversaoFimUS = valida_data(filtrosSelecionados.cancelamentos.dtReversaoFim)
                                      ? formatDateUS(filtrosSelecionados.cancelamentos.dtReversaoFim)
                                      : null
                                  "
                                ></v-text-field>
                              </template>
                              <v-date-picker v-model="filtroSelCanDtReversaoFimUS" locale="pt-br" no-title @input="mnfiltroSelCanDtReversaoFimUS = false"></v-date-picker>
                            </v-menu>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-layout>
                  </v-container>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark elevation-0>
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Roteiro</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider></v-divider>
                <v-container fluid grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 md10>
                      <v-autocomplete
                        v-model="filtrosSelecionados.roteiro.idRota"
                        :items="rotas"
                        :filter="filtrarObjetos"
                        label="Rota"
                        item-text="nome"
                        item-value="id"
                        autocomplete="disabled"
                        :name="Math.random()"
                        no-data-text
                        clearable
                        multiple
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 md2>
                      <v-text-field
                        v-model="filtrosSelecionados.roteiro.sigla"
                        label="Sigla"
                        maxlength="100"
                        color="blue-grey lighten-2"
                        clearable
                        hint="Enter p/ selecionar"
                        @keyup.enter="rotasSigla"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md12>
                      <v-autocomplete
                        v-model="filtrosSelecionados.roteiro.idEntregador"
                        :items="entregadores"
                        :filter="filtrarObjetos"
                        label="Entregador"
                        item-text="nome"
                        item-value="id"
                        autocomplete="disabled"
                        :name="Math.random()"
                        no-data-text
                        clearable
                        multiple
                      ></v-autocomplete>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
export default {
  props: { prefixo: { type: String, default: "" } },
  data() {
    return {
      filtrosSelecionados: {
        id: null,
        status: null,
        idUsuario: null,
        dtCriacaoInicio: null,
        dtCriacaoFim: null,
        dtCancelamento: null,
        online: null,
        renovacoes: {
          atual: "ATUAL",
          renovacao: null,
          idCliente: null,
          edicao: null,
          edicoes: null,
          dtRenovacaoInicio: null,
          dtRenovacaoFim: null,
          dtInicio_inicial: null,
          dtInicio_final: null,
          dtFim_inicial: null,
          dtFim_final: null,
          exemplaresInicio: null,
          exemplaresFim: null,
          ativosInicio: null,
          ativosFim: null,
          entreguesInicio: null,
          entreguesFim: null,
          idUsuario: null,
          modalidade: null,
          tipoProduto: null,
          tipoProdutoOmitir: null,
          autorizacao: null,
          automatica: null,
          reinicio: null,
          online: null,
          vip: null,
          ativas: 1,
          idPlano: null,
          idProduto: null,
          valorInicio: 0,
          valorFim: 0,
          codAntigo: null,
          idProtheus: null
        },
        agentes: {
          idAgente: null
        },
        observacoes: {
          dtCriacaoInicio: null,
          dtCriacaoFim: null,
          idUsuario: null,
          observacao: ""
        },
        reclamacoes: {
          status: null,
          dtRegistroInicio: null,
          dtRegistroFim: null,
          idUsuario: null,
          idReclamacao: null,
          reclamacao: "",
          idUsuarioExecucao: null,
          dtExecucaoInicio: null,
          dtExecucaoFim: null,
          execucao: "",
          idUsuarioSolucao: null,
          dtSolucaoInicio: null,
          dtSolucaoFim: null,
          solucao: ""
        },
        suspensoes: {
          dtRegistroInicio: null,
          dtRegistroFim: null,
          dtInicio: null,
          dtFim: null,
          idUsuario: null,
          idMotivoSuspensao: null,
          motivo: null
        },
        regrasAjuste: {
          dtRegistroInicio: null,
          dtRegistroFim: null,
          idRegraAjuste: null,
          motivo: null,
          idUsuario: null
        },
        promocoes: {
          idPromocao: null
        },
        cortesias: {
          idCliente: null
        },
        cancelamentos: {
          dtRegistroInicio: null,
          dtRegistroFim: null,
          dtAgendamentoInicio: null,
          dtAgendamentoFim: null,

          idUsuarioCancelamento: null,
          idMotivoCancelamento: null,
          motivoCancelamento: null,
          dtCancelamentoInicio: null,
          dtCancelamentoFim: null,

          dtRecuperacaoInicio: null,
          dtRecuperacaoFim: null,
          idUsuarioRecuperacao: null,
          motivoRecuperacao: null,

          dtReversaoInicio: null,
          dtReversaoFim: null,
          idUsuarioReversao: null,
          motivoReversao: null
        },
        roteiro: {
          idRota: null,
          sigla: null,
          idEntregador: null
        }
      },
      assinaturaDefault: {
        id: null,
        status: null,
        idUsuario: null,
        dtCriacaoInicio: null,
        dtCriacaoFim: null,
        dtCancelamento: null,
        online: null,
        renovacoes: {
          atual: "ATUAL",
          renovacao: null,
          edicao: null,
          edicoes: null,
          dtRenovacaoInicio: null,
          dtRenovacaoFim: null,
          dtInicio_inicial: null,
          dtInicio_final: null,
          dtFim_inicial: null,
          dtFim_final: null,
          exemplaresInicio: null,
          exemplaresFim: null,
          ativosInicio: null,
          ativosFim: null,
          entreguesInicio: null,
          entreguesFim: null,
          idUsuario: null,
          modalidade: null,
          tipoProduto: null,
          tipoProdutoOmitir: null,
          autorizacao: null,
          automatica: null,
          reinicio: null,
          online: null,
          vip: null,
          ativas: 1,
          idPlano: null,
          idProduto: null,
          valorInicio: 0,
          valorFim: 0,
          codAntigo: null,
          idProtheus: null
        },
        agentes: {
          idAgente: null
        },
        observacoes: {
          dtCriacaoInicio: null,
          dtCriacaoFim: null,
          idUsuario: null,
          observacao: ""
        },
        reclamacoes: {
          status: null,
          dtRegistroInicio: null,
          dtRegistroFim: null,
          idUsuario: null,
          idReclamacao: null,
          reclamacao: "",
          idUsuarioExecucao: null,
          dtExecucaoInicio: null,
          dtExecucaoFim: null,
          execucao: "",
          idUsuarioSolucao: null,
          dtSolucaoInicio: null,
          dtSolucaoFim: null,
          solucao: ""
        },
        // cartoesClube: {
        //   idCliente: null,
        //   idDependente: null
        // },
        suspensoes: {
          dtInicio: null,
          dtFim: null,
          idUsuario: null,
          idMotivoSuspensao: null,
          motivo: null
        },
        regrasAjuste: {
          dtRegistroInicio: null,
          dtRegistroFim: null,
          idRegraAjuste: null,
          motivo: null,
          idUsuario: null
        },
        promocoes: {
          idPromocao: null
        },
        cortesias: {
          idCliente: null
        },
        cancelamentos: {
          dtRegistroInicio: null,
          dtRegistroFim: null,
          dtAgendamentoInicio: null,
          dtAgendamentoFim: null,

          idUsuarioCancelamento: null,
          idMotivoCancelamento: null,
          motivoCancelamento: null,
          dtCancelamentoInicio: null,
          dtCancelamentoFim: null,

          dtRecuperacaoInicio: null,
          dtRecuperacaoFim: null,
          idUsuarioRecuperacao: null,
          motivoRecuperacao: null,

          dtReversaoInicio: null,
          dtReversaoFim: null,
          idUsuarioReversao: null,
          motivoReversao: null
        },
        roteiro: {
          idRota: null,
          sigla: null,
          idEntregador: null
        }
      },

      renovacoesDtInicioInicialUS: null,
      mnRenovacoesInicio: false,
      renovacoesDtInicioFinalUS: null,
      mnRenovacoesFim: false,

      renovacoesDtRenovacaoInicialUS: null,
      mnRenovacaoInicio: false,
      renovacoesDtRenovacaoFinalUS: null,
      mnRenovacaoFim: false,

      filtroSelRenovacoesInicioInicialUS: null,
      mnfiltroSelRenovacoesInicioInicialUS: false,
      filtroSelRenovacoesInicioFinalUS: null,
      mnfiltroSelRenovacoesInicioFinalUS: false,

      filtroSelRenovacoesFimInicialUS: null,
      mnfiltroSelRenovacoesFimInicialUS: false,
      filtroSelRenovacoesFimFinalUS: null,
      mnfiltroSelRenovacoesFimFinalUS: false,

      filtroSelObservacoesInicioUS: null,
      mnfiltroSelObservacoesInicioUS: false,

      filtroSelObservacoesFimUS: null,
      mnfiltroSelObservacoesFimUS: false,

      filtroSelReclamacoesInicioUS: null,
      mnfiltroSelReclamacoesInicioUS: false,
      filtroSelReclamacoesFimUS: null,
      mnfiltroSelReclamacoesFimUS: false,

      filtroSelReclamacoesDtExecucaoInicioUS: null,
      mnfiltroSelReclamacoesDtExecucaoInicioUS: false,
      filtroSelReclamacoesDtExecucaoFimUS: null,
      mnfiltroSelReclamacoesDtExecucaoFimUS: false,

      filtroSelReclamacoesDtSolucaoInicioUS: null,
      mnfiltroSelReclamacoesDtSolucaoInicioUS: false,
      filtroSelReclamacoesDtSolucaoFimUS: null,
      mnfiltroSelReclamacoesDtSolucaoFimUS: false,

      filtroSelSuspensoesDtInicioUS: null,
      mnfiltroSelSuspensoesDtInicioUS: false,

      filtroSelSuspensoesDtFimUS: null,
      mnfiltroSelSuspensoesDtFimUS: false,

      filtroSelSuspensoesDtRegistroInicioUS: null,
      mnfiltroSelSuspensoesDtRegistroInicioUS: false,

      filtroSelSuspensoesDtRegistroFimUS: null,
      mnfiltroSelSuspensoesDtRegistroFimUS: false,

      filtroSelRegAjusDtRegistroInicioUS: null,
      mnfiltroSelRegAjusDtRegistroInicioUS: false,

      filtroSelRegAjusDtRegistroFimUS: null,
      mnfiltroSelRegAjusDtRegistroFimUS: false,

      filtroSelCancDtRegistroInicioUS: null,
      mnfiltroSelCancDtRegistroInicioUS: false,

      filtroSelCancDtRegistroFimUS: null,
      mnfiltroSelCancDtRegistroFimUS: false,

      filtroSelCancDtAgendamentoInicioUS: null,
      mnfiltroSelCancDtAgendamentoInicioUS: false,

      filtroSelCancDtAgendamentoFimUS: null,
      mnfiltroSelCancDtAgendamentoFimUS: false,

      filtroSelCancDtCancelamentoInicioUS: null,
      mnfiltroSelCancDtCancelamentoInicioUS: false,

      filtroSelCancDtCancelamentoFimUS: null,
      mnfiltroSelCancDtCancelamentoFimUS: false,

      filtroSelCanDtRecuperacaoInicioUS: null,
      mnfiltroSelCanDtRecuperacaoInicioUS: false,

      filtroSelCanDtRecuperacaoFimUS: null,
      mnfiltroSelCanDtRecuperacaoFimUS: false,

      filtroSelCanDtReversaoInicioUS: null,
      mnfiltroSelCanDtReversaoInicioUS: false,

      filtroSelCanDtReversaoFimUS: null,
      mnfiltroSelCanDtReversaoFimUS: false,

      origensVenda: [],
      motivosCancelamento: [],
      reclamacoesPadrao: [],
      motivosSuspensao: [],
      dependentes: [],
      idSitePrincipal: null,
      promocoes: [],
      cortesias: [],
      usuarios: [],
      formasPgto: [],

      itensStatus: [
        "PROPOSTA",
        "CORRENTE",
        "SUSPENSA",
        // "CORTADA", desativado até o desenvolvimento
        "RECUPERAÇÃO",
        "CANCELADA",
        "EXPIRADA"
      ],

      itensAtual: [
        {
          id: "ATUAL",
          nome: "Considerar somente a Renovação Atual",
          descricao: "* Busca a informação somente na renovação atual (caso não exista, na anterior a atual)."
        },
        {
          id: "RECENTE",
          nome: "Considerar a Renovação mais recente que atenda ao filtro",
          descricao: "* Busca a informação na renovação mais recente (para trás, da última até a primeira)."
        },
        {
          id: "ÚLTIMA",
          nome: "Considerar somente a Última Renovação do contrato",
          descricao: "* Busca a informação somente na última renovação existente no contrato."
        },
        {
          id: "TODAS",
          nome: "Considerar Todas as Renovações",
          descricao: "* Busca a informação relacionada ao contrato, independente da renovação."
        }
      ],

      regrasAjuste: [],
      agentes: [],
      modalidades: [],

      itensTipoProduto: ["IMPRESSO", "ONLINE", "CLUBE"],

      itensSimNao: [
        { id: 1, nome: "SIM" },
        { id: 0, nome: "NÃO" }
      ],

      itensDias: ["DOM", "SEG", "TER", "QUA", "QUI", "SEX", "SAB"],

      statusReclamacao: ["ABERTA", "EXECUÇÃO", "FECHADA"],

      clientes: [],
      produtos: [],
      planos: [],
      cartoesClube: [],

      rotas: [],
      entregadores: [],

      loadingClientes: false,
      loadingDependentes: false,
      searchCliente: "",
      searchDependente: "",

      painel: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],

      autoUpdate: true
    };
  },

  methods: {
    filtroUrlParametros() {
      var filtro = "";

      //* Verifica os campos que foram preenchidos
      if (this.filtrosSelecionados.idAssinatura) {
        filtro = filtro + "&idAssinatura=" + this.filtrosSelecionados.idAssinatura;
      }

      if (this.filtrosSelecionados.status && this.filtrosSelecionados.status.length) {
        filtro = filtro + "&" + this.prefixo + "status=" + this.filtrosSelecionados.status.join(",");
      }

      if (this.filtrosSelecionados.idOrigem && this.filtrosSelecionados.idOrigem.length) {
        filtro = filtro + "&" + this.prefixo + "idOrigem=" + this.filtrosSelecionados.idOrigem.join(",");
      }
      if (this.filtrosSelecionados.idUsuario && this.filtrosSelecionados.idUsuario.length) {
        this.filtrosSelecionados.renovacoes.atual = "RECENTE";
        filtro = filtro + "&" + this.prefixo + "idUsuario=" + this.filtrosSelecionados.idUsuario.join(",");
      }

      if (this.filtrosSelecionados.dtCriacaoInicio) {
        this.filtrosSelecionados.renovacoes.atual = "RECENTE";
        filtro = filtro + "&" + this.prefixo + "dtCriacaoInicio=" + this.filtrosSelecionados.dtCriacaoInicio;
      }

      if (this.filtrosSelecionados.dtCriacaoFim) {
        this.filtrosSelecionados.renovacoes.atual = "RECENTE";
        filtro = filtro + "&" + this.prefixo + "dtCriacaoFim=" + this.filtrosSelecionados.dtCriacaoFim;
      }

      if (this.filtrosSelecionados.dtCancelamento) {
        filtro = filtro + "&" + this.prefixo + "dtCancelamento=" + this.filtrosSelecionados.dtCancelamento;
      }

      if (this.filtrosSelecionados.online != null) {
        filtro = filtro + "&" + this.prefixo + "online=" + this.filtrosSelecionados.online;
      }

      if (this.filtrosSelecionados.idFormaPgto && this.filtrosSelecionados.idFormaPgto.length) {
        filtro = filtro + "&" + this.prefixo + "idFormaPgto=" + this.filtrosSelecionados.idFormaPgto.join(",");
      }

      if (this.filtrosSelecionados.nroParcelas) {
        filtro = filtro + "&" + this.prefixo + "nroParcelas=" + this.filtrosSelecionados.nroParcelas;
      }

      if (this.filtrosSelecionados.idRegraAjuste && this.filtrosSelecionados.idRegraAjuste.length) {
        filtro = filtro + "&" + this.prefixo + "idRegraAjuste=" + this.filtrosSelecionados.idRegraAjuste.join(",");
      }

      //* Valida as renovacoes
      if (this.filtrosSelecionados.renovacoes) {
        if (!this.filtrosSelecionados.renovacoes.ativas) {
          filtro = filtro + "&" + this.prefixo + "renovacoesExcluida=1";
        }

        if (this.filtrosSelecionados.renovacoes.atual) {
          filtro = filtro + "&" + this.prefixo + "renovacoesAtual=" + this.filtrosSelecionados.renovacoes.atual;
        }
        if (this.filtrosSelecionados.renovacoes.renovacao) {
          filtro = filtro + "&" + this.prefixo + "renovacoesRenovacao=" + this.filtrosSelecionados.renovacoes.renovacao;
        }

        if (this.filtrosSelecionados.renovacoes.edicao && this.filtrosSelecionados.renovacoes.edicao.length) {
          filtro = filtro + "&" + this.prefixo + "renovacoesEdicao=" + this.filtrosSelecionados.renovacoes.edicao.join(",");
        }

        if (this.filtrosSelecionados.renovacoes.edicoes) {
          filtro = filtro + "&" + this.prefixo + "renovacoesEdicoes=" + this.filtrosSelecionados.renovacoes.edicoes;
        }

        if (this.filtrosSelecionados.renovacoes.dtRenovacaoInicio) {
          filtro = filtro + "&" + this.prefixo + "renovacoesDtRenovacaoInicio=" + this.filtrosSelecionados.renovacoes.dtRenovacaoInicio;
        }
        if (this.filtrosSelecionados.renovacoes.dtRenovacaoFim) {
          filtro = filtro + "&" + this.prefixo + "renovacoesDtRenovacaoFim=" + this.filtrosSelecionados.renovacoes.dtRenovacaoFim;
        }

        if (this.filtrosSelecionados.renovacoes.dtInicio_inicial) {
          filtro = filtro + "&" + this.prefixo + "renovacoesDtInicioInicial=" + this.filtrosSelecionados.renovacoes.dtInicio_inicial;
        }
        if (this.filtrosSelecionados.renovacoes.dtInicio_final) {
          filtro = filtro + "&" + this.prefixo + "renovacoesDtInicioFinal=" + this.filtrosSelecionados.renovacoes.dtInicio_final;
        }

        if (this.filtrosSelecionados.renovacoes.dtFim_inicial) {
          filtro = filtro + "&" + this.prefixo + "renovacoesDtFimInicial=" + this.filtrosSelecionados.renovacoes.dtFim_inicial;
        }
        if (this.filtrosSelecionados.renovacoes.dtFim_final) {
          filtro = filtro + "&" + this.prefixo + "renovacoesDtFimFinal=" + this.filtrosSelecionados.renovacoes.dtFim_final;
        }

        if (this.filtrosSelecionados.renovacoes.exemplaresInicio) {
          filtro = filtro + "&" + this.prefixo + "renovacoesExemplaresInicio=" + this.filtrosSelecionados.renovacoes.exemplaresInicio;
        }
        if (this.filtrosSelecionados.renovacoes.exemplaresFim) {
          filtro = filtro + "&" + this.prefixo + "renovacoesExemplaresFim=" + this.filtrosSelecionados.renovacoes.exemplaresFim;
        }

        if (this.filtrosSelecionados.renovacoes.ativosInicio) {
          filtro = filtro + "&" + this.prefixo + "renovacoesAtivosInicio=" + this.filtrosSelecionados.renovacoes.ativosInicio;
        }
        if (this.filtrosSelecionados.renovacoes.ativosFim) {
          filtro = filtro + "&" + this.prefixo + "renovacoesAtivosFim=" + this.filtrosSelecionados.renovacoes.ativosFim;
        }

        if (this.filtrosSelecionados.renovacoes.entreguesInicio) {
          filtro = filtro + "&" + this.prefixo + "renovacoesEntreguesInicio=" + this.filtrosSelecionados.renovacoes.entreguesInicio;
        }
        if (this.filtrosSelecionados.renovacoes.entreguesFim) {
          filtro = filtro + "&" + this.prefixo + "renovacoesEntreguesFim=" + this.filtrosSelecionados.renovacoes.entreguesFim;
        }

        if (this.filtrosSelecionados.renovacoes.idUsuario && this.filtrosSelecionados.renovacoes.idUsuario.length) {
          filtro = filtro + "&" + this.prefixo + "renovacoesIdUsuario=" + this.filtrosSelecionados.renovacoes.idUsuario.join(",");
        }

        if (this.filtrosSelecionados.renovacoes.modalidade && this.filtrosSelecionados.renovacoes.modalidade.length) {
          filtro = filtro + "&" + this.prefixo + "renovacoesModalidade=" + this.filtrosSelecionados.renovacoes.modalidade.join(",");
        }

        if (this.filtrosSelecionados.renovacoes.tipoProduto && this.filtrosSelecionados.renovacoes.tipoProduto.length) {
          filtro = filtro + "&" + this.prefixo + "renovacoesTipoProduto=" + this.filtrosSelecionados.renovacoes.tipoProduto.join(",");
        }

        if (this.filtrosSelecionados.renovacoes.tipoProdutoOmitir && this.filtrosSelecionados.renovacoes.tipoProdutoOmitir.length) {
          filtro = filtro + "&" + this.prefixo + "renovacoesTipoProdutoOmitir=" + this.filtrosSelecionados.renovacoes.tipoProdutoOmitir.join(",");
        }

        if (this.filtrosSelecionados.renovacoes.autorizacao) {
          filtro = filtro + "&" + this.prefixo + "renovacoesAutorizacao=" + this.filtrosSelecionados.renovacoes.autorizacao;
        }

        if (this.filtrosSelecionados.renovacoes.automatica != null) {
          filtro = filtro + "&" + this.prefixo + "renovacoesAutomatica=" + this.filtrosSelecionados.renovacoes.automatica;
        }
        if (this.filtrosSelecionados.renovacoes.reinicio != null) {
          filtro = filtro + "&" + this.prefixo + "renovacoesReinicio=" + this.filtrosSelecionados.renovacoes.reinicio;
        }
        if (this.filtrosSelecionados.renovacoes.online != null) {
          filtro = filtro + "&" + this.prefixo + "renovacoesOnline=" + this.filtrosSelecionados.renovacoes.online;
        }
        if (this.filtrosSelecionados.renovacoes.vip != null) {
          filtro = filtro + "&" + this.prefixo + "renovacoesVIP=" + this.filtrosSelecionados.renovacoes.vip;
        }

        if (this.filtrosSelecionados.renovacoes.idPlano && this.filtrosSelecionados.renovacoes.idPlano.length) {
          filtro = filtro + "&" + this.prefixo + "renovacoesIdPlano=" + this.filtrosSelecionados.renovacoes.idPlano.join(",");
        }

        if (this.filtrosSelecionados.renovacoes.idProduto && this.filtrosSelecionados.renovacoes.idProduto.length) {
          filtro = filtro + "&" + this.prefixo + "renovacoesIdProduto=" + this.filtrosSelecionados.renovacoes.idProduto.join(",");
        }

        if (this.filtrosSelecionados.renovacoes.valorInicio != 0) {
          filtro = filtro + "&" + this.prefixo + "renovacoesValorInicio=" + this.filtrosSelecionados.renovacoes.valorInicio;
        }
        if (this.filtrosSelecionados.renovacoes.valorFim != 0) {
          filtro = filtro + "&" + this.prefixo + "renovacoesValorFim=" + this.filtrosSelecionados.renovacoes.valorFim;
        }
        if (this.filtrosSelecionados.renovacoes.codAntigo) {
          filtro = filtro + "&" + this.prefixo + "renovacoesCodAntigo=" + this.filtrosSelecionados.renovacoes.codAntigo;
        }
        if (this.filtrosSelecionados.renovacoes.idProtheus) {
          filtro = filtro + "&" + this.prefixo + "renovacoesIdProtheus=" + this.filtrosSelecionados.renovacoes.idProtheus;
        }
      }

      //* Valida os agentes
      if (this.filtrosSelecionados.agentes && this.filtrosSelecionados.agentes.idAgente && this.filtrosSelecionados.agentes.idAgente.length) {
        filtro = filtro + "&" + this.prefixo + "agentesIdAgente=" + this.filtrosSelecionados.agentes.idAgente.join(",");
      }

      //* Valida as observações
      if (this.filtrosSelecionados.observacoes) {
        if (this.filtrosSelecionados.observacoes.dtCriacaoInicio) {
          filtro = filtro + "&" + this.prefixo + "observacoesDtCriacaoInicio=" + this.filtrosSelecionados.observacoes.dtCriacaoInicio;
        }

        if (this.filtrosSelecionados.observacoes.dtCriacaoFim) {
          filtro = filtro + "&" + this.prefixo + "observacoesDtCriacaoFim=" + this.filtrosSelecionados.observacoes.dtCriacaoFim;
        }

        if (this.filtrosSelecionados.observacoes.idUsuario && this.filtrosSelecionados.observacoes.idUsuario.length) {
          filtro = filtro + "&" + this.prefixo + "observacoesIdUsuario=" + this.filtrosSelecionados.observacoes.idUsuario.join(",");
        }

        if (this.filtrosSelecionados.observacoes.observacao) {
          filtro = filtro + "&" + this.prefixo + "observacoesObservacao=" + this.filtrosSelecionados.observacoes.observacao;
        }
      }

      //* Valida as reclamações
      if (this.filtrosSelecionados.reclamacoes) {
        if (this.filtrosSelecionados.reclamacoes.status) {
          filtro = filtro + "&" + this.prefixo + "reclamacoesStatus=" + this.filtrosSelecionados.reclamacoes.status;
        }

        if (this.filtrosSelecionados.reclamacoes.dtRegistroInicio) {
          filtro = filtro + "&" + this.prefixo + "reclamacoesDtRegistroInicio=" + this.filtrosSelecionados.reclamacoes.dtRegistroInicio;
        }

        if (this.filtrosSelecionados.reclamacoes.dtRegistroFim) {
          filtro = filtro + "&" + this.prefixo + "reclamacoesDtRegistroFim=" + this.filtrosSelecionados.reclamacoes.dtRegistroFim;
        }

        if (this.filtrosSelecionados.reclamacoes.idUsuario && this.filtrosSelecionados.reclamacoes.idUsuario.length) {
          filtro = filtro + "&" + this.prefixo + "reclamacoesIdUsuario=" + this.filtrosSelecionados.reclamacoes.idUsuario.join(",");
        }

        if (this.filtrosSelecionados.reclamacoes.idReclamacao && this.filtrosSelecionados.reclamacoes.idReclamacao.length) {
          filtro = filtro + "&" + this.prefixo + "reclamacoesIdReclamacao=" + this.filtrosSelecionados.reclamacoes.idReclamacao.join(",");
        }

        if (this.filtrosSelecionados.reclamacoes.reclamacao) {
          filtro = filtro + "&" + this.prefixo + "reclamacoesReclamacao=" + this.filtrosSelecionados.reclamacoes.reclamacao;
        }

        if (this.filtrosSelecionados.reclamacoes.idUsuarioExecucao && this.filtrosSelecionados.reclamacoes.idUsuarioExecucao.length) {
          filtro = filtro + "&" + this.prefixo + "reclamacoesIdUsuarioExecucao=" + this.filtrosSelecionados.reclamacoes.idUsuarioExecucao.join(",");
        }

        if (this.filtrosSelecionados.reclamacoes.dtExecucaoInicio) {
          filtro = filtro + "&" + this.prefixo + "reclamacoesDtExecucaoInicio=" + this.filtrosSelecionados.reclamacoes.dtExecucaoInicio;
        }

        if (this.filtrosSelecionados.reclamacoes.dtExecucaoFim) {
          filtro = filtro + "&" + this.prefixo + "reclamacoesDtExecucaoFim=" + this.filtrosSelecionados.reclamacoes.dtExecucaoFim;
        }

        if (this.filtrosSelecionados.reclamacoes.execucao) {
          filtro = filtro + "&" + this.prefixo + "reclamacoesExecucao=" + this.filtrosSelecionados.reclamacoes.execucao;
        }

        if (this.filtrosSelecionados.reclamacoes.idUsuarioSolucao && this.filtrosSelecionados.reclamacoes.idUsuarioSolucao.length) {
          filtro = filtro + "&" + this.prefixo + "reclamacoesIdUsuarioSolucao=" + this.filtrosSelecionados.reclamacoes.idUsuarioSolucao.join(",");
        }

        if (this.filtrosSelecionados.reclamacoes.dtSolucaoInicio) {
          filtro = filtro + "&" + this.prefixo + "reclamacoesDtSolucaoInicio=" + this.filtrosSelecionados.reclamacoes.dtSolucaoInicio;
        }

        if (this.filtrosSelecionados.reclamacoes.dtSolucaoFim) {
          filtro = filtro + "&" + this.prefixo + "reclamacoesDtSolucaoFim=" + this.filtrosSelecionados.reclamacoes.dtSolucaoFim;
        }

        if (this.filtrosSelecionados.reclamacoes.solucao) {
          filtro = filtro + "&" + this.prefixo + "reclamacoesSolucao=" + this.filtrosSelecionados.reclamacoes.solucao;
        }
      }

      //* Valida cartoesClube - reinserir após corrigir filtro
      // if (
      //   this.filtrosSelecionados.cartoesClube.idCliente
      // ) {
      //   filtro =
      //     filtro +
      //     "&" +
      //     this.prefixo +
      //     "cartoesClubeIdCliente=" +
      //     this.filtrosSelecionados.cartoesClube.idCliente;
      // }

      // if (
      //   this.filtrosSelecionados.cartoesClube.idDependente
      // ) {
      //   filtro =
      //     filtro +
      //     "&" +
      //     this.prefixo +
      //     "cartoesClubeIdDependente=" +
      //     this.filtrosSelecionados.cartoesClube.idDependente;
      // }

      //* Valida suspensões
      if (this.filtrosSelecionados.suspensoes) {
        if (this.filtrosSelecionados.suspensoes.dtRegistroInicio) {
          filtro = filtro + "&" + this.prefixo + "suspensoesDtRegistroInicio=" + this.filtrosSelecionados.suspensoes.dtRegistroInicio;
        }
        if (this.filtrosSelecionados.suspensoes.dtRegistroFim) {
          filtro = filtro + "&" + this.prefixo + "suspensoesDtRegistroFim=" + this.filtrosSelecionados.suspensoes.dtRegistroFim;
        }
        if (this.filtrosSelecionados.suspensoes.dtInicio) {
          filtro = filtro + "&" + this.prefixo + "suspensoesDtInicio=" + this.filtrosSelecionados.suspensoes.dtInicio;
        }
        if (this.filtrosSelecionados.suspensoes.dtFim) {
          filtro = filtro + "&" + this.prefixo + "suspensoesDtFim=" + this.filtrosSelecionados.suspensoes.dtFim;
        }
        if (this.filtrosSelecionados.suspensoes.idUsuario && this.filtrosSelecionados.suspensoes.idUsuario.length) {
          filtro = filtro + "&" + this.prefixo + "suspensoesIdUsuario=" + this.filtrosSelecionados.suspensoes.idUsuario.join(",");
        }
        if (this.filtrosSelecionados.suspensoes.idMotivoSuspensao && this.filtrosSelecionados.suspensoes.idMotivoSuspensao.length) {
          filtro = filtro + "&" + this.prefixo + "suspensoesIdMotivoSuspensao=" + this.filtrosSelecionados.suspensoes.idMotivoSuspensao.join(",");
        }
        if (this.filtrosSelecionados.suspensoes.motivo) {
          filtro = filtro + "&" + this.prefixo + "suspensoesMotivo=" + this.filtrosSelecionados.suspensoes.motivo;
        }
      }

      //* Valida regras ajustes
      if (this.filtrosSelecionados.regrasAjuste) {
        if (this.filtrosSelecionados.regrasAjuste.dtRegistroInicio) {
          filtro = filtro + "&" + this.prefixo + "regrasAjusteDtRegistroInicio=" + this.filtrosSelecionados.regrasAjuste.dtRegistroInicio;
        }

        if (this.filtrosSelecionados.regrasAjuste.dtRegistroFim) {
          filtro = filtro + "&" + this.prefixo + "regrasAjusteDtRegistroFim=" + this.filtrosSelecionados.regrasAjuste.dtRegistroFim;
        }

        if (this.filtrosSelecionados.regrasAjuste.idRegraAjuste && this.filtrosSelecionados.regrasAjuste.idRegraAjuste.length) {
          filtro = filtro + "&" + this.prefixo + "regrasAjusteIdRegraAjuste=" + this.filtrosSelecionados.regrasAjuste.idRegraAjuste.join(",");
        }

        if (this.filtrosSelecionados.regrasAjuste.motivo) {
          filtro = filtro + "&" + this.prefixo + "regrasAjusteMotivo=" + this.filtrosSelecionados.regrasAjuste.motivo;
        }

        if (this.filtrosSelecionados.regrasAjuste.idUsuario && this.filtrosSelecionados.regrasAjuste.idUsuario.length) {
          filtro = filtro + "&" + this.prefixo + "regrasAjusteIdUsuario=" + this.filtrosSelecionados.regrasAjuste.idUsuario.join(",");
        }
      }

      //* Valida promoções
      if (this.filtrosSelecionados.promocoes && this.filtrosSelecionados.promocoes.idPromocao && this.filtrosSelecionados.promocoes.idPromocao.length) {
        filtro = filtro + "&" + this.prefixo + "promocoesIdPromocao=" + this.filtrosSelecionados.promocoes.idPromocao.join(",");
      }

      if (this.filtrosSelecionados.cortesias && this.filtrosSelecionados.cortesias.idCliente && this.filtrosSelecionados.cortesias.idCliente.length) {
        filtro = filtro + "&" + this.prefixo + "cortesiasIdCliente=" + this.filtrosSelecionados.cortesias.idCliente.join(",");
      }

      //* Valida cancelamentos
      if (this.filtrosSelecionados.cancelamentos) {
        if (this.filtrosSelecionados.cancelamentos.dtRegistroInicio) {
          filtro = filtro + "&" + this.prefixo + "cancelamentosDtRegistroInicio=" + this.filtrosSelecionados.cancelamentos.dtRegistroInicio;
        }

        if (this.filtrosSelecionados.cancelamentos.dtRegistroFim) {
          filtro = filtro + "&" + this.prefixo + "cancelamentosDtRegistroFim=" + this.filtrosSelecionados.cancelamentos.dtRegistroFim;
        }

        if (this.filtrosSelecionados.cancelamentos.dtAgendamentoInicio) {
          filtro = filtro + "&" + this.prefixo + "cancelamentosDtAgendamentoInicio=" + this.filtrosSelecionados.cancelamentos.dtAgendamentoInicio;
        }

        if (this.filtrosSelecionados.cancelamentos.dtAgendamentoFim) {
          filtro = filtro + "&" + this.prefixo + "cancelamentosDtAgendamentoFim=" + this.filtrosSelecionados.cancelamentos.dtAgendamentoFim;
        }

        if (this.filtrosSelecionados.cancelamentos.idUsuarioCancelamento && this.filtrosSelecionados.cancelamentos.idUsuarioCancelamento.length) {
          filtro = filtro + "&" + this.prefixo + "cancelamentosIdUsuarioCancelamento=" + this.filtrosSelecionados.cancelamentos.idUsuarioCancelamento.join(",");
        }

        if (this.filtrosSelecionados.cancelamentos.idMotivoCancelamento && this.filtrosSelecionados.cancelamentos.idMotivoCancelamento.length) {
          filtro = filtro + "&" + this.prefixo + "cancelamentosIdMotivoCancelamento=" + this.filtrosSelecionados.cancelamentos.idMotivoCancelamento.join(",");
        }

        if (this.filtrosSelecionados.cancelamentos.motivoCancelamento) {
          filtro = filtro + "&" + this.prefixo + "cancelamentosMotivoCancelamento=" + this.filtrosSelecionados.cancelamentos.motivoCancelamento;
        }

        if (this.filtrosSelecionados.cancelamentos.dtCancelamentoInicio) {
          filtro = filtro + "&" + this.prefixo + "cancelamentosDtCancelamentoInicio=" + this.filtrosSelecionados.cancelamentos.dtCancelamentoInicio;
        }

        if (this.filtrosSelecionados.cancelamentos.dtCancelamentoFim) {
          filtro = filtro + "&" + this.prefixo + "cancelamentosDtCancelamentoFim=" + this.filtrosSelecionados.cancelamentos.dtCancelamentoFim;
        }

        if (this.filtrosSelecionados.cancelamentos.dtRecuperacaoInicio) {
          filtro = filtro + "&" + this.prefixo + "cancelamentosDtRecuperacaoInicio=" + this.filtrosSelecionados.cancelamentos.dtRecuperacaoInicio;
        }

        if (this.filtrosSelecionados.cancelamentos.dtRecuperacaoFim) {
          filtro = filtro + "&" + this.prefixo + "cancelamentosDtRecuperacaoFim=" + this.filtrosSelecionados.cancelamentos.dtRecuperacaoFim;
        }

        if (this.filtrosSelecionados.cancelamentos.idRecuperacao) {
          filtro = filtro + "&" + this.prefixo + "cancelamentosIdRecuperacao=" + this.filtrosSelecionados.cancelamentos.idRecuperacao;
        }

        if (this.filtrosSelecionados.cancelamentos.motivoRecuperacao) {
          filtro = filtro + "&" + this.prefixo + "cancelamentosMotivoRecuperacao=" + this.filtrosSelecionados.cancelamentos.motivoRecuperacao;
        }

        if (this.filtrosSelecionados.cancelamentos.dtReversaoInicio) {
          filtro = filtro + "&" + this.prefixo + "cancelamentosDtReversaoInicio=" + this.filtrosSelecionados.cancelamentos.dtReversaoInicio;
        }

        if (this.filtrosSelecionados.cancelamentos.dtReversaoFim) {
          filtro = filtro + "&" + this.prefixo + "cancelamentosDtReversaoFim=" + this.filtrosSelecionados.cancelamentos.dtReversaoFim;
        }

        if (this.filtrosSelecionados.cancelamentos.idUsuarioRecuperacao && this.filtrosSelecionados.cancelamentos.idUsuarioRecuperacao.length) {
          filtro = filtro + "&" + this.prefixo + "cancelamentosIdUsuarioRecuperacao=" + this.filtrosSelecionados.cancelamentos.idUsuarioRecuperacao.join(",");
        }

        if (this.filtrosSelecionados.cancelamentos.idUsuarioReversao && this.filtrosSelecionados.cancelamentos.idUsuarioReversao.length) {
          filtro = filtro + "&" + this.prefixo + "cancelamentosIdUsuarioReversao=" + this.filtrosSelecionados.cancelamentos.idUsuarioReversao.join(",");
        }

        if (this.filtrosSelecionados.cancelamentos.motivoReversao) {
          filtro = filtro + "&" + this.prefixo + "cancelamentosMotivoReversao=" + this.filtrosSelecionados.cancelamentos.motivoReversao;
        }
      }

      //* Valida o roteiro
      if (this.filtrosSelecionados.roteiro) {
        if (this.filtrosSelecionados.roteiro.idRota && this.filtrosSelecionados.roteiro.idRota.length) {
          filtro = filtro + "&" + this.prefixo + "roteiroIdRota=" + this.filtrosSelecionados.roteiro.idRota.join(",");
        }
        if (this.filtrosSelecionados.roteiro.idEntregador && this.filtrosSelecionados.roteiro.idEntregador.length) {
          filtro = filtro + "&" + this.prefixo + "roteiroIdEntregador=" + this.filtrosSelecionados.roteiro.idEntregador.join(",");
        }
      }

      if (filtro) {
        filtro = filtro + "&" + this.prefixo + "idSite=" + this.idSitePrincipal;
      }

      return filtro;
    },

    carregarOrigensVenda() {
      api.get(process.env.VUE_APP_URL_WEB_API + "ass/origem/lista?idSite=" + this.idSitePrincipal).then(response => {
        this.origensVenda = response.data;
      });
    },
    carregarFormasPgto() {
      api.get(process.env.VUE_APP_URL_WEB_API + "adm/forma-pagamento/lista?idSite=" + this.idSitePrincipal).then(response => {
        this.formasPgto = response.data;
      });
    },
    async carregarReclamacoesPadrao() {
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/reclamacao-padrao/lista?idSite=" + this.idSitePrincipal);
      if (response.data) {
        this.reclamacoesPadrao = response.data;
      }
    },
    carregarUsuarios() {
      api.get(process.env.VUE_APP_URL_WEB_API + "adm/site-usuario/usuariosSite/" + this.idSitePrincipal).then(response => {
        this.usuarios = response.data;
      });
    },
    carregarPlanos() {
      api.get(process.env.VUE_APP_URL_WEB_API + "ass/plano/lista?idSite=" + this.idSitePrincipal).then(response => {
        this.planos = response.data;
      });
    },
    carregarProdutos() {
      api.get(process.env.VUE_APP_URL_WEB_API + "ass/produto/all?idSite=" + this.idSitePrincipal).then(response => {
        this.produtos = response.data;
      });
    },
    carregarPromocoes() {
      api.get(process.env.VUE_APP_URL_WEB_API + "ass/promocao/all?idSite=" + this.idSitePrincipal).then(response => {
        this.promocoes = response.data;
      });
    },
    carregarCortesias() {
      api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-cortesia/clientes?idSite=" + this.idSitePrincipal).then(response => {
        this.cortesias = response.data;
      });
    },
    carregarModalidades() {
      api.get(process.env.VUE_APP_URL_WEB_API + "ass/modalidade/all?idSite=" + this.idSitePrincipal).then(response => {
        this.modalidades = response.data;
      });
    },
    carregarAgentes() {
      api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-agente/all?idSite=" + this.idSitePrincipal).then(response => {
        this.agentes = response.data;
      });
    },
    carregarRotas() {
      api.get(process.env.VUE_APP_URL_WEB_API + "ass/rota/all?idSite=" + this.idSitePrincipal).then(response => {
        this.rotas = response.data;
      });
    },
    carregarEntregadores() {
      api.get(process.env.VUE_APP_URL_WEB_API + "ass/entregador/all?idSite=" + this.idSitePrincipal).then(response => {
        this.entregadores = response.data;
      });
    },
    rotasSigla() {
      if (this.filtrosSelecionados.roteiro.sigla) {
        this.filtrosSelecionados.roteiro.sigla = this.filtrosSelecionados.roteiro.sigla.toUpperCase();
        if (!this.filtrosSelecionados.roteiro.idRota) {
          this.filtrosSelecionados.roteiro.idRota = [];
        }
        this.rotas.forEach(element => {
          if (element.sigla && element.sigla.startsWith(this.filtrosSelecionados.roteiro.sigla)) {
            this.filtrosSelecionados.roteiro.idRota.push(element.id);
          }
        });
        this.filtrosSelecionados.roteiro.sigla = "";
      }
    },

    limpar() {
      this.filtrosSelecionados = JSON.parse(JSON.stringify(this.assinaturaDefault));
      this.painel = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      this.renovacoesDtInicioInicialUS = null;
      this.renovacoesDtInicioFinalUS = null;
      this.renovacoesDtRenovacaoInicialUS = null;
      this.renovacoesDtRenovacaoFinalUS = null;
      this.filtroSelRenovacoesInicioInicialUS = null;
      this.filtroSelRenovacoesInicioFinalUS = null;
      this.filtroSelObservacoesInicioUS = null;
      this.filtroSelObservacoesFimUS = null;
      this.filtroSelReclamacoesInicioUS = null;
      this.filtroSelReclamacoesFimUS = null;
      this.filtroSelReclamacoesDtExecucaoInicioUS = null;
      this.filtroSelReclamacoesDtExecucaoFimUS = null;
      this.filtroSelReclamacoesDtSolucaoInicioUS = null;
      this.filtroSelReclamacoesDtSolucaoFimUS = null;
      this.filtroSelSuspensoesDtRegistroInicioUS = null;
      this.filtroSelSuspensoesDtRegistroFimUS = null;
      this.filtroSelSuspensoesDtInicioUS = null;
      this.filtroSelSuspensoesDtFimUS = null;
      this.filtroSelRegAjusDtRegistroInicioUS = null;
      this.filtroSelRegAjusDtRegistroFimUS = null;
      this.filtroSelCancDtRegistroInicioUS = null;
      this.filtroSelCancDtRegistroFimUS = null;
      this.filtroSelCancDtAgendamentoInicioUS = null;
      this.filtroSelCancDtAgendamentoFimUS = null;
      this.filtroSelCancDtCancelamentoInicioUS = null;
      this.filtroSelCancDtCancelamentoFimUS = null;
      this.filtroSelCanDtRecuperacaoInicioUS = null;
      this.filtroSelCanDtRecuperacaoFimUS = null;
      this.filtroSelCanDtReversaoInicioUS = null;
      this.filtroSelCanDtReversaoFimUS = null;
    },
    async carregarMotivosCancelamentoPadrao() {
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/motivo-cancelamento/lista?idSite=" + this.idSitePrincipal);
      if (response.data) {
        this.motivosCancelamento = response.data;
      }
    },
    async carregarMotivosSuspensaoPadrao() {
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/motivo-suspensao/lista?idSite=" + this.idSitePrincipal);
      if (response.data) {
        this.motivosSuspensao = response.data;
      }
    },
    async carregarRegrasAjuste() {
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/regra-ajuste/all?idSite=" + this.idSitePrincipal);
      if (response.data) {
        this.regrasAjuste = response.data;
      }
    },
    formatDateUS(date) {
      if (date && date.length == 10) {
        return util.formatDateUS(date);
      }
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    filtrarItens(item, queryText, itemText) {
      return util.filtrarItens(item, queryText, itemText);
    },
    filtrarUFs(item, queryText, itemText) {
      return util.filtrarUFs(item, queryText, itemText);
    },

    formatDate(date) {
      return util.formatDate(date);
    },
    formatDateTime(datetime) {
      return util.formatDateTime(datetime);
    },
    valida_data(date) {
      if (date) {
        return util.valida_data_BR(date);
      } else {
        return true;
      }
    },
    valida_data_futura(date) {
      if (date && this.cancelamentos.id == null) {
        return util.valida_data_futura_BR(date);
      } else {
        return true;
      }
    }
  },

  watch: {
    renovacoesDtInicioInicialUS() {
      this.filtrosSelecionados.dtCriacaoInicio = util.formatDate(this.renovacoesDtInicioInicialUS);
      this.mnRenovacoesInicio = false;
    },
    renovacoesDtInicioFinalUS() {
      this.filtrosSelecionados.dtCriacaoFim = util.formatDate(this.renovacoesDtInicioFinalUS);
      this.mnRenovacoesFim = false;
    },
    renovacoesDtRenovacaoInicialUS() {
      this.filtrosSelecionados.renovacoes.dtRenovacaoInicio = util.formatDate(this.renovacoesDtRenovacaoInicialUS);
      this.mnRenovacaoInicio = false;
    },
    renovacoesDtRenovacaoFinalUS() {
      this.filtrosSelecionados.renovacoes.dtRenovacaoFim = util.formatDate(this.renovacoesDtRenovacaoFinalUS);
      this.mnRenovacaoFim = false;
    },

    filtroSelRenovacoesInicioInicialUS() {
      this.filtrosSelecionados.renovacoes.dtInicio_inicial = util.formatDate(this.filtroSelRenovacoesInicioInicialUS);
      this.mnfiltroSelRenovacoesInicioInicialUS = false;
    },
    filtroSelRenovacoesInicioFinalUS() {
      this.filtrosSelecionados.renovacoes.dtInicio_final = util.formatDate(this.filtroSelRenovacoesInicioFinalUS);

      this.mnfiltroSelRenovacoesInicioFinalUS = false;
    },

    filtroSelRenovacoesFimInicialUS() {
      this.filtrosSelecionados.renovacoes.dtFim_inicial = util.formatDate(this.filtroSelRenovacoesFimInicialUS);
      this.mnfiltroSelRenovacoesFimInicialUS = false;
    },
    filtroSelRenovacoesFimFinalUS() {
      this.filtrosSelecionados.renovacoes.dtFim_final = util.formatDate(this.filtroSelRenovacoesFimFinalUS);

      this.mnfiltroSelRenovacoesFimFinalUS = false;
    },

    filtroSelObservacoesInicioUS() {
      this.filtrosSelecionados.observacoes.dtCriacaoInicio = util.formatDate(this.filtroSelObservacoesInicioUS);
      this.mnfiltroSelObservacoesInicioUS = false;
    },
    filtroSelObservacoesFimUS() {
      this.filtrosSelecionados.observacoes.dtCriacaoFim = util.formatDate(this.filtroSelObservacoesFimUS);
      this.mnfiltroSelObservacoesFimUS = false;
    },

    filtroSelReclamacoesInicioUS() {
      this.filtrosSelecionados.reclamacoes.dtRegistroInicio = util.formatDate(this.filtroSelReclamacoesInicioUS);
      this.mnfiltroSelReclamacoesInicioUS = false;
    },
    filtroSelReclamacoesFimUS() {
      this.filtrosSelecionados.reclamacoes.dtRegistroFim = util.formatDate(this.filtroSelReclamacoesFimUS);
      this.mnfiltroSelReclamacoesFimUS = false;
    },

    filtroSelReclamacoesDtExecucaoInicioUS() {
      this.filtrosSelecionados.reclamacoes.dtExecucaoInicio = util.formatDate(this.filtroSelReclamacoesDtExecucaoInicioUS);
      this.mnfiltroSelReclamacoesDtExecucaoInicioUS = false;
    },
    filtroSelReclamacoesDtExecucaoFimUS() {
      this.filtrosSelecionados.reclamacoes.dtExecucaoFim = util.formatDate(this.filtroSelReclamacoesDtExecucaoFimUS);
      this.mnfiltroSelReclamacoesDtExecucaoFimUS = false;
    },

    filtroSelReclamacoesDtSolucaoInicioUS() {
      this.filtrosSelecionados.reclamacoes.dtSolucaoInicio = util.formatDate(this.filtroSelReclamacoesDtSolucaoInicioUS);
      this.mnfiltroSelReclamacoesDtSolucaoInicioUS = false;
    },
    filtroSelReclamacoesDtSolucaoFimUS() {
      this.filtrosSelecionados.reclamacoes.dtSolucaoFim = util.formatDate(this.filtroSelReclamacoesDtSolucaoFimUS);
      this.mnfiltroSelReclamacoesDtSolucaoFimUS = false;
    },

    filtroSelSuspensoesDtRegistroInicioUS() {
      this.filtrosSelecionados.suspensoes.dtRegistroInicio = util.formatDate(this.filtroSelSuspensoesDtRegistroInicioUS);
      this.mnfiltroSelSuspensoesDtRegistroInicioUS = false;
    },
    filtroSelSuspensoesDtRegistroFimUS() {
      this.filtrosSelecionados.suspensoes.dtRegistroFim = util.formatDate(this.filtroSelSuspensoesDtRegistroFimUS);
      this.mnfiltroSelSuspensoesDtRegistroFimUS = false;
    },
    filtroSelSuspensoesDtInicioUS() {
      this.filtrosSelecionados.suspensoes.dtInicio = util.formatDate(this.filtroSelSuspensoesDtInicioUS);
      this.mnfiltroSelSuspensoesDtInicioUS = false;
    },
    filtroSelSuspensoesDtFimUS() {
      this.filtrosSelecionados.suspensoes.dtFim = util.formatDate(this.filtroSelSuspensoesDtFimUS);
      this.mnfiltroSelSuspensoesDtFimUS = false;
    },
    filtroSelRegAjusDtRegistroInicioUS() {
      this.filtrosSelecionados.regrasAjuste.dtRegistroInicio = util.formatDate(this.filtroSelRegAjusDtRegistroInicioUS);
      this.mnfiltroSelRegAjusDtRegistroInicioUS = false;
    },
    filtroSelRegAjusDtRegistroFimUS() {
      this.filtrosSelecionados.regrasAjuste.dtRegistroFim = util.formatDate(this.filtroSelRegAjusDtRegistroFimUS);
      this.mnfiltroSelRegAjusDtRegistroFimUS = false;
    },
    filtroSelCancDtRegistroInicioUS() {
      this.filtrosSelecionados.cancelamentos.dtRegistroInicio = util.formatDate(this.filtroSelCancDtRegistroInicioUS);
      this.mnfiltroSelCancDtRegistroInicioUS = false;
    },
    filtroSelCancDtRegistroFimUS() {
      this.filtrosSelecionados.cancelamentos.dtRegistroFim = util.formatDate(this.filtroSelCancDtRegistroFimUS);
      this.mnfiltroSelCancDtRegistroFimUS = false;
    },
    filtroSelCancDtAgendamentoInicioUS() {
      this.filtrosSelecionados.cancelamentos.dtAgendamentoInicio = util.formatDate(this.filtroSelCancDtAgendamentoInicioUS);
      this.mnfiltroSelCancDtAgendamentoInicioUS = false;
    },
    filtroSelCancDtAgendamentoFimUS() {
      this.filtrosSelecionados.cancelamentos.dtAgendamentoFim = util.formatDate(this.filtroSelCancDtAgendamentoFimUS);
      this.mnfiltroSelCancDtAgendamentoFimUS = false;
    },
    filtroSelCancDtCancelamentoInicioUS() {
      this.filtrosSelecionados.cancelamentos.dtCancelamentoInicio = util.formatDate(this.filtroSelCancDtCancelamentoInicioUS);
      this.mnfiltroSelCancDtCancelamentoInicioUS = false;
    },
    filtroSelCancDtCancelamentoFimUS() {
      this.filtrosSelecionados.cancelamentos.dtCancelamentoFim = util.formatDate(this.filtroSelCancDtCancelamentoFimUS);
      this.mnfiltroSelCancDtCancelamentoFimUS = false;
    },
    filtroSelCanDtRecuperacaoInicioUS() {
      this.filtrosSelecionados.cancelamentos.dtRecuperacaoInicio = util.formatDate(this.filtroSelCanDtRecuperacaoInicioUS);
      this.mnfiltroSelCanDtRecuperacaoInicioUS = false;
    },
    filtroSelCanDtRecuperacaoFimUS() {
      this.filtrosSelecionados.cancelamentos.dtRecuperacaoFim = util.formatDate(this.filtroSelCanDtRecuperacaoFimUS);
      this.mnfiltroSelCanDtRecuperacaoFimUS = false;
    },
    filtroSelCanDtReversaoInicioUS() {
      this.filtrosSelecionados.cancelamentos.dtReversaoInicio = util.formatDate(this.filtroSelCanDtReversaoInicioUS);
      this.mnfiltroSelCanDtReversaoInicioUS = false;
    },
    filtroSelCanDtReversaoFimUS() {
      this.filtrosSelecionados.cancelamentos.dtReversaoFim = util.formatDate(this.filtroSelCanDtReversaoFimUS);
      this.mnfiltroSelCanDtReversaoFimUS = false;
    }
  },

  async created() {
    try {
      this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

      //* Carrega os itens dos selects
      this.carregarOrigensVenda();
      this.carregarFormasPgto();
      this.carregarUsuarios();
      this.carregarPlanos();
      this.carregarProdutos();
      this.carregarPromocoes();
      this.carregarCortesias();
      this.carregarModalidades();
      this.carregarAgentes();
      this.carregarRegrasAjuste();
      this.carregarReclamacoesPadrao();
      this.carregarMotivosSuspensaoPadrao();
      this.carregarMotivosCancelamentoPadrao();
      this.carregarRotas();
      this.carregarEntregadores();
    } catch {
      // this.mensagem = "Ocorreu um erro ao carregar os dados";
      // this.snackErro = true;
    }
  }
};
</script>
