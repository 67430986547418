<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">mdi-account</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light"> Cobrança {{ gateway }} </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="retornar(false)">
        <v-icon color="white">cancel</v-icon>
      </v-btn>
    </v-toolbar>

    <v-container fluid grid-list-md>
      <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs6>
                <v-text-field v-model="cobranca.nome" label="Titular" readonly color="blue-grey lighten-2"></v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field v-model="cobranca.identificacao" label="Identificação" readonly color="blue-grey lighten-2"></v-text-field>
              </v-flex>
              <v-flex xs4>
                <v-text-field v-model="cobranca.numeroSeg" label="Número" readonly color="blue-grey lighten-2"></v-text-field>
              </v-flex>
              <v-flex xs4>
                <v-text-field v-model="cobranca.validade" label="Validade" readonly color="blue-grey lighten-2"></v-text-field>
              </v-flex>
              <v-flex xs4>
                <v-text-field v-model="cobranca.valorF" label="Valor" readonly color="blue-grey lighten-2"></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  v-model="cobranca.parcelas"
                  label="Parcelas Afetadas"
                  maxlength="1024"
                  rows="3"
                  :rules="[validacao.parcelas == false || validacao.parcelas]"
                  color="blue-grey lighten-2"
                  @change="validacao.parcelas = false"
                ></v-textarea>
              </v-flex>

              <v-flex offset-xs6 xs4>
                <v-autocomplete
                  v-model="cobranca.nroParcelas"
                  :items="nrosParcelas"
                  hint="Obrigatório"
                  :rules="[validacao.nroParcelas == false || validacao.nroParcelas, value => !!value || 'Obrigatório.']"
                  label="Número de Parcelas"
                  autocomplete="disabled"
                  :name="Math.random()"
                  no-data-text
                  @change="validacao.nroParcelas = false"
                ></v-autocomplete>
              </v-flex>

              <v-flex xs12 v-if="validacao.mensagemErro">
                <v-sheet :value="true" class="red--text">
                  {{ validacao.mensagemErro }}
                </v-sheet>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="orange lighten-2 white" style="color:white" @click="retornar(false)">
            Retornar
          </v-btn>
          <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="blue white" style="color:white" depressed @click="cobrar">Enviar Cobrança</v-btn>
        </v-card-actions>
      </v-form>
      <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
      <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    </v-container>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  props: {
    parcelas: String,
    nroParcelas: { type: Number, default: 0 }
  },

  data() {
    return {
      cobranca: {
        id: null,
        identificacao: null,
        nome: null,
        numeroSeg: null,
        validade: null,
        valor: null,
        valorF: null,
        nroParcelas: 1,
        parcelas: null,
        token: null,
        formaPgto: "CARTAO_CREDITO"
      },

      validacao: {
        id: false,
        identificacao: false,
        nome: false,
        numeroSeg: false,
        validade: false,
        valor: false,
        nroParcelas: false,
        parcelas: false,
        mensagemErro: false
      },

      nrosParcelas: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      gateway: this.$store.state.usuario.site_gateway,

      idSitePrincipal: null,
      elementoValid: true,
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false,
      bloqueiaBotoes: false
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    async cobrar() {
      this.bloqueiaBotoes = true;
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        this.bloqueiaBotoes = false;
        return;
      }

      this.isUpdating = true;
      try {
        //* Envia o valor para validação
        await api.post(
          process.env.VUE_APP_URL_WEB_API +
            "fin/" +
            (this.gateway.includes("MUNDIPAGG") ? "mundipagg" : this.gateway.includes("ASAAS") ? "asaas" : "cielo") +
            "?idSite=" +
            this.idSitePrincipal,
          this.cobranca
        );
        this.mensagem = "Cobrança enviada com sucesso";
        this.snackbar = true;
        setTimeout(() => {
          this.retornar(null);
        }, 1500);
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
        this.bloqueiaBotoes = false;
      }
    },

    retornar(valor) {
      this.$emit("update:dialogCartao", valor);
    },
    async carregarParcelas() {
      await util.limparObjeto(this.validacao, false);
      this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

      this.isUpdating = false;
      this.bloqueiaBotoes = true;
      try {
        if (!this.nroParcelas) {
          this.nrosParcelas = [1];
        } else if (this.nroParcelas > 1) {
          //* No Asaas, o parcelamento deve refletir as parcelas, então todas tem que ter o mesmo valor e só pode ser parcelado pelo número de parcelas selecionadas
          this.nrosParcelas = [1, this.nroParcelas];
        }
        this.cobranca = {
          id: null,
          identificacao: null,
          nome: null,
          numeroSeg: null,
          validade: null,
          valor: null,
          valorF: null,
          nroParcelas: 1,
          parcelas: null,
          token: null,
          formaPgto: "CARTAO_CREDITO"
        };
        //* Busca os dados da parcela conferindo se pode ser cobrada
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/parcela/cartao?parcelas=" + this.parcelas);

        this.cobranca = response.data;
        this.cobranca.numeroSeg = util.escondeCartao(this.cobranca.numero);
        this.cobranca.valorF = util.formatCurrency(this.cobranca.valor);
        //this.cobranca.parcelas = this.parcelas; //parcelas são retornadas na ordem de vencimento (para refletir o parcelamento no Asaas)
        if (util.parseDateUS(this.cobranca.dtVecto) < new Date()) {
          this.cobranca.dtVecto = util.dateToStringUS(new Date());
        }
        if (this.cobranca.payAsaas) {
          //* Se tem payAsaas, cobrança já foi criada e não é parcelamento
          this.nrosParcelas = [1];
        }
        if (this.cobranca.parcAsaas) {
          //* Se tem parcAsaas, cobrança já foi criada como parcelamento
          this.nrosParcelas = [this.cobranca.nroParcelas];
        } else {
          this.cobranca.nroParcelas = 1;
        }

        if (this.cobranca.validade) {
          var dtValidade = new Date();
          dtValidade.setMonth(parseInt(this.cobranca.validade.substring(0, 2)) - 1);
          dtValidade.setFullYear(parseInt(this.cobranca.validade.substring(3)));
          if (dtValidade < new Date()) {
            throw { response: { data: [{ mensagem: "Validade do cartão é inválida" }] } };
          }
        } else {
          throw { response: { data: [{ mensagem: "Validade do cartão é inválida" }] } };
        }
      } catch (e) {
        this.isUpdating = true;
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
      setTimeout(() => {
        this.bloqueiaBotoes = false;
      }, 1000);
    },

    formatDate(date) {
      return util.formatDate(date);
    },
    formatDateTime(datetime) {
      return util.formatDateTime(datetime);
    },
    formatDateUS(date) {
      return util.formatDateUS(date);
    }
  },

  beforeMount() {
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_PARCELAS_RECEBER_COBRAR_CARTAO"]);
  },

  watch: {
    parcelas() {
      if (this.parcelas) {
        this.carregarParcelas();
      }
    }
  },

  created() {
    //* Apesar de já ser carregado nos whatch, se não tiver aqui não irá carregar a primeira vez
    this.carregarParcelas();
  }
};
</script>
