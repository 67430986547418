<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">grid_off</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Motivo de Suspensão</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12 md12 v-if="siteGrupo.length > 0">
            <v-autocomplete
              v-model="motivoSuspensao.idSite"
              :disabled="isUpdating"
              :items="siteGrupo"
              :filter="filtrarObjetos"
              label="Restrito ao Site"
              item-text="nome"
              item-value="id"
              :rules="[validacao.idSite == false || validacao.idSite]"
              no-data-text
              clearable
              @change="validacao.idSite = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md12>
            <v-text-field
              autofocus
              v-model="motivoSuspensao.nome"
              label="Nome do Motivo de Suspensão"
              maxlength="50"
              hint="Obrigatório"
              :rules="[validacao.nome == false || validacao.nome, value => !!value || 'Obrigatório.', value => value.length > 2 || 'Preencha o nome do motivo de suspensão']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="
                motivoSuspensao.nome = pLetra(motivoSuspensao.nome);
                validacao.nome = false;
              "
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md4>
            <v-switch v-model="motivoSuspensao.bonificar" :label="`${motivoSuspensao.bonificar == 1 ? 'Bonificar Período' : 'Não bonificar período'}`"></v-switch>
          </v-flex>
          <v-flex xs12 md4>
            <v-switch v-model="motivoSuspensao.digital" :label="`${motivoSuspensao.digital == 1 ? 'Bloquear Acesso Digital' : 'Não bloquear acesso digital'}`"></v-switch>
          </v-flex>
          <v-flex xs12 md4>
            <v-switch
              v-model="motivoSuspensao.adiarVecto"
              :label="`${motivoSuspensao.adiarVecto == 1 ? 'Adiar Vencimentos Parcelas*' : 'Não adiar vencimentos parcelas'}`"
            ></v-switch>
          </v-flex>
          <v-flex xs12 md12>
            <v-switch v-model="motivoSuspensao.situacao" :label="`${motivoSuspensao.situacao == 1 ? 'Ativo' : 'Inativo'}`"></v-switch>
          </v-flex>
          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">
              {{ validacao.mensagemErro }}
            </v-sheet>
          </v-flex>
          <span style="color:red"> *Funcionalidade em desenvolvimento.</span>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn to="/assinaturas/motivos-suspensao" color="orange lighten-2 white" style="color:white" id="btn-rsn">
        <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar
      </v-btn>
      <v-btn :loading="isUpdating" color="blue white" style="color:white" id="btn-rsn" depressed @click="salvar('false')"> <v-icon id="icon-rsn" left>update</v-icon>Salvar </v-btn>

      <v-btn :loading="isUpdating" color="orange white" style="color:white" id="btn-rsn" outlined @click="salvar('retornar')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>
      <v-btn :loading="isUpdating" color="blue white" style="color:white" id="btn-rsn" outlined @click="salvar('true')" v-if="direitoCriar">
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Novo
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      motivoSuspensao: {
        id: "",
        idSite: null,
        nome: "",
        bonificar: 0,
        digital: 0,
        adiarVecto: 0,
        situacao: 1
      },
      validacao: {
        id: false,
        idSite: false,
        nome: false,
        bonificar: false,
        digital: false,
        adiarVecto: false,
        situacao: false,
        mensagemErro: false
      },

      siteGrupo: [],

      idSitePrincipal: null,
      direitoCriar: false,
      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      this.isUpdating = true;
      try {
        var retorno = "";
        //se tiver id é um update
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "ass/motivo-suspensao/" + this.id, this.motivoSuspensao);
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "ass/motivo-suspensao?idSite=" + this.idSitePrincipal, this.motivoSuspensao);
          this.id = retorno.data.id;
          this.motivoSuspensao.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "true") {
          this.$router.push("/assinaturas/motivo-suspensao");
          this.$router.go("/assinaturas/motivo-suspensao");
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push("/assinaturas/motivos-suspensao");
          }, 2000);
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    pLetra(text) {
      return util.pLetra(text);
    }
  },

  async created() {
    this.id = this.$route.params.id ? this.$route.params.id : "";

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_MOTIVOS_SUSPENSÃO_CRIAR") {
        this.direitoCriar = true;
      }
    });

    //* Verifica se o site do usuário está em GRUPO - OBS: ou ele compartilha a informação ou deixa exclusiva para o seu site
    var idGrupoSite = this.$store.state.usuario.site_idGrupo;
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
    if (idGrupoSite != null) {
      this.siteGrupo = [
        {
          id: this.idSitePrincipal,
          nome: this.$store.state.usuario.site_nome
        }
      ];
    }
    try {
      //* Se é edição, carrega o elemento
      if (this.id == "") {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_MOTIVOS_SUSPENSÃO_CRIAR"]);
      } else {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_MOTIVOS_SUSPENSÃO_EDITAR"]);
        //* Carrega o elemento - somente se for público (idSite=null) ou for do site do usuário logado
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/motivo-suspensao/" + this.id + "?idSite=" + this.idSitePrincipal);
        if (!response.data) {
          this.$router.push("/assinaturas/motivos-suspensao");
        } else {
          this.motivoSuspensao = response.data;
        }
      }
    } catch {
      this.mensagem = "Ocorreu um erro ao carregar os dados";
      this.snackErro = true;
    }
  }
};
</script>
