<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">bookmarks</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">
        Conta -
        {{ grupo == 1 ? ($store.state.usuario.site_planoContas || direitoAdmin ? "Ativo" : "Caixa & Bancos") : grupo == 2 ? "Passivo" : grupo == 3 ? "Receitas" : "Despesas" }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12 md12 v-if="siteGrupo.length > 0">
            <v-autocomplete
              v-model="conta.idSite"
              :disabled="id || isUpdating || this.conta.idSintetica != null"
              :items="siteGrupo"
              :filter="filtrarObjetos"
              label="Restrita ao Site"
              item-text="nome"
              item-value="id"
              :rules="[validacao.idSite == false || validacao.idSite]"
              autocomplete="disabled"
              :name="Math.random()"
              no-data-text
              clearable
              @change="validacao.idSite = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12>
            <v-text-field v-model="hierarquia" label="Hierarquia" disabled color="blue-grey lighten-2"></v-text-field>
            <v-subheader class="red--text" v-if="maesInativas">{{ maesInativas }}</v-subheader>
          </v-flex>

          <v-flex xs12>
            <v-text-field
              autofocus
              v-model="conta.nome"
              label="Nome"
              maxlength="60"
              hint="Obrigatório"
              :rules="[validacao.nome == false || validacao.nome, value => !!value || 'Obrigatório']"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="
                conta.nome = pLetra(conta.nome);
                validacao.nome = false;
              "
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md4 v-if="grupo > 2 && conta.analitica == 1">
            <v-autocomplete
              v-model="conta.idCentroCusto"
              :disabled="isUpdating"
              :items="centrosCusto"
              :filter="filtrarObjetos"
              label="Centro de Custo"
              item-text="nome"
              item-value="id"
              :rules="[validacao.idCentroCusto == false || validacao.idCentroCusto]"
              no-data-text
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.idCentroCusto = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md4>
            <v-autocomplete
              v-model="conta.tipo"
              :disabled="isUpdating || id != ''"
              :items="tiposConta"
              :filter="filtrarObjetos"
              label="Tipo da Conta"
              item-text="nome"
              item-value="nome"
              :rules="[validacao.tipo == false || validacao.tipo]"
              no-data-text
              autocomplete="disabled"
              :name="Math.random()"
              clearable
              @change="validacao.tipo = false"
            >
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.nome"></v-list-item-title>
                  <v-list-item-subtitle v-html="data.item.descricao"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-flex>

          <v-flex xs12 md4 v-if="conta.analitica">
            <v-text-field
              v-model="conta.subtipo"
              label="SubTipo"
              hint="Assinatura/Anúncio: Nome do Produto; Venda Avulsa: Nome do Tipo de Banca"
              maxlength="50"
              :rules="[validacao.subtipo == false || validacao.subtipo]"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.subtipo = false"
              :disabled="isUpdating || id != ''"
            ></v-text-field>
          </v-flex>
          <v-flex xs12></v-flex>

          <v-flex xs6 md2>
            <v-text-field
              v-model="conta.ordem"
              label="Ordem"
              v-mask="'#######'"
              hint="Obrigatório - ordem desta conta dentro da hierarquia acima: 1,2..."
              :rules="[validacao.ordem == false || validacao.ordem, value => !!value || 'Obrigatório - ordem desta conta dentro da hierarquia acima: 1,2...']"
              color="blue-grey lighten-2"
              @change="validacao.ordem = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs6 md2>
            <v-text-field
              v-model="conta.contabil"
              label="Código Contábil"
              hint="Código da conta no sistema de Contabilidade"
              maxlength="20"
              :rules="[validacao.contabil == false || validacao.contabil]"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.contabil = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md4>
            <v-autocomplete
              v-if="conta.analitica"
              v-model="conta.codCategoria"
              :disabled="isUpdating || !direitoExtrato"
              :items="categorias"
              :filter="filtrarObjetos"
              label="Categoria"
              item-text="nome"
              item-value="id"
              :rules="[validacao.codCategoria == false || validacao.codCategoria]"
              no-data-text
              autocomplete="disabled"
              :name="Math.random()"
              clearable
              @change="validacao.codCategoria = false"
            >
              <template v-slot:selection="data">
                <v-avatar size="32" class="accent white--text" left>
                  {{ data.item.id }}
                </v-avatar>
                <span class="ml-2">{{ data.item.nome }}</span>
              </template>
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.id"></v-list-item-title>
                  <v-list-item-subtitle v-html="data.item.nome"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-flex>
          <v-flex xs4></v-flex>

          <v-flex xs12 md4>
            <v-switch
              v-model="conta.analitica"
              :label="`${conta.analitica == 1 ? 'Analítica - Recebe Lançamentos' : 'Sintética - Não Recebe Lançamentos'}`"
              :disabled="(conta.id != '' && conta.filhas > 0) || final"
            ></v-switch>
          </v-flex>

          <v-flex xs4>
            <v-switch v-model="conta.situacao" :label="`${conta.situacao == 1 ? 'Ativa' : 'Inativa'}`"></v-switch>
          </v-flex>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">{{ validacao.mensagemErro }}</v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn :to="'/financeiro/contas/' + grupo" color="orange lighten-2 white" id="btn-rsn" style="color:white">
        <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar
      </v-btn>
      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="blue white" id="btn-rsn" style="color:white" depressed @click="salvar('false')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar
      </v-btn>
      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="orange white" style="color:white" id="btn-rsn" outlined @click="salvar('retornar')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>
      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="blue white" id="btn-rsn" style="color:white" outlined @click="salvar('true')" v-if="direitoCriar">
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Nova
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      conta: {
        id: "",
        idSite: null,
        idSintetica: null,
        idCentroCusto: null,
        grupo: null,
        ordem: 0,
        nome: "",
        contabil: null,
        tipo: null,
        subtipo: null,
        analitica: 1,
        codCategoria: null,
        situacao: 1
      },
      validacao: {
        id: false,
        idSite: false,
        idSintetica: false,
        idCentroCusto: false,
        grupo: false,
        ordem: false,
        nome: false,
        contabil: false,
        tipo: false,
        subtipo: false,
        analitica: false,
        codCategoria: false,
        situacao: false,
        mensagemErro: false
      },

      hierarquia: "",
      siteGrupo: [],
      centrosCusto: [],
      tiposConta: [
        {
          nome: "APLICACAO",
          descricao: "Grupo de Contas de Aplicações Financeiras (no Ativo/Disponível)"
        },
        {
          nome: "ASSINATURA",
          descricao: "Receita de Venda/Renovação de Assinatura"
        },
        {
          nome: "MOVIMENTO",
          descricao: "Grupo de Contas de Movimento (Caixa & Bancos)"
        },
        {
          nome: "CAIXA",
          descricao: "Conta de Movimento de Caixa (no Ativo/Disponível)"
        },
        {
          nome: "CLASSIFICADO",
          descricao: "Receita de Venda de Classificados"
        },
        {
          nome: "CLIENTE",
          descricao: "Conta a Receber de Clientes"
        },
        {
          nome: "COMISSAO",
          descricao: "Conta a Pagar a Agentes/Agências"
        },
        {
          nome: "CONTA_PAGAR",
          descricao: "Grupo de Contas a Pagar (no Passivo/Circulante)"
        },
        {
          nome: "DEDUCAO_BONIFICACAO",
          descricao: "Dedução da Receita de Venda por Bonificação"
        },
        {
          nome: "DEDUCAO_CANCELAMENTO",
          descricao: "Dedução da Receita de Venda por Cancelamento"
        },
        {
          nome: "DEDUCAO_COMPENSACAO",
          descricao: "Dedução da Receita de Venda por Compensação"
        },
        {
          nome: "DEDUCAO_CORTESIA",
          descricao: "Dedução da Receita de Venda por Cortesia"
        },
        {
          nome: "DEDUCAO_DESCONTO_VENDA",
          descricao: "Dedução da Receita de Venda por Descontos Concedidos"
        },
        {
          nome: "DEDUCAO_VENDA",
          descricao: "Grupo de Deduções da Receita de Venda"
        },
        {
          nome: "DESCONTO_CONCEDIDO",
          descricao: "Despesa Financeira de Desconto Concedido"
        },
        {
          nome: "DESCONTO_RECEBIDO",
          descricao: "Receita Financeira de Desconto Recebido"
        },
        {
          nome: "FORNECEDOR",
          descricao: "Contas a Pagar a Fornecedores"
        },
        {
          nome: "IMOBILIZADO",
          descricao: "Grupo do Imobilizado"
        },
        {
          nome: "JURO_PAGO",
          descricao: "Despesa Financeira de Juro Pago"
        },
        {
          nome: "JURO_RECEBIDO",
          descricao: "Receita Financeira de Juro Recebido"
        },
        {
          nome: "PACOTE",
          descricao: "Venda Antecipada de Pacote de Anúncio"
        },
        {
          nome: "PERDA",
          descricao: "Despesa de Perda no Recebimento de Créditos no Cancelamento da Parcela"
        },
        {
          nome: "PERMUTA_PAGAR",
          descricao: "Permutas a Pagar"
        },
        {
          nome: "PERMUTA_RECEBER",
          descricao: "Permutas a Receber"
        },
        {
          nome: "PUBLICIDADE",
          descricao: "Receita de Venda de Publicidade"
        },
        {
          nome: "RECEITA_VENDA",
          descricao: "Grupo de Receitas de Vendas"
        },
        {
          nome: "VENDA_AVULSA",
          descricao: "Receita de Venda de Jornal Avulso"
        }
      ],
      categorias: [
        { id: 101, nome: "Cheques" },
        { id: 102, nome: "Encargos" },
        { id: 103, nome: "Estornos" },
        { id: 104, nome: "Lançamento Avisado" },
        { id: 105, nome: "Tarifas" },
        { id: 106, nome: "Aplicação" },
        { id: 107, nome: "Empréstimo / Financiamento" },
        { id: 108, nome: "Câmbio" },
        { id: 109, nome: "CPMF" },
        { id: 110, nome: "IOF" },
        { id: 111, nome: "Imposto de Renda" },
        { id: 112, nome: "Pagamento Fornecedores" },
        { id: 113, nome: "Pagamento Funcionários" },
        { id: 114, nome: "Saque Eletrônico" },
        { id: 115, nome: "Ações" },
        { id: 117, nome: "Transferência entre Contas" },
        { id: 118, nome: "Devolução da Compensação" },
        { id: 119, nome: "Devolução de Cheque Depositado" },
        { id: 120, nome: "Transferência Interbancária (DOC, TED)" },
        { id: 121, nome: "Antecipação a Fornecedores" },
        { id: 122, nome: "OC / AEROPS" },
        { id: 201, nome: "Depósitos" },
        { id: 202, nome: "Líquido de Cobrança" },
        { id: 203, nome: "Devolução de Cheques" },
        { id: 204, nome: "Estornos" },
        { id: 205, nome: "Lançamento Avisado" },
        { id: 206, nome: "Resgate de Aplicação" },
        { id: 207, nome: "Empréstimo / Financiamento" },
        { id: 208, nome: "Câmbio" },
        { id: 209, nome: "Transferência Interbancária (DOC, TED)" },
        { id: 210, nome: "Ações" },
        { id: 211, nome: "Dividendos" },
        { id: 212, nome: "Seguro" },
        { id: 213, nome: "Transferência entre Contas" },
        { id: 214, nome: "Depósitos Especiais" },
        { id: 215, nome: "Devolução da Compensação" },
        { id: 216, nome: "OCT" }
      ],

      final: false,
      idSitePrincipal: null,
      maesInativas: "",
      direitoAdmin: false,
      direitoCriar: false,
      direitoExtrato: false,
      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false,
      bloqueiaBotoes: false
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      this.isUpdating = true;
      try {
        var retorno = "";
        if (!this.conta.contabil) {
          this.conta.contabil = null;
        }
        if (!this.conta.analitica) {
          this.conta.idCentroCusto = null;
          this.conta.codCategoria = null;
          // } else {
          //   this.conta.origem = 0;
        }
        if (!this.conta.analitica || !this.conta.subtipo) {
          this.conta.subtipo = null;
        }
        //se tiver id é um update
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "fin/conta/" + this.id, this.conta);
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "fin/conta?idSite=" + this.idSitePrincipal, this.conta);
          this.id = retorno.data.id;
          this.conta.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "true") {
          this.$router.push("/financeiro/conta/" + this.grupo + (this.mae ? "/" + this.mae : "")); //para alterar a url
          this.$router.go("/financeiro/conta/" + this.grupo + (this.mae ? "/" + this.mae : "")); //para recarregar a página
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push("/financeiro/contas/" + this.grupo); //para alterar a url
          }, 2000);
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },
    async carregarCentrosCusto() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/centro-custo/lista?idSite=" + this.idSitePrincipal);
        if (response.data) {
          this.centrosCusto = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de centros de custo";
        this.snackErro = true;
      }
    },
    async carregarHierarquia() {
      try {
        //* Carrega a hierarquia da conta - nomes das contas mãe
        var idMae = this.conta.idSintetica;
        var itens = [];
        this.maesInativas = "";
        if (idMae) {
          while (idMae) {
            const responseMae = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/conta/" + idMae + "?idSite=" + this.idSitePrincipal);
            if (responseMae.data) {
              if (!responseMae.data.situacao) {
                this.maesInativas = responseMae.data.nome + (this.maesInativas ? ", " : "") + this.maesInativas;
              }
              idMae = responseMae.data.idSintetica;
              this.conta.idSite = responseMae.data.idSite; //* Todas as contas filhas estarão no mesmo site da conta de primeiro nível
              itens.push(responseMae.data.nome);
              if (!this.conta.ordem) {
                this.conta.ordem = parseInt(responseMae.data.filhas) + 1;
              }
            } else {
              idMae = null;
            }
          }
          if (this.maesInativas) {
            if (this.maesInativas.split(",").length == 1) {
              this.maesInativas = "Conta " + this.maesInativas + " está INATIVA";
            } else {
              this.maesInativas = "Contas " + this.maesInativas + " estão INATIVAS";
            }
          }
        } else {
          const responseGrupo = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/conta/filhasGrupo/" + this.grupo);
          if (responseGrupo.data) {
            if (!this.conta.ordem) {
              this.conta.ordem = parseInt(responseGrupo.data.filhas) + 1;
            }
          }
        }
        if (this.grupo == 1 && !this.$store.state.usuario.site_planoContas && !this.direitoAdmin) {
          this.hierarquia = "Caixa & Bancos/";
        } else {
          itens.forEach(element => {
            this.hierarquia = element + "\\" + this.hierarquia;
          });
          if (this.hierarquia.split("\\").length > 3) {
            //* Só permite 3 níveis, então a terceira tem que ser final
            this.conta.analitica = 1;
            this.final = true;
          }
          this.hierarquia = (this.grupo == 1 ? "Ativo" : this.grupo == 2 ? "Passivo" : this.grupo == 3 ? "Receitas" : "Despesas") + "\\" + this.hierarquia;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a hierarquia";
        this.snackErro = true;
      }
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    pLetra(text) {
      return util.pLetra(text);
    }
  },

  computed: {
    grupo() {
      return this.$route.params.grupo;
    },
    mae() {
      return this.$route.params.mae;
    }
  },

  async created() {
    this.isUpdating = false;
    this.bloqueiaBotoes = true;
    this.id = this.$route.params.id ? this.$route.params.id : "";

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA") {
        this.direitoAdmin = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_CONTAS_CRIAR") {
        this.direitoCriar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_EXTRATOS_LANÇAMENTOS") {
        this.direitoExtrato = true;
      }
    });
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

    //* Verifica se o site do usuário está em GRUPO - OBS: ou ele compartilha a informação ou deixa exclusiva para o seu site
    var idGrupoSite = this.$store.state.usuario.site_idGrupo;
    if (idGrupoSite != null) {
      this.siteGrupo = [
        {
          id: this.idSitePrincipal,
          nome: this.$store.state.usuario.site_nome
        }
      ];
    }
    await this.carregarCentrosCusto();

    //* Se é edição, carrega o elemento
    if (this.id == "") {
      this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_CONTAS_CRIAR"]);
      this.conta.grupo = this.grupo;
      this.conta.idSintetica = this.mae ? (this.mae == "null" ? null : this.mae) : null;
      await this.carregarHierarquia();
    } else {
      this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_CONTAS_EDITAR"]);
      try {
        //* Carrega o elemento - somente se for público (idSite=null) ou for do site do usuário logado
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/conta/" + this.id + "?idSite=" + this.idSitePrincipal);
        if (!response.data) {
          this.$router.push("/financeiro/contas/" + this.grupo);
        } else {
          this.conta = response.data;
          await this.carregarHierarquia();
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os dados";
        this.snackErro = true;
      }
    }
    setTimeout(() => {
      this.bloqueiaBotoes = false;
    }, 1000);
  }
};
</script>
