<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">radio</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Programação da {{ tipo == 1 ? "Rádio" : "TV" }}</v-toolbar-title>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12 md6>
            <v-autocomplete
              v-model="programacao.dia"
              :items="dias"
              :filter="filtrarItens"
              label="Dia da Semana"
              :disabled="isUpdating"
              :rules="[validacao.dia == false || validacao.dia, value => !!value || 'Obrigatório.']"
              no-data-text
              clearable
              @change="validacao.idSite = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs6 md3>
            <v-switch :disabled="isUpdating" class="mt-6" v-model="programacao.situacao" :label="`${programacao.situacao == 1 ? 'Ativa' : 'Inativa'}`"></v-switch>
          </v-flex>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">{{ validacao.mensagemErro }}</v-sheet>
          </v-flex>
        </v-layout>

        <v-layout wrap>
          <v-expansion-panels v-model="painel" focusable>
            <v-expansion-panel dark class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.programacaoItens ? 'red--text' : ''">
                <strong>Programação</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-toolbar>
                      <v-spacer></v-spacer>
                      <v-dialog persistent v-model="dialogProgramacaoItem" max-width="600px">
                        <template v-slot:activator="{ on }">
                          <v-btn color="success" fab small v-on="on" :disabled="isUpdating">
                            <v-icon color="white" title="Incluir">add</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form ref="formProgramacaoItem" v-model="programacaoItemValid" lazy-validation>
                            <v-toolbar>
                              <span class="headline">Programação</span>
                              <v-spacer></v-spacer>
                              <v-btn icon @click="closeProgramacaoItem">
                                <v-icon color="darken-1">cancel</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-container grid-list-md>
                                <v-layout wrap>
                                  <v-flex xs12>
                                    <v-text-field
                                      autofocus
                                      background-color="white"
                                      v-model="programacaoItem.nome"
                                      :rules="[validacao.nome == false || validacao.nome, value => !!value || 'Obrigatório.']"
                                      label="Nome"
                                      maxlength="35"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      color="blue-grey lighten-2"
                                      @change="
                                        programacaoItem.nome = programacaoItem.nome.toUpperCase();
                                        validacao.nome = false;
                                      "
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs6 md3>
                                    <v-text-field
                                      background-color="white"
                                      v-model="programacaoItem.horaInicio"
                                      :rules="[validacao.horaInicio == false || validacao.horaInicio, value => valida_hora(value)]"
                                      label="Horário Inicial"
                                      maxlength="5"
                                      v-mask="'##:##'"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      color="blue-grey lighten-2"
                                      @change="validacao.horaInicio = false"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs6 md3>
                                    <v-text-field
                                      background-color="white"
                                      v-model="programacaoItem.horaFim"
                                      :rules="[validacao.horaFim == false || validacao.horaFim, value => valida_hora(value)]"
                                      label="Horário Final"
                                      maxlength="5"
                                      v-mask="'##:##'"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      color="blue-grey lighten-2"
                                      @change="validacao.horaFim = false"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12 offset-md3 md3>
                                    <v-switch v-model="programacaoItem.situacao" :label="programacaoItem.situacao == 1 ? 'Ativa' : 'Inativa'"></v-switch>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" @click="closeProgramacaoItem">Retornar</v-btn>
                              <v-btn color="blue darken-1" @click="saveProgramacaoItem">Atualizar</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="header"
                      :items="programacaoItens"
                      no-data-text
                      no-results-text
                      :footer-props="{
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageOptions: [10, 25, 50],
                        pageText: ''
                      }"
                    >
                      <template v-slot:item.actions="{ item }">
                        <v-icon v-if="!isUpdating" class="mr-2" color="blue lighten-2" title="Editar" @click="editarProgramacaoItem(item)">edit</v-icon>
                        <v-icon v-if="!isUpdating" class="mr-2" color="red" title="Remover Programação" @click="removerProgramacaoItem(item)">delete</v-icon>
                      </template>
                      <template v-slot:item.situacao="{ item }">
                        <span v-html="item.situacao == 1 ? 'Ativa' : item.situacao == 0 ? '<strong>Inativa</strong>' : ''"></span>
                      </template>
                    </v-data-table>
                    <v-alert v-if="validacao.programacaoItens" :value="true" color="error" icon="priority_high" outlined>{{ validacao.programacaoItens }}</v-alert>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">
              {{ validacao.mensagemErro }}
            </v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn :to="'/administrativo/' + (tipo == 1 ? 'radios' : 'tvs')" id="btn-rsn" color="orange lighten-2 white" style="color:white">
        <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar
      </v-btn>
      <v-btn :loading="isUpdating" color="blue white" style="color:white" id="btn-rsn" depressed @click="salvar('false')"> <v-icon id="icon-rsn" left>update</v-icon>Salvar </v-btn>
      <v-btn :loading="isUpdating" color="orange white" style="color:white" id="btn-rsn" outlined @click="salvar('retornar')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>
      <v-btn :loading="isUpdating" color="blue white" style="color:white" id="btn-rsn" outlined @click="salvar('true')" v-if="direitoCriar">
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Nova
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>

    <v-dialog persistent v-model="dialogConfirm" max-width="600">
      <v-card>
        <v-toolbar>
          <span class="headline">Confirmação</span>
        </v-toolbar>
        <v-card-text class="mt-4" v-html="'<b>Tem certeza que deseja excluir?</b>'"></v-card-text>
        <v-card-actions>
          <v-btn color="red darken-1" text @click="naoConfirmado">Não</v-btn>
          <v-btn color="green darken-1" text @click="confirmado">Sim</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      programacao: {
        id: "",
        tipo: null,
        dia: null,
        situacao: 1
      },
      validacao: {
        id: false,
        dia: false,
        situacao: false,
        mensagemErro: false,
        programacaoItens: false,
        nome: false,
        horaInicio: false,
        horaFim: false
      },

      programacaoItens: [],
      dias: ["segunda", "terça", "quarta", "quinta", "sexta", "sábado", "domingo"],

      dialogProgramacaoItem: false,
      header: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Nome", value: "nome" },
        { text: "Horário Inicial", value: "horaInicio" },
        { text: "Horário Final", value: "horaFim" },
        { text: "Situação", value: "situacao" }
      ],
      programacaoItemValid: true,
      programacaoItemIndex: -1,
      programacaoItem: {
        id: null,
        nome: "",
        horaInicio: "",
        horaFim: "",
        situacao: 1
      },
      defaultProgramacaoItem: {
        id: null,
        nome: "",
        horaInicio: "",
        horaFim: "",
        situacao: 1
      },

      dialogConfirm: false,

      idSitePrincipal: null,
      painel: [0],
      direitoCriar: false,
      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }
      if (!this.programacaoItens.length) {
        this.mensagem = "Inclua pelo menos 1 item na programação";
        this.snackErro = true;
        return;
      }

      this.programacao.itens = this.programacaoItens;
      this.programacao.tipo = this.tipo;

      this.isUpdating = true;
      try {
        var retorno = "";
        //se tiver id é um update
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "adm/programacao/" + this.id, this.programacao);
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "adm/programacao?idSite=" + this.idSitePrincipal, this.programacao);
          this.id = retorno.data.id;
          this.programacao.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "true") {
          this.$router.push("/administrativo/" + (this.tipo == 1 ? "radio" : "tv"));
          this.$router.go("/administrativo/" + (this.tipo == 1 ? "radio" : "tv"));
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push("/administrativo/" + (this.tipo == 1 ? "radios" : "tvs"));
          }, 2000);
        } else {
          this.carregaItens();
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },

    editarProgramacaoItem(item) {
      this.programacaoItemIndex = this.programacaoItens.indexOf(item);
      this.programacaoItem = Object.assign({}, item);
      this.dialogProgramacaoItem = true;
    },
    removerProgramacaoItem(item) {
      this.programacaoItemIndex = this.programacaoItens.indexOf(item);
      this.programacaoItem = Object.assign({}, item);

      if (item.id) {
        this.dialogConfirm = true;
      } else {
        this.confirmado();
      }
    },
    async confirmado() {
      this.dialogConfirm = false;
      try {
        if (this.programacaoItem.id) {
          await api.delete(process.env.VUE_APP_URL_WEB_API + "adm/programacao-item/" + this.programacaoItem.id);
        }
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      } finally {
        this.programacaoItens.splice(this.programacaoItemIndex, 1);
        this.programacaoItem = Object.assign({}, this.defaultProgramacaoItem);
        this.programacaoItemIndex = -1;
        this.programacaoItemSelecionado = {};
      }
    },
    naoConfirmado() {
      this.dialogConfirm = false;
    },
    closeProgramacaoItem() {
      this.dialogProgramacaoItem = false;
      setTimeout(() => {
        this.programacaoItem = Object.assign({}, this.defaultProgramacaoItem);
        this.programacaoItemIndex = -1;
        this.$refs.formProgramacaoItem.resetValidation();
      }, 300);
    },
    saveProgramacaoItem() {
      if (this.$refs.formProgramacaoItem.validate()) {
        if (!util.valida_hora(this.programacaoItem.horaInicio)) {
          this.validacao.horaInicio = "Hora inválida";
          return;
        }
        if (!util.valida_hora(this.programacaoItem.horaFim)) {
          this.validacao.horaFim = "Hora inválida";
          return;
        }

        if (this.programacaoItemIndex > -1) {
          Object.assign(this.programacaoItens[this.programacaoItemIndex], this.programacaoItem);
        } else {
          this.programacaoItens.push(this.programacaoItem);
        }
        this.closeProgramacaoItem();
        this.programacaoItem = Object.assign({}, this.defaultProgramacaoItem);
        this.programacaoItemIndex = -1;
        this.$refs.formProgramacaoItem.resetValidation();
        this.validacao.programacaoItem = false;
      }
    },

    async carregaItens() {
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/programacao-item/programacao/" + this.id);
      if (response.data) {
        this.programacaoItens = response.data;
      }
    },

    filtrarItens(item, queryText, itemText) {
      return util.filtrarItens(item, queryText, itemText);
    },
    valida_hora(hora) {
      if (hora && !util.valida_hora(hora)) {
        return "Hora inválida";
      } else {
        return true;
      }
    }
  },

  computed: {
    tipo() {
      return this.$route.path.indexOf("/radio") > 1 ? 1 : 2;
    }
  },

  async created() {
    this.id = this.$route.params.id ? this.$route.params.id : "";
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ADMINISTRATIVO_RÁDIO_TV_CRIAR") {
        this.direitoCriar = true;
      }
    });

    try {
      //* Se é edição, carrega o item
      if (this.id == "") {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ADMINISTRATIVO_RÁDIO_TV_CRIAR"]);
      } else {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ADMINISTRATIVO_RÁDIO_TV_EDITAR"]);

        this.isUpdating = true;
        //* Carrega o item - somente se for público (idSite=null) ou for do site do usuário logado
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/programacao/" + this.id + "?idSite=" + this.idSitePrincipal);

        if (!response.data) {
          this.$router.push("/administrativo/" + (this.tipo == 1 ? "radios" : "tvs"));
        } else {
          this.programacao = response.data;
          await this.carregaItens();
          this.isUpdating = false;
        }
      }
    } catch {
      this.mensagem = "Ocorreu um erro ao carregar os dados";
      this.snackErro = true;
    }
  }
};
</script>
