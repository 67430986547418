<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">question_answer</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Módulo Financeiro</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="direitoExterno" color="success" fab small @click="dialogUpload = true">
        <v-icon color="white" title="Envio de Arquivo BeAudio">cloud_upload</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container>
      <v-layout wrap>
        <v-flex xs12>
          <v-alert :value="true" color="blue" icon="apps" outlined>Bem vindo ao módulo Financeiro.</v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.39 - 03/10/2024:
            <br />- Geração imediata de arquivo remessa de Débito em Conta do Banco do Brasil, Caixa, Bradesco, Banrisul, Sicredi, Santander, Itaú; <br />- Otimização do
            processamento de retornos de Débitos em Conta do Banco do Brasil, Caixa, Bradesco, Banrisul, Sicredi, Santander, Itaú;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.38 - 24/09/2024:
            <br />- Geração imediata de arquivo remessa de boletos Banrisul e Banco do Brasil; <br />- Otimização do processamento de retornos de boletos Banrisul e Banco do
            Brasil;<br />- Geração imediata de PDF's de boletos, arquivo remessa e retorno Sicoob; <br />- Geração imediata de PDF's de boletos Ailos;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.37 - 02/09/2024:
            <br />- Geração imediata de PDF's e arquivo remessa de boletos Sicredi; <br />- Otimização do processamento de retornos de boletos Sicredi;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.18 - 31/07/2023:
            <br />- Registro do motivo do bloqueio e cancelamento de parcelas;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.17 - 27/07/2023:
            <br />- Cobrança de parcelas do Financeiro através de réguas/etapas;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.13 - 12/06/2023:
            <br />- Atualização do layout Pagamentos BRR Banrisul com inclusão do PIX;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.10 - 05/05/2023:
            <br />- Geração imediata do arquivo remessa Banrisul (CNAB400);
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.6 - 07/03/2023:
            <br />- Filtro avançado de Contas a Pagar e a Receber e exportação para CSV; <br />- Criação de contas a pagar Mensais a partir do cadastro de uma única operação;
            <br />- Distinção da conta a pagar entre Orçamento e Previsão para Pagamento; <br />- Periodicidade em dias para ajuste de vencimentos no desmembramento de parcelas;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.5 - 28/02/2023:
            <br />- Contatos de cobrança com promessa de pagamento para parcelas de Pacotes, Venda Avulsa e Financeiro;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.4 - 07/02/2023:
            <br />- Geração imediata de PDF's de boletos Santander, Banrisul e Banco do Brasil;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.2 - 16/01/2023:
            <br />- Novo mecanismo de remessa/retorno Celesc;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.93 - 08/11/2022:
            <br />- Seleção automática do cedente para emissão de boletos, por módulo/forma de pagamento (caso haja mais de 1 cedente para boleto);
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.92 - 03/11/2022:
            <br />- Alteração em lote de valores de parcelas (ajuste no desconto/acréscimo);
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.84 - 02/08/2022:
            <br />- Geração de arquivo com dados das notas fiscais para SPED Fiscal (EFD-ICMS/IPI);
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.82 - 19/07/2022:
            <br />- Download dos XML's das NFe e NFSe por competência fechada; <br />- Cancelamento de NFe Remessa Futura, se não baixada;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.81 - 14/07/2022:
            <br />- Apresentação do vencimento original das parcelas na descrição do boleto;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.80 - 12/07/2022:
            <br />- Relatório de Apresentação Mensal;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.76 - 06/06/2022:
            <br />- Possibilidade de ajustar vencimentos ao desmembrar parcela (a cada 30 dias);
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.75 - 26/05/2022:
            <br />- Possibilidade de liberação imediata da parcela (sem emissão do lote de cancelamento/baixa no banco); <br />- Possibilidade de geração de lote único diário
            (inclui tudo em único lote para remessa no fim do dia);<br />
            <v-badge color="red" content="Atenção"></v-badge
            ><span class="ml-12"> Remessa deve ser solicitada novamente, caso sejam incluídas parcelas durante o processamento (status=SOLICITADA).</span>
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.74:
            <br />- Possibilidade de emissão de NFe Remessa Futura (e baixa automática por quitação/cancelamento de parcelas associadas);
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.69:
            <br />- Link para geração imediata de arquivo remessa de alteração/cancelamento e liberação das parcelas;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.68:
            <br />- Caixa Troco ao quitar parcela;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.65:
            <br />- Nota Fiscal de Serviços Eletrônica - Município de Pelotas/RS
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.59:
            <br />- Nota Fiscal de Serviços Eletrônica - Município de Tijucas/SC
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.58:
            <br />- Relatório de Carteira futura (com base na situação atual e agendamentos de cancelamentos, suspensões e renovações automáticas)
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.57:
            <br />- Permutas: <br />- Cadastro de conta a receber utilizando PERMUTA A PAGAR cadastrada previamente; <br />- Cadastro de conta a receber na forma de pagamento
            PERMUTA A RECEBER a ser abatida posteriormente; <br />- Inclusão de filtro pelo Cedente da parcela;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.54:
            <br />- Envio de Recibos por e-mail;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.53:
            <br />- Relatório Cliente/Fornecedor;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.52:
            <br />- Pagamentos BRR Banrisul;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.49:
            <br />- Emissão de boleto e nota fiscal por parcela (além da opção padrão: por cliente);
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.46:
            <br />- Extratos e Conciliação bancária;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.45:
            <br />- Seleção da conta movimento para quitação; <br />- Relatório de Balancete; <br />- Devolução, Estorno e Carta Correção de NFe;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.44:
            <br />- Cobrança de cartão de crédito online via Cielo;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.43:
            <br />- Log de Cobrança Cielo;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.42:
            <br />- Log de Parcelas;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.41:
            <br />- Permissão especial para cancelar quitação de parcela realizada pelo retorno do banco - a parcela será removida do lote e seu NossoNumero limpo; <br />- Novo
            status de Parcela = EXCLUÍDA = histórico de parcelas de renovações excluídas do sistema;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.40:
            <br />- Filtro e visualização (na tela de log) dos NossoNumero Anteriores;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.38:
            <br />- Personalização da mensagem do e-mail de cobrança; <br />- Filtro de e-mails por status (Enviado ou Erro); <br />- Data da fundação da pessoa jurídica;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.37:
            <br />- Inclusão de novos filtros na tela de remessas/retornos: tipo(1=Inc/2=Alt/3=Can), remessa, arquivo; <br />- Relatório de comissionamento (simples) de anúncios
            (na tela do agente, aba Relatório);
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.36:
            <br />- Se cobrança=Erro apenas limpa dados de cobrança anteriores, sem incluir em lote de alteração/cancelamento; <br />- inclusão do ID da parcela no Filtro Avançado
            para busca rápida - utilize este filtro também para buscas por cliente, identificação e contrato, é mais rápido;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.34:
            <br />- Tratamento do CSV de parcelas para apresentar campos essenciais (remoção de campos desnecessários e inclusão de novos campos, tais como status do retorno da
            cobrança);
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.33:
            <br />- Liberação do nossoNumero de cobrança cancelada no momento do download do arquivo remessa de cancelamento, sem afetar registro da confirmação (ou não) do
            cancelamento;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.32:
            <br />- Cobrança de cartão de crédito online via Mundipagg;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.29:
            <br />- Definição da receita/despesa e/ou origem/destino e centros de custo das operações a receber/pagar e transferências;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.28:
            <br />- Aplicação do endereço de cobrança ativo e mais atual do cliente, em todas as parcelas ainda não faturadas, ao desativar um endereço de cobrança; <br />- Idem
            para desativação de unidade consumidora, cartão de crédito e conta corrente;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.27:
            <br />- Inclusão de dados do agente de venda na NFSC com desconto de comissão; <br />- Inclusão de campo para dados adicionais para a NFSC além do campo de dados
            essenciais já existente;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.24:
            <br />- Fechamento NFSC (geração dos arquivos do convênio 115/03 para validação, geração do arquivo controle e envio a Sefaz);
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.20:
            <br />- Alerta de arquivo remessa criado e PDFs dos boletos gerados;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.19:
            <br />- Reenvio de email com erro e link para acesso rápido ao cliente do email para correções;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.18:
            <br />- Envio de email cobrança, boleto e nota fiscal;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.16:
            <br />- Seleção do Cedente do Boleto, no caso de haver mais de um; <br />- Ajuste do número da remessa, no momento da geração;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.15:
            <br />- Alteração de parcelas e geração de lotes de alteração/baixa de boleto/débito em conta;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.12:
            <br />- Criação do lote de remessa Celesc com único clique;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.10:
            <br />- Totalização de valores das parcelas (também selecionadas);
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.10:
            <br />- Totalização de valores das parcelas (também selecionadas);
          </v-alert>
        </v-flex>
      </v-layout>
      <v-dialog persistente v-model="dialogUpload" max-width="400px">
        <v-card>
          <v-toolbar>
            <span class="headline">Envio de Arquivo BeAudio</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialogUpload = false">
              <v-icon color="darken-1">cancel</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <FileUpload :beaudio="true" />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </v-card>
</template>

<script>
import FileUpload from "@/views/components/FileUpload.vue";
import { mapMutations } from "vuex";

export default {
  components: {
    FileUpload
  },
  data() {
    return {
      direitoExterno: false,
      dialogUpload: false
    };
  },
  methods: {
    ...mapMutations(["MOSTRAR_MENU"])
  },
  beforeMount() {
    this.MOSTRAR_MENU();

    var direitoFinanceiro = false;
    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;

      if (element.modulo == "FINANCEIRO" || direito == "WEBSUITA_ADMIN_SUITA") {
        direitoFinanceiro = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_EXTERNOS_UPLOAD") {
        this.direitoExterno = true;
      }
    });
    if (!direitoFinanceiro) {
      this.$router.push("/acesso-negado");
    }
  }
};
</script>

<style></style>
