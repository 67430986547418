<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">question_answer</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light"></v-toolbar-title>
    </v-toolbar>
    <v-container>
      <v-layout wrap>
        <v-flex xs12>
          <v-alert :value="true" color="blue" icon="apps" outlined>Bem vindo ao Suita S.I.M.</v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.32 - 24/05/2024:
            <br />- Integração com o Asaas (Cartão, Boleto, Pix);
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.31 - 29/04/2024:
            <br />- Integração com o Protheus;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.30 - 26/03/2024:
            <br />- Integração com o Google Firebase;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.3 - 01/02/2023:
            <br />- Novas customizações para Portugal;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.96 - 24/11/2022:
            <br />- Obrigação de senha forte - <strong style="color: red">Atenção</strong>: corrija sua senha para evitar que seja bloqueada em alguns dias;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.95 - 21/11/2022:
            <br />- Inclusão de direitos especiais para alteração de informações críticas em assinaturas e renovações;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.94 - 14/11/2022:
            <br />- Inclusão de direitos especiais para exportação de dados e alteração de informações críticas (edição avançada);
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.90 - 18/10/2022:
            <br />- Primeira parte do Guia Suita SIM;
            <v-icon title="Guia">help_outline</v-icon>
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.89 - 05/09/2022:
            <br />- Alteração das URLs para HPPS;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.77 - 08/06/2022:
            <br />- Customizações para Portugal;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.17:
            <br />- Descrição nos ícones dos módulos;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.11:
            <br />- Otimizações para maior agilidade ao sistema; <br />- Inclusão de botão para ligação direta ao cliente pelo celular;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.9:
            <br />- Atualização dinâmica das mensagens;
            <v-badge color="red" content="2" class="ml-1"></v-badge>
          </v-alert>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  methods: {
    ...mapMutations(["SELECIONAR_DASH"])
  },
  beforeMount() {
    this.SELECIONAR_DASH();
  }
};
</script>

<style>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
