<template>
  <div id="app">
    <v-app id="inspire">
      <v-card>
        <v-toolbar color="#333333">
          <v-icon color="white" class="mr-2">people</v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light">
            {{
              rota.includes("/assinantes")
                ? "Assinantes"
                : rota.includes("/bancas")
                ? "Bancas"
                : rota.includes("/fornecedores")
                ? "Fornecedores"
                : rota.includes("/agentes")
                ? "Agentes/Agências"
                : rota.includes("/anunciantes")
                ? "Anunciantes"
                : "Clientes"
            }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <router-link
            :to="
              rota.includes('/assinantes')
                ? '/assinaturas/assinante'
                : rota.includes('/bancas')
                ? '/assinaturas/banca'
                : rota.includes('/fornecedores')
                ? '/financeiro/fornecedor'
                : rota.includes('/agentes')
                ? '/administrativo/agente'
                : rota.includes('/anunciantes')
                ? '/comercial/anunciante'
                : '/financeiro/cliente'
            "
            style="text-decoration: none"
            v-if="direitoCriar"
          >
            <v-btn color="success" fab small>
              <v-icon color="white" title="Incluir">add</v-icon>
            </v-btn>
          </router-link>
        </v-toolbar>
        <div class="px-6 mt-4">
          <v-container fluid grid-list-md>
            <v-layout wrap>
              <v-flex md1 xs3>
                <v-dialog v-model="dialogFiltroAvancado" max-width="800px">
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" color="blue" fab small :disabled="loading || loadingExp || loadingImp">
                      <v-icon color="white" title="Filtro Avançado">search</v-icon>
                    </v-btn>

                    <v-btn
                      color="red lighten-2"
                      style="color:white"
                      class="btn-rsn ml-n2 mb-6"
                      id="btn-limpa-filtro"
                      fab
                      @click="filtroAvancadoLimpar"
                      :disabled="loading || loadingExp || loadingImp"
                    >
                      <v-icon class="icon-rsn" small center title="Limpar Filtro">clear</v-icon>
                    </v-btn>
                  </template>

                  <template>
                    <v-card>
                      <v-tabs v-model="tab" background-color="blue accent-4" center-active dark>
                        <v-tab>Cliente</v-tab>
                        <v-tab v-if="$store.state.usuario.site_assinaturas">Assinatura</v-tab>
                        <v-tab v-if="$store.state.usuario.site_comercial">Anúncio</v-tab>
                        <v-tab>Parcela</v-tab>
                        <v-tab>Nota Fiscal</v-tab>
                        <v-tab>Pesquisas</v-tab>
                      </v-tabs>

                      <v-tabs-items v-model="tab">
                        <v-tab-item>
                          <FiltroCliente ref="filtroCliente" />
                        </v-tab-item>
                        <v-tab-item v-if="$store.state.usuario.site_assinaturas">
                          <FiltroAssinatura ref="filtroAssinatura" prefixo="a." />
                        </v-tab-item>
                        <v-tab-item v-if="$store.state.usuario.site_comercial">
                          <FiltroAnuncio ref="filtroAnuncio" prefixo="u." />
                        </v-tab-item>
                        <v-tab-item>
                          <FiltroParcela ref="filtroParcela" prefixo="p." origem="CLI" />
                        </v-tab-item>
                        <v-tab-item>
                          <FiltroNF ref="filtroNF" prefixo="n." v-bind:nfe="true" v-bind:nfsc="true" v-bind:nfse="true" />
                        </v-tab-item>
                        <v-tab-item>
                          <Pesquisas ref="pesquisas" prefixo="c" />
                        </v-tab-item>
                      </v-tabs-items>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn small color="orange lighten-2 white" style="color:white" class="btn-rsn" @click="filtroAvancadoRetornar">
                          <v-icon class="icon-rsn" left>keyboard_arrow_left</v-icon>{{ $vuetify.breakpoint.mdAndUp ? "Retornar" : "" }}
                        </v-btn>
                        <v-btn small outlined color="red white" style="color:white" class="btn-rsn" @click="filtroAvancadoLimpar">
                          <v-icon class="icon-rsn" left>clear</v-icon>{{ $vuetify.breakpoint.mdAndUp ? "Limpar" : "" }}
                        </v-btn>
                        <v-btn small v-if="tab != 5" color="blue white" style="color:white" class="btn-rsn" depressed @click="limpaFiltro">
                          <v-icon class="icon-rsn" left>tune</v-icon>{{ $vuetify.breakpoint.mdAndUp ? "Filtrar" : "" }}
                        </v-btn>
                        <v-btn
                          small
                          v-if="direitoExportar"
                          outlined
                          color="blue white"
                          style="color:white"
                          class="btn-rsn"
                          depressed
                          @click="filtroAvancadoFiltrar(true, false, false)"
                        >
                          <v-icon class="icon-rsn" left>description</v-icon>{{ $vuetify.breakpoint.mdAndUp ? "Exportar" : "" }}
                        </v-btn>
                        <v-btn
                          small
                          v-if="direitoExportar"
                          outlined
                          color="blue white"
                          style="color:white"
                          class="btn-rsn"
                          depressed
                          @click="filtroAvancadoFiltrar(true, false, true)"
                        >
                          <v-icon class="icon-rsn" title="Telefones" left>perm_phone_msg</v-icon>
                        </v-btn>
                        <v-btn
                          small
                          v-if="tab != 5 && direitoImprimir"
                          outlined
                          color="blue white"
                          style="color:white"
                          class="btn-rsn"
                          depressed
                          @click="filtroAvancadoFiltrar(false, true, false)"
                        >
                          <v-icon class="icon-rsn" left>print</v-icon>{{ $vuetify.breakpoint.mdAndUp ? "Imprimir" : "" }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex md1 xs2>
                <v-btn
                  v-if="direitoExportar"
                  color="blue"
                  :loading="loadingExp"
                  :disabled="loading || loadingExp || loadingImp"
                  fab
                  small
                  @click="filtroAvancadoFiltrar(true, false, false)"
                >
                  <v-icon color="white" title="Exportar">description</v-icon>
                </v-btn>
              </v-flex>
              <v-flex md1 xs2>
                <v-btn
                  v-if="direitoExportar"
                  color="blue"
                  :loading="loadingExp"
                  :disabled="loading || loadingExp || loadingImp"
                  fab
                  small
                  @click="filtroAvancadoFiltrar(true, false, true)"
                >
                  <v-icon color="white" title="Telefones">perm_phone_msg</v-icon>
                </v-btn>
              </v-flex>
              <v-flex md1 xs2>
                <v-btn
                  v-if="direitoImprimir"
                  color="blue"
                  :loading="loadingImp"
                  :disabled="loading || loadingExp || loadingImp"
                  fab
                  small
                  @click="filtroAvancadoFiltrar(false, true, false)"
                >
                  <v-icon color="white" title="Imprimir">print</v-icon>
                </v-btn>
              </v-flex>
              <v-flex xs6 md2>
                <v-text-field
                  background-color="white"
                  class="mb-2 elevation-0"
                  v-model="idClientePesquisa"
                  v-mask="'#######'"
                  append-icon="search"
                  label="ID"
                  @keyup.enter="limpaFiltro"
                  @click:append="limpaFiltro"
                  autocomplete="disabled"
                  :name="Math.random()"
                  :disabled="loading || loadingExp || loadingImp"
                ></v-text-field>
              </v-flex>
              <v-flex xs6 md2>
                <v-text-field
                  background-color="white"
                  class="mb-2 elevation-0"
                  v-model="identificacaoPesquisa"
                  v-mask="'##############'"
                  append-icon="search"
                  label="Identificação"
                  @keyup.enter="limpaFiltro"
                  @click:append="limpaFiltro"
                  autocomplete="disabled"
                  :name="Math.random()"
                  :disabled="loading || loadingExp || loadingImp"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md2>
                <v-text-field
                  background-color="white"
                  class="mb-2 elevation-0"
                  v-model="nomePesquisa"
                  append-icon="search"
                  label="Nome"
                  @keyup.enter="limpaFiltro"
                  @click:append="limpaFiltro"
                  autocomplete="disabled"
                  :name="Math.random()"
                  :disabled="loading || loadingExp || loadingImp"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>

          <v-data-table
            mobile-breakpoint="0"
            :headers="
              rota.includes('/clientes') || rota.includes('/assinantes') || rota.includes('/anunciantes')
                ? headers
                : rota.includes('/agentes')
                ? headersAge
                : rota.includes('/bancas')
                ? headersBan
                : headersAlt
            "
            :items="registros"
            :options.sync="options"
            :no-data-text="mensagemPesquisa"
            :footer-props="rodape"
            :disable-sort="carregando"
            :disable-pagination="carregando"
            :loading="loading"
            loading-text="Carregando..."
          >
            <template v-slot:item.actions="{ item }">
              <v-icon v-if="direitoEditar" color="blue" title="Editar" @click="editar(item)">edit</v-icon>
              <v-icon v-if="direitoEditar" color="blue" title="Visualizar Log" @click="visualizarLog(item)">info</v-icon>
              <v-icon v-if="direitoExportar" color="green lighten-2" title="Contatos" @click="visualizarContatos(item)">contact_phone</v-icon>
            </template>
            <template v-slot:item.online="{ item }">
              <span v-html="item.online == 1 ? 'Sim' : ''"></span>
            </template>
            <template v-slot:item.situacao="{ item }">
              <span
                v-html="
                  item.situacao == 1 &&
                  (item.agenteSituacao == 1 || item.agenteSituacao == 0 ? item.agenteSituacao : 1) &&
                  (item.bancaSituacao == 1 || item.bancaSituacao == 0 ? item.bancaSituacao : 1)
                    ? 'Ativo'
                    : '<strong>Inativo</strong>'
                "
              ></span>
            </template>
            <template v-slot:item.descComissao="{ item }">
              <span v-html="item.descComissao == 1 ? 'Sim' : ''"></span>
            </template>
          </v-data-table>
          <div class="text-xs-center pt-2 marg-bt">
            <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 7" circle></v-pagination>
          </div>
        </div>
        <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagemPesquisa }}</v-snackbar>
        <v-dialog v-model="dialogLogAlteracao" max-width="1200px">
          <v-card>
            <LogAlteracao :idCliente="idCliente" :dialogLogAlteracao.sync="dialogLogAlteracao" />
          </v-card>
        </v-dialog>
      </v-card>
    </v-app>
  </div>
</template>

<script>
import FiltroCliente from "@/views/components/FiltroCliente.vue";
import FiltroAssinatura from "@/views/components/FiltroAssinatura.vue";
import FiltroAnuncio from "@/views/components/FiltroAnuncio.vue";
import FiltroParcela from "@/views/components/FiltroParcela.vue";
import FiltroNF from "@/views/components/FiltroNF.vue";
import Pesquisas from "@/views/components/Pesquisas.vue";
import LogAlteracao from "@/views/components/LogAlteracao.vue";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
import util from "../../util";
export default {
  components: {
    FiltroCliente,
    FiltroAssinatura,
    FiltroAnuncio,
    FiltroParcela,
    FiltroNF,
    Pesquisas,
    LogAlteracao
  },
  data() {
    return {
      headers: [
        { text: "Ações", value: "actions", width: "120" },
        { text: "ID", value: "id" },
        { text: "Identificação", value: "identificacao" },
        { text: "Nome", value: "nome" },
        { text: "E-mail", value: "email" },
        { text: "Online", value: "online" },
        // { text: "Rede", value: "rede" },
        { text: "Situação", value: "situacao" }
      ],
      headersAlt: [
        { text: "Ações", value: "actions", width: "120" },
        { text: "ID", value: "id" },
        { text: "Identificação", value: "identificacao" },
        { text: "Nome", value: "nome" },
        { text: "E-mail", value: "email" },
        // { text: "Rede", value: "rede" },
        { text: "Situação", value: "situacao" }
      ],
      headersAge: [
        { text: "Ações", value: "actions", width: "120" },
        { text: "ID", value: "id" },
        { text: "Identificação", value: "identificacao" },
        { text: "Nome", value: "nome" },
        { text: "E-mail", value: "email" },
        // { text: "Rede", value: "rede" },
        { text: "Situação", value: "situacao", divider: "true" },
        { text: "Desc. Máx. Anúncio (%)", value: "descMaximoCom" },
        { text: "Com. Padr. Anúncio (%)", value: "comissaoPadraoCom" },
        { text: "Descontar Comissão", value: "descComissao" }
      ],
      headersBan: [
        { text: "Ações", value: "actions", width: "120" },
        { text: "ID", value: "id" },
        { text: "Identificação", value: "identificacao" },
        { text: "Nome", value: "nome" },
        { text: "E-mail", value: "email" },
        // { text: "Rede", value: "rede" },
        { text: "Situação", value: "situacao", divider: "true" },
        { text: "Tipo", value: "tipoBanca" }
      ],

      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },

      dialogLogAlteracao: false,
      idCliente: "",

      inicial: true,
      idSitePrincipal: null,
      filtroCliente: null,
      dialogFiltroAvancado: false,
      direitoCriar: false,
      direitoEditar: false,
      direitoExportar: false,
      direitoImprimir: false,
      mensagemPesquisa: "",
      snackErro: false,

      idClientePesquisa: null,
      identificacaoPesquisa: null,
      nomePesquisa: null,

      registros: [],
      registrosPagina: 10,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      carregando: true,
      ordenacao: "",
      ordem: "desc",
      options: {},
      tab: null,
      loader: null,
      loading: false,
      loadingExp: false,
      loadingImp: false
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    filtroAvancadoRetornar() {
      //* Esconde a dialog do filtro avançado
      this.dialogFiltroAvancado = false;
    },
    editar(item) {
      window.open(
        (this.rota.includes("/assinantes")
          ? "/assinaturas/assinante"
          : this.rota.includes("/bancas")
          ? "/assinaturas/banca"
          : this.rota.includes("/fornecedores")
          ? "/financeiro/fornecedor"
          : this.rota.includes("/agentes")
          ? "/administrativo/agente"
          : this.rota.includes("/anunciantes")
          ? "/comercial/anunciante"
          : "/financeiro/cliente") +
          "/" +
          item.id,
        "_blank"
      );
    },
    filtroAvancadoLimpar() {
      //* Limpa os registros da tabela
      this.registros = [];
      this.totalRegistros = 0;
      this.valorTotal = 0;
      this.totalPaginas = 0;
      this.mensagemPesquisa = "MONTE SEU FILTRO PARA VISUALIZAR OS REGISTROS...";

      //* Limpa os campos dos filtros
      if (this.$refs.filtroCliente) {
        this.$refs.filtroCliente.limpar();
      }
      if (this.$refs.filtroAssinatura) {
        this.$refs.filtroAssinatura.limpar();
      }
      if (this.$refs.filtroAnuncio) {
        this.$refs.filtroAnuncio.limpar();
      }
      if (this.$refs.filtroParcela) {
        this.$refs.filtroParcela.limpar();
      }
      if (this.$refs.filtroNF) {
        this.$refs.filtroNF.limpar();
      }
    },
    limpaFiltro() {
      var pgAnterior = this.paginaAtual;
      this.paginaAtual = 1;
      if (pgAnterior == 1) {
        this.filtroAvancadoFiltrar(false, false, false);
      }
    },
    async filtroAvancadoFiltrar(exportar, imprimir, fones) {
      //* Limpa os registros da tabela
      this.registros = [];
      this.totalRegistros = 0;
      this.totalPaginas = 0;

      if (this.idClientePesquisa) {
        this.$router.push(
          (this.rota.includes("/assinante")
            ? "/assinaturas/assinante/"
            : this.rota.includes("/banca")
            ? "/assinaturas/banca/"
            : this.rota.includes("/fornecedor")
            ? "/financeiro/fornecedor/"
            : this.rota.includes("/agente")
            ? "/administrativo/agente/"
            : this.rota.includes("/anunciante")
            ? "/comercial/anunciante/"
            : "/financeiro/cliente/") + this.idClientePesquisa
        );
        return;
      }
      if (this.identificacaoPesquisa && !util.valida_cpf(this.identificacaoPesquisa) && !util.valida_cnpj(this.identificacaoPesquisa)) {
        this.mensagemPesquisa = "Identificação inválida";
        this.snackErro = true;
        return;
      }

      this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
      var l = null;
      if (exportar) {
        this.loader = "loadingExp";
        l = this.loader;
        this[l] = !this[l];
        setTimeout(() => (this[l] = false), 5000);
        this.loader = null;
      }
      if (imprimir) {
        this.loader = "loadingImp";
        l = this.loader;
        this[l] = !this[l];
      }

      if (!imprimir && !exportar) {
        this.carregando = true;
        this.mensagemPesquisa = "";
        this.loading = true;
      } else {
        this.mensagemPesquisa = "MONTE SEU FILTRO PARA VISUALIZAR OS REGISTROS...";
      }

      //* Esconde a dialog do filtro avançado
      this.dialogFiltroAvancado = false;

      //* Filtros avançados do cliente
      var filtroClienteUrl = this.montaFiltro();
      if (this.identificacaoPesquisa) {
        if (filtroClienteUrl.includes("identificacao")) {
          this.identificacaoPesquisa = "";
        } else {
          filtroClienteUrl += "&identificacao=" + this.identificacaoPesquisa;
        }
      }
      if (this.nomePesquisa) {
        if (filtroClienteUrl.includes("&nome=")) {
          this.nomePesquisa = "";
        } else {
          filtroClienteUrl += "&nome=" + this.nomePesquisa;
          filtroClienteUrl += "&nomeFantasia=" + this.nomePesquisa;
        }
      }

      var camposAnuncio = "";
      if (exportar) {
        if (this.$refs.pesquisas) {
          //* Carrega os filtros da nota fiscal
          camposAnuncio = this.$refs.pesquisas.filtroUrlCampos();
        }
      }

      try {
        var tela = this.rota.includes("/bancas") ? "banca" : this.rota.includes("/fornecedores") ? "fornecedor" : this.rota.includes("/agentes") ? "agente" : "cliente";
        //* Consulta os dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "adm/cliente?" +
            filtroClienteUrl +
            "&campos=" +
            camposAnuncio +
            "&pagina=" +
            this.paginaAtual +
            "&tamanho=" +
            this.registrosPagina +
            "&ordenacao=" +
            this.ordenacao +
            "&ordem=" +
            this.ordem +
            "&tela=" +
            tela +
            (exportar ? "&exportar=1" : "") +
            (imprimir ? "&imprimir=1" : "") +
            (fones ? "&telefones=1" : "")
        );

        if (imprimir) {
          util.imprimir(response.data);
        } else if (!exportar) {
          //* Carrega os dados de retorno
          this.registros = response.data.data;
          this.registros.forEach(element => {
            element.nome = element.nome + (element.nomeFantasia ? " | " + element.nomeFantasia : "");
            if (element.lgpd) {
              element.nome = util.abreviaNome(element.nome);
              element.identificacao = element.identificacao.substring(0, 3) + "***";
              element.email = util.abreviaEmail(element.email);
            }
          });
          this.totalRegistros = response.data.total;
          this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
          this.rodape.pageText = this.totalRegistros + " itens";

          //* Fecha a mensagem de carregando
          this.carregando = false;

          //* Registra caso algum item não seja encontrado
          this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
        }
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else if (e.response && e.response.data.length > 0) {
          this.mensagemPesquisa = "ERRO: " + e.response.data[0].mensagem;
          this.snackErro = true;
        } else {
          this.mensagemPesquisa = "Erro ao buscar dados: " + e;
          this.snackErro = true;
        }
      } finally {
        this.loading = false;
        if (imprimir) {
          this[l] = false;
          this.loader = null;
        }
      }
    },

    montaFiltro() {
      var filtroClienteUrl = "";
      try {
        //* Carrega os filtros do cliente
        if (this.$refs.filtroCliente) {
          filtroClienteUrl = this.$refs.filtroCliente.filtroUrlParametros(this.idSitePrincipal);
        }

        if (this.$refs.filtroAssinatura) {
          //* Carrega os filtros da assinatura
          filtroClienteUrl += this.$refs.filtroAssinatura.filtroUrlParametros(this.idSitePrincipal);
        }

        if (this.$refs.filtroAnuncio) {
          //* Carrega os filtros do anúncio
          filtroClienteUrl += this.$refs.filtroAnuncio.filtroUrlParametros(this.idSitePrincipal);
        }

        if (this.$refs.filtroParcela) {
          //* Carrega os filtros da parcela
          filtroClienteUrl += this.$refs.filtroParcela.filtroUrlParametros(this.idSitePrincipal);
        }

        if (this.$refs.filtroNF) {
          //* Carrega os filtros da nota fiscal
          filtroClienteUrl += this.$refs.filtroNF.filtroUrlParametros();
        }
      } catch {
        filtroClienteUrl = "";
      }
      return filtroClienteUrl;
    },

    visualizarLog(item) {
      this.idCliente = item.id + "";
      this.dialogLogAlteracao = true;
    },
    async visualizarContatos(item) {
      try {
        if (!item.processando) {
          item.processando = true;
          const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente/contatos/" + item.id + "?nome=" + item.nome);
          util.imprimir(response.data);
        }
      } catch (e) {
        this.mensagem = "ERRO: " + e.response.data;
        this.snackErro = true;
      } finally {
        item.processando = false;
      }
    },
    closeLogAlteracao() {
      this.dialogLogAlteracao = false;
      this.idCliente = "";
    }
  },

  beforeMount() {
    this.POSSUI_DIREITO([
      "WEBSUITA_ADMIN_SUITA",
      "FINANCEIRO_CLIENTES_ACESSO",
      "ASSINATURAS_ASSINANTES_ACESSO",
      "ADMINISTRATIVO_AGENTES_ACESSO",
      "ASSINATURAS_BANCAS_ACESSO",
      "FINANCEIRO_FORNECEDORES_ACESSO",
      "COMERCIAL_ANUNCIANTES_ACESSO"
    ]);
    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;

      if (this.rota.includes("/assinantes")) {
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_ASSINANTES_CRIAR") {
          this.direitoCriar = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_ASSINANTES_EDITAR") {
          this.direitoEditar = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_ASSINANTES_EXPORTAR") {
          this.direitoExportar = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_ASSINANTES_IMPRIMIR") {
          this.direitoImprimir = true;
        }
      } else if (this.rota.includes("/bancas")) {
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_BANCAS_CRIAR") {
          this.direitoCriar = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_BANCAS_EDITAR") {
          this.direitoEditar = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_BANCAS_EXPORTAR") {
          this.direitoExportar = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_BANCAS_IMPRIMIR") {
          this.direitoImprimir = true;
        }
      } else if (this.rota.includes("/fornecedores")) {
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_FORNECEDORES_CRIAR") {
          this.direitoCriar = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_FORNECEDORES_EDITAR") {
          this.direitoEditar = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_FORNECEDORES_EXPORTAR") {
          this.direitoExportar = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_FORNECEDORES_IMPRIMIR") {
          this.direitoImprimir = true;
        }
      } else if (this.rota.includes("/agentes")) {
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ADMINISTRATIVO_AGENTES_CRIAR") {
          this.direitoCriar = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ADMINISTRATIVO_AGENTES_EDITAR") {
          this.direitoEditar = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ADMINISTRATIVO_AGENTES_EXPORTAR") {
          this.direitoExportar = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ADMINISTRATIVO_AGENTES_IMPRIMIR") {
          this.direitoImprimir = true;
        }
      } else if (this.rota.includes("/anunciantes")) {
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_ANUNCIANTES_CRIAR") {
          this.direitoCriar = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_ANUNCIANTES_EDITAR") {
          this.direitoEditar = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_ANUNCIANTES_EXPORTAR") {
          this.direitoExportar = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_ANUNCIANTES_IMPRIMIR") {
          this.direitoImprimir = true;
        }
      } else {
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_CLIENTES_CRIAR") {
          this.direitoCriar = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_CLIENTES_EDITAR") {
          this.direitoEditar = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_CLIENTES_EXPORTAR") {
          this.direitoExportar = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_CLIENTES_IMPRIMIR") {
          this.direitoImprimir = true;
        }
      }
    });
  },

  computed: {
    rota() {
      return this.$route.path;
    }
  },
  watch: {
    paginaAtual: function() {
      this.filtroAvancadoFiltrar(false, false, false);
    },
    $route() {
      this.filtroAvancadoFiltrar(false, false, false);
    },
    dialogLogAlteracao(val) {
      val || this.closeLogAlteracao();
    },

    options: {
      handler() {
        this.ordem = "desc";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }

        if (this.options.sortBy.length > 0) {
          if (this.options.sortBy[0] == "id") {
            this.ordenacao = "cliente.id";
          } else if (this.options.sortBy[0] == "identificacao") {
            this.ordenacao = "cliente.identificacao";
          } else if (this.options.sortBy[0] == "nome") {
            this.ordenacao = "cliente.nome";
          } else if (this.options.sortBy[0] == "email") {
            this.ordenacao = "cliente.email";
          } else if (this.options.sortBy[0] == "online") {
            this.ordenacao = "cliente.online";
            // } else if (this.options.sortBy[0] == "rede") {
            //   this.ordenacao = "cliente.rede";
          } else if (this.options.sortBy[0] == "situacao") {
            if (this.rota.includes("/bancas")) {
              this.ordenacao = "(cliente_banca.situacao AND cliente.situacao)";
            } else if (this.rota.includes("/agentes")) {
              this.ordenacao = "(cliente_agente.situacao AND cliente.situacao)";
            } else {
              this.ordenacao = "cliente.situacao";
            }
          } else {
            this.ordenacao = this.options.sortBy[0];
          }
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }
        if (!this.inicial) {
          this.filtroAvancadoFiltrar(false, false, false);
        } else {
          this.mensagemPesquisa = "MONTE SEU FILTRO PARA VISUALIZAR OS REGISTROS...";
          this.inicial = false;
        }
      }
    }
  }
};
</script>
