<template>
  <v-navigation-drawer
    fixed
    :clipped="$vuetify.breakpoint.mdAndUp"
    app
    v-model="$store.state.drawer"
  >
    <a target="_blank" class="estilo-link-site" href="https://suita.com.br">
      <img class="logo-suita mt-4" src="/suitalogo.png" />
    </a>
    <v-btn
      class="mt-4"
      fab
      fixed
      right
      small
      @click.stop="MOSTRAR_OCULTAR_MENU"
    >
      <v-icon color="black">navigate_before</v-icon>
    </v-btn>

    <v-list dense>
      <template v-for="item in $store.getters.filtroMenu">
        <v-list-group
          v-if="item.children"
          v-model="item.model"
          :key="item.text"
          :prepend-icon="item.model ? item.icon : item['icon-alt']"
          append-icon
        >
          <v-list-item absolute fixed slot="activator">
            <v-list-item-content>
              <v-list-item-title>
                <strong>{{ item.text }}</strong>
              </v-list-item-title>
              <!-- itens/títulos com subitens -->
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-for="(child, i) in item.children"
            :key="i"
            :to="child.acesso ? child.link : ''"
          >
            <v-list-item-action v-if="child.icon" class="ml-4">
              <v-icon>{{ child.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title style="color: black">{{
                ajustaTexto(child.text)
              }}</v-list-item-title>
              <!-- subitens -->
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item v-else :key="item.text" :to="item.acesso ? item.link : ''">
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title style="color: black">{{
              item.text
            }}</v-list-item-title>
            <!-- itens sem subitens -->
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
    <v-list>
      <v-list-item-action>
        <v-list-item-content></v-list-item-content>
      </v-list-item-action>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  methods: {
    ...mapMutations(["MOSTRAR_OCULTAR_MENU"]),
    ajustaTexto(texto) {
      var direitoAdmin = false;
      if (this.$store.state.usuario.direitos.length > 0) {
        const item = this.$store.state.usuario.direitos[0];
        direitoAdmin =
          item.modulo + "_" + item.grupo + "_" + item.direito ==
          "WEBSUITA_ADMIN_SUITA";
      }
      var retorno = texto;
      if (
        texto == "Ativo" &&
        !this.$store.state.usuario.site_planoContas &&
        !direitoAdmin
      ) {
        retorno = "Caixa & Bancos";
      }
      return retorno;
    }
  },
  data() {
    return {
      modulo: ""
    };
  }
};
</script>

<style>
.primary--text {
  color: #000 !important;
  caret-color: #000 !important;
}
</style>
