<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">account_balance_wallet</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Regra de Comissionamento de Agentes/Agências</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12 md12 v-if="siteGrupo.length > 0">
            <v-autocomplete
              v-model="regraComissionamento.idSite"
              :disabled="isUpdating"
              :items="siteGrupo"
              :filter="filtrarObjetos"
              label="Restrito ao Site"
              item-text="nome"
              item-value="id"
              autocomplete="disabled"
              :name="Math.random()"
              no-data-text
              clearable
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md9>
            <v-text-field
              autofocus
              v-model="regraComissionamento.nome"
              label="Nome da Regra de Comissionamento"
              maxlength="50"
              hint="Obrigatório"
              :rules="[
                validacao.nome == false || validacao.nome,
                value => !!value || 'Obrigatório.',
                value => valida_nome(value) || 'Nome inválido',
                value => value.length > 2 || 'Preencha o nome da regra de comissionamento'
              ]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="
                regraComissionamento.nome = pLetra(regraComissionamento.nome);
                validacao.nome = false;
              "
            ></v-text-field>
          </v-flex>
          <v-flex xs6 md3>
            <v-switch v-model="regraComissionamento.meta" :label="`${regraComissionamento.meta == 1 ? 'Possui Meta(s)' : 'Não possui meta(s)'}`" :disabled="id != ''"></v-switch>
          </v-flex>

          <v-flex xs12 md6 v-if="regraComissionamento.meta == 0">
            <v-card color="white lighten-1" lighten>
              <v-container fluid grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <h4 class="overline font-weight-black font-italic">
                      Assinatura Nova (ou reiniciada)
                    </h4>
                    <v-divider></v-divider>
                  </v-flex>
                  <v-flex xs6>
                    <v-currency-field
                      :locale="`pt-${$store.state.usuario.site_pais}`"
                      v-model="regraComissionamento.percNova"
                      label="Percentual"
                      :allowNegative="false"
                      :max="999"
                      :rules="[value => !!value || 'Obrigatório.', value => (value && value.length < 6) || 'Percentual da Comissão inválido']"
                      color="blue-grey lighten-2"
                      :disabled="isUpdating"
                    ></v-currency-field>
                  </v-flex>
                  <v-flex xs6>
                    <v-currency-field
                      :locale="`pt-${$store.state.usuario.site_pais}`"
                      v-model="regraComissionamento.vlrNova"
                      label="Valor Fixo"
                      :allowNegative="false"
                      :max="9999"
                      :rules="[value => !!value || 'Obrigatório.', value => (value && value.length < 8) || 'Valor Fixo da Comissão inválido']"
                      color="blue-grey lighten-2"
                      :disabled="isUpdating"
                    ></v-currency-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>
          </v-flex>

          <v-flex xs12 md6>
            <v-card color="white lighten-1" lighten>
              <v-container fluid grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <h4 class="overline font-weight-black font-italic">
                      Assinatura Renovada (não reiniciada)
                    </h4>
                    <v-divider></v-divider>
                  </v-flex>
                  <v-flex xs6>
                    <v-currency-field
                      :locale="`pt-${$store.state.usuario.site_pais}`"
                      v-model="regraComissionamento.percRenovada"
                      label="Percentual"
                      :allowNegative="false"
                      :max="999"
                      :rules="[value => !!value || 'Obrigatório.', value => (value && value.length < 6) || 'Percentual da Comissão inválido']"
                      color="blue-grey lighten-2"
                      :disabled="isUpdating"
                    ></v-currency-field>
                  </v-flex>
                  <v-flex xs6>
                    <v-currency-field
                      :locale="`pt-${$store.state.usuario.site_pais}`"
                      v-model="regraComissionamento.vlrRenovada"
                      label="Valor Fixo"
                      :allowNegative="false"
                      :max="9999"
                      :rules="[value => !!value || 'Obrigatório.', value => (value && value.length < 8) || 'Valor Fixo da Comissão inválido']"
                      color="blue-grey lighten-2"
                      :disabled="isUpdating"
                    ></v-currency-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>
          </v-flex>

          <v-flex xs12 md3 v-if="regraComissionamento.meta == 1">
            <v-text-field
              v-model="regraComissionamento.fatorDigital"
              label="Fator Digital"
              v-mask="'##'"
              hint="Número de assinaturas ONLINE que equivalem a 1 IMPRESSO"
              :rules="[value => (value > 0 && value <= 99) || 'Fator inválido']"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
            ></v-text-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-switch v-model="regraComissionamento.situacao" :label="`${regraComissionamento.situacao == 1 ? 'Ativo' : 'Inativo'}`"></v-switch>
          </v-flex>
          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">
              {{ validacao.mensagemErro }}
            </v-sheet>
          </v-flex>
        </v-layout>

        <v-layout wrap>
          <v-expansion-panels v-model="painel" focusable>
            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating" v-if="regraComissionamento.meta == 1">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.metas ? 'red--text' : ''">
                <strong>Metas por Captação</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-toolbar>
                      <v-spacer></v-spacer>
                      <v-dialog persistent v-model="dialogMeta" max-width="600px">
                        <template v-slot:activator="{ on }">
                          <v-btn color="success" fab small v-on="on">
                            <v-icon color="white" title="Incluir">add</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form ref="formMeta" v-model="metaValid" lazy-validation>
                            <v-toolbar>
                              <span class="headline">Meta</span>
                              <v-spacer></v-spacer>
                              <v-btn icon @click="closeMeta">
                                <v-icon color="darken-1">cancel</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-container grid-list-md>
                                <v-layout wrap>
                                  <v-flex xs12>
                                    <h4 class="overline font-weight-black font-italic">
                                      Faixa de Assinaturas Captadas
                                    </h4>
                                    <v-divider></v-divider>
                                  </v-flex>
                                  <v-flex xs12 md6>
                                    <v-text-field
                                      autofocus
                                      class="ml-4"
                                      v-model="metaItem.de"
                                      label="De"
                                      v-mask="'##'"
                                      hint="Obrigatório"
                                      :rules="[value => (value > 0 && value <= 99) || 'Faixa inválida']"
                                      color="blue-grey lighten-2"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      :readonly="metaItem.id != null"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12 md6>
                                    <v-text-field
                                      class="mr-4"
                                      v-model="metaItem.ate"
                                      label="Até"
                                      v-mask="'###'"
                                      hint="Obrigatório - use 999 para finalizar"
                                      :rules="[value => (value > 0 && value <= 999) || 'Faixa inválida']"
                                      color="blue-grey lighten-2"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      :readonly="metaItem.id != null"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12 md6>
                                    <v-currency-field
                                      :locale="`pt-${$store.state.usuario.site_pais}`"
                                      v-model="metaItem.vlrUnitario"
                                      label="Valor Unitário"
                                      :allowNegative="false"
                                      :max="99"
                                      hint="Valor a ser multiplicado pelo número de assinaturas captadas"
                                      :rules="[value => !!value || 'Obrigatório.', value => (value && value.length < 100) || 'Valor Unitário inválido']"
                                      color="blue-grey lighten-2"
                                      :readonly="metaItem.id != null"
                                    ></v-currency-field>
                                  </v-flex>
                                  <v-flex xs12 md6>
                                    <v-currency-field
                                      :locale="`pt-${$store.state.usuario.site_pais}`"
                                      v-model="metaItem.vlrBonus"
                                      label="Valor Bônus"
                                      :allowNegative="false"
                                      :max="9999"
                                      hint="Valor fixo do bônus ao atingir a meta de captações"
                                      :rules="[value => !!value || 'Obrigatório.', value => (value && value.length < 10000) || 'Valor do bônus inválido']"
                                      color="blue-grey lighten-2"
                                      :readonly="metaItem.id != null"
                                    ></v-currency-field>
                                  </v-flex>
                                  <v-flex xs12>
                                    <v-alert v-if="validacao.meta" :value="true" color="error" icon="priority_high" outlined>{{ validacao.meta }}</v-alert>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" @click="closeMeta">Retornar</v-btn>
                              <v-btn v-if="metaItem.id == null" color="blue darken-1" @click="saveMeta">Atualizar</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="headerMetas"
                      :items="metasRegra"
                      no-data-text
                      no-results-text
                      :footer-props="{
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageOptions: [10, 25, 50],
                        pageText: ''
                      }"
                    >
                      <template v-slot:item.actions="{ item }">
                        <v-icon class="mr-2" color="blue lighten-2" :title="item.id == null ? 'Editar' : 'Visualizar'" @click="editarMeta(item)">{{
                          item.id == null ? "edit" : "loupe"
                        }}</v-icon>
                        <v-icon
                          :color="item.situacao == 1 ? 'blue lighten-1' : 'blue-grey lighten-3'"
                          :title="item.situacao == 1 ? 'Desativar' : 'Desativada'"
                          @click="desativarMeta(item)"
                        >
                          {{ item.situacao == 1 ? "radio_button_checked" : "radio_button_unchecked" }}
                        </v-icon>
                      </template>

                      <template v-slot:item.vlrUnitario="{ item }">
                        {{ formatCurrency(item.vlrUnitario) }}
                      </template>
                      <template v-slot:item.vlrBonus="{ item }">
                        {{ formatCurrency(item.vlrBonus) }}
                      </template>
                      <template v-slot:item.dtRegistro="{ item }">
                        {{ formatDateTime(item.dtRegistro) }}
                      </template>
                      <template v-slot:item.dtExclusao="{ item }">
                        {{ formatDateTime(item.dtExclusao) }}
                      </template>
                    </v-data-table>
                    <v-snackbar v-model="snackMetas" :timeout="3000" absolute bottom left>{{ msgMetas }}</v-snackbar>
                    <v-alert v-if="validacao.metas" :value="true" color="error" icon="priority_high" outlined>{{ validacao.metas }}</v-alert>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.agentes ? 'red--text' : ''">
                <strong>Agentes/Agências</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout wrap>
                  <v-flex xs12 md6>
                    <v-autocomplete
                      v-model="agentesSelecionados"
                      autofocus
                      label="Incluir Agente/Agência"
                      :disabled="isUpdating"
                      :items="agentes"
                      :filter="filtrarObjetos"
                      item-text="nome"
                      item-value="id"
                      autocomplete="disabled"
                      :name="Math.random()"
                      no-data-text
                      return-object
                      multiple
                      @dblclick.stop="selectAllAgentes"
                    >
                      <template v-slot:selection="data">
                        <span>{{ (agentesSelecionados.length > 10 ? abreviaNome(data.item.nome) : data.item.nome) + (agentesSelecionados.length > 1 ? ",⠀" : "") }}</span>
                      </template>
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs1>
                    <v-btn class="mt-2" color="green" :disabled="isUpdating" fab small @click="incluirAgentes">
                      <v-icon color="white" title="Incluir">add</v-icon>
                    </v-btn>
                  </v-flex>

                  <v-spacer></v-spacer>
                  <v-flex md4>
                    <v-text-field
                      background-color="white"
                      class="mt-2 mb-2 elevation-0"
                      v-model="searchAgentes"
                      append-icon="search"
                      label="Digite para pesquisar..."
                      placeholder="Pesquisa pelo nome"
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md12>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="headerAgentes"
                      :items="agentesRegra"
                      :search="searchAgentes"
                      no-data-text
                      no-results-text
                      :footer-props="{
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageOptions: [10, 25, 50],
                        pageText: ''
                      }"
                    >
                      <template v-slot:item.actions="{ item }">
                        <v-icon
                          :color="item.situacao == 1 ? 'blue lighten-1' : 'blue-grey lighten-3'"
                          :title="item.situacao == 1 ? 'Desativar' : 'Desativado'"
                          @click="desativarAgente(item)"
                        >
                          {{ item.situacao == 1 ? "radio_button_checked" : "radio_button_unchecked" }}
                        </v-icon>
                      </template>

                      <template v-slot:item.agenteSituacao="{ item }">
                        <span v-html="item.agenteSituacao == 1 ? 'Ativo' : item.agenteSituacao == 0 ? '<strong>Inativo</strong>' : ''"></span>
                      </template>
                      <template v-slot:item.dtRegistro="{ item }">
                        {{ formatDateTime(item.dtRegistro) }}
                      </template>
                      <template v-slot:item.dtExclusao="{ item }">
                        {{ formatDateTime(item.dtExclusao) }}
                      </template>

                      <template v-slot:no-results>
                        <v-alert :value="true" color="error" outlined icon="warning">"{{ searchAgentes }}" não encontrado.</v-alert>
                      </template>
                    </v-data-table>
                  </v-flex>
                  <v-alert v-if="validacao.agentes" :value="true" color="error" icon="priority_high" outlined>{{ validacao.agentes }}</v-alert>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn to="/assinaturas/regras-comissionamento" id="btn-rsn" color="orange lighten-2 white" style="color:white">
        <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar
      </v-btn>
      <v-btn :loading="isUpdating" color="blue white" style="color:white" id="btn-rsn" depressed @click="salvar('false')"> <v-icon id="icon-rsn" left>update</v-icon>Salvar </v-btn>
      <v-btn :loading="isUpdating" color="orange white" style="color:white" id="btn-rsn" outlined @click="salvar('retornar')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>
      <v-btn :loading="isUpdating" color="blue white" style="color:white" id="btn-rsn" outlined @click="salvar('true')" v-if="direitoCriar">
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Novo
      </v-btn>
    </v-card-actions>

    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      regraComissionamento: {
        id: "",
        idSite: null,
        nome: "",
        meta: 0,
        percNova: 0,
        vlrNova: 0,
        percRenovada: 0,
        vlrRenovada: 0,
        fatorDigital: 1,
        situacao: 1
      },
      validacao: {
        id: false,
        idSite: false,
        nome: false,
        meta: false,
        percNova: false,
        vlrNova: false,
        percRenovada: false,
        vlrRenovada: false,
        fatorDigital: false,
        situacao: false,
        metas: false,
        meta: false,
        agentes: false,
        regra_usuario: false,
        mensagemErro: false
      },

      siteGrupo: [],
      metasRegra: [],
      agentesRegra: [],
      agentes: [],

      headerMetas: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "De", value: "de" },
        { text: "Até", value: "ate" },
        { text: "Valor Unitário", value: "vlrUnitario" },
        { text: "Valor Bônus", value: "vlrBonus" },
        { text: "Registro", value: "dtRegistro" },
        { text: "Desativado em", value: "dtExclusao" }
      ],
      headerAgentes: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Agente", value: "agente" },
        { text: "Situação", value: "agenteSituacao" },
        { text: "Registro", value: "dtRegistro" },
        { text: "Desativado em", value: "dtExclusao" }
      ],

      dialogMeta: false,
      metaValid: true,
      metaIndex: -1,
      metaItem: {
        id: null,
        de: 0,
        ate: 0,
        vlrUnitario: 0,
        vlrBonus: 0,
        situacao: 1
      },
      defaultMetaItem: {
        id: null,
        de: 0,
        ate: 0,
        vlrUnitario: 0,
        vlrBonus: 0,
        situacao: 1
      },
      msgMetas: "",
      snackMetas: false,

      agentesSelecionados: [],
      searchAgentes: "",

      painel: [0, 0, 0],
      idSitePrincipal: null,
      direitoCriar: false,
      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false
    };
  },
  watch: {
    dialogMeta(val) {
      val || this.closeMeta();
    }
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      if (this.regraComissionamento.meta) {
        // this.regraComissionamento.percNova = 0;
        // this.regraComissionamento.percRenovada = 0;
        // this.regraComissionamento.vlrNova = 0;
        // this.regraComissionamento.vlrRenovada = 0;

        var itensVal = [];
        var itemDuplicado = "";
        this.metasRegra.forEach(element => {
          if (element.situacao) {
            var valores = [];
            var de = parseInt(element.de);
            var ate = parseInt(element.ate);
            for (var i = de; i <= ate; i++) {
              valores.push(i);
            }
            valores.forEach(valor => {
              if (itensVal.includes(valor)) {
                itemDuplicado = valor;
              } else {
                itensVal.push(valor);
              }
            });
          }
        });
        if (itemDuplicado) {
          this.mensagem = "Já existe uma meta ativa para o item [" + itemDuplicado + "]";
          this.snackErro = true;
          return;
        }
      } else {
        this.regraComissionamento.fatorDigital = 1;
        this.metasRegra = [];
      }

      if (!this.regraComissionamento.percNova && !this.regraComissionamento.vlrNova && !this.regraComissionamento.percRenovada && !this.regraComissionamento.vlrRenovada) {
        this.mensagem = "Preencha pelo menos 1 percentual ou valor fixo";
        this.snackErro = true;
        return;
      }
      if (this.regraComissionamento.percNova && this.regraComissionamento.vlrNova) {
        this.mensagem = "Preencha somente um campo para comissionamento de Assinatura Nova: Percentual ou Valor";
        this.snackErro = true;
        return;
      }
      if (this.regraComissionamento.percRenovada && this.regraComissionamento.vlrRenovada) {
        this.mensagem = "Preencha somente um campo para comissionamento de Assinatura Renovada: Percentual ou Valor";
        this.snackErro = true;
        return;
      }

      this.regraComissionamento.metas = this.metasRegra;
      this.regraComissionamento.agentes = this.agentesRegra;

      this.isUpdating = true;
      try {
        var retorno = "";
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "ass/regra-comissao/" + this.id, this.regraComissionamento);
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "ass/regra-comissao?idSite=" + this.idSitePrincipal, this.regraComissionamento);
          this.id = retorno.data.id;
          this.regraComissionamento.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        this.carregarMetasRegra();
        this.carregarAgentesRegra();
        if (novo == "true") {
          this.$router.push("/assinaturas/regra-comissionamento");
          this.$router.go("/assinaturas/regra-comissionamento");
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push("/assinaturas/regras-comissionamento");
          }, 2000);
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },

    editarMeta(item) {
      this.metaIndex = this.metasRegra.indexOf(item);
      this.metaItem = Object.assign({}, item);
      this.validacao.meta = "";
      this.dialogMeta = true;
    },
    desativarMeta(item) {
      this.metaItem = Object.assign({}, item);
      this.metaIndex = this.metasRegra.indexOf(item);
      if (this.metaItem.id == null) {
        this.metasRegra.splice(this.metaIndex, 1);
        this.metaItem = Object.assign({}, this.defaultMetaItem);
        this.metaIndex = -1;
      } else {
        if (this.metaItem.situacao) {
          this.metaItem.situacao = 0;
          this.metaItem.dtExclusao = new Date();
        } else {
          this.metaItem.situacao = 1;
          this.metaItem.dtExclusao = null;
        }
        this.desativaMeta();
      }
    },
    async desativaMeta() {
      var situacao = { situacao: this.metaItem.situacao };
      try {
        await api.put(process.env.VUE_APP_URL_WEB_API + "ass/regra-comissao-meta/situacao/" + this.metaItem.id, situacao);

        Object.assign(this.metasRegra[this.metaIndex], this.metaItem);

        this.metaItem = Object.assign({}, this.defaultMetaItem);
        this.metaIndex = -1;

        this.msgMetas = "Item " + (situacao ? "Ativado" : "Desativado");
        this.snackMetas = true;
      } catch (e) {
        this.msgMetas =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackMetas = true;
      }
    },
    closeMeta() {
      this.dialogMeta = false;
      setTimeout(() => {
        this.metaItem = Object.assign({}, this.defaultMetaItem);
        this.metaIndex = -1;
        this.$refs.formMeta.resetValidation();
      }, 300);
    },
    saveMeta() {
      if (this.$refs.formMeta.validate()) {
        if (this.metaItem.de > this.metaItem.ate) {
          this.validacao.meta = "Faixa final (Até) não pode ser inferior a faixa inicial (De)";
          return;
        }
        if (!this.metaItem.vlrUnitario && !this.metaItem.vlrBonus) {
          this.validacao.meta = "Valor Unitário ou Valor do Bônus para a Faixa deve ser informado";
          return;
        }
        this.metaItem.dtRegistro = new Date();
        if (this.metaIndex > -1) {
          Object.assign(this.metasRegra[this.metaIndex], this.metaItem);
        } else {
          this.metasRegra.push(this.metaItem);
        }
        this.closeMeta();
        this.metaItem = Object.assign({}, this.defaultMetaItem);
        this.metaIndex = -1;
        this.$refs.formMeta.resetValidation();
        this.validacao.metas = false;
      }
    },

    desativarAgente(item) {
      this.agenteItem = Object.assign({}, item);
      this.agenteIndex = this.agentesRegra.indexOf(item);
      if (this.agenteItem.id == null) {
        this.agentesRegra.splice(this.agenteIndex, 1);
        this.agenteItem = Object.assign({}, this.defaultAgenteItem);
        this.agenteIndex = -1;
      } else {
        if (this.agenteItem.situacao) {
          this.agenteItem.situacao = 0;
          this.agenteItem.dtExclusao = new Date();
        } else {
          this.agenteItem.situacao = 1;
          this.agenteItem.dtExclusao = null;
        }
        this.desativaAgente();
      }
    },
    async desativaAgente() {
      var situacao = { situacao: this.agenteItem.situacao };
      try {
        await api.put(process.env.VUE_APP_URL_WEB_API + "ass/regra-comissao-agente/situacao/" + this.agenteItem.id, situacao);
        Object.assign(this.agentesRegra[this.agenteIndex], this.agenteItem);

        this.agenteItem = Object.assign({}, this.defaultAgenteItem);
        this.agenteIndex = -1;

        this.mensagem = "Item " + (situacao ? "Ativado" : "Desativado");
        this.snackbar = true;
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },

    async carregarAgentes() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-agente/lista?idSite=" + this.idSitePrincipal);
        if (response.data) {
          this.agentes = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de agentes";
        this.snackErro = true;
      }
    },
    async carregarMetasRegra() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/regra-comissao-meta/regra/" + this.regraComissionamento.id);
        if (response.data) {
          this.metasRegra = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as metas da regra de comissionamento";
        this.snackErro = true;
      }
    },
    async carregarAgentesRegra() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/regra-comissao-agente/regra/" + this.regraComissionamento.id);
        if (response.data) {
          this.agentesRegra = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os agentes da regra de comissionamento";
        this.snackErro = true;
      }
    },

    incluirAgentes() {
      var msgErro = "";
      this.agentesSelecionados.forEach(agente => {
        var incluido = false;
        this.agentesRegra.forEach(element => {
          if (agente.id == element.idAgente) {
            incluido = true;
            msgErro += agente.nome + " | ";
          }
        });
        if (!incluido) {
          this.agentesRegra.push({
            id: null,
            idAgente: agente.id,
            agente: agente.nome,
            agenteSituacao: agente.situacao,
            dtRegistro: new Date(),
            situacao: 1
          });
        }
      });
      this.agentesSelecionados = [];
      if (msgErro) {
        this.mensagem = msgErro + " já incluído(s)";
        this.snackErro = true;
      }
    },
    selectAllAgentes() {
      this.agentes.forEach(element => {
        this.agentesSelecionados.push(element);
      });
    },

    abreviaNome(nome) {
      return util.abreviaNome(nome);
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    pLetra(text) {
      return util.pLetra(text);
    },
    valida_nome(text) {
      return util.valida_nome(text);
    },
    formatDateTime(datetime) {
      return util.formatDateTime(datetime);
    },
    formatCurrency(valor) {
      return util.formatCurrency(valor);
    }
  },

  async created() {
    this.id = this.$route.params.id ? this.$route.params.id : "";

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_REGRAS_COMISSIONAMENTO_AGENTES_CRIAR") {
        this.direitoCriar = true;
      }
    });

    //* Verifica se o site do usuário está em GRUPO - OBS: ou ele compartilha a informação ou deixa exclusiva para o seu site
    var idGrupoSite = this.$store.state.usuario.site_idGrupo;
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
    if (idGrupoSite != null) {
      this.siteGrupo = [
        {
          id: this.idSitePrincipal,
          nome: this.$store.state.usuario.site_nome
        }
      ];
    }
    await this.carregarAgentes();

    try {
      //* Se é edição, carrega o item
      if (this.id == "") {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_REGRAS_COMISSIONAMENTO_AGENTES_CRIAR"]);
      } else {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_REGRAS_COMISSIONAMENTO_AGENTES_EDITAR"]);
        //* Carrega o item - somente se for público (idSite=null) ou for do site do usuário logado
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/regra-comissao/" + this.id + "?idSite=" + this.idSitePrincipal);
        if (!response.data) {
          this.$router.push("/assinaturas/regras-comissionamento");
        } else {
          this.regraComissionamento = response.data;

          await this.carregarMetasRegra();
          await this.carregarAgentesRegra();
        }
      }
    } catch {
      this.mensagem = "Ocorreu um erro ao carregar os dados";
      this.snackErro = true;
    }
  }
};
</script>
