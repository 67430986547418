<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">dashboard</v-icon>
      <v-toolbar-title style="color: white" class="font-weight-light">Paywall / Plano</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <!-- <v-flex xs12 md12 v-if="siteGrupo.length > 0">
            <v-autocomplete
              v-model="paywallPlano.idSite"
              :disabled="isUpdating"
              :items="siteGrupo"
              :filter="filtrarObjetos"
              label="Restrito ao Site"
              item-text="nome"
              item-value="id"
              clearable
              no-data-text
            ></v-autocomplete>
          </v-flex> -->

          <v-flex xs12 md12>
            <v-text-field
              autofocus
              v-model="paywallPlano.nome"
              label="Nome do Plano"
              maxlength="50"
              hint="Obrigatório"
              :rules="[validacao.nome == false || validacao.nome, value => !!value || 'Obrigatório.', value => value.length > 2 || 'Preencha o nome do plano']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="
                paywallPlano.nome = pLetra(paywallPlano.nome);
                validacao.nome = false;
              "
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md6>
            <v-text-field
              v-model="paywallPlano.url"
              label="Url"
              maxlength="50"
              hint="Obrigatório"
              :rules="[validacao.url == false || validacao.url, value => !!value || 'Obrigatório.', value => value.length > 2 || 'Preencha a url do plano']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="
                paywallPlano.url = paywallPlano.url.toLowerCase();
                validacao.url = false;
              "
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md3>
            <v-text-field
              v-model="paywallPlano.ordem"
              label="Ordem"
              maxlength="2"
              v-mask="'##'"
              hint="Obrigatório"
              :rules="[validacao.ordem == false || validacao.ordem, value => !!value || 'Obrigatório.', value => (value && parseInt(value) < 100) || 'Verifique a ordem']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.ordem = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md3>
            <v-text-field
              v-model="paywallPlano.degustacao"
              label="Degustação (dias)"
              maxlength="2"
              v-mask="'##'"
              hint="Obrigatório - use zero para 'sem período de degustação'"
              :rules="[validacao.degustacao == false || validacao.degustacao, value => (value != null && parseInt(value) < 31) || 'Verifique o número de dias de degustação']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.degustacao = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md12>
            <v-autocomplete
              v-model="paywallPlano.idAgente"
              :items="agentes"
              :filter="filtrarObjetos"
              hint="Obrigatório"
              :rules="[validacao.idAgente == false || validacao.idAgente, value => !!value || 'Obrigatório.']"
              label="Agente"
              item-text="nome"
              item-value="id"
              no-data-text
              @change="validacao.idAgente = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md12>
            <v-autocomplete
              v-model="paywallPlano.idPlano"
              :items="planos"
              :filter="filtrarObjetos"
              hint="Obrigatório"
              :rules="[validacao.idPlano == false || validacao.idPlano, value => !!value || 'Obrigatório.']"
              label="Plano"
              item-text="nome"
              item-value="id"
              no-data-text
              @change="validacao.idPlano = false"
              append-icon="dashboard"
              @click:append="visualizarPlano"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md12>
            <v-textarea
              v-model="paywallPlano.descricaoHtml"
              label="Descrição em html do plano"
              maxlength="4000"
              counter="4000"
              rows="10"
              hint="Obrigatório"
              :rules="[validacao.descricaoHtml == false || validacao.descricaoHtml, value => !!value || 'Obrigatório.']"
              color="blue-grey lighten-2"
              @change="validacao.descricaoHtml = false"
            ></v-textarea>
          </v-flex>

          <v-flex xs12 md3>
            <v-switch v-model="paywallPlano.situacao" :label="`${paywallPlano.situacao == true ? 'Ativo' : 'Inativo'}`"></v-switch>
          </v-flex>

          <v-flex xs12 md3>
            <v-switch
              v-model="paywallPlano.site"
              :label="`${paywallPlano.site == true ? 'Plano Site' : 'Não é plano site'}`"
              :rules="[validacao.site == false || validacao.site]"
            ></v-switch>
          </v-flex>
          <v-flex xs12 md3>
            <v-switch
              v-model="paywallPlano.android"
              :label="`${paywallPlano.android == true ? 'Plano Android' : 'Não é plano Android'}`"
              :rules="[validacao.android == false || validacao.android]"
            ></v-switch>
          </v-flex>
          <v-flex xs12 md3>
            <v-switch
              v-model="paywallPlano.ios"
              :label="`${paywallPlano.ios == true ? 'Plano iOS' : 'Não é plano iOS'}`"
              :rules="[validacao.ios == false || validacao.ios]"
            ></v-switch>
          </v-flex>
          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">
              {{ validacao.mensagemErro }}
            </v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn to="/assinaturas/paywall/planos" id="btn-rsn" color="orange lighten-2 white" style="color: white">
        <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar
      </v-btn>
      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="blue white" id="btn-rsn" style="color: white" depressed @click="salvar('false')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar
      </v-btn>

      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="orange white" style="color: white" id="btn-rsn" outlined @click="salvar('retornar')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>
      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="blue white" id="btn-rsn" style="color: white" outlined @click="salvar('true')" v-if="direitoCriar">
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Novo
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      paywallPlano: {
        id: "",
        // idSite: null,
        nome: "",
        idAgente: null,
        url: "",
        idPlano: null,
        descricaoHtml: "",
        situacao: 0,
        site: 0,
        android: 0,
        ios: 0,
        degustacao: 0,
        ordem: 1
      },
      validacao: {
        id: false,
        // idSite: false,
        nome: false,
        idAgente: false,
        url: false,
        idPlano: false,
        descricaoHtml: false,
        situacao: false,
        site: false,
        android: false,
        ios: false,
        degustacao: false,
        mensagemErro: false,
        ordem: false
      },

      planos: [],
      agentes: [],
      // siteGrupo: [],

      direitoCriar: false,
      direitoPlanos: false,
      elementoValid: true,
      id: "",

      idSitePrincipal: null,
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false,
      bloqueiaBotoes: false
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      this.isUpdating = true;
      try {
        var retorno = "";

        //se tiver id é um update
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "ass/paywall/plano/" + this.id, this.paywallPlano);
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "ass/paywall/plano?idSite=" + this.idSitePrincipal, this.paywallPlano);
          this.id = retorno.data.id;
          this.paywallPlano.id = retorno.data.id;
        }
        if (retorno.data.erro) {
          throw retorno;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "true") {
          this.$router.push("/assinaturas/paywall/plano");
          this.$router.go("/assinaturas/paywall/plano");
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push("/assinaturas/paywall/planos");
          }, 2000);
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },

    async carregarPlanos() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/plano/lista?idSite=" + this.idSitePrincipal);
        if (response.data) {
          this.planos = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de planos de assinatura";
        this.snackErro = true;
      }
    },
    async carregarAgentes() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-agente/lista?idSite=" + this.idSitePrincipal);
        if (response.data) {
          this.agentes = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de agentes de venda";
        this.snackErro = true;
      }
    },

    visualizarPlano() {
      if (this.direitoPlanos) {
        window.open("/assinaturas/plano/" + this.paywallPlano.idPlano, "_blank");
      }
    },

    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    pLetra(text) {
      return util.pLetra(text);
    }
  },

  async created() {
    this.isUpdating = false;
    this.bloqueiaBotoes = true;
    this.id = this.$route.params.id ? this.$route.params.id : "";

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_PAYWALL_PLANOS_CRIAR") {
        this.direitoCriar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_PLANOS_EDITAR") {
        this.direitoPlanos = true;
      }
    });

    if (!this.$store.state.usuario.site_gateway) {
      this.$router.push("/assinaturas/paywall/planos");
      return;
    }

    //* Verifica se o site do usuário está em GRUPO - OBS: ou ele compartilha a informação ou deixa exclusiva para o seu site
    // var idGrupoSite = this.$store.state.usuario.site_idGrupo;
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
    // if (idGrupoSite != null) {
    //   this.siteGrupo = [
    //     {
    //       id: this.idSitePrincipal,
    //       nome: this.$store.state.usuario.site_nome
    //     }
    //   ];
    // }

    await this.carregarAgentes();
    await this.carregarPlanos();

    try {
      //* Se é edição, carrega o elemento
      if (this.id == "") {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_PAYWALL_PLANOS_CRIAR"]);
      } else {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_PAYWALL_PLANOS_EDITAR"]);

        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/paywall/plano/" + this.id + "&idSite=" + this.idSitePrincipal);

        if (!response.data) {
          this.$router.push("/assinaturas/paywall/planos");
        } else {
          this.paywallPlano = response.data;
        }
      }
    } catch {
      this.mensagem = "Ocorreu um erro ao carregar os dados do plano paywall";
      this.snackErro = true;
    }

    setTimeout(() => {
      this.bloqueiaBotoes = false;
    }, 1000);
  }
};
</script>
