<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">mdi-account</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">
        {{
          (idParcela ? "Parcela " + idParcela : "") +
            (idAssinatura ? " | Assinatura " + idAssinatura : "") +
            (idAnuncio ? " | Anúncio " + idAnuncio : "") +
            (idPacote ? " | Pacote " + idPacote : "") +
            (idOperacao ? " | " + (origem == "AVU" ? "Venda Avulsa " : " Operação ") + idOperacao : "")
        }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="retornar(false)">
        <v-icon color="white">cancel</v-icon>
      </v-btn>
    </v-toolbar>

    <v-container fluid grid-list-md>
      <v-layout row wrap>
        <v-expansion-panels focusable v-model="painel">
          <v-expansion-panel dark class="elevation-0">
            <v-divider></v-divider>

            <v-expansion-panel-header :class="validacao.telefones ? 'red--text' : ''">
              <strong>Telefones para Contato</strong>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-flex xs12 md12>
                <v-data-table
                  mobile-breakpoint="0"
                  :headers="headerTelefones"
                  :items="telefonesCliente"
                  hide-default-footer
                  no-data-text
                  :footer-props="{
                    itemsPerPageOptions: [50],
                    pageText: ''
                  }"
                  height="300"
                  fixed-header
                >
                  <template v-slot:[getItemTipo()]="{ item }">
                    <v-icon color="blue lighten-2" class="mr-2" v-if="item.comercial == 1" title="Telefone Comercial">contact_phone</v-icon>

                    <v-icon color="blue lighten-2" class="mr-2" v-if="item.residencial == 1" title="Telefone Residencial">home</v-icon>
                    <v-icon color="blue lighten-2" v-if="item.celular == 1" title="Telefone Celular">smartphone</v-icon>
                    <a v-if="item.comercial == 1 || item.residencial == 1 || item.celular == 1" v-bind:href="'tel:' + item.ddd + item.numero">
                      <v-icon color="blue" title="Discagem">phone_in_talk</v-icon>
                    </a>
                  </template>
                </v-data-table>
              </v-flex>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel dark class="elevation-0">
            <v-divider></v-divider>
            <v-expansion-panel-header v-if="idAssinatura || idAnuncio || idPacote || idOperacao" :class="validacao.mensagemErro ? 'red--text' : ''">
              <strong>
                Novo Contato de
                {{ contato.tipo == 1 ? "Recuperação" : "Cobrança" }}
              </strong>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
                <v-card-text>
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12>
                        <v-text-field v-model="nomeCliente" label="Cliente" readonly color="blue-grey lighten-2"></v-text-field>
                      </v-flex>
                      <v-flex xs12 md12 v-if="contato.tipo == 1 && direitoRecuperar">
                        <v-switch
                          v-model="contato.recuperado"
                          :disabled="isUpdating"
                          :label="`${contato.recuperado == 1 ? 'Recuperado' : 'Não Recuperado'}`"
                          :rules="[validacao.recuperado == false || validacao.recuperado]"
                        ></v-switch>
                      </v-flex>
                      <v-flex xs6 v-if="contato.tipo == 2 && direitoPrometer">
                        <v-menu
                          ref="mnuDtPromessa"
                          v-model="mnuDtPromessa"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="dtPromessa"
                              label="Promessa de Pagamento"
                              v-mask="'##/##/####'"
                              :rules="[validacao.dtPromessa == false || validacao.dtPromessa, value => valida_data(value) || 'Data inválida']"
                              :disabled="isUpdating"
                              prepend-icon="event"
                              v-on="on"
                              color="blue-grey lighten-2"
                              autocomplete="disabled"
                              :name="Math.random()"
                              @keyup="dtPromessaUS = valida_data(dtPromessa) ? formatDateUS(dtPromessa) : null"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="dtPromessaUS" locale="pt-br" no-title @input="mnuDtPromessa = false"></v-date-picker>
                        </v-menu>
                      </v-flex>
                      <v-flex xs12 md12>
                        <v-textarea
                          v-model="contato.contato"
                          autofocus
                          :disabled="isUpdating"
                          label="Descrição do Contato"
                          rows="3"
                          maxlength="255"
                          counter="255"
                          hint="Obrigatório"
                          :rules="[
                            validacao.contato == false || validacao.contato,
                            value => !!value || 'Obrigatório.',
                            value => (value != null && value.length > 10 && value.length < 256) || 'Texto do contato deve ter entre 10 e 255 caracteres'
                          ]"
                          color="blue-grey lighten-2"
                        ></v-textarea>
                      </v-flex>
                      <v-flex xs12 v-if="validacao.mensagemErro">
                        <v-sheet :value="true" class="red--text">
                          {{ validacao.mensagemErro }}
                        </v-sheet>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue white" style="color:white" depressed @click="salvar" :loading="isUpdating" :disabled="bloqueiaBotoes" v-if="direitoCriar">Salvar</v-btn>
                </v-card-actions>
              </v-form>
              <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
              <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel dark class="elevation-0">
            <v-divider></v-divider>
            <v-expansion-panel-header>
              <strong>
                Histórico de Contatos de
                {{ contato.tipo == 1 ? "Recuperação da Assinatura " + idAssinatura : "Cobrança da Parcela " + idParcela }}
              </strong>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                <v-data-table
                  mobile-breakpoint="0"
                  :headers="headerContatos"
                  :items="contatos"
                  no-data-text
                  hide-default-footer
                  no-results-text
                  :footer-props="{
                    itemsPerPageOptions: [200],
                    pageText: ''
                  }"
                  height="300"
                  fixed-header
                >
                  <template v-slot:[getItemRecuperado()]="{ item }">
                    <span v-html="item.recuperado ? 'Recuperado' : ''"></span>
                  </template>

                  <template v-slot:[getItemBloqueado()]="{ item }">
                    <span v-html="item.bloqueado ? 'Bloqueado' : ''"></span>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel dark class="elevation-0">
            <v-divider></v-divider>
            <v-expansion-panel-header v-if="idAssinatura || idAnuncio || idPacote || idOperacao">
              <strong
                >Parcelas
                {{
                  idAssinatura
                    ? "da Assinatura " + idAssinatura
                    : idAnuncio
                    ? "do Anúncio " + idAnuncio
                    : idPacote
                    ? "do Pacote " + idPacote
                    : idOperacao
                    ? (origem == "AVU" ? "da Venda Avulsa " : "da Operação ") + idOperacao
                    : ""
                }}</strong
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                <v-data-table
                  mobile-breakpoint="0"
                  :headers="headerParcelas"
                  :items="parcelas"
                  no-data-text
                  no-results-text
                  :footer-props="{
                    itemsPerPageOptions: [1000],
                    pageText: ''
                  }"
                  hide-default-footer
                  height="300"
                  fixed-header
                >
                  <template v-slot:[getItemStatus()]="{ item }">
                    <span
                      :class="
                        item.status == 'ABERTA'
                          ? 'blue--text'
                          : item.status == 'BLOQUEADA'
                          ? 'orange--text'
                          : item.status == 'CANCELADA'
                          ? 'red--text'
                          : item.status == 'QUITADA'
                          ? 'green--text'
                          : ''
                      "
                      >{{ item.status }}</span
                    >
                  </template>
                </v-data-table>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-layout>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="orange lighten-2 white" style="color:white" @click="retornar(false)"> <v-icon left>keyboard_arrow_left</v-icon>Retornar </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
export default {
  props: {
    idEtapa: String,
    idParcela: String,
    idAssinatura: String,
    idRenovacao: String,
    idAnuncio: String,
    idPacote: String,
    idOperacao: String,
    idCliente: String,
    cliente: String,
    tipo: String,
    origem: String
  },

  data() {
    return {
      headerContatos: [
        { text: "ID", value: "id" },
        { text: "Usuário", value: "usuario" },
        { text: "Registro", value: "dtRegistro" },
        { text: "Recuperação", value: "recuperado" },
        { text: "Promessa", value: "dtPromessa" },
        { text: "Bloqueio", value: "bloqueado" },
        { text: "Descrição do Contato", value: "contato" }
      ],
      headerTelefones: [
        { text: "Tipo", value: "tipo" },
        { text: "DDD", value: "ddd" },
        { text: "Número", value: "numero" }
      ],

      headerParcelas: [
        { text: "ID", value: "id" },
        { text: "Índice", value: "indice" },
        { text: "Parcela", value: "numero" },
        { text: "Status", value: "status" },
        { text: "Forma de Pagamento", value: "formaPagamento" },
        { text: "Vencimento", value: "dtVectoOriginal" },
        { text: "Promessa", value: "dtPromessa" },
        { text: "Valor", value: "valor", align: "end" }
      ],

      nomeCliente: "",
      painel: 1,

      parcelas: [],
      contatos: [],
      contato: {},

      validacao: {
        dtPromessa: false,
        bloqueado: false,
        recuperado: false,
        contato: false,
        mensagemErro: false,
        telefones: false
      },

      telefonesCliente: [],

      dtPromessa: null,
      dtPromessaUS: null,
      mnuDtPromessa: false,

      direitoCriar: false,
      direitoRecuperar: false,
      direitoPrometer: false,
      direitoBloquear: false,

      idSitePrincipal: null,
      elementoValid: true,
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false,
      bloqueiaBotoes: false
    };
  },

  methods: {
    getItemTipo() {
      return "item.tipo";
    },
    getItemRecuperado() {
      return "item.recuperado";
    },
    getItemBloqueado() {
      return "item.bloqueado";
    },
    getItemStatus() {
      return "item.status";
    },
    async salvar() {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      if (this.dtPromessa) {
        if (!util.valida_data_futura(util.formatDateUS(this.dtPromessa), true)) {
          this.mensagem = "Data de promessa inválida";
          this.snackErro = true;
          return;
        }
        this.contato.dtPromessa = util.formatDateUS(this.dtPromessa);
      } else {
        this.contato.dtPromessa = null;
      }

      if (this.idAssinatura) {
        this.contato.origem = "ASS";
      } else if (this.idAnuncio) {
        this.contato.origem = "ANU";
      } else if (this.idPacote) {
        this.contato.origem = "PAC";
      } else {
        this.contato.origem = this.origem;
      }
      this.isUpdating = true;
      try {
        //* Registra o contato
        await api.post(process.env.VUE_APP_URL_WEB_API + "fin/contato?idSite=" + this.idSitePrincipal, this.contato);

        if (this.tipo == 2) {
          if (this.contato.dtPromessa) {
            var promessa = { dtPromessa: this.contato.dtPromessa };
            //* Atualiza a data de promessa na Parcela (se não estiver quitada ou cancelada)
            await api.put(process.env.VUE_APP_URL_WEB_API + "fin/parcela/promessa/" + this.idParcela, promessa);
          }
        } else {
          if (this.contato.recuperado) {
            //* Atualiza o status da Assinatura para CORRENTE (se estiver como RECUPERAÇÃO)
            //* Busca o último registro da tabela assinatura_cancelamento e seta dtRecuperacao
            var objetoRecuperacao = {
              idUsuarioRecuperacao: this.$store.state.usuario.id,
              motivoRecuperacao: this.contato.contato
            };
            await api.put(process.env.VUE_APP_URL_WEB_API + "ass/assinatura/recuperado/" + this.idAssinatura, objetoRecuperacao);
          }
        }

        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        setTimeout(() => {
          this.retornar(null);
        }, 1500);
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },

    retornar(valor) {
      this.$emit("update:dialogContato", valor);
    },
    async carregarContatos() {
      this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
      this.isUpdating = false;
      this.bloqueiaBotoes = true;
      try {
        var responseContatos = [];
        if (this.idAssinatura || this.idParcela) {
          if (this.tipo == "2") {
            //* Carrega os contatos de cobrança
            responseContatos = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/contato/parcela/" + this.idParcela);
          } else {
            // * Carrega os contatos de recuperação
            responseContatos = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/contato/assinatura/" + this.idAssinatura + "?recuperacao=1");
          }
          this.contatos = responseContatos.data;
          this.contatos.forEach(element => {
            element.dtRegistro = util.formatDateTime(element.dtRegistro);
            element.dtPromessa = util.formatDate(element.dtPromessa);
          });

          this.dtPromessa = null;
          this.dtPromessaUS = null;
          this.contato = {
            id: null,
            idEtapa: this.idEtapa ? this.idEtapa : null,
            idAssinatura: this.idAssinatura ? this.idAssinatura : null,
            idRenovacao: this.idRenovacao ? this.idRenovacao : null,
            idAnuncio: this.idAnuncio ? this.idAnuncio : null,
            idPacote: this.idPacote ? this.idPacote : null,
            idOperacao: this.idOperacao ? this.idOperacao : null,
            idParcela: this.idParcela ? this.idParcela : null,
            tipo: parseInt(this.tipo),
            idUsuario: this.$store.state.usuario.id,
            dtPromessa: null,
            bloqueado: 0,
            recuperado: 0,
            contato: null
          };
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os dados do contato";
        this.snackErro = true;
      }
      setTimeout(() => {
        this.bloqueiaBotoes = false;
      }, 1000);
    },
    async carregarTelefones() {
      try {
        //* Carrega os telefones do cliente
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-telefone/cliente/" + this.idCliente + "?ativos=1");
        if (response.data) {
          this.telefonesCliente = response.data;
          this.telefonesCliente.forEach(element => {
            element.dtRegistro = util.formatDateTime(element.dtRegistro);
          });
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os telefones do cliente";
        this.snackErro = true;
      }
    },
    async carregarParcelas() {
      try {
        var response = [];
        if (this.idAssinatura) {
          response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/parcela/assinatura/" + this.idAssinatura);
        } else if (this.idAnuncio) {
          response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/parcela/anuncio/" + this.idAnuncio);
        } else if (this.idPacote) {
          response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/parcela/pacote/" + this.idPacote);
        } else if (this.idOperacao) {
          response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/parcela/operacao/" + this.idOperacao);
        }
        if (response.data) {
          this.parcelas = response.data;
          this.parcelas.forEach(element => {
            element.dtVectoOriginal = util.formatDate(element.dtVectoOriginal);
            element.dtPromessa = util.formatDate(element.dtPromessa);
            element.valor = util.formatCurrency(element.valor + element.descAcr);
          });
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as parcelas";
        this.snackErro = true;
      }
    },

    formatDate(date) {
      return util.formatDate(date);
    },
    formatDateTime(datetime) {
      return util.formatDateTime(datetime);
    },

    valida_data(date) {
      if (date) {
        return util.valida_data_BR(date);
      } else {
        return true;
      }
    },
    formatDateUS(date) {
      return util.formatDateUS(date);
    }
  },

  beforeMount() {
    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_CONTATO_CRIAR") {
        this.direitoCriar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_RECUPERAÇÃO_RECUPERAR") {
        this.direitoRecuperar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_RECEBER_ALTERAR_PROMESSA") {
        this.direitoPrometer = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_RECEBER_ALTERAR_BLOQUEIO") {
        this.direitoBloquear = true;
      }
    });
  },

  watch: {
    cliente() {
      this.nomeCliente = this.cliente;
    },
    idAssinatura() {
      if (this.tipo == "1") {
        this.carregarContatos();
        this.carregarTelefones();
        this.carregarParcelas();
      }
    },
    idParcela() {
      if (this.tipo == "2") {
        this.carregarContatos();
        this.carregarTelefones();
        this.carregarParcelas();
      }
    },
    dtPromessaUS() {
      this.dtPromessa = util.formatDate(this.dtPromessaUS);
      this.mnuDtPromessa = false;
    }
  },

  created() {
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
    //* Apesar de já ser carregado nos whatch, se não tiver aqui não irá carregar a primeira vez
    this.nomeCliente = this.cliente;
    this.carregarContatos();
    this.carregarTelefones();
    this.carregarParcelas();
  }
};
</script>
