<template>
  <div id="app">
    <v-app id="inspire">
      <v-card>
        <v-toolbar color="#333333">
          <v-icon color="white" class="mr-2">money</v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light"
            >Tabelas Preço</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <router-link
            to="/comercial/anuncio-tabela"
            style="text-decoration: none"
            v-if="direitoCriar"
          >
            <v-btn color="success" fab small>
              <v-icon color="white" title="Incluir">add</v-icon>
            </v-btn>
          </router-link>
        </v-toolbar>
        <div class="px-6 mt-4">
          <v-layout wrap>
            <v-spacer></v-spacer>
            <v-flex xs12 md5>
              <v-text-field
                background-color="white"
                class="mt-2 mb-2 elevation-0"
                v-model="filtroPesquisa"
                append-icon="search"
                label="Digite para pesquisar..."
                placeholder="Pesquise pelo produto, caderno, formato, determinação ou colocação"
                hide-details
                @keyup.enter="limpaFiltro"
                @click:append="limpaFiltro"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-data-table
            mobile-breakpoint="0"
            :headers="headers"
            :items="registros"
            :options.sync="options"
            :no-data-text="mensagemPesquisa"
            :footer-props="rodape"
          >
            <template v-slot:[getItemActions()]="{ item }">
              <router-link
                :to="'/comercial/anuncio-tabela/' + item.id"
                style="text-decoration: none"
                v-if="direitoEditar"
              >
                <v-icon color="blue" title="Editar">edit</v-icon>
              </router-link>
              <v-icon
                v-if="direitoEditar"
                color="blue"
                title="Visualizar Log"
                @click="visualizarLog(item)"
                >info</v-icon
              >
            </template>

            <template v-slot:[getItemDtInicio()]="{ item }">{{
              formatDate(item.dtInicio)
            }}</template>

            <template v-slot:[getItemTipoCobranca()]="{ item }">
              <span
                v-html="
                  item.tipoCobranca == 1
                    ? 'Centímetro x Coluna'
                    : item.tipoCobranca == 2
                    ? 'Preço Fixo'
                    : item.tipoCobranca == 3
                    ? 'Largura x Altura'
                    : item.tipoCobranca == 4
                    ? 'Número de Palavras'
                    : item.tipoCobranca == 5
                    ? 'Número de Caracteres'
                    : ''
                "
              ></span>
            </template>
          </v-data-table>
          <div class="text-xs-center pt-2 marg-bt">
            <v-pagination
              v-model="paginaAtual"
              :length="totalPaginas"
              :total-visible="$vuetify.breakpoint.xsOnly ? 4 : 7"
              circle
            ></v-pagination>
          </div>
        </div>
        <v-dialog v-model="dialogLogAlteracao" max-width="1200px">
          <v-card>
            <LogAlteracao
              :idTabelaPreco="idTabelaPreco"
              :dialogLogAlteracao.sync="dialogLogAlteracao"
            />
          </v-card>
        </v-dialog>
      </v-card>
    </v-app>
  </div>
</template>

<script>
import LogAlteracao from "@/views/components/LogAlteracao.vue";
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  components: {
    LogAlteracao
  },
  data() {
    return {
      headers: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Nome", value: "nome" },
        { text: "Início", value: "dtInicio" },
        { text: "Produto", value: "produto" },
        { text: "Caderno", value: "caderno" },
        { text: "Formato", value: "formato" },
        { text: "Determinação", value: "determinacao" },
        { text: "Colocação", value: "colocacao" },
        { text: "Tipo de Cobrança", value: "tipoCobranca" }
      ],
      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },

      dialogLogAlteracao: false,
      idTabelaPreco: "",

      direitoCriar: false,
      direitoEditar: false,
      mensagemPesquisa: "",
      filtroPesquisa: "",
      registros: [],
      registrosPagina: 10,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      carregando: true,
      ordenacao: "",
      ordem: "",
      options: {}
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    getItemActions() {
      return "item.actions";
    },
    getItemDtInicio() {
      return "item.dtInicio";
    },
    getItemTipoCobranca() {
      return "item.tipoCobranca";
    },

    limpaFiltro() {
      this.paginaAtual = 1;
      this.pesquisaDados();
    },
    async pesquisaDados() {
      this.registros = [];
      //sinaliza que está carregando
      this.carregando = true;
      this.mensagemPesquisa = "Carregando...";

      var idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "com/anuncio-tabela?idSite=" +
            idSitePrincipal +
            "&pagina=" +
            this.paginaAtual +
            "&tamanho=" +
            this.registrosPagina +
            "&filtro=" +
            this.filtroPesquisa +
            "&ordenacao=" +
            this.ordenacao +
            "&ordem=" +
            this.ordem
        );

        //seta as variaveis com o retorno
        this.registros = response.data.data;
        this.totalRegistros = response.data.total;
        this.totalPaginas = Math.ceil(
          this.totalRegistros / this.registrosPagina
        );
        this.rodape.pageText = this.totalRegistros + " itens";
        //fecha o loading
        this.carregando = false;
        //se não achar registro seta a mensagem senão deixa em branco
        this.mensagemPesquisa =
          this.registros.length > 1 ? "" : "Nenhum registro encontrado";
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else {
          this.mensagemPesquisa = "Erro ao buscar dados: " + e;
        }
      }
    },
    visualizarLog(item) {
      this.idTabelaPreco = item.id + "";
      this.dialogLogAlteracao = true;
    },
    closeLogAlteracao() {
      this.dialogLogAlteracao = false;
      this.idTabelaPreco = "";
    },

    formatDate(datetime) {
      return util.formatDate(datetime);
    }
  },

  beforeMount() {
    this.POSSUI_DIREITO([
      "WEBSUITA_ADMIN_SUITA",
      "COMERCIAL_TABELAS_PREÇO_ACESSO"
    ]);
    //* Verifica se o usuário logado possui direito para incluir
    this.$store.state.usuario.direitos.forEach(element => {
      var direito =
        element.modulo + "_" + element.grupo + "_" + element.direito;
      if (
        direito == "WEBSUITA_ADMIN_SUITA" ||
        direito == "COMERCIAL_TABELAS_PREÇO_CRIAR"
      ) {
        this.direitoCriar = true;
      }
      if (
        direito == "WEBSUITA_ADMIN_SUITA" ||
        direito == "COMERCIAL_TABELAS_PREÇO_EDITAR"
      ) {
        this.direitoEditar = true;
      }
    });
  },

  watch: {
    paginaAtual() {
      this.pesquisaDados();
    },
    dialogLogAlteracao(val) {
      val || this.closeLogAlteracao();
    },

    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }

        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }

        this.pesquisaDados();
      }
    }
  }
};
</script>
