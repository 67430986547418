<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">import_contacts</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Caderno</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12 md12 v-if="siteGrupo.length > 0">
            <v-autocomplete
              v-model="caderno.idSite"
              :disabled="isUpdating"
              :items="siteGrupo"
              :filter="filtrarObjetos"
              label="Restrito ao Site"
              item-text="nome"
              item-value="id"
              :rules="[validacao.idSite == false || validacao.idSite]"
              autocomplete="disabled"
              :name="Math.random()"
              no-data-text
              clearable
              @change="validacao.idSite = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md6>
            <v-autocomplete
              autofocus
              v-model="produtoSelecionado"
              :disabled="isUpdating || (id != '' && !direitoAvancado)"
              :items="produtos"
              :filter="filtrarObjetos"
              hint="Obrigatório"
              :rules="[validacao.idProduto == false || validacao.idProduto, value => !!value || 'Obrigatório']"
              label="Selecione o Produto"
              item-text="nome"
              item-value="id"
              autocomplete="disabled"
              :name="Math.random()"
              no-data-text
              return-object
              @change="validacao.idProduto = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md6>
            <v-text-field
              v-model="caderno.nome"
              label="Nome do Caderno"
              maxlength="50"
              hint="Obrigatório"
              :rules="[
                validacao.nome == false || validacao.nome,
                value => !!value || 'Obrigatório.',
                value => valida_nome(value) || 'Nome inválido',
                value => value.length > 1 || 'Preencha o nome do caderno'
              ]"
              :disabled="isUpdating"
              autocomplete="disabled"
              :name="Math.random()"
              @change="
                caderno.nome = caderno.nome.toUpperCase();
                validacao.nome = false;
              "
            ></v-text-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="caderno.paginaAltura"
              label="Altura da Página"
              :allowNegative="false"
              :max="produtoSelecionado && produtoSelecionado.tipo == 'ONLINE' ? 5000 : 60"
              hint="Obrigatório"
              :rules="[validacao.paginaAltura == false || validacao.paginaAltura, value => !!value || 'Obrigatório.', value => valida_altura(value)]"
              :disabled="isUpdating"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.paginaAltura = false"
            ></v-currency-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="caderno.paginaLargura"
              label="Largura da Página"
              :allowNegative="false"
              :max="produtoSelecionado && produtoSelecionado.tipo == 'ONLINE' ? 5000 : 30"
              hint="Obrigatório"
              :rules="[validacao.paginaLargura == false || validacao.paginaLargura, value => !!value || 'Obrigatório.', value => valida_largura(value)]"
              :disabled="isUpdating"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.paginaLargura = false"
            ></v-currency-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-text-field
              v-model="caderno.numeroColunas"
              :disabled="isUpdating"
              label="Nº de Colunas"
              hint="Obrigatório"
              :rules="[
                validacao.numeroColunas == false || validacao.numeroColunas,
                value => !!value || 'Obrigatório.',
                value => (value && parseInt(value) < 13) || 'Verifique o número de colunas'
              ]"
              v-mask="'##'"
              maxlength="2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.numeroColunas = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="caderno.espacoColunas"
              label="Espaço entre Colunas"
              hint="Obrigatório"
              :allowNegative="false"
              :max="1"
              :rules="[
                validacao.espacoColunas == false || validacao.espacoColunas,
                value => !!value || 'Obrigatório.',
                value => (value && parseFloat(value.replace('.', '').replace(',', '.')) >= 0.1 && parseFloat(value.replace('.', '').replace(',', '.')) <= 1) || 'Verifique o espaço'
              ]"
              :disabled="isUpdating"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.espacoColunas = false"
            ></v-currency-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="caderno.margemSuperior"
              label="Margem Superior"
              hint="Obrigatório"
              :allowNegative="false"
              :max="2"
              :rules="[
                validacao.margemSuperior == false || validacao.margemSuperior,
                value => !!value || 'Obrigatório.',
                value =>
                  (value && parseFloat(value.replace('.', '').replace(',', '.')) >= 0.01 && parseFloat(value.replace('.', '').replace(',', '.')) <= 2) ||
                  'Verifique a margem superior'
              ]"
              :disabled="isUpdating"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.margemSuperior = false"
            ></v-currency-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="caderno.margemInferior"
              label="Margem Inferior"
              hint="Obrigatório"
              :allowNegative="false"
              :max="2"
              :rules="[
                validacao.margemInferior == false || validacao.margemInferior,
                value => !!value || 'Obrigatório.',
                value =>
                  (value && parseFloat(value.replace('.', '').replace(',', '.')) >= 0.01 && parseFloat(value.replace('.', '').replace(',', '.')) <= 2) ||
                  'Verifique a margem inferior'
              ]"
              :disabled="isUpdating"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.margemInferior = false"
            ></v-currency-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="caderno.margemEsquerda"
              label="Margem Esquerda"
              hint="Obrigatório"
              :allowNegative="false"
              :max="2"
              :rules="[
                validacao.margemEsquerda == false || validacao.margemEsquerda,
                value => !!value || 'Obrigatório.',
                value =>
                  (value && parseFloat(value.replace('.', '').replace(',', '.')) >= 0.01 && parseFloat(value.replace('.', '').replace(',', '.')) <= 2) || 'Verifique o tamanho'
              ]"
              :disabled="isUpdating"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.margemEsquerda = false"
            ></v-currency-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="caderno.margemDireita"
              label="Margem Direita"
              hint="Obrigatório"
              :allowNegative="false"
              :max="2"
              :rules="[
                validacao.margemDireita == false || validacao.margemDireita,
                value => !!value || 'Obrigatório.',
                value =>
                  (value && parseFloat(value.replace('.', '').replace(',', '.')) >= 0.01 && parseFloat(value.replace('.', '').replace(',', '.')) <= 2) || 'Verifique o tamanho'
              ]"
              :disabled="isUpdating"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.margemDireita = false"
            ></v-currency-field>
          </v-flex>

          <v-flex xs12 md3>
            <v-autocomplete
              v-model="caderno.dias"
              :disabled="isUpdating || (id != '' && !direitoAvancado)"
              :items="diasSemana"
              :filter="filtrarItens"
              hint="Obrigatório"
              :rules="[validacao.dias == false || validacao.dias, value => !!value || 'Obrigatório.']"
              multiple
              label="Selecione os Dias de Publicação"
              autocomplete="disabled"
              :name="Math.random()"
              no-data-text
              @change="validacao.dias = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md3>
            <v-text-field
              v-model="caderno.formatoData"
              label="Formato da Data"
              maxlength="100"
              hint="Obrigatório"
              :rules="[validacao.formatoData == false || validacao.formatoData, value => !!value || 'Obrigatório.', value => value.length > 1 || 'Preencha o formato da data']"
              :disabled="isUpdating"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.formatoData = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md3>
            <v-text-field
              v-model="caderno.formatoNumeroPagina"
              label="Formato do Número da Página"
              maxlength="50"
              hint="Obrigatório"
              :rules="[
                validacao.formatoNumeroPagina == false || validacao.formatoNumeroPagina,
                value => !!value || 'Obrigatório.',
                value => value.length > 1 || 'Preencha o formato do número da página'
              ]"
              :disabled="isUpdating"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.formatoNumeroPagina = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md3>
            <v-text-field
              v-model="caderno.siglaCaderno"
              label="Sigla do Caderno"
              maxlength="50"
              hint="Obrigatório"
              :rules="[validacao.siglaCaderno == false || validacao.siglaCaderno, value => !!value || 'Obrigatório.', value => value.length > 1 || 'Preencha a sigla do caderno']"
              :disabled="isUpdating"
              autocomplete="disabled"
              :name="Math.random()"
              @change="
                caderno.siglaCaderno = caderno.siglaCaderno.toUpperCase();
                validacao.siglaCaderno = false;
              "
            ></v-text-field>
          </v-flex>

          <v-flex xs3>
            <v-switch
              v-model="caderno.situacao"
              :label="`${caderno.situacao == 1 ? 'Ativo' : 'Inativo'}`"
              :disabled="isUpdating || (id != '' && caderno.situacao == 1 && !direitoAvancado)"
            ></v-switch>
          </v-flex>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">
              {{ validacao.mensagemErro }}
            </v-sheet>
          </v-flex>
        </v-layout>

        <v-layout wrap>
          <v-expansion-panels v-model="painel" focusable>
            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Formatos Associados</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout wrap>
                  <v-spacer></v-spacer>
                  <v-flex md4>
                    <v-text-field
                      background-color="white"
                      class="mt-2 mb-2 elevation-0"
                      v-model="searchFormatos"
                      append-icon="search"
                      label="Digite para pesquisar..."
                      placeholder="Pesquisa pelo nome ou tipo (LS,LSI,LT,LTI,P,S)"
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md12>
                    <v-card>
                      <v-data-table
                        mobile-breakpoint="0"
                        :headers="headerFormatos"
                        :items="formatos"
                        :search="searchFormatos"
                        no-data-text
                        no-results-text
                        :footer-props="{
                          itemsPerPageText: 'Registros por página',
                          itemsPerPageOptions: [10, 25, 50],
                          pageText: ''
                        }"
                      >
                        <template v-slot:[getItemActions()]="{ item }">
                          <router-link :to="'/comercial/formato/' + item.idFormato" style="text-decoration: none" v-if="direitoFormato" target="_blank">
                            <v-icon color="green" title="Visualizar Formato">loupe</v-icon>
                          </router-link>
                        </template>

                        <template v-slot:[getItemTipo()]="{ item }">
                          <span
                            v-html="
                              item.tipo == 'LS'
                                ? 'Linha Simples'
                                : item.tipo == 'LSI'
                                ? 'Linha Simples Imagem'
                                : item.tipo == 'LT'
                                ? 'Linha Titulada'
                                : item.tipo == 'LTI'
                                ? 'Linha Titulada Imagem'
                                : item.tipo == 'D'
                                ? 'Destacado'
                                : item.tipo == 'P'
                                ? 'Publicidade'
                                : 'Site'
                            "
                          ></span>
                        </template>

                        <template v-slot:[getItemSituacao()]="{ item }">
                          <span v-html="item.situacao == 1 ? 'Ativo' : item.situacao == 0 ? '<strong>Inativo</strong>' : ''"></span>
                        </template>

                        <template v-slot:[getItemFormatoSituacao()]="{ item }">
                          <span v-html="item.formatoSituacao == 1 ? 'Ativo' : item.formatoSituacao == 0 ? '<strong>Inativo</strong>' : ''"></span>
                        </template>

                        <template v-slot:no-results>
                          <v-alert :value="true" color="error" icon="warning">"{{ searchFormatos }}" não encontrada.</v-alert>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn :to="'/comercial/cadernos'" color="orange lighten-2 white" id="btn-rsn" style="color:white"> <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar </v-btn>
      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="blue white" id="btn-rsn" style="color:white" depressed @click="salvar('false')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar
      </v-btn>
      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="orange white" style="color:white" id="btn-rsn" outlined @click="salvar('retornar')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>
      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="blue white" id="btn-rsn" style="color:white" outlined @click="salvar('true')" v-if="direitoCriar">
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Novo
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>

<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      caderno: {
        id: "",
        idSite: null,
        idProduto: null,
        nome: "",
        paginaAltura: 0,
        paginaLargura: 0,
        margemSuperior: 0,
        margemInferior: 0,
        margemEsquerda: 0,
        margemDireita: 0,
        espacoColunas: 0,
        numeroColunas: 0,
        dias: null,
        publicaSeg: 0,
        publicaTer: 0,
        publicaQua: 0,
        publicaQui: 0,
        publicaSex: 0,
        publicaSab: 0,
        publicaDom: 0,
        formatoData: "{DDDD} | {D} | {MMMM} | {YYYY}",
        formatoNumeroPagina: "{NN}",
        siglaCaderno: "",
        situacao: 1
      },

      validacao: {
        id: false,
        idSite: false,
        idProduto: false,
        nome: false,
        paginaAltura: false,
        paginaLargura: false,
        margemSuperior: false,
        margemInferior: false,
        margemEsquerda: false,
        margemDireita: false,
        espacoColunas: false,
        numeroColunas: false,
        dias: false,
        publicaSeg: false,
        publicaTer: false,
        publicaQua: false,
        publicaQui: false,
        publicaSex: false,
        publicaSab: false,
        publicaDom: false,
        formatoData: false,
        formatoNumeroPagina: false,
        siglaCaderno: false,
        situacao: false,
        mensagemErro: false
      },

      siteGrupo: [],
      produtoSelecionado: {},
      produtos: [],
      diasSemana: ["DOM", "SEG", "TER", "QUA", "QUI", "SEX", "SAB"],

      headerFormatos: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Nome", value: "nome" },
        { text: "Tipo", value: "tipo" },
        { text: "Situação", value: "situacao" },
        { text: "Situação Formato", value: "formatoSituacao" }
      ],

      formatos: [],
      searchFormatos: "",

      idSitePrincipal: null,
      painel: [0],
      direitoCriar: false,
      direitoFormato: false,
      direitoAvancado: false,

      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false,
      bloqueiaBotoes: false
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    getItemActions() {
      return "item.actions";
    },
    getItemSituacao() {
      return "item.situacao";
    },
    getItemFormatoSituacao() {
      return "item.formatoSituacao";
    },
    getItemTipo() {
      return "item.tipo";
    },

    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      var erro = false;
      if (this.produtoSelecionado.tipo == "ONLINE") {
        if (this.caderno.paginaAltura > 5000) {
          erro = true;
          this.validacao.paginaAltura = "Verifique a altura da página";
        }
        if (this.caderno.paginaLargura > 5000) {
          erro = true;
          this.validacao.paginaLargura = "Verifique a largura da página";
        }
      } else {
        if (this.caderno.paginaAltura > 60) {
          erro = true;
          this.validacao.paginaAltura = "Verifique a altura da página";
        }
        if (this.caderno.paginaLargura > 30) {
          erro = true;
          this.validacao.paginaLargura = "Verifique a largura da página";
        }
      }
      if (erro) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      this.caderno.nome = this.caderno.nome.toUpperCase();
      this.caderno.publicaSeg = 0;
      this.caderno.publicaTer = 0;
      this.caderno.publicaQua = 0;
      this.caderno.publicaQui = 0;
      this.caderno.publicaSex = 0;
      this.caderno.publicaSab = 0;
      this.caderno.publicaDom = 0;
      this.caderno.dias.forEach(element => {
        if (element == "SEG") {
          this.caderno.publicaSeg = 1;
        }
        if (element == "TER") {
          this.caderno.publicaTer = 1;
        }
        if (element == "QUA") {
          this.caderno.publicaQua = 1;
        }
        if (element == "QUI") {
          this.caderno.publicaQui = 1;
        }
        if (element == "SEX") {
          this.caderno.publicaSex = 1;
        }
        if (element == "SAB") {
          this.caderno.publicaSab = 1;
        }
        if (element == "DOM") {
          this.caderno.publicaDom = 1;
        }
      });

      this.isUpdating = true;
      try {
        var retorno = "";
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "com/caderno/" + this.id, this.caderno);
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "com/caderno?idSite=" + this.idSitePrincipal, this.caderno);
          this.id = retorno.data.id;
          this.caderno.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "true") {
          this.$router.push("/comercial/caderno");
          this.$router.go("/comercial/caderno");
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push("/comercial/cadernos");
          }, 2000);
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },
    async carregarProdutos() {
      try {
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API + "com/produto/lista" //tem que deixar vazio para saberem que tem algo errado (está inativo)  + (this.caderno.idProduto ? "?inativo=" + this.caderno.idProduto : "")
        );
        if (response.data) {
          this.produtos = response.data;
          this.produtos.forEach(element => {
            if (element.id == this.caderno.idProduto) {
              this.produtoSelecionado = element;
            }
          });
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de produtos";
        this.snackErro = true;
      }
    },
    async carregarFormatosCaderno() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "com/formato-caderno/caderno/" + this.caderno.id + "?inativos=1");
        if (response.data) {
          this.formatos = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os formatos do caderno";
        this.snackErro = true;
      }
    },

    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    filtrarItens(item, queryText, itemText) {
      return util.filtrarItens(item, queryText, itemText);
    },
    pLetra(text) {
      return util.pLetra(text);
    },
    valida_nome(text) {
      return util.valida_nome(text);
    },
    valida_altura(value) {
      value = value.toString();
      var retorno = true;
      if (this.produtoSelecionado && this.produtoSelecionado.id) {
        if (
          value &&
          parseFloat(
            value
              .replace(".", "")
              .replace(" ", "")
              .replace(",", ".")
          ) > (this.produtoSelecionado.tipo == "ONLINE" ? 5000 : 60)
        ) {
          retorno = "Verifique a altura da página";
        }
      }
      return retorno;
    },
    valida_largura(value) {
      value = value.toString();
      var retorno = true;
      if (this.produtoSelecionado && this.produtoSelecionado.id) {
        if (
          value &&
          parseFloat(
            value
              .replace(".", "")
              .replace(" ", "")
              .replace(",", ".")
          ) > (this.produtoSelecionado.tipo == "ONLINE" ? 5000 : 30)
        ) {
          retorno = "Verifique a largura da página";
        }
      }
      return retorno;
    }
  },

  watch: {
    produtoSelecionado(val) {
      this.caderno.idProduto = val.id;
    }
  },
  async created() {
    this.isUpdating = false;
    this.bloqueiaBotoes = true;
    this.id = this.$route.params.id ? this.$route.params.id : "";

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_CADERNOS_CRIAR") {
        this.direitoCriar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_FORMATOS_CRIAR" || direito == "COMERCIAL_FORMATOS_EDITAR") {
        this.direitoFormato = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_CADERNOS_EDITAR_AVANÇADO") {
        this.direitoAvancado = true;
      }
    });

    //* Verifica se o site do usuário está em GRUPO - OBS: ou ele compartilha a informação ou deixa exclusiva para o seu site
    var idGrupoSite = this.$store.state.usuario.site_idGrupo;
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
    if (idGrupoSite != null) {
      this.siteGrupo = [
        {
          id: this.idSitePrincipal,
          nome: this.$store.state.usuario.site_nome
        }
      ];
    }

    //* Se é edição, carrega o item
    if (this.id == "") {
      await this.carregarProdutos();

      this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "COMERCIAL_CADERNOS_CRIAR"]);
    } else {
      this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "COMERCIAL_CADERNOS_EDITAR"]);
      try {
        //* Carrega o item - somente se for público (idSite=null) ou for do site do usuário logado
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "com/caderno/" + this.id + "?idSite=" + this.idSitePrincipal);
        if (!response.data) {
          this.$router.push("/comercial/cadernos");
        } else {
          this.caderno = response.data;
          await this.carregarProdutos();
          await this.carregarFormatosCaderno();

          this.caderno.dias = [];
          if (this.caderno.publicaSeg) {
            this.caderno.dias.push("SEG");
          }
          if (this.caderno.publicaTer) {
            this.caderno.dias.push("TER");
          }
          if (this.caderno.publicaQua) {
            this.caderno.dias.push("QUA");
          }
          if (this.caderno.publicaQui) {
            this.caderno.dias.push("QUI");
          }
          if (this.caderno.publicaSex) {
            this.caderno.dias.push("SEX");
          }
          if (this.caderno.publicaSab) {
            this.caderno.dias.push("SAB");
          }
          if (this.caderno.publicaDom) {
            this.caderno.dias.push("DOM");
          }
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os dados do caderno";
        this.snackErro = true;
      }
    }
    setTimeout(() => {
      this.bloqueiaBotoes = false;
    }, 1000);
  }
};
</script>
