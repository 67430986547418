<template>
  <div id="app">
    <v-app id="inspire">
      <v-card>
        <v-toolbar color="#333333">
          <v-icon color="white" class="mr-2">dns</v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light"
            >Cupons de Promoções</v-toolbar-title
          >
        </v-toolbar>
        <div class="px-6 mt-4">
          <v-layout wrap>
            <v-spacer></v-spacer>
            <v-flex xs12 md5>
              <v-text-field
                background-color="white"
                class="mb-2 elevation-0"
                v-model="filtroPesquisa"
                append-icon="search"
                label="Digite para pesquisar..."
                placeholder="Pesquisa pela assinatura, cliente, promoção, cupom, competência"
                hide-details
                @keyup.enter="limpaFiltro"
                @click:append="limpaFiltro"
                autocomplete="disabled"
                :name="Math.random()"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-data-table
            mobile-breakpoint="0"
            :headers="headers"
            :items="registros"
            :options.sync="options"
            :no-data-text="mensagemPesquisa"
            :footer-props="rodape"
            :disable-sort="carregando"
            :disable-pagination="carregando"
          >
            <template v-slot:[getItemMesAno()]="{ item }">
              <span>
                {{ formatDate(item.mesAno).substring(3) }}
              </span>
            </template>
            <template v-slot:[getItemDtCriacao()]="{ item }">
              <span>
                {{ formatDateTime(item.dtCriacao) }}
              </span>
            </template>
          </v-data-table>
          <div class="text-xs-center pt-2 marg-bt">
            <v-pagination
              v-model="paginaAtual"
              :length="totalPaginas"
              :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 11"
              circle
            ></v-pagination>
          </div>
        </div>
      </v-card>
    </v-app>
  </div>
</template>

<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      headers: [
        { text: "ID", value: "id" },
        { text: "Assinatura", value: "idAssinatura" },
        { text: "Renovação", value: "renovacao" },
        { text: "Assinante", value: "cliente" },
        { text: "Promoção", value: "promocao" },
        { text: "Cupom", value: "numero" },
        { text: "Competência", value: "mesAno" },
        { text: "Usuário", value: "usuario" },
        { text: "Criação", value: "dtCriacao" }
      ],
      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },

      mensagemPesquisa: "",
      filtroPesquisa: "",
      registros: [],
      registrosPagina: 10,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      carregando: true,
      ordenacao: "",
      ordem: "",
      options: {}
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    getItemMesAno() {
      return "item.mesAno";
    },
    getItemDtCriacao() {
      return "item.dtCriacao";
    },

    limpaFiltro() {
      this.paginaAtual = 1;
      this.pesquisaDados();
    },
    async pesquisaDados() {
      //sinaliza que está carregando
      this.registros = [];
      this.carregando = true;
      this.mensagemPesquisa = "Carregando...";

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "ass/renovacao-promocao-cupom?idSite=" +
            this.$store.state.usuario.idSitePrincipal +
            "&pagina=" +
            this.paginaAtual +
            "&tamanho=" +
            this.registrosPagina +
            "&filtro=" +
            this.filtroPesquisa +
            "&ordenacao=" +
            this.ordenacao +
            "&ordem=" +
            this.ordem
        );

        //seta as variaveis com o retorno
        this.registros = response.data.data;
        this.registros.forEach(element => {
          element.mesAno = util.parseDate("01/" + element.mesAno);
        });

        this.totalRegistros = response.data.total;
        this.totalPaginas = Math.ceil(
          this.totalRegistros / this.registrosPagina
        );
        this.rodape.pageText = this.totalRegistros + " itens";
        //fecha o loading
        this.carregando = false;
        //se não achar registro seta a mensagem senão deixa em branco
        this.mensagemPesquisa =
          this.registros.length > 1 ? "" : "Nenhum registro encontrado";
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else {
          this.mensagemPesquisa = "Erro ao buscar dados: " + e;
        }
      }
    },
    formatDate(date) {
      return util.formatDate(date);
    },
    formatDateTime(datetime) {
      return util.formatDateTime(datetime);
    }
  },
  beforeMount() {
    this.POSSUI_DIREITO([
      "WEBSUITA_ADMIN_SUITA",
      "ASSINATURAS_CUPONS_PROMOÇÕES_ACESSO"
    ]);
  },

  watch: {
    paginaAtual() {
      this.pesquisaDados();
    },
    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }
        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }
        this.pesquisaDados();
      }
    }
  }
};
</script>
