<template>
  <div id="app">
    <v-app id="inspire">
      <v-card>
        <v-toolbar color="#333333">
          <v-icon color="white" class="mr-2">ac_unit</v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light">
            Distribuição
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-form ref="formDistribuicao" v-model="distribuicaoValid" lazy-validation>
          <v-container fluid grid-list-md>
            <v-layout wrap>
              <v-flex xs12 md12 v-if="siteGrupo.length > 0">
                <v-autocomplete
                  v-model="idSite"
                  :disabled="isUpdating"
                  :items="siteGrupo"
                  :filter="filtrarObjetos"
                  label="Restrito ao Site"
                  item-text="nome"
                  item-value="id"
                  autocomplete="disabled"
                  :name="Math.random()"
                  no-data-text
                  clearable
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 md3>
                <v-autocomplete
                  autofocus
                  v-model="idProduto"
                  :items="produtos"
                  :filter="filtrarObjetos"
                  hint="Obrigatório"
                  :rules="[value => !!value || 'Obrigatório.']"
                  label="Produto"
                  item-text="nome"
                  item-value="id"
                  no-data-text
                ></v-autocomplete>
              </v-flex>
              <v-flex xs3 md1>
                <v-text-field
                  v-model="quebra"
                  label="Quebra"
                  v-mask="'###'"
                  hint="Obrigatório"
                  :rules="[value => !!value || 'Obrigatório.', value => value > 9 || 'Quebra inválida']"
                  color="blue-grey lighten-2"
                  autocomplete="disabled"
                  :name="Math.random()"
                ></v-text-field>
              </v-flex>
              <v-flex xs7 md2>
                <v-menu v-model="mnuDtEntrega" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="dtEntrega"
                      label="Data da Entrega"
                      v-mask="'##/##/####'"
                      hint="Obrigatório"
                      :rules="[value => !!value || 'Obrigatório.', value => valida_data(value) || 'Data inválida']"
                      prepend-icon="event"
                      v-on="on"
                      color="blue-grey lighten-2"
                      autocomplete="disabled"
                      :name="Math.random()"
                      @keyup="dtEntregaUS = valida_data(dtEntrega) ? formatDateUS(dtEntrega) : null"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="dtEntregaUS" locale="pt-br" no-title @input="mnuDtEntrega = false"></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs2 md1 class="text-right">
                <v-btn color="blue" :loading="loading" :disabled="loading" fab small @click="distribuicao">
                  <v-icon color="white" title="Relatório Distribuição">ac_unit</v-icon>
                </v-btn>
              </v-flex>

              <v-flex xs12>
                <v-progress-linear v-model="value" :active="loading" class="ma-0" color="cyan accent-3" height="16" :indeterminate="value > 100"
                  >Montando relatório. Aguarde...</v-progress-linear
                >
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
        <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
        <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
      </v-card>
    </v-app>
  </div>
</template>
<script>
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
import util from "../../../util.js";
export default {
  data() {
    return {
      idSitePrincipal: null,
      siteGrupo: [],
      idProduto: null,
      produtos: [],
      quebra: 0,

      dtEntrega: null,
      dtEntregaUS: null,
      mnuDtEntrega: false,

      distribuicaoValid: true,

      mensagem: "",
      snackbar: false,
      snackErro: false,

      loader: null,
      loading: false,
      value: 0
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    async distribuicao() {
      if (!this.$refs.formDistribuicao.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      this.loader = "loading";
      const l = this.loader;
      this[l] = !this[l];

      try {
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "adm/cep/distribuicao" +
            "?dtRoteiro=" +
            this.dtEntregaUS +
            (this.idSite ? "&idSite=" + this.idSite : "") +
            "&idProduto=" +
            this.idProduto +
            "&quebra=" +
            this.quebra +
            "&sobrepor=1"
        );

        util.imprimir(response.data);
      } catch (e) {
        this.mensagem = e.response.data;
        this.snackErro = true;
      } finally {
        this[l] = false;
        this.loader = null;
      }
    },

    async carregarProdutos() {
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/produto/lista?" + (this.vendaAvulsa ? "vendaAvulsa=1" : "tipo=IMPRESSO"));
      if (response.data) {
        this.produtos = response.data;
        if (this.produtos.length > 0) {
          this.idProduto = this.produtos[0].id;
        }
      }
    },

    valida_data(date) {
      if (date) {
        return util.valida_data_BR(date);
      } else {
        return true;
      }
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    formatDateUS(date) {
      return util.formatDateUS(date);
    }
  },

  computed: {
    competencia() {
      var retorno = "";
      if (this.competenciaUS) {
        retorno = util.formatMMAAAA(this.competenciaUS);
      }
      return retorno;
    }
  },
  watch: {
    dtEntregaUS(val) {
      this.dtEntrega = util.formatDate(val);
      this.mnuDtEntrega = false;
    }
  },
  beforeMount() {
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_RELATÓRIOS_DISTRIBUIÇÃO"]);
  },
  created() {
    var idGrupoSite = this.$store.state.usuario.site_idGrupo;
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
    if (idGrupoSite != null) {
      this.siteGrupo = [
        {
          id: this.idSitePrincipal,
          nome: this.$store.state.usuario.site_nome
        }
      ];
    }

    this.carregarProdutos();
    var amanha = new Date();
    amanha.setDate(amanha.getDate() + 1);
    this.dtEntregaUS = util.dateToStringUS(amanha);
  }
};
</script>
