<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-form ref="formElemento" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <h4 class="overline font-weight-black font-italic">
              Selecione os Campos Desejados na Exportação
            </h4>
            <v-divider></v-divider>
          </v-flex>
          <v-flex xs12 md6>
            <v-flex xs12 v-if="prefixo == 'u'">
              <v-autocomplete
                v-model="campos"
                :items="itensAnuncio"
                :filter="filtrarObjetos"
                label="ANÚNCIO"
                item-text="nome"
                item-value="id"
                no-data-text
                autocomplete="disabled"
                :name="Math.random()"
                multiple
                return-object
              >
                <template v-slot:selection></template>
              </v-autocomplete>
            </v-flex>
            <v-flex xs12 v-if="prefixo == 'u'">
              <v-autocomplete
                v-model="campos"
                :items="itensPublicacoes"
                :filter="filtrarObjetos"
                label="PUBLICAÇÕES"
                item-text="nome"
                item-value="id"
                no-data-text
                autocomplete="disabled"
                :name="Math.random()"
                multiple
                return-object
              >
                <template v-slot:selection></template>
              </v-autocomplete>
            </v-flex>
            <v-flex xs12 v-if="prefixo == 'a'">
              <v-autocomplete
                v-model="campos"
                :items="itensAssinatura"
                :filter="filtrarObjetos"
                label="ASSINATURA"
                item-text="nome"
                item-value="id"
                no-data-text
                autocomplete="disabled"
                :name="Math.random()"
                multiple
                return-object
              >
                <template v-slot:selection></template>
              </v-autocomplete>
            </v-flex>
            <v-flex xs12 v-if="prefixo == 'c' || prefixo == 'u'">
              <v-autocomplete
                v-model="campos"
                :items="itensCliente"
                :filter="filtrarObjetos"
                label="CLIENTE"
                item-text="nome"
                item-value="id"
                no-data-text
                autocomplete="disabled"
                :name="Math.random()"
                multiple
                return-object
              >
                <template v-slot:selection></template>
              </v-autocomplete>
            </v-flex>
            <v-flex xs12 v-if="prefixo == 'p' || prefixo == 'u'">
              <v-autocomplete
                v-model="campos"
                :items="itensParcela"
                :filter="filtrarObjetos"
                label="PARCELA"
                item-text="nome"
                item-value="id"
                no-data-text
                autocomplete="disabled"
                :name="Math.random()"
                multiple
                return-object
              >
                <template v-slot:selection></template>
              </v-autocomplete>
            </v-flex>
            <v-flex xs12 v-if="prefixo.startsWith('n') || prefixo == 'u'">
              <v-autocomplete
                v-model="campos"
                :items="itensNotaFiscal"
                :filter="filtrarObjetos"
                label="NOTA FISCAL"
                item-text="nome"
                item-value="id"
                no-data-text
                autocomplete="disabled"
                :name="Math.random()"
                multiple
                return-object
              >
                <template v-slot:selection></template>
              </v-autocomplete>
            </v-flex>
          </v-flex>
          <v-flex xs12 md6>
            <v-flex xs12>
              <v-data-table mobile-breakpoint="0" :headers="headers" :items="campos" hide-default-footer disable-sort disable-pagination no-data-text>
                <template v-slot:[getItemActions()]="{ item }">
                  <v-icon color="orange" title="Mover para cima" @click="subir(item)">arrow_upward</v-icon>
                  <v-icon color="orange" title="Mover para baixo" @click="descer(item)">arrow_downward</v-icon>
                  <v-icon color="error" title="Excluir" @click="excluir(item)">delete</v-icon>
                </template>
              </v-data-table>
            </v-flex>
            <v-flex offset-md8 md2 offset-xs6 xs6>
              <v-btn outlined color="orange white" style="color:white" class="btn-rsn" @click="campos = []"> <v-icon class="icon-rsn" left>clear</v-icon>Limpar </v-btn>
            </v-flex>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>
  </v-card>
</template>
<script>
import util from "../../util.js";
export default {
  props: {
    prefixo: { type: String, default: "" }
  },
  data() {
    this.$vuetify.theme.primary = "blue lighten-2";
    return {
      headers: [
        { text: "Origem", value: "origem" },
        { text: "Campo", value: "nome" },
        { text: "Ações", value: "actions", width: "110" }
      ],

      campos: [],

      itensAnuncio: [
        { header: "Dados Básicos" },
        { origem: "Anúncio", id: "u.online", nome: "Online" },
        { origem: "Anúncio", id: "u.idPacote", nome: "ID Pacote" },
        { origem: "Anúncio", id: "u.status", nome: "Status" },
        { origem: "Anúncio", id: "u.idUsuario", nome: "Usuário Captação" },
        { origem: "Anúncio", id: "u.dtCaptacao", nome: "Data Captação" },
        {
          origem: "Anúncio",
          id: "u.dtCancelamento",
          nome: "Data Cancelamento"
        },
        { origem: "Anúncio", id: "u.vlrCobrado", nome: "Valor Cobrado" },
        { origem: "Anúncio", id: "u.vlrTaxado", nome: "Valor Taxado" },
        { origem: "Anúncio", id: "u.autorizacao", nome: "Autorização" },
        { origem: "Anúncio", id: "u.idProduto", nome: "Produto" },
        { origem: "Anúncio", id: "u.idCaderno", nome: "Caderno" },
        { origem: "Anúncio", id: "u.idFormato", nome: "Formato" },
        { origem: "Anúncio", id: "u.idDeterminacao", nome: "Determinação" },
        { origem: "Anúncio", id: "u.idColocacao", nome: "Colocação" },
        { origem: "Anúncio", id: "u.idSecao", nome: "Seção" },
        { origem: "Anúncio", id: "u.tipoCobranca", nome: "Tipo de Cobrança" },
        { origem: "Anúncio", id: "u.cor", nome: "Colorido" },
        {
          origem: "Anúncio",
          id: "u.descComissao",
          nome: "Desconto Comissão"
        },
        { origem: "Anúncio", id: "u.emNegociacao", nome: "Em Negociação" },
        {
          origem: "Anúncio",
          id: "u.bloquearPublicacao",
          nome: "Bloqueio Publicação"
        },
        { origem: "Anúncio", id: "u.titulo", nome: "Título" },
        { origem: "Anúncio", id: "u.texto", nome: "Texto" },
        { origem: "Anúncio", id: "u.colunas", nome: "Colunas/Largura" },
        { origem: "Anúncio", id: "u.altura", nome: "Altura" },
        { origem: "Anúncio", id: "u.tamanho", nome: "Tamanho (CxA)" },
        { origem: "Anúncio", id: "u.caracteres", nome: "Caracteres" },
        { origem: "Anúncio", id: "u.palavras", nome: "Palavras" },
        { divider: true },
        { header: "Publicação" },
        {
          origem: "Anúncio",
          id: "u.dtPublicacao",
          nome: "Publicação (Início/Fim)"
        },
        { divider: true },
        { header: "Condição Especial" },
        {
          origem: "Anúncio",
          id: "u.condicoes",
          nome: "Condições Especiais"
        },
        { divider: true },
        { header: "Agente/Agência" },
        { origem: "Anúncio", id: "u.idAgente1", nome: "Agente1" },
        { origem: "Anúncio", id: "u.idAgente2", nome: "Agente2" },
        { divider: true },
        { header: "Observação" },
        { origem: "Anúncio", id: "u.observacoes", nome: "Observações" }
      ],

      itensAssinatura: [{ header: "Dados Básicos" }, { origem: "Assinatura", id: "a.online", nome: "Online" }],

      itensPublicacoes: [
        { header: "Dados Básicos" },
        {
          origem: "Publicação",
          id: "pub.dtPublicacao",
          nome: "Data Publicação"
        },
        { origem: "Publicação", id: "pub.vlrBruto", nome: "Valor Bruto" },
        { origem: "Publicação", id: "pub.vlrLiquido", nome: "Valor Líquido" },
        { origem: "Publicação", id: "pub.arteAnuncio", nome: "Arte" },
        { origem: "Publicação", id: "pub.paginaPublicado", nome: "Página" },
        {
          origem: "Publicação",
          id: "pub.statusPublicacao",
          nome: "Status Publicação"
        }
      ],

      itensCliente: [],
      itensClienteCliente: [
        { header: "Dados Básicos" },
        { origem: "Cliente", id: "c.identificacao", nome: "Identificação" },
        { origem: "Cliente", id: "c.email", nome: "E-mail Principal" },
        { origem: "Cliente", id: "c.statusCredito", nome: "Status de Crédito" },
        { origem: "Cliente", id: "c.nome", nome: "Nome/Razão Social" },
        { origem: "Cliente", id: "c.nomeFantasia", nome: "Nome Fantasia" }
      ],
      itensClienteAnuncio: [
        { header: "Dados Básicos" },
        { origem: "Cliente", id: "c.identificacao", nome: "Identificação" },
        { origem: "Cliente", id: "c.email", nome: "E-mail Principal" },
        { origem: "Cliente", id: "c.statusCredito", nome: "Status de Crédito" },
        { origem: "Cliente", id: "c.nome", nome: "Nome/Razão Social" },
        { origem: "Cliente", id: "c.nomeFantasia", nome: "Nome Fantasia" },
        { divider: true },
        { header: "Endereço" },
        {
          origem: "Cliente",
          id: "c.endereco",
          nome: "Último Endereço de Cobrança Ativo"
        },
        { divider: true },
        { header: "Telefone" },
        {
          origem: "Cliente",
          id: "c.telefoneComercial",
          nome: "Último Telefone Comercial Ativo"
        },
        {
          origem: "Cliente",
          id: "c.telefoneResidencial",
          nome: "Último Telefone Residencial Ativo"
        },
        {
          origem: "Cliente",
          id: "c.celular",
          nome: "Último Telefone Celular Ativo"
        },
        {
          origem: "Cliente",
          id: "c.whatsapp",
          nome: "Último Whatsapp Ativo"
        }
      ],

      itensParcela: [],
      itensParcelaAnuncio: [{ header: "Dados Básicos" }, { origem: "Parcela", id: "p.idFormaPgto", nome: "Formas de Pagamento" }],
      itensParcelaParcela: [{ header: "Dados Básicos" }, { origem: "Parcela", id: "p.idFormaPgto", nome: "Formas de Pagamento" }],

      itensNotaFiscal: [],
      itensNotaFiscalAnuncio: [{ header: "NFe" }, { origem: "NFe", id: "n.nfeStatus", nome: "Status" }],
      itensNotaFiscalNotaFiscal: [{ header: "NFe" }, { origem: "NFe", id: "n.nfeStatus", nome: "Status" }],

      mensagem: "",

      autoUpdate: true
    };
  },

  methods: {
    getItemActions() {
      return "item.actions";
    },

    carregarItem() {
      if (this.prefixo == "u") {
        this.itensCliente = this.itensClienteAnuncio;
        this.itensParcela = this.itensParcelaAnuncio;
        this.campos = [
          { origem: "Anúncio", id: "u.status", nome: "Status" },
          { origem: "Anúncio", id: "u.autorizacao", nome: "Autorização" },
          { origem: "Anúncio", id: "u.dtCaptacao", nome: "Data Captação" },
          { origem: "Anúncio", id: "u.online", nome: "Online" },
          { origem: "Anúncio", id: "u.vlrCobrado", nome: "Valor Cobrado" },
          { origem: "Anúncio", id: "u.vlrTaxado", nome: "Valor Taxado" },
          {
            origem: "Parcela",
            id: "p.idFormaPgto",
            nome: "Formas de Pagamento"
          },
          { origem: "Cliente", id: "c.identificacao", nome: "Identificação" },
          { origem: "Cliente", id: "c.nome", nome: "Nome/Razão Social" },
          { origem: "Cliente", id: "c.email", nome: "E-mail Principal" },
          { origem: "Anúncio", id: "u.idAgente1", nome: "Agente1" },
          { origem: "Anúncio", id: "u.idProduto", nome: "Produto" },
          { origem: "Anúncio", id: "u.idCaderno", nome: "Caderno" },
          { origem: "Anúncio", id: "u.idFormato", nome: "Formato" },
          { origem: "Anúncio", id: "u.idDeterminacao", nome: "Determinação" },
          { origem: "Anúncio", id: "u.tamanho", nome: "Tamanho (CxA)" },
          {
            origem: "Anúncio",
            id: "u.dtPublicacao",
            nome: "Publicação (Início/Fim)"
          }
        ];
      } else if (this.prefixo == "a") {
        this.itensAssinatura = [
          {
            origem: "Assinatura",
            id: "assinatura",
            nome: "Em desenvolvimento..."
          }
        ];
        this.campos = this.itensAssinatura;
      } else if (this.prefixo == "c") {
        this.itensCliente = [
          {
            origem: "Cliente",
            id: "cliente",
            nome: "Em desenvolvimento..."
          }
        ];
        this.campos = this.itensCliente;
      } else if (this.prefixo == "p") {
        this.itensParcela = [
          {
            origem: "Parcela",
            id: "parcela",
            nome: "Em desenvolvimento..."
          }
        ];
        this.campos = this.itensParcela;
      } else if (this.prefixo == "nfe") {
        this.itensNotaFiscal = [
          {
            origem: "NotaFiscal",
            id: "notafiscal",
            nome: "Em desenvolvimento..."
          }
        ];
        this.campos = this.itensNotaFiscal;
      } else if (this.prefixo == "nfsc") {
        this.itensNotaFiscal = [
          {
            origem: "NotaFiscal",
            id: "notafiscal",
            nome: "Em desenvolvimento..."
          }
        ];
        this.campos = this.itensNotaFiscal;
      } else if (this.prefixo == "nfse") {
        this.itensNotaFiscal = [
          {
            origem: "NotaFiscal",
            id: "notafiscal",
            nome: "Em desenvolvimento..."
          }
        ];
        this.campos = this.itensNotaFiscal;
      } else {
        this.campos = [];
      }
    },
    filtroUrlCampos() {
      var idsCampos = "";
      this.campos.forEach(element => {
        idsCampos += (idsCampos ? "," : "") + element.id;
      });
      return idsCampos;
    },

    limpar() {
      this.campos = [];
    },

    subir(item) {
      var index = this.campos.indexOf(item);
      var acima = index == 0 ? 0 : index - 1;
      this.campos = this.changePosition(this.campos, index, acima);
    },
    descer(item) {
      var index = this.campos.indexOf(item);
      var abaixo = index == this.campos.length - 1 ? this.campos.length - 1 : index + 1;
      this.campos = this.changePosition(this.campos, index, abaixo);
    },
    excluir(item) {
      var index = this.campos.indexOf(item);
      this.campos.splice(index, 1);
    },

    changePosition(arr, from, to) {
      arr.splice(to, 0, arr.splice(from, 1)[0]);
      return arr;
    },

    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    }
  },
  watch: {
    prefixo() {
      this.carregarItem();
    }
  },
  created() {
    this.carregarItem();
  }
};
</script>
