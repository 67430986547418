<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <!-- Barra de Título -->
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">format_list_numbered</v-icon>
      <v-toolbar-title v-if="informacoesOk == false" style="color: white" class="font-weight-light">{{ informacoesOkTexto }}</v-toolbar-title>
      <v-toolbar-title v-if="informacoesOk" style="color: white" class="font-weight-light"> {{ nomeEditoria }} {{ nomeArtigo }} </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-form v-if="informacoesOk" ref="formElemento" class="mb-5" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <!-- Barra de Progresso -->
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <!-- Seleção de Site (Restrito a Sites em Grupo) -->
          <v-flex xs12 md12 v-if="siteGrupo.length > 0">
            <v-autocomplete
              v-model="editoria.idSite"
              :disabled="isUpdating"
              :items="siteGrupo"
              :filter="filtrarObjetos"
              label="Restrita ao Site"
              item-text="nome"
              item-value="id"
              :rules="[validacao.idSite == false || validacao.idSite]"
              autocomplete="disabled"
              :name="Math.random()"
              no-data-text
              clearable
              @change="validacao.idSite = false"
            ></v-autocomplete>
          </v-flex>
          <!-- Campo de Título do Artigo -->
          <v-flex xs12> </v-flex>

          <!-- Editor de Texto (Quill) -->
          <v-flex xs12>
            <div class="box-editor-quill" ref="quillContainer">
              <quill-editor :style="{ fontSize: this.editorFontSize + 'px' }" v-model="content" ref="myQuillEditor" :options="editorOptions"></quill-editor>
            </div>
          </v-flex>

          <!-- Mostra Modal NOME -->
          <v-dialog v-model="abreModalTitle" max-width="800px">
            <v-card>
              <v-toolbar color="#333333">
                <v-toolbar-title style="color: white" class="font-weight-light">Nome da Matéria</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="abreModalTitle = false">
                  <v-icon color="white">cancel</v-icon>
                </v-btn>
              </v-toolbar>
              <div class="px-6 mt-4">
                <v-card-text>
                  <v-alert v-if="tituloErro" type="error" prominent icon="mdi-alert-circle-outline">
                    {{ mensagemAviso }}
                  </v-alert>
                  <v-form>
                    <v-text-field
                      autofocus
                      v-model="nomeArtigo"
                      label="Nome"
                      maxlength="300"
                      hint="Obrigatório"
                      :rules="[validacao.nome == false || validacao.nome, (value) => !!value || 'Obrigatório']"
                      color="blue-grey lighten-2"
                      autocomplete="disabled"
                      :name="Math.random()"
                      @change="
                        nomeArtigo = pLetra(nomeArtigo);
                        validacao.nome = false;
                      "
                    ></v-text-field>
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="salvar">Salvar</v-btn>
                </v-card-actions>
              </div>
            </v-card>
          </v-dialog>
          <!-- Mostra Modal NOME -->

          <!-- Mostra Modal Imagem -->
          <v-dialog v-model="abreModalImagem" max-width="800px">
            <v-card>
              <v-toolbar color="#333333">
                <v-icon color="white" class="mr-2">mdi-file-image</v-icon>
                <v-toolbar-title style="color: white" class="font-weight-light">Incluir Imagem</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="abreModalImagem = false">
                  <v-icon color="white">cancel</v-icon>
                </v-btn>
              </v-toolbar>
              <div class="px-6 mt-4">
                <v-card-text>
                  <v-form>
                    <v-text-field
                      background-color="white"
                      v-model="urlImgInsert"
                      label="Endereço (URL) do site"
                      maxlength="250"
                      hint="Obrigatório"
                      :rules="[
                        validacao.urlImgInsert == false || validacao.urlImgInsert,
                        (value) => !!value || 'Obrigatório.',
                        (value) => value.length > 2 || 'Preencha o endereço do site',
                        (value) => valida_url(value)
                      ]"
                      :disabled="isUpdating"
                      @change="validacao.urlImgInsert = false"
                      color="blue-grey lighten-2"
                      autocomplete="disabled"
                      :name="Math.random()"
                    ></v-text-field>
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="fnInsertImagem">Inserir</v-btn>
                </v-card-actions>
              </div>
            </v-card>
          </v-dialog>
          <!-- Mostra Modal Imagem -->

          <!-- Modal de Inclusão de Estilo -->
          <v-dialog v-model="mostrarModalEstilos" max-width="800px">
            <v-card>
              <v-toolbar color="#333333">
                <v-icon color="white" class="mr-2">mdi-spellcheck</v-icon>
                <v-toolbar-title style="color: white" class="font-weight-light">Incluir Estilo</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="mostrarModalEstilos = false">
                  <v-icon color="white">cancel</v-icon>
                </v-btn>
              </v-toolbar>
              <div class="px-6 mt-4">
                <v-card-text>
                  <v-form>
                    <!-- Seleção de Grupo de Estilos -->
                    <v-autocomplete
                      v-model="selectedGrupoEstilos"
                      :items="registroGruposEstilos"
                      item-text="nome"
                      :item-value="(grupo) => ({ id: grupo.id, nome: grupo.nome })"
                      label="Grupos Estilos"
                      no-data-text="Nenhum grupo de estilo disponível"
                      @change="fnRecebeGrupoEstilos"
                    ></v-autocomplete>
                  </v-form>
                </v-card-text>

                <v-card-text>
                  <v-form>
                    <!-- Seleção de Estilo -->
                    <v-autocomplete
                      v-model="selectedEstilo"
                      :items="registroEstilos"
                      item-text="nome"
                      :item-value="(estilo) => ({ id: estilo.id, nome: estilo.nome })"
                      label="Estilos"
                      no-data-text="Escolha primeiro o grupo de estilos"
                      @change="fnCriaEstilo"
                    ></v-autocomplete>
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="fnAplicaEstiloFechaModal">Inserir</v-btn>
                </v-card-actions>
              </div>
            </v-card>
          </v-dialog>

          <!-- MODAL AVISOS -->
          <v-dialog v-model="dialogAviso" persistent max-width="500px">
            <v-card>
              <v-card-title class="headline">Aviso</v-card-title>
              <v-card-text>
                {{ mensagemAviso }}
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogAviso = false">OK</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- MODAL AVISOS -->

          <!-- MODAL SALVAR SAIR -->
          <v-dialog v-model="modalSalvarSair" max-width="500px">
            <v-card>
              <v-card-title>Alterações não salvas</v-card-title>
              <v-card-text> Você possui alterações não salvas. Deseja salvá-las antes de sair? </v-card-text>
              <v-card-actions>
                <v-btn color="primary" @click="salvar">Salvar</v-btn>
                <v-btn color="error" @click="fecharSemSalvar">Sair</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- MODAL SALVAR SAIR -->

          <!-- Mensagem de Erro de Validação -->
          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">{{ validacao.mensagemErro }}</v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <!-- Snackbars para Mensagens -->
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>

<script>
import util from "../../util.js";
import { api } from "@/services/api";
import { mapMutations } from "vuex";

// Importações para o Quill Editor
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.snow.css";

import { BroadcastChannel } from 'broadcast-channel'; // Importe a biblioteca

export default {
  components: {
    quillEditor
  },
  data() {
    return {
      id: "",
      idEditoria: "",
      idConteudo: "",
      nomeEditoria: "",

      editoria: {
        id: "",
        idSite: null,
        idSuperior: null,
        ordem: 0,
        aliasInternet: "",
        tempoLimpeza: 0,
        tipo: 0,
        situacao: 1
      },
      validacao: {
        id: false,
        idSite: false,
        idSuperior: false,
        ordem: false,
        nome: false,
        aliasInternet: false,
        tempoLimpeza: false,
        tipo: false,
        situacao: false,
        mensagemErro: false,
        urlImgInsert: false
      },
      hierarquia: "",
      siteGrupo: [],
      tipos: [
        { id: 0, nome: "Edição" },
        { id: 1, nome: "Internet" },
        { id: 2, nome: "Lixeira" },
        { id: 3, nome: "Produção" }
      ],
      idSitePrincipal: null,
      direitoCriar: false,
      elementoValid: true,
      mensagem: "",
      snackbar: false,
      snackErro: false,
      isUpdating: false,

      selectedEditoria: null,
      nomeEditoriaSelecionada: "",
      editorFontSize: 16, // Tamanho de fonte inicial do editor
      urlImgInsert: "",

      conteudoEscrito: "",
      conteudoEscritoRft: "",
      conteudoEscritoRftConvertido: "",

      //ARQUIVO DIGITAL IMAGENS
      imagensRecebidas: [],
      //ARQUIVO DIGITAL IMAGENS

      nomeArtigo: "",
      modalSalvarSair: false,
      // Dados para envio do novo artigo
      newArtigo: {
        idMateria: "0",
        idEditoria: "",
        //  idUsuario: "110",
        idUsuario: this.$store.state.usuario.id,
        nome: "",
        altura: "0",
        conteudo: "",
        idMateriaAnterior: "0",
        dtCriacao: "",
        corrigida: "0"
      },

      informacoesOk: true,
      informacoesOkTexto: "",

      dialogAviso: false,
      mensagemAviso: "",
      tituloErro: false,
      // Dados para o Modal de Incluir Estilo
      mostrarModalEstilos: false,
      selectedGrupoEstilos: null,
      registroGruposEstilos: [],
      selectedEstilo: null,
      idSelectGrupo: "",
      nomeSelectGrupo: "",
      registroEstilos: [],

      actionArtigo: "",
      // Dados para controle do cursor no Quill Editor
      cursorLine: null,
      textToInsert: "",
      contentAlterado: "",
      content: " ",
      salvarFechar: false,

      blockedKeys: ["<", ">"], // Keys to block
      // Opções do Quill Editor

      abreModalImagem: false,
      abreModalTitle: false,

      editorOptions: {
        theme: "snow",
        placeholder: ". . .",
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"],
              [
                { imgButton: "Imagem" },
                { insertStyle: "Estilo" },
                { titleButtonAumentaTexto: "+" },
                { titleButtonDiminuiTexto: "-" },
                { anotherButton: "Salvar" },
                { fecharButton: "Fechar" }
              ]
            ],
            handlers: {
              imgButton: () => {
                console.log("Insert Imagem");
                this.abrirModalImagem();
              },
              insertStyle: () => {
                console.log("Insert Style button clicked");
                this.mostrarModalSelecionaEstilo();
              },
              titleButtonAumentaTexto: () => {
                console.log("IAumenta Texto");
                if (this.editorFontSize < 32) {
                  this.editorFontSize += 2;
                  this.updateEditorFontSize();
                }
              },
              titleButtonDiminuiTexto: () => {
                console.log("Diminui Texto");
                if (this.editorFontSize > 10) {
                  this.editorFontSize -= 2;
                  this.updateEditorFontSize();
                }
              },
              anotherButton: () => {
                console.log("Salvar Button clicked");
                this.salvar();
              },
              fecharButton: () => {
                console.log("Fechar Button clicked");

                this.tentarSair();
              }
            }
          }
        },
        formats: ["font", "size", "bold", "italic", "underline", "strike", "image"]
      }
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    // Salva o artigo (novo ou edição)
    async salvar() {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      this.fnDtCriacao();
      this.codificarNome();
      this.convertHtmlToBase64();
      this.isUpdating = true;

      if (this.nomeArtigo == "") {
        this.abrirModalTitle();
        this.isUpdating = false;
        return;
      } else {
        this.abreModalTitle = false;
        this.tituloErro = false;
      }

      //VERIFICA <> no TEXTO
      console.log("TEXTO:- " + this.newArtigo.conteudo);
      const resultadoVerificacao = this.verificarCaracteres();

      if (!resultadoVerificacao.resultado) {
        // Mostra a mensagem de aviso se houver algo errado
        this.mensagemAviso = resultadoVerificacao.mensagemAviso;
        this.dialogAviso = true;
        return;
      } else {
      }
      //VERIFICA <> no TEXTO

      try {
        var retorno = "";
        if (this.id) {
          // Edição de artigo existente
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "edt/editorial/salvaMateria?idSite=" + this.idSitePrincipal, this.newArtigo, {
            headers: {
              "Content-Type": "application/json; charset=utf-8"
            }
          });
          // retorna ERRO se o título já existe
          if (retorno.data.erro == true) {
            console.log(retorno.data);
            this.mensagemAviso = "Houve algum problema ao tentar atualizar o artigo.";
            this.dialogAviso = true;
            return;
          } else {
            this.contentAlterado = this.content;
          }
        } else {
          // Criação de novo artigo
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "edt/editorial/salvaMateria?idSite=" + this.idSitePrincipal, this.newArtigo, {
            headers: {
              "Content-Type": "application/json; charset=utf-8"
            }
          });

          console.log(retorno.data);
          console.log(retorno.data.erro);
          console.log(retorno.data.descricao);

          // retorna ERRO se o título já existe
          if (retorno.data.erro == true) {
            this.mensagemAviso = retorno.data.descricao;
            this.tituloErro = true;
            this.abrirModalTitle();
            return;
          }

          this.id = retorno.data.idMateria;
          this.idConteudo = "0";

          this.$router.replace("/editorial/artigo/" + this.idEditoria + "/" + retorno.data.idMateria + "/null");

          this.fnCarregaArtigoEdit(retorno.data.idMateria);
        }
        if (this.salvarFechar == true) {
          window.close();
        }

        this.checkAlteracao = false; //Verifica se houve alteração no editor após o último salvar

        this.mensagemAviso = "Dados foram atualizados";
        this.dialogAviso = true;
        //this.mensagem = "Dados foram atualizados";
        // this.snackbar = true;
      } catch (e) {
        // Tratamento de erros
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },

    // Filtra objetos em um autocomplete
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },

    // Formata a primeira letra para maiúscula
    pLetra(text) {
      return util.pLetra(text);
    },

    abrirModalTitle() {
      this.abreModalTitle = true;
    },

    fnInsertImagem() {
      if (this.cursorLine !== null) {
        const quillEditor = this.$refs.myQuillEditor.quill;
        const fullText = quillEditor.getText();
        const lines = fullText.split("\n");

        // Calcula o índice da linha para inserir a imagem
        let insertIndex = 0;
        for (let i = 0; i < this.cursorLine; i++) {
          insertIndex += lines[i].length + 1;
        }

        // Use a URL original da imagem
        const imgUrlMontada = `<img  class="img-artigo" src="${this.urlImgInsert}">`;

        console.log("Imagem HTML:", imgUrlMontada);

        // Insere a imagem na posição abaixo do texto
        quillEditor.clipboard.dangerouslyPasteHTML(insertIndex, imgUrlMontada + "\n");

        // Define o cursor após a imagem inserida
        quillEditor.setSelection(insertIndex + imgUrlMontada.length, 0);

        console.log(`Imagem inserida na posição: ${insertIndex}`);
      } else {
        console.log("Posição do cursor não armazenada");
      }
      this.abreModalImagem = false;
      this.urlImgInsert = "";
    },

    abrirModalImagem() {
      // Abre o modal para selecionar uma imagem
      this.abreModalImagem = true;
      const quillEditor = this.$refs.myQuillEditor.quill;
      const selection = quillEditor.getSelection();
      if (selection) {
        const index = selection.index;
        const textBeforeCursor = quillEditor.getText(0, index);
        this.cursorLine = textBeforeCursor.split("\n").length;
        console.log("Posição do cursor capturada:", this.cursorLine);
      } else {
        console.log("Cursor não está no editor");
      }
    },

    // Abre o modal de seleção de estilo
    mostrarModalSelecionaEstilo() {
      this.mostrarModalEstilos = true;

      // Obtém a posição do cursor no Quill Editor
      const quillEditor = this.$refs.myQuillEditor.quill;
      const selection = quillEditor.getSelection();
      if (selection) {
        const index = selection.index;
        const textBeforeCursor = quillEditor.getText(0, index);
        this.cursorLine = textBeforeCursor.split("\n").length;
        console.log("Cursor is on line:", this.cursorLine);
      } else {
        console.log("Cursor is not in the editor");
      }
    },

    // Aplica o estilo selecionado e fecha o modal
    fnAplicaEstiloFechaModal() {
      this.mostrarModalEstilos = false;

      if (this.cursorLine !== null) {
        const quillEditor = this.$refs.myQuillEditor.quill;
        const fullText = quillEditor.getText();
        const lines = fullText.split("\n");

        // Calcula o índice da linha para inserir o estilo
        let insertIndex = 0;
        for (let i = 0; i < this.cursorLine - 1; i++) {
          insertIndex += lines[i].length + 1;
        }

        // Insere o estilo na posição calculada
        quillEditor.insertText(insertIndex, this.textToInsert + "\n", "user");
        quillEditor.setSelection(insertIndex + "Texto Inserido\n".length, 0);

        console.log(`Texto inserido na posição: ${insertIndex}`);
      } else {
        console.log("Linha do cursor não armazenada");
      }
    },

    // Carrega os grupos de estilos disponíveis
    async fnCarregaGrupos() {
      this.registroGruposEstilos = [];

      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "edt/editorial/buscaGrupoEstilos");
        this.registroGruposEstilos = response.data.grupoEstilos;
      } catch (error) {
        console.error("Erro ao buscar grupos de estilos:", error);
      }
    },

    // Carrega os estilos de um grupo específico
    async fnRecebeGrupoEstilos(retornoSelectGrupo) {
      this.idSelectGrupo = retornoSelectGrupo.id;
      this.nomeSelectGrupo = retornoSelectGrupo.nome;
      this.fnCarregarEstilos();
    },

    async fnCarregarEstilos() {
      this.registroEstilos = [];

      const bodyEstilos = {
        idGrupo: this.idSelectGrupo,
        idEstilo: "",
        filtroBusca: "0"
      };

      try {
        const response = await api.post(process.env.VUE_APP_URL_WEB_API + "edt/editorial/buscaEstilos", bodyEstilos);
        this.registroEstilos = response.data.estilos;
      } catch (error) {
        console.error("Erro ao buscar estilos:", error);
      }
    },

    // Define o texto a ser inserido no editor
    async fnCriaEstilo() {
      this.textToInsert = "<" + this.selectedGrupoEstilos.nome + ";" + this.selectedEstilo.nome + ">";
    },

    // Obtém o conteúdo HTML do Quill Editor
    convertHtmlToBase64() {
      const quillEditor = this.$refs.myQuillEditor.quill;
      let htmlContent = quillEditor.root.innerHTML;

      // Cria um elemento temporário para manipular o HTML
      let tempDiv = document.createElement("div");
      tempDiv.innerHTML = htmlContent;
      // Seleciona todas as tags <img> e adiciona a classe "img-artigo"
      const images = tempDiv.querySelectorAll("img");
      images.forEach((img) => {
        img.classList.add("img-artigo");
      });
      // Atualiza o conteúdo HTML com as classes aplicadas
      htmlContent = tempDiv.innerHTML;
      // Codifica o conteúdo HTML
      // const conteudoCodificado = btoa(unescape(encodeURIComponent(htmlContent)));
      const conteudoCodificado = htmlContent;
      console.log("*****************************************************************");
      console.log(conteudoCodificado);
      this.newArtigo.conteudo = conteudoCodificado;
    },

    verificarCaracteres() {
      //Verifica se existe < digitado pelo usuário sem ser um estilo
      // Expressão regular para capturar &lt; seguido de algo que não seja letra maiúscula ou número
      const regexInvalid = /&lt;[^A-Z0-9]/g;
      // Expressão regular para capturar &lt; seguido de letra maiúscula ou número
      const regexValid = /&lt;[A-Z0-9]/g;

      // Verifica se existem ocorrências de &lt; seguidas de algo que não seja uma letra maiúscula ou número
      const invalidMatches = this.newArtigo.conteudo.match(regexInvalid);

      // Verifica se existem ocorrências válidas de &lt; seguidas por uma letra maiúscula ou número
      const validMatches = this.newArtigo.conteudo.match(regexValid);

      // Lógica de verificação
      if (invalidMatches) {
        // Se houver ocorrências inválidas, retorna a mensagem de aviso
        return {
          mensagemAviso: "Não usar os caracteres < ou > no texto.",
          resultado: false
        };
      } else if (validMatches) {
        // Se houver ocorrências válidas, retorna que o texto está correto
        console.log("O texto contém '&lt;' seguido de uma letra maiúscula ou número.");
        return {
          resultado: true
        };
      } else {
        // Se não houver nem válidos nem inválidos, retorna sem alertas
        return {
          resultado: true
        };
      }
    },

    // Carrega as editorias disponíveis
    async carregarEditorias() {
      try {
        const response = await api.post(process.env.VUE_APP_URL_WEB_API + "edt/editoria/all");
        const editoriaEncontrada = response.data.find((editoria) => editoria.id == this.idEditoria);
        if (editoriaEncontrada) {
          if (editoriaEncontrada.tipo == 2 || editoriaEncontrada.tipo == 3) {
            this.informacoesOk = false;
            this.informacoesOkTexto = "EDITORIA DE PRODUÇÃO";
          } else {
            this.newArtigo.idEditoria = this.idEditoria;
            console.log("Editoria encontrada:", editoriaEncontrada);
            this.nomeEditoria = editoriaEncontrada.nome;
          }
        } else {
          // console.log("Editoria com ID", this.idEditoria, "não encontrada.");
          this.informacoesOk = false;
          this.informacoesOkTexto = "EDITORIA NÃO EXISTE";
        }
      } catch (error) {
        console.error("Erro ao buscar editorias:", error);
      }
    },

    // Define a editoria do artigo
    async carregarArtigos(selectEditoria) {
      this.newArtigo.idEditoria = selectEditoria.id;
      this.nomeEditoriaSelecionada = selectEditoria.nome;
    },

    // Obtém a data e hora atual formatada
    fnDtCriacao() {
      const options = {
        timeZone: "America/Sao_Paulo",
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric"
      };
      const dataHoraBrasilia = new Date().toLocaleString("pt-BR", options);
      this.newArtigo.dtCriacao = dataHoraBrasilia.replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$2-$1").replace(",", "");
    },

    // Codifica o nome do artigo para Base64
    codificarNome() {
      //const utf8String = unescape(encodeURIComponent(this.nomeArtigo));
      //this.newArtigo.nome = btoa(utf8String);

      this.newArtigo.nome = this.nomeArtigo;
    },

    /* ########### EDIÇÃO ################# */

    async fnCarregaArtigoEdit(idArtigoEdita) {
      const body = {
        idMateria: idArtigoEdita,
        idConteudo: this.idConteudo,
        idUsuario: this.$store.state.usuario.id,
        destravar: "1" //DIREITO DE EDITAR
      };
      console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%% AQUII");
      console.log(this.idConteudo);
      console.log("body" + body);

      //Organiza e exibie as editorias na lateral
      try {
        // Faz a pesquisa no banco de dados
        const response = await api.post(process.env.VUE_APP_URL_WEB_API + "edt/editorial/editaMateria", body);

        console.log("response.data");
        console.log(response.data);
        console.log(this.idEditoria + " - - " + response.data.idEditoria);

        if (response.data.erro != true) {
          if (this.idEditoria != response.data.idEditoria) {
            this.informacoesOk = false;
            this.informacoesOkTexto = "EDITORIA DO ARTIGO É DIFERENTE DA EDITORIA ENVIADA NA URL";
          } else {
            // Seta as variáveis com o retorno
            // Cria um buffer contendo o texto em base64

            this.content = response.data.textoMateria;
            this.contentAlterado = response.data.textoMateria;

            this.nomeArtigo = response.data.nomeMateria;
            this.newArtigo.idMateria = idArtigoEdita;
            console.log("ID EDITORIA MATÉRIA" + response.data.idEditoria);
          }
        } else {
          this.informacoesOk = false;
          this.informacoesOkTexto = "ERRO NÃO TEM DIREITO";

          console.log(response.data);
        }
      } catch (error) {
        console.error("Erro ao buscar editorias:", error);
        // Tratar o erro (exibir mensagem ao usuário, etc.)
      }
      // Converte e exibe texto plano
    },

    /* ########### EDIÇÃO ################# */

    valida_url(url, assinante) {
      if (!util.valida_url(url)) {
        return assinante && !url ? true : "Endereço inválido.";
      } else {
        return true;
      }
    },

    updateEditorFontSize() {
      // Acessa o elemento .ql-editor diretamente e aplica o tamanho da fonte
      const quillEditor = this.$refs.myQuillEditor;
      if (quillEditor && quillEditor.$el) {
        const editor = quillEditor.$el.querySelector(".ql-editor");
        if (editor) {
          editor.style.fontSize = this.editorFontSize + "px";
        }
      }
    },

    /* MODAL SALVAR SAIR */
    tentarSair() {
      if (this.contentAlterado !== this.content) {
        this.modalSalvarSair = true; // Exibe o modal de confirmação
        this.salvarFechar = true; // fecha a janela após salvar
      } else {
        this.fecharSemSalvar();
      }
    },
    fecharSemSalvar() {
      window.close();
      // Lógica para fechar ou navegar
    },
    /* MODAL SALVAR SAIR */

    /* MODAL AVISO */
    mostrarAviso() {
      this.dialogAviso = true;
    },
    /* MODAL AVISO */

    /* VERIFICA BLOQUEIA <> */
    handleKeyPress(event) {
      if (this.blockedKeys.includes(event.key)) {
        event.preventDefault();
      }
    },
    /* VERIFICA BLOQUEIA <> */

    /* VERIFICA FECHAMENTO DA ABA */
    beforeUnloadHandler(event) {
      if (this.contentAlterado !== this.content) {
        event.preventDefault();
        event.returnValue = "";
      }
    },


    /* VERIFICA FECHAMENTO DA ABA */
  },

  mounted() {
    this.fnCarregaGrupos();
    this.updateEditorFontSize();
    /*    setTimeout(() => {
      if (this.$refs.myQuillEditor && this.$refs.myQuillEditor.quill) {
        this.$refs.myQuillEditor.quill.focus(); // Corrigido: quill em vez de quillEditor
      }
    }, 500); // Ajuste o tempo se necessário */

    /* VERIFICA BLOQUEIA <> */
    this.$nextTick(() => {
      this.$refs.quillContainer.addEventListener("keydown", this.handleKeyPress);
    });
    /* VERIFICA BLOQUEIA <> */

    /* VERIFICA FECHAMENTO DA ABA */
    window.addEventListener("beforeunload", this.beforeUnloadHandler);
    /* VERIFICA FECHAMENTO DA ABA */

    this.$nextTick(() => {
      if (this.$refs.myQuillEditor && this.$refs.myQuillEditor.quill) {
        this.$refs.myQuillEditor.quill.focus();
      }
    });


    console.log("######################!@$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$4");
    //ARQUIVO DIGITAL IMAGENS
    const channel = new BroadcastChannel('envia_imagens');

    const handleImageMessage = (event) => { // Use uma arrow function para corrigir o 'this'
      this.imagensRecebidas = [];
      const urlRecebida = event.data;
      console.log('Imagem recebida:', urlRecebida);
      this.imagensRecebidas = urlRecebida.split('|');
    };

    channel.onmessage = handleImageMessage;
    //ARQUIVO DIGITAL IMAGENS
  }, // FIM mounted()

  beforeDestroy() {
    this.$refs.myQuillEditor.removeEventListener("keydown", this.handleKeyPress); /* BLOQUEIA <> */

    window.removeEventListener("beforeunload", this.beforeUnloadHandler); /* VERIFICA FECHAMENTO DA ABA */

    channel.close(); //  ARQUIVO DIGITAL IMAGENSFeche o canal para evitar vazamentos de recursos
  },

  computed: {},

  async created() {
    this.idEditoria = this.$route.params.idEditoria ? this.$route.params.idEditoria : "";
    if (this.idEditoria == "null") {
      this.idEditoria = "";
    }

    //SE EXISTIR ID É EDIÇÃO
    this.id = this.$route.params.id ? this.$route.params.id : "";
    if (this.id == "null") {
      this.id = "";
    }

    this.idConteudo = this.$route.params.idConteudo ? this.$route.params.idConteudo : "";
    if (this.idConteudo == "null") {
      this.idConteudo = "0";
    }
    console.log(this.idConteudo);
    console.log("IDDD CONTEUDOOOOOO VERSÃOOOOOOOOOOOOOOOOOOOOO");

    console.log("EDITORIA!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
    console.log(this.idEditoria);

    console.log("ID ARTIGO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
    console.log(this.id);

    // Verifica permissões do usuário
    this.$store.state.usuario.direitos.forEach((element) => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "EDITORIAL_EDITORIAS_CRIAR") {
        this.direitoCriar = true;
      }
    });

    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

    // Define os sites permitidos para o usuário
    var idGrupoSite = this.$store.state.usuario.site_idGrupo;
    if (idGrupoSite != null) {
      this.siteGrupo = [
        {
          id: this.idSitePrincipal,
          nome: this.$store.state.usuario.site_nome
        }
      ];
    }

    console.log(this.idSitePrincipal + " ID SITE PRINCIPAL");
    console.log(this.$store.state.usuario.site_nome + " nome USUÁRIO LOGADO");
    console.log(this.$store.state.usuario.id + " ID USUÁRIO LOGADO");

    try {
      if (this.id == "") {
        // Criação de novo artigo
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "EDITORIAL_EDITORIAS_CRIAR"]);

        this.carregarEditorias();
        this.actionArtigo = "Novo";
      } else {
        // Edição de artigo existente
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "EDITORIAL_EDITORIAS_EDITAR"]);

        this.actionArtigo = "Editar";
        this.fnCarregaArtigoEdit(this.id);
        this.carregarEditorias();
      }
    } catch {
      this.mensagem = "Ocorreu um erro ao carregar os dados";
      this.snackErro = true;
    }
  }
};
</script>

<style>
/* Importa o CSS do Quill Editor */
@import "~quill/dist/quill.snow.css";

/* EDITOR */

.ql-container.ql-snow {
  margin-left: 45px;
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
}
.quill-editor {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.ql-toolbar.ql-snow {
  display: flex;
  flex-direction: column;
  width: 45px;
  position: fixed;
  top: 130px;
  border: none;
}
.ql-container.ql-snow {
  height: 81vh;
}
/* EDITOR */

/* btn imagem */
.ql-toolbar .ql-imgButton::before {
  font-family: "Material Icons";
  content: "add_photo_alternate";
  font-size: 25px;
}
.ql-toolbar .ql-imgButton:hover {
  background-color: #e0e0e0;
}
.ql-toolbar .ql-insertStyle::before {
  font-family: "Material Icons";
  content: "segment";
  font-size: 25px;
}

.ql-toolbar .ql-titleButtonAumentaTexto::before {
  font-family: "Material Icons";
  content: "zoom_in";
  font-size: 25px;
}

.ql-toolbar .ql-titleButtonDiminuiTexto::before {
  font-family: "Material Icons";
  content: "zoom_out";
  font-size: 25px;
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 15px;
  width: auto;
}
button.ql-imgButton {
  margin-right: 10px;
}
button.ql-insertStyle {
  margin-right: 10px;
}

/* Estilo personalizado para o botão anotherButton */
.ql-toolbar .ql-anotherButton::before {
  font-family: "Material Icons";
  content: "save"; /* Texto visível no botão */
  font-size: 25px;
}

.ql-toolbar .ql-fecharButton::before {
  font-family: "Material Icons";
  content: "close"; /* Texto visível no botão */
  font-size: 25px;
}
.ql-toolbar .ql-anotherButton:hover,
.ql-toolbar .ql-insertStyle:hover {
  background-color: #e0e0e0; /* Cor de fundo ao passar o mouse */
}

.ql-toolbar .ql-insertStyle,
.ql-toolbar .ql-anotherButton,
.ql-toolbar .ql-fecharButton,
.ql-toolbar .ql-titleButtonAumentaTexto,
.ql-toolbar .ql-titleButtonDiminuiTexto {
  background: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  height: 28px !important;
  padding: 0px 0px !important;
  width: 28px !important;
  margin: 5px 0px !important;
}
.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
  background: none !important;
  border: none !important;
  cursor: pointer !important;
  display: inline-block !important;
  height: 28px !important;
  padding: 0 !important;
  width: 28px !important;
  margin: 8px 0px !important;
}
.ql-snow .ql-editor img {
  width: 300px;
  height: auto;
}
</style>
