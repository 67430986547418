<template>
  <div id="app">
    <v-app id="inspire">
      <v-card>
        <v-toolbar color="#333333">
          <v-icon color="white" class="mr-2">people</v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light">
            ACERVO - Clientes
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <div class="px-6 mt-4">
          <v-container fluid grid-list-md>
            <v-layout wrap>
              <v-spacer></v-spacer>
              <v-flex xs6 md2>
                <v-text-field
                  background-color="white"
                  class="mb-2 elevation-0"
                  v-model="idClientePesquisa"
                  v-mask="'#######'"
                  append-icon="search"
                  label="ID"
                  @keyup.enter="limpaFiltro"
                  @click:append="limpaFiltro"
                  autocomplete="disabled"
                  :name="Math.random()"
                  :disabled="loading"
                ></v-text-field>
              </v-flex>
              <v-flex xs6 md2>
                <v-text-field
                  background-color="white"
                  class="mb-2 elevation-0"
                  v-model="identificacaoPesquisa"
                  v-mask="'##############'"
                  append-icon="search"
                  label="Identificação"
                  @keyup.enter="limpaFiltro"
                  @click:append="limpaFiltro"
                  autocomplete="disabled"
                  :name="Math.random()"
                  :disabled="loading"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md2>
                <v-text-field
                  background-color="white"
                  class="mb-2 elevation-0"
                  v-model="nomePesquisa"
                  append-icon="search"
                  label="Nome"
                  @keyup.enter="limpaFiltro"
                  @click:append="limpaFiltro"
                  autocomplete="disabled"
                  :name="Math.random()"
                  :disabled="loading"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>

          <v-data-table
            mobile-breakpoint="0"
            :headers="headers"
            :items="registros"
            :options.sync="options"
            :no-data-text="mensagemPesquisa"
            :footer-props="rodape"
            :disable-sort="carregando"
            :disable-pagination="carregando"
            :loading="loading"
            loading-text="Carregando..."
          >
            <template v-slot:item.actions="{ item }">
              <v-icon color="orange" title="Enviar para Produção" @click="enviar(item)">forward</v-icon>
            </template>
            <template v-slot:item.cliente="{ item }">
              <span v-html="item.cliente == 1 ? 'Sim' : ''"></span>
            </template>
            <template v-slot:item.fornecedor="{ item }">
              <span v-html="item.fornecedor == 1 ? 'Sim' : ''"></span>
            </template>
            <template v-slot:item.agente="{ item }">
              <span v-html="item.agente == 1 ? 'Sim' : ''"></span>
            </template>
            <template v-slot:item.banca="{ item }">
              <span v-html="item.banca == 1 ? 'Sim' : ''"></span>
            </template>
            <template v-slot:item.situacao="{ item }">
              <span v-html="item.situacao == 1 ? 'Ativo' : '<strong>Inativo</strong>'"></span>
            </template>
          </v-data-table>
          <div class="text-xs-center pt-2 marg-bt">
            <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 7" circle></v-pagination>
          </div>
        </div>
        <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
        <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
      </v-card>
    </v-app>
  </div>
</template>

<script>
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
import util from "../../util";
export default {
  data() {
    return {
      headers: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Identificação", value: "identificacao" },
        { text: "Nome", value: "nome" },
        { text: "E-mail", value: "email" },
        { text: "Cliente", value: "cliente" },
        { text: "Fornecedor", value: "fornecedor" },
        { text: "Agente", value: "agente" },
        { text: "Banca", value: "banca" },
        { text: "Situação", value: "situacao" }
      ],

      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },

      inicial: true,
      idSitePrincipal: null,

      direitoMigrar: false,
      direitoCliente: false,
      direitoBanca: false,
      direitoFornecedor: false,
      direitoAnunciante: false,
      direitoAgente: false,
      direitoAssinante: false,
      mensagemPesquisa: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,

      idClientePesquisa: null,
      identificacaoPesquisa: null,
      nomePesquisa: null,

      registros: [],
      registrosPagina: 10,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      carregando: true,
      ordenacao: "",
      ordem: "desc",
      options: {},
      tab: null,
      loading: false
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    limpaFiltro() {
      var pgAnterior = this.paginaAtual;
      this.paginaAtual = 1;
      if (pgAnterior == 1) {
        this.filtroAvancadoFiltrar();
      }
    },
    async filtroAvancadoFiltrar() {
      //* Limpa os registros da tabela
      this.registros = [];
      this.totalRegistros = 0;
      this.totalPaginas = 0;

      if (this.identificacaoPesquisa && !util.valida_cpf(this.identificacaoPesquisa) && !util.valida_cnpj(this.identificacaoPesquisa)) {
        this.mensagem = "Identificação inválida";
        this.snackErro = true;
        return;
      }

      this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

      this.mensagem = "MONTE SEU FILTRO PARA VISUALIZAR OS REGISTROS...";
      this.loading = true;

      //* Filtros do cliente
      var filtroClienteUrl = "";

      if (this.idClientePesquisa) {
        filtroClienteUrl += "&id=" + this.idClientePesquisa;
      }
      if (this.identificacaoPesquisa) {
        if (filtroClienteUrl.includes("identificacao")) {
          this.identificacaoPesquisa = "";
        } else {
          filtroClienteUrl += "&identificacao=" + this.identificacaoPesquisa;
        }
      }
      if (this.nomePesquisa) {
        if (filtroClienteUrl.includes("&nome=")) {
          this.nomePesquisa = "";
        } else {
          filtroClienteUrl += "&nome=" + this.nomePesquisa;
        }
      }

      try {
        //* Consulta os dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "adm/acervo-cliente?" +
            filtroClienteUrl +
            "&pagina=" +
            this.paginaAtual +
            "&tamanho=" +
            this.registrosPagina +
            "&ordenacao=" +
            this.ordenacao +
            "&ordem=" +
            this.ordem
        );

        //* Carrega os dados de retorno
        this.registros = response.data.data;
        this.registros.forEach(element => {
          element.nome = element.nome + (element.nomeFantasia ? " | " + element.nomeFantasia : "");
          if (element.lgpd) {
            element.nome = util.abreviaNome(element.nome);
            element.identificacao = element.identificacao.substring(0, 3) + "***";
            element.email = util.abreviaEmail(element.email);
          }
        });
        this.totalRegistros = response.data.total;
        this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
        this.rodape.pageText = this.totalRegistros + " itens";

        //* Fecha a mensagem de carregando
        this.carregando = false;

        //* Registra caso algum item não seja encontrado
        this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else if (e.response && e.response.data.length > 0) {
          this.mensagemPesquisa = "ERRO: " + e.response.data[0].mensagem;
          this.snackErro = true;
        } else {
          this.mensagemPesquisa = "Erro ao buscar dados: " + e;
          this.snackErro = true;
        }
      } finally {
        this.loading = false;
      }
    },

    async enviar(cliente) {
      try {
        var index = this.registros.indexOf(cliente);
        this.registros.splice(index, 1);

        const retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "adm/acervo-cliente/producao/" + cliente.id + "?idSite=" + this.$store.state.usuario.idSitePrincipal);
        this.mensagem = "Cliente foi enviado para a base de produção";
        this.snackbar = true;
        if (
          retorno.data.id &&
          ((cliente.cliente && (this.direitoAssinante || this.direitoAnunciante || this.direitoCliente)) ||
            (cliente.banca && this.direitoBanca) ||
            (cliente.fornecedor && this.direitoFornecedor) ||
            (cliente.agente && this.direitoAgente))
        ) {
          this.mensagem =
            (this.direitoAssinante
              ? "Assinante"
              : this.direitoAnunciante
              ? "Anunciante"
              : this.direitoAgente
              ? "Agente"
              : this.direitoFornecedor
              ? "Fornecedor"
              : this.direitoBanca
              ? "Banca"
              : "Cliente") + " foi enviado para a base de produção. Acessando seu cadastro...";
          this.snackbar = true;
          setTimeout(() => {
            this.$router.push(
              "/" +
                (this.direitoAssinante && cliente.cliente
                  ? "assinaturas/assinante/"
                  : this.direitoAnunciante && cliente.cliente
                  ? "comercial/anunciante"
                  : this.direitoCliente && cliente.cliente
                  ? "financeiro/cliente/"
                  : this.direitoAgente && cliente.agente
                  ? "administrativo/agente/"
                  : this.direitoFornecedor && cliente.fornecedor
                  ? "financeiro/fornecedor/"
                  : this.direitoBanca && cliente.banca
                  ? "assinaturas/banca/"
                  : "") +
                retorno.data.id
            );
          }, 3000);
        }
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    }
  },

  beforeMount() {
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ADMINISTRATIVO_ACERVO_CLIENTES_ACESSO"]);
    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;

      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ADMINISTRATIVO_ACERVO_CLIENTES_MIGRAR") {
        this.direitoMigrar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_BANCAS_EDITAR") {
        this.direitoBanca = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_FORNECEDORES_EDITAR") {
        this.direitoFornecedor = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ADMINISTRATIVO_AGENTES_EDITAR") {
        this.direitoAgente = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_ANUNCIANTES_EDITAR") {
        this.direitoAnunciante = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_ASSINANTES_EDITAR") {
        this.direitoAssinante = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_CLIENTES_EDITAR") {
        this.direitoCliente = true;
      }
    });
  },

  watch: {
    paginaAtual: function() {
      this.filtroAvancadoFiltrar();
    },

    options: {
      handler() {
        this.ordem = "desc";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }

        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }

        if (!this.inicial) {
          this.filtroAvancadoFiltrar();
        } else {
          this.mensagemPesquisa = "MONTE SEU FILTRO PARA VISUALIZAR OS REGISTROS...";
          this.inicial = false;
        }
      }
    }
  }
};
</script>
