<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">view_week</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Produto</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12 md12 v-if="siteGrupo.length > 0">
            <v-autocomplete
              v-model="produto.idSite"
              :disabled="isUpdating"
              :items="siteGrupo"
              :filter="filtrarObjetos"
              label="Restrito ao Site"
              item-text="nome"
              item-value="id"
              :rules="[validacao.idSite == false || validacao.idSite]"
              clearable
              no-data-text
              @change="validacao.idSite = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md12>
            <v-text-field
              autofocus
              v-model="produto.nome"
              label="Nome do Produto"
              maxlength="50"
              hint="Obrigatório"
              :rules="[validacao.nome == false || validacao.nome, value => !!value || 'Obrigatório.', value => value.length > 2 || 'Preencha o nome do motivo de cancelamento']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="
                produto.nome = pLetra(produto.nome);
                validacao.nome = false;
              "
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md12>
            <v-autocomplete
              v-model="produto.tipo"
              :items="tipos"
              :filter="filtrarItens"
              :name="Math.random()"
              hint="Obrigatório"
              :rules="[validacao.tipo == false || validacao.tipo, value => !!value || 'Obrigatório.']"
              label="Selecione o Tipo de Produto"
              no-data-text
              @change="
                validacao.tipo = false;
                produto.vendaAvulsa = produto.tipo != 'IMPRESSO' ? 0 : produto.vendaAvulsa;
              "
              :disabled="isUpdating || (id != '' && !direitoAvancado)"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 v-if="produto.tipo == 'CLUBE'">
            <v-autocomplete
              v-model="produto.dependentes"
              :disabled="isUpdating"
              :items="dependentes"
              :filter="filtrarObjetos"
              label="Dependentes"
              item-text="nome"
              item-value="id"
              :rules="[validacao.dependentes == false || validacao.dependentes]"
              no-data-text
              @change="validacao.dependentes = false"
            >
              <template v-slot:selection="data">
                <v-chip color="blue lighten-3">
                  {{ data.item.nome }}
                </v-chip>
                <span class="ml-2">{{ data.item.descricao }}</span>
              </template>

              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.nome"></v-list-item-title>
                  <v-list-item-subtitle v-html="data.item.descricao"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-flex>

          <v-flex xs12 md12>
            <v-switch
              v-model="produto.vendaAvulsa"
              :label="`${produto.vendaAvulsa == 1 ? 'Produto utilizado na Venda Avulsa' : 'Produto não é utilizado na venda avulsa'}`"
              :disabled="isUpdating || (id != '' && produto.vendaAvulsa && !direitoAvancado) || produto.tipo != 'IMPRESSO'"
            ></v-switch>
          </v-flex>
          <v-flex xs12 md12>
            <v-switch
              v-model="produto.situacao"
              :label="`${produto.situacao == 1 ? 'Ativo' : 'Inativo'}`"
              :disabled="isUpdating || (id != '' && produto.situacao == 1 && !direitoAvancado)"
            ></v-switch>
          </v-flex>
          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">
              {{ validacao.mensagemErro }}
            </v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn to="/assinaturas/produtos" id="btn-rsn" color="orange lighten-2 white" style="color:white"> <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar </v-btn>
      <v-btn :loading="isUpdating" color="blue white" id="btn-rsn" style="color:white" depressed @click="salvar('false')"> <v-icon id="icon-rsn" left>update</v-icon>Salvar </v-btn>

      <v-btn :loading="isUpdating" color="orange white" style="color:white" id="btn-rsn" outlined @click="salvar('retornar')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>
      <v-btn :loading="isUpdating" color="blue white" id="btn-rsn" style="color:white" outlined @click="salvar('true')" v-if="direitoCriar">
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Novo
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      produto: {
        id: "",
        idSite: null,
        nome: "",
        tipo: null,
        dependentes: 1,
        vendaAvulsa: 0,
        situacao: 1
      },
      validacao: {
        id: false,
        idSite: false,
        nome: false,
        tipo: false,
        dependentes: false,
        vendaAvulsa: false,
        situacao: false,
        mensagemErro: false
      },

      siteGrupo: [],
      tipos: ["IMPRESSO", "ONLINE", "CLUBE"],
      dependentes: [
        { id: 0, nome: "NÃO", descricao: "Número de Dependentes não faz parte do cálculo da assinatura" },
        {
          id: 1,
          nome: "SIM",
          descricao: "Valor do produto é calculado com base no número de Dependentes ativos do assinante"
        }
      ],

      idSitePrincipal: null,
      direitoCriar: false,
      direitoAvancado: false,
      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }
      if (this.produto.tipo != "CLUBE") {
        this.produto.dependentes = 0;
      }

      this.isUpdating = true;
      try {
        var retorno = "";
        //se tiver id é um update
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "ass/produto/" + this.id, this.produto);
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "ass/produto?idSite=" + this.idSitePrincipal, this.produto);
          this.id = retorno.data.id;
          this.produto.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "true") {
          this.$router.push("/assinaturas/produto");
          this.$router.go("/assinaturas/produto");
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push("/assinaturas/produtos");
          }, 2000);
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },

    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    filtrarItens(item, queryText, itemText) {
      return util.filtrarItens(item, queryText, itemText);
    },
    pLetra(text) {
      return util.pLetra(text);
    }
  },

  async created() {
    this.id = this.$route.params.id ? this.$route.params.id : "";

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_PRODUTOS_CRIAR") {
        this.direitoCriar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_PRODUTOS_EDITAR_AVANÇADO") {
        this.direitoAvancado = true;
      }
    });

    //* Verifica se o site do usuário está em GRUPO - OBS: ou ele compartilha a informação ou deixa exclusiva para o seu site
    var idGrupoSite = this.$store.state.usuario.site_idGrupo;
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
    if (idGrupoSite != null) {
      this.siteGrupo = [
        {
          id: this.idSitePrincipal,
          nome: this.$store.state.usuario.site_nome
        }
      ];
    }

    try {
      //* Se é edição, carrega o elemento
      if (this.id == "") {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_PRODUTOS_CRIAR"]);
      } else {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_PRODUTOS_EDITAR"]);
        //* Carrega o elemento - somente se for público (idSite=null) ou for do site do usuário logado
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/produto/" + this.id + "?idSite=" + this.idSitePrincipal);
        if (!response.data) {
          this.$router.push("/assinaturas/produtos");
        } else {
          this.produto = response.data;
        }
      }
    } catch {
      this.mensagem = "Ocorreu um erro ao carregar os dados";
      this.snackErro = true;
    }
  }
};
</script>
