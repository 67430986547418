<template>
  <v-card>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">mdi-account</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Registro de Alterações</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="retornar">
        <v-icon color="white">cancel</v-icon>
      </v-btn>
    </v-toolbar>
    <div class="px-6 mt-4">
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <div class="px-6 mt-4">
      <v-carousel light v-model="model" :continuous="false">
        <v-carousel-item v-for="item in registros" :key="item._id">
          <div class="px-6 mt-4">
            <v-container fluid grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-card class="overflow-y-auto" height="420">
                    <div class="px-6 mt-4">
                      <v-layout wrap>
                        <v-flex xs12 md2>
                          <v-text-field v-model="item.id" label="ID" readonly></v-text-field>
                        </v-flex>
                        <v-flex xs12 md2>
                          <v-text-field v-model="item.operacao" label="Operação" readonly></v-text-field>
                        </v-flex>
                        <v-flex xs12 md5>
                          <v-text-field v-model="item.usuario" label="Usuário" readonly></v-text-field>
                        </v-flex>
                        <v-flex xs12 md3>
                          <v-text-field v-model="item.dtRegistro" label="Data" readonly></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                          <h4 class="overline font-weight-black font-italic">
                            {{ item.tipo }}
                          </h4>
                          <v-divider></v-divider>
                        </v-flex>
                        <v-flex xs12 md12 v-for="dado in item.dados" :key="dado.id">
                          <h4 class="overline font-italic" v-if="dado.valor == '###SUBITEM###'">
                            {{ dado.campo }}
                          </h4>

                          <v-text-field :class="dado.desloca ? 'ml-4' : ''" v-if="dado.valor != '###SUBITEM###'" v-model="dado.valor" :label="dado.campo" readonly></v-text-field>
                        </v-flex>
                      </v-layout>
                    </div>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
          </div>
        </v-carousel-item>
      </v-carousel>
      <div class="text-xs-center pt-2 marg-bt">
        <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 7" circle></v-pagination>
      </div>

      <v-divider></v-divider>

      <v-card-actions class="card-btn">
        <v-spacer></v-spacer>

        <v-btn class="sub-btn" color="blue darken-1" @click="retornar">Retornar</v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  props: {
    idParcela: { type: String, default: "" },
    idAssinatura: { type: String, default: "" },
    idAnuncio: { type: String, default: "" },
    idPublicacao: { type: String, default: "" },
    idBloqueio: { type: String, default: "" },
    idOperacao: { type: String, default: "" },
    idTabelaPreco: { type: String, default: "" },
    idCliente: { type: String, default: "" },
    idClienteEndereco: { type: String, default: "" },
    idSite: { type: String, default: "" },
    idCedente: { type: String, default: "" },
    idConta: { type: String, default: "" }
  },

  data() {
    return {
      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },
      nNrosAnteriores: "",
      lotesAnteriores: "",
      filtroPesquisa: "",
      registros: [],
      usuarios: [],
      idSitePrincipal: null,
      registrosPagina: 10,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      ordenacao: "",
      ordem: "",
      options: {},
      model: 0,
      isUpdating: false
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    limpaFiltro() {
      this.paginaAtual = 1;
      this.pesquisaDados();
    },
    async pesquisaDados() {
      this.isUpdating = true;
      this.registros = [];
      if (
        !this.idParcela &&
        !this.idAssinatura &&
        !this.idAnuncio &&
        !this.idPublicacao &&
        !this.idBloqueio &&
        !this.idOperacao &&
        !this.idTabelaPreco &&
        !this.idCliente &&
        !this.idClienteEndereco &&
        !this.idSite &&
        !this.idCedente &&
        !this.idConta
      ) {
        return;
      }
      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "log-mongo" +
            (this.idParcela
              ? "/parcela"
              : this.idAssinatura
              ? "/assinatura"
              : this.idAnuncio
              ? "/anuncio"
              : this.idBloqueio
              ? "/celesc_bloqueio"
              : this.idOperacao
              ? "/operacao"
              : this.idTabelaPreco
              ? "/anuncio_tabela"
              : this.idCliente
              ? "/cliente"
              : this.idClienteEndereco
              ? "/cliente_endereco"
              : this.idSite
              ? "/site"
              : this.idCedente
              ? "/cedente"
              : this.idConta
              ? "/conta"
              : "/anuncio_publicacao") +
            "/" +
            (this.idParcela ||
              this.idAssinatura ||
              this.idAnuncio ||
              this.idPublicacao ||
              this.idOperacao ||
              this.idBloqueio ||
              this.idTabelaPreco ||
              this.idCliente ||
              this.idClienteEndereco ||
              this.idSite ||
              this.idCedente ||
              this.idConta) +
            "?pagina=" +
            this.paginaAtual +
            "&tamanho=" +
            this.registrosPagina +
            "&filtro=" +
            this.filtroPesquisa +
            "&ordenacao=" +
            this.ordenacao +
            "&ordem=" +
            this.ordem
        );

        //seta as variaveis com o retorno
        this.registros = [];
        response.data.itens.forEach(element => {
          var dadoCommit = {};
          dadoCommit.id = element.id;
          dadoCommit.operacao = element.operacao;
          this.usuarios.forEach(usuario => {
            if (element.idUsuario == usuario.id) {
              dadoCommit.usuario = usuario.nome;
            }
          });
          dadoCommit.dtRegistro = util.formatDateTime(element.dtRegistro);
          if (element.tipo == "POST") {
            dadoCommit.tipo = "INCLUSÃO REALIZADA";
          } else if (element.tipo == "PUT") {
            dadoCommit.tipo = "ALTERADO PARA";
          }
          dadoCommit.dados = [];

          var keys = Object.keys(element);
          var ct = 0;
          for (var i = 0; i < keys.length; i++) {
            if (!["_id", "id", "tabela", "tipo", "idUsuario", "dtRegistro", "operacao"].includes(keys[i])) {
              var chave = keys[i];
              var valor = element[keys[i]];
              if (chave == "senha" && valor.length > 4) {
                valor = valor.substring(0, 4) + "***";
              }
              if (chave == "fbAppId" && valor) {
                valor = "***";
              }
              if (chave == "fbAppKey" && valor) {
                valor = "***";
              }
              if (chave == "gpAppId" && valor) {
                valor = "***";
              }
              if (chave == "gpAppKey" && valor) {
                valor = "***";
              }
              if (chave == "twAppId" && valor) {
                valor = "***";
              }
              if (chave == "twAppKey" && valor) {
                valor = "***";
              }
              if (chave == "inAppId" && valor) {
                valor = "***";
              }
              if (chave == "inAppKey" && valor) {
                valor = "***";
              }
              if (chave == "googleCaptchaSite" && valor) {
                valor = "***";
              }
              if (chave == "googleCaptchaSecret" && valor) {
                valor = "***";
              }
              if (chave == "cieloMerchantId" && valor) {
                valor = "***";
              }
              if (chave == "cieloMerchantKey" && valor) {
                valor = "***";
              }
              if (chave == "mundipaggKey" && valor) {
                valor = "***";
              }
              if (chave == "takeBlipKey" && valor) {
                valor = "***";
              }
              if (chave == "swUser" && valor) {
                valor = "***";
              }
              if (chave == "swSenhaCert" && valor) {
                valor = "***";
              }
              if (chave == "swIdentifierCA" && valor) {
                valor = "***";
              }
              if (chave == "firebaseKey" && valor) {
                valor = "***";
              }
              if (chave == "urlProtheus" && valor) {
                valor = "***";
              }
              if (chave == "tokenAsaas" && valor) {
                valor = "***";
              }
              if (chave == "mapsApiKey" && valor) {
                valor = "***";
              }

              if (Array.isArray(valor) && valor.length > 0) {
                valor.forEach(valores => {
                  var kItem = Object.keys(valores);
                  ct++;
                  dadoCommit.dados.push({
                    id: ct,
                    campo: chave,
                    valor: "###SUBITEM###"
                  });
                  for (let ii = 0; ii < kItem.length; ii++) {
                    var chItem = kItem[ii];
                    var vlrItem = valores[kItem[ii]];
                    if (chItem == "senha" && vlrItem.length > 4) {
                      vlrItem = vlrItem.substring(0, 4) + "***";
                    }
                    if (util.formatDate(vlrItem)) {
                      if (vlrItem.length > 10 || vlrItem.includes(" ")) {
                        vlrItem = util.formatDateTime(vlrItem);
                      } else {
                        vlrItem = util.formatDate(vlrItem);
                      }
                    }
                    ct++;
                    dadoCommit.dados.push({
                      id: ct,
                      campo: chItem,
                      valor: vlrItem,
                      desloca: true
                    });
                  }
                });
              } else {
                if (util.formatDate(valor)) {
                  if (valor.length > 10 || valor.includes(" ")) {
                    valor = util.formatDateTime(valor);
                  } else {
                    valor = util.formatDate(valor);
                  }
                }
                ct++;
                dadoCommit.dados.push({
                  id: ct,
                  campo: chave,
                  valor: valor,
                  desloca: false
                });
              }
            }
          }
          this.registros.push(dadoCommit);
        });

        this.totalRegistros = response.data.total;
        this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
        this.rodape.pageText = this.totalRegistros + " itens";
        setTimeout(() => {
          this.isUpdating = false;
        }, 1000);
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
        }
      }
    },
    async carregarUsuarios() {
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/usuario/all?idSite=" + this.idSitePrincipal);
      if (response.data) {
        this.usuarios = response.data;
      }
    },
    retornar() {
      this.$emit("update:dialogLogAlteracao", false);
    }
  },
  beforeMount() {
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_LOG_PARCELAS_ACESSO", "COMERCIAL_ANÚNCIOS_LOG"]);
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
    this.carregarUsuarios();
  },

  watch: {
    idParcela() {
      this.paginaAtual = 1;
      this.model = 0;
      this.pesquisaDados();
    },
    idAssinatura() {
      this.paginaAtual = 1;
      this.model = 0;
      this.pesquisaDados();
    },
    idAnuncio() {
      this.paginaAtual = 1;
      this.model = 0;
      this.pesquisaDados();
    },
    idPublicacao() {
      this.paginaAtual = 1;
      this.model = 0;
      this.pesquisaDados();
    },
    idBloqueio() {
      this.paginaAtual = 1;
      this.model = 0;
      this.pesquisaDados();
    },
    idOperacao() {
      this.paginaAtual = 1;
      this.model = 0;
      this.pesquisaDados();
    },
    idTabelaPreco() {
      this.paginaAtual = 1;
      this.model = 0;
      this.pesquisaDados();
    },
    idCliente() {
      this.paginaAtual = 1;
      this.model = 0;
      this.pesquisaDados();
    },
    idClienteEndereco() {
      this.paginaAtual = 1;
      this.model = 0;
      this.pesquisaDados();
    },
    idSite() {
      this.paginaAtual = 1;
      this.model = 0;
      this.pesquisaDados();
    },
    paginaAtual() {
      this.model = 0;
      this.pesquisaDados();
    },
    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }
        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }
        this.pesquisaDados();
      }
    }
  },
  created() {
    this.pesquisaDados();
  }
};
</script>
