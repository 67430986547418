<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">track_changes</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">IVC - Município Adjacente</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12 md6>
            <v-autocomplete
              autofocus
              v-model="idUF"
              :disabled="isUpdating"
              :items="ufs"
              hint="Obrigatório"
              :rules="[value => !!value || 'Obrigatório.']"
              :filter="filtrarUFs"
              label="Selecione o Estado"
              item-text="nome"
              item-value="id"
              autocomplete="disabled"
              :name="Math.random()"
              no-data-text
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md6>
            <v-autocomplete
              v-model="site_municipio.idMunicipio"
              :disabled="isUpdating"
              :items="municipios"
              :filter="filtrarObjetos"
              hint="Obrigatório"
              :rules="[validacao.idMunicipio == false || validacao.idMunicipio, value => !!value || 'Obrigatório.']"
              label="Selecione o Município"
              item-text="nome"
              item-value="id"
              autocomplete="disabled"
              :name="Math.random()"
              no-data-text="Selecione um Estado"
              @change="validacao.idMunicipio = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">{{ validacao.mensagemErro }}</v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn to="/assinaturas/municipios-ivc" id="btn-rsn" color="orange lighten-2 white" style="color:white">
        <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar
      </v-btn>
      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="blue white" id="btn-rsn" style="color:white" depressed @click="salvar('false')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar
      </v-btn>
      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="orange white" style="color:white" id="btn-rsn" outlined @click="salvar('retornar')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>

      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="blue white" style="color:white" id="btn-rsn" outlined @click="salvar('true')" v-if="direitoCriar">
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Nova
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      site_municipio: {
        id: "",
        idSite: null,
        idMunicipio: null
      },
      validacao: {
        id: false,
        idSite: false,
        idMunicipio: false,
        mensagemErro: false
      },

      idUF: null,
      ufs: [],
      municipios: [],

      idSitePrincipal: null,
      direitoCriar: false,
      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false,
      bloqueiaBotoes: false
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      this.isUpdating = true;
      try {
        var retorno = "";
        // if (this.id) {
        //   retorno = await api.put(
        //     process.env.VUE_APP_URL_WEB_API + "adm/site-municipio/" + this.id,
        //     this.site_municipio
        //   );
        // } else {
        retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "adm/site-municipio?idSite=" + this.idSitePrincipal, this.site_municipio);
        this.id = retorno.data.id;
        this.site_municipio.id = retorno.data.id;
        // }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "true") {
          this.$router.push("/assinaturas/municipio-ivc");
          this.$router.go("/assinaturas/municipio-ivc");
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push("/assinaturas/municipios-ivc");
          }, 2000);
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },
    carregarMunicipios() {
      this.municipios = [];
      if (this.idUF) {
        //* Carrega os municípios da UF
        api
          .get(process.env.VUE_APP_URL_WEB_API + "adm/municipio/lista?idUF=" + this.idUF)
          .then(response => {
            this.municipios = response.data;
          })
          .catch(e => {
            this.isUpdating = true;
            this.mensagem = "Ocorreu um erro ao carregar a lista de municípios da UF: " + e;
            this.snackErro = true;
          });
      }
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    filtrarUFs(item, queryText, itemText) {
      return util.filtrarUFs(item, queryText, itemText);
    },
    pLetra(text) {
      return util.pLetra(text);
    },
    valida_nome(text) {
      return util.valida_nome(text);
    }
  },
  async created() {
    this.isUpdating = false;
    this.bloqueiaBotoes = true;
    this.id = this.$route.params.id ? this.$route.params.id : "";

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_MUNICÍPIOS_IVC_CRIAR") {
        this.direitoCriar = true;
      }
    });

    //* Carrega as UFs
    api
      .get(process.env.VUE_APP_URL_WEB_API + "adm/uf/lista")
      .then(response => {
        this.ufs = response.data;
      })
      .catch(e => {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de UFs: " + e;
        this.snackErro = true;
      });

    //* Se é edição, carrega o item
    if (this.id == "") {
      this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_MUNICÍPIOS_IVC_CRIAR"]);
      this.site_municipio.idSite = this.$store.state.usuario.idSitePrincipal;
      setTimeout(() => {
        this.idUF = this.$store.state.usuario.site_idUF;
      }, 1000);
    } else {
      // this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_ROTAS_EDITAR"]);
      // try {
      //   //* Carrega o item - somente se for público (idSite=null) ou for do site do usuário logado
      //   const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/site-municipio/" + this.id + "?idSite=" + this.idSitePrincipal);
      //   if (!response.data) {
      //     this.$router.push("/assinaturas/municipios-ivc");
      //   } else {
      //     this.site_municipio = response.data;
      //   }
      // } catch {
      //   this.isUpdating = true;
      //   this.mensagem = "Ocorreu um erro ao carregar os dados do municipio do site";
      //   this.snackErro = true;
      // }
      // //* Define a UF do agente e carrega seus municípios
      // if (this.site_municipio.idMunicipio) {
      //   api
      //     .get(process.env.VUE_APP_URL_WEB_API + "adm/municipio/" + this.site_municipio.idMunicipio)
      //     .then(response => {
      //       this.idUF = response.data.idUF;
      //       this.carregarMunicipios(this.idUF);
      //     })
      //     .catch(() => {
      //       this.isUpdating = true;
      //       this.mensagem = "Ocorreu um erro ao carregar " + (this.$store.state.usuario.site_pais == "BR" ? "a UF do município" : "o concelho do distrito");
      //       this.snackErro = true;
      //     });
      // }
    }
    setTimeout(() => {
      this.bloqueiaBotoes = false;
    }, 1000);
  },
  watch: {
    idUF() {
      this.carregarMunicipios();
    }
  }
};
</script>
