<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">short_text</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Tipo de Logradouro</v-toolbar-title>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12 md6>
            <v-text-field
              autofocus
              v-model="tipoLogradouro.nome"
              label="Tipo"
              maxlength="15"
              hint="Obrigatório"
              :rules="[
                validacao.nome == false || validacao.nome,
                value => !!value || 'Obrigatório.',
                value => value.split(' ').length == 1 || 'Nome inválido',
                value => value.length > 2 || 'Preencha o tipo de logradouro'
              ]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="
                tipoLogradouro.nome = pLetra(tipoLogradouro.nome);
                validacao.nome = false;
              "
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md6>
            <v-text-field
              autofocus
              v-model="tipoLogradouro.sigla"
              label="Sigla"
              maxlength="4"
              hint="Obrigatório"
              :rules="[validacao.sigla == false || validacao.sigla, value => !!value || 'Obrigatório.', value => value.split(' ').length == 1 || 'Sigla inválida']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="
                tipoLogradouro.sigla = pLetra(tipoLogradouro.sigla);
                validacao.sigla = false;
              "
            ></v-text-field>
          </v-flex>

          <v-flex xs6 md6>
            <v-switch v-model="tipoLogradouro.situacao" :label="`${tipoLogradouro.situacao == 1 ? 'Ativo' : 'Inativo'}`"></v-switch>
          </v-flex>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">{{ validacao.mensagemErro }}</v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn to="/administrativo/tipos-logradouro" id="btn-rsn" color="orange lighten-2 white" style="color:white">
        <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar
      </v-btn>
      <v-btn :loading="isUpdating" color="blue white" style="color:white" id="btn-rsn" depressed @click="salvar('false')"> <v-icon id="icon-rsn" left>update</v-icon>Salvar </v-btn>
      <v-btn :loading="isUpdating" color="orange white" style="color:white" id="btn-rsn" outlined @click="salvar('retornar')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>
      <v-btn :loading="isUpdating" color="blue white" style="color:white" id="btn-rsn" outlined @click="salvar('true')" v-if="direitoCriar">
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Nova
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      tipoLogradouro: {
        id: "",
        nome: "",
        sigla: "",
        situacao: 1
      },
      validacao: {
        id: false,
        nome: false,
        sigla: false,
        situacao: false,
        mensagemErro: false
      },

      idSitePrincipal: null,
      direitoCriar: false,
      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      this.isUpdating = true;
      try {
        var retorno = "";
        //se tiver id é um update
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "adm/tipo-logradouro/" + this.id, this.tipoLogradouro);
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "adm/tipo-logradouro?idSite=" + this.idSitePrincipal, this.tipoLogradouro);
          this.id = retorno.data.id;
          this.tipoLogradouro.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "true") {
          this.$router.push((this.rota.includes("/assinaturas") ? "/assinaturas/" : "/administrativo/") + "tipo-logradouro");
          this.$router.go((this.rota.includes("/assinaturas") ? "/assinaturas/" : "/administrativo/") + "tipo-logradouro");
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push((this.rota.includes("/assinaturas") ? "/assinaturas/" : "/administrativo/") + "tipos-logradouro");
          }, 2000);
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },
    pLetra(text) {
      return util.pLetra(text);
    }
  },
  computed: {
    rota() {
      return this.$route.path;
    }
  },
  async created() {
    this.id = this.$route.params.id ? this.$route.params.id : "";

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ADMINISTRATIVO_TIPOS_LOGRADOURO_CRIAR") {
        this.direitoCriar = true;
      }
    });

    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

    try {
      //* Se é edição, carrega o item
      if (this.id == "") {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ADMINISTRATIVO_TIPOS_LOGRADOURO_CRIAR"]);
      } else {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ADMINISTRATIVO_TIPOS_LOGRADOURO_EDITAR"]);
        //* Carrega o item - somente se for público (idSite=null) ou for do site do usuário logado
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/tipo-logradouro/" + this.id);

        if (!response.data) {
          this.$router.push((this.rota.includes("/assinaturas") ? "/assinaturas/" : "/administrativo/") + "tipos-logradouro");
        } else {
          this.tipoLogradouro = response.data;
        }
      }
    } catch {
      this.mensagem = "Ocorreu um erro ao carregar os dados";
      this.snackErro = true;
    }
  }
};
</script>
