<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">chrome_reader_mode</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Tabela de Venda Avulsa</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12 md6>
            <v-autocomplete
              autofocus
              v-model="vendaAvulsa.idProduto"
              :disabled="isUpdating"
              :items="produtos"
              :filter="filtrarObjetos"
              label="Selecione o Produto"
              hint="Obrigatório"
              :rules="[validacao.idProduto == false || validacao.idProduto, value => !!value || 'Obrigatório.']"
              item-text="nome"
              item-value="id"
              no-data-text
              @change="validacao.idProduto = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 offset-md3 md3>
            <v-menu ref="mnuDtInicio" v-model="mnuDtInicio" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dtInicio"
                  label="Data Inicial"
                  v-mask="'##/##/####'"
                  hint="Obrigatório"
                  :rules="[validacao.dtInicio == false || validacao.dtInicio, value => valida_data(value) || 'Data inválida', value => !!value || 'Obrigatório.']"
                  :disabled="isUpdating"
                  prepend-icon="event"
                  v-on="on"
                  color="blue-grey lighten-2"
                  autocomplete="disabled"
                  :name="Math.random()"
                  @keyup="dtInicioUS = valida_data(dtInicio) ? formatDateUS(dtInicio) : null"
                  @change="validacao.dtInicio = false"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dtInicioUS" locale="pt-br" no-title @input="mnuDtInicio = false"></v-date-picker>
            </v-menu>
          </v-flex>

          <v-flex xs12>
            <h4 class="overline font-weight-black font-italic">
              Preços
            </h4>
            <v-divider></v-divider>
          </v-flex>

          <v-flex xs6 md3>
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="vendaAvulsa.vlrDom"
              label="Domingo"
              :allowNegative="false"
              :max="9999"
              :rules="[validacao.vlrDom == false || validacao.vlrDom, value => !!value || 'Obrigatório.', value => (value && value.length < 9) || 'Verifique o valor']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.vlrDom = false"
            ></v-currency-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="vendaAvulsa.vlrSeg"
              label="Segunda-Feira"
              :allowNegative="false"
              :max="9999"
              :rules="[validacao.vlrSeg == false || validacao.vlrSeg, value => !!value || 'Obrigatório.', value => (value && value.length < 9) || 'Verifique o valor']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.vlrSeg = false"
            ></v-currency-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="vendaAvulsa.vlrTer"
              label="Terça-Feira"
              :allowNegative="false"
              :max="9999"
              :rules="[validacao.vlrTer == false || validacao.vlrTer, value => !!value || 'Obrigatório.', value => (value && value.length < 9) || 'Verifique o valor']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.vlrTer = false"
            ></v-currency-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="vendaAvulsa.vlrQua"
              label="Quarta-Feira"
              :allowNegative="false"
              :max="9999"
              :rules="[validacao.vlrQua == false || validacao.vlrQua, value => !!value || 'Obrigatório.', value => (value && value.length < 9) || 'Verifique o valor']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.vlrQua = false"
            ></v-currency-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="vendaAvulsa.vlrQui"
              label="Quinta-Feira"
              :allowNegative="false"
              :max="9999"
              :rules="[validacao.vlrQui == false || validacao.vlrQui, value => !!value || 'Obrigatório.', value => (value && value.length < 9) || 'Verifique o valor']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.vlrQui = false"
            ></v-currency-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="vendaAvulsa.vlrSex"
              label="Sexta-Feira"
              :allowNegative="false"
              :max="9999"
              :rules="[validacao.vlrSex == false || validacao.vlrSex, value => !!value || 'Obrigatório.', value => (value && value.length < 9) || 'Verifique o valor']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.vlrSex = false"
            ></v-currency-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="vendaAvulsa.vlrSab"
              label="Sábado"
              :allowNegative="false"
              :max="9999"
              :rules="[validacao.vlrSab == false || validacao.vlrSab, value => !!value || 'Obrigatório.', value => (value && value.length < 9) || 'Verifique o valor']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.vlrSab = false"
            ></v-currency-field>
          </v-flex>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">
              {{ validacao.mensagemErro }}
            </v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn to="/assinaturas/venda-avulsa-tabelas" color="orange lighten-2 white" id="btn-rsn" style="color:white">
        <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar
      </v-btn>
      <v-btn v-if="dataFutura" :loading="isUpdating" :disabled="bloqueiaBotoes" color="blue white" id="btn-rsn" style="color:white" depressed @click="salvar('false')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar
      </v-btn>

      <v-btn v-if="dataFutura" :loading="isUpdating" :disabled="bloqueiaBotoes" color="orange white" style="color:white" id="btn-rsn" outlined @click="salvar('retornar')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>

      <v-btn
        :loading="isUpdating"
        :disabled="bloqueiaBotoes"
        color="blue white"
        style="color:white"
        id="btn-rsn"
        outlined
        @click="salvar('true')"
        v-if="direitoCriar && dataFutura"
      >
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Novo
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      vendaAvulsa: {
        id: "",
        idProduto: null,
        dtInicio: null,
        vlrDom: 0,
        vlrSeg: 0,
        vlrTer: 0,
        vlrQua: 0,
        vlrQui: 0,
        vlrSex: 0,
        vlrSab: 0
      },
      validacao: {
        id: false,
        idProduto: false,
        dtInicio: false,
        vlrDom: false,
        vlrSeg: false,
        vlrTer: false,
        vlrQua: false,
        vlrQui: false,
        vlrSex: false,
        vlrSab: false,
        mensagemErro: false
      },
      dtInicio: null,
      dtInicioUS: null,
      mnuDtInicio: false,

      produtos: [],

      idSitePrincipal: null,
      dataFutura: true,
      direitoCriar: false,
      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false,
      bloqueiaBotoes: false
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }
      if (this.dtInicio != null) {
        if (util.valida_data(util.formatDateUS(this.dtInicio))) {
          this.vendaAvulsa.dtInicio = util.formatDateUS(this.dtInicio);
        } else {
          this.validacao.dtInicio = "Data inválida";
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário"; //não deveria cair aqui
          this.snackErro = true;
          return;
        }
      } else {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário"; //não deveria cair aqui
        this.snackErro = true;
        return;
      }

      this.isUpdating = true;
      try {
        var retorno = "";
        //se tiver id é um update
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "ass/venda-avulsa-tabela/" + this.id, this.vendaAvulsa);
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "ass/venda-avulsa-tabela?idSite=" + this.idSitePrincipal, this.vendaAvulsa);
          this.id = retorno.data.id;
          this.vendaAvulsa.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "true") {
          this.$router.push("/assinaturas/venda-avulsa-tabela");
          this.$router.go("/assinaturas/venda-avulsa-tabela");
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push("/assinaturas/venda-avulsa-tabelas");
          }, 2000);
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },
    async carregarProdutos() {
      api
        .get(process.env.VUE_APP_URL_WEB_API + "ass/produto/lista?idSite=" + this.idSitePrincipal + "&vendaAvulsa=1")
        .then(response => {
          this.produtos = response.data;
          if (this.produtos.length == 1) {
            this.vendaAvulsa.idProduto = this.produtos[0].id;
          }
        })
        .catch(e => {
          this.isUpdating = true;
          this.mensagem = "Ocorreu um erro ao carregar a lista de produtos de venda avulsa: " + e;
          this.snackErro = true;
        });
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    valida_data(date) {
      if (date) {
        return util.valida_data_BR(date);
      } else {
        return true;
      }
    },
    formatDateUS(date) {
      return util.formatDateUS(date);
    }
  },

  watch: {
    dtInicioUS() {
      this.dtInicio = util.formatDate(this.dtInicioUS);
      this.mnuDtInicio = false;
    }
  },

  async created() {
    this.isUpdating = false;
    this.bloqueiaBotoes = true;
    this.id = this.$route.params.id ? this.$route.params.id : "";

    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_VENDA_AVULSA_TABELA_CRIAR") {
        this.direitoCriar = true;
      }
    });

    await this.carregarProdutos();

    //* Se é edição, carrega o elemento
    if (this.id == "") {
      this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_VENDA_AVULSA_TABELA_CRIAR"]);
    } else {
      this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_VENDA_AVULSA_TABELA_EDITAR"]);
      try {
        //* Carrega o elemento - somente se for público (idSite=null) ou for do site do usuário logado
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/venda-avulsa-tabela/" + this.id + "?idSite=" + this.idSitePrincipal);
        if (!response.data) {
          this.$router.push("/assinaturas/venda-avulsa-tabelas");
        } else {
          this.vendaAvulsa = response.data;
          this.dtInicioUS = util.dateToStringUS(this.vendaAvulsa.dtInicio);
          this.dataFutura = util.valida_data_futura(util.dateToStringUS(this.vendaAvulsa.dtInicio), true);
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os dados da tabela";
        this.snackErro = true;
      }
    }
    setTimeout(() => {
      this.bloqueiaBotoes = false;
    }, 1000);
  }
};
</script>
