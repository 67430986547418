export default {
  UPDATE_LOGIN(state, payload) {
    state.login = payload;
  },
  UPDATE_MENU(state, payload) {
    //ADMIN
    if (payload.path.includes("/administrativo/")) {
      if (
        payload.path.includes("/assinante") ||
        payload.path.includes("/assinantes") ||
        payload.path.includes("/banca") ||
        payload.path.includes("/bancas")
      ) {
        state.modulo = "Assinaturas"; //seta o nome do modulo
        state.itens = this.state.itensAssinaturas; //seta os itens do menu
      } else if (
        payload.path.includes("/fornecedor") ||
        payload.path.includes("/fornecedores") ||
        payload.path.includes("/cliente") ||
        payload.path.includes("/clientes")
      ) {
        state.modulo = "Financeiro"; //seta o nome do modulo
        state.itens = this.state.itensFinanceiro; //seta os itens do menu
      } else {
        state.modulo = "Administrativo"; //seta o nome do modulo
        setTimeout(() => {
          state.itens =
            state.usuario.site_pais == "BR"
              ? this.state.itensAministrativo
              : this.state.itensAministrativoPT;
        }, 300);
      }
    }
    if (payload.path.includes("/assinaturas/")) {
      state.modulo = "Assinaturas"; //seta o nome do modulo
      state.itens = this.state.itensAssinaturas; //seta os itens do menu
    }
    if (payload.path.includes("/comercial/")) {
      state.itens = this.state.itensComercial; //seta os itens do menu
      state.modulo = "Comercial"; //seta o nome do modulo
    }
    if (payload.path.includes("/financeiro/")) {
      state.modulo = "Financeiro"; //seta o nome do modulo
      state.itens = this.state.itensFinanceiro; //seta os itens do menu}
    }
    if (payload.path.includes("/editorial/")) {
      state.modulo = "Editorial"; //seta o nome do modulo
      state.itens = this.state.itensEditorial; //seta os itens do menu
    }
    if (payload.path.includes("/flip/")) {
      state.modulo = "Flip"; //seta o nome do modulo
      state.itens = this.state.itensFlip; //seta os itens do menu
    }
  },
  UPDATE_USUARIO(state, payload) {
    state.usuario = Object.assign(state.usuario, payload);
  },
  MOSTRAR_OCULTAR_MENU(state) {
    state.drawer = !this.state.drawer;
  },
  MOSTRAR_MENU(state) {
    state.drawer = true;
  },
  OCULTAR_MENU(state) {
    state.drawer = false;
  },
  SELECIONAR_DASH(state) {
    state.modulo = ""; //seta o nome do modulo
    state.itens = this.state.itensDASH; //seta os itens do menu
  },
  POSSUI_DIREITO(state, direito) {
    var possuiDireito = false;
    state.usuario.direitos.forEach(direitoLogado => {
      direito.forEach(direitoPagina => {
        if (
          direitoLogado.modulo +
            "_" +
            direitoLogado.grupo +
            "_" +
            direitoLogado.direito ==
          direitoPagina
        ) {
          possuiDireito = true;
        }
      });
    });
    if (!possuiDireito) {
      window.location = "/acesso-negado";
    }
  },
  MENSAGEM_ERRO(state, erro) {
    state.mensagemErro.tipo = erro.tipo;
    state.mensagemErro.mensagem = erro.mensagem;
  }
};
