<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar height="50" color="#333333">
      <v-icon color="white" class="mr-2">person</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Usuário</v-toolbar-title>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12>
            <h4 class="overline font-weight-black font-italic">
              Dados Básicos
            </h4>
            <v-divider></v-divider>
          </v-flex>

          <v-flex xs12>
            <v-text-field
              autofocus
              background-color="white"
              v-model="usuario.nome"
              ref="focusNome"
              label="Nome Completo"
              maxlength="100"
              hint="Obrigatório"
              :rules="[
                validacao.nome == false || validacao.nome,
                value => !!value || 'Obrigatório.',
                value => valida_nome(value) || 'Nome inválido',
                value => (value != null && value.length > 5) || 'Preencha o nome do usuário com no mínimo 6 caracteres'
              ]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="
                usuario.nome = pLetra(usuario.nome);
                validacao.nome = false;
              "
            ></v-text-field>
          </v-flex>

          <v-flex xs12>
            <v-text-field
              background-color="white"
              v-model="usuario.login"
              label="Login"
              maxlength="30"
              hint="Obrigatório"
              :rules="[
                validacao.login == false || validacao.login,
                value => !!value || 'Obrigatório.',
                value => valida_nome(value) || 'Login inválido',
                value => (value != null && value.length > 7) || 'Preencha o login do usuário com no mínimo 8 caracteres'
              ]"
              :disabled="isUpdating || !direitoAdmin"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.login = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md6>
            <v-text-field
              background-color="white"
              v-model="usuario.senha"
              label="Digite sua senha"
              maxlength="20"
              :rules="[
                validacao.senha == false || validacao.senha,
                value => !!value || 'Obrigatório.',
                value => (value != null && value.length > 7) || 'Prrencha a senha do usuário com no mínimo 8 caracteres',
                value => valida_senha(value)
              ]"
              :type="showSenha ? 'text' : 'password'"
              hint="Obrigatório"
              :append-icon="showSenha ? 'visibility' : 'visibility_off'"
              @click:append="mostrarSenha"
              emailMatch="As senhas não coincidem"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.senha = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md6>
            <v-text-field
              background-color="white"
              v-model="confSenha"
              label="Digite novamente a senha"
              maxlength="20"
              :rules="[value => !!value || 'Obrigatório.', value => value == usuario.senha || 'Valor difere da senha']"
              :type="showSenha ? 'text' : 'password'"
              hint="Obrigatório"
              :append-icon="showSenha ? 'visibility' : 'visibility_off'"
              @click:append="mostrarSenha"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 v-if="direitoAdmin">
            <v-switch v-model="usuario.situacao" :label="`${usuario.situacao == 1 ? 'Ativo' : 'Inativo'}`"></v-switch>
          </v-flex>
        </v-layout>

        <v-layout wrap>
          <v-expansion-panels v-model="painel" focusable>
            <v-expansion-panel dark class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.sites ? 'red--text' : ''">
                <strong>Sites do Usuário {{ usuario.admin ? "ADMIN" : "" }}</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout wrap>
                  <v-flex xs12 md6>
                    <v-autocomplete
                      v-if="direitoAdmin"
                      v-model="siteSelecionado"
                      autofocus
                      :disabled="isUpdating"
                      :items="sites"
                      :filter="filtrarObjetos"
                      label="Incluir Site"
                      item-text="nome"
                      item-value="id"
                      no-data-text
                      @blur="siteSelecionado = null"
                      @change="incluirSite"
                      return-object
                    ></v-autocomplete>
                  </v-flex>
                  <v-spacer></v-spacer>
                  <v-flex xs12 md4>
                    <v-text-field
                      background-color="white"
                      class="mt-2 mb-2 elevation-0"
                      v-model="searchSitesUsuario"
                      append-icon="search"
                      label="Digite para pesquisar..."
                      placeholder="Pesquisa pelo nome"
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md12>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="headersSitesUsuario"
                      :items="sitesUsuario"
                      :search="searchSitesUsuario"
                      no-data-text
                      no-results-text
                      :footer-props="{
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageOptions: [10, 25, 50]
                      }"
                    >
                      <template v-slot:[getItemActions()]="{ item }">
                        <router-link :to="'/administrativo/site/' + item.idSite" style="text-decoration: none" v-if="direitoAdmin" target="_blank">
                          <v-icon color="green" title="Visualizar Site">loupe</v-icon>
                        </router-link>
                        <v-icon v-if="direitoAdmin" color="error" title="Remover" @click="removerSite(item)">delete</v-icon>
                      </template>

                      <template v-slot:[getItemSiteSituacao()]="{ item }">
                        <span v-html="item.site_situacao == 1 ? 'Ativo' : item.site_situacao == 0 ? '<strong>Inativo</strong>' : ''"></span>
                      </template>

                      <template v-slot:no-results>
                        <v-alert :value="true" color="error" icon="warning" outlined>"{{ searchSitesUsuario }}" não encontrado.</v-alert>
                      </template>
                    </v-data-table>
                  </v-flex>
                  <v-alert v-if="validacao.sites" :value="true" color="error" icon="priority_high" outlined>{{ validacao.sites }}</v-alert>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" v-if="!usuario.admin">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.perfis ? 'red--text' : ''">
                <strong>Perfis do Usuário</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout wrap>
                  <v-flex xs12 md6>
                    <v-autocomplete
                      v-if="direitoAdmin"
                      v-model="perfilSelecionado"
                      autofocus
                      :disabled="isUpdating"
                      :items="perfis"
                      :filter="filtrarObjetos"
                      label="Incluir Perfil"
                      item-text="nome"
                      item-value="id"
                      no-data-text
                      @blur="perfilSelecionado = null"
                      @change="incluirPerfil"
                      return-object
                    ></v-autocomplete>
                  </v-flex>
                  <v-spacer></v-spacer>
                  <v-flex md4>
                    <v-text-field
                      background-color="white"
                      class="mt-2 mb-2 elevation-0"
                      v-model="searchPerfisUsuario"
                      append-icon="search"
                      label="Digite para pesquisar..."
                      placeholder="Pesquisa pelo nome"
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md12>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="headersPerfisUsuario"
                      :items="perfisUsuario"
                      :search="searchPerfisUsuario"
                      no-data-text
                      no-results-text
                      :footer-props="{
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageOptions: [10, 25, 50]
                      }"
                    >
                      <template v-slot:[getItemActions()]="{ item }">
                        <router-link :to="'/administrativo/perfil/' + item.idPerfil" style="text-decoration: none" v-if="direitoAdmin" target="_blank">
                          <v-icon color="green" title="Visualizar Perfil">loupe</v-icon>
                        </router-link>
                        <v-icon v-if="direitoAdmin" color="error" title="Remover" @click="removerPerfil(item)">delete</v-icon>
                      </template>

                      <template v-slot:[getItemPerfilSituacao()]="{ item }">
                        <span
                          class="text-xs-left"
                          style="border-right: solid 1px #dddddd;"
                          v-html="item.perfil_situacao == 1 ? 'Ativo' : item.perfil_situacao == 0 ? '<strong>Inativo</strong>' : ''"
                        ></span>
                      </template>

                      <template v-slot:[getItemSiteSituacao()]="{ item }">
                        <span v-html="item.site_situacao == 1 ? 'Ativo' : item.site_situacao == 0 ? '<strong>Inativo</strong>' : ''"></span>
                      </template>

                      <template v-slot:no-results>
                        <v-alert :value="true" color="error" icon="warning"> outlined "{{ searchPerfisUsuario }}" não encontrado. </v-alert>
                      </template>
                    </v-data-table>
                  </v-flex>
                  <v-alert v-if="validacao.perfis" :value="true" color="error" icon="priority_high" outlined>{{ validacao.perfis }}</v-alert>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-layout>

        <v-layout wrap>
          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">{{ validacao.mensagemErro }}</v-sheet>
          </v-flex>

          <v-flex xs12 offset-md6 md6>
            <v-autocomplete
              v-model="usuario.idSitePrincipal"
              :disabled="isUpdating"
              :items="sitesUsuario"
              :filter="filtrarObjetos"
              hint="Obrigatório"
              :rules="[validacao.idSitePrincipal == false || validacao.idSitePrincipal, value => !!value || 'Obrigatório.']"
              color="blue-grey lighten-2"
              label="Selecione o Site Principal"
              item-text="nome"
              item-value="idSite"
              no-data-text="Associe o(s) site(s) ao usuário"
              @change="validacao.idSitePrincipal = false"
            ></v-autocomplete>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>
    <v-card-actions id="flex-wrap">
      <v-btn to="/administrativo/usuarios" color="orange lighten-2 white" style="color:white" id="btn-rsn" v-if="direitoAdmin">
        <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar
      </v-btn>
      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="blue white" style="color:white" id="btn-rsn" depressed @click="salvar('false')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar
      </v-btn>
      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="orange white" style="color:white" id="btn-rsn" outlined @click="salvar('retornar')" v-if="direitoAdmin">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>
      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="blue white" style="color:white" id="btn-rsn" outlined @click="salvar('true')" v-if="direitoAdmin">
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Novo
      </v-btn>
    </v-card-actions>
    <v-dialog v-model="dialogConfirm" max-width="290">
      <v-card>
        <v-toolbar>
          <span class="headline">Confirmação</span>
        </v-toolbar>
        <v-card-text>{{ msgConfirm }}</v-card-text>
        <v-card-actions>
          <v-btn color="red darken-1" text @click="dialogConfirm = false">Não</v-btn>
          <v-btn color="green darken-1" text @click="confirmado">Sim</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<style scoped>
.v-input input {
  min-height: 50px;
}
</style>
<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      usuario: {
        id: "",
        login: "",
        senha: "",
        nome: "",
        idSitePrincipal: null,
        situacao: 1,
        nameRules: [v => !!v || "Name is required", v => v.length <= 10 || "Name must be less than 10 characters"]
      },
      validacao: {
        id: false,
        login: false,
        senha: false,
        nome: false,
        idSitePrincipal: false,
        situacao: false,
        sites: false,
        perfis: false,
        mensagemErro: false
      },
      confSenha: "",
      sitesUsuario: [],
      perfisUsuario: [],
      sites: [],
      perfis: [],
      direitoAdmin: false,

      headersSitesUsuario: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Site", value: "site_nome" },
        {
          text: this.$store.state.usuario.site_pais == "BR" ? "Município/UF" : "Concelho/Distrito",
          value: "municipio"
        },
        { text: "Situação Site", value: "site_situacao" }
      ],
      headersPerfisUsuario: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Módulo", value: "perfil_modulo" },
        { text: "Perfil", value: "perfil_nome" },
        { text: "Situação Perfil", value: "perfil_situacao" },
        { text: "Site", value: "site_nome" },
        { text: "Situação Site", value: "site_situacao" }
      ],

      dialogConfirm: false,
      tipoConfirm: "",
      msgConfirm: "",
      siteExcluido: null,

      painel: [0, 0],
      elementoValid: true,
      id: "",
      siteSelecionado: {},
      perfilSelecionado: null,
      searchSitesUsuario: "",
      searchPerfisUsuario: "",
      showSenha: false,
      mensagem: "",
      snackbar: false,
      snackErro: false,

      ordenacao: "",
      ordem: "",
      isUpdating: false,
      bloqueiaBotoes: false
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    getItemActions() {
      return "item.actions";
    },
    getItemSiteSituacao() {
      return "item.site_situacao";
    },
    getItemPerfilSituacao() {
      return "item.perfil_situacao";
    },
    incluirSite() {
      this.validacao.sites = null;

      var elemento = {};
      elemento.idSite = this.siteSelecionado.id;
      elemento.id = null;
      elemento.nome = this.siteSelecionado.nome;
      elemento.site_nome = this.siteSelecionado.site_nome;
      elemento.municipio = this.siteSelecionado.municipio + "/" + this.siteSelecionado.uf;
      elemento.uf = this.siteSelecionado.uf;
      elemento.site_situacao = this.siteSelecionado.situacao;

      var incluido = false;
      this.sitesUsuario.forEach(element => {
        if (element.idSite == this.siteSelecionado.id) {
          incluido = true;
          return true;
        }
      });

      if (!incluido) {
        this.sitesUsuario.push(elemento);
        this.carregarPerfisSitesUsuario();
      }
    },
    removerSite(item) {
      //* Verifica se há pelo menos 1 perfil do usuário associado ao site do usuário
      var possuiSite = false;
      this.perfisUsuario.forEach(element => {
        if (item.idSite == element.idSite) {
          possuiSite = true;
        }
      });
      this.siteExcluido = item;
      if (possuiSite) {
        this.tipoConfirm = "REMOVER_SITE";
        this.msgConfirm = "Remover o site do usuário irá remover todos os perfis do usuário associados a este site. Deseja continuar?";
        this.dialogConfirm = true;
      } else {
        this.removeSite();
      }
    },
    removeSite() {
      if (this.siteExcluido.idSite == this.usuario.idSitePrincipal) {
        this.usuario.idSitePrincipal = null;
      }
      const index = this.sitesUsuario.indexOf(this.siteExcluido);
      this.sitesUsuario.splice(index, 1);

      var perfisTemp = [];
      this.perfisUsuario.forEach(element => {
        if (this.siteExcluido.idSite != element.idSite) {
          perfisTemp.push(element);
        }
      });
      this.perfisUsuario = [];

      perfisTemp.forEach(element => {
        this.perfisUsuario.push(element);
      });

      this.carregarPerfisSitesUsuario();
    },
    confirmado() {
      this.dialogConfirm = false;
      this.removeSite();
    },

    incluirPerfil() {
      this.validacao.perfis = null;

      var elemento = {};
      elemento.idPerfil = this.perfilSelecionado.id;
      elemento.idSite = this.perfilSelecionado.idSite;
      elemento.id = null;
      elemento.site_nome = this.perfilSelecionado.site;
      elemento.site_situacao = this.perfilSelecionado.site_situacao;
      elemento.perfil_modulo = this.perfilSelecionado.modulo;
      elemento.perfil_nome = this.perfilSelecionado.perfil_nome;
      elemento.perfil_situacao = this.perfilSelecionado.situacao;

      var incluido = false;
      this.perfisUsuario.forEach(element => {
        if (element.idPerfil == this.perfilSelecionado.id) {
          incluido = true;
          return true;
        }
      });

      if (!incluido) {
        this.perfisUsuario.push(elemento);
      }
    },
    removerPerfil(item) {
      const index = this.perfisUsuario.indexOf(item);
      this.perfisUsuario.splice(index, 1);
    },

    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      var erro = false;
      if (this.sitesUsuario.length == 0) {
        erro = true;
        this.validacao.sites = "Você deve selecionar pelo menos 1 site para o usuário";
      }

      if (!this.$refs.formElemento.validate() || erro) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      //* Perfis são associados posteriormente pelo jornal

      var usuariosSite = [];
      this.sitesUsuario.forEach(element => {
        usuariosSite.push({
          id: element.id,
          idSite: element.idSite,
          idUsuario: this.id
        });
      });

      this.usuario.usuariosSite = usuariosSite;

      var usuariosPerfil = [];
      this.perfisUsuario.forEach(element => {
        usuariosPerfil.push({
          id: element.id,
          idPerfil: element.idPerfil,
          idUsuario: this.id
        });
      });

      this.usuario.usuariosPerfil = usuariosPerfil;

      this.showSenha = false;

      this.isUpdating = true;
      try {
        var retorno = "";
        //se tiver id é um update
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "adm/usuario/" + this.id, this.usuario);
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "adm/usuario?idSite=" + (this.direitoAdmin ? 0 : this.$store.state.usuario.idSitePrincipal), this.usuario);
          this.id = retorno.data.id;
          this.usuario.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "true") {
          this.$router.push("/administrativo/usuario");
          this.$router.go("/administrativo/usuario");
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push("/administrativo/usuarios");
            this.$router.go("/administrativo/usuarios"); //*retorna recarregando, para recarregar permissões e logo do jornal do usuário
          }, 2000);
        } else {
          //* Necessita recarregar para atualizar permissões
          this.$router.push("/administrativo/usuario/" + this.id);
          this.$router.go("/administrativo/usuario/" + this.id);
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },
    async carregarSitesAtivos() {
      try {
        //* Carrega os sites ativos
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/site/lista");
        this.sites = response.data;
        this.sites.forEach(element => {
          element.site_nome = element.nome;
          element.nome = element.nome + " - " + element.municipio + "/" + element.uf + " (" + element.id + "-" + element.sigla + ")";
        });
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de sites";
        this.snackErro = true;
      }
    },
    async carregarSitesUsuario() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/site-usuario/usuario/" + this.usuario.id);
        this.sitesUsuario = response.data;
        this.sitesUsuario.forEach(element => {
          element.municipio += "/" + element.uf;

          element.nome = element.site_nome + " - " + element.municipio + " (" + element.idSite + "-" + element.sigla + ")";
        });

        //* Carrega os perfis ativos dos sites do usuário
        this.carregarPerfisSitesUsuario();
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de sites do usuário";
        this.snackErro = true;
      }
    },
    async carregarPerfisUsuario() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/perfil-usuario?idUsuario=" + this.usuario.id);
        this.perfisUsuario = response.data.data;
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de perfis do usuário";
        this.snackErro = true;
      }
    },
    async carregarPerfisSitesUsuario() {
      if (this.usuario.admin) {
        return;
      }

      try {
        var idsSites = "";
        this.sitesUsuario.forEach(element => {
          idsSites += (idsSites == "" ? "" : ",") + element.idSite;
        });
        //* Carrega os perfis ativos dos sites do usuário
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/perfil/lista?sites=" + idsSites);
        this.perfis = response.data;
        this.perfis.forEach(element => {
          element.perfil_nome = element.nome;
          element.nome = element.nome + " - " + element.modulo + `: ` + element.site;
        });
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de perfis";
        this.snackErro = true;
      }
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    pLetra(text) {
      return util.pLetra(text);
    },
    valida_nome(text) {
      return util.valida_nome(text);
    },
    valida_senha(text) {
      var retorno = true;
      if (text && (!util.somenteNumeros(text) || text == util.somenteNumeros(text) || text == text.toUpperCase() || text == text.toLowerCase() || util.valida_nome(text))) {
        retorno = "Senha deve ter pelo menos 1 número, 1 símbolo (como @#$%), 1 letra maiúcula e 1 minúscula";
      }
      return retorno;
    },
    mostrarSenha() {
      if (!this.id) {
        this.showSenha = !this.showSenha;
      }
    }
  },

  async created() {
    this.isUpdating = false;
    this.bloqueiaBotoes = true;
    this.id = this.$route.params.id ? this.$route.params.id : "";

    var direito = this.$store.state.usuario.direitos[0];

    if (direito.modulo + "_" + direito.grupo + "_" + direito.direito == "WEBSUITA_ADMIN_SUITA") {
      this.direitoAdmin = true;
    }

    //* Carrega os sites ativos
    await this.carregarSitesAtivos();

    //* Se é edição, carrega o usuário, seus sites e perfis
    if (this.id == "") {
      this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA"]);
      setTimeout(() => {
        this.$refs.focusNome.focus();
      }, 1000);
    } else {
      try {
        //* Carrega o usuário
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/usuario/" + this.id);
        if (!response.data) {
          if (this.direitoAdmin) {
            this.$router.push("/administrativo/usuarios");
            return;
          } else {
            //* Só pode editar seu próprio usuário
            if (this.$store.state.usuario.id != this.usuario.id) {
              this.$router.push("/acesso-negado");
              return;
            }
          }
        }
        this.usuario = response.data;
        this.confSenha = this.usuario.senha;

        if (!this.direitoAdmin) {
          //* Só pode editar seu próprio usuário
          if (this.$store.state.usuario.id != this.usuario.id) {
            this.$router.push("/acesso-negado");
            return;
          }
        }
        //* Carrega os sites do usuário
        await this.carregarSitesUsuario();
        if (!this.usuario.admin) {
          //* Carrega os perfis do usuário
          await this.carregarPerfisUsuario();
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os dados do usuário";
        this.snackErro = true;
      }
    }
    setTimeout(() => {
      this.bloqueiaBotoes = false;
    }, 1000);
  },
  watch: {
    $route() {
      this.$router.go(this.$router.currentRoute);
    }
  }
};
</script>
