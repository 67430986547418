<template>
  <div id="app">
    <v-app id="inspire">
      <v-card>
        <v-toolbar color="#333333">
          <v-icon color="white" class="mr-2">transfer_within_a_station</v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light">
            Cliente/Fornecedor
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container fluid grid-list-md>
          <v-layout wrap>
            <v-flex xs6 md6>
              <v-autocomplete
                v-model="clienteSelecionado"
                autofocus
                :items="clientes"
                :filter="filtrarObjetos"
                label="Cliente"
                item-text="nome"
                item-value="id"
                hint="Obrigatório"
                :rules="[validacao.idCliente == false || validacao.idCliente, value => (!!value && !!value.id) || 'Obrigatório.']"
                no-data-text
                placeholder="Pesquisa pelos primeiros 50 assinantes que coincidirem com o nome ou identificação digitada"
                :loading="loadingClientes"
                :search-input.sync="search"
                @keyup.enter="buscarCliente"
                clearable
                @click:clear="limpaCliente()"
                return-object
              >
              </v-autocomplete>
            </v-flex>
            <v-flex xs1>
              <v-icon class="mt-6" color="blue" @click="buscarCliente" title="Busca pelo nome ou identificação do cliente">search</v-icon>
              <v-icon class="mt-6" v-if="direitoCliente" color="orange" @click="editarCliente" title="Editar Cliente">account_circle</v-icon>
            </v-flex>

            <v-flex xs6 md3>
              <v-menu v-model="mnuDtInicio" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="periodo"
                    label="Período Emissao"
                    prepend-icon="event"
                    v-on="on"
                    color="blue-grey lighten-2"
                    @click:clear="periodoUS = ['', '']"
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker v-model="periodoUS" locale="pt-br" no-title range
                  ><v-spacer></v-spacer>
                  <v-btn text color="primary" @click="mnuDtInicio = false">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs2 md1>
              <v-btn color="accent" :loading="loading" :disabled="loading || loadingExp || loadingTxt" fab small @click="pesquisaDados()">
                <v-icon color="white" title="Filtrar">search</v-icon>
              </v-btn>
            </v-flex>
            <v-flex xs1>
              <v-btn v-if="direitoExportar" color="blue" :loading="loadingExp" :disabled="loading || loadingExp || loadingTxt" fab small @click="pesquisaDados('CSV')">
                <v-icon color="white" title="Exportar">description</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>

        <v-card color="white lighten-1" lighten>
          <v-card-title>{{ nome }}</v-card-title>
        </v-card>
        <v-divider></v-divider>

        <v-card color="white lighten-1" lighten>
          <v-card-title>Recebimentos</v-card-title>
          <v-data-table
            mobile-breakpoint="0"
            :headers="headers"
            :items="registrosReceber"
            :no-data-text="mensagemPesquisa"
            hide-default-footer
            disable-sort
            disable-pagination
            group-by="status"
          >
            <template v-slot:[getItemIdOperacao()]="{ item }">
              <v-icon
                v-if="item.idOperacao"
                :color="item.idAssinatura ? 'red' : item.idAnuncio ? 'yellow darken-2' : item.idPacote ? 'yellow darken-3' : 'blue'"
                :title="
                  item.idAssinatura
                    ? 'Assinatura'
                    : item.idAnuncio
                    ? 'Anúncio'
                    : item.idPacote
                    ? 'Pacote'
                    : item.origem == 'VA'
                    ? 'Venda Avulsa'
                    : item.tipo == 0
                    ? 'Conta a Pagar'
                    : item.tipo == 1
                    ? 'Conta a Receber'
                    : 'Transferência'
                "
                >{{
                  item.idAssinatura
                    ? "assignment"
                    : item.idAnuncio
                    ? "chrome_reader_mode"
                    : item.idPacote
                    ? "library_books"
                    : item.origem == "VA"
                    ? "list_alt"
                    : item.tipo
                    ? "add_circle"
                    : "remove_circle"
                }}</v-icon
              >
              <span>
                {{ item.idAssinatura ? item.idAssinatura + "/" + item.renovacao : item.idAnuncio ? item.idAnuncio : item.idPacote ? item.idPacote : item.idOperacao }}
              </span>
            </template>

            <template v-slot:[getItemStatus()]="{ item }">
              <span
                :style="item.status == 'EXCLUÍDA' ? 'font-weight:bold;' : ''"
                :class="
                  item.status == 'ABERTA'
                    ? 'blue--text'
                    : item.status == 'BLOQUEADA'
                    ? 'orange--text'
                    : item.status == 'CANCELADA' || item.status == 'EXCLUÍDA'
                    ? 'red--text'
                    : item.status == 'QUITADA'
                    ? 'green--text'
                    : ''
                "
                >{{ item.status }}</span
              >
            </template>

            <template v-slot:[getItemDtOperacao()]="{ item }">
              {{ formatDate(item.dtOperacao) }}
            </template>
            <template v-slot:[getItemDtVecto()]="{ item }">
              {{ formatDate(item.dtVecto) }}
            </template>
            <template v-slot:[getItemDtQuitacao()]="{ item }">
              {{ formatDate(item.dtQuitacao) }}
            </template>
            <template v-slot:[getItemValor()]="{ item }">
              {{ formatCurrency(item.valor) }}
            </template>
          </v-data-table>
          <v-footer
            ><v-col class="text-right" cols="12"
              >A Receber: {{ formatCurrency(vlrReceber) }}<br />
              Recebido:
              {{ formatCurrency(vlrRecebido) }}<br />
              Soma: {{ formatCurrency(vlrReceber + vlrRecebido) }}
            </v-col>
          </v-footer>
        </v-card>

        <v-divider></v-divider>

        <v-card color="white lighten-1" lighten>
          <v-card-title>Pagamentos</v-card-title>
          <v-data-table
            mobile-breakpoint="0"
            :headers="headers"
            :items="registrosPagar"
            :no-data-text="mensagemPesquisa"
            hide-default-footer
            disable-sort
            disable-pagination
            group-by="status"
          >
            <template v-slot:[getItemIdOperacao()]="{ item }">
              <v-icon
                v-if="item.idOperacao"
                :color="item.idAssinatura ? 'red' : item.idAnuncio ? 'yellow darken-2' : item.idPacote ? 'yellow darken-3' : 'blue'"
                :title="
                  item.idAssinatura
                    ? 'Assinatura'
                    : item.idAnuncio
                    ? 'Anúncio'
                    : item.idPacote
                    ? 'Pacote'
                    : item.origem == 'VA'
                    ? 'Venda Avulsa'
                    : item.tipo == 0
                    ? 'Conta a Pagar'
                    : item.tipo == 1
                    ? 'Conta a Receber'
                    : 'Transferência'
                "
                >{{
                  item.idAssinatura
                    ? "assignment"
                    : item.idAnuncio
                    ? "chrome_reader_mode"
                    : item.idPacote
                    ? "library_books"
                    : item.origem == "VA"
                    ? "list_alt"
                    : item.tipo
                    ? "add_circle"
                    : "remove_circle"
                }}</v-icon
              >
              <span>
                {{ item.idAssinatura ? item.idAssinatura + "/" + item.renovacao : item.idAnuncio ? item.idAnuncio : item.idPacote ? item.idPacote : item.idOperacao }}
              </span>
            </template>

            <template v-slot:[getItemStatus()]="{ item }">
              <span
                :style="item.status == 'EXCLUÍDA' ? 'font-weight:bold;' : ''"
                :class="
                  item.status == 'ABERTA'
                    ? 'blue--text'
                    : item.status == 'BLOQUEADA'
                    ? 'orange--text'
                    : item.status == 'CANCELADA' || item.status == 'EXCLUÍDA'
                    ? 'red--text'
                    : item.status == 'QUITADA'
                    ? 'green--text'
                    : ''
                "
                >{{ item.status }}</span
              >
            </template>

            <template v-slot:[getItemDtOperacao()]="{ item }">
              {{ formatDate(item.dtOperacao) }}
            </template>
            <template v-slot:[getItemDtVecto()]="{ item }">
              {{ formatDate(item.dtVecto) }}
            </template>
            <template v-slot:[getItemDtQuitacao()]="{ item }">
              {{ formatDate(item.dtQuitacao) }}
            </template>
            <template v-slot:[getItemValor()]="{ item }">
              {{ formatCurrency(item.valor) }}
            </template>
          </v-data-table>
          <v-footer
            ><v-col class="text-right" cols="12"
              >A Pagar: {{ formatCurrency(vlrPagar) }}<br />
              Pago:
              {{ formatCurrency(vlrPago) }}<br />
              Soma:
              {{ formatCurrency(vlrPago + vlrPagar) }}</v-col
            ></v-footer
          >
        </v-card>
        <v-divider></v-divider>

        <v-footer
          ><v-col class="text-right" cols="12"
            ><strong>SALDO {{ tipoSaldo }}: {{ formatCurrency(Math.abs(saldo)) }}</strong></v-col
          ></v-footer
        >
        <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
        <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
      </v-card>
    </v-app>
  </div>
</template>

<script>
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
import util from "../../../util.js";
export default {
  data() {
    return {
      headers: [
        {
          text: "ID",
          value: "id"
        },
        {
          text: "Código",
          value: "idOperacao"
        },
        {
          text: "Status",
          value: "status"
        },
        {
          text: "Parcela",
          value: "numero"
        },
        {
          text: "Emissão",
          value: "dtOperacao"
        },
        {
          text: "Vencimento",
          value: "dtVecto"
        },
        {
          text: "Quitação",
          value: "dtQuitacao"
        },
        {
          text: "Valor",
          value: "valor",
          align: "end"
        },
        {
          text: "Forma Pgto",
          value: "formaPagamento"
        }
      ],
      registrosReceber: [],
      registrosPagar: [],
      nome: "",
      vlrReceber: 0,
      vlrRecebido: 0,
      vlrPagar: 0,
      vlrPago: 0,
      saldo: 0,
      tipoSaldo: "",

      validacao: { idCliente: false },
      clienteSelecionado: { id: null },
      clientes: [],
      loadingClientes: false,
      search: "",

      periodoUS: ["", ""],
      mnuDtInicio: false,

      direitoCliente: false,
      direitoExportar: false,

      mensagem: "",
      snackbar: false,
      snackErro: false,
      mensagemPesquisa: "",
      carregando: true,
      options: {},
      loader: null,
      loading: false,
      loadingExp: false,
      loadingTxt: false
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    getItemIdOperacao() {
      return "item.idOperacao";
    },
    getItemStatus() {
      return "item.status";
    },
    getItemDtOperacao() {
      return "item.dtOperacao";
    },
    getItemDtVecto() {
      return "item.dtVecto";
    },
    getItemDtQuitacao() {
      return "item.dtQuitacao";
    },
    getItemValor() {
      return "item.valor";
    },

    async pesquisaDados(exportar) {
      this.nome = "";
      this.vlrReceber = 0;
      this.vlrRecebido = 0;
      this.vlrPagar = 0;
      this.vlrPago = 0;
      this.saldo = 0;
      this.registrosReceber = [];
      this.registrosPagar = [];
      this.mensagemPesquisa = "MONTE SEU FILTRO PARA VISUALIZAR OS DADOS...";

      if (!this.clienteSelecionado || !this.clienteSelecionado.id) {
        this.mensagem = "Selecione um Cliente";
        this.snackErro = true;
        return;
      }

      var dtInic = "";
      var dtFim = "";
      if (this.periodoUS && this.periodoUS.length > 1) {
        dtInic = this.periodoUS[0];
        dtFim = this.periodoUS[1];
      }
      if (!dtInic || !dtFim) {
        this.mensagem = "Verifique o período";
        this.snackErro = true;
        return;
      }

      if (exportar == "CSV") {
        this.loader = "loadingExp";
      } else if (exportar == "TXT") {
        this.loader = "loadingTxt";
      } else {
        this.loader = "loading";
      }
      const l = this.loader;
      this[l] = !this[l];

      //sinaliza que está carregando
      this.carregando = true;
      this.mensagemPesquisa = "Carregando...";

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "fin/parcela/clienteFornecedor?identificacao=" +
            this.clienteSelecionado.identificacao +
            "&dtEmissaoInicio=" +
            dtInic +
            "&dtEmissaoFim=" +
            dtFim +
            (exportar ? "&exportar=" + exportar : "")
        );
        var registros = response.data;
        if (registros.length > 0) {
          this.nome = registros[0].nome;
        }

        registros.forEach(element => {
          if (element.tipo) {
            if (element.status == "ABERTA") {
              this.vlrReceber += element.valor;
            } else if (element.status == "QUITADA") {
              this.vlrRecebido += element.valor;
            }
            this.registrosReceber.push(element);
          } else {
            if (element.status == "ABERTA") {
              this.vlrPagar += element.valor;
            } else if (element.status == "QUITADA") {
              this.vlrPago += element.valor;
            }
            this.registrosPagar.push(element);
          }
        });
        this.saldo = this.vlrReceber - this.vlrPagar;
        this.tipoSaldo = this.saldo > 0 ? "A RECEBER" : this.saldo < 0 ? "A PAGAR" : "";

        //fecha o loading
        this.carregando = false;
        //se não achar registro seta a mensagem senão deixa em branco
        if (registros.length) {
          this.mensagemPesquisa = "";

          if (exportar) {
            this.mensagem = "Relatório exportado, verifique suas mensagens";
            this.snackbar = true;
          }
        } else {
          this.mensagemPesquisa = "Nenhum registro encontrado";
        }
      } catch (e) {
        this.carregando = false;
        this.mensagemPesquisa = "Erro ao buscar dados: " + e;
      } finally {
        this[l] = false;
        this.loader = null;
      }
    },

    async buscarCliente() {
      if (this.loadingClientes || !this.search) {
        return;
      }

      this.loadingClientes = true;

      var filtro = "";
      if (util.valida_cpf(this.search) || util.valida_cnpj(this.search)) {
        filtro = "identificacao=" + this.search;
      } else {
        filtro = "nome=" + this.search + "&nomeFantasia=" + this.search;
      }

      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente?" + filtro + "&ordenacao=nome&ordem=asc&pagina=1&tamanho=50");
      if (response.data.data) {
        this.clientes = response.data.data;
        if (this.clientes.length == 1) {
          this.clienteSelecionado = this.clientes[0];
        }
      }
      this.loadingClientes = false;
    },

    editarCliente() {
      if (this.direitoCliente && this.clienteSelecionado && this.clienteSelecionado.id) {
        window.open("/financeiro/cliente/" + this.clienteSelecionado.id, "_blank");
      }
    },
    limpaCliente() {
      this.clientes = [];
      this.nome = "";
      this.vlrReceber = 0;
      this.vlrRecebido = 0;
      this.vlrPagar = 0;
      this.vlrPago = 0;
      this.saldo = 0;
      this.registrosReceber = [];
      this.registrosPagar = [];
      this.mensagemPesquisa = "MONTE SEU FILTRO PARA VISUALIZAR OS DADOS...";
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    formatDate(date) {
      return util.formatDate(date);
    },
    formatDateUS(date) {
      return util.formatDateUS(date);
    },
    formatCurrency(valor) {
      return util.formatCurrency(valor);
    }
  },

  beforeMount() {
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_RELATÓRIOS_CLIENTE_FORNECEDOR_ACESSO"]);

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_CLIENTES_EDITAR") {
        this.direitoCliente = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_RELATÓRIOS_CLIENTE_FORNECEDOR_EXPORTAR") {
        this.direitoExportar = true;
      }
    });

    this.mensagemPesquisa = "MONTE SEU FILTRO PARA VISUALIZAR OS DADOS...";
  },
  watch: {
    clienteSelecionado(val) {
      if (!val) {
        this.limpaCliente();
      }
    }
  },
  computed: {
    periodo() {
      var retorno = "";
      if (this.periodoUS && this.periodoUS.length > 1) {
        this.periodoUS.forEach(element => {
          if (element) {
            retorno += (retorno ? " - " : "") + util.formatDate(element);
          }
        });
      }
      return retorno;
    }
  }
};
</script>
