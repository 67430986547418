<template>
  <div id="app">
    <v-app id="inspire">
      <v-card>
        <v-toolbar color="#333333">
          <v-icon color="white" class="mr-2">grid_off</v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light">Motivos de Cancelamento</v-toolbar-title>
          <v-spacer></v-spacer>
          <router-link to="/assinaturas/motivo-cancelamento" style="text-decoration: none" v-if="direitoCriar">
            <v-btn color="success" fab small>
              <v-icon color="white" title="Incluir">add</v-icon>
            </v-btn>
          </router-link>
        </v-toolbar>
        <div class="px-6 mt-4">
          <v-layout wrap>
            <v-spacer></v-spacer>
            <v-flex xs12 md5>
              <v-text-field
                v-model="filtroPesquisa"
                append-icon="search"
                label="Digite para pesquisar..."
                placeholder="Pesquisa pelo nome"
                hide-details
                background-color="white"
                class="mt-2 mb-2 elevation-0"
                @keyup.enter="limpaFiltro"
                @click:append="limpaFiltro"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-data-table
            mobile-breakpoint="0"
            :headers="headers"
            :items="registros"
            :options.sync="options"
            :no-data-text="mensagemPesquisa"
            :footer-props="rodape"
            :disable-sort="carregando"
            :disable-pagination="carregando"
          >
            <template v-slot:item.actions="{ item }">
              <router-link v-if="direitoEditar" :to="'/assinaturas/motivo-cancelamento/' + item.id" style="text-decoration: none">
                <v-icon color="blue" title="Editar">edit</v-icon>
              </router-link>
            </template>

            <template v-slot:item.cancelarParcelas="{ item }">
              <span v-html="item.cancelarParcelas == 1 ? 'CANCELAR' : item.cancelarParcelas == 2 ? 'RECALCULAR' : 'MANTER'"></span>
            </template>

            <!-- <template v-slot:item.recuperacao="{ item }">
              <span v-html="item.recuperacao == 1 ? 'Sim' : ''"></span>
            </template>

            <template v-slot:item.inadimplencia="{ item }">
              <span v-html="item.inadimplencia == 1 ? 'Sim' : ''"></span>
            </template>

            <template v-slot:item.espontaneo="{ item }">
              <span v-html="item.espontaneo == 1 ? 'Sim' : ''"></span>
            </template> -->

            <template v-slot:item.imediato="{ item }">
              <span v-html="item.imediato == 1 ? 'Sim' : ''"></span>
            </template>

            <template v-slot:item.situacao="{ item }">
              <span v-html="item.situacao == 1 ? 'Ativo' : '<strong>Inativo</strong>'"></span>
            </template>
          </v-data-table>
          <div class="text-xs-center pt-2 marg-bt">
            <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 7" circle></v-pagination>
          </div>
        </div>
      </v-card>
    </v-app>
  </div>
</template>

<script>
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      headers: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Nome", value: "nome" },
        { text: "Parcelas", value: "cancelarParcelas" },
        // { text: "Recuperação", value: "recuperacao" },
        // { text: "Inadimplência", value: "inadimplencia" },
        // { text: "Espontâneo", value: "espontaneo" },
        { text: "Situação", value: "situacao" }
      ],
      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },
      direitoCriar: false,
      direitoEditar: false,
      mensagemPesquisa: "",
      filtroPesquisa: "",
      registros: [],
      registrosPagina: 10,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      carregando: true,
      ordenacao: "",
      ordem: "",
      options: {}
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    limpaFiltro() {
      this.paginaAtual = 1;
      this.pesquisaDados();
    },
    async pesquisaDados() {
      this.registros = [];
      //sinaliza que está carregando
      this.carregando = true;
      this.mensagemPesquisa = "Carregando...";

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "ass/motivo-cancelamento?idSite=" +
            this.$store.state.usuario.idSitePrincipal +
            "&pagina=" +
            this.paginaAtual +
            "&tamanho=" +
            this.registrosPagina +
            "&filtro=" +
            this.filtroPesquisa +
            "&ordenacao=" +
            this.ordenacao +
            "&ordem=" +
            this.ordem
        );

        //seta as variaveis com o retorno
        this.registros = response.data.data;
        this.totalRegistros = response.data.total;
        this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
        this.rodape.pageText = this.totalRegistros + " itens";
        //fecha o loading
        this.carregando = false;
        //se não achar registro seta a mensagem senão deixa em branco
        this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else {
          this.mensagemPesquisa = "Erro ao buscar dados: " + e;
        }
      }
    }
  },
  beforeMount() {
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_MOTIVOS_CANCELAMENTO_ACESSO"]);
    //* Verifica se o usuário logado possui direito para incluir
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_MOTIVOS_CANCELAMENTO_CRIAR") {
        this.direitoCriar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_MOTIVOS_CANCELAMENTO_EDITAR") {
        this.direitoEditar = true;
      }
    });
    if (this.$store.state.usuario.site_diasRecuperacao) {
      this.headers.push({ text: "Imediato", value: "imediato" });
    }
  },

  watch: {
    paginaAtual() {
      this.pesquisaDados();
    },
    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }

        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }

        this.pesquisaDados();
      }
    }
  }
};
</script>
