<template>
  <div id="app">
    <v-app id="inspire">
      <v-card>
        <v-toolbar color="#333333">
          <v-icon color="white" class="mr-2"> offline_bolt </v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light">Recepções Celesc</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <div class="px-6 mt-4">
          <v-layout wrap>
            <v-spacer></v-spacer>
            <v-flex xs12 md5>
              <v-text-field
                background-color="white"
                class="mt-2 mb-2 elevation-0"
                v-model="filtroPesquisa"
                append-icon="search"
                label="Digite para pesquisar..."
                placeholder="Pesquisa pela competência(MM/AAAA) ou sigla do site"
                hide-details
                @keyup.enter="limpaFiltro"
                @click:append="limpaFiltro"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-data-table mobile-breakpoint="0" :headers="headers" :items="registros" :options.sync="options" :no-data-text="mensagemPesquisa" :footer-props="rodape">
            <template v-slot:[getItemActions()]="{ item }">
              <v-icon v-if="direitoTitulos && item.status != 'EXCLUÍDO'" color="blue" title="Visualizar Títulos" @click="visualizarTitulos(item)">add_circle_outline</v-icon>
              <v-icon v-if="item.status == 'CORRENTE'" color="red lighten-3" title="Excluir Lote" @click="excluiLote(item)">cancel</v-icon>
            </template>

            <template v-slot:item.sigla="{ item }">
              <span>{{ item.sigla.toUpperCase() }}</span>
            </template>
            <template v-slot:item.status="{ item }">
              <span :class="item.status == 'EXCLUÍDO' ? 'red--text' : 'blue--text'">{{ item.status }}</span>
            </template>
            <template v-slot:item.dtCriacao="{ item }">
              <span>{{ formatDate(item.dtCriacao) }}</span>
            </template>
            <template v-slot:item.valor="{ item }">
              <span>{{ formatCurrency(item.valor) }}</span>
            </template>
            <template v-slot:item.qtde="{ item }">
              <span>{{ formatNumber(item.qtde) }}</span>
            </template>
            <template v-slot:item.dtExclusao="{ item }">
              <span>{{ formatDateTime(item.dtExclusao) }}</span>
            </template>
          </v-data-table>
          <div class="text-xs-center pt-2 marg-bt">
            <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 11" circle></v-pagination>
          </div>
        </div>
        <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
      </v-card>
      <v-dialog persistent v-model="dialogConfirm" max-width="400">
        <v-card>
          <v-toolbar>
            <span class="headline">Confirmação</span>
          </v-toolbar>
          <v-card-text v-html="msgConfirm"></v-card-text>
          <v-card-actions>
            <v-btn color="red darken-1" text @click="naoConfirmado">Não</v-btn>
            <v-btn color="green darken-1" text @click="confirmado">Sim</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      headers: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Sigla", value: "sigla" },
        { text: "Site", value: "site", width: 200 },
        { text: "Número", value: "numero" },
        { text: "Status", value: "status" },
        { text: "Data Recepção", value: "dtCriacao" },
        { text: "Valor", value: "valor", align: "end" },
        { text: "Itens", value: "qtde", align: "end" },
        { text: "Data Exclusão", value: "dtExclusao" }
      ],
      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },

      //* Confirmação
      dialogConfirm: false,
      acaoConfirm: "",
      msgConfirm: "",
      dadoIndex: null,
      dadoItem: null,

      direitoTitulos: false,
      mensagemPesquisa: "",
      mensagem: "",
      snackErro: false,
      filtroPesquisa: "",
      registros: [],
      registrosPagina: 10,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      carregando: true,
      ordenacao: "",
      ordem: "",
      options: {}
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    getItemActions() {
      return "item.actions";
    },

    limpaFiltro() {
      this.paginaAtual = 1;
      this.pesquisaDados();
    },
    async pesquisaDados() {
      this.registros = [];
      //sinaliza que está carregando
      this.carregando = true;
      this.mensagemPesquisa = "Carregando...";

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "fin/lote-faturamento/celesc/2?pagina=" +
            this.paginaAtual +
            "&tamanho=" +
            this.registrosPagina +
            "&filtro=" +
            this.filtroPesquisa +
            "&ordenacao=" +
            this.ordenacao +
            "&ordem=" +
            this.ordem
        );

        //seta as variaveis com o retorno
        this.registros = response.data.data;
        this.totalRegistros = response.data.total;
        this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
        this.rodape.pageText = this.totalRegistros + " lotes - " + util.formatNumber(response.data.itens) + " itens = R$ " + util.formatCurrency(response.data.soma);
        //fecha o loading
        this.carregando = false;
        //se não achar registro seta a mensagem senão deixa em branco
        this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else {
          this.mensagem =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
          this.snackErro = true;
        }
      }
    },
    visualizarTitulos(item) {
      window.open("/financeiro/celesc-titulos?idLoteRecepcao=" + item.id, "_blank");
    },
    excluiLote(item) {
      this.dadoIndex = this.registros.indexOf(item);
      this.dadoItem = Object.assign({}, item);
      this.msgConfirm = "<br>TODAS as parcelas serão removidas deste lote!<br /><br /><strong>Tem certeza que deseja EXCLUIR este lote?</strong>";
      this.acaoConfirm = "excluir";
      this.dialogConfirm = true;
    },

    async excluirLote() {
      try {
        await api.put(process.env.VUE_APP_URL_WEB_API + "fin/lote-faturamento/excluirCelesc/" + this.dadoItem.id + "?tipo=" + this.dadoItem.tipo);

        this.dadoItem.status = "EXCLUÍDO";
        this.dadoItem.remessa = "";
        this.dadoItem.dtExclusao = new Date();
        this.dadoItem.numero = "";
        Object.assign(this.registros[this.dadoIndex], this.dadoItem);

        this.mensagem = "Parcelas removidas do lote";
        this.snackbar = true;
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      }
    },
    confirmado() {
      this.dialogConfirm = false;
      this.excluirLote();
    },
    naoConfirmado() {
      this.dialogConfirm = false;
    },

    formatNumber(valor) {
      return util.formatNumber(valor);
    },
    formatCurrency(valor) {
      return util.formatCurrency(valor);
    },
    formatDate(date) {
      return util.formatDate(date);
    },
    formatDateTime(datetime) {
      return util.formatDateTime(datetime);
    }
  },
  beforeMount() {
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_CELESC_RECEPÇÕES_ACESSO"]);

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;

      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_CELESC_TITULOS_ACESSO") {
        this.direitoTitulos = true;
      }
    });

    if (this.$store.state.usuario.idSitePrincipal != 46) {
      this.mensagem = "Somente Adjori pode acessar esta tela!";
      this.snackErro = true;
      setTimeout(() => {
        this.$router.push("/acesso-negado");
        return;
      }, 3000);
    }

    this.filtroPesquisa = util.formatDate(new Date()).substring(3);
  },
  watch: {
    paginaAtual() {
      this.pesquisaDados();
    },
    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }
        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }
        this.pesquisaDados();
      }
    }
  }
};
</script>
