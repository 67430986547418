<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">flash_off</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Bloqueio Celesc</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12>
            <v-autocomplete
              autofocus
              v-model="bloqueio.idSite"
              :disabled="isUpdating"
              :items="sitesCelesc"
              :filter="filtrarObjetos"
              label="Site"
              item-text="nome"
              item-value="id"
              :rules="[validacao.idSite == false || validacao.idSite, value => !!value || 'Obrigatório.']"
              no-data-text
              @change="validacao.idSite = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md6>
            <v-text-field
              v-model="bloqueio.unidadeConsumidora"
              :disabled="isUpdating"
              label="Unidade Consumidora"
              v-mask="'#############'"
              hint="Obrigatório"
              :rules="[
                validacao.unidadeConsumidora == false || validacao.unidadeConsumidora,
                value => !!value || 'Obrigatório.',
                value => value.length > 2 || 'Deve conter até 13 dígitos numéricos'
              ]"
              color="blue-grey lighten-2"
              @change="validacao.unidadeConsumidora = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md6>
            <v-text-field
              v-model="bloqueio.identificacao"
              label="Identificação (CPF/CNPJ)"
              v-mask="'##############'"
              hint="Obrigatório"
              :rules="[validacao.identificacao == false || validacao.identificacao, value => !!value || 'Obrigatório.', value => valida_identificacao(value)]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.identificacao = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs12>
            <v-text-field
              v-model="bloqueio.cliente"
              label="Titular"
              maxlength="100"
              hint="Obrigatório"
              :rules="[
                validacao.cliente == false || validacao.cliente,
                value => !!value || 'Obrigatório.',
                value => valida_nome(value) || 'Nome inválido',
                value => value.length > 2 || 'Preencha o nome do titular'
              ]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="
                bloqueio.cliente = pLetra(bloqueio.cliente);
                validacao.cliente = false;
              "
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md11>
            <v-textarea
              v-model="bloqueio.justificativa"
              label="Justificativa"
              maxlength="500"
              counter="500"
              rows="3"
              hint="Obrigatório"
              :rules="[
                validacao.justificativa == false || validacao.justificativa,
                value => !!value || 'Obrigatório.',
                value => (value != null && value.length > 10 && value.length < 1000) || 'Texto da justificativa deve ter entre 10 e 500 caracteres'
              ]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.justificativa = false"
            ></v-textarea>
          </v-flex>

          <v-flex xs6 md1>
            <v-switch v-model="bloqueio.situacao" :label="`${bloqueio.situacao == 1 ? 'Ativo' : 'Inativo'}`"></v-switch>
          </v-flex>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">
              {{ validacao.mensagemErro }}
            </v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn to="/financeiro/celesc-bloqueios" id="btn-rsn" color="orange lighten-2 white" style="color:white">
        <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar
      </v-btn>
      <v-btn :loading="isUpdating" color="blue white" style="color:white" id="btn-rsn" depressed @click="salvar('false')"> <v-icon id="icon-rsn" left>update</v-icon>Salvar </v-btn>
      <v-btn :loading="isUpdating" color="orange white" style="color:white" id="btn-rsn" outlined @click="salvar('retornar')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>

      <v-btn :loading="isUpdating" color="blue white" style="color:white" id="btn-rsn" outlined @click="salvar('true')" v-if="direitoCriar">
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Novo
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      bloqueio: {
        id: "",
        idSite: null,
        identificacao: "",
        cliente: "",
        unidadeConsumidora: "",
        dtCriacao: null,
        dtExclusao: null,
        justificativa: null,
        situacao: 1
      },
      validacao: {
        id: false,
        idSite: false,
        identificacao: false,
        cliente: false,
        unidadeConsumidora: false,
        dtCriacao: false,
        dtExclusao: false,
        justificativa: false,
        situacao: false,
        mensagemErro: false
      },

      sitesCelesc: [],

      idSitePrincipal: null,
      direitoCriar: false,
      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      this.isUpdating = true;
      try {
        var retorno = "";
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "fin/celesc-bloqueio/" + this.id, this.bloqueio);
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "fin/celesc-bloqueio?idSite=" + this.idSitePrincipal, this.bloqueio);
          this.id = retorno.data.id;
          this.bloqueio.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "true") {
          this.$router.push("/financeiro/celesc-bloqueio");
          this.$router.go("/financeiro/celesc-bloqueio");
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push("/financeiro/celesc-bloqueios");
          }, 2000);
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },
    async carregarSitesCelesc() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/site/celesc");

        this.sitesCelesc = response.data;
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de sites que utilizam Celesc";
        this.snackErro = true;
      }
    },

    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    pLetra(text) {
      return util.pLetra(text);
    },
    valida_nome(text) {
      return util.valida_nome(text);
    },
    valida_identificacao(text) {
      var retorno = true;
      if (text.length != 11 && text.length != 14) {
        retorno = "Identificação deve ter 11 ou 14 dígitos";
      } else {
        if (text.length == 11) {
          retorno = util.valida_cpf(text) ? true : "CPF inválido";
        } else {
          retorno = util.valida_cnpj(text) ? true : "CNPJ inválido";
        }
      }
      return retorno;
    }
  },

  async created() {
    this.id = this.$route.params.id ? this.$route.params.id : "";

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_CELESC_BLOQUEIOS_CRIAR") {
        this.direitoCriar = true;
      }
    });

    //* Verifica se o site do usuário está em GRUPO - OBS: ou ele compartilha a informação ou deixa exclusiva para o seu site

    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
    if (this.idSitePrincipal != 46) {
      this.mensagem = "Somente Adjori pode acessar esta tela!";
      this.snackErro = true;
      setTimeout(() => {
        this.$router.push("/acesso-negado");
        return;
      }, 3000);
    }

    await this.carregarSitesCelesc();

    try {
      //* Se é edição, carrega o item
      if (this.id == "") {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_CELESC_BLOQUEIOS_CRIAR"]);
      } else {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_CELESC_BLOQUEIOS_EDITAR"]);
        //* Carrega o item - somente se for público (idSite=null) ou for do site do usuário logado
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/celesc-bloqueio/" + this.id + "?idSite=" + this.idSitePrincipal);
        if (!response.data) {
          this.$router.push("/financeiro/celesc-bloqueios");
        } else {
          this.bloqueio = response.data;
        }
      }
    } catch {
      this.mensagem = "Ocorreu um erro ao carregar os dados";
      this.snackErro = true;
    }
  }
};
</script>
