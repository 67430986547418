<template>
  <div id="app">
    <v-app id="inspire">
      <v-card>
        <v-toolbar color="#333333">
          <v-icon color="white" class="mr-2">card_giftcard</v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light">Cartões do Clube do Assinante</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="blue" fab small v-if="direitoExportar && idProdutoClube" depressed @click="pesquisaDados('clube')" :loading="loading">
            <v-icon color="white" title="Carteirinhas">card_giftcard</v-icon>
          </v-btn>
          <v-btn class="ml-2" color="blue" fab small v-if="direitoExportar && idProdutoHelp" depressed @click="pesquisaDados('help')" :loading="loading">
            <v-icon color="white" title="Help">volunteer_activism</v-icon>
          </v-btn>
          <v-btn class="ml-2" color="blue" fab small v-if="direitoExportar" depressed @click="exportar" :loading="loading">
            <v-icon color="white" title="Exportar">description</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="px-6 mt-4">
          <v-container fluid grid-list-md>
            <v-layout wrap>
              <v-flex xs12 md2>
                <v-autocomplete
                  v-model="idProduto"
                  :items="produtosClube"
                  :filter="filtrarObjetos"
                  label="Produto"
                  item-text="nome"
                  item-value="id"
                  no-data-text
                  clearable
                  @change="limpaFiltro"
                  :disabled="loading"
                >
                </v-autocomplete>
              </v-flex>
              <v-flex xs12 md1>
                <v-autocomplete
                  v-model="idAtivo"
                  :items="itens"
                  :filter="filtrarObjetos"
                  label="Ativo"
                  item-text="nome"
                  item-value="id"
                  no-data-text
                  clearable
                  @change="limpaFiltro"
                  :disabled="loading"
                >
                </v-autocomplete>
              </v-flex>
              <v-flex xs12 md1>
                <v-autocomplete
                  v-model="idVencido"
                  :items="itens"
                  :filter="filtrarObjetos"
                  label="Vencido"
                  item-text="nome"
                  item-value="id"
                  no-data-text
                  clearable
                  @change="limpaFiltro"
                  :disabled="loading"
                >
                </v-autocomplete>
              </v-flex>
              <v-flex xs12 md2>
                <v-menu v-model="mnuInclusao" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="intervaloInclusao"
                      label="Período Inclusão"
                      prepend-icon="event"
                      v-on="on"
                      color="blue-grey lighten-2"
                      clearable
                      @click:clear="
                        intervaloInclusaoUS = ['', ''];
                        limpaFiltro();
                      "
                      readonly
                      :disabled="loading"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="intervaloInclusaoUS" locale="pt-br" no-title range
                    ><v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="
                        mnuInclusao = false;
                        limpaFiltro();
                      "
                      :disabled="loading"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 md2>
                <v-menu v-model="mnuCancelamento" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="intervaloCancelamento"
                      label="Período Cancelamento"
                      prepend-icon="event"
                      v-on="on"
                      color="blue-grey lighten-2"
                      clearable
                      @click:clear="
                        intervaloCancelamentoUS = ['', ''];
                        limpaFiltro();
                      "
                      readonly
                      :disabled="loading"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="intervaloCancelamentoUS" locale="pt-br" no-title range
                    ><v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="
                        mnuCancelamento = false;
                        limpaFiltro();
                      "
                      :disabled="loading"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex xs12 md4>
                <v-text-field
                  background-color="white"
                  class="mb-2 elevation-0"
                  v-model="filtroPesquisa"
                  append-icon="search"
                  label="Digite para pesquisar..."
                  placeholder="Pesquisa pela assinatura, nome/identificação do titular ou dependente"
                  hide-details
                  @keyup.enter="limpaFiltro"
                  @click:append="limpaFiltro"
                  :disabled="loading"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>

          <v-data-table
            mobile-breakpoint="0"
            :headers="headers"
            :items="registros"
            :options.sync="options"
            :no-data-text="mensagemPesquisa"
            :footer-props="rodape"
            :disable-sort="carregando"
            :disable-pagination="carregando"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon
                v-if="item.dtCancelamento == null && direitoCancelar"
                class="mr-2"
                color="red"
                title="Cancelar Solicitação de Cartão do Clube"
                @click="cancelarCartaoClube(item)"
                >delete</v-icon
              >
              <v-icon v-if="direitoEditar" color="blue lighten-2" title="Visualizar" @click="visualizarCartaoClube(item)">loupe</v-icon>
            </template>

            <template v-slot:item.titular="{ item }">
              <span>{{ item.titular }} - {{ item.identTit }}</span>
            </template>
            <template v-slot:item.dependente="{ item }">
              <span>{{ item.dependente }} - {{ item.identDep }}</span>
            </template>
            <template v-slot:item.sitDep="{ item }">
              <span
                :class="
                  item.status == 'CORRENTE'
                    ? 'blue--text'
                    : item.status == 'SUSPENSA' || item.status == 'CORTADA'
                    ? 'orange--text'
                    : item.status == 'CANCELADA' || item.status == 'RECUPERAÇÃO' || item.status == 'EXPIRADA'
                    ? 'red--text'
                    : item.status == 'PROPOSTA'
                    ? 'green--text'
                    : item.status == 'EXCLUÍDA'
                    ? 'red--text'
                    : ''
                "
                v-html="
                  !['CORRENTE', 'SUSPENSA'].includes(item.status)
                    ? item.status
                    : item.sitDep == 0
                    ? '<strong>Inativo</strong>'
                    : item.dtCancelamento
                    ? '<strong>Cancelado</strong>'
                    : parseDateUS(item.dtValidade) < parseDateUS(new Date())
                    ? '<strong>Expirado</strong>'
                    : 'Ativo'
                "
              ></span>
            </template>
            <template v-slot:item.dtRegistro="{ item }">
              <span>{{ formatDateTime(item.dtRegistro) }}</span>
            </template>
            <template v-slot:item.dtValidade="{ item }">
              <span>{{ formatDate(item.dtValidade) }}</span>
            </template>
            <template v-slot:item.dtExportacao="{ item }">
              <span>{{ formatDateTime(item.dtExportacao) }}</span>
            </template>
            <template v-slot:item.dtCancelamento="{ item }">
              <span>{{ formatDateTime(item.dtCancelamento) }}</span>
            </template>
            <template v-slot:item.dtExclusao="{ item }">
              <span>{{ formatDateTime(item.dtExclusao) }}</span>
            </template>
          </v-data-table>
          <div class="text-xs-center pt-2 marg-bt">
            <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 11" circle></v-pagination>
          </div>
          <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
          <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
        </div>
      </v-card>
      <v-dialog persistent v-model="dialogCartaoClube" max-width="600px" @keydown.esc="dialogCartaoClube = false">
        <v-card>
          <v-form ref="formCartaoClube" v-model="cartaoClubeValid" lazy-validation>
            <v-toolbar>
              <span class="headline">Cartão do Clube do Assinante</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="closeCartaoClube">
                <v-icon color="darken-1">cancel</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-container fluid grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 md12>
                    <v-text-field v-model="cartaoClubeItem.dependente" label="Dependente" readonly color="blue-grey lighten-2"></v-text-field>
                  </v-flex>
                  <v-flex xs12 md12>
                    <v-text-field v-model="cartaoClubeItem.usuario" label="Usuário" readonly color="blue-grey lighten-2"></v-text-field>
                  </v-flex>
                  <v-expansion-panels v-model="painel" focusable>
                    <v-expansion-panel v-if="cartaoClubeItem.dtExportacao != null">
                      <v-divider></v-divider>
                      <v-expansion-panel-header>
                        <strong>Exportação</strong>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-flex xs12 md6>
                          <v-text-field v-model="cartaoClubeItem.dtExp" label="Data" readonly color="blue-grey lighten-2"></v-text-field>
                        </v-flex>
                        <v-flex xs12 md12>
                          <v-text-field v-model="cartaoClubeItem.usuarioExportacao" label="Usuário" readonly color="blue-grey lighten-2"></v-text-field>
                        </v-flex>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel v-if="cartaoClubeItem.dtCancelamento != null">
                      <v-divider></v-divider>
                      <v-expansion-panel-header>
                        <strong>Cancelamento</strong>
                      </v-expansion-panel-header>

                      <v-expansion-panel-content>
                        <v-flex xs12 md6>
                          <v-text-field v-model="cartaoClubeItem.dtCanc" label="Data" readonly color="blue-grey lighten-2"></v-text-field>
                        </v-flex>
                        <v-flex xs12 md12>
                          <v-text-field v-model="cartaoClubeItem.usuarioCancelamento" label="Usuário" readonly color="blue-grey lighten-2"></v-text-field>
                        </v-flex>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" @click="closeCartaoClube">Retornar</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
import download from "downloadjs";
export default {
  data() {
    return {
      headers: [
        { text: "Ações", value: "actions", width: "90" },
        { text: "ID", value: "id" },
        { text: "Assinatura", value: "idAssinatura" },
        { text: "Titular", value: "titular" },
        { text: "Dependente", value: "dependente" },
        { text: "Situação/Status", value: "sitDep" },
        { text: "Produto", value: "produto" },
        { text: "Cadastro", value: "dtRegistro" },
        { text: "Validade", value: "dtValidade" },
        { text: "Exportação", value: "dtExportacao" },
        { text: "Cancelamento", value: "dtCancelamento" },
        { text: "Exclusão", value: "dtExclusao" }
      ],
      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },

      direitoEditar: false,
      direitoExportar: false,
      direitoCancelar: false,
      painel: [0, 0],
      dialogCartaoClube: false,
      cartaoClubeValid: true,
      cartaoClubeIndex: -1,
      cartaoClubeItem: {
        id: 0,
        idDependente: null,
        dependente: "",
        usuario: "",
        usuarioExportacao: null,
        usuarioCancelamento_: null,
        dtExportacao: null,
        dtExp: null,
        dtCancelamento: null,
        dtCanc: null
      },
      defaultCartaoClubeItem: {
        id: 0,
        idDependente: null,
        dependente: "",
        usuario: "",
        usuarioExportacao: null,
        usuarioCancelamento_: null,
        dtExportacao: null,
        dtExp: null,
        dtCancelamento: null,
        dtCanc: null
      },

      idProduto: null,
      produtosClube: [],
      idProdutoHelp: null,
      idProdutoClube: null,

      idAtivo: 1,
      idVencido: 0,
      itens: [
        { id: 1, nome: "Sim" },
        { id: 0, nome: "Não" },
        { id: null, nome: "Ambos" }
      ],

      mnuCancelamento: false,
      intervaloCancelamentoUS: ["", ""],

      mnuInclusao: false,
      intervaloInclusaoUS: ["", ""],

      loading: false,
      mensagem: "",
      snackbar: false,
      snackErro: false,
      mensagemPesquisa: "",
      filtroPesquisa: "",
      registros: [],
      registrosPagina: 10,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      carregando: true,
      ordenacao: "",
      ordem: "",
      options: {}
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    limpaFiltro() {
      this.paginaAtual = 1;
      this.pesquisaDados();
    },
    async pesquisaDados(cartoesClube) {
      this.loading = true;
      this.registros = [];
      //sinaliza que está carregando
      this.carregando = true;
      this.mensagemPesquisa = "Carregando...";

      if (cartoesClube == "help") {
        this.idProduto = this.idProdutoHelp;
      } else if (cartoesClube == "clube") {
        this.idProduto = this.idProdutoClube;
      }

      var dtInicio = "";
      var dtFim = "";
      if (this.intervaloCancelamentoUS && this.intervaloCancelamentoUS.length > 1 && this.intervaloCancelamentoUS[0] && this.intervaloCancelamentoUS[1]) {
        dtInicio = this.intervaloCancelamentoUS[0];
        dtFim = this.intervaloCancelamentoUS[1];
        this.idAtivo = null;
        this.idVencido = null;
        setTimeout(() => {
          this.idAtivo = 0;
          this.idVencido = 0;
        }, 300);
        setTimeout(() => {
          this.idAtivo = null;
          this.idVencido = null;
        }, 500);
      }

      var dtInicioInc = "";
      var dtFimInc = "";
      if (this.intervaloInclusaoUS && this.intervaloInclusaoUS.length > 1 && this.intervaloInclusaoUS[0] && this.intervaloInclusaoUS[1]) {
        dtInicioInc = this.intervaloInclusaoUS[0];
        dtFimInc = this.intervaloInclusaoUS[1];
      }

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "ass/assinatura-clube-assinante-cartao?pagina=" +
            this.paginaAtual +
            "&tamanho=" +
            this.registrosPagina +
            "&filtro=" +
            this.filtroPesquisa +
            (this.idProduto ? "&idProduto=" + this.idProduto : "") +
            (cartoesClube == "clube" ? "&cartoesClube=1" : cartoesClube == "help" ? "&help=1" : "") +
            ("&ativo=" + this.idAtivo) +
            ("&vencido=" + this.idVencido) +
            (dtInicio ? "&dtInicio=" + dtInicio + "&dtFim=" + dtFim : "") +
            (dtInicioInc ? "&dtInicioInc=" + dtInicioInc + "&dtFimInc=" + dtFimInc : "") +
            "&ordenacao=" +
            this.ordenacao +
            "&ordem=" +
            this.ordem
        );

        //seta as variaveis com o retorno
        this.registros = response.data.data;
        this.registros.forEach(element => {
          element.dtCanc = util.formatDateTime(element.dtCancelamento);
          element.dtExp = util.formatDateTime(element.dtExportacao);
        });
        this.totalRegistros = response.data.total;
        this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
        this.rodape.pageText = this.totalRegistros + " itens";
        //fecha o loading
        this.carregando = false;
        this.loading = false;
        //se não achar registro seta a mensagem senão deixa em branco
        this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
        if (["clube", "help"].includes(cartoesClube)) {
          this.mensagemPesquisa = "Relatório gerado - verifique suas mensagens";
        }
      } catch (e) {
        this.carregando = false;
        this.loading = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else {
          this.mensagemPesquisa = "Erro ao buscar dados: " + e;
        }
      }
    },
    visualizarCartaoClube(item) {
      this.cartaoClubeIndex = this.registros.indexOf(item);
      this.cartaoClubeItem = Object.assign({}, item);
      this.dialogCartaoClube = true;
    },
    async cancelarCartaoClube(cartao) {
      var cartaoItem = Object.assign({}, cartao);
      cartaoItem.dtCancelamento = new Date();
      cartaoItem.dtCanc = util.formatDateTime(new Date());
      var usuarioCartao = {
        idUsuarioCancelamento: this.$store.state.usuario.id
      };
      try {
        await api.put(process.env.VUE_APP_URL_WEB_API + "ass/assinatura-clube-assinante-cartao/cancelar/" + cartaoItem.id, usuarioCartao);

        var cartaoIndex = this.registros.indexOf(cartao);
        Object.assign(this.registros[cartaoIndex], cartaoItem);
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },
    closeCartaoClube() {
      this.dialogCartaoClube = false;
      setTimeout(() => {
        this.cartaoClubeItem = Object.assign({}, this.defaultCartaoClubeItem);
        this.cartaoClubeIndex = -1;
        this.$refs.formCartaoClube.resetValidation();
      }, 300);
    },
    async exportar() {
      try {
        //* Carrega os dados do site principal
        const responseSite = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/site/completo/" + this.$store.state.usuario.idSitePrincipal);

        //* Ids a serem informados que foram exportados
        var arrCartoesIds = [];
        var configExportacaoCartao = {
          nome: responseSite.data.nome,
          nomePrograma: "ASCA0923",
          fixo2: "Carta Clube do Assinante-Não desativar",
          prefixoArquivo: "CartaoClube_"
        };

        //* Carrega a data atual
        var dataAtual = new Date();
        var dataAtualLimpa =
          dataAtual
            .getDate()
            .toString()
            .padStart(2, "0") +
          (dataAtual.getMonth() + 1).toString().padStart(2, "0") +
          dataAtual.getFullYear().toString();
        //var horaAtualLimpa = dataAtual.getHours().toString() + dataAtual.getMinutes().toString() + dataAtual.getSeconds().toString();

        //* Carrega os cartões
        const apiExportacao = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/assinatura-clube-assinante-cartao/exportacao");

        //* Define a constante com os dados retornados
        const arrCartoes = apiExportacao.data;

        //* Define o conteudo do arquivo de saida
        const arrCabecalhoArquivo = {
          fixo1: "0",
          nome: configExportacaoCartao.nome.padEnd(25, " ").substring(0, 25),
          nomePrograma: configExportacaoCartao.nomePrograma.padEnd(8, " ").substring(0, 8),
          fixo2: configExportacaoCartao.fixo2.padEnd(40, " ").substring(0, 40),
          data: dataAtualLimpa
        };

        var textCartoesExportados =
          arrCabecalhoArquivo.fixo1 + arrCabecalhoArquivo.nome + arrCabecalhoArquivo.nomePrograma + arrCabecalhoArquivo.fixo2 + arrCabecalhoArquivo.data + "\n";

        //* Percorre os cartões exportados
        arrCartoes.forEach(function(cartao) {
          //* Insere o id do cartao exportado
          arrCartoesIds.push(cartao.id);

          //* Define os dados de cada cartão a ser incluído no arquivo
          const arrCartaoExportado = {
            fixo1: "0",
            assinatura: util
              .nullToString(cartao.idAssinatura)
              .padEnd(7, " ")
              .substring(0, 7),
            plano: util
              .nullToString(cartao.planoNome)
              .padEnd(40, " ")
              .substring(0, 40),
            nome:
              cartao.idDependente == null
                ? util
                    .nullToString(cartao.nome)
                    .padEnd(40, " ")
                    .substring(0, 40)
                : util
                    .nullToString(cartao.dependenteNome)
                    .padEnd(40, " ")
                    .substring(0, 40),
            numeroResidencia: util
              .nullToString(cartao.enderecoNumero)
              .padEnd(5, " ")
              .substring(0, 5),
            uf: util
              .nullToString(cartao.enderecoUf)
              .padEnd(2, " ")
              .substring(0, 2),
            cep: util
              .nullToString(cartao.cep)
              .padEnd(8, " ")
              .substring(0, 8),
            tipoLogradouro: util
              .nullToString(cartao.enderecoTipoLogradouro)
              .padEnd(3, " ")
              .substring(0, 3),
            logradouro: util
              .nullToString(cartao.enderecoLogradouro)
              .padEnd(40, " ")
              .substring(0, 40),
            bairro: util
              .nullToString(cartao.enderecoBairro)
              .padEnd(40, " ")
              .substring(0, 40),
            cidade: util
              .nullToString(cartao.enderecoCidade)
              .padEnd(40, " ")
              .substring(0, 40),
            complementoTipo: util
              .nullToString(cartao.complementoTipo)
              .padEnd(3, " ")
              .substring(0, 3),
            complementoNumero: util
              .nullToString(cartao.complementoNumero)
              .padEnd(5, " ")
              .substring(0, 5),
            complementoBloco: util
              .nullToString(cartao.complementoBloco)
              .padEnd(2, " ")
              .substring(0, 2)
          };

          //* Insere o cartão atual no arquivo de saída
          textCartoesExportados +=
            arrCartaoExportado.fixo1 +
            arrCartaoExportado.assinatura +
            arrCartaoExportado.plano +
            arrCartaoExportado.nome +
            arrCartaoExportado.tipoLogradouro +
            arrCartaoExportado.logradouro +
            arrCartaoExportado.numeroResidencia +
            arrCartaoExportado.complementoTipo +
            arrCartaoExportado.complementoNumero +
            arrCartaoExportado.complementoBloco +
            arrCartaoExportado.bairro +
            arrCartaoExportado.cidade +
            arrCartaoExportado.uf +
            arrCartaoExportado.cep +
            "\n";
        });

        //* Verifica se foram exportados os cartões
        if (arrCartoesIds.length > 0) {
          //* Sinaliza os cartões que foram exportados
          await api.put(process.env.VUE_APP_URL_WEB_API + "ass/assinatura-clube-assinante-cartao/exportados/" + this.$store.state.usuario.id, arrCartoesIds);

          //* Atualiza a lista
          this.pesquisaDados();

          //* Retorna o download do arquivo
          download(
            textCartoesExportados,
            configExportacaoCartao.prefixoArquivo +
              dataAtualLimpa +
              dataAtual
                .getHours()
                .toString()
                .padStart(2, "0") +
              dataAtual
                .getMinutes()
                .toString()
                .padStart(2, "0") +
              dataAtual
                .getSeconds()
                .toString()
                .padStart(2, "0") +
              ".txt",
            "text/plain"
          );
        }
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },
    async carregarProdutosClube() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/produto/lista?tipo=CLUBE");
        this.produtosClube = response.data;
        this.produtosClube.forEach(element => {
          if (element.nome.toUpperCase() == "HELP") {
            this.idProdutoHelp = element.id;
          } else {
            this.idProdutoClube = element.id;
          }
        });
      } catch (e) {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de produtos do clube";
        this.snackErro = true;
      }
    },

    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    parseDateUS(date) {
      return util.parseDateUS(date);
    },
    formatDate(date) {
      return util.formatDate(date);
    },
    formatDateTime(date) {
      return util.formatDateTime(date);
    }
  },
  async beforeMount() {
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_CARTÃO_CLUBE_ACESSO"]);
    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_CARTÃO_CLUBE_EDITAR") {
        this.direitoEditar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_CARTÃO_CLUBE_EXPORTAR") {
        this.direitoExportar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_CARTÃO_CLUBE_CANCELAR") {
        this.direitoCancelar = true;
      }
    });
    await this.carregarProdutosClube();
  },
  computed: {
    intervaloCancelamento() {
      var retorno = "";
      if (this.intervaloCancelamentoUS && this.intervaloCancelamentoUS.length > 1) {
        this.intervaloCancelamentoUS.forEach(element => {
          if (element) {
            retorno += (retorno ? " - " : "") + util.formatDate(element);
          }
        });
      }
      return retorno;
    },
    intervaloInclusao() {
      var retorno = "";
      if (this.intervaloInclusaoUS && this.intervaloInclusaoUS.length > 1) {
        this.intervaloInclusaoUS.forEach(element => {
          if (element) {
            retorno += (retorno ? " - " : "") + util.formatDate(element);
          }
        });
      }
      return retorno;
    }
  },
  watch: {
    dialogCartaoClube(val) {
      val || this.closeCartaoClube();
    },
    paginaAtual() {
      this.pesquisaDados();
    },
    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }

        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }

        this.pesquisaDados();
      }
    }
  }
};
</script>
