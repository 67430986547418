<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">settings_applications</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Configuração do Fechamento do Roteiro</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12 md12 v-if="siteGrupo.length > 0">
            <v-autocomplete
              v-model="configFechRoteiro.idSite"
              :disabled="isUpdating"
              :items="siteGrupo"
              :filter="filtrarObjetos"
              label="Restrito ao Site"
              item-text="nome"
              item-value="id"
              :rules="[validacao.idSite == false || validacao.idSite]"
              clearable
              no-data-text
              @change="validacao.idSite = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12>
            <h4 class="overline font-weight-black font-italic">Fechamento <span style="color:blue">Segunda-Feira</span></h4>
            <v-divider></v-divider>
          </v-flex>
          <v-flex xs6 md1>
            <v-text-field
              v-model="configFechRoteiro.hsSeg"
              label="Horário"
              v-mask="'##:##'"
              hint="Obrigatório"
              :rules="[validacao.hsSeg == false || validacao.hsSeg, value => valida_hora(value) || 'Data inválida']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.hsSeg = false"
            ></v-text-field>
          </v-flex>
          <v-flex xs6 md1>
            <v-text-field
              v-model="configFechRoteiro.diasSeg"
              label="Pular"
              v-mask="'#'"
              suffix="dia(s)"
              hint="Obrigatório"
              :rules="[
                validacao.diasSeg == false || validacao.diasSeg,
                value => parseInt(value) >= 0 || 'Obrigatório.',
                value => (parseInt(value) >= 0 && parseInt(value) < 7) || 'Número de dias inválido'
              ]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @keyup="calculaPulo('SEG')"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md10>
            <v-subheader class="mt-2" v-html="`<span>Lançamentos* após o fechamento irão para <b>${diaSeg}</b></span>`"></v-subheader>
          </v-flex>

          <v-flex xs12>
            <h4 class="overline font-weight-black font-italic">Fechamento <span style="color:blue">Terça-Feira</span></h4>
            <v-divider></v-divider>
          </v-flex>
          <v-flex xs6 md1>
            <v-text-field
              v-model="configFechRoteiro.hsTer"
              label="Horário"
              v-mask="'##:##'"
              hint="Obrigatório"
              :rules="[validacao.hsTer == false || validacao.hsTer, value => valida_hora(value) || 'Data inválida']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.hsTer = false"
            ></v-text-field>
          </v-flex>
          <v-flex xs6 md1>
            <v-text-field
              v-model="configFechRoteiro.diasTer"
              label="Pular"
              v-mask="'#'"
              suffix="dia(s)"
              hint="Obrigatório"
              :rules="[
                validacao.diasTer == false || validacao.diasTer,
                value => parseInt(value) >= 0 || 'Obrigatório.',
                value => (parseInt(value) >= 0 && parseInt(value) < 7) || 'Número de dias inválido'
              ]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @keyup="calculaPulo('TER')"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md10>
            <v-subheader class="mt-2" v-html="`<span>Lançamentos* após o fechamento irão para <b>${diaTer}</b></span>`"></v-subheader>
          </v-flex>

          <v-flex xs12>
            <h4 class="overline font-weight-black font-italic">Fechamento <span style="color:blue">Quarta-Feira</span></h4>
            <v-divider></v-divider>
          </v-flex>
          <v-flex xs6 md1>
            <v-text-field
              v-model="configFechRoteiro.hsQua"
              label="Horário"
              v-mask="'##:##'"
              hint="Obrigatório"
              :rules="[validacao.hsQua == false || validacao.hsQua, value => valida_hora(value) || 'Data inválida']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.hsQua = false"
            ></v-text-field>
          </v-flex>
          <v-flex xs6 md1>
            <v-text-field
              v-model="configFechRoteiro.diasQua"
              label="Pular"
              v-mask="'#'"
              suffix="dia(s)"
              hint="Obrigatório"
              :rules="[
                validacao.diasQua == false || validacao.diasQua,
                value => parseInt(value) >= 0 || 'Obrigatório.',
                value => (parseInt(value) >= 0 && parseInt(value) < 7) || 'Número de dias inválido'
              ]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @keyup="calculaPulo('QUA')"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md10>
            <v-subheader class="mt-2" v-html="`<span>Lançamentos* após o fechamento irão para <b>${diaQua}</b></span>`"></v-subheader>
          </v-flex>

          <v-flex xs12>
            <h4 class="overline font-weight-black font-italic">Fechamento <span style="color:blue">Quinta-Feira</span></h4>
            <v-divider></v-divider>
          </v-flex>
          <v-flex xs6 md1>
            <v-text-field
              v-model="configFechRoteiro.hsQui"
              label="Horário"
              v-mask="'##:##'"
              hint="Obrigatório"
              :rules="[validacao.hsQui == false || validacao.hsQui, value => valida_hora(value) || 'Data inválida']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.hsQui = false"
            ></v-text-field>
          </v-flex>
          <v-flex xs6 md1>
            <v-text-field
              v-model="configFechRoteiro.diasQui"
              label="Pular"
              v-mask="'#'"
              suffix="dia(s)"
              hint="Obrigatório"
              :rules="[
                validacao.diasQui == false || validacao.diasQui,
                value => parseInt(value) >= 0 || 'Obrigatório.',
                value => (parseInt(value) >= 0 && parseInt(value) < 7) || 'Número de dias inválido'
              ]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @keyup="calculaPulo('QUI')"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md10>
            <v-subheader class="mt-2" v-html="`<span>Lançamentos* após o fechamento irão para <b>${diaQui}</b></span>`"></v-subheader>
          </v-flex>

          <v-flex xs12>
            <h4 class="overline font-weight-black font-italic">Fechamento <span style="color:blue">Sexta-Feira</span></h4>
            <v-divider></v-divider>
          </v-flex>
          <v-flex xs6 md1>
            <v-text-field
              v-model="configFechRoteiro.hsSex"
              label="Horário"
              v-mask="'##:##'"
              hint="Obrigatório"
              :rules="[validacao.hsSex == false || validacao.hsSex, value => valida_hora(value) || 'Data inválida']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.hsSex = false"
            ></v-text-field>
          </v-flex>
          <v-flex xs6 md1>
            <v-text-field
              v-model="configFechRoteiro.diasSex"
              label="Pular"
              v-mask="'#'"
              suffix="dia(s)"
              hint="Obrigatório"
              :rules="[
                validacao.diasSex == false || validacao.diasSex,
                value => parseInt(value) >= 0 || 'Obrigatório.',
                value => (parseInt(value) >= 0 && parseInt(value) < 7) || 'Número de dias inválido'
              ]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @keyup="calculaPulo('SEX')"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md10>
            <v-subheader class="mt-2" v-html="`<span>Lançamentos* após o fechamento irão para <b>${diaSex}</b></span>`"></v-subheader>
          </v-flex>

          <v-flex xs12>
            <h4 class="overline font-weight-black font-italic">Fechamento <span style="color:blue">Sábado</span></h4>
            <v-divider></v-divider>
          </v-flex>
          <v-flex xs6 md1>
            <v-text-field
              v-model="configFechRoteiro.hsSab"
              label="Horário"
              v-mask="'##:##'"
              hint="Obrigatório"
              :rules="[validacao.hsSab == false || validacao.hsSab, value => valida_hora(value) || 'Data inválida']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.hsSab = false"
            ></v-text-field>
          </v-flex>
          <v-flex xs6 md1>
            <v-text-field
              v-model="configFechRoteiro.diasSab"
              label="Pular"
              v-mask="'#'"
              suffix="dia(s)"
              hint="Obrigatório"
              :rules="[
                validacao.diasSab == false || validacao.diasSab,
                value => parseInt(value) >= 0 || 'Obrigatório.',
                value => (parseInt(value) >= 0 && parseInt(value) < 7) || 'Número de dias inválido'
              ]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @keyup="calculaPulo('SAB')"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md10>
            <v-subheader class="mt-2" v-html="`<span>Lançamentos* após o fechamento irão para <b>${diaSab}</b></span>`"></v-subheader>
          </v-flex>

          <v-flex xs12>
            <h4 class="overline font-weight-black font-italic">Fechamento <span style="color:blue">Domingo</span></h4>
            <v-divider></v-divider>
          </v-flex>
          <v-flex xs6 md1>
            <v-text-field
              v-model="configFechRoteiro.hsDom"
              label="Horário"
              v-mask="'##:##'"
              hint="Obrigatório"
              :rules="[validacao.hsDom == false || validacao.hsDom, value => valida_hora(value) || 'Data inválida']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.hsDom = false"
            ></v-text-field>
          </v-flex>
          <v-flex xs6 md1>
            <v-text-field
              v-model="configFechRoteiro.diasDom"
              label="Pular"
              v-mask="'#'"
              suffix="dia(s)"
              hint="Obrigatório"
              :rules="[
                validacao.diasDom == false || validacao.diasDom,
                value => parseInt(value) >= 0 || 'Obrigatório.',
                value => (parseInt(value) >= 0 && parseInt(value) < 7) || 'Número de dias inválido'
              ]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @keyup="calculaPulo('DOM')"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md10>
            <v-subheader class="mt-2" v-html="`<span>Lançamentos* após o fechamento irão para <b>${diaDom}</b></span>`"></v-subheader>
          </v-flex>

          <v-flex xs12 md12>
            <v-switch v-model="configFechRoteiro.situacao" :label="`${configFechRoteiro.situacao == 1 ? 'Ativa' : 'Inativa'}`"></v-switch>
          </v-flex>
          <v-flex xs12>
            <v-subheader>*Início do período (em novas captações), agendamentos de suspensões, cancelamentos e endereços de edições.</v-subheader>
          </v-flex>
          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">
              {{ validacao.mensagemErro }}
            </v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn to="/assinaturas/desktop" id="btn-rsn" color="orange lighten-2 white" style="color:white"> <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar </v-btn>
      <v-btn v-if="direitoEditar" :loading="isUpdating" color="blue white" style="color:white" id="btn-rsn" depressed @click="salvar('false')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      configFechRoteiro: {
        id: "",
        idSite: null,
        hsSeg: "",
        hsTer: "",
        hsQua: "",
        hsQui: "",
        hsSex: "",
        hsSab: "",
        hsDom: "",
        diasSeg: 0,
        diasTer: 0,
        diasQua: 0,
        diasQui: 0,
        diasSex: 0,
        diasSab: 0,
        diasDom: 0,
        situacao: 1
      },
      validacao: {
        id: false,
        idSite: false,
        hsSeg: false,
        hsTer: false,
        hsQua: false,
        hsQui: false,
        hsSex: false,
        hsSab: false,
        hsDom: false,
        diasSeg: false,
        diasTer: false,
        diasQua: false,
        diasQui: false,
        diasSex: false,
        diasSab: false,
        diasDom: false,
        situacao: false,
        mensagemErro: false
      },

      siteGrupo: [],

      diaSeg: "terça-feira",
      diaTer: "quarta-feira",
      diaQua: "quinta-feira",
      diaQui: "sexta-feira",
      diaSex: "sábado",
      diaSab: "domingo",
      diaDom: "segunda-feira",

      idSitePrincipal: null,
      direitoEditar: false,
      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    async salvar() {
      await util.limparObjeto(this.validacao, false);

      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      this.isUpdating = true;
      try {
        var retorno = "";
        //se tiver id é um update
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "ass/config-fechamento-roteiro/" + this.id, this.configFechRoteiro);
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "ass/config-fechamento-roteiro?idSite=" + this.idSitePrincipal, this.configFechRoteiro);
          this.id = retorno.data.id;
          this.configFechRoteiro.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },

    valida_hora(hora) {
      return util.valida_hora(hora);
    },
    calculaPulo(dia) {
      const listaDias = [
        "domingo",
        "segunda-feira",
        "terça-feira",
        "quarta-feira",
        "quinta-feira",
        "sexta-feira",
        "sábado",
        "domingo",
        "segunda-feira",
        "terça-feira",
        "quarta-feira",
        "quinta-feira",
        "sexta-feira",
        "sábado"
      ];
      if (dia == "SEG") {
        this.validacao.diasSeg = false;
        if (parseInt(this.configFechRoteiro.diasSeg) < 7) {
          this.diaSeg = listaDias[2 + parseInt(this.configFechRoteiro.diasSeg)];
        } else {
          this.diaSeg = "";
        }
      } else if (dia == "TER") {
        this.validacao.diasTer = false;
        if (parseInt(this.configFechRoteiro.diasTer) < 7) {
          this.diaTer = listaDias[3 + parseInt(this.configFechRoteiro.diasTer)];
        } else {
          this.diaTer = "";
        }
      } else if (dia == "QUA") {
        this.validacao.diasQua = false;
        if (parseInt(this.configFechRoteiro.diasQua) < 7) {
          this.diaQua = listaDias[4 + parseInt(this.configFechRoteiro.diasQua)];
        } else {
          this.diaQua = "";
        }
      } else if (dia == "QUI") {
        this.validacao.diasQui = false;
        if (parseInt(this.configFechRoteiro.diasQui) < 7) {
          this.diaQui = listaDias[5 + parseInt(this.configFechRoteiro.diasQui)];
        } else {
          this.diaQui = "";
        }
      } else if (dia == "SEX") {
        this.validacao.diasSex = false;
        if (parseInt(this.configFechRoteiro.diasSex) < 7) {
          this.diaSex = listaDias[6 + parseInt(this.configFechRoteiro.diasSex)];
        } else {
          this.diaSex = "";
        }
      } else if (dia == "SAB") {
        this.validacao.diasSab = false;
        if (parseInt(this.configFechRoteiro.diasSab) < 7) {
          this.diaSab = listaDias[0 + parseInt(this.configFechRoteiro.diasSab)];
        } else {
          this.diaSab = "";
        }
      } else if (dia == "DOM") {
        this.validacao.diasDom = false;
        if (parseInt(this.configFechRoteiro.diasDom) < 7) {
          this.diaDom = listaDias[1 + parseInt(this.configFechRoteiro.diasDom)];
        } else {
          this.diaDom = "";
        }
      }
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    }
  },

  async created() {
    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_CONFIG_FECHAMENTO_ROTEIRO_EDITAR") {
        this.direitoEditar = true;
      }
    });

    //* Verifica se o site do usuário está em GRUPO - OBS: ou ele compartilha a informação ou deixa exclusiva para o seu site
    var idGrupoSite = this.$store.state.usuario.site_idGrupo;
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
    if (idGrupoSite != null) {
      this.siteGrupo = [
        {
          id: this.idSitePrincipal,
          nome: this.$store.state.usuario.site_nome
        }
      ];
    }
    try {
      this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_CONFIG_FECHAMENTO_ROTEIRO_ACESSO"]);
      //* Carrega o elemento - somente se for público (idSite=null) ou for do site do usuário logado
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/config-fechamento-roteiro?idSite=" + this.idSitePrincipal);
      if (!response.data) {
        this.id = "";
      } else {
        this.id = response.data.id;
        this.configFechRoteiro = response.data;
        this.calculaPulo("SEG");
        this.calculaPulo("TER");
        this.calculaPulo("QUA");
        this.calculaPulo("QUI");
        this.calculaPulo("SEX");
        this.calculaPulo("SAB");
        this.calculaPulo("DOM");
      }
    } catch {
      this.mensagem = "Ocorreu um erro ao carregar os dados";
      this.snackErro = true;
    }
  }
};
</script>
