import Vue from "vue";
import Router from "vue-router";
import VueCookies from "vue-cookies";
import store from "./store"; //* Mecanismo de rotas

import AcessoNegado from "./AcessoNegado.vue";
import Dashboard from "./views/Dashboard.vue";
import Login from "./views/Login.vue";

//* Rotas do Módulo Administrativo
//================================
import DeskTopADM from "./views/adm/Desktop.vue";

import Paises from "./views/adm/Paises.vue";
import Pais from "./views/adm/Pais.vue";

import UFs from "./views/adm/UFs.vue";
import UF from "./views/adm/UF.vue";

import Municipios from "./views/adm/Municipios.vue";
import Municipio from "./views/adm/Municipio.vue";

import Redes from "./views/adm/Redes.vue";
import Rede from "./views/adm/Rede.vue";

import Grupos from "./views/adm/Grupos.vue";
import Grupo from "./views/adm/Grupo.vue";

import Sites from "./views/adm/Sites.vue";
import Site from "./views/adm/Site.vue";

import Perfis from "./views/adm/Perfis.vue";
import Perfil from "./views/adm/Perfil.vue";

import Usuarios from "./views/adm/Usuarios.vue";
import Usuario from "./views/adm/Usuario.vue";

import TiposLogradouro from "./views/adm/TiposLogradouro.vue";
import TipoLogradouro from "./views/adm/TipoLogradouro.vue";

import CEPs from "./views/adm/CEPs.vue";
import CEP from "./views/adm/CEP.vue";

import AcervoCEPs from "./views/adm/AcervoCEPs.vue";
//import AcervoCEP from "./views/adm/AcervoCEP.vue";

import ConfigsEmails from "./views/adm/ConfigsEmails.vue";
import ConfigEmail from "./views/adm/ConfigEmail.vue";

import Feriados from "./views/adm/Feriados.vue";
import Feriado from "./views/adm/Feriado.vue";

import FormasPagamento from "./views/adm/FormasPagamento.vue";
import FormaPagamento from "./views/adm/FormaPagamento.vue";

import MotivosContato from "./views/adm/MotivosContato.vue";
import MotivoContato from "./views/adm/MotivoContato.vue";

import RamosAtividade from "./views/adm/RamosAtividade.vue";
import RamoAtividade from "./views/adm/RamoAtividade.vue";

import Programacoes from "./views/adm/Programacoes.vue";
import Programacao from "./views/adm/Programacao.vue";

import Clientes from "./views/adm/Clientes.vue"; //fica no módulo administrativo pois é comum a todos os módulos
import Cliente from "./views/adm/Cliente.vue"; //fica no módulo administrativo pois é comum a todos os módulos

import AcervoClientes from "./views/adm/AcervoClientes.vue";
// import AcervoCliente from "./views/adm/AcervoCliente.vue";

import Mensagens from "./views/adm/Mensagens.vue";

//* Rotas do Módulo Comercial
//============================
import DeskTopCOM from "./views/com/Desktop.vue";

import Anuncios from "./views/com/Anuncios.vue";
import Anuncio from "./views/com/Anuncio.vue";

import Pacotes from "./views/com/Pacotes.vue";
import Pacote from "./views/com/Pacote.vue";

import ProdutosComercial from "./views/com/Produtos.vue";
import ProdutoComercial from "./views/com/Produto.vue";

import Cadernos from "./views/com/Cadernos.vue";
import Caderno from "./views/com/Caderno.vue";

import CondicoesEspeciais from "./views/com/CondicoesEspeciais.vue";
import CondicaoEspecial from "./views/com/CondicaoEspecial.vue";

import Formatos from "./views/com/Formatos.vue";
import Formato from "./views/com/Formato.vue";

import Determinacoes from "./views/com/Determinacoes.vue";
import Determinacao from "./views/com/Determinacao.vue";

import Colocacoes from "./views/com/Colocacoes.vue";
import Colocacao from "./views/com/Colocacao.vue";

import Secoes from "./views/com/Secoes.vue";
import Secao from "./views/com/Secao.vue";

import TabelasPreco from "./views/com/TabelasPreco.vue";
import TabelaPreco from "./views/com/TabelaPreco.vue";

import Textos from "./views/com/Textos.vue";
import Texto from "./views/com/Texto.vue";

// import Diagramas from "./views/com/Diagramas.vue";
// import Diagrama from "./views/com/Diagrama.vue";

//* Rotas do Módulo Assinaturas
//=============================
import DeskTopASS from "./views/ass/Desktop.vue";

import Assinaturas from "./views/ass/Assinaturas.vue";
import AssinaturaEdicao from "./views/ass/AssinaturaEdicao.vue";
import AssinaturaCaptacao from "./views/ass/AssinaturaCaptacao.vue";

import RoteiroEntrega from "./views/ass/RoteiroEntrega.vue";
import HistoricoRoteiro from "./views/ass/HistoricoRoteiro.vue";

import EtapasCobranca from "./views/ass/EtapasCobranca.vue";
import EtapaCobrancaGrupo from "./views/ass/EtapaCobrancaGrupo.vue";
import EtapaCobranca from "./views/ass/EtapaCobranca.vue";

import ClubeAssinante from "./views/ass/ClubeAssinante.vue";

import CuponsPromocoes from "./views/ass/CuponsPromocoes.vue";

import Modalidades from "./views/ass/Modalidades.vue";
import Modalidade from "./views/ass/Modalidade.vue";

import ModosEntrega from "./views/ass/ModosEntrega.vue";
import ModoEntrega from "./views/ass/ModoEntrega.vue";

import MotivosCancelamento from "./views/ass/MotivosCancelamento.vue";
import MotivoCancelamento from "./views/ass/MotivoCancelamento.vue";

import MotivosSuspensao from "./views/ass/MotivosSuspensao.vue";
import MotivoSuspensao from "./views/ass/MotivoSuspensao.vue";

import Cobranca from "./views/ass/Cobranca.vue";

import Recuperacao from "./views/ass/Recuperacao.vue";

import ReclamacoesPadrao from "./views/ass/ReclamacoesPadrao.vue";
import ReclamacaoPadrao from "./views/ass/ReclamacaoPadrao.vue";

import Origens from "./views/ass/Origens.vue";
import Origem from "./views/ass/Origem.vue";

import Setores from "./views/ass/Setores.vue";
import Setor from "./views/ass/Setor.vue";

import PaywallPlanos from "./views/ass/PaywallPlanos.vue";
import PaywallPlano from "./views/ass/PaywallPlano.vue";

import PaywallAssines from "./views/ass/PaywallAssines.vue";
import PaywallAssine from "./views/ass/PaywallAssine.vue";

import Reclamacoes from "./views/ass/Reclamacoes.vue";

import Contatos from "./views/adm/Contatos.vue";

import Produtos from "./views/ass/Produtos.vue";
import Produto from "./views/ass/Produto.vue";

import Planos from "./views/ass/Planos.vue";
import Plano from "./views/ass/Plano.vue";

import Promocoes from "./views/ass/Promocoes.vue";
import Promocao from "./views/ass/Promocao.vue";

// import RegrasEnderecos from "./views/ass/RegrasEnderecos.vue";
// import RegraEndereco from "./views/ass/RegraEndereco.vue";

import VendaAvulsaTabelas from "./views/ass/VendaAvulsaTabelas.vue";
import VendaAvulsaTabela from "./views/ass/VendaAvulsaTabela.vue";

import RegrasAjuste from "./views/ass/RegrasAjuste.vue";
import RegraAjuste from "./views/ass/RegraAjuste.vue";

import Entregadores from "./views/ass/Entregadores.vue";
import Entregador from "./views/ass/Entregador.vue";

import Rotas from "./views/ass/Rotas.vue";
import Rota from "./views/ass/Rota.vue";

import VendaAvulsaRepartes from "./views/ass/VendaAvulsaRepartes.vue";
import VendaAvulsaReparte from "./views/ass/VendaAvulsaReparte.vue";

import VendaAvulsaFaturamento from "./views/ass/VendaAvulsaFaturamento.vue";

import TiposBanca from "./views/ass/TiposBanca.vue";
import TipoBanca from "./views/ass/TipoBanca.vue";

import RegrasComissionamento from "./views/ass/RegrasComissionamento.vue";
import RegraComissionamento from "./views/ass/RegraComissionamento.vue";

import RegrasComissionamentoUsuarios from "./views/ass/RegrasComissionamentoUsuarios.vue";

import ConfigFechamentoRoteiro from "./views/ass/ConfigFechamentoRoteiro.vue";
import ConfigIVC from "./views/ass/ConfigIVC.vue";

import MunicipiosIVC from "./views/ass/MunicipiosIVC";
import MunicipioIVC from "./views/ass/MunicipioIVC.vue";

import Carteira from "./views/ass/rel/Carteira.vue";
import Comissionamento from "./views/ass/rel/Comissionamento.vue";
import Distribuicao from "./views/ass/rel/Distribuicao.vue";
import IVC from "./views/ass/rel/IVC.vue";

//* Rotas do Módulo Financeiro
//============================
import DeskTopFIN from "./views/fin/Desktop.vue";

import Extratos from "./views/fin/Extratos.vue";
import Extrato from "./views/fin/Extrato.vue";

import Operacoes from "./views/fin/Operacoes.vue";
import Operacao from "./views/fin/Operacao.vue";

import Parcelas from "./views/fin/Parcelas.vue";
import Parcela from "./views/fin/Parcela.vue";

import RemessasRetornos from "./views/fin/RemessasRetornos.vue";

import Emails from "./views/fin/Emails.vue";

import Transferencias from "./views/fin/Transferencias.vue";
import Transferencia from "./views/fin/Transferencia.vue";

import Bancos from "./views/fin/Bancos.vue";
import Banco from "./views/fin/Banco.vue";

import Cedentes from "./views/fin/Cedentes.vue";
import Cedente from "./views/fin/Cedente.vue";

import CelescBloqueios from "./views/fin/CelescBloqueios.vue";
import CelescBloqueio from "./views/fin/CelescBloqueio.vue";
import CelescTitulos from "./views/fin/CelescTitulos.vue";
import CelescRecepcoes from "./views/fin/CelescRecepcoes.vue";
import CelescRemessasRetornos from "./views/fin/CelescRemessasRetornos.vue";

import CentrosCusto from "./views/fin/CentrosCusto.vue";
import CentroCusto from "./views/fin/CentroCusto.vue";

import Contabilistas from "./views/fin/Contabilistas.vue";
import Contabilista from "./views/fin/Contabilista.vue";

import Contas from "./views/fin/Contas.vue";
import Conta from "./views/fin/Conta.vue";

import NFe from "./views/fin/NFe.vue";
import NFSC from "./views/fin/NFSC.vue";
import NFSe from "./views/fin/NFSe.vue";

import FechamentoNFSC from "./views/fin/FechamentoNFSC.vue";
import FechamentoSPED from "./views/fin/FechamentoSPED.vue";

import ConfigNFe from "./views/fin/ConfigNFe.vue";
import ConfigNFSC from "./views/fin/ConfigNFSC.vue";
import ConfigNFSe from "./views/fin/ConfigNFSe.vue";

import Apresentacao from "./views/fin/rel/Apresentacao.vue";
import Balancete from "./views/fin/rel/Balancete.vue";
import ClienteFornecedor from "./views/fin/rel/ClienteFornecedor.vue";
import Conciliacao from "./views/fin/rel/Conciliacao.vue";
import MotivosParcela from "./views/fin/MotivosParcela.vue";
import MotivoParcela from "./views/fin/MotivoParcela.vue";

//* Rotas do Módulo EDT
//============================
import DeskTopEDT from "./views/edt/Desktop.vue";

import Editorias from "./views/edt/Editorias.vue";
import Editoria from "./views/edt/Editoria.vue";
import Artigos from "./views/edt/Artigos.vue";
import Artigo from "./views/edt/Artigo.vue";


//* Rotas do Módulo FLIP
//============================
import DeskTopFLIP from "./views/flip/Desktop.vue";

import Edicoes from "./views/flip/Edicoes.vue";
import Edicao from "./views/flip/Edicao.vue";
import Flip from "./views/flip/Flip.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: Dashboard
    },
    {
      path: "/login",
      component: Login,
      meta: {
        publico: true,
        somenteDeslogado: true
      }
    },
    {
      path: "/acesso-negado",
      component: AcessoNegado
    },

    //* Rotas do Módulo Administrativo
    //================================
    {
      path: "/administrativo/desktop",
      component: DeskTopADM
    },

    {
      path: "/administrativo/paises",
      component: Paises
    },
    {
      path: "/administrativo/pais",
      component: Pais
    },
    {
      path: "/administrativo/pais/:id",
      component: Pais
    },

    {
      path: "/administrativo/ufs",
      component: UFs
    },
    {
      path: "/administrativo/uf",
      component: UF
    },
    {
      path: "/administrativo/uf/:id",
      component: UF
    },

    {
      path: "/administrativo/municipios",
      component: Municipios
    },
    {
      path: "/administrativo/municipio",
      component: Municipio
    },
    {
      path: "/administrativo/municipio/:id",
      component: Municipio
    },

    {
      path: "/administrativo/redes",
      component: Redes
    },
    {
      path: "/administrativo/rede",
      component: Rede
    },
    {
      path: "/administrativo/rede/:id",
      component: Rede
    },

    {
      path: "/administrativo/grupos",
      component: Grupos
    },
    {
      path: "/administrativo/grupo",
      component: Grupo
    },
    {
      path: "/administrativo/grupo/:id",
      component: Grupo
    },

    {
      path: "/administrativo/sites",
      component: Sites
    },
    {
      path: "/administrativo/site",
      component: Site
    },
    {
      path: "/administrativo/site/:id",
      component: Site
    },

    {
      path: "/administrativo/perfis",
      component: Perfis
    },
    {
      path: "/administrativo/perfil",
      component: Perfil
    },
    {
      path: "/administrativo/perfil/:id",
      component: Perfil
    },

    {
      path: "/administrativo/usuarios",
      component: Usuarios
    },
    {
      path: "/administrativo/usuario",
      component: Usuario
    },
    {
      path: "/administrativo/usuario/:id",
      component: Usuario
    },

    {
      path: "/administrativo/tipos-logradouro",
      component: TiposLogradouro
    },
    {
      path: "/administrativo/tipo-logradouro",
      component: TipoLogradouro
    },
    {
      path: "/administrativo/tipo-logradouro/:id",
      component: TipoLogradouro
    },

    {
      path: "/administrativo/ceps",
      component: CEPs
    },
    {
      path: "/administrativo/cep",
      component: CEP
    },
    {
      path: "/administrativo/cep/:id",
      component: CEP
    },

    {
      path: "/administrativo/acervo-ceps",
      component: AcervoCEPs
    },
    // {
    //   path: "/administrativo/acervo-cep/:id",
    //   component: AcervoCEP
    // },

    {
      path: "/administrativo/acervo-clientes",
      component: AcervoClientes
    },
    // {
    //   path: "/administrativo/acervo-cliente/:id",
    //   component: AcervoCliente
    // },

    {
      path: "/administrativo/agentes",
      component: Clientes
    },
    {
      path: "/administrativo/agente",
      component: Cliente
    },
    {
      path: "/administrativo/agente/:id",
      component: Cliente
    },

    {
      path: "/administrativo/configs-emails",
      component: ConfigsEmails
    },
    {
      path: "/administrativo/config-email",
      component: ConfigEmail
    },
    {
      path: "/administrativo/config-email/:id",
      component: ConfigEmail
    },

    {
      path: "/administrativo/feriados",
      component: Feriados
    },
    {
      path: "/administrativo/feriado",
      component: Feriado
    },
    {
      path: "/administrativo/feriado/:id",
      component: Feriado
    },

    {
      path: "/administrativo/formas-pagamento",
      component: FormasPagamento
    },
    {
      path: "/administrativo/forma-pagamento",
      component: FormaPagamento
    },
    {
      path: "/administrativo/forma-pagamento/:id",
      component: FormaPagamento
    },

    {
      path: "/administrativo/motivos-contato",
      component: MotivosContato
    },
    {
      path: "/administrativo/motivo-contato",
      component: MotivoContato
    },
    {
      path: "/administrativo/motivo-contato/:id",
      component: MotivoContato
    },

    {
      path: "/administrativo/ramos-atividade",
      component: RamosAtividade
    },
    {
      path: "/administrativo/ramo-atividade",
      component: RamoAtividade
    },
    {
      path: "/administrativo/ramo-atividade/:id",
      component: RamoAtividade
    },

    {
      path: "/administrativo/radios",
      component: Programacoes
    },
    {
      path: "/administrativo/radio",
      component: Programacao
    },
    {
      path: "/administrativo/radio/:id",
      component: Programacao
    },

    {
      path: "/administrativo/tvs",
      component: Programacoes
    },
    {
      path: "/administrativo/tv",
      component: Programacao
    },
    {
      path: "/administrativo/tv/:id",
      component: Programacao
    },

    {
      path: "/administrativo/mensagens",
      component: Mensagens
    },

    //* Rotas do Módulo Assinaturas
    //=============================
    {
      path: "/assinaturas/desktop",
      component: DeskTopASS
    },
    {
      path: "/assinaturas/assinantes",
      component: Clientes
    },
    {
      path: "/assinaturas/assinante",
      component: Cliente
    },
    {
      path: "/assinaturas/assinante/:id",
      component: Cliente
    },

    {
      path: "/assinaturas/assinaturas",
      component: Assinaturas
    },
    //* Captação de nova assinatura
    {
      path: "/assinaturas/assinatura",
      component: AssinaturaCaptacao
    },
    //* Edição da renovação (se ainda não iniciou, tudo exceto dataFim; caso já tenha iniciado: autorização, parcelas e encerrar ou agendar encerramento criando uma renovação futura)
    {
      path: "/assinaturas/assinatura/:idAssinatura/:idRenovacao",
      component: AssinaturaCaptacao
    },
    //* Edição da assinatura como um todo
    {
      path: "/assinaturas/assinatura/:id",
      component: AssinaturaEdicao
    },

    {
      path: "/assinaturas/roteiro-entrega",
      component: RoteiroEntrega
    },
    {
      path: "/assinaturas/venda-avulsa-roteiro-entrega",
      component: RoteiroEntrega
    },
    {
      path: "/assinaturas/roteiro-historico",
      component: HistoricoRoteiro
    },

    {
      path: "/assinaturas/bancas",
      component: Clientes
    },
    {
      path: "/assinaturas/banca",
      component: Cliente
    },
    {
      path: "/assinaturas/banca/:id",
      component: Cliente
    },

    {
      path: "/assinaturas/etapas-cobranca",
      component: EtapasCobranca
    },
    {
      path: "/assinaturas/etapa-cobranca",
      component: EtapaCobranca
    },
    {
      path: "/assinaturas/etapa-cobranca/:id",
      component: EtapaCobranca
    },
    {
      path: "/assinaturas/etapa-cobranca-grupo",
      component: EtapaCobrancaGrupo
    },
    {
      path: "/assinaturas/etapa-cobranca-grupo/:id",
      component: EtapaCobrancaGrupo
    },

    {
      path: "/assinaturas/modalidades",
      component: Modalidades
    },
    {
      path: "/assinaturas/modalidade",
      component: Modalidade
    },
    {
      path: "/assinaturas/modalidade/:id",
      component: Modalidade
    },

    {
      path: "/assinaturas/modos-entrega",
      component: ModosEntrega
    },
    {
      path: "/assinaturas/modo-entrega",
      component: ModoEntrega
    },
    {
      path: "/assinaturas/modo-entrega/:id",
      component: ModoEntrega
    },

    {
      path: "/assinaturas/motivos-cancelamento",
      component: MotivosCancelamento
    },
    {
      path: "/assinaturas/motivo-cancelamento",
      component: MotivoCancelamento
    },
    {
      path: "/assinaturas/motivo-cancelamento/:id",
      component: MotivoCancelamento
    },

    {
      path: "/assinaturas/motivos-suspensao",
      component: MotivosSuspensao
    },
    {
      path: "/assinaturas/motivo-suspensao",
      component: MotivoSuspensao
    },
    {
      path: "/assinaturas/motivo-suspensao/:id",
      component: MotivoSuspensao
    },

    {
      path: "/assinaturas/cobranca",
      component: Cobranca
    },

    {
      path: "/assinaturas/recuperacao",
      component: Recuperacao
    },

    {
      path: "/assinaturas/clube-assinante",
      component: ClubeAssinante
    },

    {
      path: "/assinaturas/reclamacoes",
      component: Reclamacoes
    },

    {
      path: "/assinaturas/contatos",
      component: Contatos
    },

    {
      path: "/assinaturas/cupons-promocoes",
      component: CuponsPromocoes
    },

    {
      path: "/assinaturas/reclamacoes-padrao",
      component: ReclamacoesPadrao
    },
    {
      path: "/assinaturas/reclamacao-padrao",
      component: ReclamacaoPadrao
    },
    {
      path: "/assinaturas/reclamacao-padrao/:id",
      component: ReclamacaoPadrao
    },

    {
      path: "/assinaturas/setores",
      component: Setores
    },
    {
      path: "/assinaturas/setor",
      component: Setor
    },
    {
      path: "/assinaturas/setor/:id",
      component: Setor
    },

    {
      path: "/assinaturas/origens",
      component: Origens
    },
    {
      path: "/assinaturas/origem",
      component: Origem
    },
    {
      path: "/assinaturas/origem/:id",
      component: Origem
    },

    {
      path: "/assinaturas/paywall/planos",
      component: PaywallPlanos
    },
    {
      path: "/assinaturas/paywall/plano",
      component: PaywallPlano
    },
    {
      path: "/assinaturas/paywall/plano/:id",
      component: PaywallPlano
    },

    {
      path: "/assinaturas/paywall/assines",
      component: PaywallAssines
    },
    {
      path: "/assinaturas/paywall/assine",
      component: PaywallAssine
    },
    {
      path: "/assinaturas/paywall/assine/:id",
      component: PaywallAssine
    },

    {
      path: "/assinaturas/produtos",
      component: Produtos
    },
    {
      path: "/assinaturas/produto",
      component: Produto
    },
    {
      path: "/assinaturas/produto/:id",
      component: Produto
    },

    {
      path: "/assinaturas/planos",
      component: Planos
    },
    {
      path: "/assinaturas/plano",
      component: Plano
    },
    {
      path: "/assinaturas/plano/:id",
      component: Plano
    },

    {
      path: "/assinaturas/promocoes",
      component: Promocoes
    },
    {
      path: "/assinaturas/promocao",
      component: Promocao
    },
    {
      path: "/assinaturas/promocao/:id",
      component: Promocao
    },

    // {
    //   path: "/assinaturas/regras-enderecos",
    //   component: RegrasEnderecos
    // },
    // {
    //   path: "/assinaturas/regra-endereco",
    //   component: RegraEndereco
    // },
    // {
    //   path: "/assinaturas/regra-endereco/:id",
    //   component: RegraEndereco
    // },

    {
      path: "/assinaturas/venda-avulsa-tabelas",
      component: VendaAvulsaTabelas
    },
    {
      path: "/assinaturas/venda-avulsa-tabela",
      component: VendaAvulsaTabela
    },
    {
      path: "/assinaturas/venda-avulsa-tabela/:id",
      component: VendaAvulsaTabela
    },

    {
      path: "/assinaturas/venda-avulsa-repartes",
      component: VendaAvulsaRepartes
    },
    {
      path: "/assinaturas/venda-avulsa-reparte",
      component: VendaAvulsaReparte
    },
    {
      path: "/assinaturas/venda-avulsa-reparte/:id",
      component: VendaAvulsaReparte
    },

    {
      path: "/assinaturas/venda-avulsa-faturamento",
      component: VendaAvulsaFaturamento
    },

    {
      path: "/assinaturas/regras-ajuste",
      component: RegrasAjuste
    },
    {
      path: "/assinaturas/regra-ajuste",
      component: RegraAjuste
    },
    {
      path: "/assinaturas/regra-ajuste/:id",
      component: RegraAjuste
    },

    {
      path: "/assinaturas/entregadores",
      component: Entregadores
    },
    {
      path: "/assinaturas/entregador",
      component: Entregador
    },
    {
      path: "/assinaturas/entregador/:id",
      component: Entregador
    },

    {
      path: "/assinaturas/rotas",
      component: Rotas
    },
    {
      path: "/assinaturas/rota",
      component: Rota
    },
    {
      path: "/assinaturas/rota/:id",
      component: Rota
    },
    {
      path: "/assinaturas/tipos-logradouro",
      component: TiposLogradouro
    },
    {
      path: "/assinaturas/tipo-logradouro",
      component: TipoLogradouro
    },
    {
      path: "/assinaturas/tipo-logradouro/:id",
      component: TipoLogradouro
    },

    {
      path: "/assinaturas/ceps",
      component: CEPs
    },
    {
      path: "/assinaturas/cep",
      component: CEP
    },
    {
      path: "/assinaturas/cep/:id",
      component: CEP
    },

    {
      path: "/assinaturas/tipos-banca",
      component: TiposBanca
    },
    {
      path: "/assinaturas/tipo-banca",
      component: TipoBanca
    },
    {
      path: "/assinaturas/tipo-banca/:id",
      component: TipoBanca
    },

    {
      path: "/assinaturas/regras-comissionamento",
      component: RegrasComissionamento
    },
    {
      path: "/assinaturas/regra-comissionamento",
      component: RegraComissionamento
    },
    {
      path: "/assinaturas/regra-comissionamento/:id",
      component: RegraComissionamento
    },

    // {
    //   path: "/assinaturas/regras-comissionamento-usuarios",
    //   component: RegrasComissionamentoUsuarios
    // },

    {
      path: "/assinaturas/config-fechamento-roteiro",
      component: ConfigFechamentoRoteiro
    },
    {
      path: "/assinaturas/config-ivc",
      component: ConfigIVC
    },

    {
      path: "/assinaturas/municipios-ivc",
      component: MunicipiosIVC
    },
    {
      path: "/assinaturas/municipio-ivc",
      component: MunicipioIVC
    },

    {
      path: "/assinaturas/relatorios/carteira",
      component: Carteira
    },
    {
      path: "/assinaturas/relatorios/comissionamento",
      component: Comissionamento
    },
    {
      path: "/assinaturas/relatorios/distribuicao",
      component: Distribuicao
    },
    {
      path: "/assinaturas/relatorios/ivc",
      component: IVC
    },

    //* Rotas do Módulo Comercial
    //=============================
    {
      path: "/comercial/desktop",
      component: DeskTopCOM
    },

    {
      path: "/comercial/anunciantes",
      component: Clientes
    },
    {
      path: "/comercial/anunciante",
      component: Cliente
    },
    {
      path: "/comercial/anunciante/:id",
      component: Cliente
    },

    {
      path: "/comercial/pacotes",
      component: Pacotes
    },
    {
      path: "/comercial/pacote",
      component: Pacote
    },
    {
      path: "/comercial/pacote/:id",
      component: Pacote
    },

    {
      path: "/comercial/anuncios",
      component: Anuncios
    },
    {
      path: "/comercial/anuncio",
      component: Anuncio
    },
    {
      path: "/comercial/anuncio/:id",
      component: Anuncio
    },

    {
      path: "/comercial/cobranca",
      component: Cobranca
    },

    {
      path: "/comercial/contatos",
      component: Contatos
    },

    {
      path: "/comercial/produtos",
      component: ProdutosComercial
    },
    {
      path: "/comercial/produto",
      component: ProdutoComercial
    },
    {
      path: "/comercial/produto/:id",
      component: ProdutoComercial
    },

    {
      path: "/comercial/cadernos",
      component: Cadernos
    },
    {
      path: "/comercial/caderno",
      component: Caderno
    },
    {
      path: "/comercial/caderno/:id",
      component: Caderno
    },

    {
      path: "/comercial/condicoes-especiais",
      component: CondicoesEspeciais
    },
    {
      path: "/comercial/condicao-especial",
      component: CondicaoEspecial
    },
    {
      path: "/comercial/condicao-especial/:id",
      component: CondicaoEspecial
    },

    {
      path: "/comercial/regras-ajuste",
      component: RegrasAjuste
    },
    {
      path: "/comercial/regra-ajuste",
      component: RegraAjuste
    },
    {
      path: "/comercial/regra-ajuste/:id",
      component: RegraAjuste
    },

    {
      path: "/comercial/formatos",
      component: Formatos
    },
    {
      path: "/comercial/formato",
      component: Formato
    },
    {
      path: "/comercial/formato/:id",
      component: Formato
    },

    {
      path: "/comercial/determinacoes",
      component: Determinacoes
    },
    {
      path: "/comercial/determinacao",
      component: Determinacao
    },
    {
      path: "/comercial/determinacao/:id",
      component: Determinacao
    },

    {
      path: "/comercial/colocacoes",
      component: Colocacoes
    },
    {
      path: "/comercial/colocacao",
      component: Colocacao
    },
    {
      path: "/comercial/colocacao/:id",
      component: Colocacao
    },

    {
      path: "/comercial/secoes",
      component: Secoes
    },
    {
      path: "/comercial/secao/:mae",
      component: Secao
    },
    {
      path: "/comercial/secao/:mae/:id",
      component: Secao
    },

    {
      path: "/comercial/anuncio-tabelas",
      component: TabelasPreco
    },
    {
      path: "/comercial/anuncio-tabela",
      component: TabelaPreco
    },
    {
      path: "/comercial/anuncio-tabela/:id",
      component: TabelaPreco
    },

    {
      path: "/comercial/textos",
      component: Textos
    },
    {
      path: "/comercial/texto",
      component: Texto
    },
    {
      path: "/comercial/texto/:id",
      component: Texto
    },

    // {
    //   path: "/comercial/diagramas",
    //   component: Diagramas
    // },
    // {
    //   path: "/comercial/diagrama",
    //   component: Diagrama
    // },
    // {
    //   path: "/comercial/diagrama/:id",
    //   component: Diagrama
    // },

    {
      path: "/comercial/etapas-cobranca",
      component: EtapasCobranca
    },
    {
      path: "/comercial/etapa-cobranca",
      component: EtapaCobranca
    },
    {
      path: "/comercial/etapa-cobranca/:id",
      component: EtapaCobranca
    },
    {
      path: "/comercial/etapa-cobranca-grupo",
      component: EtapaCobrancaGrupo
    },
    {
      path: "/comercial/etapa-cobranca-grupo/:id",
      component: EtapaCobrancaGrupo
    },

    //* Rotas do Módulo Financeiro
    //============================
    {
      path: "/financeiro/desktop",
      component: DeskTopFIN
    },

    {
      path: "/financeiro/clientes",
      component: Clientes
    },
    {
      path: "/financeiro/cliente",
      component: Cliente
    },
    {
      path: "/financeiro/cliente/:id",
      component: Cliente
    },

    {
      path: "/financeiro/fornecedores",
      component: Clientes
    },
    {
      path: "/financeiro/fornecedor",
      component: Cliente
    },
    {
      path: "/financeiro/fornecedor/:id",
      component: Cliente
    },

    {
      path: "/financeiro/extratos",
      component: Extratos
    },
    {
      path: "/financeiro/extrato/:id",
      component: Extrato
    },

    {
      path: "/financeiro/operacoes/receber",
      component: Operacoes
    },
    {
      path: "/financeiro/operacao/receber",
      component: Operacao
    },
    {
      path: "/financeiro/operacao/receber/:id",
      component: Operacao
    },

    {
      path: "/financeiro/parcelas/receber",
      component: Parcelas
    },
    {
      path: "/financeiro/parcela/receber/:id",
      component: Parcela
    },

    {
      path: "/financeiro/remessas/receber",
      component: RemessasRetornos
    },

    {
      path: "/financeiro/retornos/receber",
      component: RemessasRetornos
    },

    {
      path: "/financeiro/operacoes/pagar",
      component: Operacoes
    },
    {
      path: "/financeiro/operacao/pagar",
      component: Operacao
    },
    {
      path: "/financeiro/operacao/pagar/:id",
      component: Operacao
    },

    {
      path: "/financeiro/parcelas/pagar",
      component: Parcelas
    },
    {
      path: "/financeiro/parcela/pagar/:id",
      component: Parcela
    },

    {
      path: "/financeiro/remessas/pagar",
      component: RemessasRetornos
    },

    {
      path: "/financeiro/retornos/pagar",
      component: RemessasRetornos
    },

    {
      path: "/financeiro/emails",
      component: Emails
    },

    {
      path: "/financeiro/bancos",
      component: Bancos
    },
    {
      path: "/financeiro/banco",
      component: Banco
    },
    {
      path: "/financeiro/banco/:id",
      component: Banco
    },

    {
      path: "/financeiro/cedentes",
      component: Cedentes
    },
    {
      path: "/financeiro/cedente",
      component: Cedente
    },
    {
      path: "/financeiro/cedente/:id",
      component: Cedente
    },

    {
      path: "/financeiro/celesc-titulos",
      component: CelescTitulos
    },
    {
      path: "/financeiro/celesc-bloqueios",
      component: CelescBloqueios
    },
    {
      path: "/financeiro/celesc-bloqueio",
      component: CelescBloqueio
    },
    {
      path: "/financeiro/celesc-bloqueio/:id",
      component: CelescBloqueio
    },
    {
      path: "/financeiro/celesc-recepcoes",
      component: CelescRecepcoes
    },
    {
      path: "/financeiro/celesc-remessas",
      component: CelescRemessasRetornos
    },

    {
      path: "/financeiro/celesc-retornos",
      component: CelescRemessasRetornos
    },

    {
      path: "/financeiro/transferencias",
      component: Transferencias
    },
    {
      path: "/financeiro/transferencia",
      component: Transferencia
    },
    {
      path: "/financeiro/transferencia/:id",
      component: Transferencia
    },

    {
      path: "/financeiro/centros-custo",
      component: CentrosCusto
    },
    {
      path: "/financeiro/centro-custo",
      component: CentroCusto
    },
    {
      path: "/financeiro/centro-custo/:id",
      component: CentroCusto
    },

    {
      path: "/financeiro/contabilistas",
      component: Contabilistas
    },
    {
      path: "/financeiro/contabilista",
      component: Contabilista
    },
    {
      path: "/financeiro/contabilista/:id",
      component: Contabilista
    },

    {
      path: "/financeiro/contas/:grupo",
      component: Contas
    },
    {
      path: "/financeiro/conta/:grupo/:mae",
      component: Conta
    },
    {
      path: "/financeiro/conta/:grupo/:mae/:id",
      component: Conta
    },

    {
      path: "/financeiro/nfe",
      component: NFe
    },
    {
      path: "/financeiro/nfsc",
      component: NFSC
    },
    {
      path: "/financeiro/nfse",
      component: NFSe
    },

    {
      path: "/financeiro/fechamento/nfsc",
      component: FechamentoNFSC
    },

    {
      path: "/financeiro/fechamento/sped",
      component: FechamentoSPED
    },

    {
      path: "/financeiro/config-nfe",
      component: ConfigNFe
    },
    {
      path: "/financeiro/config-nfsc",
      component: ConfigNFSC
    },
    {
      path: "/financeiro/config-nfse",
      component: ConfigNFSe
    },

    {
      path: "/financeiro/relatorios/apresentacao",
      component: Apresentacao
    },
    {
      path: "/financeiro/relatorios/balancete",
      component: Balancete
    },
    {
      path: "/financeiro/relatorios/cliente-fornecedor",
      component: ClienteFornecedor
    },
    {
      path: "/financeiro/relatorios/conciliacao",
      component: Conciliacao
    },

    {
      path: "/financeiro/cobranca",
      component: Cobranca
    },

    {
      path: "/financeiro/contatos",
      component: Contatos
    },

    {
      path: "/financeiro/etapas-cobranca",
      component: EtapasCobranca
    },
    {
      path: "/financeiro/etapa-cobranca",
      component: EtapaCobranca
    },
    {
      path: "/financeiro/etapa-cobranca/:id",
      component: EtapaCobranca
    },
    {
      path: "/financeiro/etapa-cobranca-grupo",
      component: EtapaCobrancaGrupo
    },
    {
      path: "/financeiro/etapa-cobranca-grupo/:id",
      component: EtapaCobrancaGrupo
    },

    {
      path: "/financeiro/motivos-parcela",
      component: MotivosParcela
    },
    {
      path: "/financeiro/motivo-parcela",
      component: MotivoParcela
    },
    {
      path: "/financeiro/motivo-parcela/:id",
      component: MotivoParcela
    },

    //* Rotas do Módulo Editorial
    //=============================
    {
      path: "/editorial/desktop",
      component: DeskTopEDT
    },

    {
      path: "/editorial/editorias",
      component: Editorias
    },
    {
      path: "/editorial/editoria/:mae",
      component: Editoria
    },
    {
      path: "/editorial/editoria/:mae/:id",
      component: Editoria
    },
    {
      path: "/editorial/artigos",
      component: Artigos
    },
    {
      path: "/editorial/artigo/:idEditoria/:id/:idConteudo",
      component: Artigo
    },
    {
      path: "/editorial/artigo",
      component: Artigo
    },
   
    //* Rotas do Módulo Flip
    //======================
    {
      path: "/flip/desktop",
      component: DeskTopFLIP
    },
    {
      path: "/flip/edicoes",
      component: Edicoes
    },
    {
      path: "/flip/edicao",
      component: Edicao
    },
    {
      path: "/flip/edicao/:id",
      component: Edicao
    },
    {
      path: "/flip/flip",
      component: Flip
    }
  ]
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  const publico = to.matched.some(record => record.meta.publico);
  const logout = to.matched.some(record => record.meta.somenteDeslogado);
  const logado = VueCookies.get("token") ? true : false;

  if (!publico && !logado) {
    return next({
      path: "/login",
      query: to.fullPath != "/" ? { redirect: to.fullPath } : ""
    });
  }

  if (logado && logout) {
    return next("/");
  }

  store.commit("UPDATE_MENU", to);

  next();
});

export default router;
