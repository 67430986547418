<template>
  <div id="app">
    <v-app id="inspire">
      <v-card>
        <v-toolbar color="#333333">
          <v-icon color="white" class="mr-2">contact_phone</v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light"
            >Contatos com o {{ $route.path.includes("assinaturas") ? "Assinante" : $route.path.includes("comercial") ? "Anunciante" : "Cliente" }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn v-if="existeHELP" color="success" fab small @click="dialogUpload = true">
            <v-icon color="white" title="Envio do Arquivo HELP">cloud_upload</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="px-6 mt-4">
          <v-container fluid grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <h4 class="overline font-weight-black font-italic">
                  Filtrar Contatos
                </h4>
                <v-divider></v-divider>
              </v-flex>
              <v-flex xs12 md3>
                <v-autocomplete autofocus v-model="idStatus" :items="status" label="Status" no-data-text clearable></v-autocomplete>
              </v-flex>
              <v-flex xs12 md3>
                <v-autocomplete
                  v-model="idAssunto"
                  :items="assuntos"
                  :filter="filtrarObjetos"
                  label="Assunto"
                  item-text="nome"
                  item-value="id"
                  no-data-text
                  clearable
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 md3>
                <v-autocomplete
                  v-model="idMotivo"
                  :items="motivos"
                  :filter="filtrarObjetos"
                  label="Motivo"
                  item-text="nome"
                  item-value="id"
                  no-data-text
                  clearable
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 md3>
                <v-autocomplete
                  v-model="idProcedimento"
                  :items="procedimentos"
                  :filter="filtrarObjetos"
                  label="Procedimento"
                  item-text="nome"
                  item-value="id"
                  no-data-text
                  clearable
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 md2>
                <v-menu v-model="mnuRegistro" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="intervaloRegistro"
                      label="Período do Registro"
                      prepend-icon="event"
                      v-on="on"
                      color="blue-grey lighten-2"
                      clearable
                      @click:clear="intervaloRegistroUS = ['', '']"
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="intervaloRegistroUS" locale="pt-br" no-title range
                    ><v-spacer></v-spacer>
                    <v-btn text color="primary" @click="mnuRegistro = false">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 md2>
                <v-menu v-model="mnuExecucao" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="intervaloExecucao"
                      label="Período da Execução"
                      prepend-icon="event"
                      v-on="on"
                      color="blue-grey lighten-2"
                      clearable
                      @click:clear="intervaloExecucaoUS = ['', '']"
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="intervaloExecucaoUS" locale="pt-br" no-title range
                    ><v-spacer></v-spacer>
                    <v-btn text color="primary" @click="mnuExecucao = false">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 md2>
                <v-menu v-model="mnuSolucao" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="intervaloSolucao"
                      label="Período da Solução"
                      prepend-icon="event"
                      v-on="on"
                      color="blue-grey lighten-2"
                      clearable
                      @click:clear="intervaloSolucaoUS = ['', '']"
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="intervaloSolucaoUS" locale="pt-br" no-title range
                    ><v-spacer></v-spacer>
                    <v-btn text color="primary" @click="mnuSolucao = false">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs6 md1>
                <v-btn v-if="direitoExportar" color="blue" :loading="loadingExp" :disabled="loadingExp" fab small @click="pesquisaDados(1)">
                  <v-icon color="white" title="Exportar">description</v-icon>
                </v-btn>
              </v-flex>
              <v-flex xs12 md5>
                <v-text-field
                  background-color="white"
                  class="mb-2 elevation-0"
                  v-model="filtroPesquisa"
                  append-icon="search"
                  label="Digite para pesquisar..."
                  placeholder="Pesquisa pelo cliente, contato, usuário"
                  hide-details
                  @keyup.enter="limpaFiltro"
                  @click:append="limpaFiltro"
                  autocomplete="disabled"
                  :name="Math.random()"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>

          <v-data-table
            mobile-breakpoint="0"
            :headers="headers"
            :items="registros"
            :options.sync="options"
            :no-data-text="mensagemPesquisa"
            :footer-props="rodape"
            :disable-sort="carregando"
            :disable-pagination="carregando"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon v-if="direitoEditar && item.id" class="mr-2" color="blue lighten-2" title="Editar" @click="editarContato(item)">edit</v-icon>
              <v-icon
                v-if="dreitoCliente && item.id"
                color="orange"
                :title="$route.path.includes('assinaturas') ? 'Visualizar Assinante' : $route.path.includes('comercial') ? 'Visualizar Anunciante' : 'Visualizar Cliente'"
                @click="visualizarCliente(item)"
                >account_circle</v-icon
              >
            </template>
            <template v-slot:item.status="{ item }">
              <v-icon v-if="item.id" color="blue lighten-2" :title="item.status">{{
                item.status == "ABERTO" ? "lock_open" : item.status == "EXECUÇÃO" ? "hourglass_empty" : "lock"
              }}</v-icon>
            </template>
            <template v-slot:item.dtRegistro="{ item }">
              <span>{{ formatDateTime(item.dtRegistro) }}</span>
            </template>
            <template v-slot:item.dtExecucao="{ item }">
              <span>{{ formatDateTime(item.dtExecucao) }}</span>
            </template>
            <template v-slot:item.dtSolucao="{ item }">
              <span>{{ formatDateTime(item.dtSolucao) }}</span>
            </template>
            <template v-slot:item.tempo="{ item }">
              <span>{{ getTempo(item) }}</span>
            </template>
          </v-data-table>
          <div class="text-xs-center pt-2 marg-bt">
            <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 15" circle></v-pagination>
          </div>
          <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
          <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
        </div>
      </v-card>
      <v-dialog persistent v-model="dialogContato" max-width="600px">
        <v-card>
          <v-form ref="formContato" v-model="contatoValid" lazy-validation>
            <v-toolbar>
              <span class="headline">Contato ({{ contatoItem.id }})</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="closeContato">
                <v-icon color="darken-1">cancel</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-autocomplete v-model="contatoItem.idAssunto" :items="assuntos" label="Assunto" item-text="nome" item-value="id" no-data-text readonly></v-autocomplete>
                  </v-flex>
                  <v-flex xs12>
                    <v-autocomplete v-model="contatoItem.idMotivo" :items="motivos" label="Motivo" item-text="nome" item-value="id" no-data-text readonly></v-autocomplete>
                  </v-flex>
                  <v-flex xs12>
                    <v-autocomplete
                      v-model="contatoItem.idProcedimento"
                      :items="procedimentos"
                      label="Procedimento"
                      item-text="nome"
                      item-value="id"
                      no-data-text
                      readonly
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex xs12>
                    <v-autocomplete
                      v-model="contatoItem.status"
                      :items="status"
                      label="Status"
                      no-data-text
                      :readonly="contatoItem.dtSolucao != null"
                      :rules="[validacao.status == false || validacao.status]"
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex xs12 md12>
                    <v-textarea
                      v-model="contatoItem.contato"
                      label="Descrição do Contato"
                      maxlength="1000"
                      counter="1000"
                      rows="5"
                      color="blue-grey lighten-2"
                      readonly
                    ></v-textarea>
                  </v-flex>
                  <v-flex xs12 md12>
                    <v-textarea
                      v-if="contatoItem.status == 'EXECUÇÃO' || contatoItem.dtExecucao != null"
                      v-model="contatoItem.execucao"
                      label="Texto para Execução"
                      rows="5"
                      maxlength="500"
                      :rules="[
                        validacao.execucao == false || validacao.execucao,
                        value => !!value || 'Obrigatório.',
                        value => (value != null && value.length > 10 && value.length < 500) || 'Texto para execução deve ter entre 10 e 500 caracteres'
                      ]"
                      counter="500"
                      :readonly="contatoItem.dtExecucao != null"
                      color="blue-grey lighten-2"
                    ></v-textarea>
                  </v-flex>
                  <v-flex xs12 md12>
                    <v-textarea
                      v-if="contatoItem.status == 'EXECUÇÃO' && contatoItem.dtExecucao != null && contatoItem.idUsuarioExecucao == $store.state.usuario.id"
                      v-model="contatoItem.execucao2"
                      label="Texto Complementar para Execução"
                      rows="5"
                      :maxlength="500 - 22 - contatoItem.execucao.length"
                      :rules="[
                        validacao.execucao == false || validacao.execucao,
                        value =>
                          (value != null && contatoItem.execucao.length + value.length + 22 < 500) ||
                          'Texto para execução deve ter até ' + (500 - 22 - contatoItem.execucao.length) + ' caracteres'
                      ]"
                      :counter="500 - 22 - contatoItem.execucao.length"
                      color="blue-grey lighten-2"
                    ></v-textarea>
                  </v-flex>
                  <v-flex xs12 md12>
                    <v-textarea
                      v-if="contatoItem.status == 'FECHADO'"
                      v-model="contatoItem.solucao"
                      label="Resolução do Contato"
                      rows="5"
                      maxlength="500"
                      :rules="[
                        validacao.solucao == false || validacao.solucao,
                        value => !!value || 'Obrigatório.',
                        value => (value != null && value.length > 10 && value.length < 500) || 'Texto da solução deve ter entre 10 e 500 caracteres'
                      ]"
                      counter="500"
                      :readonly="contatoItem.dtSolucao != null"
                      color="blue-grey lighten-2"
                    ></v-textarea>
                  </v-flex>
                  <v-flex xs12 v-if="validacao.mensagemErro">
                    <v-sheet :value="true" class="red--text">
                      {{ validacao.mensagemErro }}
                    </v-sheet>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" @click="closeContato">Retornar</v-btn>
              <v-btn
                v-if="
                  (contatoItem.status == 'EXECUÇÃO' && (contatoItem.dtExecucao == null || contatoItem.idUsuarioExecucao == $store.state.usuario.id)) ||
                    (contatoItem.status == 'FECHADO' && contatoItem.dtSolucao == null)
                "
                color="blue darken-1"
                @click="saveContato"
                >Atualizar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>

      <v-dialog persistente v-model="dialogUpload" max-width="400px">
        <v-card>
          <v-toolbar>
            <span class="headline">Envio de Arquivo</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialogUpload = false">
              <v-icon color="darken-1">cancel</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <FileUpload :help="true" />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import FileUpload from "@/views/components/FileUpload.vue";
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  components: {
    FileUpload
  },
  data() {
    return {
      validacao: {
        status: false,
        execucao: false,
        solucao: false,
        mensagemErro: false
      },
      headers: [
        { text: "Ações", value: "actions", width: "120" },
        { text: "ID", value: "id" },
        { text: "Status", value: "status" },
        { text: this.$route.path.includes("assinaturas") ? "Assinante" : this.$route.path.includes("comercial") ? "Anunciante" : "Cliente", value: "cliente", divider: "true" },
        { text: "Assunto", value: "assunto" },
        { text: "Motivo", value: "motivo" },
        { text: "Procedimento", value: "procedimento", divider: "true" },
        { text: "Usuário", value: "usuario" },
        { text: "Registro", value: "dtRegistro" },
        { text: "Usuário Execução", value: "usuarioExecucao" },
        { text: "Registro Execução", value: "dtExecucao", divider: "true" },
        { text: "Usuário Solução", value: "usuarioSolucao" },
        { text: "Registro Solução", value: "dtSolucao" },
        { text: "Tempo Solução", value: "tempo" }
      ],
      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },
      status: ["ABERTO", "EXECUÇÃO", "FECHADO"],

      direitoEditar: false,
      dreitoCliente: false,
      direitoExportar: false,

      idSitePrincipal: null,
      idAssunto: [],
      idMotivo: [],
      idProcedimento: [],
      idStatus: null,
      assuntos: [],
      motivos: [],
      procedimentos: [],

      mnuRegistro: false,
      intervaloRegistroUS: ["", ""],
      mnuExecucao: false,
      intervaloExecucaoUS: ["", ""],
      mnuSolucao: false,
      intervaloSolucaoUS: ["", ""],

      dialogContato: false,
      contatoValid: true,
      contatoIndex: -1,
      contatoItem: {
        id: null,
        status: "",
        contato: "",
        execucao: "",
        execucao2: "",
        solucao: ""
      },

      dialogUpload: false,
      existeHELP: false,

      inicial: true,
      mensagem: "",
      snackbar: false,
      snackErro: false,
      mensagemPesquisa: "",
      filtroPesquisa: "",
      registros: [],
      registrosPagina: 10,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      carregando: true,
      ordenacao: "",
      ordem: "",
      options: {},
      loader: null,
      loadingExp: false
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO", "OCULTAR_MENU"]),
    limpaFiltro() {
      this.paginaAtual = 1;
      this.pesquisaDados(0);
    },
    async pesquisaDados(exportar) {
      var l = null;
      if (exportar) {
        this.loader = "loadingExp";
        l = this.loader;
        this[l] = !this[l];
        setTimeout(() => (this[l] = false), 5000);
        this.loader = null;
      }
      if (!exportar) {
        //sinaliza que está carregando
        this.carregando = true;
        this.mensagemPesquisa = "Carregando...";
      } else {
        this.mensagemPesquisa = "MONTE SEU FILTRO PARA VISUALIZAR OS CONTATOS...";
      }

      this.registros = [];
      this.totalRegistros = 0;
      this.totalPaginas = 0;
      this.rodape.pageText = "";

      var dtRegInicio = "";
      var dtRegFim = "";
      if (this.intervaloRegistroUS && this.intervaloRegistroUS.length > 1) {
        dtRegInicio = this.intervaloRegistroUS[0];
        dtRegFim = this.intervaloRegistroUS[1];
      }
      var dtExeInicio = "";
      var dtExeFim = "";
      if (this.intervaloExecucaoUS && this.intervaloExecucaoUS.length > 1) {
        dtExeInicio = this.intervaloExecucaoUS[0];
        dtExeFim = this.intervaloExecucaoUS[1];
      }
      var dtSolInicio = "";
      var dtSolFim = "";
      if (this.intervaloSolucaoUS && this.intervaloSolucaoUS.length > 1) {
        dtSolInicio = this.intervaloSolucaoUS[0];
        dtSolFim = this.intervaloSolucaoUS[1];
      }
      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "adm/cliente-contato?pagina=" +
            this.paginaAtual +
            "&tamanho=" +
            this.registrosPagina +
            "&filtro=" +
            this.filtroPesquisa +
            (this.idStatus ? "&status=" + this.idStatus : "") +
            (this.idAssunto ? "&idAssunto=" + this.idAssunto : "") +
            (this.idMotivo ? "&idMotivo=" + this.idMotivo : "") +
            (this.idProcedimento ? "&idProcedimento=" + this.idProcedimento : "") +
            (dtRegInicio ? "&dtRegInicio=" + dtRegInicio : "") +
            (dtRegFim ? "&dtRegFim=" + dtRegFim : "") +
            (dtExeInicio ? "&dtExeInicio=" + dtExeInicio : "") +
            (dtExeFim ? "&dtExeFim=" + dtExeFim : "") +
            (dtSolInicio ? "&dtSolInicio=" + dtSolInicio : "") +
            (dtSolFim ? "&dtSolFim=" + dtSolFim : "") +
            "&ordenacao=" +
            this.ordenacao +
            "&ordem=" +
            this.ordem +
            (exportar ? "&exportar=1" : "")
        );

        if (!exportar) {
          //seta as variaveis com o retorno
          this.registros = response.data.data;
          this.totalRegistros = response.data.total;
          this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
          this.rodape.pageText = this.totalRegistros + " itens";
          //fecha o loading
          this.carregando = false;
          //se não achar registro seta a mensagem senão deixa em branco
          this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
        }
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else {
          this.mensagem =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
          this.snackErro = true;
        }
      }
    },
    async carregarMotivos() {
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/motivo-contato/lista?idSite=" + this.idSitePrincipal);
      if (response.data) {
        response.data.forEach(element => {
          if (element.tipo == 2) {
            this.motivos.push(element);
          } else if (element.tipo == 3) {
            this.procedimentos.push(element);
          } else {
            this.assuntos.push(element);
          }
        });
      }
    },
    async carregarProdutosClube() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/produto/lista?tipo=CLUBE");
        response.data.forEach(element => {
          if (element.nome.toUpperCase() == "HELP") {
            this.existeHELP = true;
          }
        });
      } catch (e) {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de produtos do clube";
        this.snackErro = true;
      }
    },
    editarContato(item) {
      this.contatoIndex = this.registros.indexOf(item);
      this.contatoItem = Object.assign({}, item);
      this.contatoItem.execucao2 = "";
      this.dialogContato = true;
    },
    closeContato() {
      this.dialogContato = false;
    },
    async saveContato() {
      if (this.$refs.formContato.validate()) {
        if (this.contatoItem.status == "ABERTO") {
          this.validacao.status = "Status inválido"; //* não deve cair aqui, pois o botão fica desabilitado para este status
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
          return;
        }
        if (this.contatoItem.status == "EXECUÇÃO") {
          if (!this.contatoItem.dtExecucao) {
            this.contatoItem.dtExecucao = new Date();
          }
          if (!this.contatoItem.usuarioExecucao) {
            this.contatoItem.usuarioExecucao = this.$store.state.usuario.nome;
          }
          if (!this.contatoItem.idUsuarioExecucao) {
            this.contatoItem.idUsuarioExecucao = this.$store.state.usuario.id;
          }
        } else {
          this.contatoItem.dtSolucao = new Date();
          this.contatoItem.usuarioSolucao = this.$store.state.usuario.nome;
          this.contatoItem.idUsuarioSolucao = this.$store.state.usuario.id;
        }

        var textoExec = this.contatoItem.execucao;
        if (this.contatoItem.execucao2) {
          textoExec += "\n[" + util.formatDateTime(new Date()) + "] " + this.contatoItem.execucao2;
          if (textoExec.length > 500) {
            textoExec = textoExec.substr(0, 500);
          }
        }

        var contatoCommit = {
          id: this.contatoItem.id,
          status: this.contatoItem.status
        };
        if (this.contatoItem.status == "EXECUÇÃO") {
          contatoCommit.idUsuarioExecucao = this.contatoItem.idUsuarioExecucao;
          contatoCommit.execucao = textoExec;
        } else {
          contatoCommit.idUsuarioSolucao = this.contatoItem.idUsuarioSolucao;
          contatoCommit.solucao = this.contatoItem.solucao;
        }

        try {
          await api.put(
            process.env.VUE_APP_URL_WEB_API + "adm/cliente-contato/" + (this.contatoItem.status == "EXECUÇÃO" ? "execucao" : "solucao") + "/" + this.contatoItem.id,
            contatoCommit
          );
          this.contatoItem.execucao = textoExec;
          Object.assign(this.registros[this.contatoIndex], this.contatoItem);

          this.closeContato();
          this.$refs.formContato.resetValidation();
          this.mensagem = "Item alterado";
          this.snackbar = true;
        } catch (e) {
          if (e.toString().includes("status code 400")) {
            this.mensagem = "SESSÃO EXPIRADA";
            this.snackErro = true;
          } else if (e.response && e.response.data) {
            util.validaErros(this.validacao, e.response.data);
            this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
            this.snackErro = true;
          } else {
            this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
            this.snackErro = true;
          }
        }
      }
    },
    async visualizarCliente(item) {
      window.open(
        `/${this.$route.path.includes("assinaturas") ? "assinaturas/assinante" : this.$route.path.includes("comercial") ? "comercial/anunciante" : "financeiro/cliente"}/${
          item.idCliente
        }`,
        "_blank"
      );
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    formatDateTime(date) {
      return util.formatDateTime(date);
    },
    getTempo(item) {
      if (item.dtSolucao) {
        const endDate = new Date(item.dtSolucao);
        const begDate = new Date(item.dtRegistro);
        const days = parseInt((endDate - begDate) / (1000 * 60 * 60 * 24));
        const hours = parseInt((Math.abs(endDate - begDate) / (1000 * 60 * 60)) % 24);
        const minutes = parseInt((Math.abs(endDate.getTime() - begDate.getTime()) / (1000 * 60)) % 60);
        const seconds = parseInt((Math.abs(endDate.getTime() - begDate.getTime()) / 1000) % 60);
        return `${days.toString().padStart(3, " ")}d, ${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
      } else {
        return null;
      }
    }
  },
  computed: {
    intervaloRegistro() {
      var retorno = "";
      if (this.intervaloRegistroUS && this.intervaloRegistroUS.length > 1) {
        this.intervaloRegistroUS.forEach(element => {
          if (element) {
            retorno += (retorno ? " - " : "") + util.formatDate(element);
          }
        });
      }
      return retorno;
    },
    intervaloExecucao() {
      var retorno = "";
      if (this.intervaloExecucaoUS && this.intervaloExecucaoUS.length > 1) {
        this.intervaloExecucaoUS.forEach(element => {
          if (element) {
            retorno += (retorno ? " - " : "") + util.formatDate(element);
          }
        });
      }
      return retorno;
    },
    intervaloSolucao() {
      var retorno = "";
      if (this.intervaloSolucaoUS && this.intervaloSolucaoUS.length > 1) {
        this.intervaloSolucaoUS.forEach(element => {
          if (element) {
            retorno += (retorno ? " - " : "") + util.formatDate(element);
          }
        });
      }
      return retorno;
    }
  },
  beforeMount() {
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_CONTATOS_ACESSO", "COMERCIAL_CONTATOS_ACESSO", "FINANCEIRO_CONTATOS_ACESSO"]);
    this.OCULTAR_MENU();
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA") {
        this.direitoEditar = true;
        this.dreitoCliente = true;
        this.direitoExportar = true;
      }
      if (this.$route.path.includes("assinaturas")) {
        if (direito == "ASSINATURAS_CONTATOS_EDITAR") {
          this.direitoEditar = true;
        }
        if (direito == "ASSINATURAS_ASSINANTES_EDITAR") {
          this.dreitoCliente = true;
        }
        if (direito == "ASSINATURAS_CONTATOS_EXPORTAR") {
          this.direitoExportar = true;
        }
      }
      if (this.$route.path.includes("comercial")) {
        if (direito == "COMERCIAL_CONTATOS_EDITAR") {
          this.direitoEditar = true;
        }
        if (direito == "COMERCIAL_ANUNCIANTES_EDITAR") {
          this.dreitoCliente = true;
        }
        if (direito == "COMERCIAL_CONTATOS_EXPORTAR") {
          this.direitoExportar = true;
        }
      }
      if (this.$route.path.includes("financeiro")) {
        if (direito == "FINANCEIRO_CONTATOS_EDITAR") {
          this.direitoEditar = true;
        }
        if (direito == "FINANCEIRO_CLIENTES_EDITAR") {
          this.dreitoCliente = true;
        }
        if (direito == "FINANCEIRO_CONTATOS_EXPORTAR") {
          this.direitoExportar = true;
        }
      }
    });
    this.carregarMotivos();
    this.carregarProdutosClube();
  },

  watch: {
    dialogContato(val) {
      val || this.closeContato();
    },
    paginaAtual: function() {
      this.pesquisaDados(0);
    },
    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }

        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }

        if (!this.inicial) {
          this.pesquisaDados(0);
        } else {
          this.mensagemPesquisa = "MONTE SEU FILTRO PARA VISUALIZAR OS CONTATOS...";
          this.inicial = false;
        }
      }
    }
  }
};
</script>
