<template>
  <div id="app">
    <v-app id="inspire">
      <v-card>
        <v-toolbar color="#333333">
          <v-icon color="white" class="mr-2">location_on</v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light">Logradouros</v-toolbar-title>
          <v-spacer></v-spacer>
          <router-link :to="(rota.includes('/assinaturas') ? '/assinaturas/' : '/administrativo/') + 'cep'" style="text-decoration: none" v-if="direitoCriar">
            <v-btn color="success" fab small>
              <v-icon color="white" title="Incluir">add</v-icon>
            </v-btn>
          </router-link>
        </v-toolbar>
        <div class="px-6 mt-4">
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="12" md="5">
              <v-text-field
                background-color="white"
                class="mt-2 mb-2 elevation-0"
                v-model="filtroPesquisa"
                append-icon="search"
                label="Digite para pesquisar..."
                :placeholder="
                  'Pesquisa por ID, ' +
                    ($store.state.usuario.site_pais == 'BR' ? 'UF, município' : 'distrito, concelho') +
                    ', CEP, ' +
                    ($store.state.usuario.site_pais == 'BR' ? 'bairro' : 'freguesia/localidade') +
                    ', logradouro, faixa, entregador, rota'
                "
                filled
                hide-details
                @keyup.enter="limpaFiltro"
                @click:append="limpaFiltro"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-data-table
            mobile-breakpoint="0"
            :headers="$store.state.usuario.site_assinaturas ? headers : headersCom"
            :items="registros"
            :options.sync="options"
            :no-data-text="mensagemPesquisa"
            :footer-props="rodape"
            :disable-sort="carregando"
            :disable-pagination="carregando"
          >
            <template v-slot:[getItemActions()]="{ item }">
              <router-link :to="(rota.includes('/assinaturas') ? '/assinaturas/' : '/administrativo/') + 'cep/' + item.id" style="text-decoration: none" v-if="direitoEditar">
                <v-icon color="blue" title="Editar">edit</v-icon>
              </router-link>
              <v-icon v-if="direitoEditar" color="blue lighten-3" title="Editar Faixa" @click="editaFaixa(item)">edit </v-icon>
              <v-icon
                v-if="direitoEditar"
                :class="item.entrega ? '' : 'mr-3'"
                :color="item.situacao == 1 ? 'blue lighten-2' : 'grey'"
                :title="item.situacao == 1 ? 'Desativar' : 'Ativar'"
                @click="ativarDesativarCEP(item)"
              >
                {{ item.situacao == 1 ? "radio_button_checked" : item.situacao == 0 ? "radio_button_unchecked" : "" }}
              </v-icon>
              <v-icon
                v-if="direitoEditar && $store.state.usuario.site_assinaturas"
                :color="item.entrega ? 'blue lighten-1' : 'blue-grey lighten-3'"
                :title="(item.entrega ? 'Desmarcar' : 'Marcar') + ' como Endereço de Entrega'"
                @click="marcarDesmarcarEndereco(item)"
                >local_shipping</v-icon
              >

              <v-icon
                v-if="item.entrega"
                :color="item.roteiro == 0 || item.roteiro == 2 ? 'red' : 'blue-grey lighten-3'"
                :title="(item.roteiro == null || item.roteiro == 1 ? 'Marcar' : 'Desmarcar') + ' como Roteiro de Assinaturas'"
                @click="roteiroAssinaturas(item)"
                >assignment</v-icon
              >

              <v-icon
                v-if="item.entrega"
                :color="item.roteiro == 1 || item.roteiro == 2 ? 'blue' : 'blue-grey lighten-3'"
                :title="(item.roteiro == null || item.roteiro == 0 ? 'Marcar' : 'Desmarcar') + ' como Roteiro de Venda Avulsa'"
                @click="roteiroVendaAvulsa(item)"
                >list_alt</v-icon
              >
              <v-icon
                :class="item.entrega ? 'ml-3' : 'ml-12'"
                v-if="direitoEditar"
                :color="item.selecionado ? 'blue lighten-1' : 'blue-grey lighten-3'"
                :title="(item.selecionado ? 'Desmarcar' : 'Marcar') + ' Logradouro para Correção'"
                @click="marcarDesmarcarLogradouro(item)"
                >forward</v-icon
              >
              <v-icon v-if="direitoEditar" color="blue-grey lighten-3" title="Logradouro Correto" @click="correcaoLogradouro(item)">done_all</v-icon>
            </template>
          </v-data-table>
          <div class="text-xs-center pt-2 marg-bt">
            <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 7" circle></v-pagination>
          </div>
          <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
          <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
        </div>
      </v-card>
      <v-dialog persistent v-model="dialogConfirm" max-width="600">
        <v-card>
          <v-toolbar>
            <span class="headline">Confirmação</span>
          </v-toolbar>
          <v-card-text v-html="msgConfirm"></v-card-text>
          <v-card-actions>
            <v-btn color="red darken-1" text @click="naoConfirmado">Não</v-btn>
            <v-btn color="green darken-1" text @click="confirmado">Sim</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog persistent v-model="dialogFaixa" max-width="600px" @keydown.esc="dialogFaixa = false">
        <v-card>
          <v-form ref="formFaixa" v-model="faixaValid" lazy-validation>
            <v-toolbar>
              <span class="headline">Faixa</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="closeFaixa">
                <v-icon color="darken-1">cancel</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-container fluid grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 md12>
                    <v-text-field
                      autofocus
                      v-model="cepItem.faixa"
                      label="Faixa"
                      maxlength="50"
                      :rules="[validacao.faixa == false || validacao.faixa, value => valida_nome(value) || 'Faixa inválida']"
                      color="blue-grey lighten-2"
                      @change="
                        cepItem.faixa = pLetra(cepItem.faixa);
                        validacao.faixa = false;
                      "
                      autocomplete="disabled"
                      :name="Math.random()"
                      title="Para distinguir rotas diferentes no mesmo logradouro, ex: Pares/Ímpares"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" @click="closeFaixa">Retornar</v-btn>
              <v-btn color="blue darken-1" @click="saveFaixa">Atualizar</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      headers: [
        { text: "Ações", value: "actions", width: "240" },
        { text: "ID", value: "id" },
        {
          text: this.$store.state.usuario.site_pais == "BR" ? "UF" : "Distrito",
          value: "uf"
        },
        {
          text: this.$store.state.usuario.site_pais == "BR" ? "Município" : "Concelho",
          value: "municipio"
        },
        { text: "CEP", value: "cep" },
        {
          text: this.$store.state.usuario.site_pais == "BR" ? "Bairro" : "Freguesia/Localidade",
          value: "bairro"
        },
        { text: "Logradouro", value: "logradouro", divider: "true" },
        { text: "Faixa", value: "faixa", divider: "true" },
        { text: "Entregador Assinaturas", value: "entregador" },
        { text: "Rota Assinaturas", value: "rota", divider: "true" },
        { text: "Entregador Bancas", value: "entregadorVA" },
        { text: "Rota Bancas", value: "rotaVA" }
      ],
      headersCom: [
        { text: "Ações", value: "actions", width: "240" },
        { text: "ID", value: "id" },
        { text: "País", value: "pais" },
        {
          text: this.$store.state.usuario.site_pais == "BR" ? "UF" : "Distrito",
          value: "uf"
        },
        {
          text: this.$store.state.usuario.site_pais == "BR" ? "Município" : "Concelho",
          value: "municipio"
        },
        { text: "CEP", value: "cep" },
        {
          text: this.$store.state.usuario.site_pais == "BR" ? "Bairro" : "Freguesia/Localidade",
          value: "bairro"
        },
        { text: "Logradouro", value: "logradouro", divider: "true" }
      ],
      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },
      validacao: { faixa: false },

      dialogConfirm: false,
      msgConfirm: "",
      cepDestino: {},
      idsOrigem: "",

      dialogFaixa: false,
      faixaValid: true,
      cepIndex: -1,
      cepItem: { id: null, faixa: "" },

      direitoCriar: false,
      direitoEditar: false,
      mensagem: "",
      snackbar: false,
      snackErro: false,
      mensagemPesquisa: "",
      filtroPesquisa: "",
      registros: [],
      registrosPagina: 10,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      carregando: true,
      ordenacao: "",
      ordem: "",
      options: {}
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    getItemActions() {
      return "item.actions";
    },

    limpaFiltro() {
      this.paginaAtual = 1;
      this.pesquisaDados();
    },

    async pesquisaDados() {
      this.registros = [];
      //sinaliza que está carregando
      this.carregando = true;
      this.mensagemPesquisa = "Carregando...";

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "adm/cep?pagina=" +
            this.paginaAtual +
            "&tamanho=" +
            this.registrosPagina +
            "&filtro=" +
            this.filtroPesquisa +
            "&ordenacao=" +
            this.ordenacao +
            "&ordem=" +
            this.ordem
        );

        //seta as variaveis com o retorno
        this.registros = response.data.data;
        this.registros.forEach(element => {
          element.logradouro = element.tipo + " " + element.logradouro;
        });
        this.totalRegistros = response.data.total;
        this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
        this.rodape.pageText = this.totalRegistros + " itens";
        //fecha o loading
        this.carregando = false;
        //se não achar registro seta a mensagem senão deixa em branco
        this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else {
          this.mensagemPesquisa = "Erro ao buscar dados: " + e;
        }
      }
    },

    async ativarDesativarCEP(cep) {
      var cepItem = Object.assign({}, cep);
      cepItem.situacao = cepItem.situacao == 1 ? 0 : 1;
      var situacao = { situacao: cepItem.situacao };
      try {
        await api.put(process.env.VUE_APP_URL_WEB_API + "adm/cep/situacao/" + cepItem.id, situacao);
        var cepIndex = this.registros.indexOf(cep);
        Object.assign(this.registros[cepIndex], cepItem);
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },

    async marcarDesmarcarEndereco(cep) {
      var cepItem = Object.assign({}, cep);
      cepItem.entrega = cepItem.entrega == 1 ? 0 : 1;
      var entrega = { entrega: cepItem.entrega };
      try {
        await api.put(process.env.VUE_APP_URL_WEB_API + "adm/cep/entrega/" + cepItem.id, entrega);
        var cepIndex = this.registros.indexOf(cep);
        Object.assign(this.registros[cepIndex], cepItem);
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },
    async roteiroAssinaturas(cep) {
      var cepItem = Object.assign({}, cep);
      if (cepItem.roteiro == null) {
        //se não tinha roteiro, passa para roteiro de assinaturas
        cepItem.roteiro = 0;
      } else if (cepItem.roteiro == 0) {
        //se era roteiro de assinaturas, não mexe, pois endereço é de entrega
        this.mensagem = "Endereço é de entrega, então precisa ter roteiro";
        this.snackErro = true;
        return;
      } else if (cepItem.roteiro == 1) {
        //se era roteiro de venda avulsa, passa para ambas
        cepItem.roteiro = 2;
      } else {
        //se era ambas, passa para roteiro de venda avulsa
        cepItem.roteiro = 1;
        cepItem.entregador = "";
        cepItem.rota = "";
      }
      var roteiro = { roteiro: cepItem.roteiro };
      try {
        await api.put(process.env.VUE_APP_URL_WEB_API + "adm/cep/roteiro/" + cepItem.id, roteiro);
        var cepIndex = this.registros.indexOf(cep);
        Object.assign(this.registros[cepIndex], cepItem);
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },
    async roteiroVendaAvulsa(cep) {
      var cepItem = Object.assign({}, cep);
      if (cepItem.roteiro == null) {
        //se não tinha roteiro, passa para roteiro de venda avulsa
        cepItem.roteiro = 1;
      } else if (cepItem.roteiro == 1) {
        //se era roteiro de venda avulsa, não mexe, pois endereço é de entrega
        this.mensagem = "Endereço é de entrega, então precisa ter roteiro";
        this.snackErro = true;
        return;
      } else if (cepItem.roteiro == 0) {
        //se era roteiro de assinatura, passa para ambas
        cepItem.roteiro = 2;
      } else {
        //se era ambas, passa para roteiro de assinatura
        cepItem.roteiro = 0;
        cepItem.entregadorVA = "";
        cepItem.rotaVA = "";
      }
      var roteiro = { roteiro: cepItem.roteiro };
      try {
        await api.put(process.env.VUE_APP_URL_WEB_API + "adm/cep/roteiro/" + cepItem.id, roteiro);
        var cepIndex = this.registros.indexOf(cep);
        Object.assign(this.registros[cepIndex], cepItem);
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },
    async marcarDesmarcarLogradouro(cep) {
      cep.selecionado = cep.selecionado == 1 ? 0 : 1;
    },
    correcaoLogradouro(cep) {
      this.cepDestino = cep;
      this.idsOrigem = "";
      var erro = false;
      var contador = 0;
      this.msgConfirm = "<br><p><strong>Transferir os seguintes logradouros ORIGEM:</strong></p>";

      this.registros.forEach(element => {
        if (element.selecionado) {
          if (cep.id == element.id) {
            erro = true;
          }
          contador++;
          this.idsOrigem += (this.idsOrigem ? "," : "") + element.id;
          this.msgConfirm += `<p>${element.id} - ${element.cep} - ${element.tipo} ${element.logradouro} - ${element.faixa} - ${element.municipio}/${element.uf}</p>`;
        }
      });
      if (erro) {
        this.mensagem = "Não é possível transferir um logradouro para ele mesmo";
        this.snackErro = true;
        return;
      }
      if (contador == 0) {
        this.mensagem = "Você deve selecionar pelo menos 1 logradouro de origem";
        this.snackErro = true;
        return;
      }
      if (contador > 10) {
        this.mensagem = "Limite de itens a serem transferidos = 10";
        this.snackErro = true;
        return;
      }

      this.msgConfirm += "<br><p><strong>Para o logradouro DESTINO:</strong></p>";
      this.msgConfirm += `<p>${cep.id} - ${cep.cep} - ${cep.tipo} ${cep.logradouro} - ${cep.faixa} - ${cep.municipio}/${cep.uf}</p>`;

      this.msgConfirm += "<br /><br /><strong><span style='color:blue'>Tem certeza que deseja realizar a transferência?</span></strong>";
      this.msgConfirm +=
        "<br /><span style='color:red'>Nota: todos os registros dos logradouros ORIGEM serão movidos para o logradouro DESTINO e os logradouros de ORIGEM serão EXCLUÍDOS do sistema.</span>";
      this.dialogConfirm = true;
    },
    async confirmado() {
      this.dialogConfirm = false;

      var transferirCommit = {
        origem: this.idsOrigem
      };

      try {
        await api.put(process.env.VUE_APP_URL_WEB_API + "adm/cep/transferir/" + this.cepDestino.id, transferirCommit);

        this.mensagem = "Logradouros transferidos com sucesso";
        this.snackbar = true;

        setTimeout(() => {
          this.pesquisaDados();
        }, 300);
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },
    naoConfirmado() {
      this.dialogConfirm = false;
    },

    editaFaixa(item) {
      this.cepIndex = this.registros.indexOf(item);
      this.cepItem = Object.assign({}, item);
      this.dialogFaixa = true;
    },
    async saveFaixa() {
      try {
        if (this.$refs.formFaixa.validate()) {
          await api.put(process.env.VUE_APP_URL_WEB_API + "adm/cep/faixa/" + this.cepItem.id, { faixa: this.cepItem.faixa });

          Object.assign(this.registros[this.cepIndex], this.cepItem);
          this.closeFaixa();
          this.$refs.formFaixa.resetValidation();
        }
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },
    closeFaixa() {
      this.dialogFaixa = false;
      setTimeout(() => {
        this.cepIndex = -1;
        this.cepItem = {};
        this.$refs.formFaixa.resetValidation();
      }, 300);
    },
    pLetra(text) {
      return util.pLetra(text);
    },
    valida_nome(text) {
      return util.valida_nome(text);
    }
  },

  beforeMount() {
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ADMINISTRATIVO_CEPS_ACESSO"]);
    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ADMINISTRATIVO_CEPS_CRIAR") {
        this.direitoCriar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ADMINISTRATIVO_CEPS_EDITAR") {
        this.direitoEditar = true;
      }
    });
  },

  computed: {
    rota() {
      return this.$route.path;
    }
  },

  watch: {
    dialogFaixa(val) {
      val || this.closeFaixa();
    },
    paginaAtual() {
      this.pesquisaDados();
    },
    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }

        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }

        this.pesquisaDados();
      }
    }
  }
};
</script>
